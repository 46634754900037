<div class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title">Profile</h3>
    <p class="bs-form-label">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis molestias quidem quis nihil sed ullam libero</p>
    <hr class="mt-0" style="height: 0px">    
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>{{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>

        <div class="row">            
                
            <!-- Email Verification -->
            <!-- <div class="col-sm-12 mt-2" id="thankyou_div" *ngIf="email_verify == 'YES'" style="box-shadow: 0px 0px 10px 1px #adb5bd;padding: 20px;border-radius: 6px;width: -webkit-fill-available;margin:20px">
                <h5 class="bs-left-form-label" style="text-indent: 10px;">Email Verification</h5>                
                <div class="p-2 pt-0 text-center">
                    <label class="bs-form-label">Email Verification is Completed !</label>
                </div>                    
            </div>                 -->

            <div class="col-sm-12 mt-2" id="registerForm_div" *ngIf="email_verify == 'NO'" style="box-shadow: 0px 0px 10px 1px #adb5bd;padding: 20px;border-radius: 6px;width: -webkit-fill-available;margin:20px">
                <h5 class="bs-left-form-label" style="text-indent: 10px;">Email Verification</h5>
            
                <form [formGroup]="registerForm" class="col-sm-4 p-2 pt-0">                                           
                    <div class="mb-2">
                        <label for="username" class="bs-form-label">Email</label>
                        <input type="text" class="form-control" id="username" formControlName="username" (blur)="ifempty($event)" placeholder="Enter email" >
                        <small style="font-size: 12px;">OTP will be sent to above email</small>
                    </div>
                    <div>
                    <a style="text-decoration: none;">
                        <button type="button" id="generate_OTP" (click)="generate_OTP()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                        Generate OTP
                        </button>
                    </a>
                    </div>
        
                    <!------- Resend OTP ------>
                    <div class="form-label mt-2" id="resendbtn" style="text-align: right; display: none"><span id="timer">{{display }}</span> <button id="resendlink" disabled (click)="resendotp()"> <u>Resend OTP</u> </button></div>
                    <!------------------------->
        
                    <div id="verifyOTP" style="display: none">
                        <hr class="mt-2 mb-2">
                        <div class="mb-2">
                            <label for="otp" class="bs-form-label">Verify OTP</label>
                            <input type="text" class="form-control" id="otp" formControlName="otp" placeholder="Enter OTP" (blur)="isNumber($event)" />
                        </div>                
                        <div>
                            <a style="text-decoration: none;">
                            <button type="submit" id="verify_OTP" (click)="verify_OTP()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                                Verify OTP
                            </button>
                            </a>
                        </div>                  
                    </div>
                </form>
            </div>                                
            <!-- End -->

            <!-- Update Profile Details -->
            <form [formGroup]="basicInfo">                    
                <div class="row">   
                    
                    <div class="col-sm-12 mt-2" style="box-shadow: 0px 0px 10px 1px #adb5bd;padding: 20px;border-radius: 6px;width: -webkit-fill-available;margin:20px">
                        <h5 class="bs-left-form-label" style="text-indent: 10px;">
                            <img src="../../assets/img/common icons/edit_profile.png" style="float: right; cursor: pointer;" (click)="profilePEdit()" />
                        </h5>                        
                    
                        <div class="row" id="NonEditP">
                            <div class="col-sm-1">
                                <img src="../../assets/img/common icons/profile-user.png" style="width: 60px; display:block; margin:auto" />
                            </div>
                            <div class="col-sm-10">
                                <div class="noedittext">{{NE_company_name}}</div>
                                <div class="noedittext bs-form-label">{{NE_group_company_name}}</div>
                                <div class="noedittext bs-form-label">{{NE_head_quarter_country}}</div>
                            </div>    
                        </div>                        

                        <div class="row p-2 pt-0" id="EditP" style="display: none">
                            <div class="col-sm-6 form-group">
                                <label class="bs-form-label" for="company_name">Company Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="company_name" id="company_name" placeholder="Company Name"
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.company_name.errors}"
                                required />
                                <ng-container
                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_name.errors">
                                <p class="error"
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_name.errors.required">
                                    Company name is required
                                </p>
                                </ng-container>
                            </div>                                               
    
                            <div class="col-sm-6 form-group">
                                <label class="bs-form-label" for="group_company_name">Group Company Name</label>
                                <input type="text" class="form-control" formControlName="group_company_name" id="group_company_name" placeholder="Group Company Name" />
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="head_quarter_country">Head Quarter Country</label>
                                <select class="form-select" formControlName="head_quarter_country" id="head_quarter_country"
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.head_quarter_country.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                </select>
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.head_quarter_country.errors">
                                    <p class="error">
                                        Head Quarter Country is required
                                    </p>
                                </ng-container>
                            </div>                             
                        </div>                                                                
                        <!-- <hr class="my-3">                                                                                                -->
                    </div>
                                            
                    <div class="col-sm-12 mt-2" style="box-shadow: 0px 0px 10px 1px #adb5bd;padding: 20px;border-radius: 6px;width: -webkit-fill-available;margin:20px">
                        <h5 class="bs-left-form-label" style="text-indent: 10px;">Contact Details
                            <img src="../../assets/img/common icons/edit_profile.png" style="float: right; cursor: pointer;" (click)="profileCntDEdit()" />
                        </h5>

                        <div class="row m-0 p-0 mt-3" id="NonEditCntD">
                            <div class="col-sm-6 row">
                                <div class="col-sm-6 form-group">
                                    <label class="bs-form-label" for="contact_person">Contact Person <span class="redstar">*</span></label>
                                    <div class="noedittext">{{NE_contact_person}}</div>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label class="bs-form-label" for="email_address">Email</label>
                                    <div class="noedittext">{{NE_email_address}}</div>
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="contact_phone_code">Mobile No <span class="redstar">*</span></label>
                                    <div class="noedittext">{{NE_contact_number}}</div>
                                </div>             
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="landline">Landline</label>
                                    <div class="noedittext">{{NE_landline}}</div>
                                </div>
                                <div class="col-sm-12 form-group mt-3">
                                    <label class="bs-form-label" for="registered_address">Address Line 1 <span class="redstar">*</span></label>
                                    <div class="noedittext">{{NE_registered_address}}</div>
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="zipcode">Zip / Postal Code <span class="redstar">*</span></label>
                                    <div class="noedittext">{{NE_zipcode}}</div>
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="city">City</label>
                                    <div class="noedittext">{{NE_city}}</div>
                                </div>                                                   
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="state">State</label>
                                    <div class="noedittext">{{NE_state}}</div>
                                </div>                                                   
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="country">Country <span class="redstar">*</span></label>
                                    <div class="noedittext">{{NE_country}}</div>
                                </div>
                            </div>
                            <div class="col-sm-6 row">

                            </div>                            
                        </div>

                        <div class="row m-0 p-0 mt-3" id="EditCntD" style="display: none">
                            <div class="col-sm-6 row">
                                <div class="col-sm-6 form-group">
                                    <label class="bs-form-label" for="contact_person">Contact Person <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="contact_person" id="contact_person" placeholder="John Doe" 
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.contact_person.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.contact_person.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.contact_person.errors.required">
                                        Contact Person is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label class="bs-form-label" for="email_address">Email</label>
                                    <input type="email" class="form-control" formControlName="email_address" id="email_address" placeholder="abc@xyz.com" />
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="contact_phone_code">Mobile No <span class="redstar">*</span></label>
                                    <div class="input-group mb-3">                                    
                                        <input type="text" class="form-control" formControlName="contact_number" id="contact_number" placeholder="1223 4455 7878" (keypress)="isNumber($event)" />                                
                                    </div>
                                </div>             
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="landline">Landline</label>
                                    <input type="email" class="form-control" formControlName="landline" id="landline" placeholder="234 098 783" (keypress)="isNumber($event)" />
                                </div>
                                <div class="col-sm-12 form-group">
                                    <label class="bs-form-label" for="registered_address">Address Line 1 <span class="redstar">*</span></label>
                                    <textarea class="form-control" formControlName="registered_address" id="registered_address" rows="2" 
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.registered_address.errors}">
                                    </textarea>
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.registered_address.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.registered_address.errors.required">
                                        Address is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="zipcode">Zip / Postal Code <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="zipcode" id="zipcode" placeholder="123456" (keypress)="isNumber_tab2($event)"
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.zipcode.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.zipcode.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.zipcode.errors.required">
                                        Zip / Postal Code is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="city">City</label>
                                    <input type="text" class="form-control" formControlName="city" id="city" placeholder="City Name" />
                                </div>                                                   
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="state">State</label>
                                    <input type="text" class="form-control" formControlName="state" id="state" placeholder="State" />
                                </div>                                                   
                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="country">Country <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="country" id="country" placeholder="Country" 
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.country.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.country.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.country.errors.required">
                                        Country is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-sm-6 row">

                            </div>                            
                        </div>  

                    </div>

                    <div class="col-sm-12 mt-2" style="box-shadow: 0px 0px 10px 1px #adb5bd;padding: 20px;border-radius: 6px;width: -webkit-fill-available;margin:20px">
                        <h5 class="bs-left-form-label" style="text-indent: 10px;">Company Details
                            <img src="../../assets/img/common icons/edit_profile.png" style="float: right; cursor: pointer;" (click)="profileCDEdit()" />
                        </h5>
                    
                        <div class="row p-2 pt-0 mt-3" id="NonEditCD">
                            
                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="turnover">Turnover / Revenue</label>
                                <div class="noedittext">{{NE_Turnover_Revenue}}</div>                                
                            </div>  

                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="type_of_firm">Company Type <span class="redstar">*</span></label>
                                <div class="noedittext">{{NE_type_of_firm}}</div>
                            </div>

                            <div class="col-sm-4" id="listeddiv" style="display:none">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="company_person">Listed / Unlisted</label>
                                </div>
                                <div class="noedittext">{{NE_company_person}}</div>                                
                            </div>

                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="company_registration_no">Company Registration Number</label>
                                <div class="noedittext">{{NE_company_registration_no}}</div>
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="GST">GST</label>
                                <div class="noedittext">{{NE_GST}}</div>
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="PAN">PAN</label>
                                <div class="noedittext">{{NE_PAN}}</div>
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="TAN">TAN</label>
                                <div class="noedittext">{{NE_TAN}}</div>
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="industry">Industry <span class="redstar">*</span></label>
                                <div class="noedittext">{{NE_industry}}</div>
                            </div>                            
                                     
                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="employee_headcount">Company Headcount <span class="redstar">*</span></label>
                                <div class="noedittext">{{NE_employee_headcount}}</div>
                            </div>                                                                                                                  
                            
                        </div>

                        <div class="row p-2 pt-0 mt-3" id="EditCD" style="display: none">
                            
                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="turnover">Turnover / Revenue</label>
                                <select class="form-select" id="turnover" formControlName="turnover">
                                    <option value="" selected>Select</option>
                                    <option value="Upto 10 Cr.">Upto 10 Cr.</option>
                                    <option value="11 Cr to 100 Cr.">11 Cr to 100 Cr.</option>
                                    <option value="101 Cr to 250 Cr.">101 Cr to 250 Cr.</option>
                                    <option value="251 Cr to 1,000 Cr.">251 Cr to 1,000 Cr.</option>
                                    <option value="1,001 Cr to 5,000 Cr.">1,001 Cr to 5,000 Cr.</option>
                                    <option value="5,001 Cr to 10,000 Cr.">5,001 Cr to 10,000 Cr.</option>
                                    <option value="Above 10,000 Cr.">Above 10,000 Cr.</option>
                                </select>
                            </div>  

                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="type_of_firm">Company Type <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="type_of_firm" id="type_of_firm" (change)="getcompanytype()" 
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.type_of_firm.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Private Limited Company">Private Limited Company</option>
                                    <option value="Public Limited Company">Public Limited Company</option>
                                    <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                                    <option value="Partnerships Company">Partnerships Company</option>
                                    <option value="One Person Company">One Person Company</option>
                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                    <option value="Section 8 Company">Section 8 Company</option>
    
                                </select>
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.type_of_firm.errors">
                                    <p class="error">
                                    Company Type is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-4" id="listeddiv" style="display:none">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="company_person">Listed / Unlisted</label>
                                </div>
                                <div>
                                    <input type="radio" class="form-radio" value="yes" formControlName="listed" name="listed" id="yes_listed" /> <span class="bs-radio-label"> Yes </span> &nbsp;
                                    <input type="radio" class="form-radio" value="no" formControlName="listed" name="listed" id="no_unlisted" /> <span class="bs-radio-label"> No </span>
                                </div>                                                  
                            </div>

                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="company_registration_no">Company Registration Number</label>
                                <input type="text" class="form-control" formControlName="company_registration_no" id="company_registration_no" placeholder="ABDDHJH3647264" />
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="GST">GST</label>
                                <input type="text" class="form-control" formControlName="GST" id="GST" maxlength="15" placeholder="ABDDHJH3647264" />
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="PAN">PAN</label>
                                <input type="text" class="form-control" formControlName="PAN" id="PAN" maxlength="10" placeholder="ABDDHJH" />
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="TAN">TAN</label>
                                <input type="text" class="form-control" formControlName="TAN" id="TAN" maxlength="10" placeholder="123456" />
                            </div>

                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="industry">Industry <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="industry" id="industry" 
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.industry.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <optgroup label="AUTO & ANCILLIARY">
                                        <option value="Automobile Industry">Automobile Industry</option>
                                        <option value="Aviation Industry">Aviation Industry </option>
                                        <option value="Tractor Industry">Tractor Industry</option>
                                    </optgroup>
                                
                                    <optgroup label="FINANCIAL & BANKING">
                                        <option value="Banking Industry">Banking Industry</option>
                                        <option value="Insurance Industry">Insurance Industry</option>
                                        <option value="Mutual Fund Industry">Mutual Fund Industry</option>
                                        <option value="Real Estate Industry">Real Estate Industry</option>
                                    </optgroup>
                                
                                    <optgroup label="CONSUMER DURABLES">
                                        <option value="Furniture Industry">Furniture Industry</option>
                                        <option value="Jute Industry">Jute Industry</option>
                                        <option value="Leather Industry">Leather Industry</option>
                                        <option value="Paper Industry">Paper Industry</option>
                                        <option value="Plastic Industry">Plastic Industry</option>
                                        <option value="Rubber Industry">Rubber Industry</option>
                                        <option value="Silk Industry">Silk Industry</option>
                                        <option value="Television Industry">Television Industry</option>
                                    </optgroup>

                                    <optgroup label="TEXTILES">
                                        <option value="Textile Industry">Textile Industry</option>
                                        <option value="Garment Industry">Garment Industry</option>
                                        <option value="Weaving Industry">Weaving Industry</option>
                                    </optgroup>

                                    <optgroup label="FMCG - FAST MOVING CONSUMER GOODS">
                                        <option value="Biscuit Industry">Biscuit Industry</option>
                                        <option value="Soap Industry">Soap Industry</option>
                                    </optgroup>

                                    <optgroup label="HEALTH CARE">
                                        <option value="Bio technology Industry">Bio technology Industry</option>
                                        <option value="Health Care Industry">Health Care Industry</option>
                                        <option value="Pharamaceutical Industry">Pharamaceutical Industry</option>
                                    </optgroup>

                                    <optgroup label="INFORMATION TECHNOLOGY & COMMUNICATIION">
                                        <option value="IT Industry">IT Industry</option>
                                    </optgroup>

                                    <optgroup label="METAL & MINING">
                                        <option value="Aluminium Industry">Aluminium Industry</option>
                                        <option value="Copper Industry">Copper Industry</option>
                                        <option value="Diamond Industry">Diamond Industry</option>
                                        <option value="Granite Industry">Granite Industry</option>
                                        <option value="Mining Industry">Mining Industry</option>
                                        <option value="Pearl Industry">Pearl Industry</option>
                                        <option value="Zinc Industry">Zinc Industry</option>
                                        <option value="Steel Industry">Steel Industry</option>
                                    </optgroup>

                                    <optgroup label="OIL & GAS">
                                        <option value="IT Industry">IT Industry</option>
                                    </optgroup>

                                    <optgroup label="POWER">
                                        <option value="Power Industry">Power Industry</option>
                                    </optgroup>

                                    <optgroup label="RETAILING">
                                        <option value="Chocolate Industry">Chocolate Industry</option>
                                        <option value="Cosmetic Industry">Cosmetic Industry</option>
                                        <option value="Food Processing Industry">Food Processing Industry</option>
                                        <option value="Jewellery Industry">Jewellery Industry</option>
                                        <option value="Music Industry">Music Industry</option>
                                        <option value="Retail Industry">Retail Industry</option>
                                        <option value="Toy Industry">Toy Industry</option>
                                    </optgroup>

                                    <optgroup label="SERVICE SECTOR">
                                        <option value="Advertising Industry">Advertising Industry</option>
                                        <option value="Electronic Industry">Electronic Industry</option>
                                        <option value="Hotel Industry">Hotel Industry</option>
                                        <option value="Railway Industry">Railway Industry</option>
                                        <option value="Shipping Industry">Shipping Industry</option>
                                        <option value="Telecom Industry">Telecom Industry</option>
                                        <option value="Tourism Industry">Tourism Industry</option>
                                    </optgroup>

                                    <optgroup label="AGRICULTURE-BASED">
                                        <option value="Agricultural Industry">Agricultural Industry</option>
                                        <option value="Tobacco Industry">Tobacco Industry</option>
                                        <option value="Dairy Industry">Dairy Industry</option>
                                        <option value="Cotton Industry">Cotton Industry</option>
                                        <option value="Sugar Industry">Sugar Industry</option>
                                        <option value="Tea Industry">Tea Industry</option>
                                        <option value="Poultry Industry">Poultry Industry</option>
                                    </optgroup>

                                    <optgroup label="OTHER INDUSTRIES">
                                        <option value="Cement Industry">Cement Industry</option>
                                        <option value="Coir Industry">Coir Industry</option>
                                        <option value="Construction Industry">Construction Industry</option>
                                        <option value="Cottage Industry">Cottage Industry</option>
                                        <option value="Fashion Industry">Fashion Industry</option>
                                        <option value="Fertilizer Industry">Fertilizer Industry</option>
                                        <option value="Film Industry">Film Industry</option>
                                        <option value="Paint Industry">Paint Industry</option>
                                        <option value="Printing Industry">Printing Industry</option>
                                        <option value="Solar Industry">Solar Industry</option>
                                        <option value="Turbine Industry">Turbine Industry</option>
                                    </optgroup>

                                </select>
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.industry.errors">
                                    <p class="error">
                                    Industry is required
                                    </p>
                                </ng-container>
                            </div>                            
                                     
                            <div class="col-sm-4 form-group mt-3">
                                <label class="bs-form-label" for="employee_headcount">Company Headcount <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="employee_headcount" id="employee_headcount" 
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.employee_headcount.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Upto 100 (usually lifes will be multiply by 4 or 5)">Upto 100 (usually lifes will be multiply by 4 or 5)</option>
                                    <option value="101 to 250">101 to 250</option>
                                    <option value="251 to 500">251 to 500</option>
                                    <option value="501 to 1000">501 to 1000</option>
                                    <option value="1001 to 2000">1001 to 2000</option>
                                    <option value="2001 to 5000">2001 to 5000</option>
                                    <option value="5001 to 10000">5001 to 10000</option>
                                    <option value="10001 to 25000">10001 to 25000</option>
                                    <option value="25001 and more">25001 and more</option>
                                </select>                                
                                <ng-container
                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employee_headcount.errors">
                                    <p class="error">
                                    Company Headcount is required
                                    </p>
                                </ng-container>
                            </div>

                            <!-- <div class="col-sm-6 form-group mt-3">
                                <div class="mb-2 bs-form-label">Company Type * <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                                <input type="radio" class="form-radio" value="yes" formControlName="MNC" id="MNC" /> <span class="bs-radio-label"> MNC </span>
                                &nbsp; 
                                <input type="radio" class="form-radio" value="no" formControlName="MNC" id="MNC" /> <span class="bs-radio-label"> Non-MNC </span>
                            </div>
                            <div class="col-sm-6 form-group mt-3">
                                <div class="mb-2 bs-form-label">Company Established * <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                                <input type="radio" class="form-radio" value="startup" formControlName="company_year_of_inception" id="company_year_of_inception" checked /> <span class="bs-radio-label"> Startup ( < 5y) </span>
                                &nbsp; <input type="radio" class="form-radio" value="established" formControlName="company_year_of_inception" id="company_year_of_inception" /> <span class="bs-radio-label"> Established ( > 5y) </span>
                            </div> -->
                                                                                       
                            
                        </div>
                        
                    </div>

                    <div class="mt-3" align="center">
                        <button class="btn btn-primary" (click)="OnSubmit()">Update</button>
                    </div>
                    
                </div> 
            </form>
            <!-- End -->

        </div>        
                
</div>