<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Claims List
                <button type="button" class="btn btn-primary" (click)="downloadCSV()" role="button" style="float:right">Download Excel</button>
            </h3>            
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- List -->
            <div class="table-responsive">
                <table class="table table-sm row-border" id="excel-table" datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Claim Intimation Number</th>
                        <th>Policy Number</th>
                        <th>Member Name</th>
                        <th>Record Claim Number</th>
                        <th>Current Status</th>
                        <th>Document</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['claim_number_auto']}}</td>
                        <td>{{group['policy_number']}}</td>
                        <td>{{group['member_name']}}</td>
                        <td>
                            <button type="button" class="btn btn-primary" *ngIf="writeaccess==1" (click)="getInfoclaimnumber(group)" role="button">
                                <span *ngIf="group['claim_intimation_number'] != null && group['claim_intimation_number'] != ''">{{group['claim_intimation_number']}}</span>
                                <span *ngIf="group['claim_intimation_number'] == null || group['claim_intimation_number'] == ''">N/A</span>
                            </button>
                            <button type="button" class="btn btn-primary" *ngIf="writeaccess==0" role="button">
                                <span *ngIf="group['claim_intimation_number'] != null && group['claim_intimation_number'] != ''">{{group['claim_intimation_number']}}</span>
                                <span *ngIf="group['claim_intimation_number'] == null || group['claim_intimation_number'] == ''">N/A</span>
                            </button>
                        </td>
                        <!-- <td>
                            <button type="button" class="btn btn-primary" *ngIf="writeaccess==1" (click)="getInfoStatus(group)" role="button">Update</button>
                        </td> -->
                        <td>{{group['claim_status']}}</td>
                        <td>
                            <div id="document" (click)="document(group['policy_id'],group['id'])" style="color:#2e7ce2; cursor: pointer;">Document ({{group['doc_count']}})</div>
                        </td>
                        <td style="width: 15%" align="center">
                            <!-- <img src="../../../assets/img/common icons/edit_new.png" *ngIf="writeaccess==1" id="edituser" (click)="edituser(group)" role="button" title="Edit" /> -->
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="edituser(group)" role="button" title="Edit" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            

           <!-- Claim Number -->
           <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleStatus}">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Modify Record Claim Number</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="bs-form-label" for="status">Record Claim Number</label>                                    
                                <input type="text" class="form-control" formControlName="status" id="status" placeholder="Enter Record Claim Number" />
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="submitclaimnumber()" role="button">Submit</button>
                        <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                    </div>
                </div>
            </div>
        </div>

    
        </div>
        <app-footer></app-footer>
    </div>
</div>