<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3">

      <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
        style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

      <h3 class="bs-title">Dash<span class="spanbs-title">board</span></h3>
      <hr class="mt-0" style="height: 0px">

      <div class="col-sm-12">

        <div class="row m-0 p-0 mb-3" style="background: #fff;">

          <div class="col-sm-2">
            <div class="row"
              style="padding: 5px; box-shadow: 0px 0px 5px 1px; border-radius: 4px; margin-left: 5px; margin-right: 5px;">
              <div class="col-sm-4 p-1">
                <img src="../../../assets/img/dashboard/claim.png" style="width: 35px; border-radius: 4px;" />
              </div>
              <div class="col-sm-8 p-1">
                <label class="bs-form-label" style="font-size: 14px;"><b> Claims</b></label> <br>
                <label class="bs-form-label" style="font-size: 14px; color: #000;"><b> 488</b></label>
              </div>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="row"
              style="padding: 5px; box-shadow: 0px 0px 5px 1px; border-radius: 4px; margin-left: 5px; margin-right: 5px;">
              <div class="col-sm-4 p-1">
                <img src="../../../assets/img/dashboard/claim.png" style="width: 35px; border-radius: 4px;" />
              </div>
              <div class="col-sm-8 p-1">
                <label class="bs-form-label" style="font-size: 14px;"><b> Premium</b></label> <br>
                <label class="bs-form-label" style="font-size: 14px; color: #000;"><b> 4,20,18,916</b></label>
              </div>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="row"
              style="padding: 5px; box-shadow: 0px 0px 5px 1px; border-radius: 4px; margin-left: 5px; margin-right: 5px;">
              <div class="col-sm-4 p-1">
                <img src="../../../assets/img/dashboard/claim.png" style="width: 35px; border-radius: 4px;" />
              </div>
              <div class="col-sm-8 p-1">
                <label class="bs-form-label" style="font-size: 14px;"><b> Incident</b></label> <br>
                <label class="bs-form-label" style="font-size: 14px; color: #000;"><b> 9.85%</b></label>
              </div>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="row mx-1" style="padding: 5px; box-shadow: 0px 0px 5px 1px; border-radius: 4px;">
              <div class="col-sm-4 p-1">
                <img src="../../../assets/img/dashboard/claim.png" style="width: 35px; border-radius: 4px;" />
              </div>
              <div class="col-sm-8 p-1">
                <label class="bs-form-label" style="font-size: 14px;"><b> Incurred</b></label> <br>
                <label class="bs-form-label" style="font-size: 14px; color: #000;"><b> 3,47,61,644</b></label>
              </div>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="row mx-1" style="padding: 5px; box-shadow: 0px 0px 5px 1px; border-radius: 4px;">
              <div class="col-sm-4 p-1">
                <img src="../../../assets/img/dashboard/claim.png" style="width: 35px; border-radius: 4px;" />
              </div>
              <div class="col-sm-8 p-1">
                <label class="bs-form-label" style="font-size: 14px;"><b> Pay Ration</b></label> <br>
                <label class="bs-form-label" style="font-size: 14px; color: #000;"><b> 73%</b></label>
              </div>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="row mx-1" style="padding: 5px; box-shadow: 0px 0px 5px 1px; border-radius: 4px;">
              <div class="col-sm-4 p-1">
                <img src="../../../assets/img/dashboard/claim.png" style="width: 35px; border-radius: 4px;" />
              </div>
              <div class="col-sm-8 p-1">
                <label class="bs-form-label" style="font-size: 14px;"><b> Claim</b></label> <br>
                <label class="bs-form-label" style="font-size: 14px; color: #000;"><b> 108%</b></label>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-9 col-md-12 col-sm-12">            
          </div>

          <div class="col-lg-3 col-md-12 col-sm-12" style="background: #DFDFEC; padding: 10px; border-radius: 4px;">

            <div style="height: 200px; overflow-y: auto; overflow-x: hidden; padding: 10px;">
              <h6 style="margin-left: -10px;"><b>Latest News</b> <span style="float: right; font-size: 10px;">Show
                  More</span></h6>

              <div class="row" style="background: #fff; border-radius: 4px;" *ngFor="let newsres of NewsArray;">

                <div class="col-sm-2" style="padding: 10px;">
                  <img src="../../../assets/img/image 20.png" style="width: 40px; border-radius: 4px;" />
                </div>
                <div class="col-sm-10" style="padding-left: 15px;">
                  <h6 class="bs-form-label" style="font-size: 11px; margin-bottom: 0px;">{{newsres['news_title']}}
                    <!-- <span style="float: right;">{{newsres['article_by']}}</span> -->
                  </h6>
                  <label class="bs-form-label"
                    style="font-size: 11px; color: #000; line-height: 15px;"><b>{{newsres['news_description']}}</b></label>
                </div>
                <hr>
              </div>
            </div>

            <div style="height: 200px; overflow-y: auto; overflow-x: hidden; padding: 10px; margin-top: 15px;">
              <h6 style="margin-left: -10px;"><b>Announcement</b> <span style="float: right; font-size: 10px;">Show
                  More</span></h6>

              <div class="row" style="background: #fff; border-radius: 4px;" *ngFor="let result of AnnoucementArray;">

                <div class="col-sm-12" style="padding-left: 15px;">
                  <label class="bs-form-label"
                    style="line-height: 15px; margin-bottom: 15px;">{{result['article_by']}}</label>
                  <label class="bs-form-label"
                    style="font-size: 11px; color: #000; line-height: 15px; margin-bottom: 15px"><b>{{result['article_title']}}</b></label>
                  <label class="bs-form-label"
                    style="line-height: 15px; margin-bottom: 15px; letter-spacing: normal;">{{result['article_description']}}</label>
                </div>
                <hr>
              </div>
            </div>

          </div>
        </div>


      </div>

    </div>
  </div>

</div>