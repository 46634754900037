import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})

export class ListingComponent implements OnInit {
  
  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayBroker: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  displayStyleDelete = "none";
  displayStyleStatus = "none";
  displayStyleError = "none";
  policy_id:any;
  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'claim_intimation';

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  statusClaimId: any;
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
    }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getBroker();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      name: new FormControl('', Validators.required),
      contact_1: new FormControl('', Validators.required),
      email_1: new FormControl('', Validators.required),      
      broker_idfk:new FormControl(''),
      address_line_1:new FormControl('', Validators.required)
    });

    // Form element defined below
    this.EditInfo = this.fb.group({      
      name_edit: new FormControl('', Validators.required),
      contact_1_edit: new FormControl('', Validators.required),
      email_1_edit: new FormControl('', Validators.required),
      broker_idfk_edit:new FormControl(''),
      address_line_1_edit:new FormControl('', Validators.required),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "contact_1":this.AddInfo.controls['contact_1'].value,
        "email_1":this.AddInfo.controls['email_1'].value,
        "name":this.AddInfo.controls['name'].value,
        "broker_idfk":this.AddInfo.controls['broker_idfk'].value,
        "address_line_1":this.AddInfo.controls['address_line_1'].value,
        "client_id":localStorage.getItem('client_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          


    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['contact_1_edit'].setValue(result.data[0]['contact_1']);
        this.EditInfo.controls['email_1_edit'].setValue(result.data[0]['email_1']);
        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['broker_idfk_edit'].setValue(result.data[0]['broker_idfk']);
        this.EditInfo.controls['address_line_1_edit'].setValue(result.data[0]['address_line_1']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['tpa_id']);        

      }, (error) => {          
                
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
    
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "contact_1":this.EditInfo.controls['contact_1_edit'].value,
        "email_1":this.EditInfo.controls['email_1_edit'].value,
        "name":this.EditInfo.controls['name_edit'].value,
        "broker_idfk":this.EditInfo.controls['broker_idfk_edit'].value,
        "address_line_1":this.EditInfo.controls['address_line_1_edit'].value,
        "client_id":localStorage.getItem('client_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
      .subscribe((result: any) => {
          
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none'); 
          this.closePopup();         
          this.validmsg = result.message;
          // this.getInfo();
          // this.dtTrigger.unsubscribe();

          window.location.reload();

        }else {
            this.show = false;
        }
      }, (error) => {          
        this.closePopup();         
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['tpa_id']);
        
      }, (error) => {          
                
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['tpa_id']);
        
      }, (error) => {          
                
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo() {    
    this.dataservice.getInfo()
      .subscribe((result: any) => {

        this.InfoArray = result.data;
        console.log(this.InfoArray);
        this.dtTrigger.next();       
        this.assignrights();

      }, (error) => {

        this.InfoArray = [];

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });

  }
  getInfoclaimnumber(res:any){

    this.displayStyleStatus = "block";

    this.statusClaimId = res.id;
    this.policy_id = res.policy_id ;

    $('#status').val(res.claim_intimation_number);

  }
  submitclaimnumber(){
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "claim_id": this.statusClaimId,
      "claim_number": $('#status').val(),
      "policy_id": this.policy_id,
    }

    this.dataservice.onSubmitClaimNumber(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {

        this.show = false;
        // console.log(result);
        this.displayStyleStatus = "none";
        // this.getInfo();
        // this.dtTrigger.next();
        window.location.reload();

      }, (error) => {

        this.displayStyleStatus = "none";

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });
  }

  document(document_id: any, claim_id: any) {
    localStorage.setItem('document_id', document_id);
    localStorage.setItem('claim_id', claim_id);
    this.route.navigateByUrl('claim-document');
  }
  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }

    }
  }

  edituser(res: any) {

    localStorage.setItem('claim_res_data',JSON.stringify(res));
    this.route.navigate(['executive-user/claim-intimation']);
  }

  downloadCSV() {    

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),     
    }

    this.dataservice.download(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {

        let finalArr:any = result.data;

        // for(var i = 0; i < finalArr.length; i++){
    
        //   delete finalArr[i]['id'];
        //   delete finalArr[i]['policy_id'];
        //   delete finalArr[i]['is_active'];
    
        // }
    
        console.log(finalArr);    
    
        /* pass here the table id */
        let element = document.getElementById('excel-table');
        const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalArr);
    
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        /* save to file */  
        XLSX.writeFile(wb, 'Claim_Data.xlsx');

      }, (error) => {    

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });    

  }

  getBroker(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getBroker()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    
    this.displayStyleStatus = "none";
    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }
  
}
   