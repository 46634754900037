import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ClientCreationService } from './client-creation';
// import data from '../../assets/jsonfiles/country.json';


@Component({
  selector: 'app-family-enrollment',
  templateUrl: './family-enrollment.component.html',
  styleUrls: ['./family-enrollment.component.css']
})
export class FamilyEnrollmentComponent implements OnInit {

  public basicInfo:FormGroup;
  public Insuredinfo:FormGroup;
  public Nomineeinfo:FormGroup;
  public enrollmentinfo:FormGroup;
  private stepper: Stepper;

  basicInfoSubmitted: boolean = false;
  InsuredinfoSubmitted: boolean = false;
  NomineeinfoSubmitted: boolean = false;
  enrollmentinfoSubmitted: boolean = false;
  
  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');
  phonenumber = localStorage.getItem('mobile_no');

  validmsg: any;
  listed: any;

  displayStyleThankyou = "none";

  /******************************/
  policyType: any = [];
  policySubType: any = [];
  Insurer:any = [];
  TPA:any = [];
  employeeTabView:any = [];
  brokerBranchName:any = [];
  companyDetails:any = [];
  entityDetails:any = [];
  cnt = 1;
  hr_info_arr: any = {};
  midterm_enrollement:any = [];
  co_insurer_perc_cnt: any = 0;
  co_insurer_perc_sum: any = 0;
  /******************************/

  /*******************************/
  allowedArr: any = {};
  cnt_allowed = 1;
  Membertype: any = [];
  coverType: any = [];
  SIType: any = [];
  SIBasis: any = [];
  premiumBasis: any = [];
  Spousemidterm: any = [];
  memberType:any = [];
  /*******************************/

  /*******************************/
  roleDesignationType: any = [];
  emailID: any = [];
  /*******************************/

  documentType: any = [];
  document_file: any;
  policy_feature_document: any;
  uploadcnt = 1;
  uploadExtraArr: any = {};

  spousedata:any = {};
  ChilData: any = {};

  /*******************************/

  RowCount:any = 0;
  GenerateSICount:any = [];
  diffArr: any = [];
  diffval: any;
  cnt_custom: any = 1;
  CustomArr: any = {};

  /*******************************/

  cnt_add_benefit: any = 1;
  add_benefit_arr: any = {};

  cnt_broker: any = 1;
  brokerArr_step4: any = {};

  cnt_employer: any = 1;
  employer_step4Arr: any = {};

  cnt_tpa: any = 1;
  tpa_step4Arr: any = {};

  installmentArr: any = [];

  entityDetails_e:any = [];
  entityDetails_tpa:any = [];

  relationDetails:any = [];

  displayinceptionmsg = "none";
  displayfamilymsg = "none";
  Standardmsgtoggle = "none";
  Validation_Error_message:any;

  policysamplefile:any;
  downloadbuttontooltip:any;

  MonthlyEquitible:any = [];

  employee_file:any;
  emmployeefile_id:any = 0;

  stepcomplete: any = '';

  Relation:any = [];
  Employepolicyes:any = [];

  displayStylePolicyFeatures = "none";
  displayStyleClosePolicy = "none";

  PFxcluded:any = [];
  PFincluded:any = [];
  PFoptional:any = [];
  ClosePolicyArray:any = [];
  openpolicies:any = [];

  constructor(private fb: FormBuilder, private clientCreationService: ClientCreationService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false' || this.resstatus == null) {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    localStorage.setItem('master_policies_id', '0');

    $(".finishbtn").css("pointer-events", 'none');
    $(".finishbtn").css("background", '#00000042');

    this.getRelation();
    this.getemployeepolicies();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Define Stepper
    const stepperElement = document.querySelector('#stepper1');
    if(stepperElement !== null){
    this.stepper = new Stepper(stepperElement , {
          linear: false,
          animation: true
        })
    }

    // Step 1
    this.basicInfo = this.fb.group({      
      employer_name: new FormControl(''),
      employer_dob: new FormControl(''),
      employer_joining: new FormControl(''),
      employer_email: new FormControl(''),
      employer_contact_number: new FormControl(''),
      employer_alternate_email: new FormControl(''),
      employer_emergency_person: new FormControl(''),
      employer_emergency_contact_number: new FormControl(''),
    });

    // Step 2
    this.Insuredinfo = this.fb.group({
      relation_with_employee: new FormControl(''),
      gender: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      insured_contact_number: new FormControl(''),
      insured_email: new FormControl(''),
      insured_dob: new FormControl(''),
    });

    // Step 3
    this.Nomineeinfo = this.fb.group({
      nominee_relation_with_employee: new FormControl(''),      
      nominee_first_name: new FormControl(''),
      nominee_last_name: new FormControl(''),
      nominee_share: new FormControl(''),      
      nominee_dob: new FormControl(''),      
    });

    // Step 4
    this.enrollmentinfo = this.fb.group({
      agree: new FormControl(''),      
    });   

  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  /************ ALL GET Step 1 ***************/
  getRelation(){
    this.clientCreationService.getRelation()
      .subscribe((result) => {
        
        this.Relation = result.data;

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }
  
  getemployeepolicies(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.clientCreationService.getemployeepolicies(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.Employepolicyes = result.data;   
        
        for(var i = 0; i<result.data.length; i++){
          if(result.data[i].enrolement_btn == 'OPEN'){
            this.openpolicies.push(result.data[i]);
          }
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  getemployeepoliciesmembers(id:any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": id            
    };

    this.clientCreationService.getemployeepoliciesmembers(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.ClosePolicyArray = result.data;          
        this.displayStyleClosePolicy = 'block';
        

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  get getInsuredinformationFormControls() {
    return this.Insuredinfo.controls;
  }

  get getNomineeinformationFormControls() {
    return this.Nomineeinfo.controls;
  }

  get getenrollmentinformationFormControls() {
    return this.enrollmentinfo.controls;
  }    

  checkshare(){
    let val:any = this.Nomineeinfo.controls['nominee_share'].value;
    if(val > 100){
      $('#nominee_share').val('');
    }
  }

  // Stepper Next Event
  next(step: number) {
    if (step == 1) {
      this.basicInfoSubmitted = true;
      if (this.basicInfoSubmitted && this.basicInfo.valid) {
        this.stepper.next();
        this.onSubmit_first();
        this.stepcomplete = 'step1';
      }
    }
    if (step == 2) {
      this.InsuredinfoSubmitted = true;
      if (this.InsuredinfoSubmitted && this.Insuredinfo.valid) {
        this.stepper.next();
        this.onSubmit_second();
        this.stepcomplete = 'step2';
      }
    }
    if (step == 3) {
      this.NomineeinfoSubmitted = true;
      if (this.NomineeinfoSubmitted && this.Nomineeinfo.valid) {        
        this.stepper.next();
        this.onSubmit_third();
        this.stepcomplete = 'step3';
      }
    }
    if (step == 4) {
      this.enrollmentinfoSubmitted = true;
      if (this.enrollmentinfoSubmitted && this.enrollmentinfo.valid) {       
        this.stepper.next();
        this.onSubmit_fourth();
        this.stepcomplete = 'step4';
      }
    }    
  }     

  // Stepper Previous Event
  previous() {
    this.stepper.previous();
  }  

  // Submit 1
  onSubmit_first() {
        
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),    

      "employer_name": this.basicInfo.controls['employer_name'].value,
      "employer_dob": this.basicInfo.controls['employer_dob'].value,
      "employer_joining": this.basicInfo.controls['employer_joining'].value,
      "employer_email": this.basicInfo.controls['employer_email'].value,
      "employer_contact_number": this.basicInfo.controls['employer_contact_number'].value,
      "employer_alternate_email": this.basicInfo.controls['employer_alternate_email'].value,
      "employer_emergency_person": this.basicInfo.controls['employer_emergency_person'].value,
      "employer_emergency_contact_number": this.basicInfo.controls['employer_emergency_contact_number'].value,                  
    };

    console.log(postdata);

    this.clientCreationService.Step1_store(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          localStorage.setItem('master_policies_id', result.data.master_policies_id);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 2
  onSubmit_second() {    

    // for(var i = 1; i < this.cnt; i++){
    //   this.hr_info_arr[i] = {co_insurer: $('#co_insurer_'+i).val(), co_insurer_perc:$('#co_insurer_perc_'+i).val()};
    // }    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      // "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      // "master_policies_id": localStorage.getItem('master_policies_id'),  
      
      "relation_with_employee": this.Insuredinfo.controls['relation_with_employee'].value,
      "gender": this.Insuredinfo.controls['gender'].value,
      "first_name": this.Insuredinfo.controls['first_name'].value,
      "last_name": this.Insuredinfo.controls['last_name'].value,
      "insured_contact_number": this.Insuredinfo.controls['insured_contact_number'].value,
      "insured_email": this.Insuredinfo.controls['insured_email'].value,
      "insured_dob": this.Insuredinfo.controls['insured_dob'].value,
    };

    console.log(postdata);

    this.clientCreationService.Step2_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 3
  onSubmit_third() {

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      // "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      // "master_policies_id": localStorage.getItem('master_policies_id'),

      "nominee_relation_with_employee": this.Nomineeinfo.controls['nominee_relation_with_employee'].value,
      "nominee_first_name": this.Nomineeinfo.controls['nominee_first_name'].value,
      "nominee_last_name": this.Nomineeinfo.controls['nominee_last_name'].value,
      "nominee_share": this.Nomineeinfo.controls['nominee_share'].value,
      "nominee_dob": this.Nomineeinfo.controls['nominee_dob'].value,
    };

    console.log(postdata);

    this.clientCreationService.Step3_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  // Submit 4
  onSubmit_fourth() {

    let val:any = $("input[name='agree']:checked").val();
    let valagree:any;
    if(val == undefined){
      valagree = 0;
    }else{
      valagree = 1;
    }
   
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      // "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      // "master_policies_id": localStorage.getItem('master_policies_id'), 
      
      "agree": valagree,
    }

    console.log(postdata);

    this.clientCreationService.Step4_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }  

  checkagree(){
    let val:any = $("input[name='agree']:checked").val();
    if(val == undefined){
      $(".finishbtn").css("pointer-events", 'none');
      $(".finishbtn").css("background", '#00000042');
    }else{
      $(".finishbtn").css("pointer-events", 'auto');
      $(".finishbtn").css("background", 'var(--bs-btn-bg)');      
    }
  }

  closePopup() {
    this.displayinceptionmsg = "none";
    this.displayfamilymsg = "none";
    this.Standardmsgtoggle="none"
    this.displayStylePolicyFeatures = "none";  
    this.displayStyleClosePolicy = 'none';
  }  

  /***** Add Field *****/
  addBenefitField(){

    let html = `
    <div id="this_benefit_row_`+this.cnt_allowed+`" class="row">
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="member_type_`+this.cnt_allowed+`">Member type </label>
          <select class="form-select" formControlName="member_type_`+this.cnt_allowed+`" id="member_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Member Type</option>
          </select>
      </div>
      <div class="col-sm-2 form-group mt-3">
          <div class="form-check" style="margin-top: 30px;">
              <input type="checkbox" formControlName="no_age_`+this.cnt_allowed+`" id="no_age_`+this.cnt_allowed+`" class="form-check-input ng-valid ng-dirty ng-touched" style="margin-left: 0px; margin-top: 0px; cursor: pointer;">
              <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
          </div>
      </div>
      <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
          <label class="bs-form-label" for="min_age_`+this.cnt_allowed+`">Min Age <span class="redstar">*</span></label>
          <input type="text" class="form-control" formControlName="min_age_`+this.cnt_allowed+`" id="min_age_`+this.cnt_allowed+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
          <label class="bs-form-label" for="max_age_`+this.cnt_allowed+`">Max Age <span class="redstar">*</span></label>
          <input type="text" class="form-control" formControlName="max_age_`+this.cnt_allowed+`" id="max_age_`+this.cnt_allowed+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt_allowed+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newbenifitField').append(html);

    for(var i=0; i<this.Membertype.length; i++){
      var val = '#' + 'member_type_'+ this.cnt;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Membertype[i]['id'])
          .text(this.Membertype[i]['name']));
    }

    // this.Insuredinfo.addControl("member_type_"+this.cnt_allowed, new FormControl(''));
    // this.Insuredinfo.addControl("no_age_"+this.cnt_allowed, new FormControl(''));
    // this.Insuredinfo.addControl("min_age_"+this.cnt_allowed, new FormControl(''));
    // this.Insuredinfo.addControl("max_age_"+this.cnt_allowed, new FormControl(''));

    this.allowedArr[this.cnt_allowed] = {member_type: "", no_age: "", min_age: "", max_age: ""};

    let flag = this;
    $("#benefit_row_"+this.cnt_allowed).on('click',function(e) {
      flag.removeRow(e);
    });

    $("#no_age_"+this.cnt_allowed).on('change',function(e) {
      flag.isnoage(e);
    });

    $("#min_age_"+this.cnt_allowed).on('keyup',function(e) {
      flag.isnumberBenefit(e);
    });

    $("#max_age_"+this.cnt_allowed).on('keyup',function(e) {
      flag.isnumberBenefit(e);
    });

    this.cnt_allowed++;

  }

  removeRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_benefit_row_' + id;
    $(idstr).remove();

  }

  isnumberBenefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  isnoage(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    let valchecked:any = $("#" + str).is(":checked");
    var value = '.noAgeDiv_' + id;
    if(valchecked == false){
      $(value).css('display', 'block');
    }else{
      $(value).css('display', 'none');
    }
  }
  /***************************************/

  // File upload
  member_data_change(event: any) {
    if (event.target.files.length > 0) {

      this.document_file = event.target.files[0];
      console.log(event.target.files[0])

      $("#custom-file-upload_document_file").empty();
      $('#custom-file-upload_document_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_document_file').empty();
      $('#small_document_file').html('');
      $('#small_document_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }
    
  // Toggle button
  radio_yesno(val:any, text: any){

    if(text == 'yes'){

      $('#'+val+'_'+text).css('background', '#40189D');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_no').css('background', '#f1f1f1');
      $('#'+val+'_no').css('color', '#000');

    }else{

      $('#'+val+'_'+text).css('background', '#9e9e9e');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_yes').css('background', '#f1f1f1');
      $('#'+val+'_yes').css('color', '#000');

    }
  }  

  navigateto(policy_id:any, group:any){
    localStorage.setItem('policy_id_details_family', policy_id);           
    localStorage.setItem('policy_id_details_family_res', JSON.stringify(this.openpolicies));
    this.route.navigate(['/employeruser-dashboard/family-enrollment/policy-details-family']);
  }

  showPolicyFeatures(res:any){
    this.displayStylePolicyFeatures = 'block';

    console.log(res);
    this.PFxcluded = res.pf_excluded;
    this.PFincluded = res.pf_included;
    this.PFoptional = res.pf_optional;

  }  

  onHover(isHovered: boolean, i:any): void {
    if (isHovered) {
      // console.log('Mouse entered');
      $('#hoverDiv_'+i).css('position','absolute');
      $('#hoverDiv_'+i).css('display','inline-block');
    } else {
      // console.log('Mouse left');
      $('#hoverDiv_'+i).css('display','none');
    }
  }

}
