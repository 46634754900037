<div class="back">
    <div class="div-center">
      <div class="content row m-0">
  
        <div class="col-sm-7 p-0">
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../assets/img/login/bg-image-1.png" class="d-block img-fluid">
                  <div class="carousel-caption d-md-block">
                    <h5>Heading 1</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src="../../../assets/img/login/bg-image-2.png" class="d-block img-fluid">
                  <div class="carousel-caption d-md-block">
                    <h5>Heading 2</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
                <!-- <div class="carousel-item">
                  <img src="../../../assets/img/login/loginslideimage1_400x300.jpeg" class="d-block img-fluid">
                  <div class="carousel-caption d-none d-md-block">
                    <h5>Heading 1</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div> -->
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
      </div>
      <div class="col-sm-5 p-4 pl-0">
          <div align="center">
              <a routerLink="/home"><img class="" src="../../../assets/logo.png" style="width: 40px; cursor: pointer;" class="img-fluid" alt="logo" /></a>
          </div>
          <div class="mt-3">
              <h4 class="text-center">Sign Up</h4>
              <!-- <label class=""> Lorem ipsum dolor sit amet consectetur</label> -->
              <form [formGroup]="registerForm" class="mt-3">                   
                  <div class="mb-2">
                      <label for="username" class="form-label">Username</label>
                      <input type="email" class="form-control" id="username" formControlName="username" placeholder="Email / Username" >
                  </div>
                  <div class="mb-2">
                      <label for="pwd" class="form-label">Password</label>
                      <input type="password" class="form-control" id="pwd" formControlName="pwd" placeholder="Password">
                  </div>
                  <div class="mb-2">
                    <label for="pwd_confirm" class="form-label">Confirm Password</label>
                    <input type="password" class="form-control" id="pwd_confirm" (keyup)="checkpwd()" placeholder="Confirm Password">
                  </div>
                  <div>
                    <a style="text-decoration: none;">
                      <button type="submit" id="registerbtn" (click)="register()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                        Create Account
                      </button>
                    </a>
                  </div>
                  <div class="mt-2 mb-3"
                      style="width: 100%; height: 14px; border-bottom: 1px solid #ced4da; text-align: center">
                      <span style="background-color: #fff; padding: 0 10px;">
                          Or
                      </span>
                  </div>
                  <div class="my-1 w-100">
                      <a class="btn btn-outline-dark w-100" href="/users/googleauth" role="button"
                          style="font-size:14px; text-transform:none; border: solid 1px #ced4da; padding: 6px 20px 6px 20px; border: 1px solid #AEAAAE; border-radius: 4px;">
                          <img width="20px" style="margin-bottom:3px; margin-right:5px;" alt="Google sign-in"
                              src="../../../assets/img/common icons/gogglelogo.png" />
                          Sign In with Google
                      </a>
                  </div>
                  <div class="my-3 w-100 form-label" style="text-align: center;"> Already have an Account? <a routerLink="/survey-login">Login</a>
                  </div>
              </form>                                        
              <div id="validmsg" class="red">{{ validmsg }}</div>
  
          </div>
      </div>
      
      </div>    
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Thank You !</h4>
              </div>                
              <div class="modal-body">                                
                  Your are registered. We have assigned you plan 'BASIC' for now you can upgrade that afterwards.
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
              </div>
          </div>
      </div>
  </div>

  </div>