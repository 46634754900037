<div id="employeeDependency" class="bs-stepper bg-white my-4 mb-5 rounded p-3">

    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
    
    <form [formGroup]="employeeDependency">
        <h3 class="bs-title mb-4">Employee Dependences
            <img src="../../assets/img/common icons/opd_download.png" (click)="ondownload('opd')" style="float:right; margin-right: 10px; width: 40px; cursor: pointer" />
            <img src="../../assets/img/common icons/gtl_download.png" (click)="ondownload('gtl')" style="float:right; margin-right: 10px; width: 40px; cursor: pointer" />
            <img src="../../assets/img/common icons/gpa_download.png" (click)="ondownload('gpa')" style="float:right; margin-right: 10px; width: 40px; cursor: pointer" />
            <img src="../../assets/img/common icons/gmc_download.png" (click)="ondownload('gmc')" style="float:right; margin-right: 10px; width: 40px; cursor: pointer" />
        </h3>
        <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
            <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
            <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <hr style="margin-top: -5px;">
        <div class="row">
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for="file">Choose File <span class="redstar">*</span></label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event)" id="file" enctype="multipart/form-data" 
                    [ngClass]="{'control-red': documentUploadSubmitted && getemployeeDetailsFormControls.file.errors}"
                    required />
                    <ng-container
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.file.errors">
                        <p class="error"
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.file.errors.required">
                        Please upload file
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <!-- <label class="bs-form-label" for="active_member_data_report_date">Active member data report date <span class="redstar">*</span></label> -->
                    <label class="bs-form-label" for="active_member_data_report_date">Report date <span class="redstar">*</span></label>
                    <input type="date" class="form-control" formControlName="active_member_data_report_date" id="active_member_data_report_date" 
                    [ngClass]="{'control-red': documentUploadSubmitted && getemployeeDetailsFormControls.active_member_data_report_date.errors}"
                    required />
                    <ng-container
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.active_member_data_report_date.errors">
                        <p class="error"
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.active_member_data_report_date.errors.required">
                        Active member data report date is required
                        </p>
                    </ng-container>
                </div>                
            </div>
            <div class="col-lg-3" *ngIf="type_name != 'client'">
                <div class="form-group">
                    <label class="bs-form-label" for="client_id">Select Client <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="client_id" id="client_id" (change)="getClient()"
                    [ngClass]="{'control-red': documentUploadSubmitted && getemployeeDetailsFormControls.client_id.errors}"
                    required>
                        <option value="" selected>Select</option>
                    </select>
                    <ng-container
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.client_id.errors">
                        <p class="error"
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.client_id.errors.required">
                        Client is required
                        </p>
                    </ng-container>
                </div>                
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for="active_member_data_report_date">Select Product <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="select_product" id="select_product" (change)="getpolicylistbyproduct()"
                    [ngClass]="{'control-red': documentUploadSubmitted && getemployeeDetailsFormControls.select_product.errors}"
                    required>
                        <option value="" selected>Select</option>
                    </select>
                    <!-- <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i> -->
                    <ng-container
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.select_product.errors">
                        <p class="error"
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.select_product.errors.required">
                        Select Product is required
                        </p>
                    </ng-container>
                </div>                
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <label class="bs-form-label" for="active_member_data_report_date">Select Policy <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="select_policy" id="select_policy" (change)="getpolicylistbyproduct()"
                    [ngClass]="{'control-red': documentUploadSubmitted && getemployeeDetailsFormControls.select_policy.errors}"
                    required>
                        <option value="" selected>Select</option>                        
                        <option *ngFor="let group of policylistbyproductArr;" [value]="group.client_product_id">{{group.proposal_name}}</option>
                    </select>
                    <ng-container
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.select_policy.errors">
                        <p class="error"
                        *ngIf="documentUploadSubmitted && getemployeeDetailsFormControls.select_policy.errors.required">
                        Select Policy is required
                        </p>
                    </ng-container>
                </div>                
            </div>
            <!-- <div class="col-lg-1">
                <div class="form-group">
                    <label class="bs-form-label" for=""></label>
                    <input type="submit" class="form-control" (click)="onSkip()" id="skipbtn" value="Skip" />
                </div>
            </div> -->
            <!-- <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for=""></label>
                    <input type="submit" class="form-control" (click)="ondownload()" id="downloadbtn" value="Download Sample File" />
                </div>
            </div> -->
            <div class="col-lg-2">
                <div class="form-group">
                    <label class="bs-form-label" for="savebtn"></label>
                    <input type="submit" class="form-control" (click)="onSubmit()" id="savebtn" value="Save" />
                </div>
            </div>            
        </div>
    </form>

    <div class="table-responsive mt-4">
        <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Emp Name</th>
                <th>Emp Code</th>
                <th>Company Name</th>
                <th>Product Name</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of InfoArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['emp_name']}}</td>                
                   <td style="width: 15%">{{group['emp_code']}}</td>
                   <td>{{group['company_name']}}</td>
                   <td>{{group['product_name']}}</td>
                   <td style="width: 15%" align="center">
                    <img src="../../../assets/img/common icons/eye.png" id="viewuser" (click)="openPopupView(group)" class="img-fluid" role="button" style="width:20px; margin-right: 10px" title="View" />
                    <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" class="img-fluid" role="button" style="width:20px; margin-left: 10px" title="Edit" />
                    <!-- <img src="../../../assets/img/common icons/trash.png" id="deleteuser" role="button" class="img-fluid" style="width:20px" title="Delete" /> -->
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_view}" style="overflow:hidden">
        <div class="modal-dialog modal-dialog_view" role="document">
            <div class="modal-content model-content-view">
                <div class="modal-header">
                    <h4 class="modal-title">
                        View Employee Dependency
                        <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;
                        right: 10px;"> &#10006;</button>
                    </h4>
                </div>
                <div class="modal-body modal-body_view">
                    
                    <div class="table-responsive mt-4">
                        <table class="table table-sm row-border" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2">
                            <thead>
                            <tr>
                                <th style="width:5%">SrNo</th>
                                <th>Full Name</th>
                                <th>SI Amount</th>
                                <th>Relation</th>
                                <th>DOB</th>
                                <th style="text-align: center;">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of InfoArray_dependency; let i = index">
                                    <td style="width:5%">{{i+1}}</td>
                                <td>{{group['full_name']}}</td>                
                                <td>{{group['sum_insured_amount']}}</td>
                                <td>{{group['relation']}}</td>
                                <td>{{group['date_of_birth']}}</td>
                                <td style="width: 15%" align="center">
                                    <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit_dep(group)" class="img-fluid" role="button" style="width:20px; margin-left: 10px" title="Edit" />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_edit}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit</h4>
            </div>
            <div class="modal-body">
                
              <form [formGroup]="EditInfo">                    
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="emp_code_edit">Employee Code <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="emp_code_edit" id="emp_code_edit" placeholder="Employee Code"
                            [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.emp_code_edit.errors}"
                            required />
                            <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_code_edit.errors">
                                <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_code_edit.errors.required">
                                    Employee Code is required
                                </p>
                            </ng-container>
                        </div>
                    </div>            
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="emp_name_edit">Name <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="emp_name_edit" id="emp_name_edit" placeholder="Name"
                            [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.emp_name_edit.errors}"
                            required />
                            <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_name_edit.errors">
                                <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_name_edit.errors.required">
                                    Name is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="emp_date_of_joining_edit">DOB <span class="redstar">*</span></label>
                            <input type="date" class="form-control" formControlName="emp_date_of_joining_edit" id="emp_date_of_joining_edit" placeholder="DOB"
                            [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.emp_date_of_joining_edit.errors}"
                            required />
                            <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_date_of_joining_edit.errors">
                                <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_date_of_joining_edit.errors.required">
                                    DOB is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="emp_date_of_birth_edit">Date of joining <span class="redstar">*</span></label>
                            <input type="date" class="form-control" formControlName="emp_date_of_birth_edit" id="emp_date_of_birth_edit" placeholder="Date of joining"
                            [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.emp_date_of_birth_edit.errors}"
                            required />
                            <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_date_of_birth_edit.errors">
                                <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.emp_date_of_birth_edit.errors.required">
                                    Date of joining is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                              <option [value]="1">Active</option>
                              <option [value]="0">Deactive</option>
                          </select>                    
                      </div>
                  </div>
                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                </div>
            </form>
  
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_edit_dep}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit</h4>
            </div>
            <div class="modal-body">
                
              <form [formGroup]="EditInfo_dep">                    
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="full_name_edit_dep">Full Name <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="full_name_edit_dep" id="full_name_edit_dep" placeholder="Full Name"
                            [ngClass]="{'control-red': EditInfoSubmitted_dep && getEditInformationFormControls_dep.full_name_edit_dep.errors}"
                            required />
                            <ng-container
                                *ngIf="EditInfoSubmitted_dep && getEditInformationFormControls_dep.full_name_edit_dep.errors">
                                <p class="error"
                                    *ngIf="EditInfoSubmitted_dep && getEditInformationFormControls_dep.full_name_edit_dep.errors.required">
                                    Full Name is required
                                </p>
                            </ng-container>
                        </div>
                    </div>                               
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="emp_date_of_birth_edit_dep">DOB <span class="redstar">*</span></label>
                            <input type="date" class="form-control" formControlName="emp_date_of_birth_edit_dep" id="emp_date_of_birth_edit_dep" placeholder="DOB"
                            [ngClass]="{'control-red': EditInfoSubmitted_dep && getEditInformationFormControls_dep.emp_date_of_birth_edit_dep.errors}"
                            required />
                            <ng-container
                                *ngIf="EditInfoSubmitted_dep && getEditInformationFormControls_dep.emp_date_of_birth_edit_dep.errors">
                                <p class="error"
                                    *ngIf="EditInfoSubmitted_dep && getEditInformationFormControls_dep.emp_date_of_birth_edit_dep.errors.required">
                                    DOB is required
                                </p>
                            </ng-container>
                        </div>
                    </div>                    
                    <!-- <div class="col-sm-6 mt-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                              <option [value]="1">Active</option>
                              <option [value]="0">Deactive</option>
                          </select>                    
                      </div>
                  </div> -->
                    <input type="hidden" class="form-control" formControlName="edit_id_dep" id="edit_id_dep" />
                </div>
            </form>
  
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmitEdit_dep()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

</div>
