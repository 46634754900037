<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Claim Status Master
                <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button"
                    style="float:right">Add
                    New</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add Claim Status Master
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit Claim Status Master
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Policy Number</th>
                            <th>Keyword Data</th>
                            <th>Status</th>
                            <th style="text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['policy_number']}}</td>
                            <td>{{group['keyword_data']}}</td>
                            <td>
                                <span *ngIf="group['status'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                                <span *ngIf="group['status'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                            </td>
                            <td style="width: 15%" align="center">
                                <img src="../../../assets/img/common icons/edit_new.png" id="edituser"
                                    (click)="openPopupEdit(group)" role="button" title="Edit" />
                                <img *ngIf="group['status'] == 1" src="../../../assets/img/selectproduct/deactive.png"
                                    id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button"
                                    title="Deactive" />
                                <img *ngIf="group['status'] == 0" src="../../../assets/img/policy/tickicon.png"
                                    id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button"
                                    title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                <form [formGroup]="AddInfo">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_number">Policy</label>
                                <select class="form-select" formControlName="policy_number" id="policy_number">
                                    <option value="">Select Policy</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.policy_number}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="keyword_data">Keyword Data <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="keyword_data" id="keyword_data"
                                    placeholder="Keyword Data"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.keyword_data.errors}"
                                    required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.keyword_data.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.keyword_data.errors.required">
                                        Keyword Data is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="is_included_in_incurred_amount">Included In Incurred Amount</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_is_included_in_incurred_amount" formControlName="is_included_in_incurred_amount" name="is_included_in_incurred_amount" value="No" />
                                    <label for="radio-two_is_included_in_incurred_amount" id="is_included_in_incurred_amount_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_is_included_in_incurred_amount" formControlName="is_included_in_incurred_amount" name="is_included_in_incurred_amount" value="Yes" />
                                    <label for="radio-one_is_included_in_incurred_amount" id="is_included_in_incurred_amount_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_number_edit">Policy</label>
                                <select class="form-select" formControlName="policy_number_edit" id="policy_number_edit">
                                    <option value="">Select Policy</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.policy_number}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="keyword_data_edit">Keyword Data <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="keyword_data_edit" id="keyword_data_edit"
                                    placeholder="Keyword Data"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.keyword_data_edit.errors}"
                                    required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.keyword_data_edit.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.keyword_data_edit.errors.required">
                                        Keyword Data is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="is_included_in_incurred_amount_edit_edit">Included In Incurred Amount</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_is_included_in_incurred_amount_edit" formControlName="is_included_in_incurred_amount_edit" name="is_included_in_incurred_amount_edit" value="No" />
                                    <label for="radio-two_is_included_in_incurred_amount_edit" id="is_included_in_incurred_amount_edit_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_is_included_in_incurred_amount_edit" formControlName="is_included_in_incurred_amount_edit" name="is_included_in_incurred_amount_edit" value="Yes" />
                                    <label for="radio-one_is_included_in_incurred_amount_edit" id="is_included_in_incurred_amount_edit_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active"
                                    required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()"
                                role="button">Update</button>
                        </div>
                    </div>
                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                </form>
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Claim Status Master</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Claim Status Master</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id"
                                id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>