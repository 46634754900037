<div id="users" class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <form [formGroup]="usersInfo">
        <h3 class="bs-title">GMC Policy Conversion</h3>
        <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
            <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
            <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <hr style="margin-top: -5px;">
        <!-- <div class="row">
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for="username">Username <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="username" id="username" placeholder="Username" 
                    [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.username.errors}"
                    required />
                    <ng-container
                        *ngIf="usersInfoSubmitted && getUsersInformationFormControls.username.errors">
                        <p class="error"
                            *ngIf="usersInfoSubmitted && getUsersInformationFormControls.username.errors.required">
                            Username is required
                        </p>
                    </ng-container>                    
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for="pwd">Password <span class="redstar">*</span></label>
                    <input type="password" class="form-control" formControlName="pwd" id="pwd" placeholder="Password"
                    [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.pwd.errors}" 
                    required />                    
                    <ng-container
                        *ngIf="usersInfoSubmitted && getUsersInformationFormControls.pwd.errors">
                        <p class="error"
                            *ngIf="usersInfoSubmitted && getUsersInformationFormControls.pwd.errors.required">
                            Password is required
                        </p>
                    </ng-container>
                </div>
            </div>            
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for="user_type_id_fk">Type <span class="redstar">*</span></label>
                    <select *ngIf="user_role == 'superadmin'" class="form-select" formControlName="user_type_id_fk" id="user_type_id_fk"
                    [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.user_type_id_fk.errors}"
                    required>
                        <option value="" selected>Select</option>                    
                        <option value="1">Admin</option>                    
                        <option value="3">client</option>
                    </select>
                    <select *ngIf="user_role == 'admin'" class="form-select" formControlName="user_type_id_fk" id="user_type_id_fk"
                    [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.user_type_id_fk.errors}"
                    required>
                        <option value="" selected>Select</option>
                        <option value="3">client</option>
                    </select>
                    <ng-container
                        *ngIf="usersInfoSubmitted && getUsersInformationFormControls.user_type_id_fk.errors">
                        <p class="error">
                            Type is required
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <label class="bs-form-label" for="plan_id_fk">Plan <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="plan_id_fk" id="plan_id_fk" 
                    [ngClass]="{'control-red': usersInfoSubmitted && getUsersInformationFormControls.plan_id_fk.errors}"
                    required>
                        <option value="" selected>Select</option>
                        <option *ngFor="let plan of planArray" [value]="plan['plan_id']">{{plan['plan_name']}}</option>                        
                    </select>                    
                    <ng-container
                        *ngIf="usersInfoSubmitted && getUsersInformationFormControls.plan_id_fk.errors">
                        <p class="error">
                            Plan is required
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-1">
                <div class="form-group">
                    <label class="bs-form-label" for="savebtn"></label>
                    <input type="submit" (click)="onSubmit()" class="form-control" id="savebtn" value="Save" />                    
                </div>
            </div>            
        </div> -->
    </form>

    <div class="table-responsive mt-4">
        <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Company</th>
                <th>Plan ID</th>
                <th>Plan Name</th>
                <th>Status</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
             <tr *ngFor="let group of usersArray; let i = index">
                <td style="width:5%">{{i+1}}</td>
                <td>
                    {{group['company_name']}}
                </td>
                <td>
                    {{group['proposal_number']}}
                </td>
                <td>
                    <span *ngIf="group['proposal_name'] != null">
                        {{group['proposal_name']}}
                    </span>
                    <span *ngIf="group['proposal_name'] == null">
                        -
                    </span>
                </td>
                   <td>
                        <div class="{{group['policy_status']}}">
                            {{group['policy_status']}}
                        </div>
                   </td>
                   <td style="width: 15%" align="center">
                        <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Request Payment ID" title="Request Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Pending' || group['payment_status'] == 'Rejected')" (click)="SubmitPayment(group['client_product_id'], group['payment_status'])" />
                        <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Approve Payment ID" title="Approve Payment ID" style="width:24px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Inserted' || group['payment_status'] == 'Rejected')" (click)="SubmitPayment(group['client_product_id'], group['payment_status'])" />

                    <!-- <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopup(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" /> -->
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit User</h4>
            </div>
            <div class="modal-body">
                
                <form [formGroup]="usersEdit">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_username">Username <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="edit_username" id="edit_username" placeholder="Username" 
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_username.errors}"
                                required />
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_username.errors">
                                    <p class="error">
                                        Username is required
                                    </p>
                                </ng-container>                    
                            </div>
                        </div>            
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_user_type_id_fk">Type <span class="redstar">*</span></label>
                                <select *ngIf="user_role == 'superadmin'" class="form-select" formControlName="edit_user_type_id_fk" id="edit_user_type_id_fk"
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_user_type_id_fk.errors}"
                                required>
                                    <option value="" selected>Select</option>                    
                                    <option value="1">Admin</option>                    
                                    <option value="3">client</option>
                                </select>
                                <select *ngIf="user_role == 'admin'" class="form-select" formControlName="edit_user_type_id_fk" id="edit_user_type_id_fk"
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_user_type_id_fk.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="3">client</option>
                                </select>
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_user_type_id_fk.errors">
                                    <p class="error">
                                        Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_plan_id_fk">Plan <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_plan_id_fk" id="edit_plan_id_fk"
                                [ngClass]="{'control-red': usersEditSubmitted && getUsersEditrmationFormControls.edit_plan_id_fk.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let plan of planArray" [value]="plan['plan_id']">{{plan['plan_name']}}</option>                        
                                </select>                  
                                <ng-container
                                    *ngIf="usersEditSubmitted && getUsersEditrmationFormControls.edit_plan_id_fk.errors">
                                    <p class="error">
                                        Plan is required
                                    </p>
                                </ng-container>  
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>                    
                            </div>
                        </div>
                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />                                                        
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete User</h4>
                </div>
                <form [formGroup]="usersDelete">
                    <div class="modal-body">                                
                        Are you sure you want to delete?
                    </div>
                    <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                        <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                    </div>
                </form>            
            </div>
        </div>
    </div>

</div>