import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { TpaDetailsService } from '../tpa-details/tpa-details';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-tpa-details',
  templateUrl: './tpa-details.component.html',
  styleUrls: ['./tpa-details.component.css']
})
export class TpaDetailsComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayBroker: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Manage TPA';

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: TpaDetailsService, 
    private route: Router,
    private toastService: ToastService

  ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getBroker();
    this.assignrights();
    
    // Form element defined below
    this.AddInfo = this.fb.group({
      name: new FormControl('', Validators.required),
      contact_1: new FormControl('', Validators.required),
      email_1: new FormControl('', Validators.required),      
      broker_idfk:new FormControl(''),
      address_line_1:new FormControl('', Validators.required)
    });

    // Form element defined below
    this.EditInfo = this.fb.group({      
      name_edit: new FormControl('', Validators.required),
      contact_1_edit: new FormControl('', Validators.required),
      email_1_edit: new FormControl('', Validators.required),
      broker_idfk_edit:new FormControl(''),
      address_line_1_edit:new FormControl('', Validators.required),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    let postdata:any = {};

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {

      if(this.type_name == 'superadmin'){

        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "contact_1":this.AddInfo.controls['contact_1'].value,
          "email_1":this.AddInfo.controls['email_1'].value,
          "name":this.AddInfo.controls['name'].value,
          "broker_idfk":this.AddInfo.controls['broker_idfk'].value,
          "address_line_1":this.AddInfo.controls['address_line_1'].value,
          "client_id":localStorage.getItem('client_id')
        };

      }else{
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "contact_1":this.AddInfo.controls['contact_1'].value,
          "email_1":this.AddInfo.controls['email_1'].value,
          "name":this.AddInfo.controls['name'].value,
          "broker_idfk":localStorage.getItem('broker_id'),
          "address_line_1":this.AddInfo.controls['address_line_1'].value,
          "client_id":localStorage.getItem('client_id')
        };
      }
      
      
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 3000);

            this.getInfo();
            this.dtTrigger.unsubscribe();

          }else {            
            this.show = false;         
          }
        }, (error) => {         
          this.toastService.showFailed('Failed', 'There was some error');       
      });
      
    } 
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          
    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        this.EditInfo.controls['contact_1_edit'].setValue(result.data[0]['contact_1']);
        this.EditInfo.controls['email_1_edit'].setValue(result.data[0]['email_1']);
        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['broker_idfk_edit'].setValue(result.data[0]['broker_idfk']);
        this.EditInfo.controls['address_line_1_edit'].setValue(result.data[0]['address_line_1']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['tpa_id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
    let postdata:any = {};
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      if(this.type_name == 'superadmin'){
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "id": id,
          "contact_1":this.EditInfo.controls['contact_1_edit'].value,
          "email_1":this.EditInfo.controls['email_1_edit'].value,
          "name":this.EditInfo.controls['name_edit'].value,
          "broker_idfk":this.EditInfo.controls['broker_idfk_edit'].value,
          "address_line_1":this.EditInfo.controls['address_line_1_edit'].value,
          "client_id":localStorage.getItem('client_id'),
          "is_active":this.EditInfo.controls['edit_is_active'].value
        };
      }else{
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "id": id,
          "contact_1":this.EditInfo.controls['contact_1_edit'].value,
          "email_1":this.EditInfo.controls['email_1_edit'].value,
          "name":this.EditInfo.controls['name_edit'].value,
          "broker_idfk":localStorage.getItem('broker_id'),
          "address_line_1":this.EditInfo.controls['address_line_1_edit'].value,
          "client_id":localStorage.getItem('client_id'),
          "is_active":this.EditInfo.controls['edit_is_active'].value
        };
      }

       

      this.dataservice.InfoUpdate(postdata)
      .subscribe((result: any) => {
          
        if (result.status == true) {            
          
          this.show = false;
          this.closePopup();         
          this.validmsg = result.message;
          this.toastService.showSuccess('Success', 'Details Updated Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 3000);

        }
      }, (error) => {          
        this.closePopup();         
        this.toastService.showFailed('Failed', 'There was some error');       
       
    });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['tpa_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } 
      }, (error) => {  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.tpa_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
      this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['tpa_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false; 
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } 
      }, (error) => {  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    let postdata: any = {};

    if(this.type_name == 'superadmin'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id')
      };
    }
     
    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  getBroker(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getBroker()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }
}
