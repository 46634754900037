<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">Health Benefit Program - Insurance
                <!-- <span class="spanfloat">
                    <a href="">Need Assistance for Medical Queries</a>
                </span> -->
                <!-- <button class="btn btn-primary editbtn">
                    Edit
                </button>-->
                <!-- <button class="btn btn-warning resetbtn">Reset</button> -->
                <button class="btn btn-warning editbtn" (click)="openmodal()">Product Features</button>
                <!-- <button class="btn btn-warning editbtn" (click)="openCoverages()">Coverages</button> -->
                <!-- <button class="btn btn-warning editbtn" (click)="openOPDBenefits()">OPD Benfits</button> -->
            </h3>
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- Tabs -->
            <div class="col-sm-12 mt-4">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab" role="tablist"
                    style="border-radius: 10px 10px 0px 0px;">
                    <li class="nav-item liwidth" role="presentation" *ngIf="gmcbasearray.length > 0">
                        <button class="nav-link w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add"
                            type="button" role="tab" aria-controls="home" aria-selected="true">Group Mediclaim</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation" *ngIf="gpabasearray.length > 0" >
                        <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" 
                            data-bs-target="#Remove" type="button" role="tab" aria-controls="Remove"
                            aria-selected="false">Group Personal Accident</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation" *ngIf="gtlbasearray.length > 0">
                        <button class="nav-link w100" id="Corrections-tab" data-bs-toggle="tab"
                            data-bs-target="#Corrections" type="button" role="tab" aria-controls="Corrections"
                            aria-selected="false">Group Term Life</button>
                    </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content tabUI" id="myTabContent">
 
                    <!-- Tab Group Mediclaim -->
                    <div class="tab-pane fade" id="Add" role="tabpanel" aria-labelledby="Add-tab" *ngIf="gmcbasearray.length > 0">
                        <button class="rightbtn_gmc" id="rightbtn_gmc" (click)="hideAndExpand_gmc($event)">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_gmc" style="background-color: #005963; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_gmc" style="padding-left: 10px;">

                                <div class="bgdiv">
                                    <div class="bgdiv_div">
                                        <div class="default_div" style="background-color: #00ACB1">Base Plan</div>
                                        <h3 class="divheading">{{gmcbasearray[0].name}}</h3>
                                        <h4><b>Group Mediclaim Policy</b></h4>
                                        <h5 style="color: var(--bs-sidebar);">Family Construct
                                            <span *ngIf="gmcbasearray[0].relation.length > 1" class="spanright textrelation" [title]="gmcbasearray[0].relation.toString()">
                                                {{gmcbasearray[0].relation.toString()}}
                                            </span>
                                            <span *ngIf="gmcbasearray[0].relation.length === 1" class="spanright" [title]="gmcbasearray[0].relation.toString()">
                                                {{gmcbasearray[0].relation.toString()}}
                                            </span>
                                        </h5>
                                        
                                        <h5 class="view_details">Sum Insured
                                            <span class="spanright">
                                                {{commonservice.moneyformatter(gmcbasearray[0].cover)}}
                                            </span>
                                        </h5>
                                        <h5 class="premium_details">Employer Premium
                                            <span class="spanright">
                                                <b>{{commonservice.moneyformatter(gmcbasearray[0].employer_pay_premium)}}</b><br>
                                                <small> 
                                                    <span *ngIf="gmcbasearray[0].is_premium_tax==1">(Tax Included)</span>
                                                    <span *ngIf="gmcbasearray[0].is_premium_tax==0">(Tax Excluded)</span>
                                                
                                                </small>
                                            </span>                                            
                                        </h5>
                                    </div>
                                </div>

                                <h3 *ngIf="gmc_flex_plan.length > 0" class="bs-title mt-4" style="color: #000; padding: 0px 10px;">Add on Coverages OR Modular Benefits
                                    <span class="spanfloat">
                                        <!-- <a href="">See all</a> -->
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider" class="intro-section appear-animate" *ngIf="gmc_flex_plan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" style="margin-bottom: 20px;">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of gmc_flex_plan; let i = index;" [class.active]="i === 0">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #1AADA9;">Active Plan</h1>
                                                            <h1 class="bs-title" style="margin: 13px 0px;">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits()">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>

                                        <div *ngIf="gmc_flex_plan.length > 1">
                                            <a class="carousel-control-prev" href="#imageCarousel_multiple" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            </a>
                                            <a class="carousel-control-next" href="#imageCarousel_multiple" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            </a>
                                        </div>
                                    </div>
                                </section>                                   
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border: solid 1px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">Ancillary Flex
                                        <span class="spanfloat">
                                            <!-- <a href="">See all</a> -->
                                        </span>
                                    </h3>
                                    <section id="multipleslider2" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple1" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple1" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple1" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>                            

                            <div class="col-sm-5" id="colSm5_gmc" style="background-color: #fff; border-radius: 4px;">
                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;"> Plans Summary</h3>

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px;  float: right;  position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(gmc_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(gmc_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{gmc_employer_pay+'%'}}" attr.aria-valuenow="{{gmc_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{gmc_you_pay+'%'}}" attr.aria-valuenow="{{gmc_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; position: relative; float: right;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;  position: relative; top: -2px;">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>

                                <!-- GMC BASE PLANS -->
                                <div class="mt-3" *ngFor="let row of gmcbasearray;">
                                    <h2 class="cardheading" style="background-color: #005963;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC VOLUNTARY PLANS -->
                                <div class="mt-3" *ngFor="let row of gmcvoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #005963;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC ADDITIONAL PLANS-->
                                <div class="mt-3" *ngFor="let row of gmcadditionalarray;">
                                    <h2 class="cardheading" style="background-color: #005963;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC Flex Plans -->
                                <div class="mt-3" *ngFor="let row of gmcFlexPlan;">
                                    <h2  *ngIf="row.gmc_base == 1" class="cardheading" style="background-color: #005963;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.gmc_base == 2" class="cardheading" style="background-color: #005963;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>
                                
                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2"  *ngIf="gmc_disclaimer.length > 0 || gmc_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{gmc_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                        
                    </div>

                    <!-- Tab Personal Accident -->
                    <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab" *ngIf="gpabasearray.length > 0">
                        <button class="rightbtn_gpa" id="rightbtn_gpa" (click)="hideAndExpand_gpa()">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_gpa" style="background-color: #005963; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_gpa" style="padding-left: 10px;">

                                <div class="bgdiv">
                                    <div class="bgdiv_div">
                                        <div class="default_div" style="background-color: #005963">Base Plan</div>
                                        <h3 class="divheading" style="background-color: #00ACB1">{{gpabasearray[0].name}}</h3>
                                        <h4><b>Group Mediclaim Policy</b></h4>
                                        <h5 style="color: var(--bs-sidebar);">Family Construct
                                            <span *ngIf="gpabasearray[0].relation.length > 3"class="spanright textrelation">
                                                {{gpabasearray[0].relation.toString()}}
                                            </span>
                                            <span *ngIf="gpabasearray[0].relation.length == 1"class="spanright">
                                                {{gpabasearray[0].relation.toString()}}
                                            </span>
                                        </h5>
                                        <h5 class="view_details">Sum Insured
                                            <span class="spanright">
                                                {{commonservice.moneyformatter(gpabasearray[0].cover)}}
                                            </span>
                                        </h5>
                                        <h5 class="premium_details">Employer Premium
                                            <span class="spanright">
                                                <b>{{commonservice.moneyformatter(gpabasearray[0].employer_pay_premium)}}</b><br>
                                                <small>
                                                    <span *ngIf="gpabasearray[0].is_premium_tax==1">(Tax Included)</span>
                                                    <span *ngIf="gpabasearray[0].is_premium_tax==0">(Tax Excluded)</span>
                                                </small>
                                            </span>                                            
                                        </h5>
                                    </div>
                                </div>

                                <h3 *ngIf="gpa_flex_plan.length > 0" class="bs-title mt-4" style="color: #000; padding: 0px 10px;">Add on Coverages OR Modular Benefits
                                    <span class="spanfloat">
                                        <!-- <a href="">See all</a> -->
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider4" class="intro-section appear-animate" *ngIf="gpaFlexPlan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" style="margin-bottom: 20px;">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of gpaFlexPlan; let i = index;" [class.active]="i === 0">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #1AADA9;">Active Plan</h1>
                                                            <h1 class="bs-title" style="margin: 13px 0px;">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits()">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>

                                        <div *ngIf="gpaFlexPlan.length > 1">
                                            <a class="carousel-control-prev" href="#imageCarousel_multiple" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            </a>
                                            <a class="carousel-control-next" href="#imageCarousel_multiple" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            </a>
                                        </div>
                                    </div>
                                </section> 
    
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border: solid 1px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">Ancillary Flex
                                        <span class="spanfloat">
                                            <!-- <a href="">See all</a> -->
                                        </span>
                                    </h3>
                                    <section id="multipleslider4" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple33" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple33" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple33" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>
                            
                            <div class="col-sm-5" id="colSm5_gpa" style="background-color: #fff; border-radius: 4px;">

                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;">Plans Summary</h3>
                                <!-- <p class="p-3 mb-0 text-white text-center fs14 leftheading">You Pay Premium ₹ 35,144.90 (Tax Included)</p>
                                <p class="p-3 text-white text-center fs14" style="background-color: #f8934c;">Employer pays ₹ 35,144.90 (Tax Included)</p> -->

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; float: right;  position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(gpa_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(gpa_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{gpa_employer_pay+'%'}}" attr.aria-valuenow="{{gpa_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{gpa_you_pay+'%'}}" attr.aria-valuenow="{{gpa_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; float: right; position: relative;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px; top: -2px; position: relative; ">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>                                

                                <!-- GPA BASE PLAN-->
                                <div class="mt-3" *ngFor="let row of gpabasearray;">
                                    <h2 class="cardheading" style="background-color: #00ACB1;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>                             

                                 <!-- GPA VOLUNTARY PLAN-->
                                 <div class="mt-3" *ngFor="let row of gpavoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #00ACB1;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div> 

                                 <!-- GPA ADDITIONAL FEATURES PLAN-->
                                 <div class="mt-3" *ngFor="let row of gpaadditionalarray;">
                                    <h2 class="cardheading" style="background-color: #00ACB1;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div> 
                                                           
                                 <!-- GPA Flex Plans -->
                                <div class="mt-3" *ngFor="let row of gpa_flex_plan;">
                                    <h2  *ngIf="row.gmc_base == 1" class="cardheading" style="background-color: #00ACB1;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.gmc_base == 2" class="cardheading" style="background-color: #00ACB1;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2" *ngIf="gpa_disclaimer.length > 0 || gpa_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne2">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne2" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne2" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{gpa_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                      
                    </div>

                    <!-- Tab Group Term Life -->
                    <div class="tab-pane fade" id="Corrections" role="tabpanel" aria-labelledby="Corrections-tab" *ngIf="gtlbasearray.length > 0">
                        <button class="rightbtn_gtl" id="rightbtn_gtl" (click)="hideAndExpand_gtl()">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_gtl" style="background-color: #005963; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_gtl" style="padding-left: 10px;">

                                <div class="bgdiv">
                                    <div class="bgdiv_div">
                                        <div class="default_div" style="background-color: #C8535E">Base Plan</div>
                                        <h3 class="divheading" style="background-color: #dd8189">{{gtlbasearray[0].name}}</h3>
                                        <h4><b>Group Mediclaim Policy</b></h4>
                                        <h5 style="color: var(--bs-sidebar);">Family Construct
                                            <span *ngIf="gtlbasearray[0].relation.length > 3" class="spanright textrelation">
                                                {{gtlbasearray[0].relation.toString()}}
                                            </span>
                                            <span *ngIf="gtlbasearray[0].relation.length == 1" class="spanright">
                                                {{gtlbasearray[0].relation.toString()}}
                                            </span>
                                        </h5>
                                        <h5 class="view_details">Sum Insured
                                            <span class="spanright">
                                                {{commonservice.moneyformatter(gtlbasearray[0].cover)}}
                                            </span>
                                        </h5>
                                        <h5 class="premium_details">Employer Premium
                                            <span class="spanright">
                                                <b>{{commonservice.moneyformatter(gtlbasearray[0].employer_pay_premium)}}</b><br>
                                                <small>
                                                    <span *ngIf="gtlbasearray[0].is_premium_tax==1">(Tax Included)</span>
                                                    <span *ngIf="gtlbasearray[0].is_premium_tax==0">(Tax Excluded)</span>
                                                </small>
                                            </span>                                            
                                        </h5>
                                    </div>
                                </div>

                                <h3  *ngIf="gtl_flex_plan.length > 0" class="bs-title mt-4" style="color: #000; padding: 0px 10px;">Add on Coverages OR Modular Benefits
                                    <span class="spanfloat">
                                        <!-- <a href="">See all</a> -->
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider4" class="intro-section appear-animate" *ngIf="gtlFlexPlan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" style="margin-bottom: 20px;">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of gtlFlexPlan; let i = index;" [class.active]="i === 0">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #1AADA9;">Active Plan</h1>
                                                            <h1 class="bs-title" style="margin: 13px 0px;">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits()">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>

                                        <div *ngIf="gtlFlexPlan.length > 1">
                                            <a class="carousel-control-prev" href="#imageCarousel_multiple" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            </a>
                                            <a class="carousel-control-next" href="#imageCarousel_multiple" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            </a>
                                        </div>
                                    </div>
                                </section>    
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border: solid 1px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">Ancillary Flex
                                        <span class="spanfloat">
                                            <!-- <a href="">See all</a> -->
                                        </span>
                                    </h3>
                                    <section id="multipleslider3" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple22" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple22" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple22" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>                            

                            <div class="col-sm-5" id="colSm5_gtl" style="background-color: #fff; border-radius: 4px;">

                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;">Plans Summary</h3>
                                <!-- <p class="p-3 mb-0 text-white text-center fs14 leftheading">You Pay Premium ₹ 35,144.90 (Tax Included)</p>
                                <p class="p-3 text-white text-center fs14" style="background-color: #f8934c;">Employer pays ₹ 35,144.90 (Tax Included)</p> -->

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; float: right; position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(gtl_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(gtl_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{gtl_employer_pay+'%'}}" attr.aria-valuenow="{{gtl_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{gtl_you_pay+'%'}}" attr.aria-valuenow="{{gtl_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; position: relative; float: right;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px; position: relative; top: -2px;">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>  

                                <!-- GTL BASE PLAN-->
                                <div class="mt-3" *ngFor="let row of gtlbasearray;">
                                    <h2 class="cardheading" style="background-color: #C8535E;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GTL VOLUNTARY PLAN-->
                                <div class="mt-3" *ngFor="let row of gtlvoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #C8535E;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GTL ADDITIONAL FEATURES PLAN-->
                                <div class="mt-3" *ngFor="let row of gtladditionalarray;">
                                    <h2 class="cardheading" style="background-color: #C8535E;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                 <!-- GTL Flex Plans -->
                                 <div class="mt-3" *ngFor="let row of gtl_flex_plan;">
                                    <h2  *ngIf="row.gmc_base == 1" class="cardheading" style="background-color: #C8535E;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.gmc_base == 2" class="cardheading" style="background-color: #C8535E;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2"  *ngIf="gtl_disclaimer.length > 0 || gtl_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne3">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne3" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne3" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{gtl_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <!-- End -->

            <!-- GMC Right Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':rightModal}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content" style="right: 0px; position: fixed; top: 0px; width: 500px; z-index: 1000000;">                       
                        <div class="modal-body p-0">
                        
                            <!-- Tabs -->
                            <div class="col-sm-12">

                                <!-- Tabs Headings -->
                                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link active w100" id="Features-tab" data-bs-toggle="tab" data-bs-target="#Features"
                                            type="button" role="tab" aria-controls="Features" aria-selected="true">Product Features
                                        </button>
                                    </li>
                                    <!-- <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link w100" id="New_Hospital-tab" data-bs-toggle="tab" data-bs-target="#New_Hospital" 
                                        type="button" role="tab" aria-controls="New_Hospital" aria-selected="false">New Hospital</button>
                                    </li>
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link w100" id="Claim_Process-tab" data-bs-toggle="tab"
                                            data-bs-target="#Claim_Process" type="button" role="tab" aria-controls="Claim_Process"
                                            aria-selected="false">Claim Process</button>
                                    </li>
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link w100" id="FAQs-tab" data-bs-toggle="tab"
                                            data-bs-target="#FAQs" type="button" role="tab" aria-controls="FAQs"
                                            aria-selected="false">FAQ’s</button>
                                    </li> -->
                                </ul>

                                <!-- Tabs Content -->
                                <div class="tab-content tabUI2" id="myTabContent2" style="height: calc(100vh - 90px); overflow-y: auto;">

                                    <!-- Tab Features -->
                                    <div class="tab-pane fade show active" id="Features" role="tabpanel" aria-labelledby="Features-tab" style="font-size: 12px;">
                                        
                                        <!-- <div class="colordiv">
                                            <div class="greenheading">PF Included</div>
                                            <img src="../../../assets/img/employee/ellipse_700.png" class="ellipse" style="width: 300px;" />
                                            <div class="greenp" style="height: 200px; overflow-y: auto;">
                                                <div *ngFor="let gmc_arr_included of gmc_feature_arr.pf_included; let i = index">
                                                    <div>{{gmc_arr_included['title']}} - {{gmc_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_included.length">
                                                </div>
                                            </div>                                            
                                        </div>
                                        
                                        <div class="colordiv">
                                            <div class="redheading">PF Excluded</div>
                                            <img src="../../../assets/img/employee/ellipse_red.png" class="ellipse" />                                            
                                            <div class="redp" style="height: 200px; overflow-y: auto;">
                                                <div *ngFor="let gmc_arr_excluded of gmc_feature_arr.pf_excluded; let i = index">
                                                    <div>{{gmc_arr_excluded['title']}} - {{gmc_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_excluded.length">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="colordiv mt-3">
                                            <div class="redheading">PF Optional</div>
                                            <img src="../../../assets/img/employee/ellipse_violet.png" class="ellipse" />                                            
                                            <div class="violetp" style="height: 200px; overflow-y: auto;">
                                                <div *ngFor="let gmc_arr_optinal of gmc_feature_arr.pf_optional; let i = index">
                                                    <div>{{gmc_arr_optinal['title']}} - {{gmc_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_arr_optinal.pf_excluded.length">
                                                </div>
                                            </div>
                                        </div> -->

                                        <div style="border: solid 5px green; padding: 10px; background: #fff;">
                                            <div style="text-align: center;
                                            background: green;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Included</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gmc_arr_included of gmc_feature_arr.pf_included; let i = index">
                                                    <div><b>{{gmc_arr_included['title']}}</b> - {{gmc_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_included.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px red; padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: red;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Excluded</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gmc_arr_excluded of gmc_feature_arr.pf_excluded; let i = index">
                                                    <div><b>{{gmc_arr_excluded['title']}}</b> - {{gmc_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_excluded.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px rgb(145, 0, 108); padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: rgb(145, 0, 108);
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Optional</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gmc_arr_optinal of gmc_feature_arr.pf_optional; let i = index">
                                                    <div><b>{{gmc_arr_optinal['title']}}</b> - {{gmc_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_optional.length">
                                                </div>
                                            </div> 
                                        </div>

                                    </div>

                                    <!-- Tab New_Hospital -->
                                    <div class="tab-pane fade" id="New_Hospital" role="tabpanel" aria-labelledby="New_Hospital-tab">
                                        <p>Coming Soon...</p> 
                                    </div>

                                    <!-- Tab Claim_Process -->
                                    <div class="tab-pane fade" id="Claim_Process" role="tabpanel" aria-labelledby="Claim_Process-tab">
                                        <p>Coming Soon...</p>
                                    </div>

                                    <!-- Tab FAQs -->
                                    <div class="tab-pane fade" id="FAQs" role="tabpanel" aria-labelledby="FAQs-tab">
                                        <p>Coming Soon...</p>
                                    </div>

                                </div>

                            </div>
                            <!-- End -->

                        </div>
                        <div class="modal-footer p-2" style="display: flex; background-color: white;">
                            <button (click)="closealert()" class="btn btn-primary" style="float: right; margin-top: 0px;">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Coverages Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Coverages}">
                <div class="modal-dialog" role="document" style="max-width: 900px;">                    
                    <div class="modal-content" style="top: -50px">                       
                        <div class="modal-header" style="background-color: var(--bs-sidebar);">
                            <h4 class="modal-title text-white p-0">Add on Coverages OR Modular Benefits
                                <button type="button" class="btn btn-danger" (click)="closealert()" style="position: absolute;right: 10px; top: 10px"> &#10006;</button>
                            </h4>
                        </div>
                        <div class="modal-body p-0" style="height: 500px; overflow-y: auto; overflow-x: hidden;">
                            
                            <div class="p-3 m-3 mb-3 coverageRow" *ngFor="let res of CoveragesArray; let i = index;">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <img src="../../../assets/img/employee/{{res['img_URL']}}" style="height: 150px;" />
                                    </div>
                                    <div class="col-sm-6 text-center mt-3">
                                        <h3 class="bs-title text-black Ctitle">{{res['title']}}</h3>
                                        <h2 class="bs-title text-black Cprice"><b>{{res['price']}}</b></h2>
                                    </div>
                                    <div class="col-sm-3">
                                        <button class="btn btn-primary mt-3" style="position: relative;top: 40px;">4 View Plans
                                            <img src="../../../assets/img/employee/rightArrow.png" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closealert()"> Close</button>
                        </div>
                    </div>                    
                </div>
            </div>
            
            <!-- OPD Benefits Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':OPDBenefits}">
                <div class="modal-dialog" role="document" style="max-width: 1300px;">                    
                    <div class="modal-content" style="top: -50px">                       
                        <div class="modal-header" style="background-color: var(--bs-sidebar);">
                            <h4 class="modal-title text-white p-0">OPD Benefits Plan
                                <button type="button" class="btn btn-danger" (click)="closealert()" style="position: absolute;right: 10px; top: 10px"> &#10006;</button>
                            </h4>
                        </div>
                        <div class="modal-body p-0" style="height: 500px; overflow-y: auto; overflow-x: hidden;">
                            
                            <div class="row">
                                <div class="col-sm-3">
                                    <img src="../../../assets/img/employee/OPD 1.png" style="width: 70%; position: relative; top: 20px; margin: auto; display: block;" alt="image" />
                                </div>
                                <div class="col-sm-9">
                                    <p class="mt-5" style="font-size: 16px;">Add Voluntary OPD Benefits to claim Teleconsultation in-Clinic Consultation, Dental, Vision, Diagnostic
                                        EAP standalone, Max Limit of  1,00,000/-</p>
                                </div>    
                            </div>                            

                            <div class="table-responsive">
                                <table class="table table-bordered table-striped custom-table" style="table-layout: fixed; border-top: none;">
                                    <thead>
                                        <tr style="border: none; background: #fff;">
                                            <th style="background: none; border: none;"></th>
                                            <th class="selectedcolumn">
                                                <input type="checkbox" name="plan" />
                                                Bronze Plan
                                            </th>
                                            <th class="nonselectedcolumn">
                                                <input type="checkbox" name="plan" /> 
                                                Silver Plan
                                            </th>
                                            <th class="nonselectedcolumn">
                                                <input type="checkbox" name="plan" /> 
                                                Gold Plan</th>
                                            <th class="nonselectedcolumn">
                                                <input type="checkbox" name="plan" />
                                                Platinum Plan
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of OPDarray; let i = index">
                                            <td class="blue-text">{{ row['col_1'] }}</td>
                                            <td>{{ row['col_2'] }}</td>
                                            <td>{{ row['col_3'] }}</td>
                                            <td>{{ row['col_4'] }}</td>
                                            <td>{{ row['col_5'] }}</td>                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closealert()"> Close</button>
                        </div> -->
                    </div>                    
                </div>
            </div>          
                
        </div>
        <app-footer></app-footer>
    </div>
</div>