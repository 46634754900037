import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PricingService } from '../pricing/pricing';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  pricingarr: any;
  resstatus = localStorage.getItem('resstatus');
  planame = localStorage.getItem('plan_name');
  tokenable_id = localStorage.getItem('tokenable_id');

  constructor(private route: Router, private pricingService: PricingService) {
    this.route.events.subscribe((e) => {
      // if (this.resstatus == null) {
      //     this.route.navigate(['/home']);
      // }
      // if (localStorage.getItem('type_name') == 'superadmin' || localStorage.getItem('type_name') == 'admin') {
      //   localStorage.clear();
      //   this.route.navigate(['/home']);
      // }
    });

   }

  ngOnInit(): void {

    $("#pricingTable tr:first-child td").addClass('columncol');
    this.getplan();

  }

  getplan(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    this.pricingarr = [];
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')
    };

    this.pricingService.getplanInfo(postdata)
      .subscribe((result: any) => {

        if (result.status == 'true') {

          this.show = false;
          
          this.pricingarr = result.data;

        } else {
          // console.log(result);
        }
      }, (error) => {
        console.log(error);
    });
  }
  fn_clickplan(value:any){

      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "value": value
        // "fileData": this.fileData
      };
      this.pricingService.updatePlans(postdata)
        .subscribe((result: any) => {

          if (result.status == true) {

            this.validmsg = 'Plan Updated Successfully';
            $('#validmsg').css('display', 'block');
            localStorage.setItem('plan_name', value);
            let flash = this;
            setTimeout(function() {
              window.location.reload();

            }, 3000);

          } else {
            console.log(result);
          }
        }, (error) => {
          console.log(error);
          this.validmsg = 'Something went wrong, Plan not updated. ';
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });


  }
  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }
  fn_gotologin(){
    this.validmsg = 'Sign Up To Use Our Exciting Features';
    $('#validmsg').css('display', 'none');
    $('#errormsg').css('display', 'block');
    let flash = this;
    setTimeout(function() {
      // window.location.reload();
      flash.route.navigateByUrl('/register');
    }, 3000);
  }
}
