import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-admintheme',
  templateUrl: './admintheme.component.html',
  styleUrls: ['./admintheme.component.css']
})
export class AdminthemeComponent implements OnInit {

  InfoArray_themelist: any = [];
  themeID:any;

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_btndanger = localStorage.getItem('type_btndanger');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeleteInfo:FormGroup;

  InfoArray: any = [];
  InfoArray_btnsquare_outline: any = [];
  InfoArray_btnsubmit_disabled: any = [];
  InfoArray_CardBlue: any = [];
  
  InfoArray_double: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDelete = "none"; 
  
  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Manage Theme';

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_btndanger');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.assignrights();

    // Form element defined below
    this.AddInfo = this.fb.group({
      theme_name: new FormControl(''),
      global_primary_color: new FormControl('#FF5722'),
      sidebar_background: new FormControl('#4e0099'),
      sidebar_color: new FormControl('#fff'),
      navbar_background: new FormControl('#4e0099'),
      navbar_color: new FormControl('#4e0099'),
      button_background: new FormControl('#FF9800'),
      button_border: new FormControl('#9E9E9E'),
      button_text: new FormControl('#607D8B'),
      btnoutline_solid: new FormControl('#03A9F4'),
      table_header_background: new FormControl('#3d3d3d'),
      table_header_color: new FormControl('#9E9E9E'),
      card_line: new FormControl('#4CAF50'),
      card_heading_color: new FormControl('#40189D'),
      wizard_heading_background: new FormControl('#795548'),
      wizard_heading_text_color: new FormControl('#9E9E9E'),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      theme_name_edit: new FormControl(''),
      global_primary_color_edit: new FormControl('#FF5722'),
      sidebar_background_edit: new FormControl('#4e0099'),
      sidebar_color_edit: new FormControl('#fff'),
      navbar_background_edit: new FormControl('#4e0099'),
      navbar_color_edit: new FormControl('#4e0099'),
      button_background_edit: new FormControl('#FF9800'),
      button_border_edit: new FormControl('#9E9E9E'),
      button_text_edit: new FormControl('#607D8B'),
      btnoutline_solid_edit: new FormControl('#03A9F4'),
      table_header_background_edit: new FormControl('#3d3d3d'),
      table_header_color_edit: new FormControl('#9E9E9E'),
      card_line_edit: new FormControl('#4CAF50'),
      card_heading_color_edit: new FormControl('#40189D'),
      wizard_heading_background_edit: new FormControl('#795548'),
      wizard_heading_text_color_edit: new FormControl('#9E9E9E'),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')
    });

  }

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;
  }

  closePopupAdd(){
    this.addflag = 0;
    window.location.reload();
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      let theme_json = {
          "global_primary_color": this.AddInfo.controls['global_primary_color'].value,
          "sidebar_background": this.AddInfo.controls['sidebar_background'].value,
          "sidebar_color": this.AddInfo.controls['sidebar_color'].value,
          "Navbar": {
            "navbar_background": this.AddInfo.controls['navbar_background'].value,
            "navbar_color": this.AddInfo.controls['navbar_color'].value,
          },
          "Button": {
              "button_background": this.AddInfo.controls['button_background'].value,
              "button_border": this.AddInfo.controls['button_border'].value,
              "button_text": this.AddInfo.controls['button_text'].value,
          },
          "Table": {
              "table_header_background": this.AddInfo.controls['table_header_background'].value,
              "table_header_color": this.AddInfo.controls['table_header_color'].value,
          },
          "Card": {
              "card_line": this.AddInfo.controls['card_line'].value,
              "card_heading_color": this.AddInfo.controls['card_heading_color'].value,
          },
          "Wizard": {
            "wizard_heading_background": this.AddInfo.controls['wizard_heading_background'].value,
            "wizard_heading_text_color": this.AddInfo.controls['wizard_heading_text_color'].value,
          }
      };

      // console.log(theme_json);

      let postdata: any = {};

      if(this.type_name == 'brokeradmin'){
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'),
          "broker_id":localStorage.getItem('broker_id'),    
          "theme_name":this.AddInfo.controls['theme_name'].value,
          "theme_json": JSON.stringify(theme_json),
        };
      }else{
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":localStorage.getItem('client_id'), 
          "theme_name":this.AddInfo.controls['theme_name'].value,
          "theme_json": JSON.stringify(theme_json),
        };
      }

      this.show = false;

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            this.show = false;
            this.toastService.showSuccess('Success', 'Details Added Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;

          }else {
            this.show = false;
          }
        }, (error) => {
        this.toastService.showFailed('Failed', 'There was some error !');               
      });

    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){

    // this.displayStyle_edit = "block";

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {

        let editarr = [];

        for(var i = 0; i < result.data.length; i++){
          let json = result.data[i].theme_json;
          editarr.push(JSON.parse(json));
        }

        // console.log(editarr);

        this.EditInfo.controls['theme_name_edit'].setValue(result.data[0]['name']);

        this.EditInfo.controls['global_primary_color_edit'].setValue(editarr[0]['global_primary_color']);

        this.EditInfo.controls['sidebar_background_edit'].setValue(editarr[0]['sidebar_background']);
        this.EditInfo.controls['sidebar_color_edit'].setValue(editarr[0]['sidebar_color']);

        this.EditInfo.controls['navbar_background_edit'].setValue(editarr[0]['navbar_background']);
        this.EditInfo.controls['navbar_color_edit'].setValue(editarr[0]['navbar_color']);

        this.EditInfo.controls['button_background_edit'].setValue(editarr[0]['Button']['button_background']);
        this.EditInfo.controls['button_border_edit'].setValue(editarr[0]['Button']['button_border']);
        this.EditInfo.controls['button_text_edit'].setValue(editarr[0]['Button']['button_text']);

        this.EditInfo.controls['table_header_background_edit'].setValue(editarr[0]['Table']['table_header_background']);
        this.EditInfo.controls['table_header_color_edit'].setValue(editarr[0]['Table']['table_header_color']);

        this.EditInfo.controls['card_line_edit'].setValue(editarr[0]['Card']['card_line']);
        this.EditInfo.controls['card_heading_color_edit'].setValue(editarr[0]['Card']['card_heading_color']);

        this.EditInfo.controls['wizard_heading_background_edit'].setValue(editarr[0]['Wizard']['wizard_heading_background']);
        this.EditInfo.controls['wizard_heading_text_color_edit'].setValue(editarr[0]['Wizard']['wizard_heading_text_color']);

        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);

      }, (error) => {

    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      let theme_json = {
        "global_primary_color": this.EditInfo.controls['global_primary_color_edit'].value,
        "sidebar_background": this.EditInfo.controls['sidebar_background_edit'].value,
        "sidebar_color": this.EditInfo.controls['sidebar_color_edit'].value,
        "Navbar": {
          "navbar_background": this.EditInfo.controls['navbar_background_edit'].value,
          "navbar_color": this.EditInfo.controls['navbar_color_edit'].value,
        },
        "Button": {
          "button_background": this.EditInfo.controls['button_background_edit'].value,
          "button_border": this.EditInfo.controls['button_border_edit'].value,
          "button_text": this.EditInfo.controls['button_text_edit'].value,
        },
        "Table": {
          "table_header_background": this.EditInfo.controls['table_header_background_edit'].value,
          "table_header_color": this.EditInfo.controls['table_header_color_edit'].value,
        },
        "Card": {
          "card_line": this.EditInfo.controls['card_line_edit'].value,
          "card_heading_color": this.EditInfo.controls['card_heading_color_edit'].value,
        },
        "Wizard": {
          "wizard_heading_background": this.EditInfo.controls['wizard_heading_background_edit'].value,
          "wizard_heading_text_color": this.EditInfo.controls['wizard_heading_text_color_edit'].value,
        }
      };

      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "client_id":localStorage.getItem('client_id'),

        "theme_name":this.EditInfo.controls['theme_name_edit'].value,
        "theme_json": JSON.stringify(theme_json),
      };

      this.show = false;

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {

          if (result.status == true) {

            this.show = false;
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

            this.closePopup();

          }else {
              this.show = false;
          }
        }, (error) => {
          this.closePopup();
          this.toastService.showFailed('Failed', 'There was some error !');               
      });

    }

  }

  //Delete
  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {

        // console.log(result);
        this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);

      }, (error) => {

    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.dataservice.InfoDelete(postdata)
      .subscribe((result: any) => {

        if (result.status == true) {

          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deleted Successfully!');        

          setTimeout(() => {
            window.location.reload();
          },3000);

          this.closePopup();
         
        } else {
          // console.log(result);
        }
      }, (error) => {
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');               
    });

  }

  //Common functions
  getInfo(){

    // this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let postdata: any = {};

    if(this.type_name == 'brokeradmin'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),    
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
      };
    }

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          this.InfoArray_double = result.data;

          if (localStorage.getItem('theme_InfoArray') !== null) {
            let a:any = localStorage.getItem('theme_InfoArray');
            let array:any = JSON.parse(a);
            this.InfoArray = array;            
          } else {           
            for(var i = 0; i < result.data.length; i++){
              let json = result.data[i].theme_json;            
              this.InfoArray.push({id:result.data[i]['id'], name:result.data[i]['name'] ,themejson: JSON.parse(json), preview: true, undo_preview: false});                      
            }             
          }

          // console.log(this.InfoArray);

        }, (error) => {

          this.show = false;
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDelete = "none";
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  setthemId(id: any){
    this.themeID = id;
    $('.labl').css('border', 'solid 1px #949494');
    $('#div'+id).css('border', 'solid 2px #000');
    // console.log(this.themeID);
  }

  onPreview(res: any, flag: any, i:any){
    
    var themejson = res.themejson;    

    if(flag == 1){
     
      //primary
      localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

      //sidebar
      localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
      localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);
      // localStorage.setItem('--bs-sidebar', '#fff');

      //navbar
      localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
      // localStorage.setItem('--bs-navbar_bg', '#fff');
      localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

      //button
      localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
      localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
      localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

      //table
      localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
      localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

      //card
      localStorage.setItem('--bs-card_line', themejson.Card.card_line);
      localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

      //wizard
      localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
      localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
      /************************  END *******************/

      let array:any = [];
      for(let j = 0; j < this.InfoArray_double.length; j++){        
        if(j == i){
          let json = this.InfoArray_double[j].theme_json;            
          array.push({id:this.InfoArray_double[j]['id'], name:this.InfoArray_double[j]['name'] ,themejson: JSON.parse(json), preview: false, undo_preview: true});                                
        }else{
          let json = this.InfoArray_double[j].theme_json;            
          array.push({id:this.InfoArray_double[j]['id'], name:this.InfoArray_double[j]['name'] ,themejson: JSON.parse(json), preview: true, undo_preview: false});                                
        }
      }
      localStorage.setItem('theme_InfoArray', JSON.stringify(array));
      window.location.reload();

    }else{
     
      //primary
      localStorage.removeItem('--bs-global-primary');

      //sidebar
      localStorage.removeItem('--bs-sidebar');
      // localStorage.removeItem('--bs-sidebar');

      //navbar
      localStorage.removeItem('--bs-navbar_bg');
      // localStorage.removeItem('--bs-navbar_bg');
      localStorage.removeItem('--bs-navbar_color');

      //button
      localStorage.removeItem('--bs-btn-bg');
      localStorage.removeItem('--bs-btn-border');
      localStorage.removeItem('--bs-btn-color');

      //table
      localStorage.removeItem('--bs-table_header_bg');
      localStorage.removeItem('--bs-table_header_color');

      //card
      localStorage.removeItem('--bs-card_line');
      localStorage.removeItem('--bs-card_heading_color');

      //wizard
      localStorage.removeItem('--bs-wizard_heading_bg');
      localStorage.removeItem('--bs-wizard_heading_text_color');
      /************************  END *******************/

      let array:any = [];
      for(let j = 0; j < this.InfoArray_double.length; j++){        
        if(j == i){
          let json = this.InfoArray_double[j].theme_json;            
          array.push({id:this.InfoArray_double[j]['id'], name:this.InfoArray_double[j]['name'] ,themejson: JSON.parse(json), preview: true, undo_preview: false});                                
        }else{
          let json = this.InfoArray_double[j].theme_json;            
          array.push({id:this.InfoArray_double[j]['id'], name:this.InfoArray_double[j]['name'] ,themejson: JSON.parse(json), preview: true, undo_preview: false});                                
        }
      }
      localStorage.setItem('theme_InfoArray',JSON.stringify(array));
      window.location.reload();
    }

  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

}
