<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title" style="line-height: unset;">Claim View
                <button type="button" class="btn btn-primary" (click)="backToClaims()" role="button" style="float:right">Back</button>
            </h3>

            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- ----------------------------- -->
            <div class="row viewdiv">

                <div class="col-sm-4">

                    <p><b>Claim ID - </b> <span
                            style="padding: 0px 8px; border: dashed 1px; background-color: #f8f8c4; border-radius: 50px;"><b>{{claimId}}</b></span>
                    </p>

                    <div style="background-color: #f7eeff; padding: 10px; border-radius: 8px; margin-top: 35px;">
                        <p style="font-size: 16px; color: #b53848;"><b>Policy Details</b></p>
                        <hr
                            style="height: 6px; border: none; background: linear-gradient(to right, rgba(151, 16, 34, 1), rgba(255, 255, 255, 1)); margin-top: 0px;">
                        <p class="mb-0">Policy Type</p>
                        <p><b>{{policyType}}</b></p>
                        <p class="mb-0">Policy Number</p>
                        <p><b>{{policyNumber}}</b></p>
                        <p class="mb-0">Insurance Company Name</p>
                        <p><b>{{insuranceCompany}}</b></p>
                        <p class="mb-0">Corporate Name</p>
                        <p><b>Persistent Systems Limited</b></p>
                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Policy Start Date</p>
                                <p><b>{{policyStartDate}}</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0" style="text-align: right;">Policy Start Date</p>
                                <p style="text-align: right;"><b>{{policyEndDate}}</b></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Employee Name</p>
                                <p><b>{{employeeName}}</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0" style="text-align: right;">Employee Mobile Number</p>
                                <p style="text-align: right;"><b>-</b></p>
                            </div>
                        </div>
                        <p class="mb-0">Employee Email ID</p>
                        <p>{{employeeEmail}}</p>
                        <p class="mb-0">Balance Suminsured</p>
                        <p><b>{{BalanceSumInsured}}</b></p>
                    </div>

                    <div style="background-color: #fef5ee; padding: 10px; margin-top: 20px; border-radius: 8px;">
                        <p style="font-size: 16px; color: #b53848;"><b>Claim Tracker</b></p>
                        <hr
                            style="height: 6px; border: none; background: linear-gradient(to right, #971022, #fff); margin-top: 0px;">
                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Claim Approved date</p>
                                <p><b>-</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0" style="text-align: right;">Claim Settled Date</p>
                                <p style="text-align: right;"><b>-</b></p>
                            </div>
                        </div>
                        <p class="mb-0">Deficiency Reason</p>
                        <p><b>{{Deficiency_Reason}}</b></p>
                        <p *ngIf="Deficiency_Reason == ''"><b>-</b></p>
                
                        <p class="mb-0">Reject Date</p>
                        <p><b>{{Reject_date}}</b></p>

                        <p class="mb-0">Reject Reason</p>
                        <p><b>{{Reject_Reason_short}}</b></p>

                        <p class="mb-0">Deficiency Raised Date</p>
                        <p><b>-</b></p>

                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Deficiency Expected Closure Date</p>
                                <p><b>-</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0" style="text-align: right;">Deficiency Actual Closure Date</p>
                                <p style="text-align: right;"><b>-</b></p>
                            </div>
                        </div>

                        <p class="mb-0">Deficiency First Reminder</p>
                        <p><b>-</b></p>
                        <p class="mb-0">Deficiency Second Reminder</p>
                        <p><b>-</b></p>
                        <p class="mb-0">Deduction Reason</p>
                        <p><b>-</b></p>

                    </div>

                </div>

                <div class="col-sm-4">

                    <p style="text-align: right;"><b>Claim Status - </b> <span
                            style="padding: 0px 8px; background-color: #ffdbde; border-radius: 50px; color: #b53848;"><b>{{claimStatus}}</b></span>
                    </p>

                    <p style="text-align: right;"><b>Claim Sub Status - </b> <span
                            style="padding: 0px 8px; background-color: #d1ddf7; border-radius: 50px; color: #2d5cc2;"><b>{{claimSubStatus}}</b></span>
                    </p>

                    <div style="background-color: #f4f9ff; padding: 10px; border-radius: 8px;">
                        <p style="font-size: 16px; color: #b53848;"><b>Claim Details</b></p>
                        <hr
                            style="height: 6px; border: none; background: linear-gradient(to right, #971022, #fff); margin-top: 0px;">
                        <p class="mb-0">Patient Name</p>
                        <p><b>-</b></p>
                        <p class="mb-0">Relationship With Employee</p>
                        <p><b>{{Relation}}</b></p>
                        <p class="mb-0">Ailment</p>
                        <p><b>-</b></p>
                        <p class="mb-0">TPA Name</p>
                        <p><b>{{TPA_name}}</b></p>
                        <p class="mb-0">Claim Type (lipd/cpd)</p>
                        <p><b>{{ClaimType}}</b></p>

                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Claim Type</p>
                                <p><b>{{ClaimType}}</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0" style="text-align: right;">Source</p>
                                <p style="text-align: right;"><b>-</b></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Claim Registered Date</p>
                                <p><b>{{ClaimRegisterDate}}</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0" style="text-align: right;">Claimed Amount</p>
                                <p style="text-align: right;"><b>{{ClaimedAmount}}</b></p>
                            </div>
                        </div>
                        <p class="mb-0">Hospital Name</p>
                        <p><b>{{HospitalName}}</b></p>
                        <p class="mb-0">Hospital Address</p>
                        <p><b>{{HospitalAddress}}</b></p>
                        <p class="mb-0">Hospital City</p>
                        <p><b>{{hospital_city}}</b></p>
                        <p class="mb-0">Hospital State</p>
                        <p><b>{{hospital_state}}</b></p>
                        <p class="mb-0">Hospital Pincode</p>
                        <p><b>{{HospPincode}}</b></p>
                        <p class="mb-0">Hospitalization Date</p>
                        <p><b>{{DateOfAdmission}}</b></p>
                        <p class="mb-0">Discharge Date</p>
                        <p><b>{{DateOfDischarge}}</b></p>
                        <p class="mb-0">Document/Letters</p>
                        <p><button class="btn btn-primary" style="background: #48807f; border: none; padding: 6px; border-radius: 6px;">View Documents</button></p>
                    </div>

                </div>

                <div class="col-sm-4" style="padding-top: 20px;">
                    <p style="font-size: 16px;"><b>Expense Details</b></p>
                    <div style="height:3px; border: dashed 1px; margin-bottom: 10px;"></div>

                    <div style="background-color: #fdfed2; padding: 10px; border-radius: 8px;">
                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Claimed Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Reported Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Settled Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Deduction Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>8876.00</b></p>
                            </div>
                        </div>
                    </div>

                    <div style="background-color: #f3fbfe; padding: 10px; margin-top: 20px; border-radius: 8px;">
                        <p style="font-size: 16px;"><b>Charges</b></p>

                        <div class="row">
                            <div class="col-sm-6">
                                <p class="mb-0">Billed Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Claim Approved Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Claim Settled Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Claim Incurred Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>8876.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Surgery charges</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>299.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Medicine Charges</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>8876.00</b></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0">Claimed Amount</p>
                            </div>
                            <div class="col-sm-6">
                                <p style="text-align: right;"><b>14609.00</b></p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>