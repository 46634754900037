import { Component, ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ClientCreationService } from './client-creation';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastService } from '../../../../../toast.service';


@Component({
  selector: 'app-policy-details-family',
  templateUrl: './policy-details-family.component.html',
  styleUrls: ['./policy-details-family.component.css']
})
export class PolicyDetailsFamilyComponent implements OnInit, AfterViewInit {

  @ViewChild('contentToConvert') contentToConvert: ElementRef;
  
  public basicInfo:FormGroup;
  public Insuredinfo:FormGroup;
  public Nomineeinfo:FormGroup;
  public enrollmentinfo:FormGroup;
  private stepper: Stepper;

  basicInfoSubmitted: boolean = false;
  InsuredinfoSubmitted: boolean = false;
  NomineeinfoSubmitted: boolean = false;
  enrollmentinfoSubmitted: boolean = false;
  
  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');
  phonenumber = localStorage.getItem('mobile_no');

  validmsg: any;
  listed: any;

  displayStyleThankyou = "none";

  /******************************/
  policyType: any = [];
  policySubType: any = [];
  Insurer:any = [];
  TPA:any = [];
  employeeTabView:any = [];
  brokerBranchName:any = [];
  companyDetails:any = [];
  entityDetails:any = [];
  cnt = 1;
  hr_info_arr: any = {};
  midterm_enrollement:any = [];
  co_insurer_perc_cnt: any = 0;
  co_insurer_perc_sum: any = 0;
  /******************************/

  /*******************************/
  allowedArr: any = {};
  cnt_allowed = 1;
  Membertype: any = [];
  coverType: any = [];
  SIType: any = [];
  SIBasis: any = [];
  premiumBasis: any = [];
  Spousemidterm: any = [];
  memberType:any = [];
  /*******************************/

  /*******************************/
  roleDesignationType: any = [];
  emailID: any = [];
  /*******************************/

  documentType: any = [];
  document_file: any;
  policy_feature_document: any;
  uploadcnt = 1;
  uploadExtraArr: any = {};

  spousedata:any = {};
  ChilData: any = {};

  /*******************************/

  RowCount:any = 0;
  GenerateSICount:any = [];
  diffArr: any = [];
  diffval: any;
  cnt_custom: any = 1;
  CustomArr: any = {};

  /*******************************/

  cnt_add_benefit: any = 1;
  add_benefit_arr: any = {};

  cnt_broker: any = 1;
  brokerArr_step4: any = {};

  cnt_employer: any = 1;
  employer_step4Arr: any = {};
  cnt_tpa: any = 1;
  tpa_step4Arr: any = {};
  installmentArr: any = [];
  entityDetails_e:any = [];
  entityDetails_tpa:any = [];
  relationDetails:any = [];
  displayinceptionmsg = "none";
  displayfamilymsg = "none";
  Standardmsgtoggle = "none";
  Benefitpopup = "none";
  Nomineepopup = "none";
  NomineePolicypopup = "none";
  Lockinpopup = "none";
  Savetoggle = "none";
  Deletetoggle = "none";
  Updatetoggle = "none";
  Datetoggle = "none";
  Validation_Error_message:any;
  policysamplefile:any;
  downloadbuttontooltip:any;
  MonthlyEquitible:any = [];
  employee_file:any;
  emmployeefile_id:any = 0;
  stepcomplete: any = '';
  Relation:any = [];
  NomineeArray: any = [];
  NomineePolicyArray: any = [];
  Employeepoliciesbenefits:any = [];
  nomination_applicable:any;
  nomination_is_show_box:any;
  Steptwodata:any = [];
  RowArray:any = [];
  NomineeArray_from_show: any = [];
  policy_id_details_family_res:any = localStorage.getItem('policy_id_details_family_res');
  popuarr:any = []; 
  Step3data:any = [];
  RowArray_Step3:any = []; 
  Relation_edit:any = []; 
  dob_future:any;
  dialogePopup:any = 'none';
  MaxLimittoggle:any = 'none';
  addErrorPopup:any = 'none';
  step2_file:any;
  editID:any;

  min_age_add:any;
  max_age_add:any;
  relation_age_difference:any;
  marraigefile_url:any;
  declaration_file_url:any;
  birth_certificate_document_url:any;
  gendertype:any;
  is_hide_cover:any;
  is_hide_premium:any;
  is_nominee_mandatory:any;
  declaration_file_url_step3:any;
  editID_step3:any;
  Errortoggle:any;
  ErrortoggleMsg:any;
  Descriptiontoggle:any;
  DescriptionMsg:any;
  si_array:any = [];
  si_array_edit_array:any = [];

  member_suminsured:any;
  employee_premium:any;
  employer_premium:any;
  is_premium_tax_edit_popup:any;
  show_si_premium:any;

  fnameflag:any;

  topup_Sum_Insured_lable:any;
  topup_Sum_Insured_value:any;
  topup_Premium_label:any;
  topup_Premium_value:any;

  TopUpSectiontoggle = "none";  

  topup_popupArray:any = [];
  topup_popupobj:any;

  BenefittableArray:any = [];
  death_certificate_document_url:any;

  stepfourdata:any = [];
  selectedinscount:any;
  installment_allow:any;
  is_payroll:any;
  insarraylen:any;

  dialogePopup_nominee = "none";
  nomineesame:any;
  gmc_same_as_above_flag:any = true;

  constructor(private fb: FormBuilder, private clientCreationService: ClientCreationService, private route: Router, private toastService: ToastService) {

    this.route.events.subscribe((e) => {

      this.popuarr = JSON.parse(this.policy_id_details_family_res);

      // console.log(arr);
      
      // this.Datetoggle = 'block';

      if (this.resstatus == 'false' || this.resstatus == null) {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();   

  }

  initGroup(){

    this.getdetails();
    this.getemployeepoliciesbenefits();
    this.getsteptwodata();
    this.getstepthreenomineedata();
    this.getstepfourdata();

    localStorage.setItem('master_policies_id', '0');

    $(".finishbtn").css("pointer-events", 'none');
    $(".finishbtn").css("background", '#00000042');

    // this.getRelation();
    
    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Define Stepper
    const stepperElement = document.querySelector('#stepper1');
    if(stepperElement !== null){
    this.stepper = new Stepper(stepperElement , {
          linear: false,
          animation: true
        })
    }

    // Step 1
    this.basicInfo = this.fb.group({      
      employer_name: new FormControl(''),
      employer_dob: new FormControl(''),
      employer_joining: new FormControl(''),
      employer_email: new FormControl(''),
      employer_contact_number: new FormControl(''),
      employer_alternate_email: new FormControl(''),
      employer_emergency_person: new FormControl(''),
      employer_emergency_contact_number: new FormControl(''),
    });

    // Step 2
    this.Insuredinfo = this.fb.group({
      relation_with_employee: new FormControl(0),
      gender: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      insured_contact_number: new FormControl(''),
      insured_email: new FormControl(''),
      insured_dob: new FormControl(''),
    });

    // Step 3
    this.Nomineeinfo = this.fb.group({
      nominee_relation_with_employee: new FormControl(''),      
      nominee_first_name: new FormControl(''),
      nominee_last_name: new FormControl(''),
      nominee_email: new FormControl(''),
      nominee_share: new FormControl(''),      
      nominee_dob: new FormControl(''),      
    });

    // Step 4
    this.enrollmentinfo = this.fb.group({
      agree: new FormControl(''),      
    });           


    /***** DOB Future Validation *****/
    var dtToday:any = new Date();
    var month:any = dtToday.getMonth() + 1;
    var day:any = dtToday.getDate();
    var year:any = dtToday.getFullYear();
    if(month < 10){
        month = '0' + month.toString();
    }
    if(day < 10){
        day = '0' + day.toString();
    }
    this.dob_future = year + '-' + month + '-' + day;
    /************************/


  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  /************ ALL GET Step 1 ***************/
  getRelation(){
    this.clientCreationService.getRelation()
      .subscribe((result) => {
        
        this.Relation = result.data;

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }
  
  getemployeepoliciesbenefits(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    this.clientCreationService.getemployeepoliciesbenefits(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.Employeepoliciesbenefits = result.data;

        for(let i = 0; i < this.Employeepoliciesbenefits[0]['insured_members'].length; i++){
          if(this.Employeepoliciesbenefits[0]['insured_members'][i]['first_name'] != ''){
            this.BenefittableArray.push({first_name: this.Employeepoliciesbenefits[0]['insured_members'][i]['first_name'], relation: this.Employeepoliciesbenefits[0]['insured_members'][i]['relation']})
          }
        }

        // console.log(this.BenefittableArray);

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  // Step 2
  getsteptwodata(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    this.clientCreationService.getsteptwodata(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.Steptwodata = result.data;

        this.RowArray = [];

        for(let i = 0; i < this.Steptwodata.length; i++){  
          
          let childs:any = 0;
          let parents:any = 0;
          let parents_in_laws:any = 0;
          let siblings:any = 0;
          let spouse:any = 0;
          
          //insured_members
          for(var j = 0; j < this.Steptwodata[i]['insured_members'].length; j++){

            // childs
            if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Son' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Daughter'){
              childs = childs + 1; 
            }

            // parents
            if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Father' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Mother'){
              parents = parents + 1; 
            }
            
            // parents-in-laws
            if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Mother-in-law'){
              parents_in_laws = parents_in_laws + 1; 
            }

            // siblings
            if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Sister' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Brother'){
              siblings = siblings + 1; 
            }

            // spouse
            if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Spouse/Partner'){
              spouse = spouse + 1; 
            }
                                     
          }       

          // let amt = this.Steptwodata[i]['total_premium'];
          // const  valamt = amt.toLocaleString('en-IN', {
          //   style: 'currency',
          //   currency: 'INR',
          // });
                    
          this.RowArray.push({
            "insured_members": this.Steptwodata[i]['insured_members'],            
            "name": this.Steptwodata[i]['name'],
            "policy_id": this.Steptwodata[i]['policy_id'],
            "policy_name": this.Steptwodata[i]['policy_name'],
            "policy_number": this.Steptwodata[i]['policy_number'],
            "relation_data": this.Steptwodata[i]['relation_data'],
            "max_spouse": this.Steptwodata[i]['max_spouse'],
            "max_spouse_filled": spouse,
            "max_child": this.Steptwodata[i]['max_child'],
            "max_child_filled": childs,
            "max_parents": this.Steptwodata[i]['max_parents'],
            "max_parents_filled": parents,
            "max_parents_inlaw": this.Steptwodata[i]['max_parents_inlaw'],
            "max_parents_inlaw_filled": parents_in_laws,
            "max_siblings": this.Steptwodata[i]['max_siblings'],
            "max_siblings_filled": siblings,
            "has_special_child": this.Steptwodata[i]['has_special_child'],
            "has_adopted_child": this.Steptwodata[i]['has_adopted_child'],
            "has_unmarried_child": this.Steptwodata[i]['has_unmarried_child'],                   
            "is_lgbtq": this.Steptwodata[i]['is_lgbtq'],
            "is_parent_cross_section": this.Steptwodata[i]['is_parent_cross_section'],
            "is_parent_policy": this.Steptwodata[i]['is_parent_policy'],
            "marraige_certificate_spouse": this.Steptwodata[i]['marraige_certificate_spouse'],
            "birth_certificate_child": this.Steptwodata[i]['birth_certificate_child'],  
            "is_hide_cover": this.Steptwodata[i]['is_hide_cover'],
            "is_hide_premium": this.Steptwodata[i]['is_hide_premium'],  
            "total_premium": this.Steptwodata[i]['total_premium'],  
            "vp_policy_mother_father_premium": this.Steptwodata[i]['vp_policy_mother_father_premium'],
            "vp_policy_motherinlaw_fatherinlaw_premium": this.Steptwodata[i]['vp_policy_motherinlaw_fatherinlaw_premium'],
            "is_premium_tax": this.Steptwodata[i]['is_premium_tax'],
            "policy_description": this.Steptwodata[i]['policy_description'],
            "pcross_comb_block": this.Steptwodata[i]['pcross_comb_block'],
            "hide_employee_contribution": this.Steptwodata[i]['hide_employee_contribution'],
            "hide_employer_contribution": this.Steptwodata[i]['hide_employer_contribution'],
            "employee_contribution_premium": this.Steptwodata[i]['employee_contribution_premium'],
            "employer_contribution_premium": this.Steptwodata[i]['employer_contribution_premium'],
            "show_si_premium": this.Steptwodata[i]['show_si_premium'],
            "is_topup": this.Steptwodata[i]['is_topup'],
            "topupArray": this.Steptwodata[i]['topupArray'],
          });

          this.is_hide_cover = this.Steptwodata[i]['is_hide_cover'];
          this.is_hide_premium = this.Steptwodata[i]['is_hide_premium'];

        }     

        console.log(this.RowArray);

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  // Step 3
  getstepthreenomineedata(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    this.clientCreationService.getstepthreenomineedata(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.Step3data = result.data;

        this.RowArray_Step3 = [];

        for(let i = 0; i < this.Step3data.length; i++){                                                   
          this.RowArray_Step3.push({
            "insured_members": this.Step3data[i]['insured_members'],            
            "name": this.Step3data[i]['name'],
            "policy_id": this.Step3data[i]['policy_id'],
            "policy_name": this.Step3data[i]['policy_name'],
            "nominee_description": this.Step3data[i]['nominee_description'],
            "policy_number": this.Step3data[i]['policy_number'],
            "relation_data": this.Step3data[i]['relation_data'],
            "is_nominee_mandatory": this.Step3data[i]['nomination_mandatory'], 
            "nomination_applicable": this.Step3data[i]['nomination_applicable'], 
            "nomination_is_show_box": this.Step3data[i]['nomination_is_show_box'], 
            "declaration_mandatory": this.Step3data[i]['declaration_mandatory'],      
          });

          this.is_nominee_mandatory = this.Step3data[i]['nomination_mandatory']

        }     
        
        console.log(this.RowArray_Step3);
        

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }
  
  // Step 4
  getstepfourdata(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    this.clientCreationService.getstepfourdata(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.stepfourdata = result.data;

        this.selectedinscount = result.data[0]['no_of_installments'];

        this.installment_allow = result.data[0]['installment_allow'];
        this.is_payroll = result.data[0]['is_payroll'];

        this.insarraylen = result.data[0]['installment_array'].length;
        
      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  get getInsuredinformationFormControls() {
    return this.Insuredinfo.controls;
  }

  get getNomineeinformationFormControls() {
    return this.Nomineeinfo.controls;
  }

  get getenrollmentinformationFormControls() {
    return this.enrollmentinfo.controls;
  }    

  checkshare(i:any){
    let val:any = $('#nominee_share_' + i).val();
    if(val > 100){
      $('#nominee_share_' + i).val('');
    }
  }

  checkshare_edit(){
    let val:any = $('#nominee_share_edit').val();
    if(val > 100){
      $('#nominee_share_edit').val('');
    }
  }

  // Stepper Next Event
  next(step: number) {
    if (step == 1) {
      this.basicInfoSubmitted = true;
      if (this.basicInfoSubmitted && this.basicInfo.valid) {
        // this.stepper.next();
        this.onSubmit_first();
        this.stepcomplete = 'step1';
        // $("#headingOne").trigger("click");        
      }
    }
    if (step == 2) {
      this.InsuredinfoSubmitted = true;
      if (this.InsuredinfoSubmitted && this.Insuredinfo.valid) {
        this.stepper.next();
        this.onSubmit_second();
        this.stepcomplete = 'step2';
      }
    }
    if (step == 3) {
      this.NomineeinfoSubmitted = true;
      if (this.NomineeinfoSubmitted && this.Nomineeinfo.valid) {        
        this.stepper.next();
        this.onSubmit_third();
        this.stepcomplete = 'step3';
      }
    }
    if (step == 4) {
      
      this.enrollmentinfoSubmitted = true;
      
      if (this.enrollmentinfoSubmitted && this.enrollmentinfo.valid) {       
        
        // this.stepper.next();        
        // this.onSubmit_fourth();
        // this.stepcomplete = 'step4';

        let isEmpty:any;
                     
        // check at one nominee required in policy validation
        for (let i = 0; i < this.RowArray_Step3.length; i++) {

          // if(this.RowArray_Step3[i]['relation_data'].length > 0){

            const row = this.RowArray_Step3[i];
                    
            if(row.insured_members.length > 0){
              isEmpty = row.insured_members.every((member: { first_name: string; }) => member.first_name.trim() === '');
            }
            console.log(isEmpty);
          
            if (isEmpty) {
              // console.log('Index of row with all empty first names', this.RowArray_Step3[i]['policy_name']);
              // $(".nomination_mandatory_div").css("display", 'block');
              this.toastService.showWarning('Warning', 'At least one Nominee required in policy');

              // this.Errortoggle = "block";
              // this.ErrortoggleMsg = "At least one Nominee required in policy " + this.RowArray_Step3[i]['policy_name'];
            }else{
              // $(".nomination_mandatory_div").css("display", 'none');  
              // this.Errortoggle = "none";   
              // this.ErrortoggleMsg = "";
            }
            // End
            
            // Check share % is equal to 100 of all nominee if first_name is not empty
            for(let j = 0; j < this.RowArray_Step3[i]['insured_members'].length; j++){                        

              if(this.RowArray_Step3[i]['insured_members'][j]['first_name'] != ''){
                
                // console.log(this.RowArray_Step3[i]['insured_members'][j]['first_name']);

                const sum = row.insured_members.reduce((acc: number, member: { share_percentage: string; }) => {
                  // Convert share_percentage to a number and add to the accumulator
                  return acc + (member.share_percentage ? parseFloat(member.share_percentage) : 0);
                }, 0);

                // console.log(sum);
            
                if (sum !== 100) {
                  // console.log(`Index ${this.RowArray_Step3[i]['policy_name']} has a total share_percentage of 100.`);
                  this.toastService.showWarning('Warning', 'Share percentage of policy' + this.RowArray_Step3[i]['policy_name']  + "is not equal to 100.");

                  // this.Errortoggle = "block";
                  // this.ErrortoggleMsg = "Share percentage of policy " + this.RowArray_Step3[i]['policy_name'] + " is not equal to 100."; 
                }

                // console.log(sum);

              }
            }
            // End

          // }          
          
        }                
        
        // check at one topup required in policy validation
        let radioflag:any = 0;

        for (let i = 0; i < this.RowArray.length; i++) {

          if(this.RowArray[i]['is_topup'] == '1'){            

            let arr:any = this.RowArray[i]['topupArray'];

            for (let j = 0; j < arr.length; j++) {
              
              if(arr[j]['topup_setup'] == '1'){
                
                for (let k = 0; k < arr[j]['topup_radio'].length; k++) {
                  if(arr[j]['topup_radio'][k]['topup_selected'] == '1'){
                    radioflag = 1;
                  }
                }

                if(radioflag == 0){
                  this.toastService.showWarning('Warning', 'At least select one Top up option!!');

                  // this.Errortoggle = "block";
                  // this.ErrortoggleMsg = "At least select one Top up option!!";
                }else{
                  if(isEmpty == false){
                    this.dialogePopup = "block";
                  }
                }

              }else{

                if(isEmpty == false){
                  this.dialogePopup = "block";
                }
              }            
            }

          }else{

            if(isEmpty == false){
              this.dialogePopup = "block";
            }

          }
          
        }
        
        // check at one installation required in step 4
        let insflag:any = 0;

        for (let i = 0; i < this.stepfourdata.length; i++) {

          if(this.installment_allow == 1 && this.is_payroll == 1 && this.stepfourdata[0]['installment_array'].length > 0){            

            if(this.stepfourdata[i]['member_installment_id'] == 0){
              this.toastService.showWarning('Warning', 'At least select one Installment option!!');

              // this.Errortoggle = "block";
              // this.ErrortoggleMsg = "At least select one Installment option!!";
            }else{
              if(isEmpty == false){
                this.dialogePopup = "block";
              }
            }

          }else{

            if(isEmpty == false){
              this.dialogePopup = "block";
            }

          }
          
        }

      }
    }    
  }     

  // Stepper Previous Event
  previous() {
    this.stepper.previous();
  }  

  // Submit 1
  onSubmit_first() {
       
    let a = this.basicInfo.controls['employer_contact_number'].value;

    if(a.length != '10'){
      $('#employer_contact_number_error').css('display', 'block');
    }else{

      $('#employer_contact_number_error').css('display', 'none');

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_id":localStorage.getItem('policy_id_details_family'),                  
  
        "employer_name": this.basicInfo.controls['employer_name'].value,
        "employer_dob": this.basicInfo.controls['employer_dob'].value,
        "employer_joining": this.basicInfo.controls['employer_joining'].value,
        "employer_email": this.basicInfo.controls['employer_email'].value,
        "contact": this.basicInfo.controls['employer_contact_number'].value,
        "alternate_email": this.basicInfo.controls['employer_alternate_email'].value,
        "emergency_contact_name": this.basicInfo.controls['employer_emergency_person'].value,
        "emergency_contact_number": this.basicInfo.controls['employer_emergency_contact_number'].value,                  
      };
  
      // console.log(postdata);
  
      this.clientCreationService.Step1_store(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          if (result.status == true) {
  
            if (result.status == true) {
              // this.Savetoggle = "block";
              this.toastService.showSuccess('Success', 'Details Saved Successfully!');        

            }
  
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
  
          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
          this.toastService.showFailed('Failed', 'There was some error');
      });
      
    }    

  }

  // Submit 2
  onSubmit_second() {           

    // var postdata = {
    //   "tokenable_id":localStorage.getItem('tokenable_id'),
    //   // "parent_id":this.basicInfo.controls['company_id'].value,
    //   "broker_id":localStorage.getItem('broker_id'),
    //   // "master_policies_id": localStorage.getItem('master_policies_id'),  
      
    //   "relation_with_employee": this.Insuredinfo.controls['relation_with_employee'].value,
    //   "gender": this.Insuredinfo.controls['gender'].value,
    //   "first_name": this.Insuredinfo.controls['first_name'].value,
    //   "last_name": this.Insuredinfo.controls['last_name'].value,
    //   "insured_contact_number": this.Insuredinfo.controls['insured_contact_number'].value,
    //   "insured_email": this.Insuredinfo.controls['insured_email'].value,
    //   "insured_dob": this.Insuredinfo.controls['insured_dob'].value,
    //   "Nominee_array": JSON.stringify(this.NomineeArray)
    // };

    // console.log(postdata);

    // this.clientCreationService.Step2_update(postdata, localStorage.getItem('Token'))
    //   .subscribe((result: any) => {
    //     console.log(result);
    //     if (result.status == true) {
    //       // console.log(result);

    //       $('#validmsg').css('display', 'block');
    //       $('#errormsg').css('display', 'none');

    //     } else {
    //       // console.log(result);
    //     }
    //   }, (error) => {
    //     this.validmsg = error.error.message;
    //     $('#validmsg').css('display', 'none');
    //     $('#errormsg').css('display', 'block');
    // });

  }

  // Submit 3
  onSubmit_third() {

    // var postdata = {
    //   "tokenable_id":localStorage.getItem('tokenable_id'),
    //   // "parent_id":this.basicInfo.controls['company_id'].value,
    //   "broker_id":localStorage.getItem('broker_id'),
    //   // "master_policies_id": localStorage.getItem('master_policies_id'),

    //   "nominee_relation_with_employee": this.Nomineeinfo.controls['nominee_relation_with_employee'].value,
    //   "nominee_first_name": this.Nomineeinfo.controls['nominee_first_name'].value,
    //   "nominee_last_name": this.Nomineeinfo.controls['nominee_last_name'].value,
    //   "nominee_share": this.Nomineeinfo.controls['nominee_share'].value,
    //   "nominee_dob": this.Nomineeinfo.controls['nominee_dob'].value,
    //   "NomineePolicy_array": JSON.stringify(this.NomineePolicyArray)
    // };

    // console.log(postdata);

    // this.clientCreationService.Step3_update(postdata, localStorage.getItem('Token'))
    //   .subscribe((result: any) => {
    //     console.log(result);
    //     if (result.status == true) {
    //       // console.log(result);

    //       $('#validmsg').css('display', 'block');
    //       $('#errormsg').css('display', 'none');

    //     } else {
    //       // console.log(result);
    //     }
    //   }, (error) => {
    //     this.validmsg = error.error.message;
    //     $('#validmsg').css('display', 'none');
    //     $('#errormsg').css('display', 'block');
    // });

  }

  // Submit 4
  onSubmit_fourth() {

    let val:any = $("input[name='agree']:checked").val();
    let valagree:any;
    if(val == undefined){
      valagree = 0;
    }else{
      valagree = 1;
    }
   
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      // "parent_id":this.basicInfo.controls['company_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
      // "master_policies_id": localStorage.getItem('master_policies_id'), 
      
      "agree": valagree,
    }

    console.log(postdata);

    this.clientCreationService.Step4_update(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);
          this.toastService.showSuccess('Success', 'Details Saved Successfully!');        

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
        this.toastService.showFailed('Failed', 'There was some error');

    });

  }  

  checkagree(){
    let val:any = $("input[name='agree']:checked").val();
    if(val == undefined){
      $(".finishbtn").css("pointer-events", 'none');
      $(".finishbtn").css("background", '#00000042');
    }else{
      $(".finishbtn").css("pointer-events", 'auto');
      $(".finishbtn").css("background", 'var(--bs-btn-bg)');      
    }   
  }

  closePopup() {
    this.displayinceptionmsg = "none";
    this.displayfamilymsg = "none";
    this.Standardmsgtoggle="none";
    this.Benefitpopup = 'none';
    this.Nomineepopup = 'none';
    this.NomineePolicypopup = 'none';
    this.Lockinpopup = 'none';
    this.Savetoggle = "none";
    this.Deletetoggle = "none";
    this.Updatetoggle = "none";
    this.Datetoggle = "none";
    this.dialogePopup = "none";
    this.MaxLimittoggle = "none";
    this.addErrorPopup = "none";
    this.Errortoggle = "none";
    this.Descriptiontoggle = "none";
    this.TopUpSectiontoggle = "none";    
    this.dialogePopup_nominee = "none";
  }  

  /***** Add Field *****/
  addBenefitField(){

    let html = `
    <div id="this_benefit_row_`+this.cnt_allowed+`" class="row">
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="member_type_`+this.cnt_allowed+`">Member type </label>
          <select class="form-select" formControlName="member_type_`+this.cnt_allowed+`" id="member_type_`+this.cnt_allowed+`">
                <option value="" selected>Select Member Type</option>
          </select>
      </div>
      <div class="col-sm-2 form-group mt-3">
          <div class="form-check" style="margin-top: 30px;">
              <input type="checkbox" formControlName="no_age_`+this.cnt_allowed+`" id="no_age_`+this.cnt_allowed+`" class="form-check-input ng-valid ng-dirty ng-touched" style="margin-left: 0px; margin-top: 0px; cursor: pointer;">
              <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
          </div>
      </div>
      <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
          <label class="bs-form-label" for="min_age_`+this.cnt_allowed+`">Min Age <span class="redstar">*</span></label>
          <input type="text" class="form-control" formControlName="min_age_`+this.cnt_allowed+`" id="min_age_`+this.cnt_allowed+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
          <label class="bs-form-label" for="max_age_`+this.cnt_allowed+`">Max Age <span class="redstar">*</span></label>
          <input type="text" class="form-control" formControlName="max_age_`+this.cnt_allowed+`" id="max_age_`+this.cnt_allowed+`" />
      </div>
      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt_allowed+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newbenifitField').append(html);

    for(var i=0; i<this.Membertype.length; i++){
      var val = '#' + 'member_type_'+ this.cnt;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Membertype[i]['id'])
          .text(this.Membertype[i]['name']));
    }

    // this.Insuredinfo.addControl("member_type_"+this.cnt_allowed, new FormControl(''));
    // this.Insuredinfo.addControl("no_age_"+this.cnt_allowed, new FormControl(''));
    // this.Insuredinfo.addControl("min_age_"+this.cnt_allowed, new FormControl(''));
    // this.Insuredinfo.addControl("max_age_"+this.cnt_allowed, new FormControl(''));

    this.allowedArr[this.cnt_allowed] = {member_type: "", no_age: "", min_age: "", max_age: ""};

    let flag = this;
    $("#benefit_row_"+this.cnt_allowed).on('click',function(e) {
      flag.removeRow(e);
    });

    $("#no_age_"+this.cnt_allowed).on('change',function(e) {
      flag.isnoage(e);
    });

    $("#min_age_"+this.cnt_allowed).on('keyup',function(e) {
      flag.isnumberBenefit(e);
    });

    $("#max_age_"+this.cnt_allowed).on('keyup',function(e) {
      flag.isnumberBenefit(e);
    });

    this.cnt_allowed++;

  }

  removeRow(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_benefit_row_' + id;
    $(idstr).remove();

  }

  isnumberBenefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;
  }

  isnoage(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    let valchecked:any = $("#" + str).is(":checked");
    var value = '.noAgeDiv_' + id;
    if(valchecked == false){
      $(value).css('display', 'block');
    }else{
      $(value).css('display', 'none');
    }
  }
  /***************************************/

  // File upload
  member_data_change(event: any) {
    if (event.target.files.length > 0) {

      this.document_file = event.target.files[0];
      console.log(event.target.files[0])

      $("#custom-file-upload_document_file").empty();
      $('#custom-file-upload_document_file').html(event.target.files[0]['name']);
      $('#custom-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_document_file').empty();
      $('#small_document_file').html('');
      $('#small_document_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }
    
  // Toggle button
  radio_yesno(val:any, text: any){

    if(text == 'yes'){

      $('#'+val+'_'+text).css('background', '#2196F3');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_no').css('background', '#f1f1f1');
      $('#'+val+'_no').css('color', '#000');

    }else{

      $('#'+val+'_'+text).css('background', '#9e9e9e');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_yes').css('background', '#f1f1f1');
      $('#'+val+'_yes').css('color', '#000');

    }
  }  

  goto(){
    this.route.navigate(['/employeruser-dashboard/family-enrollment']);
  }

  showBenefitpopup(){
    this.Benefitpopup = 'block';
  }

  /**************** Insurer Popup Step 2 ************/
  addIsurer(id:any){    
        
    this.MaxLimittoggle = 'none';
    
    /**********Add Data*********/ 
    for(var i = 0; i < this.RowArray.length; i++){  
      
      var checkcros:any;
      
      if(id == i){

        let arr = this.RowArray[i]['insured_members'];
        let relation_id:any = $('#relation_with_employee_'+i).val();
        let relation:any = $("#relation_with_employee_"+i+" option:selected").text();      
        let gender:any;
        let first_name:any;
        let last_name:any;
        let contact:any;
        let contact_email:any;
        let dob:any;  
        let marriage_date:any; 
        let si_array:any;
        let rater_id:any;         
        
        var child_data:any;
        var parents_data:any;
        var parents_inlaws__data:any;
        var siblings_data:any;
        var spouse_data:any;
        var is_adopted_child:any;
        var has_special_child:any;
        var has_unmarried_child:any;
        var duplicateparent:any = 0;
        
        rater_id = $('#si_array_'+i).val(); 
        let a = "#si_array_"+i+" option:selected";        
        si_array = $(a).text();    
        
        //relation Data
        
        let arr_relationData =this.RowArray[i]['relation_data'];

        for (var w = 0; w < arr_relationData.length; w++) {
          if(relation === arr_relationData[w]['relation'] ){
            if (arr_relationData[w]['has_member_add_lock_in'] == 1 && arr_relationData[w]['lockin_type_id'] == 1) {
              this.Lockinpopup = 'block';
              arr = [];
            }
          }      
      }

      console.log(arr);

        //insured_members
        for(var j = 0; j < arr.length; j++){
            
          for (let [key, value] of Object.entries(arr[j])) {                
  
            if(key == 'relation_id'){
              // arr[j][key] = relation_id;
            }
            if(key == 'relation'){
              // arr[j][key] = relation;
            }
            if(key == 'gender'){
              // arr[j][key] = $('#gender_'+i).val();
              gender = $('#gender_'+i).val();
            }                
            if(key == 'first_name'){
              // arr[j][key] = $('#first_name_'+i).val();
              first_name = $('#first_name_'+i).val();
            }
            if(key == 'last_name'){
              // arr[j][key] = $('#last_name_'+i).val();
              last_name = $('#last_name_'+i).val();
            }
            if(key == 'member_mob_no'){
              // arr[j][key] = $('#insured_contact_number_'+i).val();
              contact = $('#insured_contact_number_'+i).val();
            }
            if(key == 'member_email'){
              // arr[j][key] = $('#insured_email_'+i).val();
              contact_email = $('#insured_email_'+i).val();
            }                
            if(key == 'dob'){
              // arr[j][key] = $('#insured_dob_'+i).val();
              dob = $('#insured_dob_'+i).val();
            }  
            if(key == 'has_special_child'){
              has_special_child = $('#is_special_child_'+i).val();
            }
            if(key == 'has_unmarried_child'){
              has_unmarried_child = $('#is_unmarried_child_'+i).val();
            }                       
            
            marriage_date = $('#marriage_date_'+i).val();

            let prel = $('#relation_with_employee_'+i).val();;
            
            if(this.RowArray[i]['max_parents'] == '2' || this.RowArray[i]['max_parents_inlaw'] == '2'){
              if(arr[j]['relation'] == 'Father' && arr[j]['first_name'] !== '' && prel == '5' ||
              arr[j]['relation'] == 'Mother' && arr[j]['first_name'] !== '' && prel == '6' || 
              arr[j]['relation'] == 'Father-in-law' && arr[j]['first_name'] !== '' && prel == '7' ||
              arr[j]['relation'] == 'Mother-in-law' && arr[j]['first_name'] !== '' && prel == '8'){
                duplicateparent = 1;
              }          
            }
            
            // console.log("duplicateparent",duplicateparent);
            
          }
          
        }         

        let siflag:any;
        if ($('.si_array_Div').css('display') === 'block') {          
          if(si_array == 'Select'){
            $('#si_array_error_' + i).css('display', 'block');            

            if(si_array == 'Select'){
              $('#si_array_error_' + i).css('display', 'block');
              siflag = 1;
            }else{
              $('#si_array_error_' + i).css('display', 'none');
              siflag = 0;
            }

          }else{
            $('#si_array_error_' + i).css('display', 'none');
            siflag = 0;
          }
        }        

        
                
        if(relation_id == 0 || relation_id == null || gender == '' || gender == undefined || first_name == '' || first_name == undefined || dob == '' || dob == undefined || siflag == 1){

          if(relation_id == 0){
            $('#relation_with_employee_error_' + i).css('display', 'block');
          }else{
            $('#relation_with_employee_error_' + i).css('display', 'none');
          }
          
          if(gender == ''){
            $('#gender_error_' + i).css('display', 'block');
          }else{
            $('#gender_error_' + i).css('display', 'none');
          }

          if(first_name == ''){
            $('#first_name_error_' + i).css('display', 'block');
          }else{
            $('#first_name_error_' + i).css('display', 'none');
          }

          if(dob == ''){
            $('#dob_error_' + i).css('display', 'block');
          }else{
            $('#dob_error_' + i).css('display', 'none');
          }                    

        }else{

          let child_imgurl:any;
          let parent_imgurl:any;
          let parent_in_laws_imgurl:any;
          let siblings_imgurl:any;
          let spouse_imgurl:any;

          // //insured_members
          for(var j = 0; j < arr.length; j++){

            // max_childs
            if(relation === 'Son' || relation === 'Daughter'){

              if(this.RowArray[i]['max_child'] == this.RowArray[i]['max_child_filled']){
                // this.MaxLimittoggle = 'block';
                this.toastService.showFailed('Failed', 'Reached Max Limit !');
                child_data = 0;
              }else{
                this.MaxLimittoggle = 'none';
                child_data = 1;
              }

              if(relation == 'Son'){
                child_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/son.png";
              }else{
                child_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/daughter.png";
              }

            }     
            
            // max_parents
            if (relation === 'Father' || relation === 'Mother') {

              if (this.RowArray[i]['max_parents'] == this.RowArray[i]['max_parents_filled']) {
                // this.MaxLimittoggle = 'block';
                this.toastService.showFailed('Failed', 'Reached Max Limit !');
                parents_data = 0;
              } else {
                this.MaxLimittoggle = 'none';
                parents_data = 1;
              }

              if (relation == 'Father') {
                parent_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/father.png";
              } else {
                parent_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/mother.png";
              }

            }
            
            // max_parents_inlaws
            if (relation === 'Father-in-law' || relation === 'Mother-in-law') {

              if (this.RowArray[i]['max_parents_inlaw'] == this.RowArray[i]['max_parents_inlaw_filled']) {
                // this.MaxLimittoggle = 'block';
                this.toastService.showFailed('Failed', 'Reached Max Limit !');
                parents_inlaws__data = 0;
              } else {
                this.MaxLimittoggle = 'none';
                parents_inlaws__data = 1;
              }

              if (relation == 'Father-in-law') {
                parent_in_laws_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/father_inlaw.png";
              } else {
                parent_in_laws_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/mother_inlaw.png";
              }

            }

            // max_siblings
            if (relation === 'Brother' || relation === 'Sister') {

              if (this.RowArray[i]['max_siblings'] == this.RowArray[i]['max_siblings_filled']) {
                // this.MaxLimittoggle = 'block';
                this.toastService.showFailed('Failed', 'Reached Max Limit !');
                siblings_data = 0;
              } else {
                this.MaxLimittoggle = 'none';
                siblings_data = 1;
              }

              if (relation == 'Brother') {
                siblings_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/brother.png";
              } else {
                siblings_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/sister.png";
              }

            }

            // max_spouse
            if (relation === 'Spouse/Partner') {

              if (this.RowArray[i]['max_spouse'] == this.RowArray[i]['max_spouse_filled']) {
                // this.MaxLimittoggle = 'block';
                this.toastService.showFailed('Failed', 'Reached Max Limit !');
                spouse_data = 0;
              } else {
                this.MaxLimittoggle = 'none';
                spouse_data = 1;
              }

              if (relation == 'Spouse/Partner') {
                spouse_imgurl = "https://neologismtech.com/serverapp/public/uploads/relation_member/spouse.png";
              } else {
                spouse_imgurl = "";
              }

            }

          }     
                    
          /*******Childs**********/
          if(child_data == 1){

              let min:any;
              let max:any;
              let no_agelimit_check:any;
              let relation_age_depend:any;
              let relation_age_difference:any;
              let self_dob:any;

              for(var k = 0; k < this.RowArray[i]['relation_data'].length; k++){

                if(this.RowArray[i]['insured_members'][k] != undefined){
                  if(this.RowArray[i]['insured_members'][k]['relation'] == 'Self'){
                    self_dob = this.RowArray[i]['insured_members'][k]['dob'];
                  }
                }                

                if(this.RowArray[i]['relation_data'][k]['relation'] == 'Son' || this.RowArray[i]['relation_data'][k]['relation'] == 'Daughter'){
                                                      
                  /******/
                  if(this.RowArray[i]['relation_data'][k]['no_agelimit_check'] == '0'){
                    min = this.RowArray[i]['relation_data'][k]['min_age'];
                    max = this.RowArray[i]['relation_data'][k]['max_age'];
                    this.min_age_add = min;
                    this.max_age_add = max;
                    no_agelimit_check = true;
                  }else{
                    no_agelimit_check = false;
                  }

                  /******/
                  if(this.RowArray[i]['relation_data'][k]['relation_age_depend'] == '1'){
                    relation_age_depend = true;
                    relation_age_difference = this.RowArray[i]['relation_data'][k]['relation_age_difference'];
                    this.relation_age_difference = relation_age_difference;
                  }else{
                    relation_age_depend = false;
                    relation_age_difference = 0;
                    this.relation_age_difference = relation_age_difference;
                  }

                }                
              }             
                            
              const agecal:any = this.calage(dob);
              const self_age:any = this.calage(self_dob);
              // console.log(self_age, '-', agecal);
              let age_diff_value:any = self_age - agecal;
              // console.log('----------',age_diff_value);

              let age_diff:any = (agecal >= min && agecal <= max) ? true : false;
            
              /************/
              if ($('#is_special_child_'+i).is(':checked') || $('#is_unmarried_child_'+i).is(':checked')) {
                
                var data:any = {
                  "relation_id": Number(relation_id),
                  "relation": relation,
                  "epm_id": '',
                  "first_name": $('#first_name_'+i).val(),
                  "last_name": $('#last_name_'+i).val(),
                  "gender": $('#gender_'+i).val(),
                  "member_mob_no": $('#insured_contact_number_'+i).val(),
                  "member_email": $('#insured_email_'+i).val(),
                  "dob": $('#insured_dob_'+i).val(),
                  "marriage_date": $('#marriage_date_'+i).val(),
                  "image_url": child_imgurl,
                  "is_adopted_child": $('#is_adopted_child_'+i).is(':checked'),
                  "document": this.RowArray[i]['document'],
                  "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
                  "has_special_child": $('#is_special_child_'+i).is(':checked'),
                  "has_unmarried_child": $('#is_unmarried_child_'+i).is(':checked'),
                }
      
                // arr.push(data);              
                    
                // API Call
                var postdata_child_a = {
                  "tokenable_id":localStorage.getItem('tokenable_id'),
                  "policy_id": this.RowArray[i]['policy_id'],
                  "relation_id": relation_id,
                  "relation": relation,
                  "gender": gender,
                  "first_name": first_name,
                  "last_name": last_name,
                  "contact": contact,
                  "contact_email": contact_email,
                  "dob": dob,
                  "si_array": si_array,
                  "rater_id": rater_id,
                  "marriage_date": marriage_date,
                  "is_adopted_child": $('#is_adopted_child_'+i).is(':checked'),
                  "document": this.RowArray[i]['document'],
                  "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
                  "has_special_child": $('#is_special_child_'+i).is(':checked'),
                  "has_unmarried_child": $('#is_unmarried_child_'+i).is(':checked'),
                  "age_diff_flag": false
                };    
                // console.log(postdata_child_a);        
                
                this.clientCreationService.steptwosaveinsuredmemberdata(postdata_child_a, localStorage.getItem('Token'))
                  .subscribe((result) => {
                    
                    if (result.status == true) {
                      this.Savetoggle = "block";
                      arr.push(data);
                      this.emptyValues(id);                      
                      this.addErrorPopup = 'none';
                    }else{
                      this.addErrorPopup = 'block';
                    }    
                            
                  }, (error) => {         
                    
                    if(error.error.message == 'Unauthenticated.'){
                      localStorage.clear();
                      localStorage.setItem('resstatus', 'false');
                      this.route.navigate(['/home']);
                    }
                });
                //END    

                $('.age_greater_error_'+i).css('display', 'none');

              }else{

                if(no_agelimit_check == true && age_diff == true){

                  if(relation_age_depend == true){

                    console.log(age_diff_value , '>=', relation_age_difference);

                    if(age_diff_value >= relation_age_difference){

                      var data:any = {
                        "relation_id": Number(relation_id),
                        "relation": relation,
                        "epm_id": '',
                        "first_name": $('#first_name_'+i).val(),
                        "last_name": $('#last_name_'+i).val(),
                        "gender": $('#gender_'+i).val(),
                        "member_mob_no": $('#insured_contact_number_'+i).val(),
                        "member_email": $('#insured_email_'+i).val(),
                        "dob": $('#insured_dob_'+i).val(),
                        "marriage_date": $('#marriage_date_'+i).val(),
                        "image_url": child_imgurl,
                        "is_adopted_child": $('#is_adopted_child_'+i).is(':checked'),
                        "document": this.RowArray[i]['document'],
                        "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
                        "has_special_child": $('#is_special_child_'+i).is(':checked'),
                        "has_unmarried_child": $('#is_unmarried_child_'+i).is(':checked'),                        
                      }            
                                                              
                      // API Call
                      var postdata_a = {
                        "tokenable_id":localStorage.getItem('tokenable_id'),
                        "policy_id": this.RowArray[i]['policy_id'],
                        "relation_id": relation_id,
                        "relation": relation,
                        "gender": gender,
                        "first_name": first_name,
                        "last_name": last_name,
                        "contact": contact,
                        "contact_email": contact_email,
                        "dob": dob,
                        "si_array": si_array,
                        "rater_id": rater_id,
                        "marriage_date": marriage_date,
                        "is_adopted_child": $('#is_adopted_child_'+i).is(':checked'),
                        "document": this.RowArray[i]['document'],
                        "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
                        "has_special_child": $('#is_special_child_'+i).is(':checked'),
                        "has_unmarried_child": $('#is_unmarried_child_'+i).is(':checked'),     
                        "age_diff_flag": false               
                      };                                                
                      this.clientCreationService.steptwosaveinsuredmemberdata(postdata_a, localStorage.getItem('Token'))
                        .subscribe((result) => {                                                       

                          if (result.status == true) {
                            this.Savetoggle = "block";
                            arr.push(data);
                            this.emptyValues(id);                      
                            this.addErrorPopup = 'none';
                            this.getsteptwodata();
                          }else{
                            this.addErrorPopup = 'block';
                          }
                                  
                        }, (error) => {         
                          
                          if(error.error.message == 'Unauthenticated.'){
                            localStorage.clear();
                            localStorage.setItem('resstatus', 'false');
                            this.route.navigate(['/home']);
                          }
                      });
                      //END

                      $('.age_equal_to_error_'+i).css('display', 'none');
                      this.Errortoggle = "none";

                    }else{

                      var data:any = {
                        "relation_id": Number(relation_id),
                        "relation": relation,
                        "epm_id": '',
                        "first_name": $('#first_name_'+i).val(),
                        "last_name": $('#last_name_'+i).val(),
                        "gender": $('#gender_'+i).val(),
                        "member_mob_no": $('#insured_contact_number_'+i).val(),
                        "member_email": $('#insured_email_'+i).val(),
                        "dob": $('#insured_dob_'+i).val(),
                        "marriage_date": $('#marriage_date_'+i).val(),
                        "image_url": child_imgurl,
                        "is_adopted_child": $('#is_adopted_child_'+i).is(':checked'),
                        "document": this.RowArray[i]['document'],
                        "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
                        "has_special_child": $('#is_special_child_'+i).is(':checked'),
                        "has_unmarried_child": $('#is_unmarried_child_'+i).is(':checked')
                      }            
                                                              
                      // API Call
                      var postdata_aa = {
                        "tokenable_id":localStorage.getItem('tokenable_id'),
                        "policy_id": this.RowArray[i]['policy_id'],
                        "relation_id": relation_id,
                        "relation": relation,
                        "gender": gender,
                        "first_name": first_name,
                        "last_name": last_name,
                        "contact": contact,
                        "contact_email": contact_email,
                        "dob": dob,
                        "si_array": si_array,
                        "rater_id": rater_id,
                        "marriage_date": marriage_date,
                        "is_adopted_child": $('#is_adopted_child_'+i).is(':checked'),
                        "document": this.RowArray[i]['document'],
                        "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
                        "has_special_child": $('#is_special_child_'+i).is(':checked'),
                        "has_unmarried_child": $('#is_unmarried_child_'+i).is(':checked'),
                        "age_diff_flag": true                    
                      };                                                
                      this.clientCreationService.steptwosaveinsuredmemberdata(postdata_aa, localStorage.getItem('Token'))
                        .subscribe((result) => {                                                       

                          if (result.status == true) {
                            this.Savetoggle = "block";
                            arr.push(data);
                            this.emptyValues(id);                      
                            this.addErrorPopup = 'none';
                            this.getsteptwodata();
                          }else{
                            this.addErrorPopup = 'block';
                          }
                                  
                        }, (error) => {         
                          
                          if(error.error.message == 'Unauthenticated.'){
                            localStorage.clear();
                            localStorage.setItem('resstatus', 'false');
                            this.route.navigate(['/home']);
                          }
                      });
                      //END

                      // $('.age_equal_to_error_'+i).css('display', 'block');
                      this.toastService.showWarning('Warning', 'Child Difference do not match and child is added!!');

                      // this.Errortoggle = "block";
                      // this.ErrortoggleMsg = "Child Difference do not match and child is added!!";
                      $('.age_greater_error_'+i).css('display', 'none');
                    }

                  }                  
  
                  $('.age_greater_error_'+i).css('display', 'none');
  
                }else{
  
                  $('.age_greater_error_'+i).css('display', 'block');
                  $('.age_equal_to_error_'+i).css('display', 'none');
  
                } 
                
              }             
              /************/                             

          }else{
          }          

          /*******Parents**********/
          if (parents_data == 1) {

            let min: any;
            let max: any;
            let no_agelimit_check: any;
            let relation_age_depend: any;
            let relation_age_difference: any;
            let self_dob: any;

            for (var k = 0; k < this.RowArray[i]['relation_data'].length; k++) {

              if (this.RowArray[i]['insured_members'][k] != undefined) {
                if (this.RowArray[i]['insured_members'][k]['relation'] == 'Self') {
                  self_dob = this.RowArray[i]['insured_members'][k]['dob'];
                }
              }

              if (this.RowArray[i]['relation_data'][k]['relation'] == 'Father' || this.RowArray[i]['relation_data'][k]['relation'] == 'Mother') {

                /******/
                if (this.RowArray[i]['relation_data'][k]['no_agelimit_check'] == '0') {
                  min = this.RowArray[i]['relation_data'][k]['min_age'];
                  max = this.RowArray[i]['relation_data'][k]['max_age'];
                  this.min_age_add = min;
                  this.max_age_add = max;
                  no_agelimit_check = true;
                } else {
                  no_agelimit_check = false;
                }

                /******/
                if (this.RowArray[i]['relation_data'][k]['relation_age_depend'] == '1') {
                  relation_age_depend = true;
                  relation_age_difference = this.RowArray[i]['relation_data'][k]['relation_age_difference'];
                  this.relation_age_difference = relation_age_difference;
                } else {
                  relation_age_depend = false;
                  relation_age_difference = 0;
                  this.relation_age_difference = relation_age_difference;
                }

              }
            }

            const agecal: any = this.calage(dob);
            const self_age: any = this.calage(self_dob);
            // console.log(self_age, '-', agecal);
            let age_diff_value: any = self_age - agecal;
            // console.log('----------',age_diff_value);

            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;
            
            if(duplicateparent == 0){
            
              /******Cross combination validation******/
              if (this.RowArray[i]['is_parent_policy'] == '0' && this.RowArray[i]['is_parent_cross_section'] == '0' || 
              this.RowArray[i]['is_parent_policy'] == '0' && this.RowArray[i]['is_parent_cross_section'] == '1' || 
              this.RowArray[i]['is_parent_policy'] == '1' && this.RowArray[i]['is_parent_cross_section'] == '1') {
                checkcros = 0;
              }else{
                checkcros = 1;
              }

              checkcros = 0;
              
              /************/
              if (checkcros == 0) {  
                console.log('----0----');          
                if (no_agelimit_check == true && age_diff == true) {

                  console.log('----01----');

                  if (relation_age_depend == true) {

                    console.log('----001----');

                    if (age_diff_value >= relation_age_difference) {   
                      
                      console.log('----1----');

                      var data: any = {
                        "relation_id": Number(relation_id),
                        "relation": relation,
                        "epm_id": '',
                        "first_name": $('#first_name_' + i).val(),
                        "last_name": $('#last_name_' + i).val(),
                        "gender": $('#gender_' + i).val(),
                        "member_mob_no": $('#insured_contact_number_' + i).val(),
                        "member_email": $('#insured_email_' + i).val(),
                        "dob": $('#insured_dob_' + i).val(),
                        "marriage_date": $('#marriage_date_' + i).val(),
                        "image_url": parent_imgurl
                      }

                      // API Call
                      var postdata_parent1 = {
                        "tokenable_id": localStorage.getItem('tokenable_id'),
                        "policy_id": this.RowArray[i]['policy_id'],
                        "relation_id": relation_id,
                        "relation": relation,
                        "gender": gender,
                        "first_name": first_name,
                        "last_name": last_name,
                        "contact": contact,
                        "contact_email": contact_email,
                        "dob": dob,
                        "si_array": si_array,
                        "rater_id": rater_id,
                        "marriage_date": marriage_date,
                        "document": this.RowArray[i]['document'],
                        "age_diff_flag": false
                      };
                      // console.log(postdata);        

                      this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parent1, localStorage.getItem('Token'))
                        .subscribe((result) => {                        

                          if (result.status == true) {
                            this.Savetoggle = "block";                        
                            arr.push(data);
                            this.emptyValues(id);                    
                            this.addErrorPopup = 'none';
                            this.getsteptwodata();
                          }else{
                            this.addErrorPopup = 'block';
                          }

                        }, (error) => {

                          if (error.error.message == 'Unauthenticated.') {
                            localStorage.clear();
                            localStorage.setItem('resstatus', 'false');
                            this.route.navigate(['/home']);
                          }
                        });
                      //END

                      $('.age_equal_to_error_' + i).css('display', 'none');

                    } 
                    else{

                      console.log('----2----');

                      var data: any = {
                        "relation_id": Number(relation_id),
                        "relation": relation,
                        "epm_id": '',
                        "first_name": $('#first_name_' + i).val(),
                        "last_name": $('#last_name_' + i).val(),
                        "gender": $('#gender_' + i).val(),
                        "member_mob_no": $('#insured_contact_number_' + i).val(),
                        "member_email": $('#insured_email_' + i).val(),
                        "dob": $('#insured_dob_' + i).val(),
                        "marriage_date": $('#marriage_date_' + i).val(),
                        "image_url": parent_imgurl
                      }

                      // API Call
                      var postdata_parent2 = {
                        "tokenable_id": localStorage.getItem('tokenable_id'),
                        "policy_id": this.RowArray[i]['policy_id'],
                        "relation_id": relation_id,
                        "relation": relation,
                        "gender": gender,
                        "first_name": first_name,
                        "last_name": last_name,
                        "contact": contact,
                        "contact_email": contact_email,
                        "dob": dob,
                        "si_array": si_array,
                        "rater_id": rater_id,
                        "marriage_date": marriage_date,
                        "document": this.RowArray[i]['document'],
                        "age_diff_flag": true
                      };
                      // console.log(postdata);        

                      this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parent2, localStorage.getItem('Token'))
                        .subscribe((result) => {                        

                          if (result.status == true) {
                            this.Savetoggle = "block";
                            arr.push(data);
                            this.emptyValues(id);                    
                            this.addErrorPopup = 'none';                        
                            this.getsteptwodata();
                          }else{
                            this.addErrorPopup = 'block';
                          }

                        }, (error) => {

                          if (error.error.message == 'Unauthenticated.') {
                            localStorage.clear();
                            localStorage.setItem('resstatus', 'false');
                            this.route.navigate(['/home']);
                          }
                        });
                      //END                                    

                      $('.age_equal_to_error_' + i).css('display', 'block');
                      $('.age_greater_error_' + i).css('display', 'none');
                    }

                  }

                  $('.age_greater_error_' + i).css('display', 'none');

                } else {

                  $('.age_greater_error_' + i).css('display', 'block');
                  $('.age_equal_to_error_' + i).css('display', 'none');

                }
                // $('.crossParentCheckDiv_' + i).css('display', 'none');
                this.Errortoggle = "none";
                this.ErrortoggleMsg = "";
              }else{

                let Father:any = 0;
                let FatherInLaw:any = 0;
                let Mother:any = 0;
                let MotherInLaw:any = 0;

                for(var p = 0; p < this.RowArray[i]['insured_members'].length; p++){
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Father'){
                    Father = 1;
                  }
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Father-in-law'){
                    FatherInLaw = 1;
                  }
                  
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Mother'){
                    Mother = 1;
                  }
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Mother-in-law'){
                    MotherInLaw = 1;
                  }
                }
                              
                if(relation == 'Father' && FatherInLaw == 1 || relation == 'Father-in-law' && Father == 1 ||
                relation == 'Mother' && MotherInLaw == 1 || relation == 'Mother-in-law' && Mother == 1){               
                  // $('.crossParentCheckDiv_' + i).css('display', 'block');
                  this.toastService.showWarning('Warning', 'No Cross Parent is Allowed!!');

                  // this.Errortoggle = "block";
                  // this.ErrortoggleMsg = "No Cross Parent is Allowed!!";
                }else{
                  
                  /********* Tick Validation **********/
                  if (this.RowArray[i]['is_parent_policy'] == '1'){

                    let relation_allowed_for_employee_father:any;
                    let relation_allowed_for_employee_mother:any;
                    let tickflag_father:any;
                    let tickflag_mother:any;
                    
                    for(var p = 0; p < this.RowArray[i]['relation_data'].length; p++){                    
                      if (this.RowArray[i]['relation_data'][p]['relation'] == 'Father'){
                        relation_allowed_for_employee_father = this.RowArray[i]['relation_data'][p]['relation_allowed_for_employee'];
                      }                   
                      
                      if (this.RowArray[i]['relation_data'][p]['relation'] == 'Mother'){
                        relation_allowed_for_employee_mother = this.RowArray[i]['relation_data'][p]['relation_allowed_for_employee'];
                      }                   
                    }

                    // Father
                    if(relation == 'Father' && relation_allowed_for_employee_father == '0'){
                      tickflag_father = 1;
                      this.gendertype = 'Male';
                    }else if(relation == 'Father' && relation_allowed_for_employee_father == '1'){
                      tickflag_father = 0;
                      this.gendertype = 'Female';
                    }else{
                      tickflag_father = 1;
                      this.gendertype = 'Both';
                    }

                    // Mother
                    if(relation == 'Mother' && relation_allowed_for_employee_mother == '0'){
                      tickflag_mother = 1;
                      this.gendertype = 'Male';
                    }else if(relation == 'Mother' && relation_allowed_for_employee_mother == '1'){
                      tickflag_mother = 0;
                      this.gendertype = 'Female';
                    }else{
                      tickflag_mother = 1;
                      this.gendertype = 'Both';
                    }

                    // console.log('tickflag_father', tickflag_father);
                    // console.log('tickflag_mother', tickflag_mother);

                    if(relation == 'Father' && tickflag_father == 1){

                      if (no_agelimit_check == true && age_diff == true) {

                        if (relation_age_depend == true) {
        
                          if (age_diff_value >= relation_age_difference) {                    
        
                            var data: any = {
                              "relation_id": Number(relation_id),
                              "relation": relation,
                              "epm_id": '',
                              "first_name": $('#first_name_' + i).val(),
                              "last_name": $('#last_name_' + i).val(),
                              "gender": $('#gender_' + i).val(),
                              "member_mob_no": $('#insured_contact_number_' + i).val(),
                              "member_email": $('#insured_email_' + i).val(),
                              "dob": $('#insured_dob_' + i).val(),
                              "marriage_date": $('#marriage_date_' + i).val(),
                              "image_url": parent_imgurl
                            }
        
                            // API Call
                            var postdata_parent123 = {
                              "tokenable_id": localStorage.getItem('tokenable_id'),
                              "policy_id": this.RowArray[i]['policy_id'],
                              "relation_id": relation_id,
                              "relation": relation,
                              "gender": gender,
                              "first_name": first_name,
                              "last_name": last_name,
                              "contact": contact,
                              "contact_email": contact_email,
                              "dob": dob,
                              "si_array": si_array,
                              "rater_id": rater_id,
                              "marriage_date": marriage_date,
                              "document": this.RowArray[i]['document'],
                              "age_diff_flag": false
                            };
                            // console.log(postdata);        
        
                            this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parent123, localStorage.getItem('Token'))
                              .subscribe((result) => {                        
        
                                if (result.status == true) {
                                  this.Savetoggle = "block";                        
                                  arr.push(data);
                                  this.emptyValues(id);                    
                                  this.addErrorPopup = 'none';
                                  this.getsteptwodata();
                                }else{
                                  this.addErrorPopup = 'block';
                                }
        
                              }, (error) => {
        
                                if (error.error.message == 'Unauthenticated.') {
                                  localStorage.clear();
                                  localStorage.setItem('resstatus', 'false');
                                  this.route.navigate(['/home']);
                                }
                              });
                            //END
        
                            $('.age_equal_to_error_' + i).css('display', 'none');
        
                          } else {
        
                            var data: any = {
                              "relation_id": Number(relation_id),
                              "relation": relation,
                              "epm_id": '',
                              "first_name": $('#first_name_' + i).val(),
                              "last_name": $('#last_name_' + i).val(),
                              "gender": $('#gender_' + i).val(),
                              "member_mob_no": $('#insured_contact_number_' + i).val(),
                              "member_email": $('#insured_email_' + i).val(),
                              "dob": $('#insured_dob_' + i).val(),
                              "marriage_date": $('#marriage_date_' + i).val(),
                              "image_url": parent_imgurl
                            }
        
                            // API Call
                            var postdata_parent223 = {
                              "tokenable_id": localStorage.getItem('tokenable_id'),
                              "policy_id": this.RowArray[i]['policy_id'],
                              "relation_id": relation_id,
                              "relation": relation,
                              "gender": gender,
                              "first_name": first_name,
                              "last_name": last_name,
                              "contact": contact,
                              "contact_email": contact_email,
                              "dob": dob,
                              "si_array": si_array,
                              "rater_id": rater_id,
                              "marriage_date": marriage_date,
                              "document": this.RowArray[i]['document'],
                              "age_diff_flag": true
                            };
                            // console.log(postdata);        
        
                            this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parent223, localStorage.getItem('Token'))
                              .subscribe((result) => {                        
        
                                if (result.status == true) {
                                  this.Savetoggle = "block";
                                  arr.push(data);
                                  this.emptyValues(id);                    
                                  this.addErrorPopup = 'none';                        
                                  this.getsteptwodata();
                                }else{
                                  this.addErrorPopup = 'block';
                                }
        
                              }, (error) => {
        
                                if (error.error.message == 'Unauthenticated.') {
                                  localStorage.clear();
                                  localStorage.setItem('resstatus', 'false');
                                  this.route.navigate(['/home']);
                                }
                              });
                            //END                                    
        
                            $('.age_equal_to_error_' + i).css('display', 'block');
                            $('.age_greater_error_' + i).css('display', 'none');
                          }
        
                        }
        
                        $('.age_greater_error_' + i).css('display', 'none');
        
                      } else {
        
                        $('.age_greater_error_' + i).css('display', 'block');
                        $('.age_equal_to_error_' + i).css('display', 'none');
        
                      }

                      // $('.parents_allowed_' + i).css('display', 'none');
                      this.Errortoggle = "none";
                      this.ErrortoggleMsg = "";

                    }else{
                      // $('.parents_allowed_' + i).css('display', 'block');
                      this.toastService.showWarning('Warning', 'Relation Allowed for this employee does allows to add this employee');

                      // this.Errortoggle = "block";
                      // this.ErrortoggleMsg = "Relation Allowed for this employee does allows to add this employee";
                    }

                    if(relation == 'Mother' && tickflag_mother == 1){

                      if (no_agelimit_check == true && age_diff == true) {

                        if (relation_age_depend == true) {
        
                          if (age_diff_value >= relation_age_difference) {                    
        
                            var data: any = {
                              "relation_id": Number(relation_id),
                              "relation": relation,
                              "epm_id": '',
                              "first_name": $('#first_name_' + i).val(),
                              "last_name": $('#last_name_' + i).val(),
                              "gender": $('#gender_' + i).val(),
                              "member_mob_no": $('#insured_contact_number_' + i).val(),
                              "member_email": $('#insured_email_' + i).val(),
                              "dob": $('#insured_dob_' + i).val(),
                              "marriage_date": $('#marriage_date_' + i).val(),
                              "image_url": parent_imgurl
                            }
        
                            // API Call
                            var postdata_parentP = {
                              "tokenable_id": localStorage.getItem('tokenable_id'),
                              "policy_id": this.RowArray[i]['policy_id'],
                              "relation_id": relation_id,
                              "relation": relation,
                              "gender": gender,
                              "first_name": first_name,
                              "last_name": last_name,
                              "contact": contact,
                              "contact_email": contact_email,
                              "dob": dob,
                              "si_array":si_array,
                              "rater_id":rater_id,
                              "marriage_date": marriage_date,
                              "document": this.RowArray[i]['document'],
                              "age_diff_flag": false
                            };
                            // console.log(postdata);        
        
                            this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP, localStorage.getItem('Token'))
                              .subscribe((result) => {                        
        
                                if (result.status == true) {
                                  this.Savetoggle = "block";                        
                                  arr.push(data);
                                  this.emptyValues(id);                    
                                  this.addErrorPopup = 'none';
                                  this.getsteptwodata();
                                }else{
                                  this.addErrorPopup = 'block';
                                }
        
                              }, (error) => {
        
                                if (error.error.message == 'Unauthenticated.') {
                                  localStorage.clear();
                                  localStorage.setItem('resstatus', 'false');
                                  this.route.navigate(['/home']);
                                }
                              });
                            //END
        
                            $('.age_equal_to_error_' + i).css('display', 'none');
        
                          } else {
        
                            var data: any = {
                              "relation_id": Number(relation_id),
                              "relation": relation,
                              "epm_id": '',
                              "first_name": $('#first_name_' + i).val(),
                              "last_name": $('#last_name_' + i).val(),
                              "gender": $('#gender_' + i).val(),
                              "member_mob_no": $('#insured_contact_number_' + i).val(),
                              "member_email": $('#insured_email_' + i).val(),
                              "dob": $('#insured_dob_' + i).val(),
                              "marriage_date": $('#marriage_date_' + i).val(),
                              "image_url": parent_imgurl
                            }
        
                            // API Call
                            var postdata_parentPP = {
                              "tokenable_id": localStorage.getItem('tokenable_id'),
                              "policy_id": this.RowArray[i]['policy_id'],
                              "relation_id": relation_id,
                              "relation": relation,
                              "gender": gender,
                              "first_name": first_name,
                              "last_name": last_name,
                              "contact": contact,
                              "contact_email": contact_email,
                              "dob": dob,
                              "si_array":si_array,
                              "rater_id":rater_id,
                              "marriage_date": marriage_date,
                              "document": this.RowArray[i]['document'],
                              "age_diff_flag": true
                            };
                            // console.log(postdata);        
        
                            this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentPP, localStorage.getItem('Token'))
                              .subscribe((result) => {                        
        
                                if (result.status == true) {
                                  this.Savetoggle = "block";
                                  arr.push(data);
                                  this.emptyValues(id);                    
                                  this.addErrorPopup = 'none';                        
                                  this.getsteptwodata();
                                }else{
                                  this.addErrorPopup = 'block';
                                }
        
                              }, (error) => {
        
                                if (error.error.message == 'Unauthenticated.') {
                                  localStorage.clear();
                                  localStorage.setItem('resstatus', 'false');
                                  this.route.navigate(['/home']);
                                }
                              });
                            //END                                    
        
                            $('.age_equal_to_error_' + i).css('display', 'block');
                            $('.age_greater_error_' + i).css('display', 'none');
                          }
        
                        }
        
                        $('.age_greater_error_' + i).css('display', 'none');
        
                      } else {
        
                        $('.age_greater_error_' + i).css('display', 'block');
                        $('.age_equal_to_error_' + i).css('display', 'none');
        
                      }

                      // $('.parents_allowed_' + i).css('display', 'none');
                      this.Errortoggle = "none";
                      this.ErrortoggleMsg = "";

                    }else{
                      // $('.parents_allowed_' + i).css('display', 'block');
                      this.toastService.showWarning('Warning', 'Relation Allowed for this employee does allows to add this employee');

                      // this.Errortoggle = "block";
                      // this.ErrortoggleMsg = "Relation Allowed for this employee does allows to add this employee";
                    }

                  }else{

                  }
                  /********************************** */

                  // $('.crossParentCheckDiv_' + i).css('display', 'none');
                  // this.Errortoggle = "none";
                  // this.ErrortoggleMsg = "";

                }

                // $('.crossParentCheckDiv_' + i).css('display', 'block');
                // this.Errortoggle = "block";
                // this.ErrortoggleMsg = "No Cross Parent is Allowed!!";
                
              }       
              /************************************/
              $('.duplicateparent_' + i).css('display', 'none');
            }else{            
              $('.duplicateparent_' + i).css('display', 'block');
            }                           
            
          } else {
          }

          /*******Parents**In Laws********/		
          if(parents_inlaws__data == 1){
            
            let min: any;
            let max: any;
            let no_agelimit_check: any;
            let relation_age_depend: any;
            let relation_age_difference: any;
            let self_dob: any;

            for (var k = 0; k < this.RowArray[i]['relation_data'].length; k++) {

              if (this.RowArray[i]['insured_members'][k] != undefined) {
                if (this.RowArray[i]['insured_members'][k]['relation'] == 'Self') {
                  self_dob = this.RowArray[i]['insured_members'][k]['dob'];
                }
              }

              if (this.RowArray[i]['relation_data'][k]['relation'] == 'Father-in-law' || this.RowArray[i]['relation_data'][k]['relation'] == 'Mother-in-law') {

                /******/
                if (this.RowArray[i]['relation_data'][k]['no_agelimit_check'] == '0') {
                  min = this.RowArray[i]['relation_data'][k]['min_age'];
                  max = this.RowArray[i]['relation_data'][k]['max_age'];
                  this.min_age_add = min;
                  this.max_age_add = max;
                  no_agelimit_check = true;
                } else {
                  no_agelimit_check = false;
                }

                /******/
                if (this.RowArray[i]['relation_data'][k]['relation_age_depend'] == '1') {
                  relation_age_depend = true;
                  relation_age_difference = this.RowArray[i]['relation_data'][k]['relation_age_difference'];
                  this.relation_age_difference = relation_age_difference;
                } else {
                  relation_age_depend = false;
                  relation_age_difference = 0;
                  this.relation_age_difference = relation_age_difference;
                }

              }
            }

            const agecal: any = this.calage(dob);
            const self_age: any = this.calage(self_dob);
            // console.log(self_age, '-', agecal);
            let age_diff_value: any = self_age - agecal;
            // console.log('----------',age_diff_value);

            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;


            if(duplicateparent == 0){
              
              $('.duplicateparent_' + i).css('display', 'none');            

              /******Cross combination validation******/
              if (this.RowArray[i]['is_parent_policy'] == '0' && this.RowArray[i]['is_parent_cross_section'] == '0' || 
              this.RowArray[i]['is_parent_policy'] == '0' && this.RowArray[i]['is_parent_cross_section'] == '1' || 
              this.RowArray[i]['is_parent_policy'] == '1' && this.RowArray[i]['is_parent_cross_section'] == '1') {
                checkcros = 0;
              }else{
                checkcros = 1;
              }

              checkcros = 0;
              
              /************/
              if (checkcros == 0) {            
                if (no_agelimit_check == true && age_diff == true) {

                  if (relation_age_depend == true) {

                    if (age_diff_value >= relation_age_difference) {                    

                      var data: any = {
                        "relation_id": Number(relation_id),
                        "relation": relation,
                        "epm_id": '',
                        "first_name": $('#first_name_' + i).val(),
                        "last_name": $('#last_name_' + i).val(),
                        "gender": $('#gender_' + i).val(),
                        "member_mob_no": $('#insured_contact_number_' + i).val(),
                        "member_email": $('#insured_email_' + i).val(),
                        "dob": $('#insured_dob_' + i).val(),
                        "marriage_date": $('#marriage_date_' + i).val(),
                        "image_url": parent_imgurl
                      }

                      // API Call
                      var postdata_parentP1 = {
                        "tokenable_id": localStorage.getItem('tokenable_id'),
                        "policy_id": this.RowArray[i]['policy_id'],
                        "relation_id": relation_id,
                        "relation": relation,
                        "gender": gender,
                        "first_name": first_name,
                        "last_name": last_name,
                        "contact": contact,
                        "contact_email": contact_email,
                        "dob": dob,
                        "si_array":si_array,
                        "rater_id":rater_id,
                        "marriage_date": marriage_date,
                        "document": this.RowArray[i]['document'],
                        "age_diff_flag": false
                      };
                      // console.log(postdata);        

                      this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP1, localStorage.getItem('Token'))
                        .subscribe((result) => {                        

                          if (result.status == true) {
                            this.Savetoggle = "block";                        
                            arr.push(data);
                            this.emptyValues(id);                    
                            this.addErrorPopup = 'none';
                            this.getsteptwodata();
                          }else{
                            this.addErrorPopup = 'block';
                          }

                        }, (error) => {

                          if (error.error.message == 'Unauthenticated.') {
                            localStorage.clear();
                            localStorage.setItem('resstatus', 'false');
                            this.route.navigate(['/home']);
                          }
                        });
                      //END

                      $('.age_equal_to_error_' + i).css('display', 'none');

                    } else {

                      var data: any = {
                        "relation_id": Number(relation_id),
                        "relation": relation,
                        "epm_id": '',
                        "first_name": $('#first_name_' + i).val(),
                        "last_name": $('#last_name_' + i).val(),
                        "gender": $('#gender_' + i).val(),
                        "member_mob_no": $('#insured_contact_number_' + i).val(),
                        "member_email": $('#insured_email_' + i).val(),
                        "dob": $('#insured_dob_' + i).val(),
                        "marriage_date": $('#marriage_date_' + i).val(),
                        "image_url": parent_imgurl
                      }

                      // API Call
                      var postdata_parentP2 = {
                        "tokenable_id": localStorage.getItem('tokenable_id'),
                        "policy_id": this.RowArray[i]['policy_id'],
                        "relation_id": relation_id,
                        "relation": relation,
                        "gender": gender,
                        "first_name": first_name,
                        "last_name": last_name,
                        "contact": contact,
                        "contact_email": contact_email,
                        "dob": dob,
                        "si_array":si_array,
                        "rater_id":rater_id,
                        "marriage_date": marriage_date,
                        "document": this.RowArray[i]['document'],
                        "age_diff_flag": true
                      };
                      // console.log(postdata);        

                      this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP2, localStorage.getItem('Token'))
                        .subscribe((result) => {                        

                          if (result.status == true) {
                            this.Savetoggle = "block";
                            arr.push(data);
                            this.emptyValues(id);                    
                            this.addErrorPopup = 'none';                        
                            this.getsteptwodata();
                          }else{
                            this.addErrorPopup = 'block';
                          }

                        }, (error) => {

                          if (error.error.message == 'Unauthenticated.') {
                            localStorage.clear();
                            localStorage.setItem('resstatus', 'false');
                            this.route.navigate(['/home']);
                          }
                        });
                      //END                                    

                      $('.age_equal_to_error_' + i).css('display', 'block');
                      $('.age_greater_error_' + i).css('display', 'none');
                    }

                  }

                  $('.age_greater_error_' + i).css('display', 'none');

                } else {

                  $('.age_greater_error_' + i).css('display', 'block');
                  $('.age_equal_to_error_' + i).css('display', 'none');

                }
                // $('.crossParentCheckDiv_' + i).css('display', 'none');
                this.Errortoggle = "none";
                this.ErrortoggleMsg = "";
              }else{

                let Father:any = 0;
                let FatherInLaw:any = 0;
                let Mother:any = 0;
                let MotherInLaw:any = 0;

                for(var p = 0; p < this.RowArray[i]['insured_members'].length; p++){
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Father'){
                    Father = 1;
                  }
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Father-in-law'){
                    FatherInLaw = 1;
                  }
                  
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Mother'){
                    Mother = 1;
                  }
                  if (this.RowArray[i]['insured_members'][p]['relation'] == 'Mother-in-law'){
                    MotherInLaw = 1;
                  }
                }
                              
                if(relation == 'Father' && FatherInLaw == 1 || relation == 'Father-in-law' && Father == 1 ||
                relation == 'Mother' && MotherInLaw == 1 || relation == 'Mother-in-law' && Mother == 1){               
                  // $('.crossParentCheckDiv_' + i).css('display', 'block');
                  this.toastService.showWarning('Warning', 'No Cross Parent is Allowed!!');

                  // this.Errortoggle = "block";
                  // this.ErrortoggleMsg = "No Cross Parent is Allowed!!";
                }else{
                  
                  /********* Tick Validation **********/
                  if (this.RowArray[i]['is_parent_policy'] == '1'){

                    let relation_allowed_for_employee_father_in_law:any;
                    let relation_allowed_for_employee_mother_in_law:any;
                    let tickflag_father_in_law:any;
                    let tickflag_mother_in_law:any;
                    
                    for(var p = 0; p < this.RowArray[i]['relation_data'].length; p++){                    
                      if (this.RowArray[i]['relation_data'][p]['relation'] == 'Father-in-law'){
                        relation_allowed_for_employee_father_in_law = this.RowArray[i]['relation_data'][p]['relation_allowed_for_employee'];
                      }                   
                      
                      if (this.RowArray[i]['relation_data'][p]['relation'] == 'Mother-in-law'){
                        relation_allowed_for_employee_mother_in_law = this.RowArray[i]['relation_data'][p]['relation_allowed_for_employee'];
                      }                   
                    }

                    // Father
                    if(relation == 'Father-in-law' && relation_allowed_for_employee_father_in_law == '0'){
                      tickflag_father_in_law = 1;
                      this.gendertype = 'Male';
                    }else if(relation == 'Father-in-law' && relation_allowed_for_employee_father_in_law == '1'){
                      tickflag_father_in_law = 0;
                      this.gendertype = 'Female';
                    }else{
                      tickflag_father_in_law = 1;
                      this.gendertype = 'Both';
                    }

                    // Mother
                    if(relation == 'Mother-in-law' && relation_allowed_for_employee_mother_in_law == '0'){
                      tickflag_mother_in_law = 1;
                      this.gendertype = 'Male';
                    }else if(relation == 'Mother-in-law' && relation_allowed_for_employee_mother_in_law == '1'){
                      tickflag_mother_in_law = 0;
                      this.gendertype = 'Female';
                    }else{
                      tickflag_mother_in_law = 1;
                      this.gendertype = 'Both';
                    }

                    // console.log(relation, 'Father-in-law', tickflag_father_in_law);
                    // console.log(relation, 'Mother-in-law', tickflag_mother_in_law);

                    if(relation == 'Father-in-law'){
                      if(relation == 'Father-in-law' && tickflag_father_in_law == 1){

                        if (no_agelimit_check == true && age_diff == true) {
    
                          if (relation_age_depend == true) {
          
                            if (age_diff_value >= relation_age_difference) {                    
          
                              var data: any = {
                                "relation_id": Number(relation_id),
                                "relation": relation,
                                "epm_id": '',
                                "first_name": $('#first_name_' + i).val(),
                                "last_name": $('#last_name_' + i).val(),
                                "gender": $('#gender_' + i).val(),
                                "member_mob_no": $('#insured_contact_number_' + i).val(),
                                "member_email": $('#insured_email_' + i).val(),
                                "dob": $('#insured_dob_' + i).val(),
                                "marriage_date": $('#marriage_date_' + i).val(),
                                "image_url": parent_imgurl
                              }
          
                              // API Call
                              var postdata_parentP3 = {
                                "tokenable_id": localStorage.getItem('tokenable_id'),
                                "policy_id": this.RowArray[i]['policy_id'],
                                "relation_id": relation_id,
                                "relation": relation,
                                "gender": gender,
                                "first_name": first_name,
                                "last_name": last_name,
                                "contact": contact,
                                "contact_email": contact_email,
                                "dob": dob,
                                "si_array":si_array,
                                "rater_id":rater_id,
                                "marriage_date": marriage_date,
                                "document": this.RowArray[i]['document'],
                                "age_diff_flag": false
                              };
                              // console.log(postdata);        
          
                              this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP3, localStorage.getItem('Token'))
                                .subscribe((result) => {                        
          
                                  if (result.status == true) {
                                    this.Savetoggle = "block";                        
                                    arr.push(data);
                                    this.emptyValues(id);                    
                                    this.addErrorPopup = 'none';
                                    this.getsteptwodata();
                                  }else{
                                    this.addErrorPopup = 'block';
                                  }
          
                                }, (error) => {
          
                                  if (error.error.message == 'Unauthenticated.') {
                                    localStorage.clear();
                                    localStorage.setItem('resstatus', 'false');
                                    this.route.navigate(['/home']);
                                  }
                                });
                              //END
          
                              $('.age_equal_to_error_' + i).css('display', 'none');
          
                            } else {
          
                              var data: any = {
                                "relation_id": Number(relation_id),
                                "relation": relation,
                                "epm_id": '',
                                "first_name": $('#first_name_' + i).val(),
                                "last_name": $('#last_name_' + i).val(),
                                "gender": $('#gender_' + i).val(),
                                "member_mob_no": $('#insured_contact_number_' + i).val(),
                                "member_email": $('#insured_email_' + i).val(),
                                "dob": $('#insured_dob_' + i).val(),
                                "marriage_date": $('#marriage_date_' + i).val(),
                                "image_url": parent_imgurl
                              }
          
                              // API Call
                              var postdata_parentP4 = {
                                "tokenable_id": localStorage.getItem('tokenable_id'),
                                "policy_id": this.RowArray[i]['policy_id'],
                                "relation_id": relation_id,
                                "relation": relation,
                                "gender": gender,
                                "first_name": first_name,
                                "last_name": last_name,
                                "contact": contact,
                                "contact_email": contact_email,
                                "dob": dob,
                                "si_array":si_array,
                                "rater_id":rater_id,
                                "marriage_date": marriage_date,
                                "document": this.RowArray[i]['document'],
                                "age_diff_flag": true
                              };
                              // console.log(postdata);        
          
                              this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP4, localStorage.getItem('Token'))
                                .subscribe((result) => {                        
          
                                  if (result.status == true) {
                                    this.Savetoggle = "block";
                                    arr.push(data);
                                    this.emptyValues(id);                    
                                    this.addErrorPopup = 'none';                        
                                    this.getsteptwodata();
                                  }else{
                                    this.addErrorPopup = 'block';
                                  }
          
                                }, (error) => {
          
                                  if (error.error.message == 'Unauthenticated.') {
                                    localStorage.clear();
                                    localStorage.setItem('resstatus', 'false');
                                    this.route.navigate(['/home']);
                                  }
                                });
                              //END                                    
          
                              $('.age_equal_to_error_' + i).css('display', 'block');
                              $('.age_greater_error_' + i).css('display', 'none');
                            }
          
                          }
          
                          $('.age_greater_error_' + i).css('display', 'none');
          
                        } else {
          
                          $('.age_greater_error_' + i).css('display', 'block');
                          $('.age_equal_to_error_' + i).css('display', 'none');
          
                        }
    
                        // $('.parents_allowed_' + i).css('display', 'none');
                        this.Errortoggle = "none";
                        this.ErrortoggleMsg = "";                    
                      }else{
                        console.log('---2--');
                        // $('.parents_allowed_' + i).css('display', 'block');
                        this.toastService.showWarning('Warning', 'Relation Allowed for this employee does allows to add this employee!');

                        // this.Errortoggle = "block";
                        // this.ErrortoggleMsg = "Relation Allowed for this employee does allows to add this employee";
                      }
                    }
                    
                    if(relation == 'Mother-in-law'){                  
                      if(relation == 'Mother-in-law' && tickflag_mother_in_law == 1){

                        if (no_agelimit_check == true && age_diff == true) {

                          if (relation_age_depend == true) {
          
                            if (age_diff_value >= relation_age_difference) {                    
          
                              var data: any = {
                                "relation_id": Number(relation_id),
                                "relation": relation,
                                "epm_id": '',
                                "first_name": $('#first_name_' + i).val(),
                                "last_name": $('#last_name_' + i).val(),
                                "gender": $('#gender_' + i).val(),
                                "member_mob_no": $('#insured_contact_number_' + i).val(),
                                "member_email": $('#insured_email_' + i).val(),
                                "dob": $('#insured_dob_' + i).val(),
                                "marriage_date": $('#marriage_date_' + i).val(),
                                "image_url": parent_imgurl
                              }
          
                              // API Call
                              var postdata_parentP4 = {
                                "tokenable_id": localStorage.getItem('tokenable_id'),
                                "policy_id": this.RowArray[i]['policy_id'],
                                "relation_id": relation_id,
                                "relation": relation,
                                "gender": gender,
                                "first_name": first_name,
                                "last_name": last_name,
                                "contact": contact,
                                "contact_email": contact_email,
                                "dob": dob,
                                "si_array":si_array,
                                "rater_id":rater_id,
                                "marriage_date": marriage_date,
                                "document": this.RowArray[i]['document'],
                                "age_diff_flag": false
                              };
                              // console.log(postdata);        
          
                              this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP4, localStorage.getItem('Token'))
                                .subscribe((result) => {                        
          
                                  if (result.status == true) {
                                    this.Savetoggle = "block";                        
                                    arr.push(data);
                                    this.emptyValues(id);                    
                                    this.addErrorPopup = 'none';
                                    this.getsteptwodata();
                                  }else{
                                    this.addErrorPopup = 'block';
                                  }
          
                                }, (error) => {
          
                                  if (error.error.message == 'Unauthenticated.') {
                                    localStorage.clear();
                                    localStorage.setItem('resstatus', 'false');
                                    this.route.navigate(['/home']);
                                  }
                                });
                              //END
          
                              $('.age_equal_to_error_' + i).css('display', 'none');
          
                            } else {
          
                              var data: any = {
                                "relation_id": Number(relation_id),
                                "relation": relation,
                                "epm_id": '',
                                "first_name": $('#first_name_' + i).val(),
                                "last_name": $('#last_name_' + i).val(),
                                "gender": $('#gender_' + i).val(),
                                "member_mob_no": $('#insured_contact_number_' + i).val(),
                                "member_email": $('#insured_email_' + i).val(),
                                "dob": $('#insured_dob_' + i).val(),
                                "marriage_date": $('#marriage_date_' + i).val(),
                                "image_url": parent_imgurl
                              }
          
                              // API Call
                              var postdata_parentP5 = {
                                "tokenable_id": localStorage.getItem('tokenable_id'),
                                "policy_id": this.RowArray[i]['policy_id'],
                                "relation_id": relation_id,
                                "relation": relation,
                                "gender": gender,
                                "first_name": first_name,
                                "last_name": last_name,
                                "contact": contact,
                                "contact_email": contact_email,
                                "dob": dob,
                                "si_array":si_array,
                                "rater_id":rater_id,
                                "marriage_date": marriage_date,
                                "document": this.RowArray[i]['document'],
                                "age_diff_flag": true
                              };
                              // console.log(postdata);        
          
                              this.clientCreationService.steptwosaveinsuredmemberdata(postdata_parentP5, localStorage.getItem('Token'))
                                .subscribe((result) => {                        
          
                                  if (result.status == true) {
                                    this.Savetoggle = "block";
                                    arr.push(data);
                                    this.emptyValues(id);                    
                                    this.addErrorPopup = 'none';                        
                                    this.getsteptwodata();
                                  }else{
                                    this.addErrorPopup = 'block';
                                  }
          
                                }, (error) => {
          
                                  if (error.error.message == 'Unauthenticated.') {
                                    localStorage.clear();
                                    localStorage.setItem('resstatus', 'false');
                                    this.route.navigate(['/home']);
                                  }
                                });
                              //END                                    
          
                              $('.age_equal_to_error_' + i).css('display', 'block');
                              $('.age_greater_error_' + i).css('display', 'none');
                            }
          
                          }
          
                          $('.age_greater_error_' + i).css('display', 'none');
          
                        } else {
          
                          $('.age_greater_error_' + i).css('display', 'block');
                          $('.age_equal_to_error_' + i).css('display', 'none');
          
                        }

                        // $('.parents_allowed_' + i).css('display', 'none');
                        this.Errortoggle = "none";
                        this.ErrortoggleMsg = "";

                      }else{
                        // $('.parents_allowed_' + i).css('display', 'block');
                        // console.log('---3--');
                        this.toastService.showWarning('Warning', 'Relation Allowed for this employee does allows to add this employee');

                        // this.Errortoggle = "block";
                        // this.ErrortoggleMsg = "Relation Allowed for this employee does allows to add this employee";
                      }
                    }

                  }else{

                  }
                  /********************************** */

                  // $('.crossParentCheckDiv_' + i).css('display', 'none');
                  // this.Errortoggle = "none";
                  // this.ErrortoggleMsg = "";

                }

                // $('.crossParentCheckDiv_' + i).css('display', 'block');
                // this.Errortoggle = "block";
                // this.ErrortoggleMsg = "No Cross Parent is Allowed!!";
                
              }       
              /************************************/

            }else{            
              $('.duplicateparent_' + i).css('display', 'block');
            }

          }else{
          }

          /*******Siblings********/
          if(siblings_data == 1){
                        
            let min:any;
            let max:any;
            let no_agelimit_check:any;

            for(var k = 0; k < this.RowArray[i]['relation_data'].length; k++){
              if(this.RowArray[i]['relation_data'][k]['relation'] == 'Brother' || this.RowArray[i]['relation_data'][k]['relation'] == 'Sister'){
                if(this.RowArray[i]['relation_data'][k]['no_agelimit_check'] == '0'){
                  min = this.RowArray[i]['relation_data'][k]['min_age'];
                  max = this.RowArray[i]['relation_data'][k]['max_age'];
                  this.min_age_add = min;
                  this.max_age_add = max;
                  no_agelimit_check = true;
                }else{
                  no_agelimit_check = false;
                }
              }                
            }             
                          
            const agecal:any = this.calage(dob);
            let age_diff:any = (agecal >= min && agecal <= max) ? true : false;
          
            /************/
            if (no_agelimit_check == true && age_diff == true) {

              var data: any = {
                "relation_id": Number(relation_id),
                "relation": relation,
                "epm_id": '',
                "first_name": $('#first_name_' + i).val(),
                "last_name": $('#last_name_' + i).val(),
                "gender": $('#gender_' + i).val(),
                "member_mob_no": $('#insured_contact_number_' + i).val(),
                "member_email": $('#insured_email_' + i).val(),
                "dob": $('#insured_dob_' + i).val(),
                "marriage_date": $('#marriage_date_' + i).val(),
                "image_url": siblings_imgurl
              }

              // arr.push(data);

              // API Call
              var postdata_siblings_data = {
                "tokenable_id": localStorage.getItem('tokenable_id'),
                "policy_id": this.RowArray[i]['policy_id'],
                "relation_id": relation_id,
                "relation": relation,
                "gender": gender,
                "first_name": first_name,
                "last_name": last_name,
                "contact": contact,
                "contact_email": contact_email,
                "dob": dob,
                "si_array":si_array,
                "rater_id":rater_id,
                "marriage_date": marriage_date,
                "document": this.RowArray[i]['document'],
                "age_diff_flag": false
              };
              // console.log(postdata);        

              this.clientCreationService.steptwosaveinsuredmemberdata(postdata_siblings_data, localStorage.getItem('Token'))
                .subscribe((result) => {                  

                  if (result.status == true) {
                    this.Savetoggle = "block";
                    arr.push(data);
                    this.emptyValues(id);                    
                    this.addErrorPopup = 'none';
                    this.getsteptwodata();
                  }else{
                    this.addErrorPopup = 'block';
                  }

                }, (error) => {

                  if (error.error.message == 'Unauthenticated.') {
                    localStorage.clear();
                    localStorage.setItem('resstatus', 'false');
                    this.route.navigate(['/home']);
                  }
                });
              //END

              $('.age_greater_error_'+i).css('display', 'none');

            } else {

              $('.age_greater_error_'+i).css('display', 'block');

            }
            /************/
          
          }else{          
          }

          /*******Spouse********/
          if(spouse_data == 1){
            
            let min:any;
            let max:any;
            let no_agelimit_check:any;

            for(var k = 0; k < this.RowArray[i]['relation_data'].length; k++){
              if(this.RowArray[i]['relation_data'][k]['relation'] == 'Spouse/Partner'){
                if(this.RowArray[i]['relation_data'][k]['no_agelimit_check'] == '0'){
                  min = this.RowArray[i]['relation_data'][k]['min_age'];
                  max = this.RowArray[i]['relation_data'][k]['max_age'];
                  this.min_age_add = min;
                  this.max_age_add = max;
                  no_agelimit_check = true;
                }else{
                  no_agelimit_check = false;
                }
              }                
            }             
                          
            const agecal:any = this.calage(dob);
            let age_diff:any = (agecal >= min && agecal <= max) ? true : false;
          
            /************/
            if (no_agelimit_check == true && age_diff == true) {

              var data: any = {
                "relation_id": Number(relation_id),
                "relation": relation,
                "epm_id": '',
                "first_name": $('#first_name_' + i).val(),
                "last_name": $('#last_name_' + i).val(),
                "gender": $('#gender_' + i).val(),
                "member_mob_no": $('#insured_contact_number_' + i).val(),
                "member_email": $('#insured_email_' + i).val(),
                "dob": $('#insured_dob_' + i).val(),
                "marriage_date": $('#marriage_date_' + i).val(),
                "image_url": spouse_imgurl,
                "marriage_certificate_document": this.RowArray[i]['marriage_certificate_document']
              }

              // arr.push(data);

              // API Call
              var postdata_spouse_data = {
                "tokenable_id": localStorage.getItem('tokenable_id'),
                "policy_id": this.RowArray[i]['policy_id'],
                "relation_id": relation_id,
                "relation": relation,
                "gender": gender,
                "first_name": first_name,
                "last_name": last_name,
                "contact": contact,
                "contact_email": contact_email,
                "dob": dob,
                "si_array":si_array,
                "rater_id":rater_id,
                "marriage_date": marriage_date,
                "image_url": parent_in_laws_imgurl,
                "marriage_certificate_document": this.RowArray[i]['marriage_certificate_document'],
                "age_diff_flag": false
              };
              // console.log(postdata);        

              this.clientCreationService.steptwosaveinsuredmemberdata(postdata_spouse_data, localStorage.getItem('Token'))
                .subscribe((result) => {

                  if (result.status == true) {
                    this.Savetoggle = "block";
                    arr.push(data);
                    this.emptyValues(id);                    
                    this.addErrorPopup = 'none';
                    this.getsteptwodata();
                  }else{
                    this.addErrorPopup = 'block';
                  }

                }, (error) => {

                  if (error.error.message == 'Unauthenticated.') {
                    localStorage.clear();
                    localStorage.setItem('resstatus', 'false');
                    this.route.navigate(['/home']);
                  }
                });
              //END

              $('.age_greater_error_'+i).css('display', 'none');

            } else {

              $('.age_greater_error_'+i).css('display', 'block');

            }
            /************/
          
          }else{          
          }                                                  

          //Relation Data
          let relation_data = this.RowArray[i]['relation_data'];
          for(let i = 0; i < relation_data.length; i++){
            let id = relation_data[i]['relation_id'];

            if(relation_id == id){
              // relation_data.splice(i,1);
              // $('#relation_with_employee_'+i).val(0);
            }

          }
          
        }                 

      }
       
    }                    
    /*******************/


    /**********Update All Max Values*********/
    for(let i = 0; i < this.RowArray.length; i++){  
          
      let childs:any = 0;
      let parents:any = 0;
      var parents_in_laws:any = 0;
      var siblings:any = 0;
      var spouse:any = 0;
      
      //insured_members
      for(var j = 0; j < this.RowArray[i]['insured_members'].length; j++){

          // max_child
          if(this.RowArray[i]['insured_members'][j]['relation'] == 'Son' || this.RowArray[i]['insured_members'][j]['relation'] == 'Daughter'){
            childs = childs + 1; 
          }

          // max_parents
          if(this.RowArray[i]['insured_members'][j]['relation'] == 'Father' || this.RowArray[i]['insured_members'][j]['relation'] == 'Mother'){
            parents = parents + 1; 
          }

          // parents-in-laws
          if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Mother-in-law'){
            parents_in_laws = parents_in_laws + 1; 
          }

          // siblings
          if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Sister' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Brother'){
            siblings = siblings + 1; 
          }

          // spouse
          if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Spouse/Partner'){
            spouse = spouse + 1; 
          }
                                 
      }

      this.RowArray[i]['max_child_filled'] = childs;
      this.RowArray[i]['max_parents_filled'] = parents;
      this.RowArray[i]['max_parents_inlaw_filled'] = parents_in_laws;
      this.RowArray[i]['max_siblings_filled'] = siblings;
      this.RowArray[i]['max_spouse_filled'] = spouse;

    }             
    /*******************/

    console.log(this.RowArray);
    
  }

  showNomineepopup(id:any, k:any){

    // console.log(id, k);

    this.editID = id;
    this.Nomineepopup = 'block';            

    for(var i = 0; i < this.RowArray.length; i++){      

      if(id == i){
        // console.log(this.RowArray[i]);

        for(var j = 0; j < this.RowArray[i]['insured_members'].length; j++){

          if(k == j){
            // console.log(this.RowArray[i]['insured_members'][j].epm_id);

            $('#index_edit').val(i);
            $('#index_edit_child').val(k);
            $('#relation_id_edit').val(this.RowArray[i]['insured_members'][j]['relation_id']);
            $('#emp_id_edit').val(this.RowArray[i]['insured_members'][j]['epm_id']);

            $('#relation_with_employee_edit').val(this.RowArray[i]['insured_members'][j]['relation']);
            this.fnameflag = this.RowArray[i]['insured_members'][j]['relation'];
            
            $('#gender_edit').val(this.RowArray[i]['insured_members'][j]['gender']);
            $('#first_name_edit').val(this.RowArray[i]['insured_members'][j]['first_name']);
            $('#last_name_edit').val(this.RowArray[i]['insured_members'][j]['last_name']);
            $('#insured_contact_number_edit').val(this.RowArray[i]['insured_members'][j]['member_mob_no']);
            $('#insured_email_edit').val(this.RowArray[i]['insured_members'][j]['member_email']);
            $('#insured_dob_edit').val(this.RowArray[i]['insured_members'][j]['dob']);
            $('#marriage_date_edit').val(this.RowArray[i]['insured_members'][j]['marriage_date']);

            this.show_si_premium = this.RowArray[i]['show_si_premium'];
            // console.log(this.RowArray[i]['show_si_premium']);
            this.is_premium_tax_edit_popup = this.RowArray[i]['is_premium_tax'];
            this.member_suminsured = this.RowArray[i]['insured_members'][j]['member_suminsured'];
            this.employee_premium = this.RowArray[i]['insured_members'][j]['employee_premium'];
            this.employer_premium = this.RowArray[i]['insured_members'][j]['employer_premium'];

            if(this.RowArray[i]['insured_members'][j]['relation_id'] == '2'){

              $('.is_adopted_div_edit').css('display', 'none');
              $('.birth_certificate_file_edit').css('display', 'none');
              $('.is_unmarried_child_div_edit').css('display', 'none');
              $('.is_special_child_div_edit').css('display', 'none');
              $('.declaration_edit').css('display', 'none');

              $('.marriage_date_edit').css('display','block');

              // console.log("--------",this.RowArray[i]['marraige_certificate_spouse']);                           
                                                                                                                
              if(this.RowArray[i]['insured_members'][j]['marriage_certificate_document'] != '' || this.RowArray[i]['insured_members'][j]['marriage_certificate_document'] != undefined){                               
                this.marraigefile_url = this.RowArray[i]['insured_members'][j]['marriage_certificate_document'];

                if(this.RowArray[i]['marraige_certificate_spouse'] == '0'){
                  $('.marriage_certificate_file_edit').css('display','none');
                }else{
                  $('.marriage_certificate_file_edit').css('display', 'block');
                } 
                
              }else{
                this.marraigefile_url = '';
                $('.marriage_certificate_file_edit').css('display', 'none');
              }

              /******/
              
            }else{
              $('.marriage_date_edit').css('display','none');
              $('.marriage_certificate_file_edit').css('display','none');
            }            
            
            if(this.RowArray[i]['insured_members'][j]['relation'] == 'Self'){

              $('.relation_with_employee_edit').css('display', 'none');
              $('.deltbn').css('display', 'none');
              $('.savebtn').css('display', 'none');

              $('#gender_edit').prop('disabled', true);
              $('#first_name_edit').prop('disabled', true);
              $('#last_name_edit').prop('disabled', true);
              $('#insured_contact_number_edit').prop('disabled', true);
              $('#insured_email_edit').prop('disabled', true);
              $('#insured_dob_edit').prop('disabled', true);

            }else{

              $('.relation_with_employee_edit').css('display', 'block');
              $('.deltbn').css('display', 'flex');
              $('.savebtn').css('display', 'flex');

              $('#relation_with_employee_edit').prop('disabled', true);
              $('#gender_edit').prop('disabled', false);
              $('#first_name_edit').prop('disabled', false);
              $('#last_name_edit').prop('disabled', false);
              $('#insured_contact_number_edit').prop('disabled', false);
              $('#insured_email_edit').prop('disabled', false);
              $('#insured_dob_edit').prop('disabled', false);

            }                 
            
            if(this.RowArray[i]['insured_members'][j]['relation'] == 'Son' || this.RowArray[i]['insured_members'][j]['relation'] == 'Daughter'){
                           
              if(this.RowArray[i]['has_adopted_child'] == '0'){
                $('.is_adopted_div_edit').css('display', 'none');      
              }else{
                $('.is_adopted_div_edit').css('display', 'block');
              }

              if(this.RowArray[i]['has_special_child'] == '0'){
                $('.is_special_child_div_edit').css('display', 'none');      
              }else{
                $('.is_special_child_div_edit').css('display', 'block');
              }

              if(this.RowArray[i]['has_unmarried_child'] == '0'){
                $('.is_unmarried_child_div_edit').css('display', 'none');      
              }else{
                $('.is_unmarried_child_div_edit').css('display', 'block');
              }
              
              if(this.RowArray[i]['birth_certificate_child'] == '0'){
                $('.birth_certificate_file_edit').css('display', 'none');
              }else{
                $('.birth_certificate_file_edit').css('display', 'block');
              }                                    
              
              this.birth_certificate_document_url = this.RowArray[i]['insured_members'][j]['birth_certificate_document'];

              if(this.RowArray[i]['insured_members'][j]['is_adopted_child'] == true){   
                $("#is_adopted_child_edit").prop("checked", true);                             
                $('.declaration_edit').css('display', 'block');
                this.declaration_file_url = this.RowArray[i]['insured_members'][j]['doc_adopted_child'];
              }else{                
                $("#is_adopted_child_edit").prop("checked", false);
                $('.declaration_edit').css('display', 'none');
                this.declaration_file_url = '';
              }

              if(this.RowArray[i]['insured_members'][j]['has_special_child'] == true){   
                $("#is_special_child_edit").prop("checked", true);                                             
              }else{                
                $("#is_special_child_edit").prop("checked", false);               
              }

              if(this.RowArray[i]['insured_members'][j]['has_unmarried_child'] == true){   
                $("#is_unmarried_child_edit").prop("checked", true);                             
              }else{                
                $("#is_unmarried_child_edit").prop("checked", false);               
              }

            }else{
              $('.is_adopted_div_edit').css('display', 'none');
              $('.birth_certificate_file_edit').css('display', 'none');
              $('.is_unmarried_child_div_edit').css('display', 'none');
              $('.is_special_child_div_edit').css('display', 'none');
            }

            if(this.RowArray[i]['insured_members'][j]['relation_id'] == '1' ||
            this.RowArray[i]['insured_members'][j]['relation_id'] == '5' ||
            this.RowArray[i]['insured_members'][j]['relation_id'] == '6' ||
            this.RowArray[i]['insured_members'][j]['relation_id'] == '7' ||
            this.RowArray[i]['insured_members'][j]['relation_id'] == '8' || 
            this.RowArray[i]['insured_members'][j]['relation_id'] == '9' ||
            this.RowArray[i]['insured_members'][j]['relation_id'] == '10'){

              $('.declaration_edit').css('display', 'none');
              $('.is_adopted_div_edit').css('display', 'none');
              $('.birth_certificate_file_edit').css('display', 'none');
              $('.is_unmarried_child_div_edit').css('display', 'none');
              $('.is_special_child_div_edit').css('display', 'none');
              $('.marriage_date_edit').css('display','none');
              $('.marriage_certificate_file_edit').css('display','none');

            }

            // this.getSIArray(this.RowArray[i]['insured_members'][j]['relation_id'], this.RowArray[i]['insured_members'][j]['dob'], id);

            if(this.RowArray[i]['insured_members'][j]['si_array'] != ''){
              $('.si_array_edit_div').css('display','block');              
              $('#si_array_edit').val(this.RowArray[i]['insured_members'][j]['si_array']);       
            }else{
              $('.si_array_edit_div').css('display','none');
            }

            if(this.RowArray[i]['insured_members'][j]['relation_id'] != '1'){
            
              // Lock-in Validations
              if(this.RowArray[i]['insured_members'][j]['has_member_removal_lock_in'] == '1'){
                
                // upload field
                if(this.RowArray[i]['insured_members'][j]['is_lockin_death_cert'] == '1'){
                  $('.death_certificate_file_edit').css('display','block');
                }else{                
                  $('.death_certificate_file_edit').css('display','none');
                }

                // delete button
                if(this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] == null || this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] == undefined || this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] == ''){
                  // $('.is_deleted').css('display','none');
                  this.death_certificate_document_url = null;
                  $('#death_certificate_file_error_view').css('display','none');
                }else{
                  // $('.is_deleted').css('display','block');
                  this.death_certificate_document_url = this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'];
                  $('#death_certificate_file_error_view').css('display','block');
                }

                console.log(this.death_certificate_document_url);

              }else{
                $('.death_certificate_file_edit').css('display','none');
                // $('.is_deleted').css('display','block');
              }

            }else{
              $('.death_certificate_file_edit').css('display','none');
            }
            
          }

        }
        
      }else{

      }      

    }   

    // console.log(this.NomineeArray);
    
  }

  updateNominee(){    

    let id:any = $('#index_edit').val();    
    let cid:any = $('#index_edit_child').val();  
    let relation_id_edit:any = $('#relation_id_edit').val();
    let emp_id_edit:any = $('#emp_id_edit').val();  
    
    let gender:any;
    let first_name:any;
    let last_name:any;
    let contact:any;
    let contact_email:any;
    let dob:any;
    let marriage_date:any;
    let emp_id:any;
    var has_special_child:any;
    var has_unmarried_child:any;

    let si_array = $('#si_array_edit').val();
    let rater_id = $('#si_array_edit option:selected').text();
    
    // console.log(id, '-', cid);

    this.Nomineepopup = "none";

    for(var i = 0; i < this.RowArray.length; i++){      

      if(id == i){
        // console.log(this.RowArray[i]);

        let arr = this.RowArray[i]['insured_members'];

        for(var j = 0; j < arr.length; j++){
          
          if(cid == j){

            if (cid > -1) { 
                           
              for (let [key, value] of Object.entries(arr[j])) {                
                // console.log(arr[j][key]);

                if(key == 'gender'){
                  arr[j][key] = $('#gender_edit').val();
                  gender = $('#gender_edit').val();
                }                
                if(key == 'first_name'){
                  arr[j][key] = $('#first_name_edit').val();
                  first_name = $('#first_name_edit').val();
                }
                if(key == 'last_name'){
                  arr[j][key] = $('#last_name_edit').val();
                  last_name = $('#last_name_edit').val();
                }
                if(key == 'member_mob_no'){
                  arr[j][key] = $('#insured_contact_number_edit').val();
                  contact = $('#insured_contact_number_edit').val();
                }
                if(key == 'member_email'){
                  arr[j][key] = $('#insured_email_edit').val();
                  contact_email = $('#insured_email_edit').val();
                }                
                if(key == 'dob'){
                  arr[j][key] = $('#insured_dob_edit').val();
                  dob = $('#insured_dob_edit').val();
                }
                if(key == 'marriage_date'){
                  arr[j][key] = $('#marriage_date_edit').val();
                  marriage_date = $('#marriage_date_edit').val();
                } 
                if(key == 'emp_id'){
                  arr[j][key] = $('#emp_id_edit').val();
                  emp_id_edit = $('#emp_id_edit').val();
                }
                if(key == 'has_special_child'){
                  arr[j][key] = $('#is_special_child_').val();
                  has_special_child = $('#is_special_child_'+i).val();
                }
                if(key == 'has_unmarried_child'){
                  arr[j][key] = $('#is_unmarried_child_').val();
                  has_unmarried_child = $('#is_unmarried_child_'+i).val();
                }
                
              }              
              
            }else{
              // console.log('no cid');
            }          
            
            // API Call
            var postdata = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "policy_id": this.RowArray[i]['policy_id'],
              "relation_id": relation_id_edit,
              "emp_id_edit": emp_id_edit,
              "gender": gender,
              "first_name": first_name,
              "last_name": last_name,
              "contact": contact,
              "contact_email": contact_email,
              "dob": dob,
              // "si_array": si_array,
              // "rater_id": rater_id,
              "marriage_date": marriage_date,
              "marriage_certificate_document": this.RowArray[i]['marriage_certificate_document'],
              "is_adopted_child": $('#is_adopted_child_edit').is(':checked'),
              "document": this.RowArray[i]['document'],
              "birth_certificate_document": this.RowArray[i]['birth_certificate_document'],
              "has_special_child": $('#is_special_child_edit').is(':checked'),
              "has_unmarried_child": $('#is_unmarried_child_edit').is(':checked'),
              "upload_lockin_death_cert": arr[j]['upload_lockin_death_cert'],
            };

            console.log(postdata);

            this.clientCreationService.steptwoupdateinsuredmemberdata(postdata, localStorage.getItem('Token'))
              .subscribe((result) => {
                
                if (result.status == true) {
                  this.toastService.showSuccess('Success', 'Member Updated Sucessfully !');

                  // this.Updatetoggle = "block";
                }                   
                        
              }, (error) => {         
                this.toastService.showFailed('Failed', 'There was some error');

                if(error.error.message == 'Unauthenticated.'){
                  localStorage.clear();
                  localStorage.setItem('resstatus', 'false');
                  this.route.navigate(['/home']);
                }
            });
            //END

          }
        }

      }      
       
    }       
    
    console.log(this.RowArray);    

  }

  deleteNominee(){
    
    let id:any = $('#index_edit').val();    
    let cid:any = $('#index_edit_child').val();  
    let postrelation_id:any;
    let epm_id:any;
    let flag:any = 0;
    
    // console.log(id, '-', cid);
    this.Nomineepopup = "none";
    
    /**********Delete*********/
    for(var i = 0; i < this.RowArray.length; i++){      

      if(id == i){
        // console.log(this.RowArray[i]);

        let arr = this.RowArray[i]['insured_members'];
        let rel_arr = this.RowArray[i]['relation_data'];

        for(var j = 0; j < arr.length; j++){
          
          // console.log(arr[j]);

          if(cid == j){
                        
            if(this.RowArray[i]['insured_members'][j]['has_member_removal_lock_in'] == '1'){
              if(this.RowArray[i]['insured_members'][j]['is_lockin_death_cert'] == '1'){
                // delete button
                if(this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] == null || this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] == undefined || this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] == ''){  
                  this.toastService.showWarning('Warning', 'Policy Is Lock-In Mode, But You Can Upload Death Certificate Then You Can Delete Member information!');
            
                  // this.Errortoggle = "block";
                  // this.ErrortoggleMsg = "Policy Is Lock-In Mode, But You Can Upload Death Certificate Then You Can Delete Member information!";
                }else{
                  this.Errortoggle = "none"; 
                  flag = 1;             
                }
              }else{ 
                this.toastService.showWarning('Warning', 'Policy Is Lock-In Mode, So You Cannot Delete Member information!');
               
                // this.Errortoggle = "block";
                // this.ErrortoggleMsg = "Policy Is Lock-In Mode, So You Can't Delete Member information!";
              }
            }else{
              this.Errortoggle = "none";
              flag = 1;
            }
            

            if(flag == 1){

              if (cid > -1) {

                // console.log(arr[j]);
                postrelation_id = arr[j]['relation_id'];
                epm_id = arr[j]['epm_id'];

                // rel_arr.push({
                //   relation_id: arr[j]['relation_id'],
                //   relation: arr[j]['relation'],
                // });

                // arr.splice(j, 1);              

                for (let [key, value] of Object.entries(arr[j])) {

                  if (key == 'relation_id') {
                    arr[j][key] = arr[j]['relation_id'];
                  }
                  if (key == 'relation') {
                    arr[j][key] = arr[j]['relation'];
                  }
                  if (key == 'gender') {
                    arr[j][key] = '';
                  }
                  if (key == 'first_name') {
                    arr[j][key] = '';
                  }
                  if (key == 'last_name') {
                    arr[j][key] = '';
                  }
                  if (key == 'member_mob_no') {
                    arr[j][key] = '';
                  }
                  if (key == 'member_email') {
                    arr[j][key] = '';
                  }
                  if (key == 'dob') {
                    arr[j][key] = '';
                  }
                  if (key == 'marriage_date') {
                    arr[j][key] = '';
                  }

                }

              } else {
                // console.log('no cid');
              }

              // API Call
              var postdata = {
                "tokenable_id": localStorage.getItem('tokenable_id'),
                "policy_id": this.RowArray[i]['policy_id'],
                "relation_id": postrelation_id,
                "epm_id": epm_id
              };

              // console.log(postdata);

              this.clientCreationService.steptwodeleteinsuredmemberdata(postdata, localStorage.getItem('Token'))
                .subscribe((result) => {

                  if (result.status == true) {
                    this.toastService.showSuccess('Success', 'Member Deleted Successfully!');

                    // this.Deletetoggle = "block";
                    this.getsteptwodata();
                  }

                }, (error) => {
                  this.toastService.showFailed('Failed', 'There was some error!');

                  if (error.error.message == 'Unauthenticated.') {
                    localStorage.clear();
                    localStorage.setItem('resstatus', 'false');
                    this.route.navigate(['/home']);
                  }
                });
              //END

            }                    

          }
        }

      }      
       
    }       
    /*******************/        

    /**********Update All Max Values*********/
    // for(let i = 0; i < this.RowArray.length; i++){  
          
    //   let childs:any = 0;
    //   let parents:any = 0;
    //   var parents_in_laws:any = 0;
    //   var siblings:any = 0;
    //   var spouse:any = 0;

    //   // Calculate total filled members
    //   for(var j = 0; j < this.RowArray[i]['insured_members'].length; j++){

    //     // max_child
    //     if(this.RowArray[i]['insured_members'][j]['relation'] == 'Son' || this.RowArray[i]['insured_members'][j]['relation'] == 'Daughter' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //       childs = childs + 1; 
    //     }

    //     // max_parents
    //     if(this.RowArray[i]['insured_members'][j]['relation'] == 'Father' || this.RowArray[i]['insured_members'][j]['relation'] == 'Mother' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //       parents = parents + 1; 
    //     }

    //     // parents-in-laws
    //     if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Mother-in-law' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //       parents_in_laws = parents_in_laws + 1; 
    //     }

    //     // siblings
    //     if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Sister' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Brother' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //       siblings = siblings + 1; 
    //     }

    //     // spouse
    //     if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Spouse/Partner' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //       spouse = spouse + 1;           
    //     }
                               
    //   }
      
    //   // max minus filled
    //   for(var j = 0; j < this.RowArray[i]['insured_members'].length; j++){

    //       // max_child
    //       if(this.RowArray[i]['insured_members'][j]['relation'] == 'Son' || this.RowArray[i]['insured_members'][j]['relation'] == 'Daughter' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //         childs = parseInt(this.RowArray[i]['max_child']) - parseInt(childs); 
    //       }

    //       // max_parents
    //       if(this.RowArray[i]['insured_members'][j]['relation'] == 'Father' || this.RowArray[i]['insured_members'][j]['relation'] == 'Mother' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //         parents = parseInt(this.RowArray[i]['max_parents']) - parseInt(parents);
    //       }

    //       // parents-in-laws
    //       if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Mother-in-law' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //         parents_in_laws = parseInt(this.RowArray[i]['max_parents_inlaw']) - parseInt(parents_in_laws);
    //       }

    //       // siblings
    //       if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Sister' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Brother' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //         siblings = parseInt(this.RowArray[i]['max_siblings']) - parseInt(siblings); 
    //       }

    //       // spouse
    //       if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Spouse/Partner' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
    //         spouse = parseInt(this.RowArray[i]['max_spouse']) - parseInt(spouse); 
    //       }
                                 
    //   }

    //   this.RowArray[i]['max_child_filled'] = childs;
    //   this.RowArray[i]['max_parents_filled'] = parents;
    //   this.RowArray[i]['max_parents_inlaw_filled'] = parents_in_laws;
    //   this.RowArray[i]['max_siblings_filled'] = siblings;
    //   this.RowArray[i]['max_spouse_filled'] = spouse;

    // }             
    /*******************/

    // console.log(this.RowArray);

  }

  /**************** Nominee Policy Popup Step 3 ************/
  addIsurerPolicy(id:any){    
        
    for(var i = 0; i < this.RowArray_Step3.length; i++){    
            
      let sharePercentageSum:any = 0;                  
      
        if(id == i){          

          //insured_members
          for(var j = 0; j < this.RowArray_Step3[i]['insured_members'].length; j++){

            if(this.RowArray_Step3[i]['insured_members'][j]['share_percentage'] != undefined){
              sharePercentageSum = parseInt(sharePercentageSum) + parseInt(this.RowArray_Step3[i]['insured_members'][j]['share_percentage']);      
            }                          
            
          }

          // console.log("sharePercentageSum",sharePercentageSum);

          if(sharePercentageSum != 100){

            let arr = this.RowArray_Step3[i]['insured_members'];
            let relation_id:any = $('#nominee_relation_with_employee_'+i).val();
            let relation:any = $("#nominee_relation_with_employee_"+i+" option:selected").text();
            
            let nominee_first_name:any = $('#nominee_first_name_'+i).val();
            let nominee_last_name:any = $('#nominee_last_name_'+i).val();
            let nominee_email:any = $('#nominee_email_'+i).val();
            let share:any = $('#nominee_share_'+i).val();
            let dob:any = $('#nominee_dob_'+i).val(); 
            
            let shareprec:any = parseInt(sharePercentageSum) + parseInt(share);

            // console.log("shareprec",shareprec);

            if(shareprec > 100){
              
              this.toastService.showWarning('Warning', 'Sum of nominee share cannot be greater then 100%');

              // this.Errortoggle = "block";
              // this.ErrortoggleMsg = "Sum of nominee share cannot be greater then 100%"

            }else{
            
              if(relation_id == 0 || share == '' || nominee_first_name == '' || dob == ''){

                if(relation_id == 0){
                  $('#nominee_relation_with_employee_error_' + i).css('display', 'block');
                }else{
                  $('#nominee_relation_with_employee_error_' + i).css('display', 'none');
                }
                
                if(share == ''){
                  $('#nominee_share_error_' + i).css('display', 'block');
                }else{
                  $('#nominee_share_error_' + i).css('display', 'none');
                }

                if(nominee_first_name == ''){
                  $('#nominee_first_name_error_' + i).css('display', 'block');
                }else{
                  $('#nominee_first_name_error_' + i).css('display', 'none');
                }

                if(dob == ''){
                  $('#nominee_dob_error_' + i).css('display', 'block');
                }else{
                  $('#nominee_dob_error_' + i).css('display', 'none');
                }          

              }else{

                if($('.guardian_div_add_' + i).css('display') == 'block'){

                  let aa = $('#nominee_relation_with_employee_add_guardian_'+ i).val();
                  let bb = $('#nominee_first_name_add_guardian_'+i).val();            
                  let cc = $('#nominee_dob_add_guardian_'+i).val();
                  let dd = $('#nominee_last_name_add_guardian_'+i).val();

                  if(aa == '0' || bb == '' || cc == ''){
                    
                    if(aa == '0'){
                      $('#nominee_relation_with_employee_add_guardian_error_'+i).css('display', 'block');     
                    }else{
                      $('#nominee_relation_with_employee_add_guardian_error_'+i).css('display', 'none');     
                    }
          
                    if(bb == ''){
                      $('#nominee_first_name_add_guardian_error_'+i).css('display', 'block');     
                    }else{
                      $('#nominee_first_name_add_guardian_error_'+i).css('display', 'none');     
                    }
          
                    if(cc == ''){
                      $('#nominee_dob_add_guardian_error_'+i).css('display', 'block');     
                    }else{
                      $('#nominee_dob_add_guardian_error_'+i).css('display', 'none');     
                    }
                    
                  }else{
                    
                    $('#nominee_relation_with_employee_add_guardian_error_' + i).css('display', 'none');
                    $('#nominee_first_name_add_guardian_error_' + i).css('display', 'none');
                    $('#nominee_dob_add_guardian_error_' + i).css('display', 'none');

                    //insured_members
                    for(var j = 0; j < arr.length; j++){
                      
                      let aa:any = arr[j]['relation'];
                    
                      if(relation == aa){            
                        
                        for (let [key, value] of Object.entries(arr[j])) {                
                                  
                          // console.log(key, value);

                          if(key == 'relation_id'){
                            arr[j][key] = relation_id;
                          }
                          if(key == 'relation'){
                            arr[j][key] = relation;
                          }                              
                          if(key == 'first_name'){
                            arr[j][key] = $('#nominee_first_name_'+i).val();
                            nominee_first_name = $('#nominee_first_name_'+i).val();
                          }
                          if(key == 'last_name'){
                            arr[j][key] = $('#nominee_last_name_'+i).val();
                            nominee_last_name = $('#nominee_last_name_'+i).val();
                          }
                          if(key == 'email'){
                            arr[j][key] = $('#nominee_email_'+i).val();
                            nominee_email = $('#nominee_email_'+i).val();
                          }
                          if(key == 'share_percentage'){
                            arr[j][key] = $('#nominee_share_'+i).val();
                            share = $('#nominee_share_'+i).val();
                          }                              
                          if(key == 'dob'){
                            arr[j][key] = $('#nominee_dob_'+i).val();
                            dob = $('#nominee_dob_'+i).val();
                          }    
                          
                          arr[j]['guardian_relation_id'] = $('#nominee_relation_with_employee_add_guardian_'+ i).val();
                          arr[j]['guardian_fname'] = bb;
                          arr[j]['guardian_lname'] = dd;
                          arr[j]['guardian_dob'] = cc;
                          
                        }

                      }else{

                      }           
                      
                    }                   

                    // API Call
                    var postdata_add_ingaurdian = {
                      "tokenable_id":localStorage.getItem('tokenable_id'),
                      "policy_id": this.RowArray_Step3[i]['policy_id'],
                      "relation_id": relation_id,
                      "relation": relation,            
                      "first_name": nominee_first_name,
                      "last_name": nominee_last_name,            
                      "email": nominee_email,            
                      "share_percentage": share,
                      "dob": dob,
                      "guardian_relation" : aa,
                      "guardian_first_name" : bb,
                      "guardian_last_name" : dd,
                      "guardian_dob" : cc,
                      "step3_document": this.RowArray_Step3[i]['step3_document'],
                      "nominee_same_for_other_polocies": $('#nominee_same_'+i).is(':checked') == true ? 1 : 0,
                      "send_email": $('#send_email_'+i).is(':checked') == true ? 1 : 0
                    };
                
                    this.clientCreationService.savestepthreenomineedata(postdata_add_ingaurdian, localStorage.getItem('Token'))
                      .subscribe((result) => {
                        
                        if (result.status == true) {
                          // this.Savetoggle = "block";  
                          this.toastService.showSuccess('Success', 'Details Saved Successfully!');        

                          this.getstepthreenomineedata();                                                          
                        }
                                
                      }, (error) => {         
                        
                        this.toastService.showFailed('Failed', 'There was some error');

                        if(error.error.message == 'Unauthenticated.'){
                          localStorage.clear();
                          localStorage.setItem('resstatus', 'false');
                          this.route.navigate(['/home']);
                        }
                    });
                    //END

                    $('.guardian_div_add_' + i).css('display', 'none');

                    // console.log(this.RowArray_Step3[i]);
                    
                    // Relation Data
                    let relation_data = this.RowArray_Step3[i]['relation_data'];
                    for(let i = 0; i < relation_data.length; i++){
                      let id = relation_data[i]['relation_id'];

                      if(relation_id == id){
                        relation_data.splice(i,1);
                        $('#nominee_relation_with_employee_'+i).val(0);
                      }

                    }            
                    
                    $('#nominee_relation_with_employee_'+i).val(0);          
                    $('#nominee_first_name_'+i).val('');
                    $('#nominee_last_name_'+i).val('');
                    $('#nominee_email_'+i).val('');
                    $('#nominee_share_'+i).val('');          
                    $('#nominee_dob_'+i).val('');

                  }            
                  
                }else{

                  //insured_members
                  for(var j = 0; j < arr.length; j++){
                      
                    let aa:any = arr[j]['relation'];
                  
                    if(relation == aa){            
                      
                      for (let [key, value] of Object.entries(arr[j])) {                
            
                        if(key == 'relation_id'){
                          arr[j][key] = relation_id;
                        }
                        if(key == 'relation'){
                          arr[j][key] = relation;
                        }                              
                        if(key == 'first_name'){
                          arr[j][key] = $('#nominee_first_name_'+i).val();
                          nominee_first_name = $('#nominee_first_name_'+i).val();
                        }
                        if(key == 'last_name'){
                          arr[j][key] = $('#nominee_last_name_'+i).val();
                          nominee_last_name = $('#nominee_last_name_'+i).val();
                        }
                        if(key == 'email'){
                          arr[j][key] = $('#nominee_email_'+i).val();
                          nominee_email = $('#nominee_email_'+i).val();
                        }
                        if(key == 'share_percentage'){
                          arr[j][key] = $('#nominee_share_'+i).val();
                          share = $('#nominee_share_'+i).val();
                        }                              
                        if(key == 'dob'){
                          arr[j][key] = $('#nominee_dob_'+i).val();
                          dob = $('#nominee_dob_'+i).val();
                        }                
                        
                      }

                    }else{

                    }           
                    
                  }         
                
                  // API Call
                  var postdata_add = {
                    "tokenable_id":localStorage.getItem('tokenable_id'),
                    "policy_id": this.RowArray_Step3[i]['policy_id'],
                    "relation_id": relation_id,
                    "relation": relation,            
                    "first_name": nominee_first_name,
                    "last_name": nominee_last_name,            
                    "email": nominee_email,            
                    "share_percentage": share,
                    "guardian_relation" : '',
                    "guardian_first_name" : '',
                    "guardian_last_name" : '',
                    "guardian_dob" : '',
                    "dob": dob,
                    "step3_document": '',
                    "nominee_same_for_other_polocies": $('#nominee_same_'+i).is(':checked') == true ? 1 : 0,
                    "send_email": $('#send_email_'+i).is(':checked') == true ? 1 : 0
                  };

                  // console.log(postdata_add);
              
                  this.clientCreationService.savestepthreenomineedata(postdata_add, localStorage.getItem('Token'))
                    .subscribe((result) => {
                      
                      if (result.status == true) {
                        // this.Savetoggle = "block";
                        this.toastService.showSuccess('Success', 'Details Saved Successfully!');        

                        this.getstepthreenomineedata();
                      }
                              
                    }, (error) => {    
                           
                      this.toastService.showFailed('Failed', 'There was some error');

                      if(error.error.message == 'Unauthenticated.'){
                        localStorage.clear();
                        localStorage.setItem('resstatus', 'false');
                        this.route.navigate(['/home']);
                      }
                  });
                  // END

                  $('.guardian_div_add_' + i).css('display', 'none');
                  
                  // Relation Data
                  let relation_data = this.RowArray_Step3[i]['relation_data'];
                  for(let i = 0; i < relation_data.length; i++){
                    let id = relation_data[i]['relation_id'];

                    if(relation_id == id){
                      relation_data.splice(i,1);
                      $('#nominee_relation_with_employee_'+i).val(0);
                    }

                  }            
                  
                  $('#nominee_relation_with_employee_'+i).val(0);          
                  $('#nominee_first_name_'+i).val('');
                  $('#nominee_last_name_'+i).val('');
                  $('#nominee_email_'+i).val('');
                  $('#nominee_share_'+i).val('');          
                  $('#nominee_dob_'+i).val('');

                }                     

              }

            }
          
          }else{
            this.toastService.showWarning('Warning', 'Nominee share is 100%, you cannot add another nominee !!');

            // this.Errortoggle = "block";
            // this.ErrortoggleMsg = "Nominee share is 100%, you cannot add another nominee !!"
          }                         

        }                   
    }            
    this.gmc_same_as_above_flag = false;
    // console.log("after add - ",this.RowArray_Step3);
    
  }

  showNomineePolicypopupPolicy(id:any, k:any){

    // console.log(id, k);

    this.editID_step3 = id;

    this.NomineePolicypopup = 'block';            

    // Set all fields in edit popup
    for(var i = 0; i < this.RowArray_Step3.length; i++){      

      if(id == i){
        // console.log(this.RowArray_Step3[i]); 
        
        /****** Dropdown relation values *****/
        for(var j = 0; j < this.RowArray_Step3[i]['insured_members'].length; j++){
          this.Relation_edit.push({
            "relation_id": this.RowArray_Step3[i]['insured_members'][j]['relation_id'],
            "relation": this.RowArray_Step3[i]['insured_members'][j]['relation']
          });
        }
        // console.log(this.Relation_edit);
        /***********/


        /***********/
        for(var j = 0; j < this.RowArray_Step3[i]['insured_members'].length; j++){          

          if(k == j){
            // console.log(this.RowArray_Step3[i]['insured_members'][j]);

            $('#nominee_relation_with_employee_edit').val(this.RowArray_Step3[i]['insured_members'][j]['relation_id']);
            $('#nominee_first_name_edit').val(this.RowArray_Step3[i]['insured_members'][j]['first_name']);
            $('#nominee_last_name_edit').val(this.RowArray_Step3[i]['insured_members'][j]['last_name']);
            $('#nominee_share_edit').val(this.RowArray_Step3[i]['insured_members'][j]['share_percentage']);            
            $('#nominee_dob_edit').val(this.RowArray_Step3[i]['insured_members'][j]['dob']);
            $('#nominee_index_edit').val(i);
            $('#nominee_index_edit_child').val(k);
            $('#nominee_relation_id_edit').val(this.RowArray_Step3[i]['insured_members'][j]['relation_id']);
            this.declaration_file_url_step3 = this.RowArray_Step3[i]['insured_members'][j]['declaration_doc'];

            /********** DOB check 18 years ********/
            let dob:any = new Date(this.RowArray_Step3[i]['insured_members'][j]['dob']);
            let currentDate:any = new Date();                        
            let age:any = currentDate.getFullYear() - dob.getFullYear();                        
            if (
              currentDate.getMonth() < dob.getMonth() ||
              (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
            ) {
              age--;
            }                        
            let age_diff:any = age < 18;
            // console.log(age_diff);
            /*****************************/

            if(age_diff == true){

              $('.guardian_div').css('display', 'block');
              $('.declaration_edit_step3').css('display', 'block');

              if(this.RowArray_Step3[i]['insured_members'][j]['guardian_relation_id'] != ''){
                $('#nominee_relation_with_employee_edit_guardian').val(this.RowArray_Step3[i]['insured_members'][j]['guardian_relation_id']);
              }
      
              if(this.RowArray_Step3[i]['insured_members'][j]['guardian_fname'] != ''){
                $('#nominee_first_name_edit_guardian').val(this.RowArray_Step3[i]['insured_members'][j]['guardian_fname']);
              }
      
              if(this.RowArray_Step3[i]['insured_members'][j]['guardian_lname'] != ''){
                $('#nominee_last_name_edit_guardian').val(this.RowArray_Step3[i]['insured_members'][j]['guardian_lname']);
              }
      
              if(this.RowArray_Step3[i]['insured_members'][j]['guardian_dob'] != ''){
                $('#nominee_dob_edit_guardian').val(this.RowArray_Step3[i]['insured_members'][j]['guardian_dob']);
              }

            }else{
              $('.guardian_div').css('display', 'none');
              $('.declaration_edit_step3').css('display', 'none');
            }            
            
            // console.log(this.RowArray_Step3[i]['insured_members'][j]['relation']);

            if(this.RowArray_Step3[i]['insured_members'][j]['relation'] == 'Self'){

              // $('.nominee_relation_with_employee_edit').css('display', 'none');
              $('.nominee_deltbn').css('display', 'none');
              $('.nominee_savebtn').css('display', 'none');

              $('#nominee_first_name_edit').prop('disabled', true);
              $('#nominee_last_name_edit').prop('disabled', true);
              $('#nominee_share_edit').prop('disabled', true);
              $('#nominee_dob_edit').prop('disabled', true);

            }else{

              // $('.nominee_relation_with_employee_edit').css('display', 'block');
              $('.nominee_deltbn').css('display', 'flex');
              $('.nominee_savebtn').css('display', 'flex');

              // $('#nominee_relation_with_employee_edit').prop('disabled', true);
              $('#nominee_first_name_edit').prop('disabled', false);
              $('#nominee_last_name_edit').prop('disabled', false);
              $('#nominee_share_edit').prop('disabled', false);
              $('#nominee_dob_edit').prop('disabled', false);

            }              
          }

        }      
        /***********/
        
      }else{

      }      

    }   

    // Set nominee_relation_with_employee_edit field in edit popup
    setTimeout(() => {
      for(var i = 0; i < this.RowArray_Step3.length; i++){      
        if(id == i){
          for(var j = 0; j < this.RowArray_Step3[i]['insured_members'].length; j++){          
            if(k == j){  
              $('#nominee_relation_with_employee_edit').val(this.RowArray_Step3[i]['insured_members'][j]['relation_id']);
            }
          }
        }
      }
    }, 1000);    
    
  }

  updateNomineePolicy(){    

    let sharePercentageSum:any = 0;                  

    for(var i = 0; i < this.RowArray_Step3.length; i++){              

      //insured_members
      for(var j = 0; j < this.RowArray_Step3[i]['insured_members'].length; j++){

        if(this.RowArray_Step3[i]['insured_members'][j]['share_percentage'] != undefined){
          sharePercentageSum = parseInt(sharePercentageSum) + parseInt(this.RowArray_Step3[i]['insured_members'][j]['share_percentage']);      
        }                          
        
      }

    }

    let a:any = $('#nominee_relation_with_employee_edit').val();
    let b:any = $('#nominee_first_name_edit').val();
    let c:any = $('#nominee_dob_edit').val();
    let d:any = $('#nominee_share_edit').val();
    let shareprec:any = parseInt(sharePercentageSum) + parseInt(d);

    if(shareprec > 100){
      this.toastService.showWarning('Warning', 'Sum of nominee share cannot be greater then 100%');

      // this.Errortoggle = "block";
      // this.ErrortoggleMsg = "Sum of nominee share cannot be greater then 100%"

    }else{
    
      if(a == '0' || b == '' || c == '' || d == ''){
        
        if(a == '0'){
          $('#nominee_relation_with_employee_edit_error').css('display', 'block');     
        }else{
          $('#nominee_relation_with_employee_edit_error').css('display', 'none');     
        }

        if(b == ''){
          $('#nominee_first_name_edit_error').css('display', 'block');     
        }else{
          $('#nominee_first_name_edit_error').css('display', 'none');     
        }

        if(c == ''){
          $('#nominee_dob_edit_error').css('display', 'block');     
        }else{
          $('#nominee_dob_edit_error').css('display', 'none');     
        }

        if(d == ''){
          $('#nominee_share_edit_error').css('display', 'block');     
        }else{
          $('#nominee_share_edit_error').css('display', 'none');     
        }
      
      }else{          

        if($('.guardian_div').css('display') == 'block'){
          
          // console.log('in guardian');

          let aa = $('#nominee_relation_with_employee_edit_guardian').val();
          let bb = $('#nominee_first_name_edit_guardian').val();
          let cc = $('#nominee_dob_edit_guardian').val();

          if(aa == '0' || bb == '' || cc == ''){
            
            if(aa == '0'){
              $('#nominee_relation_with_employee_edit_guardian_error').css('display', 'block');     
            }else{
              $('#nominee_relation_with_employee_edit_guardian_error').css('display', 'none');     
            }

            if(bb == ''){
              $('#nominee_first_name_edit_guardian_error').css('display', 'block');     
            }else{
              $('#nominee_first_name_edit_guardian_error').css('display', 'none');     
            }

            if(cc == ''){
              $('#nominee_dob_edit_guardian_error').css('display', 'block');     
            }else{
              $('#nominee_dob_edit_guardian_error').css('display', 'none');     
            }

          }else{

            let id:any = $('#nominee_index_edit').val();    
            let cid:any = $('#nominee_index_edit_child').val();  
            let nominee_relation_id_edit:any = $('#nominee_relation_id_edit').val();      
                
            let nominee_first_name:any;
            let nominee_last_name:any;
            let nominee_email:any;
            let share:any;
            let dob:any;

            let nominee_relation_with_employee_edit_guardian:any = $('#nominee_relation_with_employee_edit_guardian').val();  
            let nominee_first_name_edit_guardian:any;
            let nominee_last_name_edit_guardian:any;
            let nominee_dob_edit_guardian:any;
            let guardian_relation_id:any;
            
            // console.log(id, '-', cid);

            this.NomineePolicypopup = "none";

            for(var i = 0; i < this.RowArray_Step3.length; i++){                  

              if(id == i){              

                let arr = this.RowArray_Step3[i]['insured_members'];

                for(var j = 0; j < arr.length; j++){
                  
                  if(cid == j){

                    if (cid > -1) {
                                          
                      // console.log(arr[j]);

                      for (let [key, value] of Object.entries(arr[j])) {   
                        
                        // console.log(key, value);
                                                            
                        if(key == 'first_name'){
                          arr[j][key] = $('#nominee_first_name_edit').val();
                          nominee_first_name = $('#nominee_first_name_edit').val();
                        }
                        
                        if(key == 'email'){
                          arr[j][key] = $('#nominee_email_edit').val();
                          nominee_email = $('#nominee_email_edit').val();
                        }
                        if(key == 'share_percentage'){
                          arr[j][key] = $('#nominee_share_edit').val();
                          share = $('#nominee_share_edit').val();
                        }                             
                        if(key == 'dob'){
                          arr[j][key] = $('#nominee_dob_edit').val();
                          dob = $('#nominee_dob_edit').val();
                        }

                        // Guardian
                        if(key == 'guardian_relation_id'){
                          arr[j][key] = $('#nominee_relation_with_employee_edit_guardian').val();
                          guardian_relation_id = $('#nominee_relation_with_employee_edit_guardian').val();
                        }

                        if(key == 'guardian_fname'){
                          arr[j][key] = $('#nominee_first_name_edit_guardian').val();
                          nominee_first_name_edit_guardian = $('#nominee_first_name_edit_guardian').val();
                        }
                        
                        if(key == 'guardian_lname'){
                          arr[j][key] = $('#nominee_last_name_edit_guardian').val();
                          nominee_last_name_edit_guardian = $('#nominee_last_name_edit_guardian').val();
                        }

                        if(key == 'guardian_dob'){
                          arr[j][key] = $('#nominee_dob_edit_guardian').val();
                          nominee_dob_edit_guardian = $('#nominee_dob_edit_guardian').val();
                        }

                      }                                                    
                      
                    }else{
                      // console.log('no cid');
                    }            

                  }

                }              

                // API Call
                var postdata_inguardian = {
                  "tokenable_id":localStorage.getItem('tokenable_id'),
                  "policy_id": this.RowArray_Step3[i]['policy_id'],
                  "relation_id": nominee_relation_id_edit,
                  "first_name": nominee_first_name,
                  "last_name": nominee_last_name,
                  "email": nominee_email,
                  "share_percentage": share,
                  "dob": dob,
                  "guardian_relation" : nominee_relation_with_employee_edit_guardian,
                  "guardian_first_name" : nominee_first_name_edit_guardian,
                  "guardian_last_name" : nominee_last_name_edit_guardian,
                  "guardian_dob" : nominee_dob_edit_guardian,
                  "is_guardian":1,
                  "step3_document": this.RowArray_Step3[i]['step3_document'], 
                };

                // console.log(postdata_inguardian);

                this.clientCreationService.updatestepthreenomineedata(postdata_inguardian, localStorage.getItem('Token'))
                  .subscribe((result) => {
                    
                    if (result.status == true) {
                      // this.Updatetoggle = "block";
                      this.toastService.showSuccess('Success', 'Details Updated Successfully!');        
                      this.getstepthreenomineedata();
                    }                   
                            
                  }, (error) => {         
                    this.toastService.showFailed('Failed', 'There was some error');

                    if(error.error.message == 'Unauthenticated.'){
                      localStorage.clear();
                      localStorage.setItem('resstatus', 'false');
                      this.route.navigate(['/home']);
                    }
                });
                //END              

              }                  
              
            }                           
          
          }
          
        }else{

          // console.log('out guardian');
          
          let id:any = $('#nominee_index_edit').val();    
          let cid:any = $('#nominee_index_edit_child').val();  
          let nominee_relation_id_edit:any = $('#nominee_relation_id_edit').val();      
              
          let nominee_first_name:any;
          let nominee_last_name:any;
          let share:any;
          let dob:any;

          let nominee_relation_with_employee_edit_guardian:any = $('#nominee_relation_with_employee_edit_guardian').val();  
          let nominee_first_name_edit_guardian:any;
          let nominee_last_name_edit_guardian:any;
          let nominee_dob_edit_guardian:any;
          
          // console.log(id, '-', cid);

          this.NomineePolicypopup = "none";

          for(var i = 0; i < this.RowArray_Step3.length; i++){      

            if(id == i){
              // console.log(this.RowArray_Step3[i]);

              let arr = this.RowArray_Step3[i]['insured_members'];

              for(var j = 0; j < arr.length; j++){
                
                if(cid == j){

                  if (cid > -1) { 
                                
                    for (let [key, value] of Object.entries(arr[j])) {                
                      
                      // console.log(arr[j][key]);
                                    
                      if(key == 'first_name'){
                        arr[j][key] = $('#nominee_first_name_edit').val();
                        nominee_first_name = $('#nominee_first_name_edit').val();
                      }
                      if(key == 'first_name'){
                        arr[j][key] = $('#nominee_last_name_edit').val();
                        nominee_last_name = $('#nominee_last_name_edit').val();
                      }
                      if(key == 'share_percentage'){
                        arr[j][key] = $('#nominee_share_edit').val();
                        share = $('#nominee_share_edit').val();
                      }                             
                      if(key == 'dob'){
                        arr[j][key] = $('#nominee_dob_edit').val();
                        dob = $('#nominee_dob_edit').val();
                      }

                      // Guardian
                      if(key == 'nominee_first_name_edit_guardian'){
                        arr[j][key] = $('#nominee_first_name_edit_guardian').val();
                        nominee_first_name_edit_guardian = $('#nominee_first_name_edit_guardian').val();
                      }else{
                        arr[j][key] = $('#nominee_first_name_edit_guardian').val();
                        nominee_first_name_edit_guardian = $('#nominee_first_name_edit_guardian').val();
                      }
                      
                      if(key == 'nominee_last_name_edit_guardian'){
                        arr[j][key] = $('#nominee_last_name_edit_guardian').val();
                        nominee_last_name_edit_guardian = $('#nominee_last_name_edit_guardian').val();
                      }else{
                        arr[j][key] = $('#nominee_last_name_edit_guardian').val();
                        nominee_last_name_edit_guardian = $('#nominee_last_name_edit_guardian').val();
                      }

                      if(key == 'nominee_dob_edit_guardian'){
                        arr[j][key] = $('#nominee_dob_edit_guardian').val();
                        nominee_dob_edit_guardian = $('#nominee_dob_edit_guardian').val();
                      }else{
                        arr[j][key] = $('#nominee_dob_edit_guardian').val();
                        nominee_dob_edit_guardian = $('#nominee_dob_edit_guardian').val();
                      }
                      
                    }              
                    
                  }else{
                    // console.log('no cid');
                  }            

                }
              }

            }      

            // API Call
            var postdata_out = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "policy_id": this.RowArray_Step3[i]['policy_id'],
              "relation_id": nominee_relation_id_edit,
              "first_name": nominee_first_name,
              "last_name": nominee_last_name,
              "share_percentage": share,
              "dob": dob,
              "guardian_relation" : nominee_relation_with_employee_edit_guardian,
              "guardian_first_name" : nominee_first_name_edit_guardian,
              "guardian_last_name" : nominee_last_name_edit_guardian,
              "guardian_dob" : nominee_dob_edit_guardian,                       
              "is_guardian":0,
              "step3_document": '', 
            };

            console.log(postdata_out);

            this.clientCreationService.updatestepthreenomineedata(postdata_out, localStorage.getItem('Token'))
              .subscribe((result) => {
                
                if (result.status == true) {
                  // this.Updatetoggle = "block";
                  this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

                  this.getstepthreenomineedata();
                }                   
                        
              }, (error) => {         
                this.toastService.showFailed('Failed', 'There was some error');

                if(error.error.message == 'Unauthenticated.'){
                  localStorage.clear();
                  localStorage.setItem('resstatus', 'false');
                  this.route.navigate(['/home']);
                }
            });
            //END
            
          }       
          
          console.log(this.RowArray_Step3);

        }          
        
      }   

    }
    
    // console.log(this.RowArray_Step3);

  }

  deleteNomineePolicy(){
    
    let id:any = $('#nominee_index_edit').val();    
    let cid:any = $('#nominee_index_edit_child').val();  
    let postrelation_id:any;
    
    // console.log(id, '-', cid);

    this.NomineePolicypopup = "none";

    for(var i = 0; i < this.RowArray_Step3.length; i++){      

      if(id == i){
        // console.log(this.RowArray_Step3[i]);

        let arr = this.RowArray_Step3[i]['insured_members'];
        let rel_arr = this.RowArray_Step3[i]['relation_data'];

        for(var j = 0; j < arr.length; j++){
          
          // console.log(arr[j]);

          if(cid == j){

            if (cid > -1) {   
              
              postrelation_id = arr[j]['relation_id'];

              rel_arr.push({
                relation_id: arr[j]['relation_id'],
                relation: arr[j]['relation'],
              });

              // arr.splice(j, 1);              

              //insured_members
              for(var j = 0; j < arr.length; j++){
                  
                let aa:any = arr[j]['relation'];
              
                if(arr[j]['relation'] == aa){            
                  
                  for (let [key, value] of Object.entries(arr[j])) {     
                    
                    // console.log(key, value);
        
                    if(key == 'relation_id'){
                      arr[j][key] = arr[j]['relation_id'];
                    }
                    if(key == 'relation'){
                      arr[j][key] = arr[j]['relation'];
                    }                             
                    if(key == 'first_name'){
                      arr[j][key] = '';
                    }
                    if(key == 'last_name'){
                      arr[j][key] = '';
                    }
                    if(key == 'share_percentage'){
                      arr[j][key] = '';
                    }                           
                    if(key == 'dob'){
                      arr[j][key] = '';
                    }                
                    
                  }

                }else{

                }           
                
              }
              
            }else{
              // console.log('no cid');
            }            

            // API Call
            var postdata = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "policy_id": this.RowArray_Step3[i]['policy_id'],
              "relation_id": postrelation_id        
            };

            console.log(postdata);
        
            this.clientCreationService.deletestepthreenomineedata(postdata, localStorage.getItem('Token'))
              .subscribe((result) => {
                
                if (result.status == true) {
                  // this.Deletetoggle = "block";
                  this.toastService.showSuccess('Success', 'Details Deleted Successfully!');        

                  this.getstepthreenomineedata();
                }                  
                        
              }, (error) => {         
                this.toastService.showFailed('Failed', 'There was some error');

                if(error.error.message == 'Unauthenticated.'){
                  localStorage.clear();
                  localStorage.setItem('resstatus', 'false');
                  this.route.navigate(['/home']);
                }
            });
            //END

          }
        }

      }      
       
    }       
    
    // console.log(this.RowArray_Step3);

  }

  /************* Step 1 *******************/
  
  getdetails() {            

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": localStorage.getItem('family_page_step1_id')             
    };

    this.clientCreationService.getdetails(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        
        // console.log(result);

        if (result.status == true) {
  
          // Step 1
          this.basicInfo.controls['employer_name'].setValue(result.data.step1_form.first_name);
          this.basicInfo.controls['employer_dob'].setValue(result.data.step1_form.dob);
          this.basicInfo.controls['employer_joining'].setValue(result.data.step1_form.doj);
          this.basicInfo.controls['employer_email'].setValue(result.data.step1_form.member_email);
          this.basicInfo.controls['employer_contact_number'].setValue(result.data.step1_form.contact);
          this.basicInfo.controls['employer_alternate_email'].setValue(result.data.step1_form.alternate_email);
          this.basicInfo.controls['employer_emergency_person'].setValue(result.data.step1_form.emergency_contact_name);
          this.basicInfo.controls['employer_emergency_contact_number'].setValue(result.data.step1_form.emergency_contact_number);

        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }

  checkDob_add(e:any, i:any){
    let val = e.target.value;

    /********** DOB check 18 years ********/
    let dob:any = new Date(val);
    let currentDate:any = new Date();                        
    let age:any = currentDate.getFullYear() - dob.getFullYear();                        
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }                        
    let age_diff:any = age < 18;
    // console.log(age_diff);
    /*****************************/

    if(age_diff == true){
      $('.guardian_div_add_'+i).css('display', 'block');

      for(let j = 0; j < this.RowArray_Step3.length; j++){
        if(this.RowArray_Step3[j]['declaration_mandatory'] == '1'){
          $('.declaration_step3_'+i).css('display', 'block');
        }else{
          $('.declaration_step3_'+i).css('display', 'none');
        }
      }

    }else{
      $('.guardian_div_add_'+i).css('display', 'none');
      $('.declaration_step3_'+i).css('display', 'none');
    }

    $('#nominee_relation_with_employee_error_' + i).css('display', 'none');
    $('#nominee_share_error_' + i).css('display', 'none');
    $('#nominee_first_name_error_' + i).css('display', 'none');
    $('#dob_error_' + i).css('display', 'none');

    $('#nominee_relation_with_employee_add_guardian_' + i).val('0');
    $('#nominee_first_name_add_guardian_' + i).val('');
    $('#nominee_last_name_add_guardian_' + i).val('');
    $('#nominee_dob_add_guardian_' + i).val('');


  }

  selectgender_step2(e:any, i:any, policy_id:any){
    
    let val = e.target.value;
    let dob = $('#insured_dob_'+i).val();
    let rel_val = $('#relation_with_employee_'+i).val();
    this.getSIArray(rel_val, dob, policy_id);

    for(var j = 0; j < this.RowArray.length; j++){
      if(i == j){       

        if(val == '2'){      

          if(this.RowArray[j]['is_lgbtq'] == '1'){
            $('#gender_'+i).prop('disabled', false);
          }else{

            for(var k=0; k < this.RowArray[j]['insured_members'].length; k++){
              if(this.RowArray[j]['insured_members'][k]['relation'] == 'Self'){
                if(this.RowArray[j]['insured_members'][k]['gender'] == 'Male'){
                  $('#gender_'+i).val('Female');
                }else{
                  $('#gender_'+i).val('Male');
                }
              }
            }

            // $('#gender_'+i).prop('disabled', true);
          }
          
          $('.marriage_date_'+i).css('display','block');
          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');

          if(this.RowArray[j]['marraige_certificate_spouse'] == '1'){
            $('.marriage_certificate_file_'+i).css('display', 'block');
          }else{
            $('.marriage_certificate_file_'+i).css('display', 'none');
          }
              
        }else{

          $('.marriage_date_'+i).css('display','none');
          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);
          
          $('.marriage_certificate_file_'+i).css('display', 'none');  
          $('.birth_certificate_file_'+i).css('display', 'none');        

        }
        if(val == '3'){
          $('#gender_'+i).val('Female');   
          // $('#gender_'+i).prop('disabled', true);   

          if(this.RowArray[j]['birth_certificate_child'] == '1'){
            $('.birth_certificate_file_'+i).css('display', 'block');
          }else{
            $('.birth_certificate_file_'+i).css('display', 'none');
          }

          if(this.RowArray[j]['has_adopted_child'] == '1'){
            $('.is_adopted_div_'+i).css('display', 'block');
          }else{
            $('.is_adopted_div_'+i).css('display', 'none');
          }

          if(this.RowArray[j]['has_special_child'] == '1'){
            $('.is_special_child_div_'+i).css('display', 'block');
          }else{
            $('.is_special_child_div_'+i).css('display', 'none');
          }

          if(this.RowArray[j]['has_unmarried_child'] == '1'){
            $('.is_unmarried_child_div_'+i).css('display', 'block');
          }else{
            $('.is_unmarried_child_div_'+i).css('display', 'none');
          }
        
        }
        if(val == '4'){
          $('#gender_'+i).val('Male');  
          // $('#gender_'+i).prop('disabled', true);

          if(this.RowArray[j]['birth_certificate_child'] == '1'){
            $('.birth_certificate_file_'+i).css('display', 'block');
          }else{
            $('.birth_certificate_file_'+i).css('display', 'none');
          }
          
          if(this.RowArray[j]['has_adopted_child'] == '1'){
            $('.is_adopted_div_'+i).css('display', 'block');
          }else{
            $('.is_adopted_div_'+i).css('display', 'none');
          }

          if(this.RowArray[j]['has_special_child'] == '1'){
            $('.is_special_child_div_'+i).css('display', 'block');
          }else{
            $('.is_special_child_div_'+i).css('display', 'none');
          }

          if(this.RowArray[j]['has_unmarried_child'] == '1'){
            $('.is_unmarried_child_div_'+i).css('display', 'block');
          }else{
            $('.is_unmarried_child_div_'+i).css('display', 'none');
          }

        }
        if(val == '5'){
          $('#gender_'+i).val('Male');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');
    
        }
        if(val == '6'){
          $('#gender_'+i).val('Female');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');
        }
        if(val == '7'){
          $('#gender_'+i).val('Male');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');

        }
        if(val == '8'){
          $('#gender_'+i).val('Female');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');

        }
        if(val == '9'){
          $('#gender_'+i).val('Male');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');

        }
        if(val == '10'){
          $('#gender_'+i).val('Male');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');          
          
        }
        if(val == '11'){
          $('#gender_'+i).val('Female');
          // $('#gender_'+i).prop('disabled', true);

          $('.is_adopted_div_'+i).css('display', 'none');
          $('.declaration_'+i).css('display', 'none');
          $("#is_adopted_child_"+i).prop("checked", false);
    
          $('.is_special_child_div_'+i).css('display', 'none');
          $("#is_special_child_"+i).prop("checked", false);
    
          $('.is_unmarried_child_div_'+i).css('display', 'none');
          $("#is_unmarried_child_"+i).prop("checked", false);

          $('.birth_certificate_file_'+i).css('display', 'none');

        }

      }      

    }          
    
  }
  
  submitstep4(){

    this.dialogePopup = 'none';

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    this.clientCreationService.submitstep4(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        if (result.status == true) {
          this.Savetoggle = 'block';

          setTimeout(() => {
            this.route.navigate(['/flex-configurator-dashboard']);
          }, 2000);          

       }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });    

  }  

  // add
  is_adopted_child(i:any){

    if ($('#is_adopted_child_'+i).is(':checked')) {
      $('.declaration_'+i).css('display', 'block');
    } else {
      $('.declaration_'+i).css('display', 'none');
    }
    
  }

  claim_doc(event: any, index:any, val:any){  

    let file:any;

    if(val == 'marriage'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_marriage_certificate_file_" + index).empty();
        $('#features-file-upload_marriage_certificate_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_marriage_certificate_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_marriage_certificate_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['marriage_certificate_document'] = file;
        }      
      }
    }

    if(val == 'adopted'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_step2_file_" + index).empty();
        $('#features-file-upload_step2_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_step2_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_step2_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['document'] = file;
        }      
      }
    }        

    if(val == 'birth'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_birth_certificate_file_" + index).empty();
        $('#features-file-upload_birth_certificate_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_birth_certificate_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_birth_certificate_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['birth_certificate_document'] = file;
        }      
      }
    }

    if(val == 'step3'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_step3_file_" + index).empty();
        $('#features-file-upload_step3_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_step3_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_step3_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray_Step3.length; i++){
        if(i == index){
          this.RowArray_Step3[i]['step3_document'] = file;
        }      
      }
    }

    // console.log("after upload - ",this.RowArray_Step3);

  }

  ondownload(){      
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    link.setAttribute('href', '../../../../../../assets/Member Data Sample Files/Sample_File.csv');
    link.setAttribute('download', `Sample_File.xls`);
          
    document.body.appendChild(link);
    link.click();
    link.remove();    
  }

  // edit
  is_adopted_child_edit(i:any){

    if ($('#is_adopted_child_edit').is(':checked')) {
      $('.declaration_edit').css('display', 'block');
    } else {
      $('.declaration_edit').css('display', 'none');
    }
    
  }

  claim_doc_edit(event: any, index:any, val:any){  

    let file:any;

    if(val == 'marriage'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_marriage_certificate_file_edit").empty();
        $('#features-file-upload_marriage_certificate_file_edit').html(event.target.files[0]['name']);
        $('#features-file-upload_marriage_certificate_file_edit').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_marriage_certificate_file_edit').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['marriage_certificate_document'] = file;
        }      
      }
    }

    if(val == 'adopted'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_step2_file_edit").empty();
        $('#features-file-upload_step2_file_edit').html(event.target.files[0]['name']);
        $('#features-file-upload_step2_file_edit').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_step2_file_edit').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['document'] = file;
        }      
      }
    }  
    
    if(val == 'birth'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_birth_certificate_file_edit").empty();
        $('#features-file-upload_birth_certificate_file_edit').html(event.target.files[0]['name']);
        $('#features-file-upload_birth_certificate_file_edit').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_birth_certificate_file_edit').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['birth_certificate_document'] = file;
        }      
      }
    }

    if(val == 'death'){
      
      let index_edit_child = $('#index_edit_child').val();

      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_death_certificate_file_edit").empty();
        $('#features-file-upload_death_certificate_file_edit').html(event.target.files[0]['name']);
        $('#features-file-upload_death_certificate_file_edit').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_death_certificate_file_edit').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          for(var j = 0; j < this.RowArray[i]['insured_members'].length; j++){
            if(j == index_edit_child){
              this.RowArray[i]['insured_members'][j]['upload_lockin_death_cert'] = file;
            }
          }
        }      
      }
      
    }

    if(val == 'step3'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_step3_file_edit").empty();
        $('#features-file-upload_step3_file_edit').html(event.target.files[0]['name']);
        $('#features-file-upload_step3_file_edit').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_step3_file_edit').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray_Step3.length; i++){        
        if(i == index){          
          this.RowArray_Step3[i]['step3_document'] = file;
        }      
      }
    }

    // console.log(this.RowArray_Step3);

  }
 
  calage(birthdate:any){    
    
    // console.log('birthdate',birthdate);

    const today = new Date();
    const birthDate = new Date(birthdate);

    // Check if the birthdate is a valid date
    if (isNaN(birthDate.getTime())) {
        console.error("Invalid birthdate");
        return null; // or handle the error in a way that makes sense for your application
    }

    let age = today.getFullYear() - birthDate.getFullYear();

    if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;

  }

  emptyValues(i:any){

    // console.log('emptyValues',i);

    $('#relation_with_employee_error_' + i).css('display', 'none');
    $('#gender_error_' + i).css('display', 'none');
    $('#first_name_error_' + i).css('display', 'none');
    $('#dob_error_' + i).css('display', 'none');
    $("#is_adopted_child_"+i).prop("checked", false);
    $("#is_special_child_"+i).prop("checked", false);
    $("#is_unmarried_child_"+i).prop("checked", false);
    
    $("#step2_file_" + i).val('');          
    $("#features-file-upload_step2_file_" + i).empty();
    let htmlcal = `
      <img src="../../assets/img/policy/pin.png" style="width: 12px;">
      &nbsp; 
      <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">
        Add File
      </span>&nbsp; 
      <span style="color:#AAAAAA">or drop File here</span>
    `;
    $("#features-file-upload_step2_file_" + i).append(htmlcal);
    $('.declaration_' + i).css('display', 'none');


    /******/
    $("#marriage_certificate_file_" + i).val('');
    $("#marriage_date_" + i).val('');
    $("#features-file-upload_marriage_certificate_file_" + i).empty();
    let htmlcal2 = `
      <img src="../../assets/img/policy/pin.png" style="width: 12px;">
      &nbsp; 
      <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">
        Add File
      </span>&nbsp; 
      <span style="color:#AAAAAA">or drop File here</span>
    `;
    $("#features-file-upload_marriage_certificate_file_" + i).append(htmlcal2);
    $('.marriage_certificate_file_' + i).css('display', 'none');

    /*****/
    $("#birth_certificate_file_" + i).val('');
    $("#features-file-upload_birth_certificate_file_" + i).empty();
    let htmlcal3 = `
      <img src="../../assets/img/policy/pin.png" style="width: 12px;">
      &nbsp; 
      <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">
        Add File
      </span>&nbsp; 
      <span style="color:#AAAAAA">or drop File here</span>
    `;
    $("#features-file-upload_birth_certificate_file_" + i).append(htmlcal3);
    $('.birth_certificate_file_' + i).css('display', 'none');

    $('.is_adopted_div_' + i).css('display', 'none');
    $('.is_special_child_div_' + i).css('display', 'none');
    $('.is_unmarried_child_div_' + i).css('display', 'none');  
    
    $('#relation_with_employee_'+i).val('0');
    $('#gender_'+i).val('');
    $('#first_name_'+i).val('');
    $('#last_name_'+i).val('');
    $('#insured_contact_number_'+i).val('');
    $('#insured_email_'+i).val('');
    $('#insured_dob_'+i).val('');

    /**********Update All Max Values*********/
    for(let i = 0; i < this.RowArray.length; i++){  
          
      let childs:any = 0;
      let parents:any = 0;
      var parents_in_laws:any = 0;
      var siblings:any = 0;
      var spouse:any = 0;      
      
      // max minus filled
      for(var j = 0; j < this.RowArray[i]['insured_members'].length; j++){

          // max_child
          if(this.RowArray[i]['insured_members'][j]['relation'] == 'Son' || this.RowArray[i]['insured_members'][j]['relation'] == 'Daughter' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
            childs = childs + 1; 
          }

          // max_parents
          if(this.RowArray[i]['insured_members'][j]['relation'] == 'Father' || this.RowArray[i]['insured_members'][j]['relation'] == 'Mother' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
            parents = parents + 1;
          }

          // parents-in-laws
          if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Mother-in-law' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
            parents_in_laws = parents_in_laws + 1;
          }

          // siblings
          if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Sister' || this.Steptwodata[i]['insured_members'][j]['relation'] == 'Brother' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
            siblings = siblings + 1; 
          }

          // spouse
          if(this.Steptwodata[i]['insured_members'][j]['relation'] == 'Spouse/Partner' && this.RowArray[i]['insured_members'][j]['first_name'] != ''){
            spouse = spouse + 1; 
          }
                                 
      }

      this.RowArray[i]['max_child_filled'] = childs;
      this.RowArray[i]['max_parents_filled'] = parents;
      this.RowArray[i]['max_parents_inlaw_filled'] = parents_in_laws;
      this.RowArray[i]['max_siblings_filled'] = siblings;
      this.RowArray[i]['max_spouse_filled'] = spouse;

    }             
    /*******************/

  }

  viewDesc(res:any){

    this.DescriptionMsg = res.policy_description;
    // this.DescriptionMsg = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
    this.Descriptiontoggle = "block";

  }

  changedob(i:any, e:any, policy_id:any){
    let relation_with_employee = $('#relation_with_employee_'+i).val();
    this.getSIArray(relation_with_employee, e.target.value, policy_id);    
  }

  getSIArray(rel_id:any, dob:any, policy_id:any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "relation_id": rel_id,
      "policy_id": policy_id,
      "dob": dob
    };

    // console.log(rel_id, dob);

    if(rel_id != undefined && dob != undefined){

      if(rel_id != '' && dob != ''){
        this.clientCreationService.getSIArray(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {
          
            if (result.status == true) {  
              
              if(result.data.length > 0){
                          
                this.si_array = [];
                this.si_array = result.data;
                $('.si_array_Div').css('display', 'block');
  
              }else{
                $('.si_array_Div').css('display', 'none');
              }
              
            }
          }, (error) => {         
            
            if(error.error.message == 'Unauthenticated.'){
              localStorage.clear();
              localStorage.setItem('resstatus', 'false');
              this.route.navigate(['/home']);
            }
        }); 
      }       

    }          
  }

  topup_section(f:any, g:any, val1:any, val2:any, val3:any, val4:any, val5:any, topup_setup:any, insured_members:any){        

    
    $('.toggle-checkbox').not('#radioButton_' + f+g).prop('checked', false);

    this.topup_Sum_Insured_lable = val1;
    this.topup_Sum_Insured_value = val2;    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "parent_policy_id": val3,
      "child_policy_id": val4,
      "topup_radio_id": val5,
      "topup_radio_selected": $('#radioButton_' + f+g).is(':checked')
    }
    
    this.topup_popupobj = postdata;

    console.log(topup_setup , insured_members.length);

    if(topup_setup == '1' && insured_members.length == 1){
      this.toastService.showWarning('Warning', 'Please add parent member before selecting top up.');

      // this.Errortoggle = "block";
      // this.ErrortoggleMsg = "Please add parent member before selecting top up."
    }else{
      this.submittopup_Popup();
    }
                

    // console.log("postdata",postdata);

    // this.clientCreationService.gettopupdisplaydatabyID(postdata, localStorage.getItem('Token'))
    //   .subscribe((result) => {

    //     if (result.status == true) {

    //       if (result.data.length > 0) {

    //         this.topup_popupArray = result.data;
    //         this.TopUpSectiontoggle = "block";

    //       } else {
    //         this.TopUpSectiontoggle = "none";
    //       }

    //     }
    //   }, (error) => {

    //     if (error.error.message == 'Unauthenticated.') {
    //       localStorage.clear();
    //       localStorage.setItem('resstatus', 'false');
    //       this.route.navigate(['/home']);
    //     }
    //   }); 

  }

  submittopup_Popup(){

    this.TopUpSectiontoggle = "none";

    this.clientCreationService.submittopup_Popup(this.topup_popupobj, localStorage.getItem('Token'))
      .subscribe((result) => {
        
          if (result.status == true) {  
            this.getsteptwodata();
          }
        }, (error) => {         
          
          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
      }); 

  }  

  // Step 4 onclick checkbox API call
  savestepfour(w:any, l:any,res:any, insarray:any){    

    $('.toggle-checkbox2').not('#step4radio_' + w+l).prop('checked', false);

    let postdata = {
      'tokenable_id':localStorage.getItem('tokenable_id'),
      'inst_id' : insarray.id,
      'is_payroll' : res.is_payroll,
      'is_installment_allowed' :res.installment_allow,
      'no_of_installments' : insarray.installment,
      'per_installment_amount' : insarray.installment_amount,
      'total_installment_amount' : res.employee_premium,
      'policy_id' : res.policy_id,
      'memberid' : insarray.memberid,
      'employee_id' : insarray.employee_idfk,
      'installment_selected': $('#step4radio_' + w+l).is(':checked')
    }

    this.selectedinscount = insarray.installment;

    // console.log(postdata);

    this.clientCreationService.savestepfour(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        if (result.status == true) {  
          this.getstepfourdata();
        }
        
      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });

  }  

  downloadAsPDF() {    
      if (this.contentToConvert) {
        const content = this.contentToConvert.nativeElement;
    
        html2canvas(content, { scale: 2 }).then((canvas) => {
          // Convert canvas to image data
          const imageData = canvas.toDataURL('image/png');
    
          // Set up jspdf
          const pdf = new jspdf('p', 'mm', 'a4');
            
          // Calculate aspect ratio to maintain the original proportions
          const imgWidth = pdf.internal.pageSize.width;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Add image data to pdf
          pdf.addImage(imageData, 'PNG', 0, 0, imgWidth, imgHeight);
    
          // Download the PDF
          pdf.save('example.pdf');
        });
      }
    }

  ngAfterViewInit() {
    // Access contentToConvert.nativeElement here or call your generatePDF method
  }

  // step3
  checknominee(e:any, id:any){
    let i = e.target.id;
    if($('#'+i).is(':checked')){    
      // $('.nomineDiv_'+id).css('display','none');
      this.dialogePopup_nominee = "block";
      this.nomineesame = id;
    }else{
      this.nomineesame = "";
      $('.nomineDiv_'+id).css('display','contents');      
    }
  }

  closePopup_nominee(id:any){
    this.dialogePopup_nominee = "none";
    $('#nominee_same_'+id).prop('checked', false);
  }

  checknominee_dailogue(id:any){

    this.dialogePopup_nominee = "none";

    if(id != '' && $('#nominee_same_'+id).is(':checked')){
      $('.nomineDiv_'+id).css('display','none');
    }else{
      $('.nomineDiv_'+id).css('display','contens');
    }


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    // this.clientCreationService.submitnominee(postdata, localStorage.getItem('Token'))
    //   .subscribe((result) => {
        
    //     if (result.status == true) {
    //       this.Savetoggle = 'block';

    //       setTimeout(() => {
    //         this.route.navigate(['/employeruser-dashboard/family-enrollment']);
    //       }, 2000);          

    //    }

    //   }, (error) => {         
        
    //     if(error.error.message == 'Unauthenticated.'){
    //       localStorage.clear();
    //       localStorage.setItem('resstatus', 'false');
    //       this.route.navigate(['/home']);
    //     }
    // });

  }

  getrelinfo(id:any, policy_id:any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "relation_id": $('#nominee_relation_with_employee_'+id).val(),            
      "policy_id": policy_id,
      "employee_id": localStorage.getItem('employee_id')
    };

    if($('#nominee_relation_with_employee_'+id).val() != '0'){
      this.clientCreationService.getrelinfo(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        
        if (result.status == true) {

          $('#nominee_first_name_'+ id).val(result.data[0]['first_name']);
          $('#nominee_last_name_'+id).val(result.data[0]['last_name']);
          $('#nominee_dob_'+id).val(result.data[0]['dob']);
          
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
    }else{
      $('#nominee_first_name_'+ id).val('');
      $('#nominee_last_name_'+id).val('');
      $('#nominee_dob_'+id).val('');
    }
    
  }

}
