import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SurveyPolicyDetailsService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  policyDetailsCreate(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);

    filedata.append("policy_sub_category",data.policy_sub_category);
    filedata.append("existing_policy_check",data.existing_policy_check);
    filedata.append("upload_check",data.upload_check);
    filedata.append("insurance_company",data.insurance_company);
    filedata.append("upload_policydocument",data.upload_policydocument);

    filedata.append("policy_date",data.policy_date);
    filedata.append("policy_expirydate",data.policy_expirydate);
    filedata.append("policy_number",data.policy_number);
    filedata.append("previous_insurer",data.previous_insurer);
    filedata.append("broker_name",data.broker_name);
    filedata.append("tpa",data.tpa);
    filedata.append("emp_atstart",data.emp_atstart);
    filedata.append("lives_covered",data.lives_covered);
    filedata.append("emp_atreport",data.emp_atreport);
    filedata.append("lives_atreport",data.lives_atreport);

    filedata.append("family_def",data.family_def);
    filedata.append("sum_insured_type",data.sum_insured_type);
    filedata.append("sum_insured_approach",data.sum_insured_approach);
    filedata.append("age_range",data.age_range);
    filedata.append("mygradedarray",JSON.stringify(data.mygradedarray));

    filedata.append("thirty_days_waiting_period",data.thirty_days_waiting_period);
    filedata.append("one_and_half_days_waiting_period",data.one_and_half_days_waiting_period);
    filedata.append("pre_existing_condition",data.pre_existing_condition);

    filedata.append("hospitalization_tableArr",JSON.stringify(data.hospitalization_tableArr));
    filedata.append("pre_post_hospitalisation",data.pre_post_hospitalisation);
    filedata.append("domiciliary_hospitalisation",data.domiciliary_hospitalisation);
    filedata.append("domiciliary_hospitalisation_limit",data.domiciliary_hospitalisation_limit);
    filedata.append("day_care_procedures",data.day_care_procedures);
    filedata.append("day_care_procedures_limit",data.day_care_procedures_limit);

    filedata.append("maternity_tableArr",JSON.stringify(data.maternity_tableArr));
    filedata.append("nine_month_waiting_period",data.nine_month_waiting_period);
    filedata.append("room_rent_natal_cover",data.room_rent_natal_cover);
    filedata.append("bay_day_one",data.bay_day_one);
    filedata.append("bay_day_one_isCover",data.bay_day_one_isCover);
    filedata.append("baby_specific_covered_limit",data.baby_specific_covered_limit);
    filedata.append("well_baby_cover",data.baby_specific_covered_limit);
    filedata.append("well_baby_cover_isCover",data.well_baby_cover_isCover);
    filedata.append("well_baby_covered_limit",data.well_baby_covered_limit);
    filedata.append("infertility_cover",data.infertility_cover);
    filedata.append("infertility_cover_isCover",data.infertility_cover_isCover);
    filedata.append("infertility_covered_limit",data.infertility_covered_limit);
    filedata.append("surrogacy_cover",data.surrogacy_cover);
    filedata.append("surrogacy_cover_covered_limit",data.surrogacy_cover_covered_limit);
    filedata.append("maternity_new_born_vaccination",data.maternity_new_born_vaccination);
    filedata.append("maternity_new_born_vaccination_isCover",data.maternity_new_born_vaccination_isCover);
    filedata.append("maternity_new_born_vaccination_limit",data.maternity_new_born_vaccination_limit);
    filedata.append("mother_cover",data.mother_cover);
    filedata.append("mother_cover_isCover",data.mother_cover_isCover);
    filedata.append("mother_cover_covered_limit",data.mother_cover_covered_limit);
    
    filedata.append("claim_copay_condition",data.claim_copay_condition);
    filedata.append("claim_copay_condition_applicable",data.claim_copay_condition_applicable);
    filedata.append("claim_diseasewisecapping_condition",data.claim_diseasewisecapping_condition);
    filedata.append("claim_diseasewisecapping_condition_applicable",data.claim_diseasewisecapping_condition_applicable);
    filedata.append("deductible",data.deductible);
    filedata.append("deductible_condition_applicable",data.deductible_condition_applicable);
    filedata.append("SI_for_parents",data.SI_for_parents);
    filedata.append("SI_for_parents_condition_applicable",data.SI_for_parents_condition_applicable);

    filedata.append("out_patient_benefits",data.out_patient_benefits);
    filedata.append("out_patient_benefits_limit",data.out_patient_benefits_limit);
    filedata.append("opd_dental",data.opd_dental);
    filedata.append("opd_dental_limit",data.opd_dental_limit);
    filedata.append("opd_vision",data.opd_vision);
    filedata.append("opd_vision_limit",data.opd_vision_limit);
    filedata.append("preventive_health_checkup",data.preventive_health_checkup);
    filedata.append("preventive_health_checkup_limit",data.preventive_health_checkup_limit);
    filedata.append("doctor_consultation",data.doctor_consultation);
    filedata.append("doctor_consultation_limit",data.doctor_consultation_limit);
    filedata.append("diagnostics",data.diagnostics);
    filedata.append("diagnostics_limit",data.diagnostics_limit);
    filedata.append("pharmacy",data.pharmacy);
    filedata.append("pharmacy_limit",data.pharmacy_limit);
    filedata.append("new_born_vaccination",data.new_born_vaccination);
    filedata.append("new_born_vaccination_limit",data.new_born_vaccination_limit);
    filedata.append("benefit_extra_fields",JSON.stringify(data.benefit_extra_fields));

    filedata.append("hospital_daily_cash",data.hospital_daily_cash);
    filedata.append("hospital_daily_cash_limit",data.hospital_daily_cash_limit);
    filedata.append("organ_donor_cover",data.organ_donor_cover);
    filedata.append("organ_donor_cover_limit",data.organ_donor_cover_limit);
    filedata.append("hiv_cover",data.hiv_cover);
    filedata.append("hiv_cover_limit",data.hiv_cover_limit);
    filedata.append("genetic_disorder",data.genetic_disorder);
    filedata.append("genetic_disorder_limit",data.genetic_disorder_limit);
    filedata.append("lucentis_Avastin_injections",data.lucentis_Avastin_injections);
    filedata.append("lucentis_Avastin_injections_limit",data.lucentis_Avastin_injections_limit);
    filedata.append("oral_chemo_therapy",data.oral_chemo_therapy);
    filedata.append("oral_chemo_therapy_limit",data.oral_chemo_therapy_limit);
    filedata.append("cochlear_implant",data.cochlear_implant);
    filedata.append("cochlear_implant_limit",data.cochlear_implant_limit);
    filedata.append("ayush_treatment",data.ayush_treatment);
    filedata.append("ayush_treatment_limit",data.ayush_treatment_limit);
    filedata.append("lasik_treatment",data.lasik_treatment);
    filedata.append("lasik_treatment_limit",data.lasik_treatment_limit);
    filedata.append("psychiatry_mental_illness_treatment",data.psychiatry_mental_illness_treatment);
    filedata.append("psychiatry_mental_illness_treatment_limit",data.psychiatry_mental_illness_treatment_limit);
    filedata.append("endoscopic_sinus_surgery",data.endoscopic_sinus_surgery);
    filedata.append("endoscopic_sinus_surgery_limit",data.endoscopic_sinus_surgery_limit);
    filedata.append("internal_congenital",data.internal_congenital);
    filedata.append("internal_congenital_limit",data.internal_congenital_limit);
    filedata.append("external_congenital",data.external_congenital);
    filedata.append("external_congenital_limit",data.external_congenital_limit);
    filedata.append("portability_cover",data.portability_cover);
    filedata.append("corporate_buffer",data.corporate_buffer);
    filedata.append("corporate_buffer_limit",data.corporate_buffer_limit);
    filedata.append("pandemic_and_epidemic",data.pandemic_and_epidemic);

    filedata.append("road_ambulance",data.road_ambulance);
    filedata.append("road_ambulance_limit",data.road_ambulance_limit);
    filedata.append("cardiac_ambulance",data.cardiac_ambulance);
    filedata.append("cardiac_ambulance_limit",data.cardiac_ambulance_limit);
    filedata.append("air_ambulace",data.air_ambulace);
    filedata.append("air_ambulace_limit",data.air_ambulace_limit);
    
    filedata.append("settlement_type",data.settlement_type);

    filedata.append("policy_document",data.policy_document);
    filedata.append("extra_upload_files",JSON.stringify(data.extra_upload_files));

    // var uploadFiles = JSON.stringify(data.extra_upload_files);
    // for(var i = 0; i < uploadFiles.length; i++){
    //     filedata.append("extra_upload_files_"+i, uploadFiles[i][1]);
    // }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/update/' + data.survey_policy_id,
      filedata
    , httpOptions);
  }

  policyDetailsCreate_first(data: any): Observable<any> {

    // console.log(data);
    var val;

    var filedata = new FormData();
    if (data.client_id == 0) {
      
      filedata.append("tokenable_id", data.tokenable_id);    
      filedata.append("company_name", data.company_name);
      filedata.append("industry", data.industry);
      filedata.append("employee_headcount", data.employee_headcount);
      filedata.append("MNC", data.MNC);
      filedata.append("turnover", data.turnover);
      
      val = 'survey/asectionadd';

    }else{

      filedata.append("tokenable_id", data.tokenable_id);    
      filedata.append("company_name", data.company_name);
      filedata.append("industry", data.industry);
      filedata.append("employee_headcount", data.employee_headcount);
      filedata.append("MNC", data.MNC);
      filedata.append("turnover", data.turnover);
      filedata.append("client_id", data.client_id);
      filedata.append("survey_policy_id", data.survey_policy_id);

      val = 'survey/asectionupdate/' + data.survey_policy_id;

    }
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + val,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_second(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);  
        
    filedata.append("emp_atstart",data.emp_atstart);
    filedata.append("do_you_have_gmc",data.do_you_have_gmc);
    filedata.append("family_contruct",data.family_contruct);
    filedata.append("LGBTQ",data.LGBTQ);  

    filedata.append("sum_insured_type",data.sum_insured_type);                    
    filedata.append("sum_insured_approach",data.sum_insured_approach);
    filedata.append("sum_insured_uniform",data.sum_insured_uniform);
    filedata.append("mygradedarray",JSON.stringify(data.mygradedarray));
    
    filedata.append("thirty_days_waiting_period",data.thirty_days_waiting_period);
    filedata.append("one_and_half_days_waiting_period",data.one_and_half_days_waiting_period);
    filedata.append("pre_existing_condition",data.pre_existing_condition);  

    filedata.append("room_rent_condition_one",data.room_rent_condition_one);     
    filedata.append("ICU_room_rent",data.ICU_room_rent); 
    filedata.append("pre_post_hospitalisation",data.pre_post_hospitalisation);
    filedata.append("day_care_procedures",data.day_care_procedures);
    filedata.append("min_max_day_care_procedures",data.min_max_day_care_procedures);
    filedata.append("day_care_procedures_limit_perc",data.day_care_procedures_limit_perc);  
    filedata.append("day_care_procedures_limit_value",data.day_care_procedures_limit_value); 
    filedata.append("domiciliary_hospitalisation",data.domiciliary_hospitalisation);
    filedata.append("min_max_domiciliary_hospitalisation",data.min_max_domiciliary_hospitalisation);
    filedata.append("domiciliary_hospitalisation_limit_perc",data.domiciliary_hospitalisation_limit_perc);
    filedata.append("survey_policy_id",data.survey_policy_id);  
    filedata.append("domiciliary_hospitalisation_limit_value",data.domiciliary_hospitalisation_limit_value); 
    
    filedata.append("do_you_want_maternity",data.do_you_want_maternity);

    filedata.append("normal_delivery_limit_maternity",data.normal_delivery_limit_maternity);
    filedata.append("c_section_limit_maternity",data.c_section_limit_maternity);
    
    filedata.append("room_rent_natal_cover",data.room_rent_natal_cover);
    filedata.append("room_rent_natal_cover_isCover",data.room_rent_natal_cover_isCover);
    filedata.append("room_rent_natal_cover_limit",data.room_rent_natal_cover_limit);

    filedata.append("bay_day_one",data.bay_day_one);
    filedata.append("bay_day_one_isCover",data.bay_day_one_isCover);
    filedata.append("baby_specific_covered_limit",data.baby_specific_covered_limit);

    filedata.append("infertility_cover",data.infertility_cover);
    filedata.append("infertility_cover_isCover",data.infertility_cover_isCover);
    filedata.append("infertility_covered_limit",data.infertility_covered_limit);

    filedata.append("surrogacy_cover",data.surrogacy_cover);
    filedata.append("surrogacy_cover_isCover",data.surrogacy_cover_isCover);
    filedata.append("surrogacy_covered_limit",data.surrogacy_covered_limit);

    filedata.append("maternity_related_complications_yes",data.maternity_related_complications_yes);
    filedata.append("maternity_related_complications_yes_isCover",data.maternity_related_complications_yes_isCover);
    filedata.append("maternity_related_complications_no",data.maternity_related_complications_no);
    filedata.append("maternity_related_complications_no_isCover",data.maternity_related_complications_no_isCover);  

    filedata.append("copay",data.copay); 
    filedata.append("deductable",data.deductable);
    filedata.append("sum_incured_sub_limit",data.sum_incured_sub_limit);
    filedata.append("claim_diseasewisecapping_condition",data.claim_diseasewisecapping_condition);    

    filedata.append("out_patient_benefits",data.out_patient_benefits);
    filedata.append("out_patient_benefits_limit",data.out_patient_benefits_limit);  
    filedata.append("opd_dental",data.opd_dental); 
    filedata.append("opd_dental_limit",data.opd_dental_limit);
    filedata.append("opd_vision",data.opd_vision);  
    filedata.append("opd_vision_limit",data.opd_vision_limit); 
    filedata.append("preventive_health_checkup",data.preventive_health_checkup);

    filedata.append("preventive_health_checkup_limit",data.preventive_health_checkup_limit);  
    filedata.append("organ_donor_cover",data.organ_donor_cover); 
    filedata.append("min_max_organ_donor_cover",data.min_max_organ_donor_cover);
    filedata.append("organ_donor_cover_limit_perc",data.organ_donor_cover_limit_perc);
    filedata.append("organ_donor_cover_limit_value",data.organ_donor_cover_limit_value);
    filedata.append("oral_chemo_therapy",data.oral_chemo_therapy);  
    filedata.append("min_max_oral_chemo_therapy",data.min_max_oral_chemo_therapy); 
    filedata.append("oral_chemo_therapy_limit_perc",data.oral_chemo_therapy_limit_perc);
    filedata.append("oral_chemo_therapy_limit_value",data.oral_chemo_therapy_limit_value);

    filedata.append("ayush_treatment",data.ayush_treatment);  
    filedata.append("min_max_ayush_treatment",data.min_max_ayush_treatment); 
    filedata.append("ayush_treatment_limit_perc",data.ayush_treatment_limit_perc);
    filedata.append("ayush_treatment_limit_value",data.ayush_treatment_limit_value);
    filedata.append("lasik_treatment",data.lasik_treatment);
    filedata.append("min_max_lasik_treatment",data.min_max_lasik_treatment);  
    filedata.append("lasik_treatment_limit_perc",data.lasik_treatment_limit_perc); 
    filedata.append("lasik_treatment_limit_value",data.lasik_treatment_limit_value);
    filedata.append("corporate_buffer",data.corporate_buffer);

    filedata.append("corporate_buffer_isCover",data.corporate_buffer_isCover);  
    filedata.append("corporate_buffer_limit",data.corporate_buffer_limit); 
    filedata.append("corporate_buffer_family_limit",data.corporate_buffer_family_limit);
    filedata.append("road_ambulance",data.road_ambulance);
    filedata.append("min_max_road_ambulance",data.min_max_road_ambulance);
    filedata.append("road_ambulance_limit_perc",data.road_ambulance_limit_perc);  
    filedata.append("road_ambulance_limit_value",data.road_ambulance_limit_value); 
    filedata.append("cardiac_ambulance",data.cardiac_ambulance);
    filedata.append("min_max_cardiac_ambulance",data.min_max_cardiac_ambulance);

    filedata.append("cardiac_ambulance_limit_perc",data.cardiac_ambulance_limit_perc);  
    filedata.append("cardiac_ambulance_limit_value",data.cardiac_ambulance_limit_value); 
    filedata.append("air_ambulance",data.air_ambulance);
    filedata.append("min_max_air_ambulance",data.min_max_air_ambulance);
    filedata.append("min_max_air_ambulance",data.min_max_air_ambulance);
    filedata.append("air_ambulance_limit_perc",data.air_ambulance_limit_perc);  
    filedata.append("air_ambulance_limit_value",data.air_ambulance_limit_value); 
    filedata.append("Premium_Contribution",data.Premium_Contribution);
    filedata.append("do_you_offer_topup",data.do_you_offer_topup);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'survey/bsectionupdate/' + data.survey_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_third(data: any): Observable<any> {

    // console.log(data);
    
    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);

    filedata.append("do_you_have_gpa",data.do_you_have_gpa),
    filedata.append("no_employess_gpa",data.no_employess_gpa);

    //Sum Incured
    filedata.append("gpa_SI_type",data.SI_type),
    filedata.append("multiple_of_salary",data.multiple_of_salary),
    filedata.append("Define_flat_SI",data.Define_flat_SI),
    filedata.append("SI_Junior_management",data.SI_Junior_management),
    filedata.append("SI_Middle_management",data.SI_Middle_management),
    filedata.append("SI_Senior_management",data.SI_Senior_management),
    filedata.append("Graded_extra_fields",JSON.stringify(data.Graded_extra_fields));    

    filedata.append("Accidental_Death",data.Accidental_Death);
    filedata.append("Accidental_Death_limit",data.Accidental_Death_limit);
    filedata.append("Permanent_Total_Disability",data.Permanent_Total_Disability);
    filedata.append("Permanent_Total_Disability_limit",data.Permanent_Total_Disability_limit);
    filedata.append("Permanent_Partial_Disability",data.Permanent_Partial_Disability);
    filedata.append("Permanent_Partial_Disability_limit",data.Permanent_Partial_Disability_limit);
    filedata.append("Total_disability_loss_of_wages",data.Total_disability_loss_of_wages);
    filedata.append("Total_disability_loss_of_wages_limit",data.Total_disability_loss_of_wages_limit);
    filedata.append("Total_disability_loss_of_wages_INR_per_week",data.Total_disability_loss_of_wages_INR_per_week);
    filedata.append("Total_disability_loss_of_wages_actual_weekly_salary",data.Total_disability_loss_of_wages_actual_weekly_salary);

    //Others   
    filedata.append("Medical_benefits_Medex",data.Medical_benefits_Medex);
    filedata.append("Medical_benefits_Medex_limit",data.Medical_benefits_Medex_limit);

    filedata.append("Double_dismemberment",data.Double_dismemberment);
    // filedata.append("Double_dismemberment_limit",data.Double_dismemberment_limit);

    filedata.append("Transportation_of_mortal_remains",data.Transportation_of_mortal_remains);
    filedata.append("Transportation_of_mortal_remains_limit",data.Transportation_of_mortal_remains_limit);

    filedata.append("Ambulance_charges",data.Ambulance_charges);
    filedata.append("Ambulance_charges_limit",data.Ambulance_charges_limit);

    filedata.append("Modification_benefit",data.Modification_benefit);
    filedata.append("Modification_benefit_limit",data.Modification_benefit_limit);

    filedata.append("Education_benefit",data.Education_benefit);
    filedata.append("Education_benefit_limit",data.Education_benefit_limit);

    filedata.append("Funeral_and_Repatriation_expenses",data.Funeral_and_Repatriation_expenses);
    filedata.append("Funeral_and_Repatriation_expenses_limit",data.Funeral_and_Repatriation_expenses_limit);

    filedata.append("do_you_have_topup",data.do_you_have_topup);
    filedata.append("offer_spouse_PA",data.offer_spouse_PA);
    

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'survey/csectionupdate/' + data.survey_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_fourth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);  
    
    filedata.append("do_you_have_gtl",data.do_you_have_gtl);
    filedata.append("no_employess_gtl",data.no_employess_gtl);
    
    //Sum Incured
    filedata.append("SI_type_gtl",data.SI_type_gtl),
    filedata.append("multiple_of_salary_gtl",data.multiple_of_salary_gtl),
    filedata.append("Define_flat_SI_gtl",data.Define_flat_SI_gtl),
    filedata.append("SI_Junior_management_gtl",data.SI_Junior_management_gtl),
    filedata.append("SI_Middle_management_gtl",data.SI_Middle_management_gtl),
    filedata.append("SI_Senior_management_gtl",data.SI_Senior_management_gtl),
    filedata.append("Graded_extra_fields_gtl",JSON.stringify(data.Graded_extra_fields_gtl));    

    filedata.append("actively_at_work",data.actively_at_work),
    filedata.append("Waived_For_all_Employees",data.Waived_For_all_Employees),
    filedata.append("Only_For_Existing_Employees",data.Only_For_Existing_Employees),

    filedata.append("Terminal_illness",data.Terminal_illness);
    filedata.append("Terminal_illness_limit",data.Terminal_illness_limit);

    filedata.append("Accidental_death_benefit",data.Accidental_death_benefit);
    filedata.append("Accidental_death_benefit_limit",data.Accidental_death_benefit_limit);

    filedata.append("Accelerated_critical_illness",data.Accelerated_critical_illness);
    filedata.append("Accelerated_critical_illness_limit",data.Accelerated_critical_illness_limit);

    filedata.append("Additional_critical_illness",data.Additional_critical_illness);
    filedata.append("Additional_critical_illness_limit",data.Additional_critical_illness_limit);

    filedata.append("do_you_have_topupplan",data.do_you_have_topupplan);
    filedata.append("do_you_have_spouse_cover",data.do_you_have_spouse_cover);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'survey/dsectionupdate/' + data.survey_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_fivth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);  
    
    filedata.append("do_you_have_opd",data.do_you_have_opd);
    filedata.append("OPD_cashless",data.OPD_cashless);        
    filedata.append("Preventive_Health_Check_up",data.Preventive_Health_Check_up);
    filedata.append("Annual_Health_Check_up",data.Annual_Health_Check_up);
    filedata.append("medical_facilities",data.medical_facilities);    

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'survey/esectionupdate/' + data.survey_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_sixth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);  
    
    filedata.append("offer_gratuity",data.offer_gratuity);
    filedata.append("offer_gratuity_per_law",data.offer_gratuity_per_law);

    filedata.append("do_you_have_ROB",data.do_you_have_ROB);
    filedata.append("offer_pension_plans",data.offer_pension_plans);        
    filedata.append("offer_car_leases",data.offer_car_leases);
    filedata.append("offer_leave_encashment",data.offer_leave_encashment);  
    filedata.append("offer_canteen_facility",data.offer_canteen_facility);
    filedata.append("offer_creche_facilities",data.offer_creche_facilities);
    filedata.append("offer_employee_emergency",data.offer_employee_emergency);
    filedata.append("offer_courses",data.offer_courses);
    filedata.append("offer_pick_drop",data.offer_pick_drop);
    filedata.append("offer_Employee_stock",data.offer_Employee_stock);
    filedata.append("offer_Gadget_allowance",data.offer_Gadget_allowance);
    filedata.append("offer_flexi_work",data.offer_flexi_work);
    filedata.append("offer_flexi_pay",data.offer_flexi_pay);
    filedata.append("offer_sabbatical_benefits",data.offer_sabbatical_benefits);

    filedata.append("offer_menstural_leave",data.offer_menstural_leave);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'survey/fsectionupdate/' + data.survey_policy_id,
      filedata
    , httpOptions);
    
  }

  getIsuranceCompanyDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'insurance-comp-detailsfront',
    httpOptions);
  }

  getTPADetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'tpa-detailsfront',
    httpOptions);
  }

  getBrokerDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'broker-detailsfront',
    httpOptions);
  }

  getSubCategory(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'productsub-info/product/'+ data.id, 
    data,   
    httpOptions);
  }

  policyDetails_planName(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("survey_policy_id",data.survey_policy_id);  
    filedata.append("plan_name",data.plan_name);
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/updateplandetails/' + data.survey_policy_id,
      filedata
    , httpOptions);
    
  }

  getBasePlan(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/getclientbasepolicy/' + data.client_id,
    data,
    httpOptions);
  }

  getuserdetails(postdataObj: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'get-user-details', 
    postdataObj
    , httpOptions);
  }

  getclientsurveyinfo(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'survey/getclientsurveyinfo/' + data.client_id,
    data,
    httpOptions);
  }
  
}
