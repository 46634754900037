<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Network Hospital</h3>   
            
            <div class="row mt-4">

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="policy_id">Policy <span class="redstar">*</span></label>
                        <select class="form-select" id="policy_id" (change)="getState()">
                            <option value="">Select Policy</option>
                            <option *ngFor="let policy of policyArray" [value]="policy.id">{{ policy.policy_name }}</option>
                        </select>          
                        <!-- <div id="policy_error" class="text-danger">Please select a policy.</div>               -->
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="state_id">State <span class="redstar">*</span></label>
                        <ng-select id="state_id"  (change)="onStateChange($event)"
                                    [items]="stateArray"
                                    bindLabel="state_name"
                                    bindValue="state_id"
                                    [placeholder]="'Select State'" 
                                    [(ngModel)]="selectedState">
                        </ng-select>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="city_id">City</label><span class="redstar">*</span>
                        <ng-select id="city_id"  (change)="displayMarkers()"
                                    [items]="citiesArray"
                                    bindLabel="city_name"
                                    bindValue="city_name"
                                    [placeholder]="'Select City'" 
                                    [(ngModel)]="selectedCity">
                        </ng-select>
                    </div>
                </div>

            </div>

            <div class="row mt-3">
                <div class="col-sm-9">
                    <div id="map" style="width: 100%; height: 500px; display: none;"></div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6021144.452232914!2d73.33764832023426!3d20.593684070566295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d60f4f9b5f94f%3A0x4a4c6d126d94433b!2sIndia!5e0!3m2!1sen!2sus!4v1624558764795!5m2!1sen!2sus"
                        width="100%"
                        height="500"
                        id="map_root"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"                        
                    ></iframe>            
                </div>
                <div class="col-sm-3">
                    <div style="height: 500px; overflow-y: scroll; padding: 10px; background: #f8f9fa; border-radius: 10px; margin-bottom: 20px;">
                        <div *ngIf="HospitalArray.length == 0">
                            No Hospital network list available
                        </div>                        
                        <div class="card mb-3" style="border-radius: 10px;" *ngFor="let res of HospitalArray; let i = index">
                            <div class="card-body">
                                <div class="card-title" style="font-size: 16px; color: var(--bs-sidebar);">
                                    <b>{{res.hospital_name}}</b>
                                </div>
                                <div class="card-text" style="font-size: 12px;">
                                    {{res.address1}}
                                </div>
                                <div class="card-text" style="font-size: 12px;">
                                    {{res.address2}} - {{res.pin_code}}
                                </div>
                                <div class="card-text" style="font-size: 12px; margin-bottom: 15px;">
                                    {{res.city_name}}, {{res.state_name}}
                                </div>
                                <div class="card-text" style="font-size: 12px; margin-bottom: 15px;">
                                    <b>Phone No. {{res.phone_no}}</b>
                                </div>
                                <div>
                                    <button class="btn btn-primary" (click)="callmaps(res.pin_code)">Locate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>            

            <!-- AIzaSyDr8kCpkWxnmbJEAa26DuVq0fQvCoy54nA -->                    
        
        </div>
        <app-footer></app-footer>
    </div>
</div>