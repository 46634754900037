<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
  

          <h3 class="bs-title"  *ngIf="addflag == 0">TPA Trigger<span class="spanbs-title"> Logs </span></h3>
          
          <hr class="mb-2 mt-4" style="height: 0px">
  
          <!-- Add -->
          <div class="card">
            <div class="card-body">
              <form [formGroup]="basicInfo" *ngIf="addflag == 0">
                <div class="row">
      
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="client_id">Client <span class="redstar">*</span></label>
                            <ng-select formControlName="client_id" id="client_id" (change)="getPolicyType()" (change)="getpolicynumbers()"
                              [items]="InfoArrayCorporate"
                              bindLabel="name"
                              bindValue="id"
                              [placeholder]="'Select Client'">
                            </ng-select>
                        </div>
                    </div>
    
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
                            <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getpolicynumbers()">
                                <option value="">Select Policy Type</option>
                                <option  *ngFor="let policyType of policy_type; let i = index" value="{{policyType.sub_type_id}}"> {{policyType.name}}</option>                          
                            </select>
                        </div>
                    </div>
    
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="policy_name">Policy Name <span class="redstar">*</span></label>
                            <select class="form-select" formControlName="policy_name" id="policy_name" (change)="getAPI()">
                                <option value="">Select Policy Name </option>
                                <option *ngFor="let corporatelist of policynumbers; let i = index" value="{{corporatelist.id}}">
                                    {{corporatelist.policy_number}}</option>
                            </select>
                        </div>
                    </div>
                
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="api">Logs <span class="redstar">*</span></label>
                            <select class="form-select" formControlName="api" id="api" (change)="getInfo()">
                                <option value="">Select Logs</option>
                                <option *ngFor="let corporatelist of InfoArrayAPI; let i = index" value="{{corporatelist.id}}">
                                    {{corporatelist.api_name}}</option>
                            </select>
                        </div>
                    </div>
    
                </div>
              </form>
            </div>
          </div>
         
  
          <div class="table-responsive mt-3" *ngIf="addflag == 0">
            <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width:5%; position: sticky; left: 0; background-color: #efefef; z-index: 10;">SrNo</th>
                        <!-- <th>Client</th> -->
                        <th style="position: sticky; left: 5%; background-color: #efefef; z-index: 10;">Policy No.</th>
                        <th>Policy Name</th>
                        <th>API Name</th>
                        <th>Request Name</th>
                        <th>Status Code</th>                      
                        <th>Method</th>
                        <th>Uploaded At</th>
                        <!-- <th>Status</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%; position: sticky; left: 0; background-color: #fff; z-index: 10;">{{i+1}}</td>
                        <!-- <td>{{group['company_name']}}</td> -->
                        <td  style="position: sticky; left: 5%; background-color: #fff; z-index: 10;">{{group['policy_no']}}</td>
                        <td>{{group['policy_name']}}</td>
                        <td>{{group['api_name']}}</td>
                        <td>{{group['request_name']}}</td>
                        <td>{{group['status_code']}}</td>
                        <td>{{group['method']}}</td>
                        <!-- <td>
                          <p style="color: #0382cc; font-size: 13px;">
                            <i class="mdi mdi-account"></i>
                            Total Uploaded: {{group['total_count']}} 
                            <span *ngIf="group['total_count'] == 1">Row</span>
                            <span *ngIf="group['total_count'] != 1">Rows</span>
                          </p>
                          <p style="color: #32cc03; font-size: 13px;">
                            <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                            Added Successfully: {{group['sucess_count']}} 
                            <span *ngIf="group['sucess_count'] == 1">Row</span>
                            <span *ngIf="group['sucess_count'] != 1">Rows</span>
                          </p>
                          <p style="color: #f03800; font-size: 13px;" *ngIf="group['fail_count'] >= 1">
                            <i class="mdi mdi-close"></i>
                            Failed To Add: {{group['fail_count']}} 
                            <span *ngIf="group['fail_count'] == 1">Row</span>
                            <span *ngIf="group['fail_count'] != 1">Rows</span>
                          </p>                        
                        </td> -->
                        <!-- <td>
                          <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['original_document'])" style="cursor: pointer" />                        
                        </td> -->
                        <!-- <td>
                          <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                          <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'])" style="cursor: pointer" *ngIf="group['fail_count'] >= 1" />                        
                        </td>                       -->
                        <!-- <td>
                          <span *ngIf="group['status'] == 'process'" class="btn btn-inverse-warning btn-fw">Process</span>
                          <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                          <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                          <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                        </td> -->
                        <td>{{group['added_date']}}</td>          
                    </tr>
                </tbody>
            </table>
          </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
  