import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { CommonService } from '../../../_services/common.service';
import { ToastService } from '../../../toast.service';

@Component({
  selector: 'app-flex-plan-multiple',
  templateUrl: './flex-plan-multiple.component.html',
  styleUrls: ['./flex-plan-multiple.component.css']
})
export class FlexPlanMultipleComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  user_name = localStorage.getItem('name');

  policy_info_id:any;
  title_text:any; 
  disclaimer:any;
  mandatory_type:any;

  gmccolorCodes:any = ['#fff', '#c4d6d8', '#93b7bb', '#62979d', '#317880', '#005963'];
  gpacolorCodes:any = ['#fff', '#c4e6e7', '#93d8da', '#62c9cc', '#31bbbf', '#00acb1'];
  gtlcolorCodes:any = ['#fff', '#ecd5d7', '#e3b4b9', '#da949a', '#d1737c', '#c8535e'];

  OPDarray:any = [
    {benefit_description:'24x7 online GP consult', col_2:'Unlimited', col_3:'Unlimited', col_4:'Unlimited', col_5:'Unlimited'},
    {benefit_description:'Specialist Consults (Virtual)', col_2:'2', col_3:'3', col_4:'5', col_5:'3'},
    {benefit_description:'Vision Cover ', col_2:'Vision Cover ', col_3:'Cover - Upto INR 5000 per Family Premium - INR 9754 Inclusion- INR 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures', col_4:'Cover - Upto INR 5000 per Family Premium - INR 9754 Inclusion- INR 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures', col_5:'Cover - Upto INR 5000 per Family Premium - INR 9754 Inclusion- INR 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures'},
    {benefit_description:'Dental Cover', col_2:'0', col_3:'Cover - Upto INR 5000 per Family Premium-  INR 9754 Inclusion- INR 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.', col_4:'Cover - Upto INR 5000 per Family Premium-  INR 9754 Inclusion- INR 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.', col_5:'Cover - Upto INR 5000 per Family Premium-  INR 9754 Inclusion- INR 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.'},
    {benefit_description:'Health Check-up including Preventive', col_2:'Upto INR 5,000 per Family', col_3:'Upto INR 5,000 per Family', col_4:'Upto INR 5,000 per Family', col_5:'Upto INR 5,000 per Family'},
    {benefit_description:'Children Vaccination Cover', col_2:'0', col_3:'Upto INR 2000 per Family', col_4:'0', col_5:'0'},
    {benefit_description:'Diagnostic Cover', col_2:'Upto INR 2,000 per Family', col_3:'Upto INR 2,000 per Family', col_4:'Upto INR 2,000 per Family', col_5:'Upto INR 2,000 per Family'},
    {benefit_description:'In-Person Consultation', col_2:'3', col_3:'3', col_4:'3', col_5:'3'},
    {benefit_description:'Maternity care', col_2:'Nutrition counselling & Health content', col_3:'Gynaecologist tele consult, Nutrition counselling & Health content', col_4:'Gynaecologist tele consult, Nutrition counselling & Health content', col_5:'Gynaecologist tele consult, Nutrition counselling & Health content'},
    {benefit_description:'Inclinic Consultation', col_2:'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_3:'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_4:'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_5:'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations'},
    {benefit_description:'0', col_2:'Add to Compare', col_3:'Add to Compare', col_4:'Add to Compare', col_5:'Add to Compare'},
    {benefit_description:'0', col_2:'Selected', col_3:'Select', col_4:'Select', col_5:'Select'},
    {benefit_description:'0', col_2:'Watch Plan Video', col_3:'Watch Plan Video', col_4:'Watch Plan Video', col_5:'Watch Plan Video'}        
  ];

  TestArray:any = [{
    "title": "24x7 online GP consult",
    "column_1": "Unlimited"    
    },
    {
      "title": "Specialist Consults (Virtual)",
      "column_1": "Unlimited"      
    }
  ];

  plan_Array:any = [];
  pricingarr:any = [];  
  TableArray:any = [];
  keys: string[] = []; // Define the keys array
  data: any[] = []; // Define the data array
  policyname:any;
  plan_array:any = [];
  subPlanArray:any = [];
  premium_Array:any = [];
  masterids_Array:any = [];
  basedata_length:any;
  policyname_color:any;
  page_title:any
  page_desc:any;
  gotoflag:any;
  plan_id:any;
  flex_benefit_id:any;
  flex_plan_idfk:any;

  hide_employer_contribution:any;

  payloadArry:any = {};
  additional_pay:any;
  employer_pay_premium:any = localStorage.getItem('employer_pay_premium');
  you_pay_premium:any = localStorage.getItem('you_pay_premium');
  my_purchase:any = localStorage.getItem('my_purchase');
  my_purchase_value:any;
  isChecked: boolean[] = [];

  extractedString:any;

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router,public commonservice: CommonService, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.you_pay_premium = this.you_pay_premium.replace(/₹/g, "");

    this.initGroup();  
    
    this.show = true;
    setTimeout(() => {
        this.my_purchase_value = this.my_purchase_value == undefined ? localStorage.getItem('my_purchase_final') : this.my_purchase_value; 
        this.show = false;
    }, 2000);    

  }

  initGroup(){                   

    this.getData();    

  }  

  getData(){
    
    this.show = true;

    setTimeout(() => {
      this.show = false;
  }, 2000); 

    let multiple_data_arr:any = localStorage.getItem("multiple_data");
    let multiple_arr_info = JSON.parse(multiple_data_arr);    
    this.policyname_color = multiple_arr_info.dropdown_name;
    this.hide_employer_contribution = multiple_arr_info.hide_employer_contribution;
    let allShowOne = true; // Flag to track if all show properties are set to 1
    this.TableArray = [];
    this.plan_Array = [];

    for (let i = 0; i < multiple_arr_info.alldata.length; i++) {
      let obj = multiple_arr_info.alldata[i];
      if (obj.show === 0 && obj.data === 0) {
        
        this.page_title = obj.benefit_name;
        this.page_desc = obj.benefit_description;
        this.TableArray = obj.table_rows; 
        this.disclaimer = obj.plan_disclaimer;  
        this.mandatory_type = obj.mandatory_type;  
        this.subPlanArray = obj.subplan_id;
        this.premium_Array = obj.premium_Array;   
        this.masterids_Array = obj.masterids;
        this.plan_id = obj.plan_id;
        this.flex_benefit_id = obj.flex_benefit_id;
        this.flex_plan_idfk = obj.flex_plan_idfk;
                        
        /**********/
        for(var j = 0; j < obj.plan_Array.length; j++){
          this.plan_Array.push({plan_name:obj.plan_Array[j], bgcolor:"", color: ""});                           
        }

        console.log("TableArray", this.TableArray);
        // console.log(this.plan_Array);

        let tabColors = this.assignBackgroundColors(this.plan_Array);  
        // console.log(tabColors);

        for(var k = 0; k < this.plan_Array.length; k++){        
          this.plan_Array[k]['bgcolor'] = tabColors[k];
          if(tabColors[k] == '#fff'){
            this.plan_Array[k]['color'] = '#000';
          }else{
            this.plan_Array[k]['color'] = '#fff';
          }          
        }          
        obj.show = 1;

        // console.log(this.masterids_Array);
        // for(var h = 0; h < this.masterids_Array.length; h++){
        //   // console.log(obj.is_selected_planid,this.masterids_Array[h]);
        //   if(obj.is_selected_planid == this.masterids_Array[h]){
        //     console.log('#plan_'+h);
        //     let a:any = '#plan_'+h;
        //     setTimeout(() => {
        //       $(a).prop('checked', true);
        //     }, 1000);              
        //     this.handleCheckbox(h,1);
        //   }            
        // }          
        // console.log(this.masterids_Array);
        for (var h = 0; h < this.masterids_Array.length; h++) {
          // console.log(obj.is_selected_planid, this.masterids_Array[h]);
          if (obj.is_selected_planid == this.masterids_Array[h]) {
              let a: any = '#plan_' + h;
              // console.log('-----',h);
              let cnt:any = h;
              setTimeout(() => {                  
                  $(a).prop('checked', true); // Set checkbox state using jQuery                  
                  this.handleCheckbox(cnt, 1);
              }, 1000);
          }
        }      
        break;
      }        
    }
    
    // console.log(multiple_arr_info);
    localStorage.setItem('multiple_data', JSON.stringify(multiple_arr_info));


    for (let i = 0; i < multiple_arr_info.alldata.length; i++) {
      let showData = multiple_arr_info.alldata[i];

      if (showData.show === 0) {
        allShowOne = false; // Set the flag to false if any show property is 0
        break;
      }

      if (!allShowOne) {
          break; // No need to continue checking if any show property is 0
      }
    }

    // Check if all show properties are set to 1
    if (allShowOne) {
      this.gotoflag = 1;  
    }else{
      this.gotoflag = 0;
    }

    // console.log("gotoflag",this.gotoflag);

  }

  getInfo() {

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "policy_id": localStorage.getItem('policy_info_id_final')
    };

    this.dataservice.getInfo(postdata)
    .subscribe((result) => {
      if (result.status == true) {

        let res_len:any = result.data.alldata.length;
        if(res_len == 0){
          this.toastService.showSuccess('Sucess', 'Detalis Saved Sucessfully!');
          setTimeout(() => {
            console.log(22)
            this.route.navigate(['/flex-plan-single']);
          }, 1000);
        }else{
          localStorage.removeItem('multiple_data');
          localStorage.setItem('multiple_data', JSON.stringify({alldata:this.setmultiple_data(result.data.alldata), dropdown_name:result.data.drop_down_name, hide_employer_contribution: result.data.hide_employer_contribution}));
          
          this.route.navigate(['/multiple-plan']);
          // window.location.reload();                  
        }  
        
        console.log('hide_employer_contribution:', result.data.hide_employer_contribution);

        // Set hide_employer_contribution
        this.hide_employer_contribution = result.data.hide_employer_contribution;
               
      }
      
    }, (error) => {

      if (error.error.message == 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/home']);
      }
    });
  }

  // Function to convert keys
  
  convertKeys(data:any) {
    const dataArray:any = Object.values(data);

    // Now you can iterate over the array
    for (let i = 0; i < dataArray.length; i++) {
        // Check if the current element is a string before calling replace
        if (typeof dataArray[i] === 'string') {
            dataArray[i] = dataArray[i].replace(/_/g, ' '); // Replace underscores with spaces
        }
    }
    return dataArray;
  }

  assignBackgroundColors(tableArray:any) {
    const numRecords = tableArray.length;
    const tabColors = [];

    let codes:any;
    if(this.policyname_color == 'gmc_dropdown' || this.policyname_color == 'gmc_topup_dropdown'){
      codes = this.gmccolorCodes;
    }
    if(this.policyname_color == 'gpa_dropdown' || this.policyname_color == 'gpa_topup_dropdown'){
      codes = this.gpacolorCodes;
    }
    if(this.policyname_color == 'gtl_dropdown' || this.policyname_color == 'gtl_topup_dropdown'){
      codes = this.gtlcolorCodes;
    }

    if (numRecords === 1) {
        tabColors.push(codes[0]);
    } else if (numRecords === 2) {
        tabColors.push(codes[0], codes[5]);
    } else if (numRecords === 3) {
        tabColors.push(codes[0], codes[2], codes[5]);
    } else if (numRecords === 4) {
        tabColors.push(codes[0], codes[3], codes[2], codes[5]);
    } else if (numRecords >= 5) {
        for (let i = 0; i < numRecords; i++) {
            tabColors.push(codes[i]);
        }
    }

    return tabColors;
  }

  gotoback(){        
    this.route.navigate(['/flex-plan-single']);
  }

  goto(){                                     

    let policies_arr:any = localStorage.getItem("policies");
    let policies_arr_info = JSON.parse(policies_arr);
    let allShowOne = true; // Flag to track if all show properties are set to 1
    let topupflag = false;    

    for (let i = 0; i < policies_arr_info.length; i++) {
      let showData = policies_arr_info[i].show_data;

      for (let j = 0; j < showData.length; j++) {
          if (showData[j].show === 2 && this.policyname_color == policies_arr_info[i]['name']) {          
            // console.log("topup_id",showData[j].topup_id);
            if(showData[j].topup_id != '0'){
              topupflag = true;
            }
            localStorage.setItem('policy_info_id_final',showData[j].topup_id);
            showData[j].show = 1;
            break;
          }
          if (showData[j].show === 0) {
              allShowOne = false; // Set the flag to false if any show property is 0
              break;
          }
      }

      if (topupflag) {
        this.getInfo();
        allShowOne = false;
        break;
      }

      if (!allShowOne) {
          break; // No need to continue checking if any show property is 0
      }
      
    }

    // console.log('topupflag',topupflag);

    // Check if all show properties are set to 1
    if(topupflag == false){
      if (allShowOne) {
        this.route.navigate(['/flex-plan-summary']);          
      }else{
        this.route.navigate(['/flex-plan-single']);
      }
    }
  }

  dynamicKeys(pricing: any): string[] {
    // Get the keys of the object
    return Object.keys(pricing);
  }

  getColumns(): string[] {
    // Assuming all rows have the same set of columns
    return Object.keys(this.TableArray[0]).filter(key => key !== 'title');
  }

  gotoflagfun(){

    if (this.mandatory_type == 1 && !this.checkIfAnyCheckboxChecked()) {
      this.toastService.showWarning('Warning','Benefit Mandatory, Please Select the Checkbox!');
      return;
    }else{
      this.onSubmit();
    }

    if(this.gotoflag == 1){
      setTimeout(() => {
        this.goto();
      }, 3000);
    }else{
      setTimeout(() => {
        this.getData();
      }, 3000);
    }

   

   
  }

  setmultiple_data(arr:any){
    for (let i = 0; i < arr.length; i++) {
      arr[i]['show'] = 0;
      if(arr[i] == ''){
        arr[i]['data'] = 1;
      }else{
        arr[i]['data'] = 0;
      }      
    }
    // console.log(arr);
    return arr;
  }

  clearCache() {
    // Add a random query parameter to the URL
    var url = window.location.href + '?nocache=' + new Date().getTime();
    // Redirect to the new URL
    window.location.href = url;
  }

  handleCheckbox(val:any, flag:any){
    
    // Uncheck all checkboxes first
    for (let i = 0; i < this.subPlanArray.length; i++) {
      if (i !== val) {
          const checkbox = document.getElementById(`plan_${i}`) as HTMLInputElement;
          if (checkbox) {
              checkbox.checked = false;
          }
      }
    }
    
    let master_id:any = [];
    let subplan_id:any;
    let policy_id:any = localStorage.getItem('policy_info_id_final');
    let plan_id:any = this.plan_id;
    let flex_benefit_id:any = this.flex_benefit_id;
    let flex_plan_idfk:any = this.flex_plan_idfk;
    let employee_id:any = localStorage.getItem('employee_id');   

    for(let i = 0; i < this.subPlanArray.length; i++){
      if(i==val){
        subplan_id = this.subPlanArray[i];
      }
    }                

    // console.log(val);
    if(this.TableArray.length > 1){
      for(let i = 0; i < this.TableArray.length; i++){
        // console.log(this.TableArray[i]['master_id'].split(','));
        const masterIdArr = this.TableArray[i]['master_id'].split(',');
        // console.log(i,val,masterIdArr[val]);
        master_id.push(masterIdArr[val]);
      } 

      let sum = 0;
      for (let i = val; i < this.premium_Array.length; i += 2) {
          sum += parseFloat(this.premium_Array[i]);
      }      

      if(flag == 1){
        this.additional_pay = sum;
      }else{
        if (this.checkIfAnyCheckboxChecked()) {
          this.additional_pay = sum;
        } else {
          this.additional_pay = 0;
        }
      }      
      
    }else{
      for(let i = 0; i < this.masterids_Array.length; i++){
        if(i==val){
          master_id.push(this.masterids_Array[i]);
        }
      } 
      for(let i = 0; i < this.premium_Array.length; i++){
        if(i==val){          
          if(flag == 1){
            this.additional_pay = this.premium_Array[i];
          }else{
            if (this.checkIfAnyCheckboxChecked()) {
              this.additional_pay = this.premium_Array[i];
            } else {
              this.additional_pay = 0;
            }
          }           
        }
      }
    }       

    /***********************************/
    let regex = /\((.*?)\)/;

    let match = this.you_pay_premium.match(regex);
    let extractedString = match ? match[1] : null;

    let match_my_prchase = this.my_purchase.match(regex);
    let extractedString_my_purchase = match_my_prchase ? match[1] : null;
    
    let my_purchase:any = 0;
    if (!localStorage.getItem('my_purchase_final')) {
      // Key does not exist     
      my_purchase = this.my_purchase;
    } else {
      my_purchase = localStorage.getItem('my_purchase_final');
    }
    let modifiedString_my_purchase = my_purchase.replace(/₹|Tax (Excluded|Included)/g, "");            
      
    if(flag == 1){    
      // console.log(1)    
      let modifiedString = this.you_pay_premium.replace(/₹|Tax (Excluded|Included)/g, "");            
      let val2:any = parseFloat(modifiedString) + parseFloat(this.additional_pay);
      let val3:any = parseFloat(modifiedString_my_purchase)  + parseFloat(this.additional_pay);
      this.you_pay_premium = val2 +' '+ '(' + extractedString+ ')';
      this.my_purchase_value = val3 +' '+ '(' + extractedString+ ')';
      this.extractedString = extractedString;
    }else{
      // console.log(2)   
      let val2:any = this.additional_pay;
      let val3:any = parseFloat(modifiedString_my_purchase)  + parseFloat(this.additional_pay);
      this.you_pay_premium = val2 +' '+ '(' + extractedString_my_purchase+ ')';      
      this.my_purchase_value = val3 +' '+ '(' + extractedString_my_purchase+ ')';   
      this.extractedString = extractedString_my_purchase;       
    }
    /***********************************/

    let myisChecked:any;
    // Example usage
    if (this.checkIfAnyCheckboxChecked()) {    
      myisChecked = 1;
    } else {
      myisChecked = 0;
    }

    this.payloadArry = {
      tokenable_id: localStorage.getItem('tokenable_id'),
      policy_id: policy_id,
      flex_plan_id: plan_id,
      subplan_id:subplan_id,
      master_id: JSON.stringify(master_id),
      flex_benefit_id: flex_benefit_id,
      // flex_plan_idfk: flex_plan_idfk,
      employee_id:employee_id,
      isChecked: myisChecked 
    }    
      
  } 

  onSubmit(){

    this.show = true;

    var postdata = this.payloadArry;

    if(Object.keys(this.payloadArry).length === 0){
      console.log('no checkbox checked.');
    }else{
      
      localStorage.setItem('my_purchase_final',this.my_purchase_value);
           
      this.dataservice.plandatasaveinfo(postdata)
      .subscribe((result) => {
        if (result.status == true) {
         this.toastService.showSuccess('Success', 'Details Saved Successfully!'); 

         setTimeout(() => {
          this.show = false;
      }, 3000); 

        }else{
          this.toastService.showFailed('Failed', 'There was some error');
        }
        
      }, (error) => {
        this.toastService.showFailed('Failed', 'There was some error');
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });  

    }
    setTimeout(() => {
      this.you_pay_premium = '0 (Tax Included)'; 
      this.additional_pay = '0.00'; 
    }, 3000);

  }

  gotoenrollment(){
    this.route.navigate(['/employeruser-dashboard/family-enrollment/policy-details-family']);
  }

  checkIfAnyCheckboxChecked(): boolean {
    for (let i = 0; i < this.subPlanArray.length; i++) {
        const checkbox = $(`#plan_${i}`);
        if (checkbox.prop('checked')) {
            return true; // At least one checkbox is checked
        }
    }
    return false; // No checkbox is checked
  }

  skipfun(){
    if (this.mandatory_type == 1 && !this.checkIfAnyCheckboxChecked()) {
      this.toastService.showWarning('Warning','Benefit Mandatory, Please Select the Checkbox!');
      return;
    }

    localStorage.setItem('my_purchase_final',this.my_purchase_value);    
    if(this.gotoflag == 1){
      this.goto();
    }else{
      this.getData();
    }
    this.you_pay_premium = '0 (Tax Included)';
  }

}
