// toast.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  showSuccess(title: string, message: string) {
    this.showToast(title, message, 'success');
  }

  showFailed(title: string, message: string) {
    this.showToast(title, message, 'failed');
  }

  showInfo(title: string, message: string) {
    this.showToast(title, message, 'info');
  }

  showWarning(title: string, message: string) {
    this.showToast(title, message, 'warning');
  }

  showToast(title: string, message: string, type: string) {
    // Create toast element
    const toast = document.createElement('div');
    toast.className = `toast ${type}`;

    // Create title element
    const titleElement = document.createElement('div');
    titleElement.className = 'toast-title';
    if(type == 'success'){
      titleElement.innerHTML = `<i class="mdi mdi-check-circle-outline toast-success-icon"></i><strong>${title}</strong>`;
    }
    if(type == 'failed'){
      titleElement.innerHTML = `<i class="mdi mdi-alert-circle-outline toast-failed-icon"></i><strong>${title}</strong>`;
    }
    if(type == 'info'){
      titleElement.innerHTML = `<i class="mdi mdi-alert-circle-outline toast-info-icon"></i><strong>${title}</strong>`;
    }
    if(type == 'warning'){
      titleElement.innerHTML = `<i class="mdi mdi-alert-outline toast-warning-icon"></i><strong>${title}</strong>`;
    }    
    titleElement.innerHTML += '<br>';

    // Create message element
    const messageElement = document.createElement('div');
    messageElement.className = 'toast-message';
    messageElement.textContent = message;

    // Create close button
    const closeButton = document.createElement('span');
    closeButton.className = 'toast-close';
    closeButton.innerHTML = '&times;';
    closeButton.addEventListener('click', () => {
      this.removeToast(toast);
    });

    // Append elements to toast
    toast.appendChild(titleElement);
    toast.appendChild(messageElement);
    toast.appendChild(closeButton);

    // Append toast to global toast-container
    const toastContainer = document.getElementById('toast-container');
    if (toastContainer) {
      toastContainer.appendChild(toast);

      // Show toast with animation
      setTimeout(() => {
        toast.classList.add('show');
      }, 100);

      // Remove toast after animation
      setTimeout(() => {
        this.removeToast(toast);
      }, 3000);
    }
  }

  removeToast(toast: HTMLElement) {
    toast.classList.remove('show');
    setTimeout(() => {
      if (toast.parentNode) {
        toast.parentNode.removeChild(toast);
      }
    }, 500);
  }
}
