<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <!-- List -->

            <div class="card">
                <div class="card-body">

                    <h3 class="bs-title">E-Card File Listing
                        <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button>
                    </h3>
                    
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="client_id">Client  <span class="redstar">*</span></label>
                                <ng-select id="client_id" (change)="getPolicy($event)" 
                                            [items]="InfoArrayClient"
                                            bindLabel="name"
                                            bindValue="id"
                                            [placeholder]="'Select Client'">
                                        </ng-select>
                            </div>
                        </div>
            
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_id">Policy <span class="redstar">*</span></label>
                                <select class="form-select" id="policy_id" (change)="getEmployee()">
                                    <option value="">Select Policy</option>
                                    <option *ngFor="let policy of policyArray" [value]="policy.id">{{ policy.policy_name }}</option>
                                </select>          
                            </div>
                        </div>
        
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="employee_id">Employee <span class="redstar">*</span></label>
                                <select class="form-select" id="employee_id" (change)="getInfo()">
                                    <option value="">Select Employee</option>
                                    <option *ngFor="let employee of EmployeeArray" [value]="employee.empid">{{employee.empcode}} - {{employee.first_name}} {{employee.last_name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           

            <div class="table-responsive mt-3">
                <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Policy No.</th>
                            <th>Policy Name</th>
                            <th>TPA ID</th>
                            <th>Reason</th>
                            <th>E- Card</th>
                            <!-- <th>Failed Document</th>                       -->
                            <th>Status</th>
                            <th>Uploaded At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['policy_number']}}</td>
                            <td>{{group['policy_name']}}</td>
                            <td>{{group['tpa_member_id']}}</td>
                            <td>{{group['reason']}}</td>
                            <!-- <td>
                              <p style="color: #0382cc; font-size: 13px;">
                                <i class="mdi mdi-account"></i>
                                Total Uploaded: {{group['total_count']}} 
                                <span *ngIf="group['total_count'] == 1">Row</span>
                                <span *ngIf="group['total_count'] != 1">Rows</span>
                              </p>
                              <p style="color: #32cc03; font-size: 13px;">
                                <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                                Added Successfully: {{group['sucess_count']}} 
                                <span *ngIf="group['sucess_count'] == 1">Row</span>
                                <span *ngIf="group['sucess_count'] != 1">Rows</span>
                              </p>
                              <p style="color: #f00020; font-size: 13px;" *ngIf="group['fail_count'] >= 1">
                                <i class="mdi mdi-close"></i>
                                Failed To Add: {{group['fail_count']}} 
                                <span *ngIf="group['fail_count'] == 1">Row</span>
                                <span *ngIf="group['fail_count'] != 1">Rows</span>
                              </p>                        
                            </td> -->
                            <td>
                              <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['ecard_url'])" style="cursor: pointer" />                        
                            </td>
                            <!-- <td>
                              <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                              <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'])" style="cursor: pointer" *ngIf="group['fail_count'] >= 1" />                        
                            </td>                       -->
                            <td>
                              <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                              <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                              <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                            </td>
                            <td>{{group['uploaded_at']}}</td>          
                        </tr>
                    </tbody>
                </table>
            </div>


            <!-- Deactivate Form -->
            <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Deactivate Master Module</h4>
                      </div>
                      <form [formGroup]="DeactivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Deactivate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                  role="button">Deactivate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div> -->

            <!-- Activate Form -->
            <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Activate Master Module</h4>
                      </div>
                      <form [formGroup]="ActivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Activate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                  role="button">Activate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div> -->

            <!-- Delete -->
            <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Wellness Partner</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->

        </div>
        <app-footer></app-footer>
    </div>
</div>