import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as pbi from 'powerbi-client';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Service } from '../dashboard/service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class ClientDashboard implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  resstatus = localStorage.getItem('resstatus');

  listArr: any = {};
  AnnoucementArray: any = [];
  NewsArray: any = [];

  constructor(private dataservice: Service, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == null) {
          this.route.navigate(['/home']);
      }      
      
    });

  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){      
   
    // this.getList();
    this.AnnoucementList();
    this.NewsList();

  }

  getList(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
    };

    this.dataservice.getList(postdata)
      .subscribe((result: any[]) => {

        // console.log(result.data);
        this.listArr = result;        
        console.log(this.listArr);

      }, (error) => {
          // console.log(result);
    });

  }

  AnnoucementList(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    this.dataservice.AnnoucementList()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.AnnoucementArray = result.data;
                    
        }, (error) => {          
          
          this.AnnoucementArray = [];
          
      });
  }

  NewsList(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    this.dataservice.NewsList()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.NewsArray = result.data;
                    
        }, (error) => {          
          
          this.NewsArray = [];
          
      });
  }

}
