import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { ToastService } from '../../toast.service';
import { event } from 'jquery'
@Component({
  selector: 'app-employer-ecard',
  templateUrl: './employer-ecard.component.html',
  styleUrls: ['./employer-ecard.component.css']
})
export class EmployerEcardComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;
  
  InfoArray: any = [];   
  InfoArrayClient: any = [];   
  policyArray: any = [];   
  EmployeeArray: any = [];   
  MemberArray: any = [];   
  selectedMember: any = [];
  selectedMemberId:any;

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
  }


  initGroup(){
    this.getPolicy();
  }

  goto(url: string){
    window.open(url,'_blank');   
  }


   getPolicy() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": localStorage.getItem('employer_id'),
    };

    this.service.getPolicy(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.policyArray = result.data;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getEmployee(){

    setTimeout(()=>{
      this.InfoArray = [];
      this.MemberArray = [];
      this.selectedMemberId = undefined
    },1000);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": localStorage.getItem('employer_id'),
      "policy_id": $('#policy_id').val(),
    };

    this.service.getEmployee(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.EmployeeArray = result.data;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getMembers(){
    setTimeout(()=>{
      this.InfoArray = [];
      // this.MemberArray = [];
      this.selectedMemberId = undefined
    },1000);
    
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": localStorage.getItem('employer_id'),
      "policy_id": $('#policy_id').val(),
      "empid": $('#employee_id').val(),
    };

    this.service.getMembers(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.MemberArray = result.data;
          if(this.MemberArray.length === 0){
            $('.memberDiv').css('display', 'flex');
          }else{
            $('.memberDiv').css('display', 'none');
          }
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getMemberData(event: any){

    this.selectedMemberId = event.target.value;
    
     var postdata = {
       "tokenable_id": localStorage.getItem('tokenable_id'),
       "broker_id": localStorage.getItem('broker_id'),
       "employer_id":  localStorage.getItem('employer_id'),
       "policy_id": $('#policy_id').val(),
       "empid": $('#employee_id').val(),
       "mid":this.selectedMemberId,
     };
 
     this.service.getMemberId(postdata)
       .subscribe((result) => {
         if (result.status == true) {
           this.InfoArray = result.data;
         }
       }, (error) => {
 
         this.InfoArray = []
         if (error.error.message == 'Unauthenticated.') {
           localStorage.clear();
           localStorage.setItem('resstatus', 'false');
           this.route.navigate(['/home']);
         }
       });
   }

}
