import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AUTH_API = environment.apiURL;
  
  constructor(private http: HttpClient) { }
  
  samllogin(samlhash: string): Observable<any> {
    return this.http.post(this.AUTH_API + 'auth/samllogin', {
      samlhash
    }, httpOptions);
  }  
  
  getuserdetails(postdataObj: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'get-user-details', 
    postdataObj
    , httpOptions);
  }
    
  getuserrights(data: any, token:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'get-user-role-permission',
    data,
    httpOptions);
  }

}