<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
      <div class="content-wrapper pt-3">                          
  
        <!-- Cards Section -->
        <div style="background: #fff; box-shadow: 0px 0px 10px 1px; border-radius: 8px;">
          <div class="row py-3">                            
            
            <!-- Tabs -->
            <div class="col-sm-12 mt-2">
              
              <!-- Tabs Headings -->
              <ul class="nav nav-tabs mytab" id="myTab" role="tablist">
                <li class="nav-item liwidth p-1" role="presentation">
                    <button class="nav-link active w100" id="Group_Cover-tab" data-bs-toggle="tab" data-bs-target="#Group_Cover" type="button"
                        role="tab" aria-controls="home" aria-selected="true">Group Cover</button>
                </li>
                <li class="nav-item liwidth p-1" role="presentation">
                    <button class="nav-link w100" id="Voluntary_Cover-tab" data-bs-toggle="tab" data-bs-target="#Voluntary_Cover" type="button"
                        role="tab" aria-controls="Voluntary_Cover" aria-selected="false">Voluntary Cover</button>
                </li>
                <li class="nav-item liwidth p-1" role="presentation">
                    <button class="nav-link w100" id="Summary-tab" data-bs-toggle="tab" data-bs-target="#Summary" type="button"
                        role="tab" aria-controls="Summary" aria-selected="false">Summary</button>
                </li>                      
              </ul>

              <!-- Tabs Content -->
              <div class="tab-content pb-0" id="myTabContent">
              
                <!-- Tab Group_Cover -->
                <div class="tab-pane fade show active" id="Group_Cover" role="tabpanel" aria-labelledby="Group_Cover-tab">
                    
                  <div class="row" style="margin: 0px;">

                    <!-- Card -->                    
                    <div class="col-lg-6 col-sm-12 col-md-6 mb-4" *ngFor="let data of InfoArray; let i = index;">
                                            
                      <div>
                        <label class="greendiv">
                          <label class="bs-title text-white greenlabel">{{data.label}}</label>
                        </label>
                      </div>

                      <div class="cardbg cardbg p-2 pb-0">                                                    

                          <div class="carddiv">
                              <div class="row p-3">
                                  <div class="col-sm-3">
                                      <img class="cardimg" src="../../../assets/img/employee/{{data.image_url}}" alt="Icon" />
                                  </div>
                                  <div class="col-sm-9 px-5 py-3">
                                      <h2 class="bs-title bstitle">{{data.title}}</h2>
                                      <h2 class="bs-title subtitle"><b>{{data.tag}}</b></h2>
                                      <h4 class="bs-title desc">{{data.description}}</h4>    
                                      <h4 class="bs-title price">
                                        <i class="mdi mdi-currency-inr menu-icon" style="position: relative; top: 3px;"></i>
                                        {{data.price}}
                                      </h4>
                                  </div>
                              </div>    
                              
                              <div class="linkdiv">
                                  <h4 class="bs-title text-center pt-1 linktext"><b>{{data.linktext}}</b></h4>
                              </div>
                          </div>                                           
                      </div>
                    </div>

                  </div>                    
                    
                </div>
              
                <!-- Tab Voluntary_Cover -->
                <div class="tab-pane fade" id="Voluntary_Cover" role="tabpanel" aria-labelledby="Voluntary_Cover-tab">
                  <label class="bs-form-label"><h4>Comming Soon</h4></label>
                </div>
              
                <!-- Tab Summary -->
                <div class="tab-pane fade" id="Summary" role="tabpanel" aria-labelledby="Summary-tab">
                  <label class="bs-form-label"><h4>Comming Soon</h4></label>
                </div>
              
              </div>
              
            </div>
            <!-- End -->
  
          </div>      
        </div>
        <!-- End -->
        
      </div>
      <app-footer></app-footer>
    </div>
  
  </div>