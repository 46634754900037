<div class="back">
    <div class="div-center">
      <div class="content row m-0">
  
        <div class="col-sm-7 p-0">
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../assets/img/login/bg-image-1.png" class="d-block img-fluid">
                  <div class="carousel-caption d-md-block">
                    <h5>Heading 1</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src="../../../assets/img/login/bg-image-2.png" class="d-block img-fluid">
                  <div class="carousel-caption d-md-block">
                    <h5>Heading 2</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
                <!-- <div class="carousel-item">
                  <img src="../../../assets/img/login/loginslideimage1_400x300.jpeg" class="d-block img-fluid">
                  <div class="carousel-caption d-none d-md-block">
                    <h5>Heading 1</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div> -->
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
      </div>
      <div class="col-sm-5 p-4 pl-0">
          <div align="center">
              <a routerLink="/home"><img class="" src="../../../assets/logo.png" style="width: 40px; cursor: pointer;" class="img-fluid" alt="logo" /></a>
          </div>
          <div class="mt-3">
              <h4 class="text-center">Email Verification</h4>
              <!-- <label class=""> Lorem ipsum dolor sit amet consectetur</label> -->
              <form [formGroup]="registerForm" class="mt-3">                   
                  <div class="mb-2">
                      <label for="mobile" class="form-label">Email</label>
                      <input type="text" class="form-control" id="username" formControlName="username" placeholder="Enter email" >
                      <small style="font-size: 12px;">OTP will be sent to above email</small>
                  </div>
                  <div>
                    <a style="text-decoration: none;">
                      <button type="button" id="generate_OTP" (click)="generate_OTP()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                        Generate OTP
                      </button>
                    </a>
                  </div>

                  <!------- Resend OTP ------>
                  <div class="form-label mt-2" id="resendbtn" style="text-align: right; display: none"><span id="timer">{{display }}</span> <button id="resendlink" disabled (click)="resendotp()"> <u>Resend OTP</u> </button></div>
                  <!------------------------->

                  <div id="verifyOTP" style="display: none">
                    <hr class="mt-4">
                    <div class="mb-2">
                      <label for="otp" class="form-label">Verify OTP</label>
                      <input type="text" class="form-control" id="otp" formControlName="otp" placeholder="Enter OTP" (keypress)="isNumber($event)" />
                    </div>                
                    <div>
                      <a style="text-decoration: none;">
                        <button type="submit" id="verify_OTP" (click)="verify_OTP()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                          Verify OTP
                        </button>
                      </a>
                    </div>                  
                  </div>
              </form>    
              <div class="mt-3">
                <button type="button" (click)="goback()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                  Back to Sign Up
                </button>
              </div>                                    
              <div id="validmsg" class="red">{{ validmsg }}</div>
          </div>
      </div>
      
      </div>    
    </div>
  </div>