import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SurveyAuthService {

  AUTH_API = environment.apiURL;
  
  constructor(private http: HttpClient) { }
  
  getuserdetails(postdataObj: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'get-user-details', 
    postdataObj
    , httpOptions);
  }

  register(username: string, pwd: string, user_type_id_fk:any, plan_id_fk:any): Observable<any> {
    return this.http.post(this.AUTH_API + 'auth/register', {
      username,
      pwd,
      user_type_id_fk,
      plan_id_fk
    }, httpOptions);
  }
}