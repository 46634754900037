import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientCreationService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  onSubmitFirst(data: any, token: any): Observable<any> {
    var URL;
    if(data.master_employer_id == 0){
      URL = 'masteremployer/step1-store';
    }else{
      URL = 'masteremployer/step1-update/' + data.master_employer_id;
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + URL,
      data
    , httpOptions);  
  }

  onSubmitSecond(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step2-update/' + data.master_employer_id,
      data
    , httpOptions);
  }

  onSubmitThird(data: any, token: any): Observable<any> {

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);        
    filedata.append("master_employer_id",data.master_employer_id);
    filedata.append("broker_id",data.broker_id);        

    filedata.append("turnover",data.turnover);
    filedata.append("listed",data.listed);
    filedata.append("type_of_firm",data.type_of_firm);
    filedata.append("company_registration_no",data.company_registration_no);
    filedata.append("GST",data.GST);
    filedata.append("PAN",data.PAN);
    filedata.append("TAN",data.TAN);

    if(data.branch_info == true){
      // console.log(data.branch_info_arr);
      filedata.append("branch_info",data.branch_info);
      filedata.append("branch_info_arr",data.branch_info_arr);
    }
    
    filedata.append("company_logo",data.image_data);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step3-update/' + data.master_employer_id,
      filedata
    , httpOptions);
  }

  onSubmitFourth(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step4-update/' + data.master_employer_id,
      data
    , httpOptions);
  }

  onSubmitFivth(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step5-update/' + data.master_employer_id,
      data
    , httpOptions);
  }

  clientCreate(data: any, token: any): Observable<any> {


    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("company_name",data.company_name);
    filedata.append("employee_type",data.employee_type);

    filedata.append("group_company_name",data.group_company_name);
    filedata.append("industry",data.industry);
    filedata.append("head_quarter_country",data.head_quarter_country);
    filedata.append("employee_headcount",data.employee_headcount);
    filedata.append("MNC",data.MNC);

    filedata.append("company_year_of_inception",data.company_year_of_inception);
    filedata.append("contact_person",data.contact_person);
    filedata.append("email_address",data.email_address);

    filedata.append("contact_number",data.contact_number);
    filedata.append("landline",data.landline);
    filedata.append("registered_address",data.registered_address);
    filedata.append("country",data.country);
    filedata.append("state",data.state);

    filedata.append("city",data.city);
    filedata.append("zipcode",data.zipcode);
    filedata.append("communication_address",data.communication_address);
    filedata.append("c_address",data.c_address);
    filedata.append("c_country",data.c_country);

    filedata.append("c_state",data.c_state);
    filedata.append("c_city",data.c_city);
    filedata.append("c_zipcode",data.c_zipcode);
    filedata.append("turnover",data.turnover);
    filedata.append("listed",data.listed);
    filedata.append("type_of_firm",data.type_of_firm);
    filedata.append("company_registration_no",data.company_registration_no);
    filedata.append("GST",data.GST);
    filedata.append("PAN",data.PAN);
    filedata.append("TAN",data.TAN);

    filedata.append("theme_id",data.theme_id);
    
    if(data.hr_info == true){
      console.log(data.hr_info_arr);
      filedata.append("hr_info",data.hr_info);
      filedata.append("hr_info_arr",data.hr_info_arr);
    }
    
    filedata.append("roles",data.roles);
    filedata.append("company_logo",data.image_data);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/store', 
      filedata
    , httpOptions);  
  }
  
  getcountry(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/citydetails',
      data
    , httpOptions);
  }

  getIndustryType(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'industry-detailsfront', 
      data
    , httpOptions);
  }

  AddInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/store', 
      data
    , httpOptions);
  }  

  InfoDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/destroy/' + data.id,
      data
    , httpOptions);
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/show/' + data.id, 
      data
    , httpOptions);
  }

  InfoUpdate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/update/' + data.id,
      data
    , httpOptions);
  }

  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer-list', 
      data,
      httpOptions);
  }

  getThemelist(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer-themeslist', 
      data,
      httpOptions);
  }

  getCountry(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/countrylist', 
      data,
      httpOptions);
  }

  getInfoState(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/statelistbycountry', 
      data,
      httpOptions);
  }

  getInfoCity(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/citylistbystate', 
      data,
      httpOptions);
  }

  getInfoIndustry(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'industry-info', 
      httpOptions);
  }

  getemployeetype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer-groupcompanylist', 
      data,
      httpOptions);
  }

  getmoduleInfo(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'adminusertypewisemodules-accesslist', 
      data,
      httpOptions);
  }

  InfoActiveDeactivate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/activedeactive/' + data.id,
      data
    , httpOptions);
  }

  emailInfo(data: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step2-checkemailcontact/' + data.master_employer_id,
      data
    , httpOptions);

  }

  ContactInfo(data: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step2-checkemailcontact/' + data.master_employer_id,
      data
    , httpOptions);

  }

  emailHrInfo(data: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/step3-checkemail/' + data.master_employer_id,
      data
    , httpOptions);

  }
}