import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-manage-document-type',
  templateUrl: './manage-document-type.component.html',
  styleUrls: ['./manage-document-type.component.css']
})
export class ManageDocumentTypeComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  activeDeactiveId: any;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_article_title = localStorage.getItem('type_article_title');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];    
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_article_title');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();

    // Form element defined below
    this.AddInfo = this.fb.group({
      doc_type: new FormControl('', Validators.required),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      doc_type_edit: new FormControl('', Validators.required),
      edit_id: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  /***************** ADD ********************/

  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;
  } 
  
  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  } 

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;  
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "doc_type":this.AddInfo.controls['doc_type'].value,
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {

          if (result.status == true) {  
            
            this.show = false;
            this.toastService.showSuccess('Success', 'Details Added Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
          }else {            
            this.show = false;         
          }
        }, (error) => {          

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
          this.toastService.showFailed('Failed', 'There was some error !');               
      
      });
      
    } else {
      // validate all form fields
    }
  }

  /***************** EDIT ********************/

  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        this.EditInfo.controls['doc_type_edit'].setValue(result.data[0]['document_type']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "doc_type":this.EditInfo.controls['doc_type_edit'].value,
        "broker_id":localStorage.getItem('broker_id'),
      };

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            
            this.closePopup();         
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.toastService.showFailed('Failed', 'There was some error !');                   
      });

    }

  }

  /***************** DEACTIVE ********************/

  openPopupDeactivate(id: any) {
    this.displayStyleDeactivate = "block";  
    this.activeDeactiveId = id;
  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": this.activeDeactiveId,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');        
   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');                      
    });

  }

  /***************** ACTIVE ********************/

  openPopupActivate(id: any) {
    this.displayStyleActivate = "block";  
    this.activeDeactiveId = id;
  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": this.activeDeactiveId,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');        
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
        }
      }, (error) => {  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');                 
    });

  }

  /***************** Common Functions ********************/
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    };

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];       
      });
  }

  closePopup() {    
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
