import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmployeeDependencyService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  employeeImport(data: any): Observable<any> {

    // console.log(data);
    var fileData = new FormData();
    fileData.append("filename",data.filename);
    fileData.append("tokenable_id",data.tokenable_id);
    fileData.append("active_member_data_report_date",data.active_member_data_report_date);
    fileData.append("client_id",data.client_id);
    fileData.append("product_id",data.product_id);
    fileData.append("client_product_id",data.client_product_id);
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee/employeeimportbyclient',
    fileData
    , httpOptions);
  }

  getClientInfo(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'user-crud-service-user-clientlist', 
      httpOptions);
  }
  
  getProducts(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'product-info',
    httpOptions);
  }

  getInfo(data: any): Observable<any> {

    if(data.user_role == "client"){

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
      };
      return this.http.post(this.AUTH_API + 'employeelistclient',
      data,
      httpOptions);

    }else{

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
      };
      return this.http.post(this.AUTH_API + 'employeelisting-info',
      data,
      httpOptions);

    }

    
  }

  InfoView(data: any): Observable<any> {

    if(data.user_role == "client"){

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
      };
      return this.http.post(this.AUTH_API + 'employeedependencylistclient/', 
        data
      , httpOptions);

    }else{

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
      };
      return this.http.post(this.AUTH_API + 'employeedependencylisting-info/', 
        data
      , httpOptions);

    }

    
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee/show/' + data.id, 
      data
    , httpOptions);
  }

  InfoUpdate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee/update/' + data.id,
      data
    , httpOptions);
  }

  InfoSingle_dep(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee-dependence/show/' + data.id, 
      data
    , httpOptions);
  }

  InfoUpdate_dep(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee-dependence/update/' + data.id,
      data
    , httpOptions);
  }

  policylistbyproduct(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee/policylistbyproduct',
      data
    , httpOptions);
  }

}
