<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
  

          <h3 class="bs-title"  *ngIf="addflag == 0">Overall Claims</h3>
          <!-- <h3 class="bs-title"  *ngIf="addflag == 1">WellnessBenefit Edit
            <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
          </h3> -->
          
          <hr class="mb-2 mt-4" style="height: 0px">
  
          <!-- Add -->
          <div class="card">
            <div class="card-body">
              <form [formGroup]="basicInfo" *ngIf="addflag == 0">
                <div class="row">
          
                  <div class="col-sm-3" *ngIf="type_name == 'brokeradmin'">
                    <div class="form-group">
                      <label class="bs-form-label" for="client_id">Client <span class="redstar">*</span></label>
                      <ng-select formControlName="client_id" id="client_id" (change)="getPolicyType()"  (change)="getpolicynumbers()" 
                        [items]="InfoArrayCorporate"
                        bindLabel="name"
                        bindValue="id"
                        [placeholder]="'Select Client'"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.client_id.errors}">
                      </ng-select>
                      <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors">
                        <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors.required">
                          Client is required
                        </p>
                      </ng-container>
                    </div>
                  </div>
          
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
                      <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getpolicynumbers()"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors}" required>
                        <option value="">Select Policy Type</option>
                        <option *ngFor="let policyType of policy_type; let i = index" value="{{policyType.sub_type_id}}">
                          {{policyType.name}}</option>
                      </select>
                      <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors">
                        <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors.required">
                          Policy Type is required
                        </p>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="bs-form-label" for="policy_claim_status">Policy Claim Status <span class="redstar">*</span></label>
                      <select class="form-select" formControlName="policy_claim_status" id="policy_claim_status"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_claim_status.errors}" required>
                        <option value="0">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <!-- <option *ngFor="let corporatelist of InfoArrayAPI; let i = index" value="{{corporatelist.id}}">
                          {{corporatelist.api_name}}</option> -->
                      </select>
                      <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_claim_status.errors">
                        <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_claim_status.errors.required">
                            Policy Claim Status is required
                        </p>
                      </ng-container>
                    </div>
                  </div>
          
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="bs-form-label" for="policy_name">Policy Name <span class="redstar">*</span></label>
                      <select class="form-select" formControlName="policy_name" id="policy_name" (change)="getPolicyDate()"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors}" required>
                        <option value="">Select Policy Name</option>
                        <option *ngFor="let corporatelist of policynumbers; let i = index" value="{{corporatelist.id}}">
                          {{corporatelist.policy_number}}</option>
                      </select>
                      <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors">
                        <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors.required">
                          Policy Name is required
                        </p>
                      </ng-container>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-3 mt-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="start_date">Start Date <span class="redstar">*</span></label> 
                          <input type="date" class="form-control" formControlName="start_date" id="start_date" 
                          [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.start_date.errors}" required />
                      </div>
                      <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.start_date.errors">
                          <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.start_date.errors.required">
                           Start Date is required
                          </p>
                        </ng-container>
                  </div>
  
                  <div class="col-sm-3 mt-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="end_date">End Date <span class="redstar">*</span></label> 
                          <input type="date" class="form-control" formControlName="end_date" id="end_date" 
                          [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.end_date.errors}" required />
                      </div>
                      <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.end_date.errors">
                          <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.end_date.errors.required">
                           End Date is required
                          </p>
                        </ng-container>
                  </div>
                    
                  </div>
               

          
          
                  <div class="col-sm-12 mt-2" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="getInfo()" role="button">Submit</button>
                  </div>
                  
                </div>
              </form>
            </div>
          </div>
  
          <hr  *ngIf="addflag == 0">
          <!-- <h5  class="bs-title"> TPA Trigger API - List </h5>  -->
          <!-- List -->
          <div class="table-responsive mt-2" *ngIf="addflag == 0">
            <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width:5%; position: sticky; left: 0; background-color: #efefef; z-index: 10;">SrNo</th>
                        <th style="position: sticky; left: 5%; background-color: #efefef; z-index: 10;">Policy No.</th>
                        <th>Policy Name</th>
                        <th>Source</th>
                        <th>TPA </th> 
                        <th>Registerd On</th>                     
                        <th>Created At</th>                     
                        <th>Updated At</th>
                        <th>Claim tagging</th>
                        <th>Claim Status</th>
                        <th>Claim Sub Status</th>
                        <th>TAT</th>
                        <th>Track Claim</th>
                        <th>Claim View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%; position: sticky; left: 0; background-color: #fff; z-index: 10;">{{i+1}}</td>
                        <td style="position: sticky; left: 5%; background-color: #fff; z-index: 10;">{{group['policy_number']}}</td>
                        <td>{{group['policy_name']}}</td>
                        <td>-</td>
                        <td>{{group['tpaname']}}</td>
                        <td>-</td>  
                        <td>-</td>  
                        <td>-</td>  
                        <td>-</td>  
                        <td>-</td>  
                        <td>-</td>  
                        <td>-</td>  
                        <td>-</td>  
                        <td>
                            <img src="../../../assets/img/common icons/view.png" title="View" (click)="viewClaims(group)" style="cursor: pointer" />                        
                        </td>        
                    </tr>
                </tbody>
            </table>
        </div>

          <!-- Edit -->
          <!-- <form [formGroup]="EditInfo" *ngIf="addflag == 1">
            <div class="row">
              <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="wellness_partner_edit">Wellness Partner <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="wellness_partner_edit" id="wellness_partner_edit" [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.wellness_partner.errors}"
                        required>
                            <option value="">Select Wellness Partner</option>
                            <option *ngFor="let wellness of InfoArrayWellness; let i = index" value="{{wellness.id}}">
                                {{wellness.wellness_name}}</option>
                    </select>
                    <ng-container
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.wellness_partner_edit.errors">
                    <p class="error"
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.wellness_partner_edit.errors.required">
                    Wellness Partner is required
                    </p>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_name_edit">Benefit Name <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_name_edit" id="benefit_name_edit" placeholder="Benefit Name"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors}"
                        required />
                    <ng-container
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors">
                    <p class="error"
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors.required">
                    Benefit Name is required
                    </p>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_url">URL <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_url" id="benefit_url" placeholder="URL"
                        [ngClass]="{'control-red': basicInfoSubmitted && getEditInformationFormControls.benefit_url.errors}"
                        required />
                    <ng-container *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors">
                        <p class="error"
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors.required">
                          URL is required
                        </p>
                    </ng-container>
                </div>
              </div>
            
              <div class="col-sm-12 mt-3">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_description_edit">Benefit Description</label>
                    <textarea type="text" class="form-control" formControlName="benefit_description_edit" id="benefit_description_edit" placeholder="Benefit Description" style="height: 70px;"></textarea>
                </div>
              </div>

                            
              <div class="col-sm-12" align="center">
                  <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>
              </div>
            </div>
          </form> -->
  
            <!-- Deactivate Form -->
            <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Deactivate Wellness Benefit</h4>
                      </div>
                      <form [formGroup]="DeactivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Deactivate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                  role="button">Deactivate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div> -->

          <!-- Activate Form -->
          <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Activate Wellness Benefit</h4>
                      </div>
                      <form [formGroup]="ActivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Activate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                  role="button">Activate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div> -->

            <!-- Delete -->
            <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Wellness Partner</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->

        </div>
        <app-footer></app-footer>
    </div>
</div>
  