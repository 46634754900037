import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';
import { numberFormat } from 'highcharts';
@Component({
  selector: 'app-flex-configurator-dashboard',
  templateUrl: './flex-configurator-dashboard.component.html',
  styleUrls: ['./flex-configurator-dashboard.component.css']
})
export class FlexConfiguratorDashboardComponent implements OnInit {
  [x: string]: any;

  show = false;
  fullScreen = true;
  template = ``;

  rightModal: any = "none";
  Coverages: any = "none";
  OPDBenefits: any = "none";

  addFlag: any = 0;

  CoveragesArray: any = [
    { title: "OPD Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_1.png" },
    { title: "Maternity Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_2.png" },
    { title: "Rewards & Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_3.png" },
    { title: "OPD Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_1.png" },
    { title: "Maternity Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_2.png" }
  ]

  OPDarray: any = [
    { col_1: '24x7 online GP consult', col_2: 'Unlimited', col_3: 'Unlimited', col_4: 'Unlimited', col_5: 'Unlimited' },
    { col_1: 'Specialist Consults (Virtual)', col_2: '2', col_3: '3', col_4: '5', col_5: '3' },
    { col_1: 'Vision Cover ', col_2: 'Vision Cover ', col_3: 'Cover - Upto INR 5000 per Family Premium - INR 9754 Inclusion- INR 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures', col_4: 'Cover - Upto INR 5000 per Family Premium - INR 9754 Inclusion- INR 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures', col_5: 'Cover - Upto INR 5000 per Family Premium - INR 9754 Inclusion- INR 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures' },
    { col_1: 'Dental Cover', col_2: '0', col_3: 'Cover - Upto INR 5000 per Family Premium-  INR 9754 Inclusion- INR 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.', col_4: 'Cover - Upto INR 5000 per Family Premium-  INR 9754 Inclusion- INR 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.', col_5: 'Cover - Upto INR 5000 per Family Premium-  INR 9754 Inclusion- INR 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.' },
    { col_1: 'Health Check-up including Preventive', col_2: 'Upto INR 5,000 per Family', col_3: 'Upto INR 5,000 per Family', col_4: 'Upto INR 5,000 per Family', col_5: 'Upto INR 5,000 per Family' },
    { col_1: 'Children Vaccination Cover', col_2: '0', col_3: 'Upto INR 2000 per Family', col_4: '0', col_5: '0' },
    { col_1: 'Diagnostic Cover', col_2: 'Upto INR 2,000 per Family', col_3: 'Upto INR 2,000 per Family', col_4: 'Upto INR 2,000 per Family', col_5: 'Upto INR 2,000 per Family' },
    { col_1: 'In-Person Consultation', col_2: '3', col_3: '3', col_4: '3', col_5: '3' },
    { col_1: 'Maternity care', col_2: 'Nutrition counselling & Health content', col_3: 'Gynaecologist tele consult, Nutrition counselling & Health content', col_4: 'Gynaecologist tele consult, Nutrition counselling & Health content', col_5: 'Gynaecologist tele consult, Nutrition counselling & Health content' },
    { col_1: 'Inclinic Consultation', col_2: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_3: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_4: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_5: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations' },
    { col_1: '0', col_2: 'Add to Compare', col_3: 'Add to Compare', col_4: 'Add to Compare', col_5: 'Add to Compare' },
    { col_1: '0', col_2: 'Selected', col_3: 'Select', col_4: 'Select', col_5: 'Select' },
    { col_1: '0', col_2: 'Watch Plan Video', col_3: 'Watch Plan Video', col_4: 'Watch Plan Video', col_5: 'Watch Plan Video' }
  ]

  gmc_relation: any = [];
  gmc_obj: any = {
    "name": "Group Mediclaim Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  gpa_relation: any = [];
  gpa_obj: any = {
    "name": "Voluntary Additional Parental Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  gtl_relation: any = [];
  gtl_obj: any = {
    "name": "Voluntary Additional Parental Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  v_topup_obj: any = {
    "name": "Voluntary Topup Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  v_addon_obj: any = {
    "name": "Add On Benefits Base Policy",
    "relation": "OPD Benefits (Floater)",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  ancillaryData: any = [];
  partner_id: any;

  gmcbasearray: any = [];
  gmcadditionalarray: any = [];
  gpabasearray: any = [];
  gpaadditionalarray: any = [];
  gtlbasearray: any = [];
  gtladditionalarray: any = [];

  /** VOLUNTARY PLANS **/
  gmcvoluntaryplans: any = [];
  gpavoluntaryplans: any = [];
  gtlvoluntaryplans: any = [];

  gmc_you_pay: any = 0
  gmc_employer_pay: any = 0;
  gmc_total_youpay_premium: any = 0;
  gmc_total_employer_pay_premium: any = 0;

  gpa_you_pay: any = 0
  gpa_employer_pay: any = 0;
  gpa_total_youpay_premium: any = 0;
  gpa_total_employer_pay_premium: any = 0;

  gtl_you_pay: any = 0
  gtl_employer_pay: any = 0;
  gtl_total_youpay_premium: any = 0;
  gtl_total_employer_pay_premium: any = 0;

  showDashboard: any;

  gmc_flex_plan:any = [];
  gmcFlexPlan:any = [];

  gpa_flex_plan:any = [];
  gpaFlexPlan:any = [];

  gtl_flex_plan:any = [];
  gtlFlexPlan:any = [];

  gmc_disclaimer:any;
  gpa_disclaimer:any;
  gtl_disclaimer:any;

  gmc_feature_arr:any = [];

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) { }

  ngOnInit(): void {
    this.gmc_relation = this.gmc_obj.relation.split(',');
    this.gpa_relation = this.gpa_obj.relation.split(',');
    this.gtl_relation = this.gtl_obj.relation.split(',');
    this.fetchtotalcostsummary();
    this.fetchAncillaryPlan();
    this.getemployeepolicies();

    this.show = true;

    setTimeout(() => {
      if (this.gmcbasearray.length > 0) {
        $('#Add-tab').addClass('active');
        $('#Add').addClass('active show');
      } else if (this.gpabasearray.length > 0) {
        $('#Remove-tab').addClass('active');
        $('#Remove').addClass('active show');
      } else if (this.gtlbasearray.length > 0) {
        $('#Corrections-tab').addClass('active');
        $('#Corrections').addClass('active show');
      }

      this.show = false;

    }, 2000);
      
  }
 
  fetchtotalcostsummary() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')
    };

    this.dataservice.getInfo(postdata)
      .subscribe((result) => {
        if (result.status == true) {

          this.gmc_disclaimer = result.data.disclaimer.gmc_disclaimer;
          this.gpa_disclaimer = result.data.disclaimer.gpa_disclaimer;
          this.gtl_disclaimer = result.data.disclaimer.gtl_disclaimer;

          this.gmcbasearray = result.data.base_plans.gmc;
          this.gmcadditionalarray = result.data.additional_featurs.gmc;

          this.gpabasearray = result.data.base_plans.gpa;
          this.gpaadditionalarray = result.data.additional_featurs.gpa;

          this.gtlbasearray = result.data.base_plans.gtl;
          this.gtladditionalarray = result.data.additional_featurs.gtl;

          this.gmcvoluntaryplans = result.data.voluntary_plans.gmc;
          this.gpavoluntaryplans = result.data.voluntary_plans.gpa;
          this.gtlvoluntaryplans = result.data.voluntary_plans.gtl;

          this.gmc_total_youpay_premium = result.data.gmc_total_youpay_premium;
          this.gmc_total_employer_pay_premium = result.data.gmc_total_employer_pay_premium;
          this.gmc_you_pay = numberFormat(parseInt(result.data.gmc_total_youpay_premium) / (parseFloat(result.data.gmc_total_employer_pay_premium) + parseFloat(result.data.gmc_total_youpay_premium)) * 100, 0);
          this.gmc_employer_pay = numberFormat(parseInt(result.data.gmc_total_employer_pay_premium) / (parseFloat(result.data.gmc_total_employer_pay_premium) + parseFloat(result.data.gmc_total_youpay_premium)) * 100, 0);

          this.gpa_total_youpay_premium = result.data.gpa_total_youpay_premium;
          this.gpa_total_employer_pay_premium = result.data.gpa_total_employer_pay_premium;

          this.gpa_you_pay = numberFormat((parseInt(result.data.gpa_total_youpay_premium) / (parseFloat(result.data.gpa_total_employer_pay_premium) + parseFloat(result.data.gpa_total_youpay_premium))) * 100, 0);
          this.gpa_employer_pay = numberFormat(parseInt(result.data.gpa_total_employer_pay_premium) / (parseFloat(result.data.gpa_total_employer_pay_premium) + parseFloat(result.data.gpa_total_youpay_premium)) * 100, 0);

          this.gtl_total_youpay_premium = result.data.gtl_total_youpay_premium;
          this.gtl_total_employer_pay_premium = result.data.gtl_total_employer_pay_premium;
          this.gtl_you_pay = numberFormat(parseInt(result.data.gtl_total_youpay_premium) / (parseFloat(result.data.gtl_total_employer_pay_premium) + parseFloat(result.data.gtl_total_youpay_premium)) * 100, 0);
          this.gtl_employer_pay = numberFormat(parseInt(result.data.gtl_total_employer_pay_premium) / (parseFloat(result.data.gtl_total_employer_pay_premium) + parseFloat(result.data.gtl_total_youpay_premium)) * 100, 0);

          this.showDashboard = result.data.show_dashboard;
          localStorage.setItem('show_dashboard', this.showDashboard);

          if(this.showDashboard === 1){
            this.route.navigate(['/flex-plan']);
          }else{
            this.route.navigate(['/flex-configurator-dashboard']);
          }
          
          var combinedArray_gmc = [].concat.apply([], result.data.flex_basic_data.gmc);
          for (let i = 0; i < combinedArray_gmc.length; i += 3) {
            this.gmc_flex_plan.push(combinedArray_gmc.slice(i, i + 3));
          }

          var combinedArray_gpa = [].concat.apply([], result.data.flex_basic_data.gpa);
          for (let i = 0; i < combinedArray_gpa.length; i += 3) {
            this.gpaFlexPlan.push(combinedArray_gpa.slice(i, i + 3));
          }

          var combinedArray_gtl = [].concat.apply([], result.data.flex_basic_data.gtl);
          for (let i = 0; i < combinedArray_gtl.length; i += 3) {
            this.gtlFlexPlan.push(combinedArray_gtl.slice(i, i + 3));
          }

          this.gmcFlexPlan = result.data.flex_basic_data.gmc;

          this.gpa_flex_plan = result.data.flex_basic_data.gpa;

          this.gtl_flex_plan = result.data.flex_basic_data.gtl;


          // var combinedArray_gpa = [].concat.apply([], result.data.flex_data.gpa);
          // for (let i = 0; i < combinedArray_gpa.length; i += 3) {
          //   this.gpa_flex_plan.push(combinedArray_gpa.slice(i, i + 3));
          // }

          // var combinedArray_gtl = [].concat.apply([], result.data.flex_data.gtl);
          // for (let i = 0; i < combinedArray_gtl.length; i += 3) {
          //   this.gtl_flex_plan.push(combinedArray_gtl.slice(i, i + 3));
          // }         

        }
      }, (error) => {

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  fetchAncillaryPlan(){

    this.addFlag = 1;
    localStorage.setItem('addFlag', this.addFlag);
    
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')
    };

    this.dataservice.getInfoAncillary(postdata)
      .subscribe((result) => {                  
        if (result.status == true) {
          // this.ancillaryData = result.data;

          const combinedArray = [].concat.apply([], result.data);
          this.ancillaryData = []; // Initialize ancillaryData as an empty array

          // Chunk combinedArray into arrays of three items each
          for (let i = 0; i < combinedArray.length; i += 5) {
            this.ancillaryData.push(combinedArray.slice(i, i + 5));
          }
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  openmodal() {
    this.rightModal = "block";
  }

  openCoverages() {
    this.Coverages = "block";
  }

  openOPDBenefits() {
    this.OPDBenefits = "block";
  }

  closealert() {
    this.rightModal = "none";
    this.Coverages = "none";
    this.OPDBenefits = "none";
  }

  hideAndExpand_gmc(e: any) {

    var displayValue = $('#colSm5_gmc').css('display');
    if (displayValue === 'block') {
      $('#colSm5_gmc').slideUp('slow', function () {
        $('#colSm7_gmc').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_gmc').css('display', 'none');
        $('#rightbtn_gmc').removeClass('rightbtn_gmc').addClass('fliprightbtn_gmc');
        $('.wrarrow_gmc').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_gmc').slideUp('slow', function () {
        $('#colSm7_gmc').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_gmc').css('display', 'block');
        $('#rightbtn_gmc').removeClass('fliprightbtn_gmc').addClass('rightbtn_gmc');
        $('.wrarrow_gmc').css('transform', 'rotateY(0deg)');
      });
    }

    e.stopPropagation();
    console.log("Button 1 clicked");

  }

  hideAndExpand_gpa() {

    var displayValue = $('#colSm5_gpa').css('display');
    if (displayValue === 'block') {
      $('#colSm5_gpa').slideUp('slow', function () {
        $('#colSm7_gpa').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_gpa').css('display', 'none');
        $('#rightbtn_gpa').removeClass('rightbtn_gpa').addClass('fliprightbtn_gpa');
        $('.wrarrow_gpa').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_gpa').slideUp('slow', function () {
        $('#colSm7_gpa').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_gpa').css('display', 'block');
        $('#rightbtn_gpa').removeClass('fliprightbtn_gpa').addClass('rightbtn_gpa');
        $('.wrarrow_gpa').css('transform', 'rotateY(0deg)');
      });
    }

  }

  hideAndExpand_gtl() {

    var displayValue = $('#colSm5_gtl').css('display');
    if (displayValue === 'block') {
      $('#colSm5_gtl').slideUp('slow', function () {
        $('#colSm7_gtl').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_gtl').css('display', 'none');
        $('#rightbtn_gtl').removeClass('rightbtn_gtl').addClass('fliprightbtn_gtl');
        $('.wrarrow_gtl').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_gtl').slideUp('slow', function () {
        $('#colSm7_gtl').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_gtl').css('display', 'block');
        $('#rightbtn_gtl').removeClass('fliprightbtn_gtl').addClass('rightbtn_gtl');
        $('.wrarrow_gtl').css('transform', 'rotateY(0deg)');
      });
    }

  }

  getemployeepolicies(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getemployeepolicies(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {                  

        for(var i = 0; i<result.data.dashboard_details.length; i++){
          if(result.data.dashboard_details[i]['name'] == 'Group Mediclaim'){
            // console.log(result.data.dashboard_details[i]['policy_features']);
            this.gmc_feature_arr = result.data.dashboard_details[i]['policy_features'];  
          }         
        }  

        localStorage.setItem('enrollment_handbook',result.data.enrollment_handbook);
        
        // console.log(this.gmc_feature_arr);

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  gotopage(id: any){

    this.route.navigate(['/insurance']);
    this.partner_id = id;

    localStorage.setItem('partner_id', this.partner_id);
  }

}
