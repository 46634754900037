import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  username: any;
  restatus: any;
  user_role: any;
  user_company: any;
  current_page_url: any;
  pagehide_status: any;
  plan_name: any;
  
  isMobile: any;
  avtivenav:any;
  surveyLoginFlag: any;

  broker_logo:any;
  client_logo:any;

  clientname:any;
  brokername:any;

  user_type_id = localStorage.getItem('user_type_id');
  name:any;

  imageURL = environment.imageURL;

  constructor(private route: Router, private fb: FormBuilder) {    
    
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {

        this.name = localStorage.getItem('name');

        // console.log(this.name);

        /******************** Dynamic Theme Color settings *****************/
        // primary
        const globalprimary = document.body;
        globalprimary.style.setProperty('--bs-global-primary', (localStorage.getItem('--bs-global-primary')));

        //sidebar
        const sidebar = document.body;
        sidebar.style.setProperty('--bs-sidebar', (localStorage.getItem('--bs-sidebar')));
        sidebar.style.setProperty('--bs-sidebar-text-color', (localStorage.getItem('--bs-sidebar-text-color')));

        //navbar
        const navbarbg = document.body;
        navbarbg.style.setProperty('--bs-navbar_bg', (localStorage.getItem('--bs-navbar_bg')));

        const navbarbgcolor = document.body;
        navbarbgcolor.style.setProperty('--bs-navbar_color', (localStorage.getItem('--bs-navbar_color')));

        //button
        const btnbg = document.body;
        btnbg.style.setProperty('--bs-btn-bg', (localStorage.getItem('--bs-btn-bg')));

        const btnborder = document.body;
        btnborder.style.setProperty('--bs-btn-border', (localStorage.getItem('--bs-btn-border')));

        const btncolor = document.body;
        btncolor.style.setProperty('--bs-btn-color', (localStorage.getItem('--bs-btn-color')));

        //Table
        const tablebg = document.body;
        tablebg.style.setProperty('--bs-table_header_bg', (localStorage.getItem('--bs-table_header_bg')));

        const tablecolor = document.body;
        tablecolor.style.setProperty('--bs-table_header_color', (localStorage.getItem('--bs-table_header_color')));

        //Card
        const cardline = document.body;
        cardline.style.setProperty('--bs-card_line', (localStorage.getItem('--bs-card_line')));

        const cardcolor = document.body;
        cardcolor.style.setProperty('--bs-card_heading_color', (localStorage.getItem('--bs-card_heading_color')));

        //Wizard
        const wizardbg = document.body;
        wizardbg.style.setProperty('--bs-wizard_heading_bg', (localStorage.getItem('--bs-wizard_heading_bg')));

        const wizardcolor = document.body;
        wizardcolor.style.setProperty('--bs-wizard_heading_text_color', (localStorage.getItem('--bs-wizard_heading_text_color')));
        /************************  END *******************/                

        this.username = localStorage.getItem('username');
        this.restatus = localStorage.getItem('resstatus');          
        this.user_role = localStorage.getItem('type_name');
        this.user_company = localStorage.getItem('company_name');     
        this.plan_name = localStorage.getItem('plan_name');  
        this.broker_logo = localStorage.getItem('broker_logo');
        this.client_logo = localStorage.getItem('client_logo');
        this.clientname = localStorage.getItem('clientname');
        this.brokername = localStorage.getItem('brokername');
                        
        // console.log(e.url);
        this.current_page_url = e.url;
        this.pagehide_status = localStorage.getItem('pagehide');        

        if(this.pagehide_status == 'no' && e.url == '/verify-email'){
            localStorage.clear();
            this.route.navigate(['/home']);
        }                   

        if(this.current_page_url == "/home"){
            localStorage.clear();
            this.route.navigate(['/home']);            
        }
        
        this.surveyLoginFlag = localStorage.getItem('surveyLoginFlag');

      }    

   });   
  }

  ngOnInit(): void {                               
        
  } 

  signout(){

    localStorage.clear();
    localStorage.setItem('resstatus', 'false');
    // this.route.navigate(['/login']);

    if(localStorage.getItem('saml2_flag') == '1'){
      this.route.navigate(['/logout']);
    }else{
      this.route.navigate(['/login']);
    }

    

  }  

  checknav(){
    var body = $('body');
    if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
      body.toggleClass('sidebar-hidden');
    } else {
      body.toggleClass('sidebar-icon-only');
    }
  } 
   

}
