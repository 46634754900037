<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">Edit <span class="spanbs-title mt-2">Client</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>        
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- Edit Form -->
            <div id="stepper1" class="bs-stepper">

                <!-- <div class="bs-stepper-header stepperclass">
                    <div class="step" data-target="#test-l-1">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">1</span>
                            <span class="bs-stepper-label">Basic Details</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#test-l-2">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">2</span>
                            <span class="bs-stepper-label">Contact</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step stepper3_div" data-target="#test-l-3">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle" id="stepper3_circle">3</span>
                            <span class="bs-stepper-label">Company Details</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#test-l-4">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">4</span>
                            <span class="bs-stepper-label">Theme</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#test-l-5">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">5</span>
                            <span class="bs-stepper-label">Role Modules</span>
                        </button>
                    </div>
                </div> -->

                <div class="bs-stepper-header stepperclass">
                    <div class="step" data-target="#test-l-1">
                        <div class="step-trigger stepper1_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper1_circle">
                                <img src="../../../assets/img/policy/Group.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan">Basic Details</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-2">
                        <div class="step-trigger" id="stepper2_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper2_circle">
                                <img src="../../../assets/img/policy/Group (1).png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan">Contact</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-3">
                        <div class="step-trigger" id="stepper3_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper3_circle">
                                <img src="../../../assets/img/policy/Group 10.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan">Company Details</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-4">
                        <div class="step-trigger" id="stepper4_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper4_circle">
                                <img src="../../../assets/img/policy/Group 11.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan">Theme</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-5">
                        <div class="step-trigger" id="stepper5_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper5_circle">
                                <img src="../../../assets/img/policy/primary.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan">Role Modules</span>
                        </div>
                    </div>                    
                </div>

                <div class="bs-stepper-content steppercontent">

                    <div id="test-l-1" class="content">
                        <form [formGroup]="basicInfo">
                            <h3 class="bs-title">Basic Details </h3>
                            <hr>
                            <div class="row">
                                <div class="col-sm-4">
                                    <h5 class="bs-left-form-label">Company Info</h5>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="company_name">Client Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="company_name"
                                            id="company_name" placeholder="Client Name"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.company_name.errors}"
                                            required />
                                        <ng-container
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_name.errors">
                                            <p class="error"
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_name.errors.required">
                                                Client name is required
                                            </p>
                                        </ng-container>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="employee_type">Entity Type</label>
                                                <select class="form-select" formControlName="employee_type"
                                                    id="employee_type" (change)="getemployeetype($event)">
                                                    <option value="Single">Single</option>
                                                    <option value="Group">Group</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mt-3" id="employee_type_div" style="display: none">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="group_company_name">Group Company Name</label>
                                                <ng-select formControlName="group_company_name" id="group_company_name"
                                                    [items]="InfoArray_group_company_name"
                                                    bindLabel="name"
                                                    bindValue="id"
                                                    [placeholder]="'Select Group Company Name'" >
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group mt-3">
                                    <label class="bs-form-label" for="group_company_name">Group Company Name</label>
                                    <input type="text" class="form-control" formControlName="group_company_name" id="group_company_name" placeholder="Group Company Name" />
                                </div> -->

                                    <div class="row">

                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="industry">Industry</label>
                                                <ng-select formControlName="industry" id="industry"
                                                    [items]="InfoArrayIndustry" bindLabel="industry_name"
                                                    bindValue="industry_id"
                                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.industry.errors}">
                                                </ng-select>
                                                <ng-container
                                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.industry.errors">
                                                    <p class="error">
                                                        Industry is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="head_quarter_country">Head Quarter Country
                                                <span class="redstar">*</span></label>
                                             <ng-select formControlName="head_quarter_country" id="head_quarter_country"
                                                    [items]="country"
                                                    bindLabel="country"
                                                    bindValue="country"
                                                    [placeholder]="'Select Country'" 
                                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.head_quarter_country.errors}">
                                                </ng-select>
                                            <ng-container
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.head_quarter_country.errors">
                                                <p class="error">
                                                    Head Quarter Country is required
                                                </p>
                                            </ng-container>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <div class="mb-2 bs-form-label">Company Type * <i
                                                    class="bi bi-exclamation-circle"
                                                    style="width:20px; height:20px; transform: rotate(-180deg)"></i>
                                            </div>
                                            <input type="radio" class="form-radio" value="yes" formControlName="MNC"
                                                id="MNC" /> <span class="bs-radio-label"> MNC </span>
                                            &nbsp;
                                            <input type="radio" class="form-radio" value="no" formControlName="MNC"
                                                id="MNC" /> <span class="bs-radio-label"> Non-MNC </span>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <div class="mb-2 bs-form-label">Company Established * <i
                                                    class="bi bi-exclamation-circle"
                                                    style="width:20px; height:20px; transform: rotate(-180deg)"></i>
                                            </div>
                                            <input type="radio" class="form-radio" value="startup"
                                                formControlName="company_year_of_inception"
                                                id="company_year_of_inception" checked /> <span class="bs-radio-label">
                                                Startup ( < 5y) </span>
                                                    &nbsp; <input type="radio" class="form-radio" value="established"
                                                        formControlName="company_year_of_inception"
                                                        id="company_year_of_inception" /> <span class="bs-radio-label">
                                                        Established ( > 5y) </span>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="employee_headcount">Company Headcount
                                                <span class="redstar">*</span></label>
                                            <select class="form-select" formControlName="employee_headcount"
                                                id="employee_headcount"
                                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.employee_headcount.errors}"
                                                required>
                                                <option value="" selected>Select</option>
                                                <option value="Upto 100 (usually lifes will be multiply by 4 or 5)">Upto
                                                    100 (usually lifes will be multiply by 4 or 5)</option>
                                                <option value="101 to 250">101 to 250</option>
                                                <option value="251 to 500">251 to 500</option>
                                                <option value="501 to 1000">501 to 1000</option>
                                                <option value="1001 to 2000">1001 to 2000</option>
                                                <option value="2001 to 5000">2001 to 5000</option>
                                                <option value="5001 to 10000">5001 to 10000</option>
                                                <option value="10001 to 25000">10001 to 25000</option>
                                                <option value="25001 and more">25001 and more</option>
                                            </select>
                                            <ng-container
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employee_headcount.errors">
                                                <p class="error">
                                                    Company Headcount is required
                                                </p>
                                            </ng-container>
                                        </div>
                                        <!-- <div class="col-sm-6 form-group mt-3">
                                        <div class="mb-2 bs-form-label">Unicorn <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                                        <input type="radio" class="form-radio" value="yes" formControlName="unicorn" id="unicorn" /> <span class="bs-radio-label"> Yes </span>
                                        &nbsp; <input type="radio" class="form-radio" value="no" formControlName="unicorn" id="unicorn" /> <span class="bs-radio-label"> No </span> 
                                    </div>    -->
                                    </div>
                                </div>
                                <hr class="my-3">
                                <div>
                                    <button (click)="next(1)" class="btn btn-primary" style="float: right;">Save &
                                        Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div id="test-l-2" class="content">
                        <form [formGroup]="contactInfo">
                            <h3 class="bs-title">Contact</h3>
                            
                            <hr>
                            <div class="row">
                                <div class="col-sm-4">
                                    <h5 class="bs-left-form-label">Contact details</h5>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="contact_person">Contact Person <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="contact_person"
                                            id="contact_person" placeholder="John Doe"
                                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.contact_person.errors}"
                                            required />
                                        <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.contact_person.errors">
                                            <p class="error"
                                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.contact_person.errors.required">
                                                Contact Person is required
                                            </p>
                                        </ng-container>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="email_address">Email<span class="redstar">*</span></label>
                                            <input type="email" class="form-control" formControlName="email_address"
                                                id="email_address" placeholder="abc@xyz.com" (blur)="email_check()"
                                                [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.email_address.errors}"
                                                required />
                                            <ng-container
                                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.email_address.errors">
                                                <p class="error"
                                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.email_address.errors.required">
                                                    Email is required
                                                </p>
                                            </ng-container>
                                        </div>
                                        <div class="modal" tabindex="-1" role="dialog"
                                            [ngStyle]="{'display':displayStyleEmail}">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">Error !</h4>
                                                    </div>
                                                    <div class="modal-body">
                                                        This email Alraedy exists. Try Using another one.
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="closePopup()"> Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="contact_phone_code">Mobile Number</label>
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control" formControlName="contact_number"
                                                    id="contact_number" placeholder="1223 4455 7878" maxlength="10" minlength="10"
                                                    (keypress)=" isNumber($event)" (blur)="mobile_check()" />
                                            </div>
                                        </div>

                                        <div class="modal" tabindex="-1" role="dialog"
                                            [ngStyle]="{'display':displayStyleMobile}">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">Error !</h4>
                                                    </div>
                                                    <div class="modal-body">
                                                        This Email / Mobile Number Alraedy exists. Try using anothor
                                                        one.
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="closePopup()"> Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <label class="bs-form-label" for="landline">Landline</label>
                                            <input type="email" class="form-control" formControlName="landline"
                                                id="landline" placeholder="234 098 783" maxlength="10"
                                                (keypress)="isNumber($event)" />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-3">
                                <div class="col-sm-4">
                                    <h5 class="bs-left-form-label">Registered Address</h5>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="registered_address">Address <span class="redstar">*</span></label>
                                        <textarea class="form-control" formControlName="registered_address"
                                            id="registered_address" rows="3"
                                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.registered_address.errors}">
                                    </textarea>
                                        <!-- <input type="text" class="form-control" formControlName="addressline1" id="addressline1" placeholder="House number, House name" 
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.addressline1.errors}"
                                    required /> -->
                                        <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.registered_address.errors">
                                            <p class="error"
                                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.registered_address.errors.required">
                                                Address is required
                                            </p>
                                        </ng-container>
                                    </div>
                                    <!-- <div class="form-group mt-3">
                                    <label class="bs-form-label" for="addressline1_1">Address Line 1 <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="addressline1_1" id="addressline1_1" placeholder="Street name, Village name" 
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.addressline1_1.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.addressline1_1.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.addressline1_1.errors.required">
                                        Address is required
                                        </p>
                                    </ng-container>
                                </div>                         -->
                                    <div class="row">
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="zipcode">Zip / Postal Code <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="zipcode"
                                                id="zipcode" placeholder="123456" maxlength="6"
                                                (keypress)="isNumber($event)" (blur)="isNumber_tab2($event)"
                                                [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.zipcode.errors}"
                                                required />
                                            <ng-container
                                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.zipcode.errors">
                                                <p class="error"
                                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.zipcode.errors.required">
                                                    Zip / Postal Code is required
                                                </p>
                                            </ng-container>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="city">City</label>
                                            <input type="text" class="form-control" formControlName="city" id="city"
                                                placeholder="City Name" readonly/>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="state">State</label>
                                            <input type="text" class="form-control" formControlName="state" id="state"
                                                placeholder="State" readonly />
                                            <!-- <select class="form-select" formControlName="state" id="state">
                                            <option value="0">Select</option>
                                            <option *ngFor="let state of statesarr1" [value]="state">{{ state }}</option>
                                        </select> -->
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="country">Country <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="country"
                                                id="country" placeholder="Country"
                                                [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.country.errors}"
                                                required readonly/>
                                            <!-- <select class="form-select" formControlName="country" id="country" (change)="loadstate();">
                                            <option selected>Select</option>
                                            <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                        </select> -->
                                            <ng-container
                                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.country.errors">
                                                <p class="error"
                                                    *ngIf="contactInfoSubmitted && getContactInformationFormControls.country.errors.required">
                                                    Country is required
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4 mx-2">
                                <div class="col-sm-4">
                                    <h5 class="bs-left-form-label">Communication Address</h5>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="company_person">Same as Registered
                                            Address</label>
                                    </div>
                                    <div>
                                        <input type="radio" class="form-radio" value="yes_as_register"
                                            name="communication_address" formControlName="communication_address"
                                            (click)="ifradioclicked()" id="yes_as_register" /> <span
                                            class="bs-radio-label"> Yes </span> &nbsp;
                                        <input type="radio" class="form-radio" value="no_as_register"
                                            name="communication_address" formControlName="communication_address"
                                            (click)="ifradioclicked()" id="no_as_register" /> <span
                                            class="bs-radio-label"> No </span>
                                    </div>
                                    <div id="yes_or_no_div" class="my-3" style="display: none;">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="c_address">Address <span class="redstar">*</span></label>
                                            <textarea class="form-control" formControlName="c_address" id="c_address" rows="3">
                                            </textarea>
                                            <small id="c_address_error" class="bs-form-label" style="color: red; display: none; float: right;">Address Required</small>
                                        </div>
                                     
                                        <div class="row">
                                            <div class="col-sm-6 form-group mt-3">
                                                <label class="bs-form-label" for="c_zipcode">Zip / Postal Code <span class="redstar">*</span></label>
                                                <input type="text" class="form-control" formControlName="c_zipcode"
                                                    id="c_zipcode" placeholder="123456" maxlength="6"
                                                    (keypress)="isNumber($event)"
                                                    (blur)="isNumber_addr_repeat($event)" />
                                                    <small id="c_zipcode_error" class="bs-form-label" style="color: red; display: none; float: right;">Zipcode Required</small>
                                            </div>
                                            <div class="col-sm-6 form-group mt-3">
                                                <label class="bs-form-label" for="c_city">City</label>
                                                <input type="text" class="form-control" formControlName="c_city"
                                                    id="c_city" placeholder="City Name" readonly />
                                            </div>
                                            <div class="col-sm-6 form-group mt-3">
                                                <label class="bs-form-label" for="c_state">State</label>
                                                <input type="text" class="form-control" formControlName="c_state"
                                                    id="c_state" placeholder="State"readonly />

                                                <!-- <select class="form-select" formControlName="c_state" id="c_state">
                                                <option value="0">Select</option>
                                                <option *ngFor="let state of statesarr2" [value]="state">{{ state }}</option>
                                            </select> -->
                                            </div>
                                            <div class="col-sm-6 form-group mt-3">
                                                <label class="bs-form-label" for="c_country">Country <span class="redstar">*</span></label>
                                                <input type="text" class="form-control" formControlName="c_country"
                                                    id="c_country" placeholder="Country" readonly />

                                                <!-- <select class="form-select" formControlName="c_country" id="c_country" (change)="loadstate2()"
                                            >
                                                <option selected>Select</option>
                                                <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                            </select> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-3">

                                <div class="col-sm-12 mt-3">
                                    <div class="row"
                                        style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">

                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="hr_info"
                                                id="hr_info" (click)="hr_check()"
                                                style="margin-left: 0px; margin-top: 0px;" />
                                            <label class="form-check-label bs-radio-label"
                                                style="vertical-align: middle;">Should create employer user(HR)?</label>
                                        </div>

                                        <div class="col-sm-12" id="hr_data"></div>


                                        <div id="showaddrow" style="display: none">
                                            <div class="col-sm-12">
                                                <div class="newhr_row">

                                                </div>
                                            </div>


                                            <div class="col-sm-3 mt-3" id="adrowdiv" style="display: none">
                                                <label class="bs-form-label" (click)="addHrRow()"
                                                    style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png"
                                                        style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add New Row</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <hr class="my-3">
                                
                                <div>
                                    <button (click)="next(2)" id="step2btn" class="btn btn-primary"
                                        style="float: right;">Save & Continue</button>
                                    <button (click)="previous()" class="btn btn-primary m-3 mt-0"
                                        style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div id="test-l-3" class="content">
                        <form [formGroup]="companyInfo">
                            <h3 class="bs-title">Company Details</h3>
                            <hr>
                            <div class="row">
                                <div class="col-sm-4">
                                    <h5 class="bs-left-form-label">Revenue</h5>
                                </div>
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <!-- <div class="form-group">
                                            <label class="bs-form-label" for="employee_headcount">Company Headcount <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="employee_headcount" id="employee_headcount" placeholder="Company Headcount" (keypress)="isNumber($event)"
                                            [ngClass]="{'control-red': companyInfoSubmitted && getCompanyInformationFormControls.employee_headcount.errors}"
                                            required />
                                            <ng-container
                                                *ngIf="companyInfoSubmitted && getCompanyInformationFormControls.employee_headcount.errors">
                                                <p class="error"
                                                *ngIf="companyInfoSubmitted && getCompanyInformationFormControls.employee_headcount.errors.required">
                                                Company Headcount is required
                                                </p>
                                            </ng-container>
                                        </div> -->
                                        </div>
                                        <div class="col-sm-12 form-group">
                                            <label class="bs-form-label" for="turnover">Turnover / Revenue</label>
                                            <select class="form-select" id="turnover" formControlName="turnover">
                                                <option value="" selected>Select</option>
                                                <option value="Upto 10 Cr.">Upto 10 Cr.</option>
                                                <option value="11 Cr to 100 Cr.">11 Cr to 100 Cr.</option>
                                                <option value="101 Cr to 250 Cr.">101 Cr to 250 Cr.</option>
                                                <option value="251 Cr to 1,000 Cr.">251 Cr to 1,000 Cr.</option>
                                                <option value="1,001 Cr to 5,000 Cr.">1,001 Cr to 5,000 Cr.</option>
                                                <option value="5,001 Cr to 10,000 Cr.">5,001 Cr to 10,000 Cr.</option>
                                                <option value="Above 10,000 Cr.">Above 10,000 Cr.</option>
                                            </select>
                                            <!-- <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                                                
                                            </div>
                                            <input type="text" class="form-control" formControlName="company_turnover_val" id="company_turnover_val" placeholder="1223 4455 7878" (keypress)="isNumber($event)">
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-3">
                                <div class="col-sm-4">
                                    <h5 class="bs-left-form-label">Company Details</h5>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="type_of_firm">Company Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="type_of_firm" id="type_of_firm"
                                            (change)="getcompanytype()"
                                            [ngClass]="{'control-red': companyInfoSubmitted && getCompanyInformationFormControls.type_of_firm.errors}"
                                            required>
                                            <option value="" selected>Select</option>
                                            <option value="Private Limited Company">Private Limited Company</option>
                                            <option value="Public Limited Company">Public Limited Company</option>
                                            <option value="Limited Liability Partnership">Limited Liability Partnership
                                            </option>
                                            <option value="Partnerships Company">Partnerships Company</option>
                                            <option value="One Person Company">One Person Company</option>
                                            <option value="Sole Proprietorship">Sole Proprietorship</option>
                                            <option value="Section 8 Company">Section 8 Company</option>

                                        </select>
                                        <ng-container
                                            *ngIf="companyInfoSubmitted && getCompanyInformationFormControls.type_of_firm.errors">
                                            <p class="error">
                                                Company Type is required
                                            </p>
                                        </ng-container>
                                    </div>

                                    <div id="listeddiv" style="display:none">
                                        <div class="form-group mt-3">
                                            <label class="bs-form-label" for="company_person">Listed / Unlisted</label>
                                        </div>
                                        <div>
                                            <input type="radio" class="form-radio" value="yes" formControlName="listed"
                                                name="listed" id="yes_listed" /> <span class="bs-radio-label"> Yes
                                            </span> &nbsp;
                                            <input type="radio" class="form-radio" value="no" formControlName="listed"
                                                name="listed" id="no_unlisted" /> <span class="bs-radio-label"> No
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="company_registration_no">Company
                                                Registration Number</label>
                                            <input type="text" class="form-control"
                                                formControlName="company_registration_no" id="company_registration_no"
                                                placeholder="ABDDHJH3647264" maxlength="21" />
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="GST">GST</label>
                                            <input type="text" class="form-control" formControlName="GST" id="GST"
                                                maxlength="15" placeholder="06BZAHM6385P6Z2"
                                                (keydown)="checkGST($event)" (keyup)="checkGST($event)"
                                                style="text-transform: uppercase;" />
                                            <p class="error" id="GSTvalid" style="display: none">GST is not in valid
                                                format eg.(06BZAHM6385P6Z2)</p>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="PAN">PAN</label>
                                            <input type="text" class="form-control" formControlName="PAN" id="PAN"
                                                maxlength="10" placeholder="AAAAA9999A" (keydown)="checkPAN($event)"
                                                (keyup)="checkPAN($event)" style="text-transform: uppercase;" />
                                            <p class="error" id="PANvalid" style="display: none">PAN is not in valid
                                                format eg.(AAAAA9999A)</p>
                                        </div>
                                        <div class="col-sm-6 form-group mt-3">
                                            <label class="bs-form-label" for="TAN">TAN</label>
                                            <input type="text" class="form-control" formControlName="TAN" id="TAN"
                                                maxlength="10" placeholder="123456" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for="member_data">Company Logo</label>
                                        <label for="file_field6" id="custom-file-upload_member_data"
                                            class="custom-file-upload form-control"
                                            style="text-align:center; cursor:pointer; padding: 5px; background: white;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span
                                                id="selectFile_member_data"
                                                style="color:#4E0099; font-weight: 600; text-align:center">Add
                                                Image</span>
                                        </label>
                                        <input id="file_field6" type="file" (change)="member_data_change($event)"
                                            formControlName="member_data" accept=".png, .jpg, .jpeg, application/pdf"
                                            enctype="multipart/form-data" style="display: none" />
                                    </div>
                                    <small style="font-size: 12px; color:#605D62" id="small_member_data">Upload only
                                        .png, .jpg, .jpeg files (Max size 4MB)</small>
                                </div>

                                <div class="imgDiv col-sm-6 mt-3" style="display: none">
                                    <img src="{{imgpath}}"
                                        style="width: 100px; height: 100px; margin: auto; display: block;" />
                                </div>
                                <!-- Branch Div -->
                                <div class="col-sm-12 mt-3">
                                    <div class="row"
                                        style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">

                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input"
                                                formControlName="branch_info" id="branch_info" (click)="branch_check()"
                                                style="margin-left: 0px; margin-top: 0px;" />
                                            <label class="form-check-label bs-radio-label"
                                                style="vertical-align: middle;">Should create branch?</label>
                                        </div>

                                        <div id="showaddrow_branch" style="display: none">
                                            <div class="col-sm-12">
                                                <div class="newbranch_row">

                                                </div>
                                            </div>

                                            <div class="col-sm-3 mt-3" id="adrowdivBranch" style="display: none">
                                                <label class="bs-form-label" (click)="addbranchRow()"
                                                    style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png"
                                                        style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add New Row</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <hr class="my-3">
                                <div>
                                    <button (click)="next(3);" class="btn btn-primary" id="step3btn"
                                        style="float: right;">Save & Continue</button>
                                    <button (click)="previous()" class="btn btn-primary m-3 mt-0"
                                        style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div id="test-l-4" class="content">
                        <form [formGroup]="themeInfo">
                            <h3 class="bs-title">Theme Details </h3>
                            <div class="alert alert-success" id="validmsg" (click)="closealert()"
                                style="display: none;">
                                <strong>Theme created Successfully! {{ validmsg }} <span
                                        class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span
                                        class="alertspan">&times;</span></strong>
                            </div>
                            <hr>

                            <div class="row">

                                <div class="col-sm-4 mt-3" *ngFor="let theme of InfoArray_themelist; let i = index">

                                    <div class="labl" id="div{{theme.id}}"
                                        style="padding: 10px; border: solid 1px #949494; border-radius: 10px 0px 10px 0px; cursor: pointer;"
                                        (click)="setthemId(theme.id)">
                                        <h6>
                                            {{theme.name}}
                                        </h6>

                                        <!-- primary -->
                                        <div style="border: solid 1px #949494; display: flex">
                                            <span title="global_primary_color"
                                                [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Button']['global_primary_color'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                        </div>

                                        <!-- sidebar -->
                                        <div style="border: solid 1px #949494; display: flex">
                                            <span title="sidebar_background"
                                                [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['sidebar_background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                        </div>

                                        <!-- navbar -->
                                        <div style="border: solid 1px #949494; display: flex">
                                            <span title="navbar_background"
                                                [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Navbar']['navbar_background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                            <span title="navbar_color"
                                                [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Navbar']['navbar_color'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                        </div>

                                        <!-- button -->
                                        <div style="border: solid 1px #949494; display: flex">
                                            <span title="button_background"
                                                [ngStyle]="{'width':'33.33%', height:'20px', 'background' : theme.themejson['Button']['button_background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                            <span title="button_border"
                                                [ngStyle]="{'width':'33.33%', height:'20px', 'background' : theme.themejson['Button']['button_border'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                            <span title="button_text"
                                                [ngStyle]="{'width':'33.33%', height:'20px', 'background' : theme.themejson['Button']['button_text'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                                        </div>

                                        <!-- table -->
                                        <div style="border: solid 1px #949494;">
                                            <div style="display: flex">
                                                <span title="table_header_background"
                                                    [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Table']['table_header_background']}">&nbsp;</span>
                                                <span title="table_header_color"
                                                    [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Table']['table_header_color']}">&nbsp;</span>
                                            </div>
                                        </div>

                                        <!-- card -->
                                        <div style="border: solid 1px #949494;">
                                            <div style="display: flex">
                                                <span title="card_line"
                                                    [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Card']['card_line']}">&nbsp;</span>
                                                <span title="card_heading_color"
                                                    [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Card']['card_heading_color']}">&nbsp;</span>

                                            </div>
                                        </div>

                                        <!-- Wizard -->
                                        <div style="border: solid 1px #949494;">
                                            <div style="display: flex">
                                                <span title="wizard_heading_background"
                                                    [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Wizard']['wizard_heading_background']}">&nbsp;</span>
                                                <span title="wizard_heading_text_color"
                                                    [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Wizard']['wizard_heading_text_color']}">&nbsp;</span>
                                            </div>
                                        </div>

                                        <!-- <div class="row" style="margin: 5px 0px; margin-bottom: 0px;">
                                            <div class="col-sm-6 p-1 text-center" *ngIf="theme.preview == true">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="onPreview(theme,1,i)" role="button"
                                                    style="width: 100%;">Preview</button>
                                            </div>
                                            <div class="col-sm-6 p-1 text-center" *ngIf="theme.undo_preview == true">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="onPreview(theme,0,i)" role="button"
                                                    style="width: 100%; background-color: #000; color:#fff">Undo
                                                    Preview</button>
                                            </div>
                                        </div> -->

                                    </div>

                                </div>

                                <hr class="my-3">
                                <div>
                                    <button (click)="next(4);" class="btn btn-primary" style="float: right;">Save &
                                        Continue</button>
                                    <button (click)="previous()" class="btn btn-primary m-3 mt-0"
                                        style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                                </div>

                            </div>

                        </form>
                    </div>

                    <div id="test-l-5" class="content">
                        <form [formGroup]="modulesInfo">
                            <h3 class="bs-title">Role Modules </h3>
                            <div class="alert alert-success" id="validmsg" (click)="closealert()"
                                style="display: none;">
                                <strong>Modules created Successfully! {{ validmsg }} <span
                                        class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span
                                        class="alertspan">&times;</span></strong>
                            </div>
                            <hr>

                            <div class="row">

                                <div *ngFor="let group of InfomoduleArray; let i = index" class="mb-3">

                                    <div class="row">

                                        <div class="col-sm-6">
                                            <h6
                                                style="color: #1f9cf8; font-weight: 600; padding: 10px; background: url('../../../assets/website/bg/header-bg.png'); background-position: left;">
                                                {{group['module_name']}}
                                            </h6>
                                        </div>

                                        <div class="col-sm-6 row"
                                            style="height: 38px; padding: 6px 10px; padding: 10px; background: url('../../../assets/website/bg/header-bg.png'); background-position: left;">

                                            <!-- <div class="form-check col-sm-4">
                                                <input type="checkbox" class="form-check-input parent" value="Read_{{group['module_name']}}" formControlName="Read" name="Read" id="Read_{{i}}" checked />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">All</label>
                                            </div>
                                            <div class="form-check col-sm-4">
                                                <input type="checkbox" class="form-check-input parent" value="Write_{{i}}" formControlName="Write" name="Write" id="Write_{{i}}" checked />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">All</label>
                                            </div>
                                            <div class="form-check col-sm-4">
                                                <input type="checkbox" class="form-check-input parent" value="Delete_{{i}}" formControlName="Delete" name="Delete" id="Delete_{{i}}" checked />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">All</label>
                                            </div> -->

                                        </div>
                                    </div>

                                    <!-- <hr class="m-0"> -->

                                    <div class="row p-0 m-0"
                                        *ngFor="let child of InfomoduleArray[i]['child']; let j = index">

                                        <div class="col-sm-6">
                                            <label class="form-check-label bs-radio-label">
                                                {{child['module_name']}}
                                            </label>
                                        </div>

                                        <div class="col-sm-6 row">

                                            <div class="form-check col-sm-4">
                                                <input type="checkbox" class="form-check-input child read" name="Read"
                                                    id="read_{{child['id']}}" [checked]="child['module_read']" />
                                                <label class="form-check-label bs-radio-label"
                                                    style="vertical-align: middle;">Read</label>
                                            </div>
                                            <div class="form-check col-sm-4">
                                                <input type="checkbox" class="form-check-input child write" name="Write"
                                                    id="write_{{child['id']}}" [checked]="child['module_write']" />
                                                <label class="form-check-label bs-radio-label"
                                                    style="vertical-align: middle;">Write</label>
                                            </div>
                                            <div class="form-check col-sm-4">
                                                <input type="checkbox" class="form-check-input child delete"
                                                    name="Delete" id="delete_{{child['id']}}"
                                                    [checked]="child['module_delete']" />
                                                <label class="form-check-label bs-radio-label"
                                                    style="vertical-align: middle;">Delete</label>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <hr class="my-3">
                                <div>
                                    <button (click)="next(5);" class="btn btn-primary"
                                        style="float: right;">Finish</button>
                                    <button (click)="previous()" class="btn btn-primary m-3 mt-0"
                                        style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>