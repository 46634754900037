<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Frequently Asked Questions</h3>

            <div class="faq-container mt-3">
                <div class="faq" *ngFor="let item of faqs" [class.active]="item.active" (click)="toggleAnswer(item)">
                    <div class="faq-question">
                        <h3>{{ item.question }}</h3>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                    <div class="faq-answer">
                        <p>{{ item.answer }}</p>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>