
<div class="bg-white my-4 mb-5 rounded p-3 px-5" id="maindiv">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="pricingtitle text-center mt-3">Annual Subscription Plans</h3>
    <!-- <p class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore amet et hic, reprehenderit explicabo dolorum</p> -->
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;padding-bottom: 5px;padding-top: 5px;">
      <strong>{{ validmsg }} <span class="alertspan" style="float: right;cursor: pointer;">&times;</span></strong>
  </div>
  <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;padding-bottom: 5px;padding-top: 5px;">
    <strong>{{ validmsg }} <span class="alertspan" style="float: right;cursor: pointer;">&times;</span></strong>
</div>
    <div class="my-4" align="center">
      <!-- <ul class="nav nav-tabs" id="pricingTab" role="tablist" style="border: none; background: #F5EAFF; width: 305px; border-radius: 4px;">
        <li class="nav-item" role="presentation" style="padding:6px 8px 8px 6px; border-radius: 4px; padding-right: 0px;">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><b>Annual Billing</b></button>
        </li>
        <li class="nav-item" role="presentation" style="padding:6px 8px 8px 6px; border-radius: 4px; padding-left: 0px;">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><b>Annual Billing</b></button>
        </li>
      </ul> -->
      <div class="tab-content" id="pricingTabContent">

        <!---Tab1-->
        <div class="tab-pane fade show active mt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="table-responsive">
                <table class="table table-striped" id="pricingTable"  *ngIf="tokenable_id!=null">
                    <tbody>
                        <tr *ngFor="let pricing of pricingarr">
                            <td style="font-size: 13px; vertical-align: middle;" class="">

                                <strong>{{pricing.title}}</strong>

                            </td>
                            <td style="font-size: 13px; vertical-align: middle;" class="text-center">
                                <span *ngIf="pricing.column_1 != 'yes' && pricing.column_1 != '-' && pricing.column_1 != '0' && pricing.column_1 != '1'">{{pricing.column_1}}</span>

                                <span *ngIf="pricing.column_1 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                                <span *ngIf="pricing.column_1 == '-'">-</span>

                                <span *ngIf="pricing.column_1 == '1' && planame == 'Base'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                                </span>

                                <span *ngIf="pricing.column_1 == '1' && planame != 'Base'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Base')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Get Started</button>
                                </span>

                                <span *ngIf="pricing.column_1 == '0' && planame != 'Base'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3"  (click)="fn_clickplan('Base')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Downgrade</button>
                                </span>

                            </td>
                            <td style="font-size: 13px; vertical-align: middle;" class="text-center">

                                <span *ngIf="pricing.column_2 != 'yes' && pricing.column_2 != '-' && pricing.column_2 != '0' && pricing.column_2 != '1'">{{pricing.column_2}}</span>

                                <span *ngIf="pricing.column_2 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                                <span *ngIf="pricing.column_2 == '-'">-</span>

                                <span *ngIf="pricing.column_2 == '1' && planame == 'Silver'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                                </span>

                                <span *ngIf="pricing.column_2 == '0' && planame != 'Silver' &&  planame == 'Base'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Silver')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Upgrade</button>
                                </span>

                                <span *ngIf="pricing.column_2 == '0' && planame != 'Silver' &&  planame == 'Gold'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Silver')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Downgrade</button>
                                </span>

                                <span *ngIf="pricing.column_2 == '0' && planame != 'Silver' && planame != 'Gold' && planame == 'Platinum'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Silver')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Downgrade</button>
                                </span>
                                

                            </td>
                            <td style="font-size: 13px; vertical-align: middle;" class="text-center">

                                <!-- <span *ngIf="pricing.column_3 == 'Gold'"><span class="rec">Recommended</span></span> -->

                                <span *ngIf="pricing.column_3 != 'yes' && pricing.column_3 != '-' && pricing.column_3 != '0' && pricing.column_3 != '1'">{{pricing.column_3}}</span>

                                <span *ngIf="pricing.column_3 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                                <span *ngIf="pricing.column_3 == '-'">-</span>

                                <span *ngIf="pricing.column_3 == '1' && planame == 'Gold'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                                </span>

                                <span *ngIf="pricing.column_3 == '0' && planame != 'Gold' && planame == 'Base'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Gold')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Upgrade</button>
                                </span>

                                <span *ngIf="pricing.column_3 == '0' && planame != 'Gold' && planame == 'Silver'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Gold')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Upgrade</button>
                                </span>

                                <span *ngIf="pricing.column_3 == '0' && planame != 'Gold' && planame != 'Silver' && planame == 'Platinum'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Gold')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Downgrade</button>
                                </span>

                            </td>
                            <td style="font-size: 13px; vertical-align: middle;" class="text-center">
                                <span *ngIf="pricing.column_4 != 'yes' && pricing.column_4 != '-' && pricing.column_4 != '0' && pricing.column_4 != '1'">{{pricing.column_4}}</span>

                                <span *ngIf="pricing.column_4 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                                <span *ngIf="pricing.column_4 == '-'">-</span>

                                <span *ngIf="pricing.column_4 == '1' && planame == 'Platinum'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                                </span>

                                <span *ngIf="pricing.column_4 == '0' && planame != 'Platinum'">
                                    <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_clickplan('Platinum')" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Upgrade</button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!------LOGIN USER------>
                <table class="table table-striped" id="pricingTable"  *ngIf="tokenable_id==null">
                  <tbody>
                      <tr *ngFor="let pricing of pricingarr">
                          <td style="font-size: 13px; vertical-align: middle;" class="">

                              <strong>{{pricing.title}}</strong>

                          </td>
                          <td style="font-size: 13px; vertical-align: middle;" class="text-center">
                              <span *ngIf="pricing.column_1 != 'yes' && pricing.column_1 != '-' && pricing.column_1 != '0' && pricing.column_1 != '1'">{{pricing.column_1}}</span>

                              <span *ngIf="pricing.column_1 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                              <span *ngIf="pricing.column_1 == '-'">-</span>

                              <span *ngIf="pricing.column_1 == '1' && planame == 'Base'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                              </span>

                              <span *ngIf="pricing.column_1 == '1' && planame != 'Base'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_gotologin()" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Get Started</button>
                              </span>

                              <span *ngIf="pricing.column_1 == '0' && planame != 'Base'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_gotologin()"  style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Get Started</button>
                              </span>

                          </td>
                          <td style="font-size: 13px; vertical-align: middle;" class="text-center">

                              <span *ngIf="pricing.column_2 != 'yes' && pricing.column_2 != '-' && pricing.column_2 != '0' && pricing.column_2 != '1'">{{pricing.column_2}}</span>

                              <span *ngIf="pricing.column_2 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                              <span *ngIf="pricing.column_2 == '-'">-</span>

                              <span *ngIf="pricing.column_2 == '1' && planame == 'Silver'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                              </span>

                              <span *ngIf="pricing.column_2 == '0' && planame != 'Silver'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_gotologin()" style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Get Started</button>
                              </span>

                          </td>
                          <td style="font-size: 13px; vertical-align: middle;" class="text-center">

                              <!-- <span *ngIf="pricing.column_3 == 'Gold'"><span class="rec">Recommended</span></span> -->

                              <span *ngIf="pricing.column_3 != 'yes' && pricing.column_3 != '-' && pricing.column_3 != '0' && pricing.column_3 != '1'">{{pricing.column_3}}</span>

                              <span *ngIf="pricing.column_3 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                              <span *ngIf="pricing.column_3 == '-'">-</span>

                              <span *ngIf="pricing.column_3 == '1' && planame == 'Gold'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                              </span>

                              <span *ngIf="pricing.column_3 == '0' && planame != 'Gold'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_gotologin()"  style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Get Started</button>
                              </span>

                          </td>
                          <td style="font-size: 13px; vertical-align: middle;" class="text-center">
                              <span *ngIf="pricing.column_4 != 'yes' && pricing.column_4 != '-' && pricing.column_4 != '0' && pricing.column_4 != '1'">{{pricing.column_4}}</span>

                              <span *ngIf="pricing.column_4 == 'yes'"><img src='../../assets/img/pricing/check.png' class='iconDetails mt-2'></span>

                              <span *ngIf="pricing.column_4 == '-'">-</span>

                              <span *ngIf="pricing.column_4 == '1' && planame == 'Plainum'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" style="display: block; font-size: 14px; background: none; border: 1px solid #787579">Current Plan</button>
                              </span>

                              <span *ngIf="pricing.column_4 == '0' && planame != 'Plainum'">
                                  <button class="btn btn-primary w-100 text-black text-center my-3" (click)="fn_gotologin()"  style="display: block; font-size: 14px; background: #4E0099; color: #fff !important;">Get Started</button>
                              </span>
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
        </div>

        <!---Tab2-->
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

        </div>

      </div>
    </div>

</div>
