import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';

@Component({
  selector: 'app-upload-policy-coverage',
  templateUrl: './upload-policy-coverage.component.html',
  styleUrls: ['./upload-policy-coverage.component.css']
})
export class UploadPolicyCoverageComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public lockinInfo:FormGroup;
  lockinInfoSubmitted: boolean = false;

  public coveractionInfo:FormGroup;
  coveractionInfoSubmitted: boolean = false;

  companyDetails:any = [];
  policy_type:any =[];
  policynumbers:any = [];

  document_file: any;
  document_file_corrections: any;

  popup_document_file: any;
  downloadbuttontooltip:any;

  removesamplefile: any;
  correctionssamplefile: any;

  Policyuploaddoclist:any = [];
  PopupDataArray:any = [];
  PopupDataArrayCorrection:any = [];
  showtable:any;
  showtableCorrection:any;
  downloadlink:any;
  statuspopupbtn:any;
  typelistinfoArray:any = [];

  TopUpSectiontoggle = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  dtOptions_popup: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger_popup: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click();
  }

  initGroup(){

    this.getcompanyDetails();
    this.getPolicyuploaddoclist();
    this.typelistinfo();

    $('#validmsg').css('display', 'none');

    this.lockinInfo = this.fb.group({
      company_id: new FormControl('', Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_number: new FormControl('', Validators.required),
      lockin_type: new FormControl('', Validators.required),
    });

    this.coveractionInfo = this.fb.group({
      // endoresement_name: new FormControl('', Validators.required),
    });    

  }

  get getlockinInformationFormControls() {
    return this.lockinInfo.controls;
  }

  get getcoveractionInformationFormControls() {
    return this.coveractionInfo.controls;
  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.companyDetails = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

  typelistinfo(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    }
    this.service.typelistinfo(postdata)
      .subscribe((result) => {

        this.typelistinfoArray = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

  getpolicytype(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.lockinInfo.controls['company_id'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.lockinInfo.controls['company_id'].value,
      "sub_type_id":this.lockinInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getsamplefile(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.lockinInfo.controls['policy_number'].value,
      "sub_type_id":this.lockinInfo.controls['policy_type'].value,
      "lockin_type":this.lockinInfo.controls['lockin_type'].value,
    }

    // Get Remove sample API file
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.removesamplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

    // Get Remove sample API file
    // this.service.getsamplefile_corrections(postdata)
    //   .subscribe((result) => {
    //     if(result.status){
    //       $("#download_sample_file_corrections").css("display", "block");
    //       this.correctionssamplefile=result.data['policy_member_sample'];
    //     }else{
    //       $("#download_sample_file_corrections").css("display", "none");
    //     }
    //   }, (error) => {
    //       console.log(error);
    // });

  }

  openCity(evt:any, cityName:any) {

    var i, tabcontent, tablinks;
    tabcontent = $(".tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    $("#"+cityName).css("display", "block")
    evt.currentTarget.className += " active";

  }

  ondownload(val: any){

    if(val == 'remove'){
      window.open(this.removesamplefile,'_blank');
    }

    if(val == 'corrections'){
      window.open(this.correctionssamplefile,'_blank');
    }


  }

  // Remove Upload file
  uploadfile(event: any){
    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  // Correction Upload file
  uploadfile_corrections(event: any){
    if(event.target.files.length > 0){
      this.document_file_corrections = event.target.files[0];
      $("#features-file-upload_document_file_corrections").empty();
      $('#features-file-upload_document_file_corrections').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file_corrections').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file_corrections').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  uploadfile_popup(event: any){
    if(event.target.files.length > 0){
      this.popup_document_file = event.target.files[0];
      $("#features-file-upload_popup_document_file").empty();
      $('#features-file-upload_popup_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_popup_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_popup_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  // Lock In Submit
  onSubmitLockIn(){

    this.coveractionInfoSubmitted = true;

    let company_id:any = $('#company_id').val();
    let policy_type:any = $('#policy_type').val();
    let policy_number:any = $('#policy_number').val();
    let lockin_type:any = $('#lockin_type').val();

    if(company_id != '' || policy_type != '' || policy_number != '' || policy_number != '' || lockin_type != ''){

      if (this.coveractionInfoSubmitted && this.coveractionInfo.valid) {

        this.show = true;

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":this.lockinInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "sub_type_id":this.lockinInfo.controls['policy_type'].value,
          "master_policies_id":this.lockinInfo.controls['policy_number'].value,
          "lockin_type":this.lockinInfo.controls['lockin_type'].value,
          policy_member_data : this.document_file
        };
        console.log(postdata);
        
        this.service.uploadsamplefile(postdata)
          .subscribe((result) => {
            if(result.status){
              this.show = false;

              this.validmsg = result.message;

              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');
              // this.route.navigate(['endoresement-request']);

              setTimeout(() => {
                window.location.reload();
              }, 2000);

            }
          }, (error) => {
              this.show = false;
              console.log(error);
        });

      }

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

      if(lockin_type != ''){
        $('.lockin_type_error').css('display','none');
      }else{
        $('.lockin_type_error').css('display','block');
      }

    }else{

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

      if(lockin_type != ''){
        $('.lockin_type_error').css('display','none');
      }else{
        $('.lockin_type_error').css('display','block');
      }

    }

  }  

  closealert(){
    this.TopUpSectiontoggle = "none";
  }

  // Table Listing API
  getPolicyuploaddoclist(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getPolicyuploaddoclist(postdata)
      .subscribe((result) => {

        this.Policyuploaddoclist = result.data;
        this.dtTrigger.next();

      }, (error) => {
          // console.log(result);
          this.Policyuploaddoclist = [];
    });
  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any, policyid:any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
      "policyid": policyid
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.policy_member_sample,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });

  }  

}

