import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';
declare var google: any;

@Component({
  selector: 'app-broker-networks',
  templateUrl: './broker-networks.component.html',
  styleUrls: ['./broker-networks.component.css']
})
export class BrokerNetworksComponent implements OnInit {
  citiesArray: any = [];
  stateArray: any = [];
  policyArray: any = [];
  InfoArrayClient: any = [];   
  HospitalArray: any = [];

  selectedClient: any; // Variable to hold the selected policy
  selectedState: any; // Variable to hold the selected state
  selectedCity: any; // Variable to hold the selected city
  map: any; // Variable to hold the Google Map object

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.loadGoogleMapsScript(() => {
      this.initMap();
    });
    this.getcompanyDetails(); // Load policies on component initialization
  }

  loadGoogleMapsScript(callback: () => void) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDr8kCpkWxnmbJEAa26DuVq0fQvCoy54nA&libraries=places`;
    script.defer = true;
    script.async = true;
    script.onload = callback; // Call the callback function when the script is loaded
    document.head.appendChild(script);
  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.dataservice.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.InfoArrayClient = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }
  
  getPolicy() {

    setTimeout(() => {
      this.selectedCity = null;
      this.selectedState = null;
      this.HospitalArray = [];
      this.citiesArray = [];
      this.stateArray = [];
    }, 1000);
  
      
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.selectedClient,
    };
  
    this.dataservice.getPolicy(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.policyArray = result.data;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }
  
  getClientID(event: any){
    this.selectedClient = event.id;
    this.getPolicy();
  }

  getState() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.selectedClient,
      "policy_id": $('#policy_id').val()
    };

    this.dataservice.getState(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.stateArray = result.data.networkHospitalCityData;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  onStateChange(event: any) {
    this.selectedState = event.state_id;
    // Clear the city selection and the citiesArray
    this.selectedCity = null;
    this.HospitalArray = [];
    this.citiesArray = [];
    this.getCities();
  }

  
  // Function to fetch cities based on the selected policy
  getCities() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.selectedClient,
      "policy_id": $('#policy_id').val(),
      "state_id": this.selectedState,
    };

    this.dataservice.getCity(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.citiesArray = result.data.networkHospitalCityData;
          this.citiesArray.sort((a: { city_name: string; }, b: { city_name: any; }) => a.city_name.localeCompare(b.city_name));
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  displayMarkers() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.selectedClient,
      "policy_id": $('#policy_id').val(),
      "state_id": this.selectedState,
      "city_name": this.selectedCity
    };
  
    this.dataservice.getInfo(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.HospitalArray = result.data.networkHospitallist;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  callmaps(pin:any){    
    $('#map').css('display',' block');
    $('#map_root').css('display',' none');
    this.initMap(pin);
  }
  
  // Function to initialize the Google Map
  initMap(places: any | any[] = []) {
    // If a single place is passed, convert it to an array
    if (!Array.isArray(places)) {
      places = [places];
    }
  
    if (places.length === 0) {
      console.log('No places provided.');
      return;
    }
  
    // Initialize variables for total latitude and longitude
    let totalLat = 0;
    let totalLng = 0;
  
    // Array to keep track of geocoded locations
    const locations: any[] = [];
  
    const geocodePromises = places.map((place: string) => {
      return this.geocodeAddress(place)
        .then((location) => {
          locations.push(location);
          totalLat += location.lat();
          totalLng += location.lng();
        })
        .catch((error) => {
          console.log('Geocode was not successful for the following reason: ' + error);
        });
    });
  
    // Once all geocoding promises are resolved
    Promise.all(geocodePromises).then(() => {
      const avgLat = totalLat / locations.length;
      const avgLng = totalLng / locations.length;
  
      // Create a LatLngBounds object to contain all the markers
      const bounds = new google.maps.LatLngBounds();
  
      // Initialize the map centered at the average location of places
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 4,
        center: { lat: avgLat, lng: avgLng }
      });
  
      // Loop through the geocoded locations and add markers
      locations.forEach((location, index) => {
        bounds.extend(location); // Extend the bounds to include this marker
        this.map.fitBounds(bounds); // Fit the map to the bounds
  
        const marker = new google.maps.Marker({
          position: location,
          map: this.map,
          title: places[index]
        });
      });
    });
  }
  

  geocodeAddress(cityName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': cityName }, (results: { geometry: { location: any; }; }[], status: string) => {
        if (status === 'OK') {
          resolve(results[0].geometry.location);
        } else {
          reject(status);
        }
      });
    });
  }

}
