import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { EmployeeDependencyService } from '../employee-dependency/employeeDependency';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-employee-dependency',
  templateUrl: './employee-dependency.component.html',
  styleUrls: ['./employee-dependency.component.css']
})
export class EmployeeDependencyComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public employeeDependency:FormGroup;

  user_role: any;
  validmsg: any;
  fileData: any;
  fileName: any = '';
  clientArray: any = [];
  InfoArray: any = [];
  InfoArray_dependency: any = [];
  displayStyle_view = "none";
  displayStyle_edit = "none";  
  displayStyle_edit_dep = "none";  

  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  dtOptions2: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger2: Subject<any> = new Subject<any>();    

  documentUploadSubmitted: boolean = false;

  productsArr:  any = [];

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public EditInfo_dep:FormGroup;
  EditInfoSubmitted_dep: boolean = false;

  policylistbyproductArr: any = [];

  constructor(private fb: FormBuilder, private employeedependencyService: EmployeeDependencyService, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

   ngOnInit(): void {

    this.initGroup();

    // Form element defined below
    this.EditInfo = this.fb.group({
      emp_code_edit: new FormControl(''),
      emp_name_edit: new FormControl(''),
      emp_date_of_joining_edit: new FormControl(''),
      emp_date_of_birth_edit: new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.EditInfo_dep = this.fb.group({
      full_name_edit_dep: new FormControl(''),
      emp_date_of_birth_edit_dep: new FormControl(''),
      edit_id_dep: new FormControl(''),
      edit_is_active_dep: new FormControl(''),
    });

  }

  initGroup(){

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    this.clientArray = [];
    this.getClientInfo();
    this.getProducts();
    this.getInfo();

    // Form element defined below
    this.employeeDependency = this.fb.group({
      file: new FormControl(''),
      fileSource: new FormControl(''),
      active_member_data_report_date: new FormControl(''),
      select_product: new FormControl(''),
      select_policy: new FormControl(''),
      client_id: new FormControl('')
    });

  }

  getClientInfo(){
    this.employeedependencyService.getClientInfo()
        .subscribe((result: any) => {          
          
          // console.log(result);
          this.clientArray = result.data;
          
          for(var i = 0; i < this.clientArray.length; i++){

            let html = `
              <option value="`+this.clientArray[i]['client_id']+`">`+this.clientArray[i]['username']+' - '+this.clientArray[i]['company_name']+`</option>
            `;
  
            $('#client_id').append(html);
  
          }

        }, (error) => {          
          
          this.clientArray = [];
          
      });
  }

  getProducts(){
    this.employeedependencyService.getProducts()
      .subscribe((result: any) => {

        // console.log(result);
        this.productsArr = result;

        for(var i = 0; i < result.length; i++){

          let html = `
            <option value="`+result[i]['product_id']+`">`+result[i]['product_name']+`</option>
          `;

          $('#select_product').append(html);

        }

      }, (error) => {
          // console.log(result);
    });

  }

  getClient(){
    
    console.log($('#client_id').val());
    var id = $('#client_id').val();
    // this.getProducts(id);

  }

  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "user_role": this.user_role,
      "client_id": localStorage.getItem('client_id'),
    };

    this.employeedependencyService.getInfo(postdata)
      .subscribe((result: any) => {

        this.show = false;

        // console.log(result);
        this.InfoArray = result.data; 
        this.dtTrigger.next();

      }, (error) => {
          // console.log(result);
    });
  }

  openPopupView(res: any){          

    this.displayStyle_view = "block";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "emp_id":res.emp_id,
      "user_role": this.user_role,
      "client_id": localStorage.getItem('client_id'),
    };

    this.employeedependencyService.InfoView(postdata)
      .subscribe((result: any) => {
        
        this.InfoArray_dependency = result.data; 
        this.dtTrigger2.next();       

      }, (error) => {          
               
    });

  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){             

    this.displayStyle_edit = "block";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.emp_id
    };

    this.employeedependencyService.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['emp_code_edit'].setValue(result.data[0]['emp_code']);
        this.EditInfo.controls['emp_name_edit'].setValue(result.data[0]['emp_name']);
        this.EditInfo.controls['emp_date_of_joining_edit'].setValue(result.data[0]['emp_date_of_joining']);
        this.EditInfo.controls['emp_date_of_birth_edit'].setValue(result.data[0]['emp_date_of_birth']);        
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['emp_id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){    

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "emp_id": id,
        "emp_code":this.EditInfo.controls['emp_code_edit'].value,
        "emp_name":this.EditInfo.controls['emp_name_edit'].value,
        "emp_date_of_joining":this.EditInfo.controls['emp_date_of_joining_edit'].value,
        "emp_date_of_birth":this.EditInfo.controls['emp_date_of_birth_edit'].value,
        "client_id":localStorage.getItem('client_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.employeedependencyService.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            this.getInfo();
            this.dtTrigger.unsubscribe();

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  get getemployeeDetailsFormControls() {
    return this.employeeDependency.controls;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  ondownload(val: any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(val == "gmc"){
      link.setAttribute('href', '../../assets/Member Data Sample Files/Active_Memeber_Data_Format_gmc.xlsx');
      link.setAttribute('download', `Active_Memeber_Data_Format_gmc.xls`);
    }
    if(val == "gpa"){
      link.setAttribute('href', '../../assets/Member Data Sample Files/Active_Memeber_Data_Format_gpa.xlsx');
      link.setAttribute('download', `Active_Memeber_Data_Format_gpa.xls`);
    }
    if(val == "gtl"){
      link.setAttribute('href', '../../assets/Member Data Sample Files/Active_Memeber_Data_Format_gtl.xlsx');
      link.setAttribute('download', `Active_Memeber_Data_Format_gtl.xls`);
    }
    if(val == "opd"){
      link.setAttribute('href', '../../assets/Member Data Sample Files/Active_Memeber_Data_Format_opd.xlsx');
      link.setAttribute('download', `Active_Memeber_Data_Format_opd.xls`);
    }
      
    document.body.appendChild(link);
    link.click();
    link.remove();    
  }

  onFileChange(event: any) {

    if (event.target.files.length > 0) {
      this.fileName = event.target.files[0]['name'];
      this.fileData = event.target.files[0];
    }

    // console.log(this.fileName);
    // console.log(this.fileData);

  }

  onSubmit() {        

    this.documentUploadSubmitted = true;
    if (this.documentUploadSubmitted && this.employeeDependency.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var client_id;
      if(this.type_name == "client"){
        client_id = localStorage.getItem('client_id');
      }else{
        client_id = this.employeeDependency.controls['client_id'].value;
      }

      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "filename": this.fileData,
        "active_member_data_report_date": this.employeeDependency.controls['active_member_data_report_date'].value,
        "client_id": client_id,
        "product_id": this.employeeDependency.controls['select_product'].value,
        "client_product_id": this.employeeDependency.controls['select_policy'].value,
      };

      // console.log("postdata",postdata);

      this.employeedependencyService.employeeImport(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            this.show = false;

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            this.validmsg = result.message;
            
            let flag = this;
            setTimeout(function() {
              if(flag.type_name == "client"){
                flag.route.navigate(['/Client-dashboard']);
              }
              if(flag.type_name == "superadmin"){              
                flag.route.navigate(['/SuperAdmin-dashboard']);
              }                         
              if(flag.type_name == "admin"){
                flag.route.navigate(['/Admin-dashboard']);
              }
              flag.employeeDependency.reset();
            }, 2000);   
            

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    } else {
      // validate all form fields
    }
  }

  closePopup() {
    
    this.dtTrigger2.unsubscribe();
    this.displayStyle_view = "none";
    this.displayStyle_edit = "none";
    this.displayStyle_edit_dep = "none";

  }

  onSkip(){
    this.route.navigate(['/Client-dashboard']);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }

  
  //Edit
  get getEditInformationFormControls_dep() {
    return this.EditInfo_dep.controls;
  }

  openPopupEdit_dep(res: any){     
    
    this.displayStyle_edit_dep = "block";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.ed_id
    };

    this.employeedependencyService.InfoSingle_dep(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo_dep.controls['full_name_edit_dep'].setValue(result.data[0]['full_name']);
        this.EditInfo_dep.controls['emp_date_of_birth_edit_dep'].setValue(result.data[0]['date_of_birth']);        
        this.EditInfo_dep.controls['edit_id_dep'].setValue(result.data[0]['ed_id']);        

      }, (error) => {          
              
    });

  }

  onSubmitEdit_dep(){    

    let id = $('#edit_id_dep').val();
  
    this.EditInfoSubmitted_dep = true;
    if (this.EditInfoSubmitted_dep && this.EditInfo_dep.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "ed_id": id,
        "full_name":this.EditInfo_dep.controls['full_name_edit_dep'].value,
        "date_of_birth":this.EditInfo_dep.controls['emp_date_of_birth_edit_dep'].value,
        "client_id":localStorage.getItem('client_id'),
        // "is_active":this.EditInfo_dep.controls['edit_is_active'].value
      };

      this.employeedependencyService.InfoUpdate_dep(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            // console.log(result.data);  
            
            this.show = false;

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            this.getInfo();
            this.dtTrigger.unsubscribe();

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  getpolicylistbyproduct(){

    var client_id;
    if(this.type_name == "client"){
      client_id = localStorage.getItem('client_id');
    }else{
      client_id = this.employeeDependency.controls['client_id'].value;
    }

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),    
      "client_id": client_id,
      "product_id": this.employeeDependency.controls['select_product'].value         
    };

    this.employeedependencyService.policylistbyproduct(postdata)
      .subscribe((result: any) => {
        
        this.policylistbyproductArr = result.data;      

      }, (error) => {          
              
    });

  }

}
