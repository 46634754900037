<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Form Center
                <!-- <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button> -->
            </h3>
            <!-- <hr class="mb-2 mt-4" style="height: 0px"> -->
           
            <!-- Tabs -->
            <div class="col-sm-12 mt-3">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link active w100" id="GMC-tab" data-bs-toggle="tab"
                            data-bs-target="#GMC" type="button" role="tab" aria-controls="GMC"
                            aria-selected="true">Group Health Insurance
                        </button>
                    </li>
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="GPA-tab" data-bs-toggle="tab"
                            data-bs-target="#GPA" type="button" role="tab" aria-controls="GPA"
                            aria-selected="false">Group Personal Accident</button>
                    </li>
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="GTL-tab" data-bs-toggle="tab" data-bs-target="#GTL"
                            type="button" role="tab" aria-controls="GTL" aria-selected="false">Group Term life</button>
                    </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content tabUI2" id="myTabContent2">

                    <div class="tab-pane fade show active" id="GMC" role="tabpanel" aria-labelledby="GMC-tab">            
                            <div class="table-responsive">
                                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100% !important;">
                                    <thead>
                                    <tr style="color: white; background: var(--bs-global-primary);">
                                        <th style="width:5%;padding: 10px;text-align: center !important;">SrNo</th>
                                        <th style="padding: 10px;text-align: center !important;">Policy Name</th>
                                        <th style="padding: 10px;text-align: center !important;">Document Name</th>
                                        <th style="text-align: center !important;padding:10px;">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let group of gmcDoc; let i = index" style="background-color: white;">
                                        <td style="width:5%;padding: 0px 10px;text-align: center !important;">{{i+1}}</td>
                                        <td style="padding: 0px 10px;text-align: center !important;">{{group['policy_name']}}</td>
                                        <td style="padding: 0px 10px;text-align: center !important;">{{group['document_name']}}</td>
                                        <td style="padding: 0px 10px;text-align: center !important;">
                                            <img src="../../../assets/img/common icons/download.png" id="document" (click)="ondownload_doc(group['document_files'])" role="button" title="Download" />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                    </div>

                    <div class="tab-pane fade" id="GPA" role="tabpanel" aria-labelledby="GPA-tab">
                        
                        <div class="table-responsive">
                            <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100% !important;">
                                <thead>
                                <tr style="color: white; background: #00acb1;">
                                    <th style="width:5%;padding: 10px;text-align: center !important;">SrNo</th>
                                    <th style="padding: 10px;text-align: center !important;">Policy Name</th>
                                    <th style="padding: 10px;text-align: center !important;">Document Name</th>
                                    <th style="text-align: center !important;padding:10px;">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of gpaDoc; let i = index" style="background-color: white;">
                                    <td style="width:5%;padding: 0px 10px;text-align: center !important;">{{i+1}}</td>
                                    <td style="padding: 0px 10px;text-align: center !important;">{{group['policy_name']}}</td>
                                    <td style="padding: 0px 10px;text-align: center !important;">{{group['document_name']}}</td>
                                    <td style="padding: 0px 10px;text-align: center !important;">
                                        <img src="../../../assets/img/common icons/download.png" id="document" (click)="ondownload_doc(group['document_files'])" role="button" title="Download" />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            
                        </div>

                    </div>

                    <div class="tab-pane fade" id="GTL" role="tabpanel" aria-labelledby="GTL-tab">
                        
                        <div class="table-responsive">
                            <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100% !important;">
                                <thead>
                                <tr style="color: white; background: #C8535E;">
                                    <th style="width:5%;padding: 10px;text-align: center !important;">SrNo</th>
                                    <th style="padding: 10px;text-align: center !important;">Policy Name</th>
                                    <th style="padding: 10px;text-align: center !important;">Document Name</th>
                                    <th style="text-align: center !important;padding:10px;">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of gtlDoc; let i = index" style="background-color: white;">
                                    <td style="width:5%;padding: 0px 10px;text-align: center !important;">{{i+1}}</td>
                                    <td style="padding: 0px 10px;text-align: center !important;">{{group['policy_name']}}</td>
                                    <td style="padding: 0px 10px;text-align: center !important;">{{group['document_name']}}</td>
                                    <td style="padding: 0px 10px;text-align: center !important;">
                                        <img src="../../../assets/img/common icons/download.png" id="document" (click)="ondownload_doc(group['document_files'])" role="button" title="Download" />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>