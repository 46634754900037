import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ServiceEmployee } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-family-contruct',
  templateUrl: './family-contruct.component.html',
  styleUrls: ['./family-contruct.component.css']
})
export class FamilyContructComponent implements OnInit {

  json:any = [{
    title: "Family Construct",
    sub_title: "Self",
    description: "IPD Sum Incured",
    price: "600000",
    imageURL: "Group 3.png",
    linktext: "Product Features",
    linkURL: "",
    label: "Group Mediclaim Policy"    
  },
  {
    title: "Family Construct",
    sub_title: "Self",
    description: "IPD Sum Incured",
    price: "600000",
    imageURL: "Group 3.png",
    linktext: "Product Features",
    linkURL: "",
    label: "Group Mediclaim Policy"    
  },
  {
    title: "Family Construct",
    sub_title: "Self",
    description: "IPD Sum Incured",
    price: "600000",
    imageURL: "Group 3.png",
    linktext: "Product Features",
    linkURL: "",
    label: "Group Mediclaim Policy"    
  },
  {
    title: "Family Construct",
    sub_title: "Self",
    description: "IPD Sum Incured",
    price: "600000",
    imageURL: "Group 3.png",
    linktext: "Product Features",
    linkURL: "",
    label: "Group Mediclaim Policy"    
  }];
  
  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  
  constructor(private fb: FormBuilder, private dataservice: ServiceEmployee, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){
    this.getInfo();

    // setInterval(() => {
    //   this.getInfo();
    // }, 10000);
  }

  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "dashboard_id":localStorage.getItem('dashboard_id'),
    }
    
    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 

        }, (error) => {          
          
          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

          this.InfoArray = [];
          
      });
  }

}
