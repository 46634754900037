import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientCreationService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  Step1_store(data: any, token: any): Observable<any> {

    var URL;
    if(data.master_policies_id == 0){
      URL = 'masterpolicy/step1-store';
    }else{
      URL = 'masterpolicy/step1-update/' + data.master_policies_id;
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + URL,
      data
    , httpOptions);
  }

  Step2_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step2-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step3_update(data: any, token: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step3-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step4_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step4-update/' + data.master_policies_id,
      data
    , httpOptions);
  }    

  /************* ALL GET *************/

  getRelation(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterrelations-list',
    httpOptions);
  }
  
  getemployeepolicies(data: any, token: any): Observable<any> {    

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getemployeepolicies',
      data
    , httpOptions);
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'tpa-details/show/' + data.id,
      data
    , httpOptions);
  }

  getRelations(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterrelations-list', httpOptions);
  }

  getemployeepoliciesmembers(data: any, token: any): Observable<any> {    

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getemployeepoliciesmembers',
      data
    , httpOptions);
  }

  submitnominee(data: any, token: any): Observable<any> {    

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getemployeepoliciesmembers',
      data
    , httpOptions);
  }

}
