import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service'

@Component({
  selector: 'app-flex-report',
  templateUrl: './flex-report.component.html',
  styleUrls: ['./flex-report.component.css']
})
export class FlexReportComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  
  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  public DeleteInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayClient: any = [];   
  policy_type:any = [];   
  policynumbers: any = [];   

  document_file: any;
  downloadbuttontooltip:any;
  samplefile: any;
  samplefileName: any;
  Policyuploaddoclist:any = [];
  addflag: any = 0;
  imgpath: any;

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  displayStyleDelete = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Flex Report';

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click()

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup(){

    this.getcompanyDetails();
    this.assignrights();

    this.basicInfo = this.fb.group({
      client: new FormControl(null, Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_name: new FormControl('', Validators.required),
      document_file: new FormControl(''),
    });

    this.EditInfo = this.fb.group({      
      wellness_partner_edit: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      document_file: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getsamplefile(){


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.basicInfo.controls['policy_name'].value,
      "policy_type_id":this.basicInfo.controls['policy_type'].value,
      "employer_id":this.basicInfo.controls['client'].value,
    }
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.samplefile=result.data['flex_report'];
          this.samplefileName=result.data['flex_file'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.InfoArrayClient = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

  getpolicytype(){

    $("#download_sample_file").css("display", "none");
    
    setTimeout(()=>{
      $('#policy_type').val('');
      $('#policy_name').val('');
    },1000);

    this.getPolicyuploaddoclist();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['client'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    setTimeout(()=>{
      $('#policy_name').val('');
    },1000);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['client'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  ondownload(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    // if(val == "policyfeatures"){
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', this.samplefileName);
    // }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  uploadfile(event: any){
    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }

    this.service.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          // this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  getPolicyuploaddoclist(){    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":this.basicInfo.controls['client'].value,
    }
    this.service.getPolicyuploaddoclist(postdata)
      .subscribe((result) => {

        this.Policyuploaddoclist = result.data;

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }, (error) => {
          // console.log(result);
          this.Policyuploaddoclist = [];
    });
  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.error_filename,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });    

  }

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  } 

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

}
