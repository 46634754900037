import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-single-plan',
  templateUrl: './single-plan.component.html',
  styleUrls: ['./single-plan.component.css']
})
export class SinglePlanComponent implements OnInit {

  constructor(private route: Router) {
    this.route.events.subscribe((e) => {
      
    });
  }

  ngOnInit(): void {
    this.route.navigate(['/flex-plan-single']);
  }

}
