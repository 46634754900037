import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientCreationService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }
  
  onSubmitFirst(data: any, token: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("policy_id",data.policy_id);
    filedata.append("claim_id",data.claim_id);

    filedata.append("doc_name",data.document);
    filedata.append("doc_file",data.document_file);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + "claimpolicy/claimuploaddocument/" + data.policy_id,
      filedata
    , httpOptions);  
  }

  /********** Get API **********/
    
  // Dropdown 1
  getdropdown(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/doclistbypolicyid/' + data.policy_id,
      data
    , httpOptions);
  }

  // Listing
  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/claimuploaddoclist/' + data.policy_id,
      data
    , httpOptions);
  }  

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/show/' + data.id, 
      data
    , httpOptions);
  }

  InfoDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/destroy/' + data.doc_id,
      data
    , httpOptions);
  }

}