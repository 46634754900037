<footer class="footer">
    <div style="text-align: right;">
        <!-- <span class="text-muted text-center text-sm-left d-block d-sm-inline-block"></span> -->        
        Copyright ©️ {{year}} 
        <span *ngIf="brokername == '' || brokername == null || brokername == undefined">
            <b>Neologismtech</b>
        </span>
        <span *ngIf="brokername != '' || brokername != null || brokername != undefined">
            <b>{{brokername}}</b>
        </span>
         | All rights reserved
    </div>
</footer>