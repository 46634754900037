import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MarketDataService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  employeeImport(data: any): Observable<any> {

    // console.log(data);
    var fileData = new FormData();
    fileData.append("filename",data.filename);
    fileData.append("tokenable_id",data.tokenable_id);
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'market-competitor/temp-import',
    fileData
    , httpOptions);
  }

  marketEdit(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'market-competitor/show/' + data.id, 
      data
    , httpOptions);
  }

  marketDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'market-competitor/destroy/' + data.id,
      data
    , httpOptions);
  }

  marketUpdate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'market-competitor/update/' + data.id,
      data
    , httpOptions);
  }

  getMarketInfo(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'market-competitor', 
      httpOptions);
  }

}