import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  getcompanyDetails(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise',
      data
    , httpOptions);
  }

  getpolicytype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }

  getpolicynumbers(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }

  /******* Tab Remove ******/
  getsamplefile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'policylockin/samplexcelfile/'+data.master_policies_id,
      data
    , httpOptions);
  }

  uploadsamplefile(data: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("master_policies_id",data.master_policies_id);
    filedata.append("policy_member_data",data.policy_member_data);
    filedata.append("client_id",data.client_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("lockin_type",data.lockin_type);
    filedata.append("sub_type_id",data.sub_type_id);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };    
    return this.http.post(this.AUTH_API + 'policylockin/uploadexcelfile/'+data.master_policies_id,
    filedata
    , httpOptions);
  }

  /****** Tab correction ********/
  getsamplefile_corrections(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/correctionfile/'+data.master_policies_id,
      data
    , httpOptions);
  }

  uploadsamplefile_corrections(data: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("master_policies_id",data.master_policies_id);
    filedata.append("policy_member_data",data.policy_member_data);
    filedata.append("client_id",data.client_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("lockin_type",data.lockin_type);
    filedata.append("sub_type_id",data.sub_type_id);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };    
    return this.http.post(this.AUTH_API + 'memberpolicy/endocorrectionuploadexcelfile/'+data.master_policies_id,
    filedata
    , httpOptions);
  }

  getPolicyuploaddoclist(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'policylockin/policyuploaddoclist',
      data
    , httpOptions);
  }  

  downloadfailedDcoument(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'policylockin/errorexcelfile/' + data.policyid,
      data
    , httpOptions);
  }

  getPopupData(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/getendorsementdetails',
      data
    , httpOptions);
  }

  submitPopup(data: any): Observable<any> {

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("policyid",data.policyid);
    filedata.append("uploadid",data.uploadid);
    filedata.append("endorsement_no",data.endorsement_no);
    filedata.append("insurer_endorsement_no",data.insurer_endorsement_no);
    filedata.append("endorsement_file",data.popup_document_file);

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/finalizeendorsementdetails',
      filedata
    , httpOptions);
  }

  typelistinfo(data: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'policylockin/typelistinfo',
    data,
    httpOptions);
  }

}
