<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3">
      
      <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

      <h3 class="bs-title">Dash<span class="spanbs-title">board</span></h3>
      <hr class="mt-0" style="height: 0px">
      
    </div>
    <app-footer></app-footer>
  </div>

</div>