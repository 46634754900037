<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

          <h3 class="bs-title">Intimate<span class="spanbs-title"> Claim</span></h3>
          <hr class="mb-2 mt-4" style="height: 0px">

          <!-- Add -->
          <div class="row">

              <!-- Filters -->
              <form [formGroup]="basicInfo">

                <div class="row">

                  <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="policy_number">Policy <span class="redstar">*</span></label>
                        <select class="form-select" formControlName="policy_number" id="policy_number" (change)="getMembers()">
                            <option value="" selected>Select Policy</option>
                            <option *ngFor="let type of policynumbers" [value]="type.id">{{ type.policy_name }} / {{ type.policy_number }}</option>
                        </select>
                        <small class="error policy_number_error" style="display: none;">Policy is required</small>
                    </div>
                  </div>

                </div>
              </form>

              <!-- Tabs -->
              <div class="col-sm-12 mt-4">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab" role="tablist" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 10px 10px 0px 0px;">
                  <li class="nav-item liwidth" role="presentation">
                      <button class="nav-link active w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add" type="button"
                          role="tab" aria-controls="home" aria-selected="true">Reimbursement</button>
                  </li>
                  <li class="nav-item liwidth" role="presentation">
                      <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" data-bs-target="#Remove" type="button"
                          role="tab" aria-controls="Remove" aria-selected="false">Cashless</button>
                  </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content" id="myTabContent" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;">

                  <!-- Tab Add -->
                  <div class="tab-pane fade show active" id="Add" role="tabpanel" aria-labelledby="Add-tab">

                    <form [formGroup]="reimbursement">

                        <div class="row">
                            <h5> <b> Reimbursement Claim </b> </h5> <hr>
                          <div class="row mt-2">
  
                            <div class="col-sm-3">
                              <div class="form-group">
                                  <label class="bs-form-label" for="patient_name">Patient Name <span class="redstar">*</span></label>
                                  <select class="form-select" formControlName="patient_name" id="patient_name_reimbursment" (change)="getDetailsReimbursment()"
                                  [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.patient_name.errors}"
                                  required>
                                      <option value="">Select Patient</option>
                                      <option *ngFor="let employee of MemberArray" [value]="employee.mid">{{employee.first_name}} {{employee.last_name}}</option>
                                  </select>
                                  <ng-container
                                  *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.patient_name.errors">
                                  <p class="error"
                                  *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.patient_name.errors.required">
                                  Patient Name is required
                                  </p>
                                </ng-container>
                              </div>
                          </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="mobile_no">Mobile Number <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="mobile_no" id="mobile_no" placeholder="Mobile Number" maxlength="10" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.mobile_no.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.mobile_no.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.mobile_no.errors.required">
                                    Mobile Number is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>
  
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="email_id">Email <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="email_id" id="email_id" placeholder="Email"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.email_id.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.email_id.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.email_id.errors.required">
                                  Email is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="doctor_name">Doctor Name <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="doctor_name" id="doctor_name" placeholder="Doctor Name"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.doctor_name.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.doctor_name.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.doctor_name.errors.required">
                                    Doctor Name is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_name">Hospital Name <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="hospital_name" id="hospital_name" placeholder="Hospital Name"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.hospital_name.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.hospital_name.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.hospital_name.errors.required">
                                    Hospital Name is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_address">Hospital Address <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="hospital_address" id="hospital_address" placeholder="Hospital Address"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.hospital_name.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.hospital_address.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.hospital_address.errors.required">
                                    Hospital Address is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="pincode">Pincode <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="pincode" id="pincode" placeholder="Pincode" maxlength="6" (keypress)="isNumber($event)" (blur)="cityDetailsReimbursement($event)"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.pincode.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.pincode.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.pincode.errors.required">
                                   Pincode is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="state">State<span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="state" id="state" placeholder="State"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.state.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.state.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.state.errors.required">
                                   State is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="city">City <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="city" id="city" placeholder="City"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.city.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.city.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.city.errors.required">
                                   City is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="reason">Reason For Hospitalization <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="reason" id="reason" placeholder="Addmitted For"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.reason.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.reason.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.reason.errors.required">
                                    Reason For Hospitalization is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="amount_istimated">Claim Amount Istimated <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="amount_istimated" id="amount_istimated" placeholder="Claim Amount Istimated" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.amount_istimated.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.amount_istimated.errors">
                                    <p class="error"
                                    *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.amount_istimated.errors.required">
                                    Claim Amount is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="remarks">Remarks</label>
                                    <input class="form-control" formControlName="remarks" id="remarks" placeholder="Remarks" />
                                </div>
                            </div>

                          </div>
  
                        <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="date_of_addmission">Date of Addmission <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="date_of_addmission" id="date_of_addmission"
                                [ngClass]="{'control-red': reimbursementSubmitted && getreimbursementInformationFormControls.date_of_addmission.errors}"
                                required />
                                <ng-container
                                *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.date_of_addmission.errors">
                                <p class="error"
                                *ngIf="reimbursementSubmitted && getreimbursementInformationFormControls.date_of_addmission.errors.required">
                                Date of Addmission  is required
                                </p>
                              </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="discharge_date">Planned Discharge Date</label>
                                <input type="date" class="form-control" formControlName="discharge_date" id="discharge_date" />
                            </div>
                        </div>
  
                        </div>
  
                        <div class="col-sm-12" align="right">
                          <button type="button" class="btn btn-primary mt-3" (click)="onSubmitReimbursement()" role="button">Save</button>
                        </div>
  
                      </form>
                    
                  </div>

                  <!-- Tab Remove -->
                  <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab">

                    <form [formGroup]="addInfo">

                        <div class="row">
                            <h5> <b> Cashless Claim </b> </h5> <hr>

                          <div class="row mt-2">
  
                            <div class="col-sm-3">
                              <div class="form-group">
                                  <label class="bs-form-label" for="patient_name">Patient Name <span class="redstar">*</span></label>
                                  <select class="form-select" formControlName="patient_name" id="patient_name" (change)="getDetails()"
                                  [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.patient_name.errors}"
                                  required>
                                      <option value="">Select Patient</option>
                                      <option *ngFor="let employee of MemberArray" [value]="employee.mid">{{employee.first_name}} {{employee.last_name}}</option>
                                  </select>
                                  <ng-container
                                  *ngIf="addInfoSubmitted && getaddInformationFormControls.patient_name.errors">
                                  <p class="error"
                                  *ngIf="addInfoSubmitted && getaddInformationFormControls.patient_name.errors.required">
                                  Patient Name is required
                                  </p>
                                </ng-container>
                              </div>
                          </div>
  
                              <div class="col-sm-3">
                                  <div class="form-group">
                                      <label class="bs-form-label" for="mobile_no">Mobile Number <span class="redstar">*</span></label>
                                      <input class="form-control" formControlName="mobile_no" id="mobile_noCashless" placeholder="Mobile Number" maxlength="10" (keypress)="isNumber($event)"
                                      [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.mobile_no.errors}"
                                      required />
                                      <ng-container
                                      *ngIf="addInfoSubmitted && getaddInformationFormControls.mobile_no.errors">
                                      <p class="error"
                                      *ngIf="addInfoSubmitted && getaddInformationFormControls.mobile_no.errors.required">
                                      Mobile Number is required
                                      </p>
                                    </ng-container>
                                  </div>
                              </div>
    
                              <div class="col-sm-3">
                                  <div class="form-group">
                                      <label class="bs-form-label" for="email_id">Email<span class="redstar">*</span></label>
                                      <input class="form-control" formControlName="email_id" id="email_idCashless" placeholder="Email"
                                      [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.email_id.errors}"
                                      required />
                                      <ng-container
                                      *ngIf="addInfoSubmitted && getaddInformationFormControls.email_id.errors">
                                      <p class="error"
                                      *ngIf="addInfoSubmitted && getaddInformationFormControls.email_id.errors.required">
                                     Email is required
                                      </p>
                                    </ng-container>
                                  </div>
                              </div>
  
                              <div class="col-sm-3">
                                  <div class="form-group">
                                      <label class="bs-form-label" for="doctor_name">Doctor Name <span class="redstar">*</span></label>
                                      <input class="form-control" formControlName="doctor_name" id="doctor_name" placeholder="Doctor Name"
                                      [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.doctor_name.errors}"
                                      required />
                                      <ng-container
                                      *ngIf="addInfoSubmitted && getaddInformationFormControls.doctor_name.errors">
                                      <p class="error"
                                      *ngIf="addInfoSubmitted && getaddInformationFormControls.doctor_name.errors.required">
                                      Doctor Name is required
                                      </p>
                                    </ng-container>
                                  </div>
                              </div>

                              <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_name">Hospital Name <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="hospital_name" id="hospital_name" placeholder="Hospital Name"
                                    [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.hospital_name.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_name.errors">
                                    <p class="error"
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_name.errors.required">
                                    Hospital Name is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                              <div class="form-group">
                                  <label class="bs-form-label" for="pincode">Pincode <span class="redstar">*</span></label>
                                  <input class="form-control" formControlName="pincode" id="pincodeCashless" placeholder="Pincode" maxlength="6" (keypress)="isNumber($event)" (blur)="cityDetaitsCashless($event)"
                                  [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.pincode.errors}"
                                  required />
                                  <ng-container
                                  *ngIf="addInfoSubmitted && getaddInformationFormControls.pincode.errors">
                                  <p class="error"
                                  *ngIf="addInfoSubmitted && getaddInformationFormControls.pincode.errors.required">
                                 Pincode is required
                                  </p>
                                </ng-container>
                              </div>
                          </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_state">Hospital State <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="hospital_state" id="hospital_state" placeholder="Hospital State"
                                    [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.hospital_state.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_state.errors">
                                    <p class="error"
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_state.errors.required">
                                    Hospital State is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_city">Hospital City <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="hospital_city" id="hospital_city" placeholder="Hospital City"
                                    [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.hospital_city.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_city.errors">
                                    <p class="error"
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_city.errors.required">
                                    Hospital City is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_reason">Reason For Hospitalization <span class="redstar">*</span></label>
                                    <input class="form-control" formControlName="hospital_reason" id="hospital_reason" placeholder="Reason For Hospitalization "
                                    [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.hospital_reason.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_reason.errors">
                                    <p class="error"
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_reason.errors.required">
                                    Reason For Hospitalization  is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_addmission">Planned Addmission Date <span class="redstar">*</span></label>
                                    <input type="date" class="form-control" formControlName="hospital_addmission" id="hospital_addmission"
                                    [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.hospital_addmission.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_addmission.errors">
                                    <p class="error"
                                    *ngIf="addInfoSubmitted && getaddInformationFormControls.hospital_addmission.errors.required">
                                    Reason For Hospitalization  is required
                                    </p>
                                  </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_discharge_date">Planned Discharge Date</label>
                                    <input type="date" class="form-control" formControlName="hospital_discharge_date" id="hospital_discharge_date" />
                                </div>
                            </div>
  
                            <div class="col-sm-3 mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="hospital_file">Hospital File</label>
                                    <input class="form-control" formControlName="hospital_file" id="hospital_file" placeholder="File No." />
                                </div>
                            </div>
                          </div>
  
                          <!-- <div class="row mt-2">
  
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label class="bs-form-label" for="document_file_add">Attached Sample File</label>
                                <label for="document_file_add" id="features-file-upload_document_file_add" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                </label>
                                <input id="document_file_add" type="file" (change)="uploadfile_add($event)" formControlName="document_file_add" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                              </div>
                              <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file_add">Upload only .xlsx, .xls, .csv files</small>
                            </div>
  
                            <div class="col-sm-3" id="download_sample_file_add" style="text-align: center;margin-top:13px;display:none">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for=""></label>
                                    <button (click)="ondownload('add')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                                </div>
                            </div>
  
                          </div> -->
  
                        </div>
  
                        <div class="col-sm-12" align="right">
                          <button type="button" class="btn btn-primary mt-3" (click)="onSubmitCashless()" role="button">Save</button>
                        </div>
  
                      </form>

                  </div>

                  </div>

                </div>

              </div>
              <!-- End -->

              <!-- Save Popup -->
              <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Savetoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Success!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/check.png" alt="img" style="width: 100px;" />
                            </div>
                            <h3 style="text-align: center;"> Success ! </h3>
                            <h4 style="text-align: center;"> Claim Submitted Successfully. Your Claim Inimation No. {{IntimationId}} </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            </div>

            <app-footer></app-footer>

        </div>
</div>

