import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/register/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public registerForm:FormGroup; 
  validmsg: any;
  registerbtn: any;
  pagehide_status: any;
  displayStyleThankyou = "none";

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private route: Router
    ) { }

  ngOnInit(): void {

    this.initialize();    

  }

  initialize(){

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    $('#registerbtn').prop('disabled', true);

    if (navigator.userAgent.indexOf("Firefox") > 0) {
      $(".div-center").attr('style', 'top: -250px');
    }

    // Form element defined below
    this.registerForm = this.fb.group({
      username: new FormControl('', Validators.required),
      pwd: new FormControl('')
    });

  }

  checkpwd(){

    if(this.registerForm.controls.pwd.value == $('#pwd_confirm').val()){
      $('#registerbtn').prop('disabled', false);      
      $('#validmsg').css('display', 'none');
    }else{
      $('#registerbtn').prop('disabled', true);
      this.validmsg = 'Password do no match';
      $('#validmsg').css('display', 'block');
    }

  }

  register() {
    if (this.registerForm.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      this.authService.register(this.registerForm.controls.username.value, this.registerForm.controls.pwd.value, 3, 1)
        .subscribe((result: any) => {
          if (result.status == true) {
            
            this.show = false;
            // console.log(result);    
            
            this.displayStyleThankyou = "block";

            localStorage.setItem('tokenable_id', result.data.tokenable_id);
            localStorage.setItem('username', this.registerForm.controls.username.value);
            localStorage.setItem('Token', result.data.token);
            localStorage.setItem('resstatus', result.status);

            let postdataObj = {
              "tokenable_id":result.data.tokenable_id
            };

            this.authService.getuserdetails(postdataObj)
            .subscribe((userdata: any) => {                            

                localStorage.setItem('type_name', userdata.data[0]['type_name']);
                localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
                this.pagehide_status = localStorage.setItem('pagehide', 'yes');
                localStorage.setItem('plan_name','Base');
                localStorage.setItem('email_verify', userdata.data[0]['email_verify']);
                // this.route.navigate(['/verify-mobile']);
                // this.route.navigate(['/clientCreation']);
                                
                // localStorage.setItem('client_id', userdata[0]['client_id']);
                // localStorage.setItem('company_name', userdata[0]['company_name']);                                        
              
            });                        

          } else {
            // console.log(result);            
          }
        }, (error) => {
          // console.log(error);
          // console.log(error.error.status);
          // console.log(error.error.message);
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'block');          
        });
    } else {

    }
  }  

  closePopup() {
    this.displayStyleThankyou = "none";
    this.route.navigate(['/verify-mobile']);
  }

}
