import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';
import { numberFormat } from 'highcharts';

interface Ancillary {
  id: number;
  type_id: number;
  insurance_ancillary_description: string;
  insurance_ancillary_logo: string;
  insurance_ancillary_name: string;
  insurance_ancillary_url: string;
  partner_name: string;
}

@Component({
  selector: 'app-car-insurance',
  templateUrl: './car-insurance.component.html',
  styleUrls: ['./car-insurance.component.css']
})

export class CarInsuranceComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');

  ancillaryData: any = [];
  ancillaryDataArray: any = [];

  ngOnInit(): void {
    this.getInfo();
    // $(".bs-title").slideToggle();
  }

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ancillaryName: any;

  getInfo(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),      
      "partner_id":localStorage.getItem('partner_id')      
    };

    this.dataservice.getemployeeAncillary(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {                                 

        this.ancillaryData = result.data.filter((ancillary: Ancillary) => ancillary.type_id === 1);
        this.ancillaryDataArray = result.data.filter((ancillary: Ancillary) => ancillary.type_id === 2);
        this.ancillaryName = result.data[0]['partner_name'];

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  goback(){

    let addFlag: any = localStorage.getItem('addFlag');
    if(addFlag == 1){
      this.route.navigate(['/flex-configurator-dashboard']);
    }else{
      this.route.navigate(['/employeruser-dashboard']);
    }
  }

  goto(url: any){
    window.open(url ,'_blank');
  }

}
