<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <!-- Add -->
            <form [formGroup]="basicInfo" *ngIf="addflag == 0">
                <!-- Tabs -->
                <div class="col-sm-12 mt-4">


                    <!-- Tabs Content -->
                    <div class="tab-content" id="myTabContent"
                        style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;">

                        <!-- Tab Add -->
                        <div class="tab-pane fade show active" id="Add" role="tabpanel" aria-labelledby="Add-tab">
                            <div class="row">

                                <h3 class="bs-title">Enrollment Report</h3>
                                <hr>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="client">Client  <span class="redstar">*</span></label>
                                            <ng-select formControlName ="client" id="client" (change)="getpolicytype()" (change)="getpolicynumbers()"
                                                [items]="InfoArrayClient"
                                                bindLabel="name"
                                                bindValue="id"
                                                [placeholder]="'Select Client'"
                                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.client.errors}">
                                            </ng-select>
                                        <ng-container
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client.errors">
                                                <p class="error"
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client.errors.required">
                                                Client is required
                                                </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_type">Policy Type  <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getpolicynumbers()"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors}"
                                            required>
                                            <option value="">Select Policy Type</option>
                                            
                                                <option  *ngFor="let policyType of policy_type; let i = index" value="{{policyType.sub_type_id}}">
                                                    {{policyType.name}}</option>
                                           
                                        </select>
                                        <ng-container
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors">
                                                <p class="error"
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors.required">
                                                Policy Type is required
                                                </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_name">Policy Name  <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="policy_name" id="policy_name" (change)="getsamplefile()"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors}"
                                            required>
                                            <option value="">Select Policy Name </option>
                                            <option *ngFor="let corporatelist of policynumbers; let i = index" value="{{corporatelist.id}}">
                                                {{corporatelist.policy_number}}</option>
                                        </select>
                                        <ng-container
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors">
                                                <p class="error"
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors.required">
                                                Policy Name is required
                                                </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-12" id="download_sample_file" style="text-align: center;margin-top:13px;display:none" align="center">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for=""></label>
                                        <button (click)="ondownload()" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Enrollment Report</button>
                                    </div>
                                </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    <!-- End -->
            </form>

            <!-- <hr *ngIf="addflag == 0"> -->

            <!-- List -->
            <!-- <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Client</th>
                            <th>Policy No.</th>
                            <th>Policy Name</th>
                            <th>Uploaded Status</th>
                            <th>Original Document</th>
                            <th>Failed Document</th>                      
                            <th>Success</th>
                            <th>Uploaded At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of Policyuploaddoclist; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['company_name']}}</td>
                            <td>{{group['policy_number']}}</td>
                            <td>{{group['policy_name']}}</td>
                            <td>
                              <p style="color: #0382cc; font-size: 13px;">
                                <i class="mdi mdi-account"></i>
                                Total Uploaded: {{group['total_count']}} 
                                <span *ngIf="group['total_count'] == 1">Row</span>
                                <span *ngIf="group['total_count'] != 1">Rows</span>
                              </p>
                              <p style="color: #32cc03; font-size: 13px;">
                                <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                                Added Successfully: {{group['sucess_count']}} 
                                <span *ngIf="group['sucess_count'] == 1">Row</span>
                                <span *ngIf="group['sucess_count'] != 1">Rows</span>
                              </p>
                              <p style="color: #f03800; font-size: 13px;" *ngIf="group['fail_count'] >= 1">
                                <i class="mdi mdi-close"></i>
                                Failed To Add: {{group['fail_count']}} 
                                <span *ngIf="group['fail_count'] == 1">Row</span>
                                <span *ngIf="group['fail_count'] != 1">Rows</span>
                              </p>                        
                            </td>
                            <td>
                              <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['original_document'])" style="cursor: pointer" />                        
                            </td>
                            <td>
                              <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                              <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'])" style="cursor: pointer" *ngIf="group['fail_count'] >= 1" />                        
                            </td>                      
                            <td>
                              <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                              <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                              <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                            </td>
                            <td>{{group['uploaded_at']}}</td>          
                        </tr>
                    </tbody>
                </table>
            </div> -->


            

           

        </div>
        <app-footer></app-footer>
    </div>
</div>