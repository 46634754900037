<div id="stepper2" class="bs-stepper bg-white my-4 mb-5 rounded p-3 mainstepper_div">
    
    <div class="flagDiv">
        
        <span class="plan_step3">OPD</span>

        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Product Name</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Policy Details</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Policy Features</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">4</span>
                    <span class="bs-stepper-label">Document Upload</span>
                </button>
            </div>
        </div>
    </div>

    <div class="progressroot">
        <div [ngClass]="{'step1': stepcomplete === 'step1'}"></div>
        <div [ngClass]="{'step2': stepcomplete === 'step2'}"></div>
        <div [ngClass]="{'step3': stepcomplete === 'step3'}"></div>
        <div [ngClass]="{'step4': stepcomplete === 'step4'}"></div>
    </div>
    
    <div class="bs-stepper-content">

            <div id="test-l-1" class="content">                
                <form [formGroup]="policyDetails">
                    <h3 class="bs-title">Product Name </h3>
                    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    <hr>
                    <div class="row">                                                                                             

                        <!--------Section 3------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label">Policy Details</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8 row">                            

                            <div class="form-group">
                                <div class="mb-2 bs-form-label">Benefit Structure <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <input type="radio" class="form-radio" value="manually" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Enter Manually </span>                            
                                &nbsp;
                                <input type="radio" class="form-radio" value="policy_template" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Upload Excel Template </span>                                                        
                                &nbsp;
                                <input type="radio" class="form-radio" value="existing_doc" formControlName="upload_check" id="upload_check" style="pointer-events:none;" /> <span class="bs-radio-label" style="color:#AAAAAA"> Upload Policy PDF </span>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadPolicyDetails()">Download Sample Template</span>                                                                        
                            </div>

                            <div class="form-group mt-3">
                                <div class="mb-2 bs-form-label"> OPD program managed through? <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <input type="radio" class="form-radio" value="Insurance company" formControlName="OPD_program" id="OPD_program" /> <span class="bs-radio-label"> Insurance company </span>                            
                                &nbsp;
                                <input type="radio" class="form-radio" value="Vendor" formControlName="OPD_program" id="OPD_program" /> <span class="bs-radio-label"> Vendor </span>                                                                                        
                            </div>
                            

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="insurance_company">Insurance company <span class="redstar">*</span></label>
                                <!-- <select class="form-select" formControlName="insurance_company" id="insurance_company" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                required >
                                    <option value="" selected>Select</option>
                                </select>                            
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container> -->

                                <ng-select formControlName="insurance_company" id="insurance_company" 
                                            [items]="isuranceCompanyDetailsarr" 
                                            bindLabel="name" 
                                            bindValue="insure_comp_id"
                                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                            required>                                    
                                </ng-select>
                                 <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container>
                            </div>                                                   

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="broker_name">Broker Name <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="broker_name" id="broker_name" style="line-height: 1.7;"
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors">
                                    <p class="error">
                                    Broker Name is required
                                    </p>
                                </ng-container>
                            </div>                        
                                                
                            <div class="form-group mt-3" id="div_upload_policydocument" style="display: none">
                                <label class="bs-form-label" for="upload_policydocument">Upload policy document</label>
                                <label for="file" id="custom-file-upload_upload_policydocument" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_policydocument" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                </label>
                                <input id="file" type="file" (change)="upload_policydocument_change($event)" enctype="multipart/form-data" accept=".xlsx, .xls, .csv, application/pdf" style="display:none" />
                                <small style="font-size: 12px; color:#605D62" id="small_upload_policydocument">Upload only .xlxs, .xls files (Max size 4MB)</small>

                            </div>
                        </div>
                        <hr class="my-3">
                        <!--------END------->

                        <div>
                            <button (click)="next(1)" id="manual_flow_save" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="next(1)" id="excel_flow_save" class="btn btn-primary" style="float: right;display:none;">Upload</button>
                        </div>
                    
                    </div>
                    
                </form>
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="verifypolicyDetails">
                <h3 class="bs-title">Policy Details</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Policy Details</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6 form-group policy_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="policy_date">Policy inception date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="policy_date" id="policy_date" (change)="loadecpirydate()"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors">
                                    <p class="error">
                                    Policy inception date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group how_soon_buy">
                                <label class="bs-form-label" for="how_soon_buy">How soon you planning to buy <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="how_soon_buy" id="how_soon_buy" (change)="how_soon_buy_check($event)"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Immediatly">Immediatly</option>
                                    <option value="Within a month">Within a month</option>
                                    <option value="Specific month">Specific month</option>
                                    <option value="Not yet decided">Not yet decided</option>
                                </select>                            
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors">
                                    <p class="error">
                                    This is required field
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group step2hideFields">
                                <label class="bs-form-label" for="policy_expirydate">Policy expiry date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="policy_expirydate" id="policy_expirydate"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 data_report_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="data_report_date">Data report date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="data_report_date" id="data_report_date"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors">
                                    <p class="error">
                                    Data report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group choose_month" style="display:none">
                                <label class="bs-form-label" for="choose_month">Choose month <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="choose_month" id="choose_month"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors}"
                                required>
                                    <option value="0" selected>Select</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 step2hideFields">
                                <label class="bs-form-label" for="policy_number">Policy number <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="340100502022000275"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors">
                                    <p class="error">
                                    Policy number is required
                                    </p>
                                </ng-container>
                            </div>                                                        
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-sm-4">
                            <div class="p-5 px-0 pb-0 step2hideFields">
                                <label class="bs-form-label" style="color:#000">Lives at Inception</label>
                            </div>
                            <div class="p-5 px-0 pt-3 step2hideFields">
                                <label class="bs-form-label" style="position: relative; top: 30px;color:#000">Lives at Renewal</label>
                            </div>
                        </div>
                        <div class="row col-sm-8 p-0 m-0">
                            <div class="col-sm-6 form-group emp_atstart" style="margin-top: 16px !important;"> 
                                <label class="bs-form-label" for="emp_atstart">No of employees <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="emp_atstart" id="emp_atstart" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors">
                                    <p class="error">
                                    No of employees at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group lives_covered mt-3 step2hideFields" style="display:none">
                                <label class="bs-form-label" for="lives_covered">No of total lives <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="lives_covered" id="lives_covered" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors">
                                    <p class="error">
                                    No of lives covered at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="emp_atreport">No of employees <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="emp_atreport" id="emp_atreport" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors">
                                    <p class="error">
                                    No of employees as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="lives_atreport">No of total lives <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="lives_atreport" id="lives_atreport" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors">
                                    <p class="error">
                                    No of lives covered as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>                

                </form>
            </div>

            <div id="test-l-3" class="content">

                <!--------- Banner ---------->
                <div class="row">
                    <div class="col-sm-6">
                        <img src="../../assets/img/policy/policybanner.png" class="img-fluid" alt="Img" style="display:block; margin:auto; width:300px" />
                    </div>
                    <div class="col-sm-6 textm">
                        <h3 class="bs-title alignc">Policy Features</h3>
                        <p class="bs-p-normal alignc"> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                            Nam non metus id augue tempor volutpat sit amet sit amet nunc.
                        </p>
                    </div>
                </div>
                <hr class="my-4">
                <!--------- END ------------>

                <form [formGroup]="policyFeatures">                            

                <!---- Family Contruct Accordian ---->
                <h5 class="bs-left-form-label accordheading SIheading" (click)="sumisuredaccord()">Family Contruct
                    <img id="sumisured_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="sumisured_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/sum-insured-coverage.png" class="headicon" />
                    <img id="SItick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="SItick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgSI">
                        <div class="value-container" id="ValueSI">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="sumisured_accord" class="accord_div">
                    <div class="row">
                        
                        <label class="bs-form-label">Which family members are eligible?</label>

                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="employees"> Employees <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'employees_div')" (mouseleave)="mouseLeave($event, 'employees_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="employees_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon"> Employees</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_employees" formControlName="employees" name="employees" value="No" (change)="checkradio('divemployees_limit', 'NotCovered')" />
                                    <label for="radio-two_employees" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_employees" formControlName="employees" name="employees" value="Yes" (change)="checkradio('divemployees_limit', 'Covered')" />
                                    <label for="radio-one_employees" class="bs-form-label">Yes</label>                                            
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divemployees_limit" style="display: none">
                                <label class="bs-form-label" for="employees_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="employees_limit" id="employees_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="employees_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="spouse"> Spouse <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'spouse_div')" (mouseleave)="mouseLeave($event, 'spouse_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="spouse_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon"> Spouse</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_spouse" formControlName="spouse" name="spouse" value="No" (change)="checkradio('divspouse_limit', 'NotCovered')" />
                                    <label for="radio-two_spouse" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_spouse" formControlName="spouse" name="spouse" value="Yes" (change)="checkradio('divspouse_limit', 'Covered')" />
                                    <label for="radio-one_spouse" class="bs-form-label">Yes</label>                                            
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divspouse_limit" style="display: none">
                                <label class="bs-form-label" for="spouse_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="spouse_limit" id="spouse_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="spouse_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="child"> Child <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'child_div')" (mouseleave)="mouseLeave($event, 'child_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="child_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon"> Child</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_child" formControlName="child" name="child" value="No" (change)="checkradio('divchild_limit', 'NotCovered')" />
                                    <label for="radio-two_child" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_child" formControlName="child" name="child" value="Yes" (change)="checkradio('divchild_limit', 'Covered')" />
                                    <label for="radio-one_child" class="bs-form-label">Yes</label>                                            
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divchild_limit" style="display: none">
                                <label class="bs-form-label" for="child_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="child_limit" id="child_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="child_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="parents"> Parent's <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'parents_div')" (mouseleave)="mouseLeave($event, 'parents_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="parents_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon"> Parent's</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_parents" formControlName="parents" name="parents" value="No" (change)="checkradio('divparents_limit', 'NotCovered')" />
                                    <label for="radio-two_parents" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_parents" formControlName="parents" name="parents" value="Yes" (change)="checkradio('divparents_limit', 'Covered')" />
                                    <label for="radio-one_parents" class="bs-form-label">Yes</label>                                            
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divparents_limit" style="display: none">
                                <label class="bs-form-label" for="parents_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="parents_limit" id="parents_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="parents_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="sibling"> Sibling <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sibling_div')" (mouseleave)="mouseLeave($event, 'sibling_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="sibling_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Sibling</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_sibling" formControlName="sibling" name="sibling" value="No" (change)="checkradio('divsibling_limit', 'NotCovered')" />
                                    <label for="radio-two_sibling" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_sibling" formControlName="sibling" name="sibling" value="Yes" (change)="checkradio('divsibling_limit', 'Covered')" />
                                    <label for="radio-one_sibling" class="bs-form-label">Yes</label>                                            
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divsibling_limit" style="display: none">
                                <label class="bs-form-label" for="sibling_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="sibling_limit" id="sibling_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="sibling_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->                

                <!---- Additional Benefits Accordian ---->
                <h5 class="bs-left-form-label accordheading additionalbenefitsheading" (click)="Othersaccord()">Additional Benefits
                    <img id="Others_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="Others_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/benefit.png" class="headicon" />
                    <img id="additionalbenefitstick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="additionalbenefitstick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgOthers">
                        <div class="value-container" id="ValueOthers">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="Others_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Others</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">                                

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="prescription_medications"> Prescription Medications <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'prescription_medications_div')" (mouseleave)="mouseLeave($event, 'prescription_medications_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="prescription_medications_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Prescription Medications</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_prescription_medications" formControlName="prescription_medications" name="prescription_medications" value="No" (change)="checkradio('divprescription_medications_limit', 'NotCovered')" />
                                            <label for="radio-two_prescription_medications" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_prescription_medications" formControlName="prescription_medications" name="prescription_medications" value="Yes" (change)="checkradio('divprescription_medications_limit', 'Covered')" />
                                            <label for="radio-one_prescription_medications" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divprescription_medications_limit" style="display: none">
                                        <label class="bs-form-label" for="prescription_medications_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="prescription_medications_limit" id="prescription_medications_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="prescription_medications_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>
                                
                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="consultations"> Consultations <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'consultations_div')" (mouseleave)="mouseLeave($event, 'consultations_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="consultations_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Consultations</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_consultations" formControlName="consultations" name="consultations" value="No" (change)="checkradio('divconsultations_limit', 'NotCovered')" />
                                            <label for="radio-two_consultations" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_consultations" formControlName="consultations" name="consultations" value="Yes" (change)="checkradio('divconsultations_limit', 'Covered')" />
                                            <label for="radio-one_consultations" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divconsultations_limit" style="display: none">
                                        <label class="bs-form-label" for="consultations_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="consultations_limit" id="consultations_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="consultations_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>                                                             						                                                                                            

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="diagnostics_or_health_checkups"> Diagnostics/Health checkups <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'diagnostics_or_health_checkups_div')" (mouseleave)="mouseLeave($event, 'diagnostics_or_health_checkups_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="diagnostics_or_health_checkups_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Diagnostics/Health checkups</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_diagnostics_or_health_checkups" formControlName="diagnostics_or_health_checkups" name="diagnostics_or_health_checkups" value="No" (change)="checkradio('divdiagnostics_or_health_checkups_limit', 'NotCovered')" />
                                            <label for="radio-two_diagnostics_or_health_checkups" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_diagnostics_or_health_checkups" formControlName="diagnostics_or_health_checkups" name="diagnostics_or_health_checkups" value="Yes" (change)="checkradio('divdiagnostics_or_health_checkups_limit', 'Covered')" />
                                            <label for="radio-one_diagnostics_or_health_checkups" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divdiagnostics_or_health_checkups_limit" style="display: none">
                                        <label class="bs-form-label" for="diagnostics_or_health_checkups_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="diagnostics_or_health_checkups_limit" id="diagnostics_or_health_checkups_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="diagnostics_or_health_checkups_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>                                

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="dental"> Dental <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'dental_div')" (mouseleave)="mouseLeave($event, 'dental_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="dental_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Dental</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_dental" formControlName="dental" name="dental" value="No" (change)="checkradio('divdental_limit', 'NotCovered')" />
                                            <label for="radio-two_dental" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_dental" formControlName="dental" name="dental" value="Yes" (change)="checkradio('divdental_limit', 'Covered')" />
                                            <label for="radio-one_dental" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divdental_limit" style="display: none">
                                        <label class="bs-form-label" for="dental_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="dental_limit" id="dental_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="dental_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="vision"> Vision <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'vision_div')" (mouseleave)="mouseLeave($event, 'vision_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="vision_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Vision</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_vision" formControlName="vision" name="vision" value="No" (change)="checkradio('divvision_limit', 'NotCovered')" />
                                            <label for="radio-two_vision" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_vision" formControlName="vision" name="vision" value="Yes" (change)="checkradio('divvision_limit', 'Covered')" />
                                            <label for="radio-one_vision" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divvision_limit" style="display: none">
                                        <label class="bs-form-label" for="vision_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="vision_limit" id="vision_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="vision_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="physiotherapy_hospitalization"> Physiotherapy or any other therapy not related to hospitalization <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'physiotherapy_hospitalization_div')" (mouseleave)="mouseLeave($event, 'physiotherapy_hospitalization_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="physiotherapy_hospitalization_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Physiotherapy or any other therapy not related to hospitalization</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_physiotherapy_hospitalization" formControlName="physiotherapy_hospitalization" name="physiotherapy_hospitalization" value="No" (change)="checkradio('divphysiotherapy_hospitalization_limit', 'NotCovered')" />
                                            <label for="radio-two_physiotherapy_hospitalization" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_physiotherapy_hospitalization" formControlName="physiotherapy_hospitalization" name="physiotherapy_hospitalization" value="Yes" (change)="checkradio('divphysiotherapy_hospitalization_limit', 'Covered')" />
                                            <label for="radio-one_physiotherapy_hospitalization" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divphysiotherapy_hospitalization_limit" style="display: none">
                                        <label class="bs-form-label" for="physiotherapy_hospitalization_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="physiotherapy_hospitalization_limit" id="physiotherapy_hospitalization_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="physiotherapy_hospitalization_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>
                                
                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="psychiatric_treatment"> Psychiatric Treatment/Psychological treatment/Counseling <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'psychiatric_treatment_div')" (mouseleave)="mouseLeave($event, 'psychiatric_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="psychiatric_treatment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Psychiatric Treatment/Psychological treatment/Counseling</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_psychiatric_treatment" formControlName="psychiatric_treatment" name="psychiatric_treatment" value="No" (change)="checkradio('divpsychiatric_treatment_limit', 'NotCovered')" />
                                            <label for="radio-two_psychiatric_treatment" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_psychiatric_treatment" formControlName="psychiatric_treatment" name="psychiatric_treatment" value="Yes" (change)="checkradio('divpsychiatric_treatment_limit', 'Covered')" />
                                            <label for="radio-one_psychiatric_treatment" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divpsychiatric_treatment_limit" style="display: none">
                                        <label class="bs-form-label" for="psychiatric_treatment_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="psychiatric_treatment_limit" id="psychiatric_treatment_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="psychiatric_treatment_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="vaccinations">  Vaccinations <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'vaccinations_div')" (mouseleave)="mouseLeave($event, 'vaccinations_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="vaccinations_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon"> Vaccinations</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_vaccinations" formControlName="vaccinations" name="vaccinations" value="No" (change)="checkradio('divvaccinations_limit', 'NotCovered')" />
                                            <label for="radio-two_vaccinations" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_vaccinations" formControlName="vaccinations" name="vaccinations" value="Yes" (change)="checkradio('divvaccinations_limit', 'Covered')" />
                                            <label for="radio-one_vaccinations" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divvaccinations_limit" style="display: none">
                                        <label class="bs-form-label" for="vaccinations_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="vaccinations_limit" id="vaccinations_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="vaccinations_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="row newothersField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addOthersField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add More Field</span>
                                    </label>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->  
                
                <!---- Policy Restriction Accordian ---->
                <h5 class="bs-left-form-label accordheading waitingheading" (click)="waitingPeriodCondition()">Policy Restriction
                    <img id="WPC_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="WPC_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/waiting.png" class="headicon" />
                    <img id="waitingtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="waitingtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="Progwaiting">
                        <div class="value-container" id="Valuewaiting">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="waitingPeriodCondition_accord" class="accord_div">

                    <div class="row">                        

                        <div class="col-sm-12">
                            <div class="row">
                               
                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="copay_applicable_OPD_program"> Co-pay is applicable in the OPD program <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'copay_applicable_OPD_program_div')" (mouseleave)="mouseLeave($event, 'copay_applicable_OPD_program_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="copay_applicable_OPD_program_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon"> Co-pay is applicable in the OPD program</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_copay_applicable_OPD_program" formControlName="copay_applicable_OPD_program" name="copay_applicable_OPD_program" value="No" (change)="checkradio('divcopay_applicable_OPD_program_limit', 'NotCovered')" />
                                            <label for="radio-two_copay_applicable_OPD_program" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_copay_applicable_OPD_program" formControlName="copay_applicable_OPD_program" name="copay_applicable_OPD_program" value="Yes" (change)="checkradio('divcopay_applicable_OPD_program_limit', 'Covered')" />
                                            <label for="radio-one_copay_applicable_OPD_program" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divcopay_applicable_OPD_program_limit" style="display: none">
                                        <label class="bs-form-label" for="copay_applicable_OPD_program_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <div style="display: flex">
                                            <input type="text" class="form-control" formControlName="copay_applicable_OPD_program_limit" id="copay_applicable_OPD_program_limit" (input)="age_limit($event, 'agerange')" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%" />
                                            <span class="bs-form-label" style="margin-top: 5px; margin-left: 10px;"> % </span>
                                        </div>                                        
                                        <p class="error" id="copay_applicable_OPD_program_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-12 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="OPD_restricted_network"> Can OPD be taken only through restricted network as per Insurer/TPA? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'OPD_restricted_network_div')" (mouseleave)="mouseLeave($event, 'OPD_restricted_network_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="OPD_restricted_network_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon"> Can OPD be taken only through restricted network as per Insurer/TPA?</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_OPD_restricted_network" formControlName="OPD_restricted_network" name="OPD_restricted_network" value="No" />
                                            <label for="radio-two_OPD_restricted_network" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_OPD_restricted_network" formControlName="OPD_restricted_network" name="OPD_restricted_network" value="Yes" />
                                            <label for="radio-one_OPD_restricted_network" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 form-group" id="divOPD_restricted_network_limit" style="display: none">
                                        <label class="bs-form-label" for="OPD_restricted_network_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="OPD_restricted_network_limit" id="OPD_restricted_network_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="OPD_restricted_network_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div> -->
                                </div>
                                

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->                               

                <div class="row">
                    <div>
                        <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <!-- <button (click)="next(3);" class="btn btn-primary" *ngIf="stepper_flag == false" style="float: right;">Finish</button> -->
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>

                </form>
            </div>

            <div id="test-l-4" class="content mt-3">                

                <form [formGroup]="documentUpload">
                <h3 class="bs-title">Document upload</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Document upload</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div *ngIf="stepper_flag == false">
                            As New policy is selected no need for uploading any doc, directly click on Finish
                        </div>
                        <div class="row" *ngIf="stepper_flag == true">                                                       

                            <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="policy_document">Policy Document</label>
                                    <!-- <input type="file" class="form-control" formControlName="policy_document" (change)="policy_document_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field5" id="custom-file-upload_policy_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field5" type="file" (change)="policy_document_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_policy_document">Upload only PDF files (Max size 4MB)</small>
                            </div>

                            <div class="col-sm-12">
                                <div class="row newuploadField">

                                </div>
                            </div>

                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addUploadField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Upload Field</span>
                                </label>
                            </div>


                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(4);" class="btn btn-primary" style="float: right;">Finish</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>
                </form>
            </div>
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 80%; padding-bottom: 10px; margin: auto; top:40px">
                <!-- <div class="modal-header">
                    <h4 class="modal-title">Hurray!!</h4>
                </div> -->
                <div class="modal-body">                    
                    <div class="row">

                        <div class="col-sm-12">
                            <img src="../../assets/img/policy/hurray.jpg" class="img-fluid mb-3" style="width:50%; margin: auto; display: block; margin-bottom: 0px !important;" />
                        </div>

                        <div class="col-sm-12">
                            
                            <h5 style="text-align: center; font-weight: bold;">Hooray!!</h5>
                            <h6 class="mt-3 text-center" style="font-size: 14px;"> Plan created successfully.</h6>
                            <!-- <h6 class="mt-2 text-center" style="font-weight: 600; font-size: 14px;">Your Plan ID : {{proposal_number_show}}</h6>

                            <div class="form-group mt-3">
                                <label class="bs-form-label text-center" style="margin: auto; display: block;"> Enter Plan Name </label>
                                <input type="text" class="form-control" id="plan_name" name="plan_name" />
                                <p class="error" id="plan_name_error" style="display: none">
                                    This field is required
                                </p>
                            </div> -->

                        </div>                

                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closePopup()" style="margin: auto;"> Back to portfolio</button>
                </div>
            </div>
        </div>
    </div>

</div>