<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">{{ancillaryName}} Insurance
                <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button>
            </h3>
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- Tabs -->
            <div class="col-sm-12">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link active w100" id="Features-tab" data-bs-toggle="tab" data-bs-target="#Features"
                            type="button" role="tab" aria-controls="Features" aria-selected="true">Buy Insurance
                        </button>
                    </li>
                    <!-- <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="New_Hospital-tab" data-bs-toggle="tab" data-bs-target="#New_Hospital" 
                        type="button" role="tab" aria-controls="New_Hospital" aria-selected="false">My Insurance</button>
                    </li> -->
                </ul>
                
                <!-- Tabs Content -->
                <div class="tab-content tabUI2" id="myTabContent2" style="height: calc(100vh - 90px); overflow-y: auto;">

                    <div class="tab-pane fade show active" id="Features" role="tabpanel" aria-labelledby="Features-tab">
                        
                        <div class="col-sm-12 headingbg" *ngIf="ancillaryData.length > 0">
                            {{ancillaryName}} Insurance Fresh Policy                                      
                        </div>

                        <div class="row">

                            <div class="col-sm-2 mt-3" *ngFor="let group of ancillaryData" (click)="goto(group['insurance_ancillary_url'])">
                                <div class="p-2 divbox">
                                    <img src="{{group['insurance_ancillary_logo']}}" class="carimg" />
                                    <div class="title_text">
                                      {{group['insurance_ancillary_name']}}
                                    </div>
                                </div>
                            </div>

                            
                        </div>   
                        
                        <div class="col-sm-12 headingbg mt-3"  *ngIf="ancillaryDataArray.length > 0">
                            {{ancillaryName}} Insurance Policy Renewal
                        </div>

                        <div class="row">

                            <div class="col-sm-2 mt-3" *ngFor="let group of ancillaryDataArray" (click)="goto(group['insurance_ancillary_url'])">
                                <div class="p-2 divbox">
                                    <img src="{{group['insurance_ancillary_logo']}}" class="carimg" />
                                    <div class="title_text">
                                        {{group['insurance_ancillary_name']}}
                                    </div>
                                </div>
                            </div>

                        </div>   
                        
                    </div>
                    
                    <div class="tab-pane fade" id="New_Hospital" role="tabpanel" aria-labelledby="New_Hospital-tab">
                        Coming Soon...
                    </div>
                </div>
            </div>
                                                

        </div>
        <app-footer></app-footer>
    </div>
</div>