import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

interface PolicyType {
  name: string;
  sub_type_id: number;
}

@Component({
  selector: 'app-overall-claims',
  templateUrl: './overall-claims.component.html',
  styleUrls: ['./overall-claims.component.css']
})
export class OverallClaimsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  public DeleteInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayCorporate: any = [];   
  InfoArrayAPI: any = [];   
  policy_type:  PolicyType[] = [];   
  policynumbers: any = [];   

  ClientName: any;
  PolicyType: any;
  PolicyName: any;

  document_file: any;
  downloadbuttontooltip:any;
  samplefile: any;
  Policyuploaddoclist:any = [];
  addflag: any = 0;
  imgpath: any;

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  displayStyleDelete = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click()

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup(){

    this.getClient();

    if(this.type_name == 'employeruser'|| this.type_name == 'employeradmin'){
      this.getPolicyType();
    }

    this.basicInfo = this.fb.group({
      client_id: new FormControl(null, Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_name: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      policy_claim_status: new FormControl('0', Validators.required),

    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getClient(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    // $('#corporate').val('0');


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    };
    
    this.service.getInfoClient(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayCorporate = result.data;

          // let clientId: any = localStorage.getItem('client_id');
          // if(clientId > 0){
          //   this.ClientName = localStorage.getItem('client_id');
          // }else{
          //   this.basicInfo.controls['client_id'].setValue('');
          // }

        }, (error) => {          
          
          this.InfoArrayCorporate = [];
          
      });
  }
  

  getPolicyType(){
    
    let postdata:any = {};

    if(this.type_name == 'brokeradmin'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.basicInfo.controls['client_id'].value,
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id": localStorage.getItem('employer_id'),
      }
    }
    
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data.filter((PolicyType: PolicyType) => PolicyType.sub_type_id === 1);

        let policyType: any = localStorage.getItem('policy_type');
        if(policyType > 0){
          this.PolicyType = localStorage.getItem('policy_type');
        }else{
          this.basicInfo.controls['policy_type'].setValue('');
        }

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    let postdata:any = {};

    if(this.type_name == 'brokeradmin'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.basicInfo.controls['client_id'].value,
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('employer_id'),
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
      }
    }
    
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

        let policyName: any = localStorage.getItem('policy_name');
        if(policyName > 0){
          this.PolicyName = localStorage.getItem('policy_name');
        }else{
          this.basicInfo.controls['policy_name'].setValue('');
        }

      }, (error) => {
          // console.log(result);
    });
  }

  
  getPolicyDate(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id":this.basicInfo.controls['policy_name'].value,
    };
    
    this.service.getPolicyDate(postdata)
        .subscribe((result: any) => {          
          
        this.basicInfo.controls['start_date'].setValue(result.data[0]['policy_start_date']);
        this.basicInfo.controls['end_date'].setValue(result.data[0]['policy_end_date']);

        }, (error) => {          
          
          this.InfoArrayAPI = [];
          
      });
  }

  ondownload(val: any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(val == "policyfeatures"){
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', 'Sample_Policy_Member_Data.xls');
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  uploadfile(event: any){
    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  getInfo(){

    let postdata:any = {};
    this.basicInfoSubmitted = true;

    if(this.type_name == 'brokeradmin'){
      if (this.basicInfoSubmitted && this.basicInfo.valid) {

        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "broker_id":localStorage.getItem('broker_id'),
          "employer_id":this.basicInfo.controls['client_id'].value,
          "policy_type":this.basicInfo.controls['policy_type'].value,
          "policy_id":this.basicInfo.controls['policy_name'].value,
        }
      

     

      let client_id = this.basicInfo.controls['client_id'].value;
      let policy_type = this.basicInfo.controls['policy_type'].value;
      let policy_id = this.basicInfo.controls['policy_name'].value;

       this.service.getInfo(postdata)
        .subscribe((result: any) => {          
          
          localStorage.setItem('client_id', client_id);
          localStorage.setItem('policy_type', policy_type);
          localStorage.setItem('policy_id', policy_id);

          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });

    }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "employer_id":localStorage.getItem('employer_id'),
        "policy_type":this.basicInfo.controls['policy_type'].value,
        "policy_id":this.basicInfo.controls['policy_name'].value,
      }

      this.service.getInfo(postdata)
        .subscribe((result: any) => {          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });

    }
   

  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "api_type_id":this.basicInfo.controls['api'].value,
      "uploadid":id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        window.open(result.data.error_filename,'_blank');

      }, (error) => {
          // console.log(result);
    });    

  }

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  } 

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  // openPopupActivate(res: any) {
  //   this.displayStyleActivate = "block";  
    
  //   var postdata = {
  //     "tokenable_id":localStorage.getItem('tokenable_id'),
  //     "id":res.id
  //   };

  //   this.service.InfoSingle(postdata)
  //     .subscribe((result: any) => {
        
  //       // console.log(result);
  //       this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
  //     }, (error) => {          
               
  //   });

  // }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');     
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;    
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  // openPopupDeactivate(res: any) {
  //   this.displayStyleDeactivate = "block";  
    
  //   var postdata = {
  //     "tokenable_id":localStorage.getItem('tokenable_id'),
  //     "id":res.id
  //   };

  //   this.service.InfoSingle(postdata)
  //     .subscribe((result: any) => {
        
  //       // console.log(result);
  //       this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
  //     }, (error) => {          
               
  //   });

  // }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');    
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  viewClaims(group: any){
    localStorage.setItem('claim_id', group.cid);
    this.route.navigate(['claim-view']);
  }

}
