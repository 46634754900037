import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { GotoPolicyDetailsService } from '../go-to-policies/goto-policy';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-go-to-policies',
  templateUrl: './go-to-policies.component.html',
  styleUrls: ['./go-to-policies.component.css']
})
export class GoToPoliciesComponent implements OnInit {

  public usersInfo:FormGroup;
  usersInfoSubmitted: boolean = false;
  
  public usersEdit:FormGroup;  
  usersEditSubmitted: boolean = false;

  public usersDelete:FormGroup;  

  user_role: any;
  validmsg: any;
  planArray: any = [];
  usersArray: any = [];    
  displayStyle = "none";
  displayStyleDelete = "none";
  
  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: [1,2,3], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  resstatus = localStorage.getItem('resstatus');
  gotopolicy = localStorage.getItem('gotopolicy');

  constructor(private fb: FormBuilder, private usersCreationService: GotoPolicyDetailsService, private route: Router) { 
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {        
        this.user_role = localStorage.getItem('type_name');        
      }  
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }  
    });
   }

  ngOnInit(): void {

    this.initGroup();  

  }

  initGroup(){    
    
    this.usersArray = [];
    this.getUsersInfo();
    // this.getplanInfo();    

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.usersInfo = this.fb.group({
      username: new FormControl(''),
      pwd: new FormControl(''),    
      user_type_id_fk: new FormControl(''),
      plan_id_fk: new FormControl('')
    });
    
    // Form element defined below
    this.usersEdit = this.fb.group({
      edit_username: new FormControl(''),
      edit_user_type_id_fk: new FormControl(''),
      edit_plan_id_fk: new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.usersDelete = this.fb.group({
      delete_id: new FormControl('')      
    });    

  }

  getUsersInfo(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id')      
    };

    this.usersCreationService.getUsersInfo(postdata)
        .subscribe((result: any) => {          
          
          // console.log(result);
          if(this.gotopolicy == 'GMC'){
            this.usersArray = result.data.gmclist;
          }
          if(this.gotopolicy == 'GPA'){
            this.usersArray = result.data.gpalist;
          }
          if(this.gotopolicy == 'GTL'){
            this.usersArray = result.data.gtllist;
          }
          if(this.gotopolicy == 'OPD'){
            this.usersArray = result.data.opdlist;
          }
                              
          this.dtTrigger.next();

        }, (error) => {          
          
          this.usersArray = [];
          
      });
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  closealert(){
    $("#validmsg").css('display', 'none');    
    $("#errormsg").css('display', 'none');    
  }

  onSubmit() {

    this.usersInfoSubmitted = true;
    if (this.usersInfoSubmitted && this.usersInfo.valid) {
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "username":this.usersInfo.controls['username'].value,
        "pwd":this.usersInfo.controls['pwd'].value,
        "user_type_id_fk":this.usersInfo.controls['user_type_id_fk'].value,
        "plan_id_fk":this.usersInfo.controls['plan_id_fk'].value                
      };
      // console.log(postdata);

      this.usersCreationService.usersCreate(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {            
            
            // this.usersInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            this.getUsersInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }  

  get getUsersInformationFormControls() {
    return this.usersInfo.controls;
  }
  
  get getUsersEditrmationFormControls() {
    return this.usersEdit.controls;
  }

  openPopup(res: any) {
    this.displayStyle = "block";
    this.onEditLoad(res);
  }

  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_id
    };

    this.usersCreationService.usersEdit(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.usersDelete.controls['delete_id'].setValue(result[0]['user_id']);
        
      }, (error) => {          
               
    });

  }

  onEditLoad(res: any){          

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_id
    };

    this.usersCreationService.usersEdit(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.usersEdit.controls['edit_username'].setValue(result[0]['username']);
        this.usersEdit.controls['edit_user_type_id_fk'].setValue(result[0]['user_type_id_fk']);
        this.usersEdit.controls['edit_plan_id_fk'].setValue(result[0]['plan_id_fk']);
        this.usersEdit.controls['edit_id'].setValue(result[0]['user_id']);
        this.usersEdit.controls['edit_is_active'].setValue(result[0]['is_active']);

      }, (error) => {          
               
    });

  }

  closePopup() {
    this.displayStyle = "none";
    this.displayStyleDelete = "none";  
  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.usersEditSubmitted = true;
    if (this.usersEditSubmitted && this.usersEdit.valid) {

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "username":this.usersEdit.controls['edit_username'].value,
        "user_type_id_fk":this.usersEdit.controls['edit_user_type_id_fk'].value,
        "plan_id_fk":this.usersEdit.controls['edit_plan_id_fk'].value,
        "is_active":this.usersEdit.controls['edit_is_active'].value
      };

      this.usersCreationService.usersUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            this.getUsersInfo();
            this.dtTrigger.unsubscribe();

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  onSubmitDelete(){

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.usersCreationService.usersDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getUsersInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }
  
  SubmitPayment(client_product_id:any, status:any){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),        
        "client_id":localStorage.getItem('client_id'),    
        "client_product_id": client_product_id,
        "status": status
      };
  
      console.log(postdata);
  
      this.usersCreationService.SubmitPayment(postdata)
        .subscribe((result: any) => {
  
          // console.log(result); 
          this.getUsersInfo();
          location.reload();
         
        }, (error) => {
            // console.log(result);
      });

    
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
