import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-broker-intimate-claims',
  templateUrl: './broker-intimate-claims.component.html',
  styleUrls: ['./broker-intimate-claims.component.css']
})
export class BrokerIntimateClaimsComponent implements OnInit {


  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public reimbursement:FormGroup;
  reimbursementSubmitted: boolean = false;

  public addInfo:FormGroup;
  addInfoSubmitted: boolean = false;

  
  InfoArray: any = [];   
  InfoArrayCorporate: any = [];   
  policy_type: any = [];   
  policynumbers: any = [];   
  EmployeeArray: any = [];   
  MemberArray: any = [];   

  addflag: any = 0;
  tpa_member_id: any;
  mid: any;
  IntimationId: any;

  Savetoggle = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
  }

  initGroup(){

    setTimeout(() => {
      this.getClient();
    }, 1000);
    
    if(this.type_name == 'employeruser'|| this.type_name == 'employeradmin'){
      this.getPolicyType();
    }

    this.basicInfo = this.fb.group({
      company_id: new FormControl('', Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_number: new FormControl('', Validators.required),
      employee_id: new FormControl('', Validators.required),
    });

    this.reimbursement = this.fb.group({
      patient_name: new FormControl('', Validators.required),
      mobile_no: new FormControl('', Validators.required),
      email_id: new FormControl('', Validators.required),
      hospital_name: new FormControl('', Validators.required),
      hospital_address: new FormControl('', Validators.required),
      doctor_name: new FormControl('', Validators.required),
      pincode: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      amount_istimated: new FormControl('', Validators.required),
      date_of_addmission: new FormControl('', Validators.required),
      remarks: new FormControl(''),
      discharge_date: new FormControl(''),

    });

    this.addInfo = this.fb.group({
      patient_name: new FormControl('', Validators.required),
      mobile_no: new FormControl('', Validators.required),
      email_id: new FormControl('', Validators.required),
      doctor_name: new FormControl('', Validators.required),
      hospital_name: new FormControl('', Validators.required),
      pincode: new FormControl('', Validators.required),
      hospital_state: new FormControl('', Validators.required),
      hospital_city: new FormControl('', Validators.required),
      hospital_reason: new FormControl('', Validators.required),
      hospital_addmission: new FormControl('', Validators.required),
      hospital_discharge_date: new FormControl(''),
      hospital_file: new FormControl(''),

    });

  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  get getreimbursementInformationFormControls() {
    return this.reimbursement.controls;
  }
  get getaddInformationFormControls() {
    return this.addInfo.controls;
  }

  getClient(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    };
    
    this.service.getInfoClient(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayCorporate = result.data;

        }, (error) => {          
          
          this.InfoArrayCorporate = [];
          
      });
  }

  getPolicyType(){

    setTimeout(()=>{
      $('#policy_type').val('');
    },1000)
    
    let postdata:any = {};

    if(this.type_name == 'brokeradmin'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.basicInfo.controls['company_id'].value,
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id": localStorage.getItem('employer_id'),
      }
    }
     
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    setTimeout(()=>{
      $('#policy_number').val('');
    },1000)

    let postdata:any = {};

    if(this.type_name == 'brokeradmin'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.basicInfo.controls['company_id'].value,
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('employer_id'),
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
      }
    }
     
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
    });
  }

  getEmployee(){

    let postdata:any = {};

    if(this.type_name == 'brokeradmin'){
     postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.basicInfo.controls['company_id'].value,
      "policy_id": this.basicInfo.controls['policy_number'].value,
    };
  }else{
    postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": localStorage.getItem('employer_id'),
      "policy_id": this.basicInfo.controls['policy_number'].value,
    };
  }

    this.service.getEmployee(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.EmployeeArray = result.data;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getMembers(){

    setTimeout(()=>{
      $('#patient_name').val('')
      $('#patient_name_reimbursment').val('')
      $('#mobile_no').val('')
      $('#email_id').val('')
      $('#mobile_noCashless').val('')
      $('#email_idCashless').val('')
    },1000);
    
    let postdata:any = {};

    if(this.type_name == 'brokeradmin'){
     postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.basicInfo.controls['company_id'].value,
      "policy_id": this.basicInfo.controls['policy_number'].value,
      "empid": $('#employee_id').val(),
    };
  }else{
    postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": localStorage.getItem('employer_id'),
      "policy_id": this.basicInfo.controls['policy_number'].value,
      "empid": $('#employee_id').val(),
    };
  }


    this.service.getMembers(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.MemberArray = result.data;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getDetails(){

    let id:any = this.addInfo.controls['patient_name'].value;

    for(var i=0; i < this.MemberArray.length; i++){
      if(this.MemberArray[i]['mid'] ==  id){
        this.addInfo.controls['mobile_no'].setValue(this.MemberArray[i]['member_mob_no']);
        this.addInfo.controls['email_id'].setValue(this.MemberArray[i]['member_email']);
        this.tpa_member_id  =  this.MemberArray[i]['tpa_member_id'];
        this.mid  =  this.MemberArray[i]['mid'];
      }
    }

  }

  getDetailsReimbursment(){

    let id:any = this.reimbursement.controls['patient_name'].value;

    for(var i=0; i < this.MemberArray.length; i++){
      if(this.MemberArray[i]['mid'] ==  id){
        this.reimbursement.controls['mobile_no'].setValue(this.MemberArray[i]['member_mob_no']);
        this.reimbursement.controls['email_id'].setValue(this.MemberArray[i]['member_email']);
        this.tpa_member_id  =  this.MemberArray[i]['tpa_member_id'];
        this.mid  =  this.MemberArray[i]['mid'];
      }
    }

  }

  onSubmitCashless(){

    this.addInfoSubmitted = true;

    let company_id:any = $('#company_id').val();
    let policy_type:any = $('#policy_type').val();
    let policy_number:any = $('#policy_number').val();
    let employee_id:any = $('#employee_id').val();

    
    if(company_id != '' || policy_number != 0 || policy_type != '' || policy_number != ''|| employee_id != ''){
    if (this.addInfoSubmitted && this.addInfo.valid) {
     
      let postdata:any = {};

      if(this.type_name == 'brokeradmin'){
       postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "client_id":this.basicInfo.controls['company_id'].value,
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
        "policy_id":this.basicInfo.controls['policy_number'].value,
        "patient_name":this.addInfo.controls['patient_name'].value,
        "mobile_no":this.addInfo.controls['mobile_no'].value,
        "email_id":this.addInfo.controls['email_id'].value,
        "doctor_name":this.addInfo.controls['doctor_name'].value,
        "hospital_name":this.addInfo.controls['hospital_name'].value,
        "hospital_state":this.addInfo.controls['hospital_state'].value,
        "hospital_city":this.addInfo.controls['hospital_city'].value,
        "hospital_reason":this.addInfo.controls['hospital_reason'].value,
        "hospital_addmission":this.addInfo.controls['hospital_addmission'].value,
        "hospital_discharge_date":this.addInfo.controls['hospital_discharge_date'].value,
        "hospital_file":this.addInfo.controls['hospital_file'].value,
        "tpa_member_id":this.tpa_member_id,
        "mid":this.mid,
        "claim_type": 2,
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "client_id":localStorage.getItem('employer_id'),
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
        "policy_id":this.basicInfo.controls['policy_number'].value,
        "patient_name":this.addInfo.controls['patient_name'].value,
        "mobile_no":this.addInfo.controls['mobile_no'].value,
        "email_id":this.addInfo.controls['email_id'].value,
        "doctor_name":this.addInfo.controls['doctor_name'].value,
        "hospital_name":this.addInfo.controls['hospital_name'].value,
        "hospital_state":this.addInfo.controls['hospital_state'].value,
        "hospital_city":this.addInfo.controls['hospital_city'].value,
        "hospital_reason":this.addInfo.controls['hospital_reason'].value,
        "hospital_addmission":this.addInfo.controls['hospital_addmission'].value,
        "hospital_discharge_date":this.addInfo.controls['hospital_discharge_date'].value,
        "hospital_file":this.addInfo.controls['hospital_file'].value,
        "tpa_member_id":this.tpa_member_id,
        "mid":this.mid,
        "claim_type": 2,
      };
    }
      this.service.onSaveCashless(postdata)
        .subscribe((result) => {
          if(result.status){
            this.show = true;            
            this.IntimationId = result.data.IntimationId
            // this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            setTimeout(() => {
              this.Savetoggle = 'block';
              this.show = false;            
              }, 2000);

            setTimeout(() => {
              this.route.navigate(['/portal-claims']);
            }, 4000);
            
          }
        }, (error) => {
            this.toastService.showFailed('Failed', 'There was some error');

      });
      
    }  
    
  }else{

    if(company_id != "" || company_id != 0){
      $('.company_id_error').css('display','none');
    }else{
      $('.company_id_error').css('display','block');
    }

    if(policy_type != ''){
      $('.policy_type_error').css('display','none');
    }else{
      $('.policy_type_error').css('display','block');
    }

    if(policy_number != ''){
      $('.policy_number_error').css('display','none');
    }else{
      $('.policy_number_error').css('display','block');
    }

    if(employee_id != ''){
      $('.employee_id_error').css('display','none');
    }else{
      $('.employee_id_error').css('display','block');
    }

  }

  }

  onSubmitReimbursement(){

    this.reimbursementSubmitted = true;

    let company_id:any = $('#company_id').val();
    let policy_type:any = $('#policy_type').val();
    let policy_number:any = $('#policy_number').val();

    if(company_id != '' || policy_number != 0 || policy_type != '' || policy_number != ''){
    if (this.reimbursementSubmitted && this.reimbursement.valid) {
     
      let postdata:any = {};

      if(this.type_name == 'brokeradmin'){
       postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "client_id":this.basicInfo.controls['company_id'].value,
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
        "policy_id":this.basicInfo.controls['policy_number'].value,
        "patient_name":this.reimbursement.controls['patient_name'].value,
        "mobile_no":this.reimbursement.controls['mobile_no'].value,
        "email_id":this.reimbursement.controls['email_id'].value,
        "doctor_name":this.reimbursement.controls['doctor_name'].value,
        "hospital_name":this.reimbursement.controls['hospital_name'].value,
        "hospital_state":this.reimbursement.controls['state'].value,
        "hospital_city":this.reimbursement.controls['city'].value,
        "hospital_reason":this.reimbursement.controls['reason'].value,
        "hospital_addmission":this.reimbursement.controls['date_of_addmission'].value,
        "hospital_discharge_date":this.reimbursement.controls['discharge_date'].value,
        "amount_istimated":this.reimbursement.controls['amount_istimated'].value,
        "remarks":this.reimbursement.controls['remarks'].value,
        "pincode":this.reimbursement.controls['pincode'].value,
        "hospital_address":this.reimbursement.controls['hospital_address'].value,
        "tpa_member_id":this.tpa_member_id,
        "mid":this.mid,
        "claim_type": 1,
      };

    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "client_id":localStorage.getItem('employer_id'),
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
        "policy_id":this.basicInfo.controls['policy_number'].value,
        "patient_name":this.reimbursement.controls['patient_name'].value,
        "mobile_no":this.reimbursement.controls['mobile_no'].value,
        "email_id":this.reimbursement.controls['email_id'].value,
        "doctor_name":this.reimbursement.controls['doctor_name'].value,
        "hospital_name":this.reimbursement.controls['hospital_name'].value,
        "hospital_state":this.reimbursement.controls['state'].value,
        "hospital_city":this.reimbursement.controls['city'].value,
        "hospital_reason":this.reimbursement.controls['reason'].value,
        "hospital_addmission":this.reimbursement.controls['date_of_addmission'].value,
        "hospital_discharge_date":this.reimbursement.controls['discharge_date'].value,
        "amount_istimated":this.reimbursement.controls['amount_istimated'].value,
        "remarks":this.reimbursement.controls['remarks'].value,
        "pincode":this.reimbursement.controls['pincode'].value,
        "hospital_address":this.reimbursement.controls['hospital_address'].value,
        "tpa_member_id":this.tpa_member_id,
        "mid":this.mid,
        "claim_type": 1,
      };
    }

      this.service.onSaveReimbursement(postdata)
        .subscribe((result) => {
          if(result.status){
            this.show = true;            

            this.IntimationId = result.data.IntimationId
            // this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            setTimeout(() => {
            this.Savetoggle = 'block';
            this.show = false;            
            }, 2000);

            setTimeout(() => {
              this.route.navigate(['/portal-claims']);
            }, 4000);

           
            
          }
        }, (error) => {
            this.toastService.showFailed('Failed', 'There was some error');

      });
      
    }  
    
  }else{

    if(company_id != "" || company_id != 0){
      $('.company_id_error').css('display','none');
    }else{
      $('.company_id_error').css('display','block');
    }

    if(policy_type != ''){
      $('.policy_type_error').css('display','none');
    }else{
      $('.policy_type_error').css('display','block');
    }

    if(policy_number != ''){
      $('.policy_number_error').css('display','none');
    }else{
      $('.policy_number_error').css('display','block');
    }

  }

  }

  closePopup() {    
    this.Savetoggle = "none";
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event; // Check if evt exists, if not, assign window.event
    var charCode = (evt.which) ? evt.which : evt.keyCode; // Get the character code depending on browser compatibility

    // Check if the character code corresponds to a number
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false; // Return false if it's not a number
    }
    return true; 
  }

  cityDetailsReimbursement(evt: any){    
    
    if(this.reimbursement.controls['pincode'].value.length >= 5){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":$('#pincode').val()
      }

      this.service.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {

            this.reimbursement.controls['state'].setValue(result.data[0]['state_name']);
            this.reimbursement.controls['city'].setValue(result.data[0]['districtName']);

          } 
        }, (error) => {

      });

    }else{
      this.reimbursement.controls['state'].setValue('');
      this.reimbursement.controls['city'].setValue('');
    }

    return true;
  }

  cityDetaitsCashless(evt: any){    
    
    if(this.addInfo.controls['pincode'].value.length >= 5){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":$('#pincodeCashless').val()
      }

      this.service.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {

            this.addInfo.controls['hospital_state'].setValue(result.data[0]['state_name']);
            this.addInfo.controls['hospital_city'].setValue(result.data[0]['districtName']);

          } 
        }, (error) => {

      });

    }else{
      this.addInfo.controls['hospital_state'].setValue('');
      this.addInfo.controls['hospital_city'].setValue('');
    }

    return true;
  }

}
