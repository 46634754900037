<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
  

          <h3 class="bs-title"  *ngIf="addflag == 0">Ancillary Details</h3>
          <h3 class="bs-title"  *ngIf="addflag == 1">Ancillary Details Edit
            <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
          </h3>
          
          <hr class="mb-2 mt-4" style="height: 0px">
  
          <!-- Add -->
          <form [formGroup]="basicInfo" *ngIf="addflag == 0">
            <div class="row">

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_id">Client <span class="redstar">*</span></label>
                            <ng-select formControlName ="client_id" id="client_id"  (change)="getInfo()"
                                [items]="InfoArrayClient"
                                bindLabel="name"
                                bindValue="id"
                                [placeholder]="'Select Client'"
                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.client_id.errors}">
                            </ng-select>
                        
                        <ng-container
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors">
                            <p class="error"
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors.required">
                            Client is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="type">Type</label>
                        <select class="form-select" formControlName="type" id="type">
                            <option value="">Select Type</option>
                            <option value="1">Insurance Fresh Policy </option>
                            <option value="2">Insurance Policy Renewal</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="ancillary_type">Ancillary <span class="redstar">*</span></label>
                        <select class="form-select" formControlName="ancillary_type" id="ancillary_type" [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.ancillary_type.errors}"
                        required>
                            <option value="">Select Ancillary</option>
                            <option *ngFor="let ancillary of InfoArrayAncillary; let i = index" value="{{ancillary.id}}">
                                {{ancillary.ancillary_name}}</option>
                        </select>
                        <ng-container
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.ancillary_type.errors">
                    <p class="error"
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.ancillary_type.errors.required">
                    Ancillary is required
                    </p>
                  </ng-container>
                    </div>
                </div>
  
              <div class="col-sm-4 mt-3">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_name">Benefit Name <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_name" id="benefit_name" placeholder="Benefit Name"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors}"
                        required />
                    <ng-container
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors">
                    <p class="error"
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors.required">
                    Benefit Name is required
                    </p>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-4 mt-3">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_url">Benefit URL <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_url" id="benefit_url" placeholder="Benefit URL"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.benefit_url.errors}"
                        required />
                    <ng-container
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_url.errors">
                    <p class="error"
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_url.errors.required">
                    Benefit URL is required
                    </p>
                  </ng-container>
                </div>
              </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                  <label class="bs-form-label" for="document_file">Upload Logo <span class="redstar">*</span> </label>
                  <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; background-color: white;">
                      <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_Logo" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                  </label>
                  <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file" accept=".jpeg, .jpg, .png" enctype="multipart/form-data" style="display: none;" />
              </div>
            <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .jpeg, .jpg, .png files</small>
            <small class="error" style="display: none; font-weight:normal" id="document_file_error">Logo Required</small>

            </div>

            <div class="col-sm-12 mt-3">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_description">Benefit Description</label>
                    <textarea type="text" class="form-control" formControlName="benefit_description" id="benefit_description" placeholder="Benefit Description" style="height: 70px;"></textarea>
                </div>
            </div>
            
  
              <div class="col-sm-12" align="center">
                  <button *ngIf="writeaccess == 1" type="button" class="btn btn-primary mt-4" (click)="onSubmitAdd()" role="button">Save</button>
              </div>
            </div>
          </form>
  
          <hr  *ngIf="addflag == 0">
  
          <!-- List -->
          <div class="table-responsive"  *ngIf="addflag == 0">
            <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th style="text-align: center;">Benefit Name</th>
                        <th style="text-align: center;">Benefit URL</th>
                        <th>Logo</th>
                        <th>Status</th>
                        <th *ngIf="writeaccess == 1">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td style="text-wrap: wrap;">{{group['insurance_ancillary_name']}}</td>
                        <td style="text-wrap: wrap;">{{group['insurance_ancillary_url']}}</td>
                        <td> <img src="{{group['insurance_ancillary_logo']}}"
                          style="width: 50px; height: 50px; margin-right: 100px; display: block;" />
                        </td>
                                            
                        <td>
                          <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                          <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>
                        <td *ngIf="writeaccess == 1" style="width: 15%" align="center">
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img src="../../../assets/img/selectproduct/delete.png" id="deleteuser" (click)="openPopupDelete(group)" class="ml-4" role="button" title="Delete" />
                            <img src="../../../assets/img/selectproduct/deactive.png" *ngIf="group['is_active']==1" (click)="openPopupDeactivate(group)" id="deactive"  class="ml-4" role="button" title="Deactive" />
                            <img src="../../../assets/img/selectproduct/active.png" *ngIf="group['is_active']==0" (click)="openPopupActivate(group)" id="active"  class="ml-4" role="button" title="Active" />
                        </td>        
                    </tr>
                </tbody>
            </table>
          </div>

          <!-- Edit -->
          <form [formGroup]="EditInfo" *ngIf="addflag == 1">
            <div class="row">
              <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="client_id">Client <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="client_id" id="client_id_edit" [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.client_id.errors}"
                    required>
                        <option value="">Select Client</option>
                        <option *ngFor="let client of InfoArrayClient; let i = index" value="{{client.id}}">
                            {{client.name}}</option>
                    </select>
                    <ng-container
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.client_id.errors">
                <p class="error"
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.client_id.errors.required">
                Client is required
                </p>
              </ng-container>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="type">Type</label>
                    <select class="form-select" formControlName="type" id="type_edit">
                        <option value="">Select Type</option>
                        <option value="1">Insurance Fresh Policy </option>
                        <option value="2">Insurance Policy Renewal</option>
                    </select>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="ancillary_type">Ancillary <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="ancillary_type" id="ancillary_type_edit" [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.ancillary_type.errors}"
                    required>
                        <option value="">Select Ancillary</option>
                        <option *ngFor="let ancillary of InfoArrayAncillary; let i = index" value="{{ancillary.id}}">
                            {{ancillary.ancillary_name}}</option>
                    </select>
                    <ng-container
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.ancillary_type.errors">
                <p class="error"
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.ancillary_type.errors.required">
                Ancillary is required
                </p>
              </ng-container>
                </div>
            </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
                <label class="bs-form-label" for="benefit_name">Benefit Name <span class="redstar">*</span></label>
                <input type="text" class="form-control" formControlName="benefit_name" id="benefit_name_edit" placeholder="Benefit Name"
                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.benefit_name.errors}"
                    required />
                <ng-container
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name.errors">
                <p class="error"
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name.errors.required">
                Benefit Name is required
                </p>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
                <label class="bs-form-label" for="benefit_url">Benefit URL <span class="redstar">*</span></label>
                <input type="text" class="form-control" formControlName="benefit_url" id="benefit_url_edit" placeholder="Benefit URL"
                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors}"
                    required />
                <ng-container
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors">
                <p class="error"
                *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors.required">
                Benefit URL is required
                </p>
              </ng-container>
            </div>
          </div>
            
            <div class="col-sm-4">
              <div class="form-group">
                  <label class="bs-form-label" for="document_file">Upload Logo <span class="redstar">*</span> </label>
                  <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; background-color: white;">
                      <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_Logo" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                  </label>
                  <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file" accept=".jpeg, .jpg, .png" enctype="multipart/form-data" style="display: none" />
              </div>
              <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .jpeg, .jpg, .png files</small>
              <small class="error" style="display: none; font-weight:normal" id="document_file_error">Logo Required</small>

              </div>

              <div class="col-sm-4 mt-3">
                <img src="{{imgpath}}"
                    style="width: 100px; height: 100px; margin-right: auto; display: block; float: left;" />
                    <!-- <button type="button" class="btn btn-primary" (click)="onSubmitEdit()" role="button" style="float: right;">Update</button>
                    <div style="clear: both;"></div> -->
              </div>

              <div class="col-sm-12 mt-3">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_description">Benefit Description</label>
                    <textarea type="text" class="form-control" formControlName="benefit_description" id="benefit_description_edit" placeholder="Benefit Description" style="height: 70px;"></textarea>
                </div>
            </div>
                            
  
              <div class="col-sm-12" align="center">
                  <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>
              </div>
            </div>
          </form>
  
            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Deactivate Ancillary Benefit</h4>
                      </div>
                      <form [formGroup]="DeactivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Deactivate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                  role="button">Deactivate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

          <!-- Activate Form -->
          <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Activate Ancillary Benefit</h4>
                      </div>
                      <form [formGroup]="ActivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Activate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                  role="button">Activate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

            <!-- Delete -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Ancillary Benefit</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
  </div>
  