import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientCreationService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  clientCreate(data: any, token: any): Observable<any> {

    var URL;
    if(data.master_policies_id == 0){
      URL = 'masterpolicy/step1-store';
    }else{
      URL = 'masterpolicy/step1-update/' + data.master_policies_id;
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + URL,
      data
    , httpOptions);
  }

  Step2_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step2-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step3_update(data: any, token: any): Observable<any> {

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("parent_id",data.parent_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("master_policies_id",data.master_policies_id);

    filedata.append("is_update", '1');

    filedata.append("cover_type",data.cover_type);
    filedata.append("salary_type",data.salary_type);
    filedata.append("SI_Type",data.SI_Type);
    filedata.append("SI_Basis",data.SI_Basis);
    filedata.append("premium_Basis",data.premium_Basis);

    filedata.append("SIArr",data.SIArr);
    filedata.append("SIArr_custom",data.SIArr_custom);
    filedata.append("MonthlyEquitible",data.MonthlyEquitible);
    filedata.append("NoOfTimesSalary",data.NoOfTimesSalary);
    filedata.append("AdditionalCover",data.AdditionalCover);

    filedata.append('additional_cover',data.additional_cover);

    filedata.append("minimum_SI",data.minimum_SI);
    filedata.append("maximum_SI",data.maximum_SI);
    filedata.append("interval_between_min_max_SI",data.interval_between_min_max_SI);
    
    filedata.append("ca_employer",data.ca_employer);
    filedata.append("ca_employee",data.ca_employee);
    filedata.append("hide_employer_contribution",data.hide_employer_contribution);
    filedata.append("hide_employee_contribution",data.hide_employee_contribution);

    filedata.append("self_additional_employer",data.self_additional_employer);
    filedata.append("self_additional_employee",data.self_additional_employee);
    filedata.append("self_additional_premium",data.self_additional_premium);

    filedata.append("spouse_details",data.spouse_details);
    filedata.append("parent_details",data.parent_details);
    filedata.append("children_details",data.children_details);
    filedata.append("special_child_contribution_all_employee",data.special_child_contribution_all_employee);
    filedata.append("unmarried_child_contribution_all_employee",data.unmarried_child_contribution_all_employee);
    filedata.append("special_child_contribution_additional_employee",data.special_child_contribution_additional_employee);
    filedata.append("unmarried_child_contribution_additional_employee",data.unmarried_child_contribution_additional_employee);

    filedata.append("parent_in_laws_details",data.parent_in_laws_details);
    filedata.append("siblings_details",data.siblings_details);

    filedata.append("use_flex",data.use_flex);
    filedata.append("payroll",data.payroll);
    filedata.append("installment_allowed",data.installment_allowed);
    filedata.append("installment_prorata",data.installment_prorata);
    filedata.append("installment_type",data.installment_type);
    filedata.append("monthly_equitible_period",data.monthly_equitible_period);

    filedata.append("tabSelected",data.tabSelected);

    filedata.append("tax_status",data.tax_status);
    filedata.append("employee_premium_tax",data.employee_premium_tax);
    

    // console.log(data.emmployeefile_id);

    if(data.SI_Basis == '1' && data.premium_Basis == '2' || data.SI_Basis == '1' && data.premium_Basis == '8' || data.SI_Basis == '1' && data.premium_Basis == '9' || data.SI_Basis == '1' && data.premium_Basis == '13' || data.SI_Basis == '1' && data.premium_Basis == '15' || data.SI_Basis == '18' && data.premium_Basis == '21'){
      filedata.append("employee_file",data.employee_file);
    }

    if(data.SI_Basis == '3' && data.premium_Basis == '4'){
      filedata.append("grade_SI_file",data.grade_SI_file);
      filedata.append("grade_SP_file",data.grade_SP_file);
    }

    if(data.SI_Basis == '8' && data.premium_Basis == '13'){
      filedata.append("family_SI_file",data.family_SI_file);
      filedata.append("family_SP_file",data.family_SP_file);
    }

    if(data.SI_Basis == '19' && data.premium_Basis == '22'){
      filedata.append("family_group_SI_file",data.family_group_SI_file);
      filedata.append("family_group_SP_file",data.family_group_SP_file);
    }

    if(data.SI_Basis == '3' && data.premium_Basis == '2'){
      filedata.append("grade_SI_file",data.grade_SI_file);
      filedata.append("employee_age_premium_file",data.grade_SP_file);
    }
    
    if(data.SI_Basis == '5' && data.premium_Basis == '5'){
      filedata.append("permily_age_wise_file",data.permily_age);
    }

    if(data.SI_Basis == '17' && data.premium_Basis == '19'){
      filedata.append("unnamed_file",data.unnamed_file);
      filedata.append("total_employee",data.total_employee);
      filedata.append("total_SI",data.total_SI);
      filedata.append("total_premium",data.total_premium);
    }

    if(data.SI_Basis == '17' && data.premium_Basis == '20'){      
      filedata.append("total_employee_unnamed",data.total_employee_unnamed);
      filedata.append("gpa_individual_cover",data.gpa_individual_cover);
      filedata.append("total_SI_unnamed",data.total_SI_unnamed);
      filedata.append("total_premium_unnamed",data.total_premium_unnamed);
    }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step3-update/' + data.master_policies_id,
      filedata
    , httpOptions);
  }

  Step4_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step4-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step5_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step5-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step6_update(data: any, token: any): Observable<any> {

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("parent_id",data.parent_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("master_policies_id",data.master_policies_id);

    // filedata.append("document_name",data.document_name);
    // filedata.append("document_type",data.document_type);
    filedata.append("document_mandatory",data.document_mandatory);
    filedata.append("no_of_back_days",data.no_of_back_days);
    filedata.append("digital_claim",data.digital_claim);
    filedata.append("claim_intimate_mandatory",data.claim_intimate_mandatory);
    // filedata.append("document_file",data.document_file);
    filedata.append("is_update",data.is_update);    
    filedata.append("document_fields",data.document_fields);        
    // filedata.append("extra_added_files",data.uploadExtraArr);    

    const mapped:any = Object.keys(data.uploadExtraArr).map(key => ({type: key, value: data.uploadExtraArr[key]}));
    // console.log(mapped);

    for (let i = 0; i < mapped.length; i++) {
      let file: File = mapped[i]['value'][i + 1];
      // console.log(file);
      filedata.append("extra_added_file_" + i, file);      
    }
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step6-update/' + data.master_policies_id,
      filedata
    , httpOptions);
  }

  Step7_update(data: any, token: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("parent_id",data.parent_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("master_policies_id",data.master_policies_id);
    filedata.append("policy_feature_document",data.policy_feature_document);
    filedata.append("is_update", '1');


    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step7-update/' + data.master_policies_id,
      filedata
    , httpOptions);
  }

  getcountry(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/citydetails',
      data
    , httpOptions);
  }

  /************* ALL GET *************/

  getpolicytype(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterpolicytypes-list',
    httpOptions);
  }

  getpolicySubtype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicysubtypes/bypolicytype',
      data
    , httpOptions);
  }

  getparentpolicytypes(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/parentspolicytypes',
      data
    , httpOptions);
  }
  
  getInsurer(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'getinsurance-comp-detailsfront',
    httpOptions);
  }

  getTPA(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'tpa-details/list',
      data
    , httpOptions);
  }

  getemployeeTabView(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicycomparmstype/bypolicycomparmstype',
    data,
    httpOptions);
  }

  getbrokerBranchName(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicycomparmstype/bypolicycomparmstype',
    data,
    httpOptions);
  }

  getclientBranchName(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/clientbranchdropdown',
    data,
    httpOptions);
  }


  getcompanyDetails(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise',
      data
    , httpOptions);
  }

  getmidtermEnrollement(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicycomparmstype/bypolicycomparmstype',
    data,
    httpOptions);
  }

  /*********************************/

  getMembertype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicycomparmstype/bypolicycomparmstype',
    data,
    httpOptions);
  }

  getCovertype(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterpolicyratertypes-list',
    httpOptions);
  }

  getSItype(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'mastersuminsuredtypes-list',
    httpOptions);
  }

  getSIBasis(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'mastersuminsuredsubtypes-list',
    httpOptions);
  }

  getpremiumBasis(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterpremium-list',
    httpOptions);
  }

  getroleDesignationType(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'usertype-info',
    httpOptions);
  }

  getemailID(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'usertype-info/usertypelistbyid',
    data,
    httpOptions);
  }

  getdocumenttype(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterpolicydocumentstype-list',
    httpOptions);
  }

  getspousemidterm(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicycomparmstype/bypolicycomparmstype',
      data
    , httpOptions);
  }

  getEntity(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwisesub',
    data,
    httpOptions);
  }

  getDesignationType(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'brokeruser/listofbrokerusers', data, httpOptions);
  }

  getDesignationType_e(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/listofemployerusers', data, httpOptions);
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'tpa-details/show/' + data.id,
      data
    , httpOptions);
  }

  InfoSingle_Insurer(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'insurance-comp-details/show/' + data.id,
      data
    , httpOptions);
  }

  getRelations(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterrelations-list', httpOptions);
  }

  /************ All Edit API ************/

  // Step 1 Get Edit Data
  getStep1Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step1-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  // Step 2 Get Edit Data
  getStep2Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step2-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  // Step 3 Get Edit Data
  getStep3Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step3-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  // Step 4 Get Edit Data
  getStep4Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step4-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  // Step 5 Get Edit Data
  getStep5Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step5-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  // Step 6 Get Edit Data
  getStep6Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step6-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  // Step 7 Get Edit Data
  getStep7Data(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'updatepolicy/step7-show/' + data.master_policies_id,
      data
    , httpOptions);
  }

  checkpolicyNumber(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/checkpolicynumber',
      data
    , httpOptions);
  }

  getcontactrole(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/contactrole',
      data
    , httpOptions);
  }

  getcontactlistbyusertype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/contactlistbyusertype',
      data
    , httpOptions);
  }

  basepolicydropdown(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/basepolicydropdown',
      data
    , httpOptions);
  }

}
