import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }


  getsamplefile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/samplexcelfile/'+data.master_policies_id,
      data
    , httpOptions);
  }

  getPolicyuploaddoclist(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/policyuploaddoclist',
      data
    , httpOptions);
  }  

  downloadfailedDcoument(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'netwokhospital/onlineerrorexcelfile/' + data.uploadid,
      data
    , httpOptions);
  }


  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'tpaclaim/overallclaimslisting', 
    data,
      httpOptions);
  }

  InfoActiveDeactivate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'benefitwellness/activedeactive/' + data.id,
      data
    , httpOptions);
  }

  getInfoClient(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise', 
      data,
      httpOptions);
  }

  getpolicytype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }

  getpolicynumbers(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }
  getPolicyDate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'tpaclaim/startendbypolicyid', 
      data,
      httpOptions);
  }
}
