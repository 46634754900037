import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../../_services/common.service';
import { numberFormat } from 'highcharts';

@Component({
  selector: 'app-flex-plan-summary',
  templateUrl: './flex-plan-summary.component.html',
  styleUrls: ['./flex-plan-summary.component.css']
})
export class FlexPlanSummaryComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray: any = [];  

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  user_name = localStorage.getItem('name');

  constructor(
    private fb: FormBuilder,
    private dataservice: Service, 
    private route: Router,
    public commonservice: CommonService,
    ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup() {
    this.getInfo();
  }

  /***** Base Plans */
  gmcbasearray : any = [];
  gpabasearray:any = [];
  gtlbasearray:any = [];

  /****** Additional Plans */
  gmcadditionalarray:any=[];
  gpaadditionalarray:any=[];
  gtladditionalarray:any=[];

  /** VOLUNTARY PLANS **/
  gmcvoluntaryplans:any = [];
  gpavoluntaryplans:any = [];
  gtlvoluntaryplans:any = [];

  /******* FLEX PLAN  */
  gmcFlexPlan : any = [];
  gpaFlexPlan : any = [];
  gtlFlexPlan : any = [];

  total_employer_pay_premium : any = 0;
  total_youpay_premium : any = 0;

  youpayavg :any= 0;
  employerpayavg : any = 0;
  
  getInfo() {

    var data:any = localStorage.getItem('policy_info_localdata');
    var localdata:any = JSON.parse(data);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    };

    this.dataservice.getInfo(postdata)
    .subscribe((result) => {
      if (result.status == true) {

        let showDashboard:any = result.data.show_dashboard;
        localStorage.setItem('show_dashboard', showDashboard);

        this.gmcbasearray = result.data.base_plans.gmc;
        this.gmcadditionalarray = result.data.additional_featurs.gmc;
        this.gpabasearray = result.data.base_plans.gpa;
        this.gpaadditionalarray = result.data.additional_featurs.gpa;
        this.gtlbasearray = result.data.base_plans.gtl;
        this.gtladditionalarray = result.data.additional_featurs.gtl;

        this.gmcvoluntaryplans = result.data.voluntary_plans.gmc;
        this.gpavoluntaryplans = result.data.voluntary_plans.gpa;
        this.gtlvoluntaryplans = result.data.voluntary_plans.gtl;

        this.gmcFlexPlan = result.data.flex_basic_data.gmc;
        this.gpaFlexPlan = result.data.flex_basic_data.gpa;
        this.gtlFlexPlan = result.data.flex_basic_data.gtl;

        this.total_employer_pay_premium = result.data.total_employer_pay_premium;
        this.total_youpay_premium = result.data.total_youpay_premium;

        this.youpayavg = numberFormat(parseInt(this.total_youpay_premium)/(parseFloat(this.total_employer_pay_premium) + parseFloat(this.total_youpay_premium))*100,0);
        this.employerpayavg = numberFormat(parseInt(this.total_employer_pay_premium)/(parseFloat(this.total_employer_pay_premium) + parseFloat(this.total_youpay_premium))*100,0);

      }
    }, (error) => {

      if (error.error.message == 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/home']);
      }
    });
  }

  goto(){

    this.route.navigate(['/employeruser-dashboard/family-enrollment/policy-details-family']);   

  }

  gotoback(){
    this.route.navigate(['/employeruser-dashboard']);   
  }

}
