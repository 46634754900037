<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>


           

            <!-- Add -->
            <div class="card">
                <div class="card-body">

                    <h3 class="bs-title">Portal [Intimate] Claims</h3>
                    <hr class="mb-2 mt-2">
                    <form [formGroup]="basicInfo">
                        <div class="row mt-4">

                            <div class="col-sm-3" *ngIf="type_name == 'brokeradmin'">
                                <div class="form-group">
                                    <label class="bs-form-label" for="client_id">Client <span
                                            class="redstar">*</span></label>
                                    <ng-select formControlName="client_id" id="client_id" (change)="getPolicyType()"
                                        (change)="getpolicynumbers()" [items]="InfoArrayCorporate" bindLabel="name"
                                        bindValue="id" [placeholder]="'Select Client'"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.client_id.errors}">
                                    </ng-select>
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors">
                                        <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors.required">
                                            Client is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="policy_type">Policy Type <span
                                            class="redstar">*</span></label>
                                    <select class="form-select" formControlName="policy_type" id="policy_type"
                                        (change)="getpolicynumbers()" 
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors}"
                                        required>
                                        <option value="">Select Policy Type</option>
                                        <option *ngFor="let policyType of policy_type; let i = index"
                                            value="{{policyType.sub_type_id}}">
                                            {{policyType.name}}</option>
                                    </select>
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors">
                                        <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors.required">
                                            Policy Type is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="policy_name">Policy Name <span
                                            class="redstar">*</span></label>
                                    <select class="form-select" formControlName="policy_name" id="policy_name"
                                        (change)="getEmployee()"(change)="getInfo()"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors}"
                                        required>
                                        <option value="">Select Policy Name</option>
                                        <option *ngFor="let corporatelist of policynumbers; let i = index"
                                            value="{{corporatelist.id}}">
                                            {{corporatelist.policy_number}}</option>
                                    </select>
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors">
                                        <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors.required">
                                            Policy Name is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="employee_id">Employee </label>
                                    <select class="form-select" id="employee_id" (change)="getMembers()"  (change)="getInfo()">
                                        <option value="">Select Employee</option>
                                        <option *ngFor="let employee of EmployeeArray" [value]="employee.empid">{{employee.empcode}} - {{employee.first_name}} {{employee.last_name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="member_id">Member</label>
                                        <select class="form-select" id="member_id" (change)="getInfo()">
                                            <option value="">Select Member</option>
                                            <option *ngFor="let member of MemberArray" [value]="member.mid">{{ member.first_name }} {{ member.last_name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

              
              <!-- Tabs -->
              <div class="col-sm-12 mt-4">

                <!-- Tabs Headings -->
                  <ul class="nav nav-tabs" id="myTab" role="tablist" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 10px 10px 0px 0px;">
                    <li class="nav-item liwidth" role="presentation">
                        <button class="nav-link active w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add" type="button"
                            role="tab" aria-controls="home" aria-selected="true">Intimation</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation">
                        <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" data-bs-target="#Remove" type="button"
                            role="tab" aria-controls="Remove" aria-selected="false">Hospitalization</button>
                    </li>
                  </ul>

                  <!-- Tabs Content -->
                  <div class="tab-content" id="myTabContent" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;">
                    
                    <!-- Tab Add -->
                    <div class="tab-pane fade show active" id="Add" role="tabpanel" aria-labelledby="Add-tab">
                      <div class="table-responsive mt-2">
                        <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                          <thead>
                            <tr>
                              <th style="width:5%; position: sticky; left: 0; background-color: #efefef; z-index: 10;">SrNo</th>
                              <th>Claim Id</th>
                              <th>Member Name</th>
                              <th>Hospitalization Date</th>
                              <th>Claim Request Date</th>
                              <th>Claim Type</th>
                              <th>Claim Sub Type</th>
                              <th>Ailment</th>
                              <th>Claim Settled / Approved Amount</th>
                              <th>Claim Amount</th>
                              <th>Claim Form</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let group of InfoArray; let i = index">
                              <td style="width:5%; position: sticky; left: 0; background-color: #fff; z-index: 10;">{{i + 1}}</td>
                              <td style="position: sticky; left: 5%; background-color: #fff; z-index: 10;">{{group['tpa_intimate_no']}}</td>
                              <td>{{group['patient_name']}}</td>
                              <td>{{group['hospital_date']}}</td>
                              <td>{{group['request_date']}}</td>
                              <td>
                                <span *ngIf="group['claim_type'] == 1">Reimbursement</span>
                                <span *ngIf="group['claim_type'] == 2">Cashless</span>
                              </td>
                              <td>-</td>
                              <td>{{group['reason']}}</td>
                              <td>-</td>
                              <td>{{group['claim_amt']}}</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <!-- Tab Remove -->
                    <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab">

                    </div>

                  </div>
              </div>
              <!-- End -->

        </div>
        <app-footer></app-footer>
    </div>
</div>