import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

interface PolicyType {
  name: string;
  sub_type_id: number;
}

@Component({
  selector: 'app-broker-tpa-trigger-logs',
  templateUrl: './broker-tpa-trigger-logs.component.html',
  styleUrls: ['./broker-tpa-trigger-logs.component.css']
})
export class BrokerTpaTriggerLogsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;
  
  InfoArray: any = [];   
  InfoArrayCorporate: any = [];   
  InfoArrayAPI: any = [];   
  policy_type:  PolicyType[] = [];   
  policynumbers: any = [];   

  document_file: any;
  downloadbuttontooltip:any;
  samplefile: any;
  Policyuploaddoclist:any = [];
  addflag: any = 0;
  imgpath: any;

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  displayStyleDelete = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'TPA trigger Logs';

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup(){

    this.getClient();
    this.assignrights();

    this.basicInfo = this.fb.group({
      client_id: new FormControl(null, Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_name: new FormControl('', Validators.required),
      api: new FormControl(''),

    });

  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getClient(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    };
    
    this.service.getInfoClient(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayCorporate = result.data;

        }, (error) => {          
          
          this.InfoArrayCorporate = [];
          
      });
  }

  getPolicyType(){

    setTimeout(()=>{
      $('#policy_name').val('');
      $('#api').val('');
    },1000)

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['client_id'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data.filter((PolicyType: PolicyType) => PolicyType.sub_type_id === 1);

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    setTimeout(()=>{
      $('#api').val('');
    },1000)

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['client_id'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  
  getAPI(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    };
    
    this.service.getAPI(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayAPI = result.data;

        }, (error) => {          
          
          this.InfoArrayAPI = [];
          
      });
  }

  getInfo(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":this.basicInfo.controls['client_id'].value,
      "policy_type":this.basicInfo.controls['policy_type'].value,
      "policy_id":this.basicInfo.controls['policy_name'].value,
      "api_id":this.basicInfo.controls['api'].value,
    }

    this.service.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  } 

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

}
