<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Contact Us
                <!-- <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button> -->
            </h3>
            <!-- <hr class="mb-2 mt-4" style="height: 0px"> -->
           
            <!-- Tabs -->
            <div class="col-sm-12 mt-3">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link active w100" id="Features-tab" data-bs-toggle="tab"
                            data-bs-target="#Features" type="button" role="tab" aria-controls="Features"
                            aria-selected="true">Group Health Insurance
                        </button>
                    </li>
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="New_Hospital-tab" data-bs-toggle="tab"
                            data-bs-target="#New_Hospital" type="button" role="tab" aria-controls="New_Hospital"
                            aria-selected="false">Group Personal Accident</button>
                    </li>
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3"
                            type="button" role="tab" aria-controls="tab3" aria-selected="false">Group Term life</button>
                    </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content tabUI2" id="myTabContent2" style="padding-top: 10px;">

                    <div class="tab-pane fade show active" id="Features" role="tabpanel" aria-labelledby="Features-tab">

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="bs-form-label" for="policy_number_gmc"> <b>Policy Number </b> </label>
                                    <select class="form-select" id="policy_number_gmc" [(ngModel)]="selectedGMCPolicy" (change)="getInfoPolicyGMC()">
                                        <option value="">Select Policy Number</option>
                                        <option *ngFor="let gmcPolicyNo of gmcPolicy" [value]="gmcPolicyNo.policy_id">{{ gmcPolicyNo.policy_name }}</option>    
                                    </select>                                             
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <img src="../../../assets/img/employee/34.png" class="levelsimg" alt="img" style="margin-top: 21px;position: unset;" />
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gmcInsurer of gmcInsurer; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        TPA - {{gmcInsurer.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Insurer</h3> -->
                                        <!-- <table>
                                            <tr>
                                                <td><img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                                    Contact Person :</td>
                                                <td><span class="spanright textrelation" title="{{gmcInsurer.contact_name}}">
                                                    <b>{{gmcInsurer.contact_name}}</b>
                                                </span> </td>
                                            </tr>
                                        </table> -->
                                        
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gmcInsurer.contact_name}}">
                                                <b>{{gmcInsurer.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gmcInsurer.contact_email}}">
                                                {{gmcInsurer.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gmcInsurer.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gmcEmployer of gmcEmployer; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Employer - {{gmcEmployer.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Employer</h3> -->
                                        <h5 class="p-2" style="color: #000; margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gmcEmployer.contact_name}}">
                                                <b>{{gmcEmployer.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000; margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gmcEmployer.contact_email}}">
                                                {{gmcEmployer.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gmcEmployer.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gmcBroker of gmcBroker; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Broker - {{gmcBroker.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Broker</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gmcBroker.contact_name}}">
                                                <b>{{gmcBroker.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gmcBroker.contact_email}}">
                                                {{gmcBroker.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gmcBroker.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="tab-pane fade" id="New_Hospital" role="tabpanel" aria-labelledby="New_Hospital-tab">
                        
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="bs-form-label" for="policy_number_gpa"> <b> Policy Number </b></label>
                                    <select class="form-select" id="policy_number_gpa"  [(ngModel)]="selectedGPAPolicy" (change)="getInfoPolicyGPA()">
                                        <option value="" selected>Select Policy Number</option>
                                        <option *ngFor="let gpaPolicyNo of gpaPolicy" [value]="gpaPolicyNo.policy_id">{{ gpaPolicyNo.policy_name }}</option>    
                                    </select>                                             
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <img src="../../../assets/img/employee/34.png" class="levelsimg" alt="img" style="margin-top: 21px;position: unset;" />
                            </div>
                        </div>

                        <div class="row">
                            
                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gpaInsurer of gpaInsurer; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Insurer - {{gpaInsurer.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Insurer</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gpaInsurer.contact_name}}">
                                                <b>{{gpaInsurer.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gpaInsurer.contact_email}}">
                                                {{gpaInsurer.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gpaInsurer.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gpaEmployer of gpaEmployer; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Employer - {{gpaEmployer.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Employer</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gpaEmployer.contact_name}}">
                                                <b>{{gpaEmployer.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gpaEmployer.contact_email}}">
                                                {{gpaEmployer.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gpaEmployer.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gpaBroker of gpaBroker; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Broker - {{gpaBroker.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Broker</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gpaBroker.contact_name}}">
                                                <b>{{gpaBroker.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gpaBroker.contact_email}}">
                                                {{gpaBroker.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gpaBroker.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                        
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="bs-form-label" for="policy_number_gtl"><b> Policy Number  </b></label>
                                    <select class="form-select" id="policy_number_gtl"  [(ngModel)]="selectedGTLPolicy" (change)="getInfoPolicyGTL()">
                                        <option value="">Select Policy Number</option>
                                        <option *ngFor="let gtlPolicyNo of gtlPolicy" [value]="gtlPolicyNo.policy_id">{{ gtlPolicyNo.policy_name }}</option>    
                                    </select>                                             
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <img src="../../../assets/img/employee/34.png" class="levelsimg" alt="img" style="margin-top: 21px;position: unset;" />
                            </div>
                        </div>

                        <div class="row">
                            
                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gtlInsurer of gtlInsurer; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Insurer - {{gtlInsurer.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Insurer</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gtlInsurer.contact_name}}">
                                                <b>{{gtlInsurer.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gtlInsurer.contact_email}}">
                                                {{gtlInsurer.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gtlInsurer.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gtlEmployer of gtlEmployer; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Employer - {{gtlEmployer.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Employer</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gtlEmployer.contact_name}}">
                                                <b>{{gtlEmployer.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gtlEmployer.contact_email}}">
                                                {{gtlEmployer.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gtlEmployer.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-sm-4 p-3 pt-0" *ngFor="let gtlBroker of gtlBroker; let i = index;">
                                
                                <div>
                                    <span class="default_div" [ngStyle]="{'background-color': getColor(i)}">
                                        Broker - {{gtlBroker.level}}
                                    </span>
                                </div>

                                <div>
                                    <div class="bgdiv_div" [ngStyle]="{'border': 'solid 1px ' + getColor(i)}">                                        
                                        <!-- <h3 class="divheading">Broker</h3> -->
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/person_FILL0_wght300_GRAD0_opsz24 (2) 1.png" /> 
                                            Contact Person :
                                            <span class="spanright textrelation" title="{{gtlBroker.contact_name}}">
                                                <b>{{gtlBroker.contact_name}}</b>
                                            </span>                                            
                                        </h5>
                                        <h5 class="p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">
                                            <img src="../../../assets/img/employee/phone_iphone_FILL0_wght300_GRAD0_opsz24 1.png" />   
                                            Mobile :
                                            <span class="spanright textrelation" title="{{gtlBroker.contact_email}}">
                                                {{gtlBroker.contact_number}}
                                            </span>                                            
                                        </h5>
                                        <h5 class="view_details p-2" style="color: #000;margin-bottom: 0px; padding-bottom: 2px !important;text-align: left;margin-left: 12px;">                                            
                                            <img src="../../../assets/img/employee/mail_FILL0_wght300_GRAD0_opsz24 (1) 1.png" />                                          
                                            Email :
                                            <span class="spanright">
                                                {{gtlBroker.contact_email}}
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>