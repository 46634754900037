<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
      <div class="content-wrapper pt-3">    
        
        <div class="impdiv">
          <h2 class="text-center" style="color: #ff4800">
            <img src="../../../../assets/img/policy/notice.png" style="width: 25px; position: relative; top: -3px;" alt="Notice" />
            <b> Important Information</b>
          </h2>
          <h6 class="text-center">Nominee details will be considered for Group Personal Accident & Group Term Life policies</h6>
        </div>
  
        <!-- Cards Section -->
        <div class="cardsection">
          <div class="row" style="margin: 0px; padding: 20px;">
            
            <!-- Card -->
            <div class="col-lg-6 col-sm-12 col-md-6 mb-4" *ngFor="let data of InfoArray; let i = index;">
                <div class="cardbg cardbg p-2 pb-0">
                    
                    <img class="statusimg" src="../../../../assets/img/dashboard/active_policy.png" alt="Icon" *ngIf="data.policy_status == 1" />
                    <img class="statusimg" src="../../../../assets/img/dashboard/expired_policy.png" alt="Icon" *ngIf="data.policy_status == 0" />

                    <div class="carddiv">
                        <div class="row p-3">
                            <div class="col-sm-3">
                                <img class="cardimg" src="../../../assets/img/employee/{{data.image_url}}" alt="Icon" />
                            </div>
                            <div class="col-sm-9 px-5 py-3">
                                <h2 class="bs-title bstitle">{{data.title}}</h2>
                                <h2 class="bs-title subtitle"><b>{{data.tag}}</b></h2>
                                <h4 class="bs-title desc">{{data.description}}</h4>  
                            </div>
                        </div>    
                        
                        <div style="background-color: var(--bs-card_line); height: 28px;">
                            <h4 class="bs-title text-center pt-1" style="color:#fff; font-size:16px;"><b *ngIf="data.policy_status == 1">{{data.linktext}}</b></h4>
                        </div>
                    </div>                                           
                </div>
            </div>            
  
          </div>      
        </div>
        <!-- End -->
        
      </div>
      <app-footer></app-footer>
    </div>
  
  </div>