<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
        
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
                
            <h3 class="bs-title" *ngIf="addflag == 0">Manage <span class="spanbs-title">User Types</span>
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">User Type</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">User Type</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4" style="height: 0px">


            <!-- List Table -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Type Name</th>
                        <th>Status</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                        <td>{{group['type_name']}}</td>
                        <td>
                            <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                            <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>                
                        <td style="width: 15%" align="center">                    
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                            <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">                                        
                <form [formGroup]="AddInfo">                    
                    <div class="row">

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="type_name">User Type <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="type_name" id="type_name" placeholder="User Type"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.type_name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.type_name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.type_name.errors.required">
                                        User Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>   
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="type_data_id">User Type</label>
                                <select class="form-select" formControlName="type_data_id" id="type_data_id">
                                    <option value="0">Select User Type</option>
                                    <option *ngFor="let usertype of InfoUserTypes" [value]="usertype.type_data_id">
                                        {{usertype.type_data_title}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- Roles Module -->
                        <div class="row mt-3">

                            <h5>Roles Modules</h5>
                                                
                            <div *ngFor="let group1 of InfomoduleArray; let k = index" class="mb-3">                                                              
                                
                                <div class="row">
                    
                                    <div class="col-sm-12"> 
                                        <h6 style="color: #1f9cf8; font-weight: 600; padding: 10px; background: url('../../../assets/website/bg/header-bg.png'); background-position: left;">
                                            <span>
                                                <!-- <input type="checkbox" class="form-check-input is_active_master" name="is_active_master" id="is_active_master_{{group1['id']}}" checked style="margin-right: 10px; vertical-align: bottom;" />                                             -->
                                                {{group1['module_name']}}
                                            </span>
                                        </h6>
                                    </div>
                    
                                </div>
                                
                                <div class="row p-0 m-0" *ngFor="let child1 of InfomoduleArray[k]['child']; let l = index">
                    
                                    <div class="col-sm-6"> 
                                        <label class="form-check-label bs-radio-label">
                                            {{child1['module_name']}}
                                        </label>                                    
                                    </div>                                
                    
                                    <div class="col-sm-6 row">
                    
                                        <!-- <div class="form-check col-sm-3">
                                            <input type="checkbox" class="form-check-input child is_show" name="is_show" id="is_active_child_{{child1['id']}}" checked />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Is Active</label>
                                        </div> -->
                                        <div class="form-check col-sm-4">
                                            <input type="checkbox" class="form-check-input child read" name="Read" id="read_{{child1['id']}}" checked />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Read</label>
                                        </div>
                                        <div class="form-check col-sm-4">
                                            <input type="checkbox" class="form-check-input child write" name="Write" id="write_{{child1['id']}}" checked />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Write</label>
                                        </div>
                                        <div class="form-check col-sm-4">
                                            <input type="checkbox" class="form-check-input child delete" name="Delete" id="delete_{{child1['id']}}" checked />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Delete</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                                                    
                            </div>                
                    
                        </div>
                        <!--  -->               

                        <!-- <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="status">Status</label>
                                <select class="form-select" formControlName="status" id="status">
                                    <option value="" selected>Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>                       
                            </div>
                        </div> -->
                        
                        <div class="col-sm-12" align="center">
                            <hr style="margin: 0px;">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                        </div>
                    </div>            
                </form>                       
            </div>    

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">                                        
                <form [formGroup]="EditInfo">                    
                    <div class="row">

                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="type_name_edit">User Type <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="type_name_edit" id="type_name_edit" placeholder="User Type"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.type_name_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.type_name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.type_name_edit.errors.required">
                                        User Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>         
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>                    
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="type_data_id_edit">User Type</label>
                                <select class="form-select" formControlName="type_data_id_edit" id="type_data_id_edit">
                                    <option value="0">Select User Type</option>
                                    <option *ngFor="let usertype of InfoUserTypes" [value]="usertype.type_data_id">
                                        {{usertype.type_data_title}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- Roles Module -->
                        <div class="row mt-3">

                            <h5>Roles Modules</h5>
                                                
                            <div *ngFor="let group1_edit of InfomoduleArray_edit; let k = index" class="mb-3">                                                              
                                
                                <div class="row">
                    
                                    <div class="col-sm-12"> 
                                        <h6 style="color: #1f9cf8; font-weight: 600; padding: 10px; background: url('../../../assets/website/bg/header-bg.png'); background-position: left;">
                                            {{group1_edit['module_name']}}
                                        </h6>
                                    </div>
                    
                                </div>
                                
                                <div class="row p-0 m-0" *ngFor="let child1_edit of InfomoduleArray_edit[k]['child']; let l = index">
                    
                                    <div class="col-sm-6"> 
                                        <label class="form-check-label bs-radio-label">
                                            {{child1_edit['module_name']}}
                                        </label>                                    
                                    </div>                                
                    
                                    <div class="col-sm-6 row">
                    
                                        <div class="form-check col-sm-4">
                                            <input type="checkbox" class="form-check-input child readedit" name="Read" value="{{group1_edit['id']}}" id="read_edit_{{child1_edit['id']}}" [checked]="child1_edit.module_read" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Read</label>
                                        </div>
                                        <div class="form-check col-sm-4">
                                            <input type="checkbox" class="form-check-input child writeedit" name="Write" value="{{group1_edit['id']}}" id="write_edit_{{child1_edit['id']+child1_edit['id']}}" [checked]="child1_edit.module_write" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Write</label>
                                        </div>
                                        <div class="form-check col-sm-4">
                                            <input type="checkbox" class="form-check-input child deleteedit" name="Delete" value="{{group1_edit['id']}}" id="delete_edit_{{child1_edit['id']+child1_edit['id']}}" [checked]="child1_edit.module_delete" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Delete</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                                                    
                            </div>                
                    
                        </div>
                        <!--  -->                                

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                        </div>
                    </div>            
                </form>                       
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>