<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">My Personal Care
                <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button>
            </h3>
            <hr class="mb-2 mt-4" style="height: 0px">

            <div class="card" *ngIf="imgpath.length == 0">
                <div class="card-body">
                    <p>
                       <b>No Personal Care Available</b> 
                    </p>
                </div>
            </div>

            <div class="row">
                
                <div class="col-sm-2 mt-3"  *ngFor="let group of imgpath; let i = index">
                    <div class="divbox" (click)="gotopage(group['id'])">
                        <div class="bgwhite">
                            <img src="{{group['ancillary_logo']}}" style="width: 80px; height: 60px; margin: auto; display: block; margin-top: 10px;"/>
                        </div>  
                        <div class="text-center">
                            <span class="numtext" title="{{group['ancillary_name']}}" style="cursor: pointer;">{{group['ancillary_name']}}</span>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>