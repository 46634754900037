import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GPAPolicyDetailsService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { } 

  policyDetailsCreate_first(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();
    filedata.append("benefit_id",data.benefit_id);

    if(data.client_product_id == 0 && data.client_product_policy_id == 0){

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
          
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);
      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
          
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);      
        filedata.append("filename",data.upload_policydocument);      
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policyGPA/store';
      }else{
        val = 'client-policyGPA/policytemp-import';
      }

    }else{

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
          
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);
      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
          
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);      
        filedata.append("filename",data.upload_policydocument);      
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policyGPA/updateGPAstep1/' + data.client_product_policy_id;
      }else{
        val = 'client-policyGPA/policytemp-import';
      }

    }                        
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + val,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_second(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("benefit_id",data.benefit_id); 

    if(data.existing_policy_check == "Yes"){
      filedata.append("policy_date",data.policy_date);
      filedata.append("policy_expirydate",data.policy_expirydate);
      filedata.append("data_report_date",data.data_report_date);      
      filedata.append("policy_number",data.policy_number);
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("lives_covered",data.lives_covered);
      filedata.append("emp_atreport",data.emp_atreport);
      filedata.append("lives_atreport",data.lives_atreport);
    }else{
      filedata.append("how_soon_buy",data.how_soon_buy);      
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("choose_month",data.choose_month);      
    }
    

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGPA/updateGPAstep2/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_third(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);
    filedata.append("benefit_id",data.benefit_id);        
    
    //Sum Incured
    filedata.append("SI_type",data.SI_type),
    filedata.append("multiple_of_salary",data.multiple_of_salary),
    filedata.append("Define_flat_SI",data.Define_flat_SI),
    filedata.append("SI_Junior_management",data.SI_Junior_management),
    filedata.append("SI_Middle_management",data.SI_Middle_management),
    filedata.append("SI_Senior_management",data.SI_Senior_management),
    filedata.append("Graded_extra_fields",JSON.stringify(data.Graded_extra_fields));

    //Waiting
    filedata.append("Top_up_plan_under_accident_cover",data.Top_up_plan_under_accident_cover);
    // filedata.append("Top_up_plan_under_accident_cover_limit",data.Top_up_plan_under_accident_cover_limit);
    filedata.append("SI_type_topup",data.SI_type_topup),
    filedata.append("multiple_of_salary_topup",data.multiple_of_salary_topup),
    filedata.append("Define_flat_SI_topup",data.Define_flat_SI_topup),
    filedata.append("SI_Junior_management_topup",data.SI_Junior_management_topup),
    filedata.append("SI_Middle_management_topup",data.SI_Middle_management_topup),
    filedata.append("SI_Senior_management_topup",data.SI_Senior_management_topup),
    filedata.append("Graded_extra_fields_topup",JSON.stringify(data.Graded_extra_fields_topup));

    filedata.append("Can_spouse_Accidental_cover_be_bought",data.Can_spouse_Accidental_cover_be_bought);
    // filedata.append("Can_spouse_Accidental_cover_be_bought_limit",data.Can_spouse_Accidental_cover_be_bought_limit);
    filedata.append("AD",data.AD);
    filedata.append("PPD",data.PPD);
    filedata.append("TTD",data.TTD);

    //OPD
    filedata.append("Accidental_Death",data.Accidental_Death);
    filedata.append("Accidental_Death_limit",data.Accidental_Death_limit);
    filedata.append("Permanent_Total_Disability",data.Permanent_Total_Disability);
    filedata.append("Permanent_Total_Disability_limit",data.Permanent_Total_Disability_limit);
    filedata.append("Permanent_Partial_Disability",data.Permanent_Partial_Disability);
    filedata.append("Permanent_Partial_Disability_limit",data.Permanent_Partial_Disability_limit);
    filedata.append("Total_disability_loss_of_wages",data.Total_disability_loss_of_wages);
    filedata.append("Total_disability_loss_of_wages_limit",data.Total_disability_loss_of_wages_limit);    
    filedata.append("Total_disability_loss_of_wages_INR_per_week",data.Total_disability_loss_of_wages_INR_per_week);
    filedata.append("Total_disability_loss_of_wages_actual_weekly_salary",data.Total_disability_loss_of_wages_actual_weekly_salary);    

    //Others   
    filedata.append("Medical_benefits_Medex",data.Medical_benefits_Medex);
    filedata.append("Medical_benefits_Medex_limit",data.Medical_benefits_Medex_limit);

    filedata.append("Double_dismemberment",data.Double_dismemberment);
    // filedata.append("Double_dismemberment_limit",data.Double_dismemberment_limit);

    filedata.append("Transportation_of_mortal_remains",data.Transportation_of_mortal_remains);
    filedata.append("Transportation_of_mortal_remains_limit",data.Transportation_of_mortal_remains_limit);

    filedata.append("Ambulance_charges",data.Ambulance_charges);
    filedata.append("Ambulance_charges_limit",data.Ambulance_charges_limit);

    filedata.append("Modification_benefit",data.Modification_benefit);
    filedata.append("Modification_benefit_limit",data.Modification_benefit_limit);

    filedata.append("Education_benefit",data.Education_benefit);
    filedata.append("Education_benefit_limit",data.Education_benefit_limit);

    filedata.append("Funeral_and_Repatriation_expenses",data.Funeral_and_Repatriation_expenses);
    filedata.append("Funeral_and_Repatriation_expenses_limit",data.Funeral_and_Repatriation_expenses_limit);

    filedata.append("Carriage_of_dead_body",data.Carriage_of_dead_body);
    filedata.append("Carriage_of_dead_body_limit",data.Carriage_of_dead_body_limit);

    filedata.append("Emergency_family_travel",data.Emergency_family_travel);
    filedata.append("Emergency_family_travel_limit",data.Emergency_family_travel_limit);

    filedata.append("Animal_bites",data.Animal_bites);
    filedata.append("Animal_bites_limit",data.Animal_bites_limit);

    filedata.append("Assualt_cover",data.Assualt_cover);
    filedata.append("Assualt_cover_limit",data.Assualt_cover_limit);

    filedata.append("Adventure_sports",data.Adventure_sports);
    filedata.append("Adventure_sports_limit",data.Adventure_sports_limit);
  
    filedata.append("Account_payment_clause",data.Account_payment_clause);
    filedata.append("Account_payment_clause_limit",data.Account_payment_clause_limit);

    filedata.append("Broken_bone_coverage",data.Broken_bone_coverage);
    filedata.append("Broken_bone_coverage_limit",data.Broken_bone_coverage_limit);

    filedata.append("Alcohol_benefit",data.Alcohol_benefit);
    filedata.append("Alcohol_benefit_limit",data.Alcohol_benefit_limit);

    filedata.append("Parental_care_benefit",data.Parental_care_benefit);
    filedata.append("Parental_care_benefit_limit",data.Parental_care_benefit_limit);

    filedata.append("Terrorism_clause",data.Terrorism_clause);
    filedata.append("Terrorism_clause_limit",data.Terrorism_clause_limit);

    filedata.append("additional_benefits_extra_fields",JSON.stringify(data.others_extra_fields));

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGPA/updateGPAbenefitdata/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_fourth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  

    if(data.existing_policy_check == "Yes"){
      filedata.append("policy_document",data.policy_document);
      filedata.append("member_data",data.member_data);
      filedata.append("claims_in_last_five_years",data.claims_in_last_five_years);
    }else{
      filedata.append("member_data",data.member_data);
      filedata.append("claims_in_last_five_years",data.claims_in_last_five_years);
    }
    
    // let sizeObj: any = Object.keys(data.extra_upload_files);    
    // for (var i = 1; i <= sizeObj.length; i++) { 
    //   filedata.append("extra_upload_files_"+i, data.extra_upload_files[i][i]);
    // }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGPA/updateGPAdoc/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  getIsuranceCompanyDetails(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'insurance-comp-detailsfront',
    data,
    httpOptions);
  }

  getBrokerDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'broker-detailsfront',
    httpOptions);
  }

  getSubCategory(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'productsub-info/product/'+ data.id, 
    data,   
    httpOptions);
  }

  policyDetails_planName(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("plan_name",data.plan_name);
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGPA/updateplanGPAdetails/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }
  
}
