import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-saml2',
  templateUrl: './saml2.component.html',
  styleUrls: ['./saml2.component.css']
})
export class Saml2Component implements OnInit {

  constructor(
    private router: Router, 
    private route: ActivatedRoute,  
    private authService: AuthService
  ) { }

  id:any;
  modulesdata: any = [];

  ngOnInit(): void {
    // Accessing parameters using paramMap changes
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      // console.log(this.id);
      // Sample URL will be http://localhost:4200/saml2/asd where asd is value of key id
    });

    this.samllogin();

  }

  samllogin(){
    this.authService.samllogin(this.id)
      .subscribe((result: any) => {
        
        if (result.status == true) {

          localStorage.setItem('tokenable_id', result.data.tokenable_id);
          localStorage.setItem('username', result.data.username);
          localStorage.setItem('Token', result.data.token);
          localStorage.setItem('resstatus', result.status);
          localStorage.setItem('surveyLoginFlag', 'No');   
          localStorage.setItem('master', result.data.master);
          localStorage.setItem('saml2_flag', '1');
          
          this.fetchuserroles(result.data.tokenable_id, result.data.token);
          this.getuserdetails(result.data.tokenable_id);
        }
      },(error) => {
        // console.log(error);        
        this.router.navigate(['/login']);
    });
  }

  getuserdetails(tokenable_id:any) {
    let postdataObj = {"tokenable_id":tokenable_id};
    this.authService.getuserdetails(postdataObj)
      .subscribe((userdata: any) => {           

        if (userdata.status == true) {
        
          // console.log(userdata.data[0]['type_name']);
          localStorage.setItem('username', userdata.data[0]['username']);
          localStorage.setItem('type_name', userdata.data[0]['type_name']);
          localStorage.setItem('client_id', userdata.data[0]['client_id']);
          localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
          localStorage.setItem('company_name', userdata.data[0]['company_name']);
          localStorage.setItem('email_verify', userdata.data[0]['email_verify']);              
          localStorage.setItem('mobile_no', userdata.data[0]['mobile_no']);                  
          localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
          localStorage.setItem('name', userdata.data[0]['name']);


          // let register = 1;

          if(userdata.data[0]['type_name'] == "superadmin"){                                        
            
            this.router.navigate(['/SuperAdmin-dashboard']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);

            // setInterval(() => {
            //   console.log('logout event occured');
            // }, 5000);

          }else if(userdata.data[0]['type_name'] == "admin"){
            this.router.navigate(['/Admin-dashboard']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
          }else if(userdata.data[0]['type_name'] == "brokeradmin"){
            
            this.router.navigate(['/broker-dashboard']);
            localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);

            localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
            localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);
            
            localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
            localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
            
          }else if(userdata.data[0]['type_name'] == "employeradmin"){

            this.router.navigate(['/employer-dashboard']);
            localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
            localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
            
            localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
            localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);
            
            localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
            localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
            /******************** Dynamic Theme Color settings *****************/

            let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
            // console.log(themejson);

            //primary
            localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

            //sidebar
            localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
            // localStorage.setItem('--bs-sidebar', '#fff');

            //navbar
            localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
            // localStorage.setItem('--bs-navbar_bg', '#fff');
            localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

            //button
            localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
            localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
            localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

            //table
            localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
            localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

            //card
            localStorage.setItem('--bs-card_line', themejson.Card.card_line);
            localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

            //wizard
            localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
            localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
            /************************  END *******************/
            
          }else if(userdata.data[0]['type_name'] == "brokeruser"){
            this.router.navigate(['/brokeruser-dashboard']);
            localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
            localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
          }else if(userdata.data[0]['type_name'] == "employeruser"){

            this.router.navigate(['/employeruser-dashboard']);
            localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
            localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
            
            localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
            localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);

            localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
            localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);

            // /******************** Dynamic Theme Color settings *****************/

            let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
            // console.log(themejson);

            //primary
            localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

            //sidebar
            localStorage.setItem('--bs-sidebar', themejson.sidebar_background);

            //navbar
            localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
            localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

            //button
            localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
            localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
            localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

            //table
            localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
            localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

            //card
            localStorage.setItem('--bs-card_line', themejson.Card.card_line);
            localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

            //wizard
            localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
            localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
            /************************  END *******************/                                      

          }else if(userdata.data[0]['type_name'] == "commonhr"){
            this.router.navigate(['/hr-info-dashboard']);
            localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
            localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
          }else if(userdata.data[0]['type_name'] == "claimsexecutiveuser"){
            this.router.navigate(['/claimsexecutiveuser-dashboard']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
            localStorage.setItem('broker_id', userdata.data[0]['broker_id']);

            localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
            localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);

            localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
            localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);

            /******************** Dynamic Theme Color settings *****************/

            let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
            // console.log(themejson);

            //primary
            localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

            //sidebar
            localStorage.setItem('--bs-sidebar', themejson.sidebar_background);

            //navbar
            localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
            localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

            //button
            localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
            localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
            localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

            //table
            localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
            localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

            //card
            localStorage.setItem('--bs-card_line', themejson.Card.card_line);
            localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

            //wizard
            localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
            localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
            /************************  END *******************/

          }else{
            this.router.navigate(['/Client-dashboard']);
            localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);                    
            // if(register == 1){
            //   this.route.navigate(['/profile']);
            // }else{
            //   this.route.navigate(['/clientCreation']);
            // }
          }

        }else{

        }
        
      });      
  }

  fetchuserroles(tokenable_id:any, token:any){
    var ab = localStorage.getItem('module_info');
    if(ab==null){
      let postdata = {
        'tokenable_id':tokenable_id,      
      }
      this.authService.getuserrights(postdata, token)
        .subscribe((result: any) => {
          this.modulesdata = result.data.module_info;
          localStorage.setItem("module_info",JSON.stringify(result.data.module_info));
        }, (error) => {
            console.log(error);
      });
    }else{
      this.modulesdata = localStorage.getItem('module_info');
      this.modulesdata = JSON.parse(this.modulesdata);
    }
  }

}
