import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from '../manage-industry/service';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-manage-industry',
  templateUrl: './manage-industry.component.html',
  styleUrls: ['./manage-industry.component.css']
})
export class ManageIndustryComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      industry_name: new FormControl(''),
      industry_tag: new FormControl(''),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      industry_name_edit: new FormControl(''),
      industry_tag_edit: new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }  

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "industry_name":this.AddInfo.controls['industry_name'].value,
        "industry_tag":this.AddInfo.controls['industry_tag'].value,
        "client_id":localStorage.getItem('client_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            this.toastService.showSuccess('Success', 'Details Added Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.toastService.showFailed('Failed', 'There was some error !');                 
      });
      
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.industry_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['industry_name_edit'].setValue(result.data[0]['industry_name']);
        this.EditInfo.controls['industry_tag_edit'].setValue(result.data[0]['industry_tag']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['industry_id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "industry_name":this.EditInfo.controls['industry_name_edit'].value,
        "industry_tag":this.EditInfo.controls['industry_tag_edit'].value,
        "client_id":localStorage.getItem('client_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            this.closePopup();         
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.toastService.showFailed('Failed', 'There was some error !');                         
      });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.industry_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['industry_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');        

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } 
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');                           
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.industry_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['industry_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');        

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');                    
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
