import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OPDPolicyDetailsService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  policyDetailsCreate_first(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();
    filedata.append("benefit_id",data.benefit_id);

    if(data.client_product_id == 0 && data.client_product_policy_id == 0){

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
          
        filedata.append("select_product",data.select_product);
        // filedata.append("opd_policy_check_limit", data.opd_policy_check_limit);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("OPD_program",data.OPD_program);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);

        filedata.append("Company_paid",data.Company_paid);
        filedata.append("Company_paid_flat",data.Company_paid_flat);
        filedata.append("Company_graded_array",JSON.stringify(data.Company_graded_array));

        filedata.append("Voluntary_paid",data.Voluntary_paid);
        filedata.append("Voluntary_paid_flat",data.Voluntary_paid_flat);
        filedata.append("Voluntary_graded_array",JSON.stringify(data.Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary",data.Company_paid_or_Voluntary);
        filedata.append("Company_paid_or_Voluntary_flat",data.Company_paid_or_Voluntary_flat);
        filedata.append("Company_paid_or_Voluntary_graded_array",JSON.stringify(data.Company_paid_or_Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary_yesflow",data.Company_paid_or_Voluntary_yesflow);

      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
         
        filedata.append("select_product",data.select_product);
        // filedata.append("opd_policy_check_limit", data.opd_policy_check_limit);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check); 
        filedata.append("OPD_program",data.OPD_program);     
        filedata.append("filename",data.upload_policydocument); 
        
        filedata.append("Company_paid",data.Company_paid);
        filedata.append("Company_paid_flat",data.Company_paid_flat);
        filedata.append("Company_graded_array",JSON.stringify(data.Company_graded_array));

        filedata.append("Voluntary_paid",data.Voluntary_paid);
        filedata.append("Voluntary_paid_flat",data.Voluntary_paid_flat);
        filedata.append("Voluntary_graded_array",JSON.stringify(data.Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary",data.Company_paid_or_Voluntary);
        filedata.append("Company_paid_or_Voluntary_flat",data.Company_paid_or_Voluntary_flat);
        filedata.append("Company_paid_or_Voluntary_graded_array",JSON.stringify(data.Company_paid_or_Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary_yesflow",data.Company_paid_or_Voluntary_yesflow);

      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policyOPD/store';
      }else{
        val = 'client-policyOPD/policytemp-import';
      }

    }else{

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
        
        filedata.append("select_product",data.select_product);
        // filedata.append("opd_policy_check_limit", data.opd_policy_check_limit);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("OPD_program",data.OPD_program);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);

        filedata.append("Company_paid",data.Company_paid);
        filedata.append("Company_paid_flat",data.Company_paid_flat);
        filedata.append("Company_graded_array",JSON.stringify(data.Company_graded_array));

        filedata.append("Voluntary_paid",data.Voluntary_paid);
        filedata.append("Voluntary_paid_flat",data.Voluntary_paid_flat);
        filedata.append("Voluntary_graded_array",JSON.stringify(data.Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary",data.Company_paid_or_Voluntary);
        filedata.append("Company_paid_or_Voluntary_flat",data.Company_paid_or_Voluntary_flat);
        filedata.append("Company_paid_or_Voluntary_graded_array",JSON.stringify(data.Company_paid_or_Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary_yesflow",data.Company_paid_or_Voluntary_yesflow);

      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
         
        filedata.append("select_product",data.select_product);
        // filedata.append("opd_policy_check_limit", data.opd_policy_check_limit);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("OPD_program",data.OPD_program);      
        filedata.append("filename",data.upload_policydocument);      

        filedata.append("Company_paid",data.Company_paid);
        filedata.append("Company_paid_flat",data.Company_paid_flat);
        filedata.append("Company_graded_array",JSON.stringify(data.Company_graded_array));

        filedata.append("Voluntary_paid",data.Voluntary_paid);
        filedata.append("Voluntary_paid_flat",data.Voluntary_paid_flat);
        filedata.append("Voluntary_graded_array",JSON.stringify(data.Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary",data.Company_paid_or_Voluntary);
        filedata.append("Company_paid_or_Voluntary_flat",data.Company_paid_or_Voluntary_flat);
        filedata.append("Company_paid_or_Voluntary_graded_array",JSON.stringify(data.Company_paid_or_Voluntary_graded_array));

        filedata.append("Company_paid_or_Voluntary_yesflow",data.Company_paid_or_Voluntary_yesflow);
        
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policyOPD/updateOPDstep1/' + data.client_product_policy_id;
      }else{
        val = 'client-policyOPD/policytemp-import';
      }

    }                        
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + val,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_second(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("benefit_id",data.benefit_id); 

    if(data.existing_policy_check == "Yes"){
      filedata.append("policy_date",data.policy_date);
      filedata.append("policy_expirydate",data.policy_expirydate);
      filedata.append("data_report_date",data.data_report_date);      
      filedata.append("policy_number",data.policy_number);
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("lives_covered",data.lives_covered);
      filedata.append("emp_atreport",data.emp_atreport);
      filedata.append("lives_atreport",data.lives_atreport);
    }else{
      filedata.append("how_soon_buy",data.how_soon_buy);      
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("choose_month",data.choose_month);      
    }
    

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyOPD/updateOPDstep2/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_third(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);
    filedata.append("benefit_id",data.benefit_id);                
    
    //Family
    filedata.append("employees",data.employees);
    filedata.append("employees_limit",data.employees_limit);

    filedata.append("spouse",data.spouse);
    filedata.append("spouse_limit",data.spouse_limit);

    filedata.append("child",data.child);
    filedata.append("child_limit",data.child_limit);

    filedata.append("parents",data.parents);
    filedata.append("parents_limit",data.parents_limit);

    filedata.append("sibling",data.sibling);
    filedata.append("sibling_limit",data.sibling_limit);      

    //Others
    filedata.append("prescription_medications",data.prescription_medications);
    filedata.append("prescription_medications_limit",data.prescription_medications_limit);

    filedata.append("consultations",data.consultations);
    filedata.append("consultations_limit",data.consultations_limit);

    filedata.append("diagnostics_or_health_checkups",data.diagnostics_or_health_checkups);
    filedata.append("diagnostics_or_health_checkups_limit",data.diagnostics_or_health_checkups_limit);

    filedata.append("dental",data.dental);
    filedata.append("dental_limit",data.dental_limit);

    filedata.append("vision",data.vision);
    filedata.append("vision_limit",data.vision_limit);

    filedata.append("vision_physiotherapy_hospitalizationlimit",data.vision_physiotherapy_hospitalizationlimit);
    filedata.append("vision_physiotherapy_hospitalizationlimit_limit",data.vision_physiotherapy_hospitalizationlimit_limit);
    
    filedata.append("physiotherapy_hospitalization",data.physiotherapy_hospitalization);
    filedata.append("physiotherapy_hospitalization_limit",data.physiotherapy_hospitalization_limit);

    filedata.append("psychiatric_treatment",data.psychiatric_treatment);
    filedata.append("psychiatric_treatment_limit",data.psychiatric_treatment_limit);

    filedata.append("vaccinations",data.vaccinations);
    filedata.append("vaccinations_limit",data.vaccinations_limit);           

    filedata.append("additional_benefits_extra_fields",JSON.stringify(data.others_extra_fields));

    //Policy Restrictions
    filedata.append("copay_applicable_OPD_program",data.copay_applicable_OPD_program);
    filedata.append("copay_applicable_OPD_program_limit",data.copay_applicable_OPD_program_limit);     

    filedata.append("OPD_restricted_network",data.OPD_restricted_network);
    // filedata.append("OPD_restricted_network_limit",data.OPD_restricted_network_limit);         

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyOPD/updateOPDbenefitdata/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_fourth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("policy_document",data.policy_document);

    // console.log(data.extra_upload_files);
    let sizeObj: any = Object.keys(data.extra_upload_files);
    // console.log(sizeObj.length);
    
    for (var i = 1; i <= sizeObj.length; i++) { 
      filedata.append("extra_upload_files_"+i, data.extra_upload_files[i][i]);
    }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyOPD/updateOPDdoc/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  getIsuranceCompanyDetails(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'insurance-comp-detailsfront',
    data,
    httpOptions);
  }

  getBrokerDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'broker-detailsfront',
    httpOptions);
  }

  getSubCategory(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'productsub-info/product/'+ data.id, 
    data,   
    httpOptions);
  }

  policyDetails_planName(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("plan_name",data.plan_name);
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyOPD/updateplanOPDdetails/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }
  
}
