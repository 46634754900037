<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">My Wellness
                <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button>
            </h3>
            <hr class="mb-2 mt-4" style="height: 0px">

            <div class="card" *ngIf="imgpath.length == 0">
                <div class="card-body">
                    <p>
                       <b>No Wellness Partners Available</b> 
                    </p>
                </div>
            </div>
            <div class="row">
                
                <div class="col-sm-3 mt-3"  *ngFor="let group of imgpath; let i = index">
                    <div class="divbox">
                        <div class="bgwhite">
                            <img src="{{group['wellness_logo']}}" class="wellbeingimg" style="width: 100px; height: 70px; margin: auto; display: block;" />
                        </div>  
                      
                        <div class="text-white text-center my-3 mt-4">
                            <span class="numtext" title="{{group['benefit_description']}}" style="cursor: pointer;">{{group['benefit_name']}}</span>
                            <!-- <span class="flattext">Flat</span><br>
                            <span class="numtext">25</span>
                            <span class="pertext">%</span>
                            <span class="offtext">OFF</span> -->
                        </div>
                        <!-- <div class="text-white text-center my-3 texttitle"><b>On Online Medicines</b> </div> -->
                        <div class="imgdiv">                        
                            <a href="{{group['benefit_url']}}" target="_blank" class="btn btn-primary" role="button" style="float:right;">Order Now</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>