<div id="makertData" class="bs-stepper bg-white my-4 mb-5 rounded p-3">

    <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div> -->

    <!----- Bnner Section ------>
    <div class="row">
        <div class="col-sm-6">
            <img src="../../assets/img/selectproduct/banner.png" class="img-fluid banner" alt="Img" />
        </div>
        <div class="col-sm-6 textm">
            <h3 class="bs-title alignc">Survey</h3>
            <p class="bs-p-normal alignc"> 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                Nam non metus id augue tempor volutpat sit amet sit amet nunc.
            </p>
            <div>
                <button class="btn btn-primary mobbtn" style="padding: 6px 20px;" (click)="filldetails('GMC')">Fill Survey</button>
            </div>
        </div>
    </div>
    <hr class="my-4">
    <!--------- END ------------>

    
    <!------- Main Section --------->
    <form [formGroup]="FormInfo">
        <div class="row">    
            
            <!-- <div class="col-sm-6">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-4 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/gmc.png" class="img-fluid imgicon" alt="GMC" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Mediclaim Benefit <br> (GMC)</h5>
                            </div>                                                        
                            <div>
                                <button class="btn btn-primary mobbtn" style="padding: 6px 20px;" (click)="filldetails('GMC')">Fill Survey</button>
                            </div>                            
                        </div>                                                
                    </div> 
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="bs-form-label">Added GMC Policies</label>
                            <br>
                            <label class="bs-form-label"><b>02</b></label>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn mobbtn policybtn" style="padding: 6px 20px; float:right" (click)="gotopolicies('GMC')">Go to policies</button>
                        </div>
                    </div>                                       
                </div>
            </div>
            
            <div class="col-sm-6">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-4 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/gpa.png" class="img-fluid imgicon" alt="GPA" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Personal Accident Benefit <br> (GPA)</h5>
                            </div>                                                        
                            <div>
                                <button class="btn btn-primary mobbtn" style="padding: 6px 20px;" (click)="filldetails('GPA')">Fill Survey</button>
                            </div>                            
                        </div>                                                
                    </div> 
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="bs-form-label">Added GPA Policies</label>
                            <br>
                            <label class="bs-form-label"><b>02</b></label>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn mobbtn policybtn" style="padding: 6px 20px; float:right" (click)="gotopolicies('GPA')">Go to policies</button>
                        </div>
                    </div>                                       
                </div>
            </div>
            
            <div class="col-sm-6 mt-3">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-4 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/gtl.png" class="img-fluid imgicon" alt="GTL" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Term Life Benefit <br> (GTL)</h5>
                            </div>                                                        
                            <div>
                                <button class="btn btn-primary mobbtn" style="padding: 6px 20px;" (click)="filldetails('GTL')">Fill Survey</button>
                            </div>                            
                        </div>                                                
                    </div> 
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="bs-form-label">Added GTL Policies</label>
                            <br>
                            <label class="bs-form-label"><b>02</b></label>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn mobbtn policybtn" style="padding: 6px 20px; float:right" (click)="gotopolicies('GTL')">Go to policies</button>
                        </div>
                    </div>                                       
                </div>
            </div>
            
            <div class="col-sm-6 mt-3">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-4 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/opdgrp.png" class="img-fluid imgicon" alt="OPD" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Outpatient Benefit <br> (OPD)</h5>
                            </div>                                                        
                            <div>
                                <button class="btn btn-primary mobbtn" style="padding: 6px 20px;" (click)="filldetails('OPD')">Fill Survey</button>
                            </div>                            
                        </div>                                                
                    </div> 
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="bs-form-label">Added OPD Policies</label>
                            <br>
                            <label class="bs-form-label"><b>02</b></label>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn mobbtn policybtn" style="padding: 6px 20px; float:right" (click)="gotopolicies('OPD')">Go to policies</button>
                        </div>
                    </div>                                       
                </div>
            </div>         -->
                
        </div>
    </form>
    <!------------ END ------------->

    <!---- Plan Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayGMCPopup}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" [ngClass]="odpflag == 'YES' ? 'yesopd' : ''">
                <div class="modal-header">
                    <h4 class="modal-title" *ngIf="flag == 'GMC'">Create GMC Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'GPA'">Create GPA Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'GTL'">Create GTL Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'OPD'">Create OPD Plan</h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="PlanInfo">
                       
                        <div *ngIf="flag == 'GMC'">
                            <div class="bs-form-label alignc">
                                Do you have insurance plans?
                            </div>

                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_survey_gmc_policy_check" formControlName="survey_gmc_policy_check" [(ngModel)]="survey_gmc_policy_check" name="survey_gmc_policy_check" value="No" />
                                <label for="radio-two_survey_gmc_policy_check" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_survey_gmc_policy_check" formControlName="survey_gmc_policy_check" [(ngModel)]="survey_gmc_policy_check" name="survey_gmc_policy_check" value="Yes" />
                                <label for="radio-one_survey_gmc_policy_check" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <div *ngIf="flag == 'GPA'"> 
                            <div class="bs-form-label alignc">
                                Accident insurance benefit offered?
                            </div>                       
                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_survey_gpa_policy_check" formControlName="survey_gpa_policy_check" [(ngModel)]="survey_gpa_policy_check" name="survey_gpa_policy_check" value="No" />
                                <label for="radio-two_survey_gpa_policy_check" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_survey_gpa_policy_check" formControlName="survey_gpa_policy_check" [(ngModel)]="survey_gpa_policy_check" name="survey_gpa_policy_check" value="Yes" />
                                <label for="radio-one_survey_gpa_policy_check" class="bs-form-label">Yes</label>
                            </div>
                        </div>

                        <div *ngIf="flag == 'GTL'"> 
                            <div class="bs-form-label alignc">
                                Life insurance is offered to employees?
                            </div>                       
                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_survey_gtl_policy_check" formControlName="survey_gtl_policy_check" [(ngModel)]="survey_gtl_policy_check" name="survey_gtl_policy_check" value="No" />
                                <label for="radio-two_survey_gtl_policy_check" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_survey_gtl_policy_check" formControlName="survey_gtl_policy_check" [(ngModel)]="survey_gtl_policy_check" name="survey_gtl_policy_check" value="Yes" />
                                <label for="radio-one_survey_gtl_policy_check" class="bs-form-label">Yes</label>
                            </div>
                        </div>

                        <div *ngIf="flag == 'OPD'" class="opddiv">   

                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="bs-form-label alignc">
                                        Do you have a current OPD program?
                                    </div>                     
                                    <div class="switch-field mobswitch">                                
                                        <input type="radio" id="radio-two_survey_opd_policy_check" formControlName="survey_opd_policy_check" [(ngModel)]="survey_opd_policy_check" name="survey_opd_policy_check" value="No" />
                                        <label for="radio-two_survey_opd_policy_check" class="bs-form-label">No</label>	
                                        <input type="radio" id="radio-one_survey_opd_policy_check" formControlName="survey_opd_policy_check" [(ngModel)]="survey_opd_policy_check" name="survey_opd_policy_check" value="Yes" />
                                        <label for="radio-one_survey_opd_policy_check" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-12 form-group mt-3" id="divsurvey_OPD_coverage_limit_no" *ngIf="survey_opd_policy_check == 'No'">
                                    <!-------- CPV ------->
                                    <div class="col-sm-12 row">                                                             
                                        <label class="bs-form-label" style="text-indent: 10px;">Are you looking for company paid or Voluntary program?</label>
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Graded" formControlName="survey_survey_Company_paid_or_Voluntary" name="survey_survey_Company_paid_or_Voluntary" (click)="show_SI_div_CPV('Graded')" id="survey_survey_Company_paid_or_Voluntary" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                        </div>                                                                                        
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Flat" formControlName="survey_survey_Company_paid_or_Voluntary" name="survey_survey_Company_paid_or_Voluntary" (click)="show_SI_div_CPV('Flat')" id="survey_survey_Company_paid_or_Voluntary" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                        </div>
                                        <p class="error" id="survey_survey_Company_paid_or_Voluntary_error" style="display:none; margin-bottom: -10px;">
                                            Please select any one option 
                                        </p>
                                    </div>

                                    <div id="div_Flat_CPV" style="display:none">
                                    
                                        <div class="col-sm-12 form-group mt-3">
                                            <label class="bs-form-label" for="survey_CPV_Define_flat_SI">Flat</label>
                                            <input type="text" class="form-control" formControlName="survey_CPV_Define_flat_SI" id="survey_CPV_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                            <p class="error" id="survey_CPV_Define_flat_SI_error" style="display:none">
                                                This field is required
                                            </p>
                                        </div>
    
                                    </div>
    
                                    <div id="div_Graded_CPV" style="display:none">
                                                
                                        <div class="col-sm-12">
                                            <div class="row newgradedField_CPV">
        
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-3">
                                            <label class="bs-form-label" (click)="addGradedField_CPV()" style="cursor: pointer">
                                                <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                <span style="color: #1489F3;">Add Amount</span>
                                            </label>
                                        </div>
    
                                    </div>                                   
                                    <!------------ END ----------->
                                </div>
    
                                <div class="col-sm-12 form-group mt-3 divsurvey_OPD_coverage_limit" id="divsurvey_OPD_coverage_limit" *ngIf="survey_opd_policy_check == 'Yes'">                                    
                                    
                                    <!---------- Question --------->
                                    <div class="col-sm-12 row">                                                             
                                        <label class="bs-form-label" style="text-indent: 10px;">Is it Company Paid OR Voluntary Paid?</label>
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Company Paid" formControlName="survey_survey_survey_Company_paid_or_Voluntary_yesflow" name="survey_survey_survey_Company_paid_or_Voluntary_yesflow" (click)="show_SI_div('survey_Company_paid')" id="survey_survey_survey_Company_paid_or_Voluntary_yesflow" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Company Paid</label>
                                        </div>                                                                                        
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Voluntary Paid" formControlName="survey_survey_survey_Company_paid_or_Voluntary_yesflow" name="survey_survey_survey_Company_paid_or_Voluntary_yesflow" (click)="show_SI_div('survey_Voluntary_paid')" id="survey_survey_survey_Company_paid_or_Voluntary_yesflow" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Voluntary Paid</label>
                                        </div>
                                        <p class="error" id="survey_survey_survey_Company_paid_or_Voluntary_yesflow_error" style="display:none; margin-bottom: -10px;">
                                            Please select any one option 
                                        </p>
                                    </div>

                                    <div id="div_survey_Company_paid" style="display:none">
                                        
                                        <!-------- Company paid ------->
                                        <div class="col-sm-12 row mt-3">                                                             
                                            <label class="bs-form-label" style="text-indent: 10px;">Is it Company paid?</label>
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Graded" formControlName="survey_Company_paid" name="survey_Company_paid" (click)="show_SI_div('Graded')" id="survey_Company_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Flat" formControlName="survey_Company_paid" name="survey_Company_paid" (click)="show_SI_div('Flat')" id="survey_Company_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <p class="error" id="survey_Company_paid_error" style="display:none; margin-bottom: -10px;">
                                                Please select any one option 
                                            </p>
                                        </div>

                                        <div id="div_Flat" style="display:none">
                                        
                                            <div class="col-sm-12 form-group mt-3">
                                                <label class="bs-form-label" for="survey_Define_flat_SI">Flat</label>
                                                <input type="text" class="form-control" formControlName="survey_Define_flat_SI" id="survey_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                <p class="error" id="survey_Define_flat_SI_error" style="display:none">
                                                    This field is required
                                                </p>
                                            </div>
        
                                        </div>
        
                                        <div id="div_Graded" style="display:none">
                                                    
                                            <div class="col-sm-12">
                                                <div class="row newgradedField">
            
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <label class="bs-form-label" (click)="addGradedField()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add Amount</span>
                                                </label>
                                            </div>
        
                                        </div>                                   
                                        <!------------ END ----------->

                                    </div>

                                    <div id="div_survey_Voluntary_paid" style="display:none">
                                        
                                        <!-------- Voluntary paid ------->
                                        <div class="col-sm-12 row mt-3">                                                             
                                            <label class="bs-form-label" style="text-indent: 10px;">Is it Voluntary paid?</label>
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Graded" formControlName="survey_Voluntary_paid" name="survey_Voluntary_paid" (click)="show_SI_div_Voluntary('Graded')" id="survey_Voluntary_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Flat" formControlName="survey_Voluntary_paid" name="survey_Voluntary_paid" (click)="show_SI_div_Voluntary('Flat')" id="survey_Voluntary_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <p class="error" id="survey_Voluntary_paid_error" style="display:none; margin-bottom: -10px;">
                                                Please select any one option 
                                            </p>
                                        </div>

                                        <div id="div_Flat_Voluntary" style="display:none">
                                        
                                            <div class="col-sm-12 form-group mt-3">
                                                <label class="bs-form-label" for="survey_Voluntary_Define_flat_SI">Flat</label>
                                                <input type="text" class="form-control" formControlName="survey_Voluntary_Define_flat_SI" id="survey_Voluntary_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                <p class="error" id="survey_Voluntary_Define_flat_SI_error" style="display:none">
                                                    This field is required
                                                </p>
                                            </div>
        
                                        </div>
        
                                        <div id="div_Graded_Voluntary" style="display:none">
                                                    
                                            <div class="col-sm-12">
                                                <div class="row newgradedField_Voluntary">
            
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <label class="bs-form-label" (click)="addGradedField_Voluntary()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add Amount</span>
                                                </label>
                                            </div>
        
                                        </div>                                   
                                        <!------------ END ----------->

                                    </div>                                                                        

                                </div>                                

                            </div>                            

                        </div>                        

                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="flag == 'GMC'" class="btn btn-success" (click)="redirect(1)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'GPA'" class="btn btn-success" (click)="redirect(2)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'GTL'" class="btn btn-success" (click)="redirect(3)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'OPD'" class="btn btn-success" (click)="redirect(4)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                                       
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="font-size: 14px;"> Close</button>
                </div>
            </div>
        </div>
    </div>

    <!---- Payment Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayPayment}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Payment ID</h4>                    
                </div>
                <div class="modal-body">
                    <form [formGroup]="PaymentInfo">
                       
                        <div class="col-sm-12 form-group">
                            <label class="bs-form-label" for="payment_id">Enter Payment ID</label>
                            <input type="text" class="form-control" formControlName="payment_id" id="payment_id" placeholder="Enter Payment ID" />
                            <p class="error" id="payment_id_error" style="display:none">
                                This field is required
                            </p>
                        </div>

                        <input type="hidden" id="payment_ID" name="payment_ID" />
                                                    
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="SubmitPayment()" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Submit</button>                                        
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="font-size: 14px;"> Close</button>
                </div>
            </div>
        </div>
    </div>

</div>