<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

          <!-- Tabs -->
          <div>

            <!-- Tabs Headings -->
            <ul class="nav nav-tabs" id="myTab" role="tablist" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 10px 10px 0px 0px;">
              <li class="nav-item liwidth" role="presentation">
                  <button class="nav-link active w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add" type="button"
                      role="tab" aria-controls="home" aria-selected="true">Lock In</button>
              </li>
              <li class="nav-item liwidth" role="presentation">
                  <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" data-bs-target="#Remove" type="button"
                      role="tab" aria-controls="Remove" aria-selected="false">Lock In - SumInsured</button>
              </li>
            </ul>

            <!-- Tabs Content -->
            <div class="tab-content" id="myTabContent" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;">

              <!-- Tab Lock In -->
              <div class="tab-pane fade active show" id="Add" role="tabpanel" aria-labelledby="Add-tab">
                
                <!-- Add Form -->
                <form [formGroup]="lockinInfo">

                  <h3 class="bs-title mt-2">Policy Lock-In Upload</h3>
                  <hr>

                  <div class="row">

                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="company_id">Company <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="company_id" id="company_id" (change)="getpolicytype()">
                              <option value="" selected>Select Company</option>
                              <option *ngFor="let companydetails of companyDetails" [value]="companydetails.id">{{ companydetails.name }}</option>
                          </select>
                          <small class="error company_id_error" style="display: none;">Company is required</small>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="policy_type" id="policy_type">
                              <option value="" selected>Select Policy Type</option>
                              <option *ngFor="let type of policy_type" [value]="type.sub_type_id">{{ type.name }}</option>
                          </select>
                          <small class="error policy_type_error" style="display: none;">Policy Type is required</small>
                      </div>
                    </div>
  
                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="lockin_type">Lock-in Type <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="lockin_type" id="lockin_type" (change)="getpolicynumbers()">
                              <option value="" selected>Select Lock-in Type</option>
                              <option *ngFor="let typeres of typelistinfoArray" [value]="typeres.id">{{ typeres.type_name }}</option>
                          </select>
                          <small class="error lockin_type_error" style="display: none;">Lock-in Type is required</small>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="policy_number">Policy Number <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="policy_number" id="policy_number" (change)="getsamplefile()">
                              <option value="" selected>Select Policy Type</option>
                              <option *ngFor="let type of policynumbers" [value]="type.id">{{ type.policy_name }} / {{ type.policy_number }}</option>
                          </select>
                          <small class="error policy_number_error" style="display: none;">Policy Number is required</small>
                      </div>
                    </div>


                    <div class="col-sm-12 row mt-3">

                      <div class="col-sm-9">
                        <div class="form-group">
                          <label class="bs-form-label" for="document_file">Attached Sample File</label>
                          <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px">
                              <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                          </label>
                          <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                        </div>
                        <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .xlsx, .xls, .csv files</small>
                      </div>

                      <div class="col-sm-3" id="download_sample_file" style="text-align: center;margin-top:13px;display:none">
                          <div class="form-group mt-3">
                              <label class="bs-form-label" for=""></label>
                              <button (click)="ondownload('remove')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                          </div>
                      </div>

                    </div>

                    <div class="col-sm-12" align="center">
                      <button type="button" class="btn btn-primary mt-3" (click)="onSubmitLockIn()" role="button">Save</button>
                    </div>

                    <div class="col-sm-12 mt-3">
                      <div class="p-3" style="background-color: #EEEEEE; border-radius: 8px;">
                        <p class="m-0">
                          <b>Note:</b> Value of Cell should be Yes & No 
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          <b>Yes:</b> Relation Mandatory
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          <b>No:</b> Relation will be Hidden
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          Row A will have Employee Code
                        </p>
                      </div>                      
                    </div>

                  </div>                  

                </form>

                <!-- List -->
                <div class="mt-5">
                  <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                          <tr>
                              <th style="width:5%">SrNo</th>
                              <!-- <th>Endorsement Name</th> -->
                              <th>Policy No.</th>
                              <th>Policy Name</th>
                              <th>Company</th>
                              <th>Upload Member Status</th>
                              <th>Original Document</th>
                              <th>Failed Document</th>
                              <th>Status</th>
                              <th>Uploaded At</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let group of Policyuploaddoclist; let i = index">
                              <td style="width:5%">{{i+1}}</td>
                              <!-- <td>{{group['endorsement_no']}}</td> -->
                              <td>{{group['policy_number']}}</td>
                              <td>{{group['policy_name']}}</td>
                              <td>{{group['company_name']}}</td>
                              <td>
                                <p style="color: #0382cc; font-size: 13px;">
                                  <i class="mdi mdi-account"></i>
                                  Total Uploaded: {{group['total_member']}}
                                  <span *ngIf="group['total_member'] == 1">Member</span>
                                  <span *ngIf="group['total_member'] != 1">Members</span>
                                </p>
                                <p style="color: #32cc03; font-size: 13px;">
                                  <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                                  <span *ngIf="group['upload_type'] == 'Delete'">Delete</span>
                                  <span *ngIf="group['upload_type'] == 'Correction'">Corrected</span>
                                  <span *ngIf="group['upload_type'] == 'Added'">Delete</span>
                                  Successfully: {{group['sucess_member']}}
                                  <span *ngIf="group['sucess_member'] == 1">Member</span>
                                  <span *ngIf="group['sucess_member'] != 1">Members</span>
                                </p>
                                <p style="color: #f03800; font-size: 13px;" *ngIf="group['fail_member'] >= 1">
                                  <i class="mdi mdi-close"></i>
                                  Failed To Add: {{group['fail_member']}}
                                  <span *ngIf="group['fail_member'] == 1">Member</span>
                                  <span *ngIf="group['fail_member'] != 1">Members</span>
                                </p>
                              </td>
                              <td>
                                <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['original_document'])" style="cursor: pointer" />
                              </td>
                              <td>
                                <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                                <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'], group['policyid'])" style="cursor: pointer" *ngIf="group['fail_member'] >= 1" />
                              </td>                        
                              <td>
                                <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                                <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                                <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                              </td>
                              <td>{{group['uploaded_at']}}</td>
                          </tr>
                      </tbody>
                  </table>
                </div> 
                
              </div>

              <!-- Tab Lock In - SumInsured -->
              <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab"> 

                <!-- Add Form -->
                <form [formGroup]="lockinInfo">

                  <h3 class="bs-title mt-2">Policy Lock-In Upload</h3>
                  <hr>

                  <div class="row">

                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="company_id">Company <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="company_id" id="company_id" (change)="getpolicytype()">
                              <option value="" selected>Select Company</option>
                              <option *ngFor="let companydetails of companyDetails" [value]="companydetails.id">{{ companydetails.name }}</option>
                          </select>
                          <small class="error company_id_error" style="display: none;">Company is required</small>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="policy_type" id="policy_type">
                              <option value="" selected>Select Policy Type</option>
                              <option *ngFor="let type of policy_type" [value]="type.sub_type_id">{{ type.name }}</option>
                          </select>
                          <small class="error policy_type_error" style="display: none;">Policy Type is required</small>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="lockin_type">Lock-in Type <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="lockin_type" id="lockin_type" (change)="getpolicynumbers()">
                              <option value="" selected>Select Lock-in Type</option>
                              <option *ngFor="let typeres of typelistinfoArray" [value]="typeres.id">{{ typeres.type_name }}</option>
                          </select>
                          <small class="error lockin_type_error" style="display: none;">Lock-in Type is required</small>
                      </div>
                    </div>
  
                    <div class="col-sm-3">
                      <div class="form-group">
                          <label class="bs-form-label" for="policy_number">Policy Number <span class="redstar">*</span></label>
                          <select class="form-select" formControlName="policy_number" id="policy_number" (change)="getsamplefile()">
                              <option value="" selected>Select Policy Type</option>
                              <option *ngFor="let type of policynumbers" [value]="type.id">{{ type.policy_name }} / {{ type.policy_number }}</option>
                          </select>
                          <small class="error policy_number_error" style="display: none;">Policy Number is required</small>
                      </div>
                    </div>

                    <div class="col-sm-12 row mt-3">

                      <div class="col-sm-9">
                        <div class="form-group">
                          <label class="bs-form-label" for="document_file">Attached Sample File</label>
                          <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px">
                              <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                          </label>
                          <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                        </div>
                        <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .xlsx, .xls, .csv files</small>
                      </div>

                      <div class="col-sm-3" id="download_sample_file" style="text-align: center;margin-top:13px;display:none">
                          <div class="form-group mt-3">
                              <label class="bs-form-label" for=""></label>
                              <button (click)="ondownload('remove')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                          </div>
                      </div>

                    </div>

                    <div class="col-sm-12" align="center">
                      <button type="button" class="btn btn-primary mt-3" (click)="onSubmitLockIn()" role="button">Save</button>
                    </div>

                    <div class="col-sm-12 mt-3">
                      <div class="p-3" style="background-color: #EEEEEE; border-radius: 8px;">
                        <p class="m-0">
                          <b>Note:</b> Value of Cell should be Yes & No 
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          <b>Yes:</b> Relation Mandatory
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          <b>No:</b> Relation will be Hidden
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          Row A will have Employee Code
                        </p>
                      </div>                      
                    </div>

                  </div>                  

                </form>

                <!-- List -->
                <div class="table-responsive">
                  <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                          <tr>
                              <th style="width:5%">SrNo</th>
                              <!-- <th>Endorsement Name</th> -->
                              <th>Policy No.</th>
                              <th>Policy Name</th>
                              <th>Company</th>
                              <th>Upload Member Status</th>
                              <th>Original Document</th>
                              <th>Failed Document</th>
                              <th>Status</th>
                              <th>Uploaded At</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let group of Policyuploaddoclist; let i = index">
                              <td style="width:5%">{{i+1}}</td>
                              <!-- <td>{{group['endorsement_no']}}</td> -->
                              <td>{{group['policy_number']}}</td>
                              <td>{{group['policy_name']}}</td>
                              <td>{{group['company_name']}}</td>
                              <td>
                                <p style="color: #0382cc; font-size: 13px;">
                                  <i class="mdi mdi-account"></i>
                                  Total Uploaded: {{group['total_member']}}
                                  <span *ngIf="group['total_member'] == 1">Member</span>
                                  <span *ngIf="group['total_member'] != 1">Members</span>
                                </p>
                                <p style="color: #32cc03; font-size: 13px;">
                                  <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                                  <span *ngIf="group['upload_type'] == 'Delete'">Delete</span>
                                  <span *ngIf="group['upload_type'] == 'Correction'">Corrected</span>
                                  <span *ngIf="group['upload_type'] == 'Added'">Delete</span>
                                  Successfully: {{group['sucess_member']}}
                                  <span *ngIf="group['sucess_member'] == 1">Member</span>
                                  <span *ngIf="group['sucess_member'] != 1">Members</span>
                                </p>
                                <p style="color: #f03800; font-size: 13px;" *ngIf="group['fail_member'] >= 1">
                                  <i class="mdi mdi-close"></i>
                                  Failed To Add: {{group['fail_member']}}
                                  <span *ngIf="group['fail_member'] == 1">Member</span>
                                  <span *ngIf="group['fail_member'] != 1">Members</span>
                                </p>
                              </td>
                              <td>
                                <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['original_document'])" style="cursor: pointer" />
                              </td>
                              <td>
                                <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                                <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'], group['policyid'])" style="cursor: pointer" *ngIf="group['fail_member'] >= 1" />
                              </td>                        
                              <td>
                                <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                                <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                                <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                              </td>
                              <td>{{group['uploaded_at']}}</td>
                          </tr>
                      </tbody>
                  </table>
                </div>

              </div>             

            </div>

          </div>
          <!-- End -->                                      

        </div>
        <app-footer></app-footer>
    </div>
</div>
