import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { Chart } from 'angular-highcharts';
import { numberFormat } from 'highcharts';
import * as Highcharts from 'highcharts';
// import Chart from 'chart.js/auto';

@Component({
  selector: 'app-employer-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.css']
})
export class EmployerDashboardComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray: any = [];

  bar: Chart;
  bar1: Chart;
  bar2: Chart;
  pieChart1: Chart;
  pieChart2: Chart;
  pieChart3: Chart;
  benchmarkchart: Chart;
  summarychart: Chart;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  employerWelcomeMsg = localStorage.getItem('employerWelcomeMsg');
  clientname = localStorage.getItem('clientname');

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup() {
    this.getInfo();
    this.createPieChart1();
    this.createPieChart2();
    this.createPieChart3();
    this.createBarCharts();
    this.createBarCharts1();
    this.createBarCharts2();
    this.createCalendarChart();
    this.createCalendarChart2();
  }

  getInfo() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    }

    this.dataservice.getInfo(postdata)
      .subscribe((result: any) => {

        this.show = false;

        // console.log(result);
        this.InfoArray = result.data.dashboard;

      }, (error) => {

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/login']);
        }

        this.InfoArray = [];

      });
  }

  createPieChart1() {

    const data1 = [
      { name: 'GMC', y: 60 },
      { name: 'GPA', y: 30 },
      { name: 'GTL', y: 10 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 180 : 120;    

    this.pieChart1 = new Chart({
      chart: {
        type: 'pie',
        height: 180,
        width: chartWidth
      },
      title: {
        text: 'Premium Spent',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Data',
        type: 'pie',
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          colors: ['#173F35', '#FFBF3F', '#F9423A'],
          dataLabels: {
            enabled: false
          },
          size: '100%' // make the pie chart take up full width
        }
      },
      legend: {
        enabled: false,        
      }
    });

  }

  createPieChart2() {

    const data1 = [
      { name: 'Good', y: 50 },
      { name: 'Excellent', y: 20 },
      { name: 'Average', y: 20 },
      { name: 'Poor', y: 10 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 180 : 120;

    this.pieChart2 = new Chart({
      chart: {
        type: 'pie',
        height: 180,
        width: chartWidth
      },
      title: {
        text: 'Claims Incurred',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Data',
        type: 'pie',
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          colors: ['#173F35', '#FFBF3F', '#FFA500', '#F9423A'],
          dataLabels: {
            enabled: false
          },
          size: '100%' // make the pie chart take up full width
        }
      },
      legend: {
        enabled: false
      }
    });

  }

  createPieChart3() {

    const data1 = [
      { name: 'Good', y: 50 },
      { name: 'Excellent', y: 20 },
      { name: 'Average', y: 20 },
      { name: 'Poor', y: 10 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 340 : 200;
    // const size = window.innerWidth > 1900 ? '50%' : '20%';

    this.pieChart3 = new Chart({
      chart: {
        type: 'pie',
        height: 210,
        width: chartWidth
      },
      title: {
        text: ''
      },
      series: [{
        name: 'Data',
        type: 'pie',
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          colors: ['#173F35', '#FFBF3F', '#FFA500', '#F9423A'],
          dataLabels: {
            enabled: true, // Enable data labels
            format: '<b>{point.name}</b>: {point.percentage:.1f} %', // Display name and percentage
            distance: 30 // Position data labels outside the pie slices
          },
          size: '50%' // make the pie chart take up full width
        }
      },
      legend: {
        enabled: false
      }
    });

  }

  createBarCharts() {
    // Assuming you have data for the bar charts
    const data1 = [
      ['GMC', 40],
      ['GPA', 30],
      ['GTL', 30]
    ];

    this.bar = new Chart({
      chart: {
        type: 'bar',
        height: 210
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['GMC', 'GPA', 'GTL'],
        labels: {
          style: {
            fontWeight: 'bold', // Set the font weight to 'old'
            fontSize: '12px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [{
        name: 'Data',
        // colorByPoint: true,
        color: '#00D63C',
        type: 'bar',
        data: data1,
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },

    });

  }  

  createBarCharts1() {
    // Assuming you have data for the bar charts
    const data1 = [
      ['ICICI', 85],
      ['BAJAJ', 30],
      ['PNB', 30]
    ];

    this.bar1 = new Chart({
      chart: {
        type: 'bar',
        height: 210
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['ICICI', 'BAJAJ', 'PNB'],
        labels: {
          style: {
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [{
        name: 'Data',
        // colorByPoint: true,
        color: '#00D63C',
        type: 'bar',
        data: data1,
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },

    });

  }

  createBarCharts2() {
    // Assuming you have data for the bar charts
    const data1: [string, number][] = [
      ['INFECTIONS', 80],
      ['MATERNITY', 30],
      ['INJURY', 50],
      ['DIGESTIVE', 70],
      ['NEOPLASMS', 20],
      ['OTHERS', 65]
    ];

    // Define an array of colors for each bar
    const colors = ['#893395', '#744EC2', '#000000', '#E66C37', '#E044A7', '#118DFF'];

    this.bar2 = new Chart({
      chart: {
        type: 'bar',
        height: 210
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['INFECTIONS', 'MATERNITY', 'INJURY', 'DIGESTIVE', 'NEOPLASMS', 'OTHERS'],
        labels: {
          style: {
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [{
        name: 'Data',
        type: 'bar',
        data: data1.map((item, index) => ({
          name: item[0],
          y: item[1], // No need for parsing, y values are already numbers
          color: colors[index] // Assign color from the colors array
        })),
        pointWidth: 10
      }],
      exporting: {
        enabled: false
      },

    });
  }

  /*******/
  createCalendarChart() {  

    // Assuming you have data for the bar charts
    const data_bench = [
      ['GMC', 30],
      ['GPA', 60],
      ['GTL', 80],
      ['OPD', 40]
    ];

    this.benchmarkchart = new Chart({
      chart: {
        type: 'bar',
        height: 210
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['GMC', 'GPA', 'GTL', 'OPD'],
        labels: {
          style: {
            fontWeight: 'bold', // Set the font weight to 'old'
            fontSize: '12px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [{
        name: 'Data',
        // colorByPoint: true,
        color: '#FFBF3F',
        type: 'bar',
        data: data_bench,
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },

    });
    
  }

  createCalendarChart2() {  

    // Assuming you have data for the bar charts
    const data_bench = [
      ['GMC', 50],
      ['GPA', 20],
      ['GTL', 70]
    ];

    this.summarychart = new Chart({
      chart: {
        type: 'bar',
        height: 210
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['GMC', 'GPA', 'GTL'],
        labels: {
          style: {
            fontWeight: 'bold', // Set the font weight to 'old'
            fontSize: '12px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [{
        name: 'Data',
        colorByPoint: true, // This will enable automatic coloring
        colors: ['#FFBF3F', '#F9423A', '#173F35'], 
        type: 'bar',
        data: data_bench,
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },

    });
    
  }

}