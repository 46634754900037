import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { TopUpPolicyDetailsService } from './topuppolicy-details';

@Component({
  selector: 'app-topup-policy-details',
  templateUrl: './topup-policy-details.component.html',
  styleUrls: ['./topup-policy-details.component.css']
})
export class TopupPolicyDetailsComponent implements OnInit {

  public policyDetails:FormGroup;
  public verifypolicyDetails:FormGroup;
  public policyFeatures:FormGroup;
  public documentUpload:FormGroup;

  private stepper: Stepper;

  policyDetailsSubmitted: boolean = false;
  verifypolicyDetailsSubmitted: boolean = false;
  policyFeaturesSubmitted: boolean = false;
  documentUploadSubmitted: boolean = false;

  isuranceCompanyDetailsarr: any = [];
  tpadetailsArr: any = [];
  brokerdetailsArr: any = [];
  productsArr:  any = [];

  fileData: any;
  fileName: any = '';

  resstatus = localStorage.getItem('resstatus');
  plan_page_flag = localStorage.getItem('plan_page_flag');

  validmsg: any;

  upload_policydocument: any;
  upload_your_active_member_data: any;
  upload_claim_MIS: any;
  upload_claim_pdf_summary: any;
  policy_document: any;

  cnt = 1;
  uploadcnt = 1;
  amt = 1;
  checkopt_val = 1;

  benefitArr: any = {};
  uploadExtraArr: any = {};
  gradedArr: any = {};

  mygradedarray: any = [];
  mygradedarray_text: any = [];

  client_product_id: any = 0;
  client_product_policy_id: any = 0;
  benefit_id: any = 0;

  displayStyleThankyou = "none";

  dropdownList: any = [];
  selectedItems: any =[];
  dropdownSettings = {};
  familyArrData: any = [];
  familyArr: any = {};

  greatest_graded_value: any;

  no_flow_flag: boolean = false;

  isDisplay:any;

  stepper_flag: boolean = false;

  flag_firstcheck_FC:any = 0;
  flag_firstcheck_SI:any = 0;  
  flag_firstcheck_WPC:any = 0;

  flagFC:any = 0;
  flagSI:any = 0; 
  flagWPC:any = 0;  

  emp_flag:any = 0;
  spo_flag:any = 0;
  child_flag:any = 0;
  par_flag:any = 0;
  sb_flag:any = 0;

  metrocnt = 1;
  gmc_policy_check:any;
  addtext:any;  
  proposal_number_show: any;
  BasePlanArr: any = [];
  GradedPlanArr: any = [];

  Multiplier_of_base_Arr: any = {};
  Graded_topup_Arr: any = {};  

  stepcomplete: any = '';

  min_date: any;
  max_date: any;

  constructor(private fb: FormBuilder, private policyDetailsService: TopUpPolicyDetailsService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == null) {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();

    this.Prog(0,'FC');
    this.Prog(0,'SI');
    this.Prog(0,'waiting');

  }

  initGroup(){

    this.gmc_policy_check = localStorage.getItem('gmc_policy_check');

    this.getIsuranceCompanyDetails();
    this.getTPADetails();
    this.getBrokerDetails();
    this.getSubCategory();
    this.getBasePlan();

    if(this.plan_page_flag == ''){
      this.plan_page_flag = '';
    }else{
      this.plan_page_flag = localStorage.getItem('plan_page_flag');
    }

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    $('#basics_accord').css('display','none');
    $('#sumisured_accord').css('display','none');
    $('#waitingPeriodCondition_accord').css('display','none');
    $('#hospitalisationaccord_accord').css('display','none');
    $('#maternity_accord').css('display','none');
    $('#CCM_accord').css('display','none');
    $('#OPD_accord').css('display','none');
    $('#Others_accord').css('display','none');
    $('#AmbulanceCover_accord').css('display','none');
    $('#PC_accord').css('display','none');

    $('#EPD').prop('disabled',true);        

    // Define Stepper
    const stepperElement = document.querySelector('#stepper2');
    if(stepperElement !== null){
    this.stepper = new Stepper(stepperElement , {
          linear: false,
          animation: true
        })
    }

    // Step 1 Form element defined below
    this.policyDetails = this.fb.group({
      policy_sub_category: new FormControl(''),
      base_plan: new FormControl(''),
      proposal_number: new FormControl(''),        
      upload_check: new FormControl('manually'),
      insurance_company: new FormControl(1),
      tpa: new FormControl(1),
      broker_name: new FormControl(1),
      upload_policydocument: new FormControl('')
    });
    $('.existing_policy_check_yesno').css('display','none');
    
    // Step 2 Form element defined below                    
    this.verifypolicyDetails = this.fb.group({
      policy_date: new FormControl(0),
      how_soon_buy: new FormControl(''),
      choose_month: new FormControl(0),
      policy_expirydate: new FormControl(''),
      data_report_date: new FormControl(''),
      policy_number: new FormControl(0),        
      emp_atstart: new FormControl(''),
      lives_covered: new FormControl(0),
      emp_atreport: new FormControl(0),
      lives_atreport: new FormControl(0)
    });

    // Step 3 Form element defined below
    this.policyFeatures = this.fb.group({

      //Family
      Employees: new FormControl(''),
      On_Roll_Employees: new FormControl(true),
      Contractual_Employees: new FormControl(0),
      Retired_Employees: new FormControl(0),
      Consultants: new FormControl(0),

      Spouse: new FormControl(''),
      Legally_Wedded_Spouse: new FormControl(0),
      Domestic_Partner: new FormControl(0),
      LGBTQ_Cover: new FormControl(0),
      
      Childrens: new FormControl(''),        
      children_type: new FormControl('More Then 2 Child'),
      Age_Limit_of_the_Child: new FormControl(0),
      Age_Limit_of_the_Child_text: new FormControl(''),

      Parents: new FormControl(''),
      Parent_only: new FormControl(''),
      // Parent_or_Parent_Inlaws: new FormControl(0),
      // Parent_and_Parent_Inlaws: new FormControl(0),
      Cross_Combination_status: new FormControl('No'),
      
      Siblings_or_disabled_siblings: new FormControl(''),
      Siblings_or_disabled_siblings_status: new FormControl('No'),
      Age_Limit_of_the_Siblings: new FormControl(0),
      Age_Limit_of_the_Siblings_text: new FormControl(''),

      overall_age_range: new FormControl(''),
      age_range_min: new FormControl(),
      age_range_max: new FormControl(),

      //SI
      basis_of_topup_SI: new FormControl(''),  
      min_topup_SI: new FormControl(''),
      max_topup_SI: new FormControl(''),
      interval_topup_SI: new FormControl(''),                        
      
      //Waiting
      policy_hygien: new FormControl(''),
      entry_exit_clause: new FormControl(''),
      entry_exit_clause_limit: new FormControl(''),
      waver_of_base_SI_sub_limit: new FormControl(''),
      waver_of_base_SI_sub_limit_limit: new FormControl(''),      
      add_more_condition: new FormControl(''),

    });

    // Step 4 Form element defined below
    this.documentUpload = this.fb.group({
      upload_your_active_member_data: new FormControl(''),
      active_member_data_report_date: new FormControl(''),
      upload_claim_MIS: new FormControl(''),
      claim_MIS_date: new FormControl(''),
      upload_claim_pdf_summary: new FormControl(''),
      policy_document: new FormControl('')
    });      

    
    if(this.gmc_policy_check == 'Yes'){
      this.no_flow_flag = true;
      $('.existing_policy_check_yesno').css('display','block');
      $('.policy_date').css('display','block'); 
      $('.how_soon_buy').css('display','none');  
      $('.lives_covered').css('display','block');
      this.policyDetails.controls['insurance_company'].setValue('');
      this.policyDetails.controls['tpa'].setValue('');
      this.policyDetails.controls['broker_name'].setValue('');       
    }else{
      this.no_flow_flag = false;
      $('.existing_policy_check_yesno').css('display','none');
      $('.policy_date').css('display','none'); 
      $('.how_soon_buy').css('display','block');
      $('.lives_covered').css('display','none');        
      this.policyDetails.controls['insurance_company'].setValue(1);
      this.policyDetails.controls['tpa'].setValue(1);
      this.policyDetails.controls['broker_name'].setValue(1);                
    }

  }

  show_table(val: any){
    if(val == 'Multiplier'){
      $('.div_Multiplier').css('display', 'block');
      $('.div_Graded').css('display', 'none');
      $('.div_Open').css('display', 'none');
    }
    if(val == 'Graded'){
      $('.div_Multiplier').css('display', 'none');
      $('.div_Graded').css('display', 'block');
      $('.div_Open').css('display', 'none');
    }
    if(val == 'Open'){
      $('.div_Multiplier').css('display', 'none');
      $('.div_Graded').css('display', 'none');
      $('.div_Open').css('display', 'block');    }

  }

  checkopt_table_SI(multiplier:any, e: any, i: any){

    var base_policy_SI:any = $('#base_policy_SI_' + i).val();
    var multiplier_val:any = e.target.value;
    var openval = base_policy_SI * multiplier_val;
    $('#open_toptup_' + i).val(openval);


  } 

  mouseEnter(evt : any, val:any){      
    $("#" + val).css('display', 'block');    
  }

  mouseLeave(evt : any, val:any){
    $("#" + val).css('display', 'none');
  }

  existing_policy_check(){
    
  }

  policy_number_text(e:any){      

    this.addtext = $("#" + e.target.id + " option:selected").text();

  }

  entermanually(){
    if(this.policyDetails.controls['upload_check'].value == 'manually'){
      $('#div_upload_policydocument').css('display','none');
      $('#sample_template_excel').css('display','none');
      $('#excel_flow_save').css('display','none');
      $('#manual_flow_save').css('display','block');
    }else{
      $('#div_upload_policydocument').css('display','block');
      $('#sample_template_excel').css('display','block');
      $('#excel_flow_save').css('display','block');
      $('#manual_flow_save').css('display','none');
    }
  }

  how_soon_buy_check(evt:any){
    var val = evt.target.value;
    if(val == "Specific month"){
      $('.choose_month').css('display','block');
      $('.emp_atstart').addClass('mt-3');
    }else{
      $('.choose_month').css('display','none');
      $('.emp_atstart').removeClass('mt-3');
    }

  }

  lives_covered(evt: any){
    
    var id = evt.target.id;
    var value: any = evt.target.value.replaceAll(',', '');

    if(id == "lives_covered"){
      var temp:any = $('#emp_atstart').val();      
    }else{
      var temp:any = $('#emp_atreport').val();      
    }
    
    var emp_atstart:any = temp.replaceAll(',', '');
    console.log(value+" <",emp_atstart);

    if(value < emp_atstart){   
      console.log('yes');     
      $('#' + id).val('');        
    }else{
      console.log('no');
    }      
    
  }  

  onItemSelect(item: any) {
    // console.log(item);
    this.familyArrData.push(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
    this.familyArrData = items;
  }

  isformat(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }  
    if(evt.target.value == "0"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }    
    return true;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  get getpolicyDetailsFormControls() {
    return this.policyDetails.controls;
  }

  get getverifypolicyDetailsFormControls() {
    return this.verifypolicyDetails.controls;
  }

  get getpolicyFeaturesFormControls() {
    return this.policyFeatures.controls;
  }

  get getdocumentUploadFormControls() {
    return this.documentUpload.controls;
  }

  // Stepper Next Event
  next(step: number) {
    if (step == 1) {        
      
      this.policyDetailsSubmitted = true;
      if (this.policyDetailsSubmitted && this.policyDetails.valid) {
        if(this.policyDetails.controls['upload_check'].value == 'policy_template'){
          this.onsubmit_first('policy_template');
        }else{
          this.onsubmit_first('No');
          this.stepper.next(); 
          
          this.stepcomplete = 'step1';
          
          if(this.gmc_policy_check == "Yes"){
            this.stepper_flag = true;
            $('.step2hideFields').css('display','block');   
            this.verifypolicyDetails.controls['policy_date'].setValue('');
            this.verifypolicyDetails.controls['policy_expirydate'].setValue('');
            this.verifypolicyDetails.controls['data_report_date'].setValue('');
            this.verifypolicyDetails.controls['policy_number'].setValue('');
            this.verifypolicyDetails.controls['emp_atreport'].setValue('');
            this.verifypolicyDetails.controls['lives_atreport'].setValue('');
            this.verifypolicyDetails.controls['lives_covered'].setValue('');
            this.verifypolicyDetails.controls['emp_atstart'].setValue('');
            this.verifypolicyDetails.controls['how_soon_buy'].setValue('Immediatly');
            // $('.choose_month').css('display','none');           

          }else{
            this.stepper_flag = false;
            $('.step2hideFields').css('display','none');            
  
            this.verifypolicyDetails.controls['policy_date'].setValue(0);
            this.verifypolicyDetails.controls['policy_expirydate'].setValue(0);
            this.verifypolicyDetails.controls['data_report_date'].setValue(0);
            this.verifypolicyDetails.controls['policy_number'].setValue(0);
            this.verifypolicyDetails.controls['emp_atreport'].setValue(0);
            this.verifypolicyDetails.controls['lives_atreport'].setValue(0);   
            this.verifypolicyDetails.controls['lives_covered'].setValue(0);
            // this.verifypolicyDetails.controls['how_soon_buy'].setValue('');  
            // $('.choose_month').css('display','block');         

          }

        }          

        console.log(this.policyDetails.value);
      }
    }
    if (step == 2) {

      if(this.no_flow_flag == true){  
        console.log(this.verifypolicyDetails.value);        
        this.verifypolicyDetailsSubmitted = true;
        if (this.verifypolicyDetailsSubmitted && this.verifypolicyDetails.valid) {          
          this.onsubmit_second();
          this.stepper.next();
          this.stepcomplete = 'step2';
          console.log(this.verifypolicyDetails.value);
        }  
      }else{        
        console.log(this.verifypolicyDetails.value);  
        this.verifypolicyDetailsSubmitted = true;
        if (this.verifypolicyDetailsSubmitted && this.verifypolicyDetails.valid) {
          this.onsubmit_second();
          this.stepper.next();
          this.stepcomplete = 'step2';
          console.log(this.verifypolicyDetails.value);          
        }
      }
      
    }
    if (step == 3) {                                

      /********************** FC Show *********************/
      if ($('#basics_accord').css('display') == 'none'){
        $('#basics_accord').css('display','block');
        $('#basic_down_arrow').css('display','block');
        $('#basic_up_arrow').css('display','none');
      }
      /***************************** END *******************************/

      /********************** PC Show *********************/
      if ($('#sumisured_accord').css('display') == 'none'){
        $('#sumisured_accord').css('display','block');
        $('#sumisured_down_arrow').css('display','block');
        $('#sumisured_up_arrow').css('display','none');
      }
      /***************************** END *******************************/


      /********************** Waiting Show *********************/
      if ($('#waitingPeriodCondition_accord').css('display') == 'none'){
        $('#waitingPeriodCondition_accord').css('display','block');
        $('#WPC_down_arrow').css('display','block');
        $('#WPC_up_arrow').css('display','none');
      }
      /***************************** END *******************************/


      /******************* FC required fields *********************/        

      var errorArr_FC = ['Age_Limit_of_the_Child_text', 'Age_Limit_of_the_Siblings_text', 
            'age_range_min', 'age_range_max'];
      
      for(var i = 0; i < errorArr_FC.length; i++){
        
        var val = errorArr_FC[i];
        var id = errorArr_FC[i] + '_error';
       
        if($('#' + val + ':visible').length == 0){                                    

        }else{
          if(this.policyFeatures.controls[val].value == '' || this.policyFeatures.controls[val].value == null){
            $('#' + id).css('display', 'block');
            $('#' + val).addClass('control-red');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + val).removeClass('control-red');
          }
        }                                

      }       
      
      /************** HIDE/SHOW error ********************/
      if($('.empcheck' + ':visible').length == 0){
        
      }else{

        if($(".empcheck").is(":checked")){
          $('#empcheck_error').css('display', 'none');
        }else{
          $('#empcheck_error').css('display', 'block');            
        }          

      }                

      if($('.Employees' + ':visible').length == 0){
                  
      }else{
        var a = this.policyFeatures.controls['Employees'].value;
        if(a == ''){
          $('#empcheck_error_1').css('display', 'block');
        }else{
          $('#empcheck_error_1').css('display', 'none');            
        }
      }
      
      if($('.spousecheck' + ':visible').length == 0){
        
      }else{
        if($(".spousecheck").is(":checked")){
          $('#spousecheck_error').css('display', 'none');
        }else{
          $('#spousecheck_error').css('display', 'block');            
        }
      }

      if($('.div_Spouse' + ':visible').length == 0){          
        
      }else{                    
        var a1 = this.policyFeatures.controls['Spouse'].value;        
        if(a1 == ''){
          $('#empcheck_error_2').css('display', 'block');
        }else{
          $('#empcheck_error_2').css('display', 'none');            
        }
      }

      if($('.div_Childrens' + ':visible').length == 0){          
        
      }else{
        var a2 = this.policyFeatures.controls['Childrens'].value;
        if(a2 == ''){
          $('#empcheck_error_3').css('display', 'block');
        }else{
          $('#empcheck_error_3').css('display', 'none');            
        }
      }

      if($('.parentcheck' + ':visible').length == 0){
        
      }else{
        if($('.parentcheck').is(':checked')) { 
          $('#parentcheck_error').css('display', 'none');
        }else{
          $('#parentcheck_error').css('display', 'block');             
        }
      }

      if($('.div_Parents' + ':visible').length == 0){
                  
      }else{
        var a3 = this.policyFeatures.controls['Parents'].value;
        if(a3 == ''){
          $('#empcheck_error_4').css('display', 'block');
        }else{
          $('#empcheck_error_4').css('display', 'none');            
        }
      }

      if($('.div_Siblings_or_disabled_siblings' + ':visible').length == 0){
                  
      }else{
        var a4 = this.policyFeatures.controls['Siblings_or_disabled_siblings'].value;
        if(a4 == ''){
          $('#empcheck_error_5').css('display', 'block');
        }else{
          $('#empcheck_error_5').css('display', 'none');            
        }
      }

      if($('.div_Overall_Age_limit' + ':visible').length == 0){          
       
      }else{
        var a5 = this.policyFeatures.controls['overall_age_range'].value;
        if(a5 == ''){
          $('#empcheck_error_6').css('display', 'block');
        }else{
          $('#empcheck_error_6').css('display', 'none');            
        } 
      }
      /************* END ***********/
      /******************************  END *******************************/



      /******************* SI required fields ****************************/        

      if($('.specialcondition_check' + ':visible').length == 0){
        
      }else{

        if($(".specialcondition_check").is(":checked")){
          $('#specialcondition_check_error').css('display', 'none');
        }else{
          $('#specialcondition_check_error').css('display', 'block');            
        }          

      }      

      /****** For dynamic added amount fields *********/
      $(".open").each(function() {
        
        var value = $(this).val();
        var field_id = $(this).attr('id');
        var id = $(this).attr('id') + '_error';            

        if(value == ''){
          $('#' + id).css('display', 'block');
          $('#' + field_id).css('border', 'solid 1px #df0000');
        }else{
          $('#' + id).css('display', 'none');
          $('#' + field_id).css('border', '1px solid #ced4da');
        }

      });

      /******************* Third radio required fields ****************************/        

      var errorArr_SI3 = ['min_topup_SI', 'max_topup_SI', 'interval_topup_SI'];
      
      for(var i = 0; i < errorArr_SI3.length; i++){
        
        var val = errorArr_SI3[i];
        var id = errorArr_SI3[i] + '_error';
       
        if($('#' + val).val() == ''){
          $('#' + id).css('display', 'block');
          $('#' + val).addClass('control-red');
        }else{
          $('#' + id).css('display', 'none');
          $('#' + val).removeClass('control-red');
        }          

      }        

      $(".multiplier").each(function() {
        
        var value = $(this).val();
        var field_id = $(this).attr('id');
        var id = $(this).attr('id') + '_error';            

        if(value == ''){
          $('#' + id).css('display', 'block');
          $('#' + field_id).css('border', 'solid 1px #df0000');
        }else{
          $('#' + id).css('display', 'none');
          $('#' + field_id).css('border', '1px solid #ced4da');
        }

      });
      /*********************** END ********************/

      /******************* Special condition required fields ****************************/        

      var errorArr_SCS = ['policy_hygien', 
                          'entry_exit_clause', 'entry_exit_clause_limit', 
                          'waver_of_base_SI_sub_limit', 'waver_of_base_SI_sub_limit_limit',
                          'add_more_condition'];
      
      for(var i = 0; i < errorArr_SCS.length; i++){
        
        var val = errorArr_SCS[i];
        var id = errorArr_SCS[i] + '_error';
       
        if($('.' + val + ':visible').length == 0){

        }else{

          if(this.policyFeatures.controls[val].value == ''){
            $('#' + id).css('display', 'block');
            $('#' + val).addClass('control-red');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + val).removeClass('control-red');
          }

        }
                  
      }        

      /****** For dynamic added amount fields *********/
      $(".graded_amount").each(function() {
        
        var value = $(this).val();
        var field_id = $(this).attr('id');
        var id = $(this).attr('id') + '_error';       

        if(value == ''){
          $('#' + id).css('display', 'block');
          $('#' + field_id).css('border', 'solid 1px #df0000');
        }else{
          $('#' + id).css('display', 'none');
          $('#' + field_id).css('border', '1px solid #ced4da');
        }

      });
      /*********************** END ********************/
      

      /********************* Open/Close FC ******************/              
      
      var id_FC = '#basics_accord';        

      var errorArr_FC = ['Age_Limit_of_the_Child_text', 'Age_Limit_of_the_Siblings_text', 
            'age_range_min', 'age_range_max'];
              
      for(var i = 0; i < errorArr_FC.length; i++){
                  
        var val = errorArr_FC[i];
        var cond = $('#' + val).is(':visible');
        
        if(cond){

          // console.log('input visible');

          var cond2 = $(id_FC + ' .error').is(':visible');

          if (cond2){  
            // console.log('yes error'); 
            this.flagFC = 0;
          }else{
            // console.log('no error');
            this.flagFC = 1;
          }

        }else{
          // console.log('input not visible');            

          var cond2 = $(id_FC + ' .error').is(':visible');

          if (cond2){
            this.flagFC = 0;
          }else{
            this.flagFC = 1;
          }

        }                    
        
      }

      console.log('FC flag - ', this.flagFC);
      
      if(this.flagFC == 0){
        this.flag_firstcheck_FC = 1;
        $('#FCtick').css('display','none');
        $('#FCtick_error').css('display','block');
        if ($(id_FC).css('display') == 'none'){
          $(id_FC).css('display','block');            
          
          $('#basic_down_arrow').css('display','none');
          $('#basic_up_arrow').css('display','block');
        }

        // $('.FCheading').css('box-shadow','0px 0px 5px 2px #df0000');
        this.Prog(50, 'FC');
        
      }else{

          // $(id_FC).css('display','none');
          $(id_FC).css('display','none');            
          
          if(this.flag_firstcheck_FC == 1){
            $('#FCtick').css('display','block');
            $('#FCtick_error').css('display','none');
            $('#basic_down_arrow').css('display','block');
            $('#basic_up_arrow').css('display','none');            
            // $('.FCheading').css('box-shadow','0px 0px 5px 2px #00bf0b');            
            this.Prog(100, 'FC');
          }
        
      }        
      /**************************** END ***********************************/



      /********************* Open/Close SI ******************/              
      
      var id_SI = '#sumisured_accord';        

      var errorArr_SI = ['basis_of_topup_SI'];
              
      for(var i = 0; i < errorArr_SI.length; i++){
                  
        var val = errorArr_SI[i];
        var cond = $('#' + val).is(':visible');
        
        if(cond){

          // console.log('input visible');

          var cond2 = $(id_SI + ' .error').is(':visible');

          if (cond2){  
            // console.log('yes error'); 
            this.flagSI = 0;
          }else{
            // console.log('no error');
            this.flagSI = 1;
          }

        }else{
          // console.log('input not visible');            

          var cond2 = $(id_SI + ' .error').is(':visible');

          if (cond2){
            this.flagSI = 0;
          }else{
            this.flagSI = 1;
          }

        }                    
        
      }

      console.log('SI flag - ', this.flagSI);
      
      if(this.flagSI == 0){
        this.flag_firstcheck_SI = 1;
        $('#SItick').css('display','none');
        $('#SItick_error').css('display','block');
        if ($(id_SI).css('display') == 'none'){
          $(id_SI).css('display','block');            
          
          $('#sumisured_down_arrow').css('display','none');
          $('#sumisured_up_arrow').css('display','block');
        }

        // $('.SIheading').css('box-shadow','0px 0px 5px 2px #df0000');
        this.Prog(50, 'SI');
        
      }else{
        // $(id_SI).css('display','none');
        $(id_SI).css('display','none');

        if(this.flag_firstcheck_SI == 1){
          $('#SItick').css('display','block');          
          $('#SItick_error').css('display','none');
          $('#sumisured_down_arrow').css('display','block');
          $('#sumisured_up_arrow').css('display','none');            
          // $('.SIheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
          this.Prog(100, 'SI');
        }
      }        
      /**************************** END ***********************************/


      /********************* Open/Close WPC ******************/              
      
      var id_WPC = '#waitingPeriodCondition_accord';        

      var check_errorArr_WPC = ['policy_hygien', 'waver_of_base_SI_sub_limit'];
              
      for(var i = 0; i < check_errorArr_WPC.length; i++){
                  
        var val = check_errorArr_WPC[i];
        var cond = $('#' + val).is(':visible');
        
        if(cond){

          // console.log('input visible');

          var cond2 = $(id_WPC + ' .error').is(':visible');

          if (cond2){  
            // console.log('yes error'); 
            this.flagWPC = 0;
          }else{
            // console.log('no error');
            this.flagWPC = 1;
          }

        }else{
          // console.log('input not visible');            

          var cond2 = $(id_WPC + ' .error').is(':visible');

          if (cond2){
            this.flagWPC = 0;
          }else{
            this.flagWPC = 1;
          }

        }                    
        
      }

      console.log('SCS flag - ', this.flagWPC);
      
      if(this.flagWPC == 0){
      this.flag_firstcheck_WPC = 1;
      $('#waitingtick').css('display','none');
      $('#waitingtick_error').css('display','block');
        if ($(id_WPC).css('display') == 'none'){
          $(id_WPC).css('display','block');            
          
          $('#WPC_down_arrow').css('display','none');
          $('#WPC_up_arrow').css('display','block');
        }

        // $('.waitingheading').css('box-shadow','0px 0px 5px 2px #df0000');
        this.Prog(50, 'waiting');
        
      }else{
        // $(id_WPC).css('display','none');
        $(id_WPC).css('display','none');

        if(this.flag_firstcheck_WPC == 1){
          $('#waitingtick').css('display','block');
          $('#waitingtick_error').css('display','none');
          
          $('#WPC_down_arrow').css('display','block');
          $('#WPC_up_arrow').css('display','none');
          
          // $('.waitingheading').css('box-shadow','0px 0px 5px 2px #00bf0b');
          this.Prog(100, 'waiting');
        }
      }        
      /**************************** END ***********************************/
      
      /********* Graded Array ********/
      for(var i = 1; i < this.cnt; i++){            
        this.benefitArr[i] = {condition: $('#condition_'+i).val()};                       
      }

      let object = this.benefitArr;
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key]['condition'] === undefined || object[key]['condition'] === null){
                delete object[key];
            }
        }
      }
      console.log("before ==> ", this.benefitArr);
      /****************** END *************************/           

      
      // Multiplier of base SI Table Array
      let multipler:any = [];
      $(".multiplier").each(function(){
        console.log($(this).val());
        multipler.push($(this).val());
      });

      let topupSI:any = [];
      $(".topupSI").each(function(){
        console.log($(this).val());
        topupSI.push($(this).val());
      });

      this.Multiplier_of_base_Arr = {
        base_policy_SI: this.mygradedarray, 
        multipler_of_SI: multipler, 
        topup_SI: topupSI
      };

      // console.log(this.Multiplier_of_base_Arr);

      /************** END ***************/


      // Graded topup Arr Table Array      
      let opentopup:any = [];
      $(".opentopup").each(function(){
        console.log($(this).val());
        opentopup.push($(this).val());
      });

      this.Graded_topup_Arr = {
        base_policy_SI: this.mygradedarray, 
        select_topupSI: opentopup
      };

      // console.log(this.Graded_topup_Arr);

      /************** END ***************/


      //API Call
      console.log(this.policyFeatures.value);
      this.onsubmit_third();


      /************************************************/
      this.policyFeaturesSubmitted = true;

      if(this.flagFC == 1 && this.flagSI == 1 && this.flagWPC == 1 && 
        this.policyFeaturesSubmitted && this.policyFeatures.valid &&
        this.flag_firstcheck_FC == 1 && this.flag_firstcheck_SI == 1 && 
        this.flag_firstcheck_WPC == 1 )
        {                                            
            //Next step Call                                    
            this.stepper.next();
            this.stepcomplete = 'step3';

        }else{
        
        }                                                 

    }
    if (step == 4) {
      this.documentUploadSubmitted = true;
      if (this.documentUploadSubmitted && this.documentUpload.valid) {
        console.log(this.documentUpload.value);

        this.onsubmit_fourth();
        this.stepcomplete = 'step4';

      }
    }
  }

  // Load onclick expire date
  loadecpirydate(){

    var policy_date = this.verifypolicyDetails.controls['policy_date'].value;

    var parts = policy_date.match(/(\d+)/g);
    var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based

    var newdate = new Date(d.getTime() + 365*24*60*60*1000);
    var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];

    this.verifypolicyDetails.controls['policy_expirydate'].setValue(dateString);

    this.min_date = policy_date;
    this.max_date = dateString;

  }

  // Stepper Previous Event
  previous() {
    this.stepper.previous();
  }

  getIsuranceCompanyDetails(){
    this.policyDetailsService.getIsuranceCompanyDetails()
      .subscribe((result: any) => {

        // console.log(result[0]);
        this.isuranceCompanyDetailsarr = result;

        // for(var i = 0; i < result.length; i++){

        //   let html = `
        //     <option value="`+result[i]['insure_comp_id']+`">`+result[i]['name']+`</option>
        //   `;

        //   $('#insurance_company').append(html);
        //   $('#previous_insurer').append(html);

        // }

      }, (error) => {
          // console.log(result);
    });
  }

  getTPADetails(){
    this.policyDetailsService.getTPADetails()
      .subscribe((result: any) => {

        // console.log(result);
        this.tpadetailsArr = result;

        for(var i = 0; i < result.length; i++){

          let html = `
            <option value="`+result[i]['tpa_id']+`">`+result[i]['name']+`</option>
          `;

          $('#tpa').append(html);

        }

      }, (error) => {
          // console.log(result);
    });
  }

  getBrokerDetails(){
    this.policyDetailsService.getBrokerDetails()
      .subscribe((result: any) => {

        // console.log(result);
        this.brokerdetailsArr = result;

        for(var i = 0; i < result.length; i++){

          let html = `
            <option value="`+result[i]['broker_id']+`">`+result[i]['name']+`</option>
          `;

          $('#broker_name').append(html);

        }


      }, (error) => {
          // console.log(result);
    });

  }

  getSubCategory(){
    var postdata = {
      'id': 1
    };

    this.policyDetailsService.getSubCategory(postdata)
      .subscribe((result: any) => {

        // console.log(result.data);
        this.productsArr = result.data;

        var val;

        for(var i = 0; i < result.data.length; i++){

          if(result.data[i]['sub_product_id'] == 2){
            val = 'selected';
          }else{
            val = '';
          }

          let html = `
            <option value="`+result.data[i]['sub_product_id']+`" `+ val +`>`+result.data[i]['sub_product_name']+`</option>
          `;

          $('#policy_sub_category').append(html);

        }


      }, (error) => {
          // console.log(result);
    });

  }

  getBasePlan(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id'),
      'product_id': 1,
      'sub_product_id': 1
    };

    this.policyDetailsService.getBasePlan(postdata)
      .subscribe((result: any) => {

        // console.log(result);
        this.BasePlanArr = result.data.clientPolicyList;

        for(var i = 0; i < result.data.clientPolicyList.length; i++){

          let html = `
            <option value="`+result.data.clientPolicyList[i]['cpp_id']+`">`+result.data.clientPolicyList[i]['proposal_name']+`</option>
          `;

          $('#base_plan').append(html);

        }

      }, (error) => {
          // console.log(result);
    });
  }

  getBenefitData(val: any){

    if(val == ''){

    }else{

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_policy_id": this.client_product_policy_id,      
        "base_plan": $('#base_plan').val()
      };
  
      this.policyDetailsService.getBenefitData(postdata)
        .subscribe((result: any) => {
  
          // console.log(result.data.benifitData[0]); 
  
          //                       
          this.policyFeatures.controls['Employees'].setValue(result.data.benifitData[0]['fc_employees']);
          if(result.data.benifitData[0]['fc_employees'] == "No"){

            $('#Employees_no').css('background', '#ff4052');
            $('#Employees_no').css('color', '#fff');
            $('#Employees_yes').css('background', '#f1f1f1');
            $('#Employees_yes').css('color', '#000');
            
          }else{

            $('#Employees_no').css('background', '#217e2b');
            $('#Employees_no').css('color', '#fff');
            $('#Employees_yes').css('background', '#f1f1f1');
            $('#Employees_yes').css('color', '#000');

          }

          this.policyFeatures.controls['Spouse'].setValue(result.data.benifitData[0]['fc_spouse']);
          if(result.data.benifitData[0]['fc_spouse'] == "No"){

            $('#Spouse_no').css('background', '#ff4052');
            $('#Spouse_no').css('color', '#fff');
            $('#Spouse_yes').css('background', '#f1f1f1');
            $('#Spouse_yes').css('color', '#000');
            
          }else{

            $('#Spouse_no').css('background', '#217e2b');
            $('#Spouse_no').css('color', '#fff');
            $('#Spouse_yes').css('background', '#f1f1f1');
            $('#Spouse_yes').css('color', '#000');

          }

          this.policyFeatures.controls['Childrens'].setValue(result.data.benifitData[0]['fc_childrens']);
          if(result.data.benifitData[0]['fc_childrens'] == "No"){

            $('#Childrens_no').css('background', '#ff4052');
            $('#Childrens_no').css('color', '#fff');
            $('#Childrens_yes').css('background', '#f1f1f1');
            $('#Childrens_yes').css('color', '#000');
            
          }else{

            $('#Childrens_no').css('background', '#217e2b');
            $('#Childrens_no').css('color', '#fff');
            $('#Childrens_yes').css('background', '#f1f1f1');
            $('#Childrens_yes').css('color', '#000');

          }

          this.policyFeatures.controls['Parents'].setValue(result.data.benifitData[0]['fc_parents']);
          if(result.data.benifitData[0]['fc_parents'] == "No"){

            $('#Parents_no').css('background', '#ff4052');
            $('#Parents_no').css('color', '#fff');
            $('#Parents_yes').css('background', '#f1f1f1');
            $('#Parents_yes').css('color', '#000');
            
          }else{

            $('#Parents_no').css('background', '#217e2b');
            $('#Parents_no').css('color', '#fff');
            $('#Parents_yes').css('background', '#f1f1f1');
            $('#Parents_yes').css('color', '#000');

          }

          this.policyFeatures.controls['Siblings_or_disabled_siblings'].setValue(result.data.benifitData[0]['fc_Siblings']);
          if(result.data.benifitData[0]['fc_Siblings'] == "No"){

            $('#Siblings_or_disabled_siblings_no').css('background', '#ff4052');
            $('#Siblings_or_disabled_siblings_no').css('color', '#fff');
            $('#Siblings_or_disabled_siblings_yes').css('background', '#f1f1f1');
            $('#Siblings_or_disabled_siblings_yes').css('color', '#000');
            
          }else{

            $('#Siblings_or_disabled_siblings_no').css('background', '#217e2b');
            $('#Siblings_or_disabled_siblings_no').css('color', '#fff');
            $('#Siblings_or_disabled_siblings_yes').css('background', '#f1f1f1');
            $('#Siblings_or_disabled_siblings_yes').css('color', '#000');

          }

          this.policyFeatures.controls['overall_age_range'].setValue(result.data.benifitData[0]['fc_overall_age_range']);
          if(result.data.benifitData[0]['fc_overall_age_range'] == "No"){

            $('#overall_age_range_no').css('background', '#ff4052');
            $('#overall_age_range_no').css('color', '#fff');
            $('#overall_age_range_yes').css('background', '#f1f1f1');
            $('#overall_age_range_yes').css('color', '#000');
            
          }else{

            $('#overall_age_range_no').css('background', '#217e2b');
            $('#overall_age_range_no').css('color', '#fff');
            $('#overall_age_range_yes').css('background', '#f1f1f1');
            $('#overall_age_range_yes').css('color', '#000');

          }
  
          // Employees
          if(result.data.benifitData[0]['fc_employees'] == 'Yes'){
            this.show_family_otp1('Employees', 'yes');
          }
          if(result.data.benifitData[0]['emp_On_Roll_Employees'] == 1){
            this.policyFeatures.controls['On_Roll_Employees'].setValue(true);
          }
          if(result.data.benifitData[0]['emp_Contractual_Employees'] == 1){
            this.policyFeatures.controls['Contractual_Employees'].setValue(true);
          }
          if(result.data.benifitData[0]['emp_Retired_Employees'] == 1){
            this.policyFeatures.controls['Retired_Employees'].setValue(true);
          }
          if(result.data.benifitData[0]['emp_Consultants'] == 1){
            this.policyFeatures.controls['Consultants'].setValue(true);
          }
  
          // Spouse
          if(result.data.benifitData[0]['fc_spouse'] == 'Yes'){
            this.show_family_otp1('Spouse', 'yes');
          }
          if(result.data.benifitData[0]['sp_Legally_Wedded_Spouse'] == 1){
            this.policyFeatures.controls['Legally_Wedded_Spouse'].setValue(true);
          }
          if(result.data.benifitData[0]['sp_Domestic_Partner'] == 1){
            this.policyFeatures.controls['Domestic_Partner'].setValue(true);
          }
          if(result.data.benifitData[0]['sp_LGBTQ_Cover'] == 1){
            this.policyFeatures.controls['LGBTQ_Cover'].setValue(true);
          }
  
          // Parents
          if(result.data.benifitData[0]['fc_parents'] == 'Yes'){
            this.show_family_otp1('Parents', 'yes');
          }
          if(result.data.benifitData[0]['pa_Cross_Combination_status'] == "Parent only"){
            $(".Cross_Combination_status").css('display', 'none');
          }else{
            $(".Cross_Combination_status").css('display', 'contents');
          }
          this.policyFeatures.controls['Parent_only'].setValue(result.data.benifitData[0]['pa_parentinfo']);
          this.policyFeatures.controls['Cross_Combination_status'].setValue(result.data.benifitData[0]['pa_Cross_Combination_status']);
  
          // Childrens
          if(result.data.benifitData[0]['fc_childrens'] == 'Yes'){
            this.show_family_otp1('Childrens', 'yes');
          }
          this.policyFeatures.controls['children_type'].setValue(result.data.benifitData[0]['ch_children_type']);
          this.policyFeatures.controls['Age_Limit_of_the_Child_text'].setValue(result.data.benifitData[0]['ch_Age_Limit_of_the_Child']);        
  
          //Siblings
          if(result.data.benifitData[0]['fc_Siblings'] == 'Yes'){
            this.show_family_otp1('Siblings_or_disabled_siblings', 'yes');
          }
          this.policyFeatures.controls['Age_Limit_of_the_Siblings_text'].setValue(result.data.benifitData[0]['sb_Age_Limit_of_the_Siblings_text']);
  
          // Age limit
          if(result.data.benifitData[0]['fc_overall_age_range'] == 'Yes'){
            this.show_family_otp1('overall_age_range', 'yes');
          }
          if(result.data.benifitData[0]['age_range_max'] == 'null'){
            this.policyFeatures.controls['age_range_max'].setValue('');
          }else{
            this.policyFeatures.controls['age_range_max'].setValue(result.data.benifitData[0]['age_range_max']);
          }
          if(result.data.benifitData[0]['age_range_min'] == 'null'){
            this.policyFeatures.controls['age_range_min'].setValue('');
          }else{
            this.policyFeatures.controls['age_range_min'].setValue(result.data.benifitData[0]['age_range_min']);
          }
          
          this.GradedPlanArr = JSON.parse(result.data.benifitData[0]['sic_sum_insured_graded']);
          // console.log(this.GradedPlanArr['graded_sum_insured_amount']);
  
          if(result.data.benifitData[0]['sic_sum_insured_approach'] == 'Graded'){
            this.mygradedarray = [];
            this.mygradedarray = this.GradedPlanArr['graded_sum_insured_amount'];
          }else{
            this.mygradedarray = [];
            this.mygradedarray[0] = this.GradedPlanArr;
          }

          // console.log(this.mygradedarray);
          
        }, (error) => {
            // console.log(result);
      });

    }
    
  }

  /********* GET Upload Files Data ************/

  // step 1 field
  upload_policydocument_change(event: any){
    if (event.target.files.length > 0) {

      this.upload_policydocument = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_upload_policydocument").empty();
      $('#custom-file-upload_upload_policydocument').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_policydocument').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_policydocument').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_policydocument').empty();
      $('#small_upload_policydocument').html('');
      $('#small_upload_policydocument').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }
  }

  // step 4 fields
  upload_your_active_member_data_change(event: any){
    if (event.target.files.length > 0) {
      // this.upload_your_active_member_data = event.target.files[0];
      this.upload_your_active_member_data = event.target.files[0];
      // console.log(event.target.files[0]);

      $("#custom-file-upload_upload_your_active_member_data").empty();
      $('#custom-file-upload_upload_your_active_member_data').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_your_active_member_data').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_your_active_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_your_active_member_data').empty();
      $('#small_upload_your_active_member_data').html('');
      $('#small_upload_your_active_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }
  }

  upload_claim_MIS_change(event: any){
    if (event.target.files.length > 0) {

      this.upload_claim_MIS = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_upload_claim_MIS").empty();
      $('#custom-file-upload_upload_claim_MIS').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_claim_MIS').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_claim_MIS').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_claim_MIS').empty();
      $('#small_upload_claim_MIS').html('');
      $('#small_upload_claim_MIS').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }

  upload_claim_pdf_summary_change(event: any) {
    if (event.target.files.length > 0) {

      this.upload_claim_pdf_summary = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_upload_claim_pdf_summary").empty();
      $('#custom-file-upload_upload_claim_pdf_summary').html(event.target.files[0]['name']);
      $('#custom-file-upload_upload_claim_pdf_summary').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_upload_claim_pdf_summary').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_upload_claim_pdf_summary').empty();
      $('#small_upload_claim_pdf_summary').html('');
      $('#small_upload_claim_pdf_summary').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }

  policy_document_change(event: any) {
    if (event.target.files.length > 0) {

      this.policy_document = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_policy_document").empty();
      $('#custom-file-upload_policy_document').html(event.target.files[0]['name']);
      $('#custom-file-upload_policy_document').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_policy_document').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_policy_document').empty();
      $('#small_policy_document').html('');
      $('#small_policy_document').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }
  /********************************************/


  /******************************/
  basicaccord(){
    this.flag_firstcheck_FC = 1;
    if($('#basics_accord').css('display') == 'none'){
      $('#basics_accord').slideToggle(1000);
      // $('#basics_accord').css('display','block');
      $('#basic_down_arrow').css('display','none');
      $('#basic_up_arrow').css('display','block');
    }else{
      $('#basics_accord').slideToggle(1000);
      // $('#basics_accord').css('display','none');
      $('#basic_down_arrow').css('display','block');
      $('#basic_up_arrow').css('display','none');
    }
  }

  sumisuredaccord(){
    this.flag_firstcheck_SI = 1;
    if($('#sumisured_accord').css('display') == 'none'){
      $('#sumisured_accord').slideToggle(1000);
      // $('#basics_accord').css('display','block');
      $('#sumisured_down_arrow').css('display','none');
      $('#sumisured_up_arrow').css('display','block');
    }else{
      $('#sumisured_accord').slideToggle(1000);
      // $('#basics_accord').css('display','none');
      $('#sumisured_down_arrow').css('display','block');
      $('#sumisured_up_arrow').css('display','none');
    }
  }

  waitingPeriodCondition(){
    this.flag_firstcheck_WPC = 1;
    if($('#waitingPeriodCondition_accord').css('display') == 'none'){
      $('#waitingPeriodCondition_accord').slideToggle(1000);
      // $('#waitingPeriodCondition_accord').css('display','block');
      $('#WPC_down_arrow').css('display','none');
      $('#WPC_up_arrow').css('display','block');
    }else{
      $('#waitingPeriodCondition_accord').slideToggle(1000);
      // $('#waitingPeriodCondition_accord').css('display','none');
      $('#WPC_down_arrow').css('display','block');
      $('#WPC_up_arrow').css('display','none');
    }
  }
  /*****************************/


  /***** Add Benefit Field *****/
  addBenefitField(text: any){

    if(text == 'yes'){

      $('.divcondition').css("display", "block");

      let html = `
      <div id="this_benefit_row_`+this.cnt+`" class="row">      
        <div class="col-sm-5 form-group mt-2">
            <label class="bs-form-label" for="condition_`+this.cnt+`">Condition </label>
            <input type="text" class="form-control graded_amount" formControlName="condition_`+this.cnt+`" id="condition_`+this.cnt+`" />
            <p class="error" id="condition_`+this.cnt+`_error" style="display:none; color: #df0000; font-size: 13px;">
                This field is required
            </p>
        </div>      
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newbenifitField').append(html);

      // this.policyFeatures.addControl("benefit_name_"+this.cnt, new FormControl(''));
      // this.policyFeatures.addControl("benefit_limit_"+this.cnt, new FormControl(''));

      var bname = "benefit_name_"+this.cnt;
      var blimit = "benefit_limit_"+this.cnt;

      this.benefitArr[this.cnt] = {bname: "", blimit: ""};

      let flag = this;
      $("#benefit_row_"+this.cnt).on('click',function(e) {
        flag.removeBenefitRow(e);
      });

      $("#benefit_limit_"+this.cnt).on('keyup',function(e) {
        flag.isnumberBenefit(e);
      });
      
      $("#benefit_limit_"+this.cnt).on('input',function(e) {
        flag.isformatbenefit(e);
      });

      this.cnt++;

      // console.log(this.cnt);
      
    }else{          
      $('.divcondition').css("display", "none");
    }
    
  }

  removeBenefitRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_benefit_row_' + id;
    $(idstr).remove();

  }


  isnumberBenefit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  isformatbenefit(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

  /***** Add Upload Field *****/
  addUploadField(){

    let html = `
    <div class="row" id="this_upload_row_`+this.uploadcnt+`"> 
      <div class="col-sm-11">
          <div class="form-group mt-3">
              <label class="bs-form-label" for="upload_field_`+this.uploadcnt+`">Document</label>

              <label for="file_field_extra`+this.uploadcnt+`" id="custom-file-upload_`+this.uploadcnt+`" class="custom-file-upload form-control extrafile" style="text-align:center; cursor:pointer">
                  <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
              </label>
              <input id="file_field_extra`+this.uploadcnt+`" type="file" accept="application/pdf" class="extrafile" enctype="multipart/form-data" style="display: none" />
              <input id="`+this.uploadcnt+`" type="hidden" class="filehidden" />

          </div>
          <small style="font-size: 12px; color:#605D62" id="small_extra`+this.uploadcnt+`">Upload only PDF files (Max size 4MB)</small>
      </div>
      <div class="col-sm-1 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    // $('.newuploadField').append(html);

    $(html).insertBefore('.newuploadField');

    var uploadcnt = this.uploadcnt;
    const extraUploadArr: any = {};

    $('.extrafile').on("change", function(event: any, extrafileArr: []){

      // console.log(uploadcnt);

      if (event.target.files.length > 0) {

        extrafileArr = event.target.files[0];

        $("#custom-file-upload_" + uploadcnt).empty();
        $('#custom-file-upload_' + uploadcnt).html(event.target.files[0]['name']);
        $('#custom-file-upload_' + uploadcnt).css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_' + uploadcnt).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_extra'+ uploadcnt).empty();
        $('#small_extra'+ uploadcnt).html('');
        $('#small_extra'+ uploadcnt).append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

      // console.log(extrafileArr);

      extraUploadArr[uploadcnt] = extrafileArr;
      // console.log(extraUploadArr);

    });

    // console.log(extraUploadArr);
    this.uploadExtraArr[this.uploadcnt] = extraUploadArr;
    console.log(this.uploadExtraArr);      

    let flag = this;
    $("#upload_row_"+this.uploadcnt).on('click',function(e) {
      flag.removeUploadRow(e);
    });

    this.uploadcnt++;

  }

  removeUploadRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_upload_row_' + id;
    $(idstr).remove();
    delete this.uploadExtraArr[id];      
    console.log(this.uploadExtraArr);      
  }
  /************** Step 3 download links download files ********/
  downloadcsv(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
    link.setAttribute('download', `Sample Template.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadclaimMIS(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
    link.setAttribute('download', `Sample Template.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadPolicyDetails(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/policy_details.xlsx');
    link.setAttribute('download', `Policy Details.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }  

  /************* show_family_otp ************/
  show_family_otp1(val:any, text: any){     

    // console.log(text,val);
    if(text == 'yes'){
      $('#show_family_otp_' + val).css('display', 'flex');
      $('#thead_' + val).css('display', 'table-cell');
      $('#tbody_' + val).css('display', 'table-cell');
      $('#deductible_tbody_' + val).css('display', 'table-cell');
      $('#sum_insured_tbody_' + val).css('display', 'table-cell');
      $('#claim_diseasewisecapping_condition_tbody_' + val).css('display', 'table-cell');

      $('#'+val+'_'+text).css('background', '#217e2b');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_no').css('background', '#f1f1f1');
      $('#'+val+'_no').css('color', '#000');

      //metro table
      // $('.metro_table').css('display', 'block');
      $('#tbody_PC_' + val).css('display', 'contents'); 
      
      if(val == 'Employees'){
        this.emp_flag = 1;
      }
      if(val == 'Spouse'){
        this.spo_flag = 1;
      }
      if(val == 'Childrens'){
        this.child_flag = 1;
      }
      if(val == 'Parents'){
        this.par_flag = 1;
      }
      if(val == 'Siblings_or_disabled_siblings'){
        this.sb_flag = 1;
      }
                                      
    }else{
      $('#show_family_otp_' + val).css('display', 'none');
      $('#thead_' + val).css('display', 'none');
      $('#tbody_' + val).css('display', 'none');
      $('#deductible_tbody_' + val).css('display', 'none');
      $('#sum_insured_tbody_' + val).css('display', 'none');
      $('#claim_diseasewisecapping_condition_tbody_' + val).css('display', 'none');

      $('#'+val+'_'+text).css('background', '#ff4052');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_yes').css('background', '#f1f1f1');
      $('#'+val+'_yes').css('color', '#000');

      //metro
      $('#tbody_PC_' + val).css('display', 'none');

      if(val == 'Employees'){
        this.emp_flag = 0;
      }
      if(val == 'Spouse'){
        this.spo_flag = 0;
      }
      if(val == 'Childrens'){
        this.child_flag = 0;
      }
      if(val == 'Parents'){
        this.par_flag = 0;
      }
      if(val == 'Siblings_or_disabled_siblings'){
        this.sb_flag = 0;
      }

    }

    /********* Check if all no then hide metrol table **********/                  

    // console.log(this.emp_flag,this.spo_flag,this.child_flag,this.par_flag,this.sb_flag);

    if(this.emp_flag == 0 && this.spo_flag == 0 && this.child_flag == 0 && this.par_flag == 0 && this.sb_flag == 0){
      $('.PC_table').css('display', 'none');
    }else{
      $('.PC_table').css('display', 'block');
    }
    /************************** END ****************************/

    /************* SHOW/HIDE each section **********************/

    if(val == 'Employees'){
      $('.div_Spouse').css('display', 'flex');
    }
    if(val == 'Spouse'){
      $('.div_Childrens').css('display', 'flex');
    }
    if(val == 'Childrens'){
      $('.div_Parents').css('display', 'flex');
    }
    if(val == 'Parents'){
      $('.div_Siblings_or_disabled_siblings').css('display', 'flex');
    }
    if(val == 'Siblings_or_disabled_siblings'){
      $('.div_Overall_Age_limit').css('display', 'flex');
    }
    /************************** END ****************************/   
    
    /********************** SHOW/HIDE maternity section ********/     

    if(this.policyFeatures.controls['Employees'].value == 'No' && 
    this.policyFeatures.controls['Spouse'].value == 'No' &&
    this.policyFeatures.controls['Parents'].value == 'Yes' &&
    this.policyFeatures.controls['Childrens'].value == 'No' &&
    this.policyFeatures.controls['Siblings_or_disabled_siblings'].value == 'No'){
      $("#root_maternity").css('display', 'none');       
    }else{
      $("#root_maternity").css('display', 'block');        
    }
    
    /********************** END ********************************/

  }
  
  age_limit(evt: any, val:any){

    let id = evt.target.id;                    
    var value: any = evt.target.value;

    if(val == 'children'){
      if(value > 35 || value == 0){
        $('#' + id).val('');
      }
    }
    if(val == 'sibling'){
      if(value > 60 || value == 0){
        $('#' + id).val('');
      }
    }                     
    if(val == 'agerange'){
      
      if(value > 100){
        $('#' + id).val('');
      }
              
      if(value == '00'){
        $('#' + id).val('');
      }

    }                     

  }
  

  /**************/
  check_SI_for_parents(event: any){
    let val: any;
    val = event.target.value;
    var str:any = event.target.id;
    var id = str.charAt(str.length -1 );
    // console.log(id);
    // console.log(this.greatest_graded_value);
    if(val > this.greatest_graded_value){
      $('#SI_for_parents_error_' + id).css('display', 'block');
      var valid = '#SI_for_parents_' + id;
      $(valid).val('');
    }else{
      $('#SI_for_parents_error_' + id).css('display', 'none');
    }
  }
  

  /************** Submit *************/  
  onsubmit_first(value: any){

    var postdata;
    var text_policy_sub_category = $("#policy_sub_category option:selected").text();      


    if(this.gmc_policy_check == 'Yes'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "benefit_id": this.benefit_id,

        "policy_sub_category": this.policyDetails.controls['policy_sub_category'].value,
        "base_plan": this.policyDetails.controls['base_plan'].value,
        // "proposal_number": this.policyDetails.controls['proposal_number'].value,
        "select_product": 1,

        "existing_policy_check":this.gmc_policy_check,
        "upload_check":this.policyDetails.controls['upload_check'].value,
        "insurance_company":this.policyDetails.controls['insurance_company'].value,
        "tpa":this.policyDetails.controls['tpa'].value,
        "broker_name":this.policyDetails.controls['broker_name'].value,        
        "upload_policydocument": this.upload_policydocument
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "benefit_id": this.benefit_id,
         
        "policy_sub_category": this.policyDetails.controls['policy_sub_category'].value,
        "base_plan": this.policyDetails.controls['base_plan'].value,
        // "proposal_number": this.policyDetails.controls['proposal_number'].value,
        "select_product": 1,

        "existing_policy_check":this.gmc_policy_check,
        "upload_check":this.policyDetails.controls['upload_check'].value,
        "upload_policydocument": this.upload_policydocument          
      }
    }      

    this.policyDetailsService.policyDetailsCreate_first(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            this.benefit_id = result.data.benefit_id;
            this.client_product_id = result.data.client_product_id;
            this.client_product_policy_id = result.data.client_product_policy_id;

            // if(value == 'policy_template'){
            //   this.displayStyleThankyou = "block";
            // }else{

            // }

            // this.validmsg = result.message;
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            // var flag = this;
            // setTimeout(function() {
            //   // flag.route.navigate(['/Client-dashboard']);
            // }, 3000);


          } else {
            // console.log(result);
          }
        }, (error) => {
          // this.displayStyleThankyou = "block";
          // this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');
      });

  }

  onsubmit_second(){
    var postdata;
    if(this.no_flow_flag == true){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),          
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "existing_policy_check": this.gmc_policy_check,
        "benefit_id": this.benefit_id,

        "policy_date":this.verifypolicyDetails.controls['policy_date'].value,
        "policy_expirydate":this.verifypolicyDetails.controls['policy_expirydate'].value,
        "data_report_date":this.verifypolicyDetails.controls['data_report_date'].value,          
        "policy_number":this.verifypolicyDetails.controls['policy_number'].value,
        "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value,
        "lives_covered":this.verifypolicyDetails.controls['lives_covered'].value,
        "emp_atreport":this.verifypolicyDetails.controls['emp_atreport'].value,
        "lives_atreport":this.verifypolicyDetails.controls['lives_atreport'].value
      }
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_id": this.client_product_id,
        "client_product_policy_id": this.client_product_policy_id,
        "benefit_id": this.benefit_id,

        "how_soon_buy":this.verifypolicyDetails.controls['how_soon_buy'].value,
        "choose_month":this.verifypolicyDetails.controls['choose_month'].value,
        "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value
      }
    }      

    this.policyDetailsService.policyDetailsCreate_second(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            this.benefit_id = result.data.benefit_id;            

            // this.validmsg = result.message;
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            var flag = this;
            setTimeout(function() {
              // flag.route.navigate(['/Client-dashboard']);
            }, 3000);            

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

  }

  onsubmit_third(){      

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id'),      
      "client_product_id": this.client_product_id,
      "client_product_policy_id": this.client_product_policy_id,
      "existing_policy_check": this.gmc_policy_check,
      "benefit_id": this.benefit_id,

      // Family
      "Employees": this.policyFeatures.controls['Employees'].value,
      "On_Roll_Employees": this.policyFeatures.controls['On_Roll_Employees'].value,
      "Contractual_Employees": this.policyFeatures.controls['Contractual_Employees'].value,
      "Retired_Employees": this.policyFeatures.controls['Retired_Employees'].value,
      "Consultants": this.policyFeatures.controls['Consultants'].value,

      "Spouse": this.policyFeatures.controls['Spouse'].value,
      "Legally_Wedded_Spouse": this.policyFeatures.controls['Legally_Wedded_Spouse'].value,
      "Domestic_Partner": this.policyFeatures.controls['Domestic_Partner'].value,
      "LGBTQ_Cover": this.policyFeatures.controls['LGBTQ_Cover'].value,

      "Childrens": this.policyFeatures.controls['Childrens'].value,        
      "children_type": this.policyFeatures.controls['children_type'].value,
      "Age_Limit_of_the_Child": this.policyFeatures.controls['Age_Limit_of_the_Child_text'].value,
      // "Age_Limit_of_the_Child_text": this.policyFeatures.controls['Age_Limit_of_the_Child_text'].value,

      "Parents": this.policyFeatures.controls['Parents'].value,
      "Parent_only": this.policyFeatures.controls['Parent_only'].value,
      // "Parent_or_Parent_Inlaws": this.policyFeatures.controls['Parent_or_Parent_Inlaws'].value,
      // "Parent_and_Parent_Inlaws": this.policyFeatures.controls['Parent_and_Parent_Inlaws'].value,        
      "Cross_Combination_status": this.policyFeatures.controls['Cross_Combination_status'].value,        
      
      "Siblings_or_disabled_siblings": this.policyFeatures.controls['Siblings_or_disabled_siblings'].value,
      "Siblings_or_disabled_siblings_status": this.policyFeatures.controls['Siblings_or_disabled_siblings_status'].value,
      "Age_Limit_of_the_Siblings": this.policyFeatures.controls['Age_Limit_of_the_Siblings'].value,
      "Age_Limit_of_the_Siblings_text": this.policyFeatures.controls['Age_Limit_of_the_Siblings_text'].value,
      
      "overall_age_range": this.policyFeatures.controls['overall_age_range'].value,
      "age_range_min": this.policyFeatures.controls['age_range_min'].value,
      "age_range_max": this.policyFeatures.controls['age_range_max'].value,            
      
      // SI
      "basis_of_topup_SI": this.policyFeatures.controls['basis_of_topup_SI'].value,   
      "min_topup_SI": this.policyFeatures.controls['min_topup_SI'].value,
      "max_topup_SI": this.policyFeatures.controls['max_topup_SI'].value,
      "interval_topup_SI": this.policyFeatures.controls['interval_topup_SI'].value,
      "Multiplier_of_base_Arr": this.Multiplier_of_base_Arr, 
      "Graded_topup_Arr": this.Graded_topup_Arr,

      // Special codition
      "policy_hygien": this.policyFeatures.controls['policy_hygien'].value,                        
      "entry_exit_clause": this.policyFeatures.controls['entry_exit_clause'].value,
      "entry_exit_clause_limit": this.policyFeatures.controls['entry_exit_clause_limit'].value,                        
      "add_more_condition": this.policyFeatures.controls['add_more_condition'].value,                        
      "waver_of_base_SI_sub_limit": this.policyFeatures.controls['waver_of_base_SI_sub_limit'].value,
      "waver_of_base_SI_sub_limit_limit": this.policyFeatures.controls['waver_of_base_SI_sub_limit_limit'].value,
      "mygradedarray": this.benefitArr, 

    }

    this.policyDetailsService.policyDetailsCreate_third(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            // if(this.gmc_policy_check == "No"){
            //   this.displayStyleThankyou = "block";
            //   var flag = this;
            //   setTimeout(function() {
            //     flag.route.navigate(['/Client-dashboard']);
            //   }, 3000);
            // }else{

            // }

            // this.benefit_id = result.data.benefit_id;

            // this.validmsg = result.message;              
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');              

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

  }

  onsubmit_fourth(){      

    // const propertyNames = Object.keys(this.uploadExtraArr);
    // console.log("==>", propertyNames);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":localStorage.getItem('client_id'),      
      "client_product_id": this.client_product_id,
      "client_product_policy_id": this.client_product_policy_id,
      "existing_policy_check": this.gmc_policy_check,

      "policy_document": this.policy_document,
      "extra_upload_files": this.uploadExtraArr
    }

    this.policyDetailsService.policyDetailsCreate_fourth(postdata)
        .subscribe((result: any) => {

          // console.log(result);          

          if (result.status == true) {

            // console.log(result);

            this.proposal_number_show = result.data.proposal_number;
            this.displayStyleThankyou = "block";

            $('#sticky-footer').css('opacity', '0');
            $('.navbar').css('opacity', '0');
            $('#sidebar-wrapper').css('z-index', '0');

            // $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            
            // var flag = this;
            // setTimeout(function() {
            //   flag.route.navigate(['/Client-dashboard']);
            // }, 3000);

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

  }

  closePopup() {

    var val = $('#plan_name').val();

    if(val == ''){
      
      $('#plan_name_error').css('display','block');
      $('#plan_name').addClass('control-red');

    }else{
      
      $('#plan_name_error').css('display','none');
      this.displayStyleThankyou = "none";

      $('#sticky-footer').css('opacity', '1');
      $('.navbar').css('opacity', '1');
      $('#sidebar-wrapper').css('z-index', '500');

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),
        "client_product_policy_id": this.client_product_policy_id,
        "plan_name": val
      }
      
      this.policyDetailsService.policyDetails_planName(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            // console.log(result);

            // this.displayStyleThankyou = "block";

            // $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            // this.route.navigate(['/select-product']);


          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

      this.route.navigate(['/select-product']);        
    }
    
  }

  show_cross_combination(val: any){
    if(val == "Parent only"){
      $(".Cross_Combination_status").css('display', 'none');
    }else{
      $(".Cross_Combination_status").css('display', 'contents');
    }
  }
  
  checkradio(id: any, val: any){

    // console.log(id,val);
    if(val == "Covered"){
      $('#'+id).css("display", "block");
    }else{
      $('#'+id).css("display", "none");
    }

    if(val == "Covered"){
      $('.'+id).css("display", "block");
    }else{
      $('.'+id).css("display", "none");
    }        

  }

  show_policydiv(val:any, text: any){     

    if(text == 'yes'){
      
      $('.morediv').css('display', 'block');
      $('.yesdivpolicy').css('display', 'block'); 
      $('.nodivpolicy').css('display', 'none');           
                                      
    }else{

      $('.morediv').css('display', 'block');
      $('.yesdivpolicy').css('display', 'none');      
      $('.nodivpolicy').css('display', 'block');      

    }

  }

  changepage(e: any){
      
    var val = e.target.value;

    localStorage.setItem('plan_page_flag', val);

    if(val == 1){
      this.route.navigate(['/policy-details']);
    }
    if(val == 2){
      this.route.navigate(['/topup-policy-details']);
    }

  }

  getbenefitdata(e:any){
    this.getBenefitData(e.target.value);
  }
  
  Prog(val:any, text:any){
    let id = "#Prog" + text;
    let value = "#Value" + text;
    let progressBar:any = document.querySelector(id);
    let valueContainer:any = document.querySelector(value);
    let progressValue = 0;
    let progressEndValue = val;
    let speed = 30;

    if(val == 0){

      let progress = setInterval(() => {
        // progressValue++;
        valueContainer.textContent = `${progressValue}%`;
        progressBar.style.background = `conic-gradient(
            #58D68C ${progressValue * 3.6}deg,
            #DEDEDE ${progressValue * 3.6}deg
        )`;
        if (progressValue == progressEndValue) {
          clearInterval(progress);
        }
      }, speed);

    }else{

      let progress = setInterval(() => {
        progressValue++;
        valueContainer.textContent = `${progressValue}%`;
        progressBar.style.background = `conic-gradient(
            #58D68C ${progressValue * 3.6}deg,
            #DEDEDE ${progressValue * 3.6}deg
        )`;
        if (progressValue == progressEndValue) {
          clearInterval(progress);
        }
      }, speed);

    }
    
  }

  purple_white_change(id:any, val:any){

    if(val == 'yes'){

      $('#'+id+'_'+val).css('background', '#4e0099');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_no').css('background', '#f1f1f1');
      $('#'+id+'_no').css('color', '#000');

    }else{

      $('#'+id+'_'+val).css('background', '#4e0099');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_yes').css('background', '#f1f1f1');
      $('#'+id+'_yes').css('color', '#000');

    }

  }

  green_red_change(id:any, val:any){

    if(val == 'yes'){

      $('#'+id+'_'+val).css('background', '#217e2b');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_no').css('background', '#f1f1f1');
      $('#'+id+'_no').css('color', '#000');

    }else{

      $('#'+id+'_'+val).css('background', '#ff4052');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_yes').css('background', '#f1f1f1');
      $('#'+id+'_yes').css('color', '#000');

    }

  }

  red_green_change(id:any, val:any){

    if(val == 'no'){
      
      $('#'+id+'_'+val).css('background', '#217e2b');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_yes').css('background', '#f1f1f1');
      $('#'+id+'_yes').css('color', '#000');        

    }else{

      $('#'+id+'_'+val).css('background', '#ff4052');
      $('#'+id+'_'+val).css('color', '#fff');
      $('#'+id+'_no').css('background', '#f1f1f1');
      $('#'+id+'_no').css('color', '#000');        

    }

  }

}
