<div id="stepper2" class="bs-stepper bg-white my-4 mb-5 rounded p-3 mainstepper_div">
    
    <div class="flagDiv">
        
        <span class="plan_step3">GTL</span>

        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1" style="margin-left: 20px;">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Product</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Policy Details</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Policy Features</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">4</span>
                    <span class="bs-stepper-label">Document Upload</span>
                </button>
            </div>
        </div>
    </div>

    <div class="progressroot">
        <div [ngClass]="{'step1': stepcomplete === 'step1'}"></div>
        <div [ngClass]="{'step2': stepcomplete === 'step2'}"></div>
        <div [ngClass]="{'step3': stepcomplete === 'step3'}"></div>
        <div [ngClass]="{'step4': stepcomplete === 'step4'}"></div>
    </div>
    
    <div class="bs-stepper-content">

            <div id="test-l-1" class="content">                
                <form [formGroup]="policyDetails">
                    <h3 class="bs-title">Product Name </h3>
                    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    <hr>
                    <div class="row">                                                                                             

                        <!--------Section 3------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label">Policy Details</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8 row">                            

                            <div class="form-group">
                                <div class="mb-2 bs-form-label">Benefit Structure <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <input type="radio" class="form-radio" value="manually" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Enter Manually </span>                            
                                &nbsp;
                                <input type="radio" class="form-radio" value="policy_template" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Upload Excel Template </span>                                                        
                                &nbsp;
                                <input type="radio" class="form-radio" value="existing_doc" formControlName="upload_check" id="upload_check" style="pointer-events:none;" /> <span class="bs-radio-label" style="color:#AAAAAA"> Upload Policy PDF </span>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadPolicyDetails()">Download Sample Template</span>                                                                        
                            </div>
                            

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="insurance_company">Insurance company <span class="redstar">*</span></label>
                                <!-- <select class="form-select" formControlName="insurance_company" id="insurance_company" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                required >
                                    <option value="" selected>Select</option>
                                </select>                            
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container> -->

                                <ng-select formControlName="insurance_company" id="insurance_company" 
                                            [items]="isuranceCompanyDetailsarr" 
                                            bindLabel="name" 
                                            bindValue="insure_comp_id"
                                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                            required>                                    
                                </ng-select>
                                 <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container>
                                
                            </div>                                                   

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="broker_name">Broker Name <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="broker_name" id="broker_name" style="line-height: 1.7;"
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors">
                                    <p class="error">
                                    Broker Name is required
                                    </p>
                                </ng-container>
                            </div>                        
                                                
                            <div class="form-group mt-3" id="div_upload_policydocument" style="display: none">
                                <label class="bs-form-label" for="upload_policydocument">Upload policy document</label>
                                <label for="file" id="custom-file-upload_upload_policydocument" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_policydocument" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                </label>
                                <input id="file" type="file" (change)="upload_policydocument_change($event)" enctype="multipart/form-data" accept=".xlsx, .xls, .csv, application/pdf" style="display:none" />
                                <small style="font-size: 12px; color:#605D62" id="small_upload_policydocument">Upload only .xlxs, .xls files (Max size 4MB)</small>

                            </div>
                        </div>
                        <hr class="my-3">
                        <!--------END------->

                        <div>
                            <button (click)="next(1)" id="manual_flow_save" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="next(1)" id="excel_flow_save" class="btn btn-primary" style="float: right;display:none;">Upload</button>
                        </div>
                    
                    </div>
                    
                </form>
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="verifypolicyDetails">
                <h3 class="bs-title">Policy Details</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Policy Details</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6 form-group policy_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="policy_date">Policy inception date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="policy_date" id="policy_date" (change)="loadecpirydate()"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors">
                                    <p class="error">
                                    Policy inception date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group how_soon_buy">
                                <label class="bs-form-label" for="how_soon_buy">How soon you planning to buy <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="how_soon_buy" id="how_soon_buy" (change)="how_soon_buy_check($event)"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Immediatly">Immediatly</option>
                                    <option value="Within a month">Within a month</option>
                                    <option value="Specific month">Specific month</option>
                                    <option value="Not yet decided">Not yet decided</option>
                                </select>                            
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors">
                                    <p class="error">
                                    This is required field
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group step2hideFields">
                                <label class="bs-form-label" for="policy_expirydate">Policy expiry date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="policy_expirydate" id="policy_expirydate"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 data_report_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="data_report_date">Data report date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="data_report_date" id="data_report_date" min="{{min_date}}" max="{{max_date}}"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors">
                                    <p class="error">
                                    Data report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group choose_month" style="display:none">
                                <label class="bs-form-label" for="choose_month">Choose month <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="choose_month" id="choose_month"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors}"
                                required>
                                    <option value="0" selected>Select</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 step2hideFields">
                                <label class="bs-form-label" for="policy_number">Policy number <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="340100502022000275"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors">
                                    <p class="error">
                                    Policy number is required
                                    </p>
                                </ng-container>
                            </div>                                                        
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-sm-4">
                            <div class="p-5 px-0 pb-0 step2hideFields">
                                <label class="bs-form-label" style="color:#000">Lives at Inception</label>
                            </div>
                            <div class="p-5 px-0 pt-3 step2hideFields">
                                <label class="bs-form-label" style="position: relative; top: 30px;color:#000">Lives at Renewal</label>
                            </div>
                        </div>
                        <div class="row col-sm-8 p-0 m-0">
                            <div class="col-sm-6 form-group emp_atstart" style="margin-top: 16px !important;"> 
                                <label class="bs-form-label" for="emp_atstart">No of employees <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="emp_atstart" id="emp_atstart" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors">
                                    <p class="error">
                                    No of employees at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group lives_covered mt-3 step2hideFields" style="display:none">
                                <label class="bs-form-label" for="lives_covered">No of total lives <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="lives_covered" id="lives_covered" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors">
                                    <p class="error">
                                    No of lives covered at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="emp_atreport">No of employees <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="emp_atreport" id="emp_atreport" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors">
                                    <p class="error">
                                    No of employees as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="lives_atreport">No of total lives <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="lives_atreport" id="lives_atreport" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors">
                                    <p class="error">
                                    No of lives covered as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>                

                </form>
            </div>

            <div id="test-l-3" class="content">

                <!--------- Banner ---------->
                <div class="row">
                    <div class="col-sm-6">
                        <img src="../../assets/img/policy/policybanner.png" class="img-fluid" alt="Img" style="display:block; margin:auto; width:300px" />
                    </div>
                    <div class="col-sm-6 textm">
                        <h3 class="bs-title alignc">Policy Features</h3>
                        <p class="bs-p-normal alignc"> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                            Nam non metus id augue tempor volutpat sit amet sit amet nunc.
                        </p>
                    </div>
                </div>
                <hr class="my-4">
                <!--------- END ------------>

                <form [formGroup]="policyFeatures">
                <!-- <h3 class="bs-title">Policy Features</h3> -->
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <!-- <hr> -->              

                <!---- Sum Insured Coverage Accordian ---->
                <h5 class="bs-left-form-label accordheading SIheading" (click)="sumisuredaccord()">Sum Assured Coverage
                    <img id="sumisured_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="sumisured_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/sum-insured-coverage.png" class="headicon" />
                    <img id="SItick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="SItick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgSI">
                        <div class="value-container" id="ValueSI">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="sumisured_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-3"> -->
                            <!-- <h5 class="bs-left-form-label">Sum Insured Coverage</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">                                                                

                                <label class="bs-form-label" style="text-indent: 10px;">What is the basis of Group Term Life (GTL) sum assured?</label>

                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Multiple of Salary" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Multiple of Salary')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiple of Salary</label>
                                </div>                                                                                        
                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Flat" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Flat')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                </div>
                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Graded" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Graded')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                </div>                                

                                <div id="div_Multiple_of_Salary" style="display:none">
                                    
                                    <div class="col-sm-4 form-group mt-3">
                                        <label class="bs-form-label" for="multiple_of_salary">Define the multiple of salary? <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="multiple_of_salary" id="multiple_of_salary" (change)="checkMOS($event)">
                                            <option value="" selected>Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>                            
                                        <p class="error" id="multiple_of_salary_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                </div>

                                <div id="div_Flat" style="display:none">
                                    
                                    <div class="col-sm-4 form-group mt-3">
                                        <label class="bs-form-label" for="Define_flat_SI">Define flat sum insured under accidental plan <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Define_flat_SI" id="Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="Define_flat_SI_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                </div>

                                <div id="div_Graded" style="display:none">

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Junior_management">Define graded accidental sum insured- Junior management <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="SI_Junior_management" id="SI_Junior_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Junior_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Middle_management">Define graded accidental sum insured- Middle management <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="SI_Middle_management" id="SI_Middle_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Middle_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Senior_management">Define graded accidental sum insured- Senior management <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="SI_Senior_management" id="SI_Senior_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Senior_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="row newgradedField">
    
                                        </div>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <label class="bs-form-label" (click)="addGradedField()" style="cursor: pointer">
                                            <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                            <span style="color: #1489F3;">Add Amount</span>
                                        </label>
                                    </div>

                                </div>                                

                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="actively_at_work">Actively at Work Clause - Waived <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'actively_at_work_div')" (mouseleave)="mouseLeave($event, 'actively_at_work_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="actively_at_work_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Actively at Work Clause - Waived</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_actively_at_work" formControlName="actively_at_work" name="actively_at_work" value="No" (change)="checkradio('divactively_at_work_limit', 'NotCovered'); green_red_change('actively_at_work', 'no')" />
                                        <label for="radio-two_actively_at_work" id="actively_at_work_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_actively_at_work" formControlName="actively_at_work" name="actively_at_work" value="Yes" (change)="checkradio('divactively_at_work_limit', 'Covered'); green_red_change('actively_at_work', 'yes')" />
                                        <label for="radio-one_actively_at_work" id="actively_at_work_yes" class="bs-form-label">Yes</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-8 form-group" id="divactively_at_work_limit" style="display: none">
                                    <div class="row p-0 m-0 w100" id="show_family_otp_Employees" style="margin-top: 25px !important;">
                                        <div class="form-check col-sm-6">
                                            <input type="checkbox" class="form-check-input empcheck" value="Waived For all Employees" formControlName="Waived_For_all_Employees" name="Waived_For_all_Employees" id="Waived_For_all_Employees" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Waived For all Employees</label>
                                        </div>
                                        <div class="form-check col-sm-6">
                                            <input type="checkbox" class="form-check-input empcheck" value="Only For Existing Employees" formControlName="Only_For_Existing_Employees" name="Only_For_Existing_Employees" id="Only_For_Existing_Employees" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Only For Existing Employees</label>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!----------------------------->                

                <!---- Additional Benefits Accordian ---->
                <h5 class="bs-left-form-label accordheading additionalbenefitsheading" (click)="Othersaccord()">Additional Benefits
                    <img id="Others_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="Others_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/benefit.png" class="headicon" />
                    <img id="additionalbenefitstick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="additionalbenefitstick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgOthers">
                        <div class="value-container" id="ValueOthers">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="Others_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Others</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Terminal_illness">Terminal illness <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Terminal_illness_div')" (mouseleave)="mouseLeave($event, 'Terminal_illness_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Terminal_illness_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Terminal illness</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Terminal_illness" formControlName="Terminal_illness" name="Terminal_illness" value="No" (change)="checkradio('divTerminal_illness_limit', 'NotCovered'); green_red_change('Terminal_illness', 'no')" />
                                            <label for="radio-two_Terminal_illness" id="Terminal_illness_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Terminal_illness" formControlName="Terminal_illness" name="Terminal_illness" value="Yes" (change)="checkradio('divTerminal_illness_limit', 'Covered'); green_red_change('Terminal_illness', 'yes')" />
                                            <label for="radio-one_Terminal_illness" id="Terminal_illness_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divTerminal_illness_limit" style="display: none">
                                        <label class="bs-form-label" for="Terminal_illness_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Terminal_illness_limit" id="Terminal_illness_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Terminal_illness_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Accidental_death_benefit">Accidental Death Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accidental_death_benefit_div')" (mouseleave)="mouseLeave($event, 'Accidental_death_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Accidental_death_benefit_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Accidental Death Benefit</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Accidental_death_benefit" formControlName="Accidental_death_benefit" name="Accidental_death_benefit" value="No" (change)="checkradio('divAccidental_death_benefit_limit', 'NotCovered'); green_red_change('Accidental_death_benefit', 'no')" />
                                            <label for="radio-two_Accidental_death_benefit" id="Accidental_death_benefit_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Accidental_death_benefit" formControlName="Accidental_death_benefit" name="Accidental_death_benefit" value="Yes" (change)="checkradio('divAccidental_death_benefit_limit', 'Covered'); green_red_change('Accidental_death_benefit', 'yes')" />
                                            <label for="radio-one_Accidental_death_benefit" id="Accidental_death_benefit_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAccidental_death_benefit_limit" style="display: none">
                                        <label class="bs-form-label" for="Accidental_death_benefit_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Accidental_death_benefit_limit" id="Accidental_death_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Accidental_death_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>                                						                                                               

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Accidental_permananent_Total_Disability">Accidental Permanent Total Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accidental_permananent_Total_Disability_div')" (mouseleave)="mouseLeave($event, 'Accidental_permananent_Total_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Accidental_permananent_Total_Disability_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Accidental Permanent Total Disability</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Accidental_permananent_Total_Disability" formControlName="Accidental_permananent_Total_Disability" name="Accidental_permananent_Total_Disability" value="No" (change)="checkradio('divAccidental_permananent_Total_Disability_limit', 'NotCovered'); green_red_change('Accidental_permananent_Total_Disability', 'no')" />
                                            <label for="radio-two_Accidental_permananent_Total_Disability" id="Accidental_permananent_Total_Disability_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Accidental_permananent_Total_Disability" formControlName="Accidental_permananent_Total_Disability" name="Accidental_permananent_Total_Disability" value="Yes" (change)="checkradio('divAccidental_permananent_Total_Disability_limit', 'Covered'); green_red_change('Accidental_permananent_Total_Disability', 'yes')" />
                                            <label for="radio-one_Accidental_permananent_Total_Disability" id="Accidental_permananent_Total_Disability_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAccidental_permananent_Total_Disability_limit" style="display: none">
                                        <label class="bs-form-label" for="Accidental_permananent_Total_Disability_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Accidental_permananent_Total_Disability_limit" id="Accidental_permananent_Total_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Accidental_permananent_Total_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Accidental_permananent_Partial_Disability">Accidental Permanent Partial Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accidental_permananent_Partial_Disability_div')" (mouseleave)="mouseLeave($event, 'Accidental_permananent_Partial_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Accidental_permananent_Partial_Disability_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Accidental Permanent Partial Disability</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Accidental_permananent_Partial_Disability" formControlName="Accidental_permananent_Partial_Disability" name="Accidental_permananent_Partial_Disability" value="No" (change)="checkradio('divAccidental_permananent_Partial_Disability_limit', 'NotCovered'); green_red_change('Accidental_permananent_Partial_Disability', 'no')" />
                                            <label for="radio-two_Accidental_permananent_Partial_Disability" id="Accidental_permananent_Partial_Disability_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Accidental_permananent_Partial_Disability" formControlName="Accidental_permananent_Partial_Disability" name="Accidental_permananent_Partial_Disability" value="Yes" (change)="checkradio('divAccidental_permananent_Partial_Disability_limit', 'Covered'); green_red_change('Accidental_permananent_Partial_Disability', 'yes')" />
                                            <label for="radio-one_Accidental_permananent_Partial_Disability" id="Accidental_permananent_Partial_Disability_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAccidental_permananent_Partial_Disability_limit" style="display: none">
                                        <label class="bs-form-label" for="Accidental_permananent_Partial_Disability_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Accidental_permananent_Partial_Disability_limit" id="Accidental_permananent_Partial_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Accidental_permananent_Partial_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>                                

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Accelerated_critical_illness"> Accelerated Critical Illness <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accelerated_critical_illness_div')" (mouseleave)="mouseLeave($event, 'Accelerated_critical_illness_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Accelerated_critical_illness_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Accelerated Critical Illness</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Accelerated_critical_illness" formControlName="Accelerated_critical_illness" name="Accelerated_critical_illness" value="No" (change)="checkradio('divAccelerated_critical_illness_limit', 'NotCovered'); green_red_change('Accelerated_critical_illness', 'no')" />
                                            <label for="radio-two_Accelerated_critical_illness" id="Accelerated_critical_illness_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Accelerated_critical_illness" formControlName="Accelerated_critical_illness" name="Accelerated_critical_illness" value="Yes" (change)="checkradio('divAccelerated_critical_illness_limit', 'Covered'); green_red_change('Accelerated_critical_illness', 'yes')" />
                                            <label for="radio-one_Accelerated_critical_illness" id="Accelerated_critical_illness_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAccelerated_critical_illness_limit" style="display: none">
                                        <label class="bs-form-label" for="Accelerated_critical_illness_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Accelerated_critical_illness_limit" id="Accelerated_critical_illness_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Accelerated_critical_illness_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Additional_critical_illness">Additional Critical Illness <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Additional_critical_illness_div')" (mouseleave)="mouseLeave($event, 'Additional_critical_illness_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Additional_critical_illness_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Additional Critical Illness</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Additional_critical_illness" formControlName="Additional_critical_illness" name="Additional_critical_illness" value="No" (change)="checkradio('divAdditional_critical_illness_limit', 'NotCovered'); green_red_change('Additional_critical_illness', 'no')" />
                                            <label for="radio-two_Additional_critical_illness" id="Additional_critical_illness_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Additional_critical_illness" formControlName="Additional_critical_illness" name="Additional_critical_illness" value="Yes" (change)="checkradio('divAdditional_critical_illness_limit', 'Covered'); green_red_change('Additional_critical_illness', 'yes')" />
                                            <label for="radio-one_Additional_critical_illness" id="Additional_critical_illness_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAdditional_critical_illness_limit" style="display: none">
                                        <label class="bs-form-label" for="Additional_critical_illness_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Additional_critical_illness_limit" id="Additional_critical_illness_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Additional_critical_illness_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Sickness_disability_rider">Sickness Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Sickness_disability_rider_div')" (mouseleave)="mouseLeave($event, 'Sickness_disability_rider_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Sickness_disability_rider_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Sickness Disability</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Sickness_disability_rider" formControlName="Sickness_disability_rider" name="Sickness_disability_rider" value="No" (change)="checkradio('divSickness_disability_rider_limit', 'NotCovered'); green_red_change('Sickness_disability_rider', 'no')" />
                                            <label for="radio-two_Sickness_disability_rider" id="Sickness_disability_rider_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Sickness_disability_rider" formControlName="Sickness_disability_rider" name="Sickness_disability_rider" value="Yes" (change)="checkradio('divSickness_disability_rider_limit', 'Covered'); green_red_change('Sickness_disability_rider', 'yes')" />
                                            <label for="radio-one_Sickness_disability_rider" id="Sickness_disability_rider_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divSickness_disability_rider_limit" style="display: none">
                                        <label class="bs-form-label" for="Sickness_disability_rider_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Sickness_disability_rider_limit" id="Sickness_disability_rider_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Sickness_disability_rider_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Total_permanent_Disability">Total Permanent Disability (Other Than Accident) <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Total_permanent_Disability_div')" (mouseleave)="mouseLeave($event, 'Total_permanent_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Total_permanent_Disability_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Total Permanent Disability (Other Than Accident)</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Total_permanent_Disability" formControlName="Total_permanent_Disability" name="Total_permanent_Disability" value="No" (change)="checkradio('divTotal_permanent_Disability_limit', 'NotCovered'); green_red_change('Total_permanent_Disability', 'no')" />
                                            <label for="radio-two_Total_permanent_Disability" id="Total_permanent_Disability_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Total_permanent_Disability" formControlName="Total_permanent_Disability" name="Total_permanent_Disability" value="Yes" (change)="checkradio('divTotal_permanent_Disability_limit', 'Covered'); green_red_change('Total_permanent_Disability', 'yes')" />
                                            <label for="radio-one_Total_permanent_Disability" id="Total_permanent_Disability_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divTotal_permanent_Disability_limit" style="display: none">
                                        <label class="bs-form-label" for="Total_permanent_Disability_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Total_permanent_Disability_limit" id="Total_permanent_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Total_permanent_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Total_partial_Disability">Total Partial Disability (Other Than Accident) <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Total_partial_Disability_div')" (mouseleave)="mouseLeave($event, 'Total_partial_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Total_partial_Disability_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Total Partial Disability (Other Than Accident)</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Total_partial_Disability" formControlName="Total_partial_Disability" name="Total_partial_Disability" value="No" (change)="checkradio('divTotal_partial_Disability_limit', 'NotCovered'); green_red_change('Total_partial_Disability', 'no')" />
                                            <label for="radio-two_Total_partial_Disability" id="Total_partial_Disability_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Total_partial_Disability" formControlName="Total_partial_Disability" name="Total_partial_Disability" value="Yes" (change)="checkradio('divTotal_partial_Disability_limit', 'Covered'); green_red_change('Total_partial_Disability', 'yes')" />
                                            <label for="radio-one_Total_partial_Disability" id="Total_partial_Disability_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divTotal_partial_Disability_limit" style="display: none">
                                        <label class="bs-form-label" for="Total_partial_Disability_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Total_partial_Disability_limit" id="Total_partial_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Total_partial_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>  
                                
                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Loss_of_Pay">Loss of Pay <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Loss_of_Pay_div')" (mouseleave)="mouseLeave($event, 'Loss_of_Pay_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Loss_of_Pay_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Loss of Pay</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Loss_of_Pay" formControlName="Loss_of_Pay" name="Loss_of_Pay" value="No" (change)="checkradio('divLoss_of_Pay_limit', 'NotCovered'); green_red_change('Loss_of_Pay', 'no')" />
                                            <label for="radio-two_Loss_of_Pay" id="Loss_of_Pay_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Loss_of_Pay" formControlName="Loss_of_Pay" name="Loss_of_Pay" value="Yes" (change)="checkradio('divLoss_of_Pay_limit', 'Covered'); green_red_change('Loss_of_Pay', 'yes')" />
                                            <label for="radio-one_Loss_of_Pay" id="Loss_of_Pay_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divLoss_of_Pay_limit" style="display: none">
                                        <label class="bs-form-label" for="Loss_of_Pay_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Loss_of_Pay_limit" id="Loss_of_Pay_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Loss_of_Pay_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="row newothersField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addOthersField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add More Field</span>
                                    </label>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->  
                
                <!---- Waiting period Condition Accordian ---->
                <h5 class="bs-left-form-label accordheading waitingheading" (click)="waitingPeriodCondition()">Topup and Spouse Cover
                    <img id="WPC_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="WPC_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/waiting.png" class="headicon" />
                    <img id="waitingtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="waitingtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="Progwaiting">
                        <div class="value-container" id="Valuewaiting">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="waitingPeriodCondition_accord" class="accord_div">

                    <div class="row">
                        <!-- <div class="col-sm-4"> -->
                            <!-- <h5 class="bs-left-form-label">Waiting period Condition</h5> -->
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="row">
                               
                                <div class="col-sm-12 row form-group m-0 p-0">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employees_life_top_up_plan">Employees are given option to buy a life top up plan? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'employees_life_top_up_plan_div')" (mouseleave)="mouseLeave($event, 'employees_life_top_up_plan_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="employees_life_top_up_plan_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Employees are given option to buy a life top up plan</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_employees_life_top_up_plan" formControlName="employees_life_top_up_plan" name="employees_life_top_up_plan" value="No" (change)="checkradio('divemployees_life_top_up_plan_limit', 'NotCovered'); green_red_change('employees_life_top_up_plan', 'no')" />
                                            <label for="radio-two_employees_life_top_up_plan" id="employees_life_top_up_plan_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_employees_life_top_up_plan" formControlName="employees_life_top_up_plan" name="employees_life_top_up_plan" value="Yes" (change)="checkradio('divemployees_life_top_up_plan_limit', 'Covered'); green_red_change('employees_life_top_up_plan', 'yes')" />
                                            <label for="radio-one_employees_life_top_up_plan" id="employees_life_top_up_plan_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group" id="divemployees_life_top_up_plan_limit" style="display: none">
                                        <!-- <label class="bs-form-label" for="employees_life_top_up_plan_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="employees_life_top_up_plan_limit" id="employees_life_top_up_plan_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="employees_life_top_up_plan_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p> -->

                                        <div class="row mt-3">                                                                

                                            <label class="bs-form-label">What is the basis of Accidental sum assured?</label>
            
                                            <div class="form-check col-sm-4 mt-3">
                                                <input type="radio" class="form-radio-input parentcheck" value="Multiple of Salary" formControlName="SI_type_topup" name="SI_type_topup" (click)="show_SI_div_topup('Multiple of Salary')" id="SI_type_topup" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiple of Salary</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-4 mt-3">
                                                <input type="radio" class="form-radio-input parentcheck" value="Flat" formControlName="SI_type_topup" name="SI_type_topup" (click)="show_SI_div_topup('Flat')" id="SI_type_topup" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <div class="form-check col-sm-4 mt-3">
                                                <input type="radio" class="form-radio-input parentcheck" value="Graded" formControlName="SI_type_topup" name="SI_type_topup" (click)="show_SI_div_topup('Graded')" id="SI_type_topup" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                
            
                                            <div id="div_multiple_of_salary_topup" style="display:none">
                                                
                                                <div class="col-sm-4 form-group mt-3">
                                                    <label class="bs-form-label" for="multiple_of_salary_topup">Define the multiple of salary? <span class="redstar">*</span></label>
                                                    <select class="form-select" formControlName="multiple_of_salary_topup" id="multiple_of_salary_topup">
                                                        <option value="" selected>Select</option>
                                                    </select>                            
                                                    <p class="error" id="multiple_of_salary_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                            </div>
            
                                            <div id="div_Flat_topup" style="display:none">
                                                
                                                <div class="col-sm-4 form-group mt-3">
                                                    <label class="bs-form-label" for="Define_flat_SI_topup">Define flat sum insured under accidental plan <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="Define_flat_SI_topup" id="Define_flat_SI_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="Define_flat_SI_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                            </div>
            
                                            <div id="div_Graded_topup" style="display:none">
            
                                                <div class="col-sm-6 form-group mt-3">
                                                    <label class="bs-form-label" for="SI_Junior_management_topup">Define graded accidental sum insured- Junior management <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="SI_Junior_management_topup" id="SI_Junior_management_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="SI_Junior_management_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                                <div class="col-sm-6 form-group mt-3">
                                                    <label class="bs-form-label" for="SI_Middle_management_topup">Define graded accidental sum insured- Middle management <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="SI_Middle_management_topup" id="SI_Middle_management_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="SI_Middle_management_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                                <div class="col-sm-6 form-group mt-3">
                                                    <label class="bs-form-label" for="SI_Senior_management_topup">Define graded accidental sum insured- Senior management <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="SI_Senior_management_topup" id="SI_Senior_management_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="SI_Senior_management_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                                <div class="col-sm-12">
                                                    <div class="row newgradedtoptupField">
                
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <label class="bs-form-label" (click)="addGradedFieldTopup()" style="cursor: pointer">
                                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                        <span style="color: #1489F3;">Add Amount</span>
                                                    </label>
                                                </div>                         
            
                                            </div>
            
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="is_spouse_life_cover">Is spouse life cover is offered? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'is_spouse_life_cover_div')" (mouseleave)="mouseLeave($event, 'is_spouse_life_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="is_spouse_life_cover_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Top up plan under accident cover</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_is_spouse_life_cover" formControlName="is_spouse_life_cover" name="is_spouse_life_cover" value="No" (change)="checkradio('divis_spouse_life_cover_limit', 'NotCovered'); green_red_change('is_spouse_life_cover', 'no')" />
                                            <label for="radio-two_is_spouse_life_cover" id="is_spouse_life_cover_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_is_spouse_life_cover" formControlName="is_spouse_life_cover" name="is_spouse_life_cover" value="Yes" (change)="checkradio('divis_spouse_life_cover_limit', 'Covered'); green_red_change('is_spouse_life_cover', 'yes')" />
                                            <label for="radio-one_is_spouse_life_cover" id="is_spouse_life_cover_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group" id="divis_spouse_life_cover_limit" style="display: none">
                                        <!-- <label class="bs-form-label" for="is_spouse_life_cover_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="is_spouse_life_cover_limit" id="is_spouse_life_cover_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="is_spouse_life_cover_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p> -->

                                        <div class="row">
                                            <div class="col-sm-7">
                                                
                                                <div style="display: flex; margin-top: 20px;">
                                                    <input type="text" style="width: 30%;height: 30px" class="form-control" formControlName="is_spouse_life_cover_limit" id="is_spouse_life_cover_limit" (keypress)="isNumber($event)" (input)="age_limit($event, 'agerange')" />
                                                    <p class="error" id="is_spouse_life_cover_limit_error" style="display:none; margin-bottom: -10px;">
                                                        This field is required 
                                                    </p>
                                                    <span style="font-size: 12px; margin-top:5px; margin-left: 10px">% of Employee base sum assured Subject to the maximum of</span>
                                                </div>
                                                
                                            </div>
                                            <div class="col-sm-3">

                                                <div style="display: flex; margin-left: 12px;margin-top: 20px;">
                                                    <span style="font-size: 12px; margin-top:5px; margin-right: 10px">INR</span>
                                                    <input type="text" style="height: 30px" class="form-control" formControlName="is_spouse_life_cover_limit_INR" id="is_spouse_life_cover_limit_INR" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                    <p class="error" id="is_spouse_life_cover_limit_INR_error" style="display:none; margin-bottom: -10px;">
                                                        This field is required 
                                                    </p>                                                    
                                                </div>
                                                                                                
                                            </div>                                                                                                                                                                               
                                        </div>

                                    </div>
                                </div>
                                

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->                               

                <div class="row">

                    <!-- <hr class="my-3"> -->
                    <div>
                        <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <!-- <button (click)="next(3);" class="btn btn-primary" *ngIf="stepper_flag == false" style="float: right;">Finish</button> -->
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>

                </form>
            </div>

            <div id="test-l-4" class="content mt-3">                

                <form [formGroup]="documentUpload">
                <h3 class="bs-title">Document upload</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Document upload</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <!-- <div *ngIf="stepper_flag == false">
                            As New policy is selected no need for uploading any doc, directly click on Finish
                        </div> -->
                        <div class="row">                                                       

                            <div class="col-sm-12" *ngIf="stepper_flag == true">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="policy_document">Policy Document</label>
                                    <!-- <input type="file" class="form-control" formControlName="policy_document" (change)="policy_document_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field5" id="custom-file-upload_policy_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field5" type="file" (change)="policy_document_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_policy_document">Upload only PDF files (Max size 4MB)</small>
                            </div>

                            <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="member_data">Member Data Upload <span class="redstar">*</span></label>
                                    <!-- <input type="file" class="form-control" formControlName="member_data" (change)="member_data_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field6" id="custom-file-upload_member_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field6" type="file" (change)="member_data_change($event)" formControlName="member_data" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors}"
                                    required />                           
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors">
                                        <p class="error">
                                        Please upload member data
                                        </p>
                                    </ng-container>
                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_member_data">Upload only .xlsx, .xls, .csv files (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadMemberdata()">Download Sample File</span>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-6 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claims_in_last_five_years">Do you have claims in last 5 Years? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'claims_in_last_five_years_div')" (mouseleave)="mouseLeave($event, 'claims_in_last_five_years_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="claims_in_last_five_years_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Do you have claims in last 5 Years?</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_claims_in_last_five_years" formControlName="claims_in_last_five_years" name="claims_in_last_five_years" value="No" (change)="checkradio('divclaims_in_last_five_years_limit', 'NotCovered'); green_red_change('claims_in_last_five_years', 'no')" />
                                        <label for="radio-two_claims_in_last_five_years" id="claims_in_last_five_years_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_claims_in_last_five_years" formControlName="claims_in_last_five_years" name="claims_in_last_five_years" value="Yes" (change)="checkradio('divclaims_in_last_five_years_limit', 'Covered'); green_red_change('claims_in_last_five_years', 'yes')" />
                                        <label for="radio-one_claims_in_last_five_years" id="claims_in_last_five_years_yes" class="bs-form-label">Yes</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-6 form-group" id="divclaims_in_last_five_years_limit" style="display: none">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="claims_in_last_five_years">Upload Document</label>

                                            <label for="file_field7" id="custom-file-claims_in_last_five_years" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_claims_in_last_five_years" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                            </label>
                                            <input id="file_field7" type="file" (change)="claims_in_last_five_years_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="small_claims_in_last_five_years">Upload only PDF files (Max size 4MB)</small>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-sm-12">
                                <div class="row newuploadField">

                                </div>
                            </div>

                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addUploadField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Upload Field</span>
                                </label>
                            </div> -->


                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(4);" class="btn btn-primary" style="float: right;">Finish</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>
                </form>
            </div>
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 80%; padding-bottom: 10px; margin: auto; top:40px">
                <!-- <div class="modal-header">
                    <h4 class="modal-title">Hurray!!</h4>
                </div> -->
                <div class="modal-body">                    
                    <div class="row">

                        <div class="col-sm-12">
                            <img src="../../assets/img/policy/hurray.jpg" class="img-fluid mb-3" style="width:50%; margin: auto; display: block; margin-bottom: 0px !important;" />
                        </div>

                        <div class="col-sm-12">
                            
                            <h5 style="text-align: center; font-weight: bold;">Hooray!!</h5>
                            <h6 class="mt-3 text-center" style="font-size: 14px;"> Plan created successfully.</h6>
                            <h6 class="mt-2 text-center" style="font-weight: 600; font-size: 14px;">Your Plan ID : {{proposal_number_show}}</h6>

                            <div class="form-group mt-3">
                                <label class="bs-form-label text-center" style="margin: auto; display: block;"> Enter Plan Name </label>
                                <input type="text" class="form-control" id="plan_name" name="plan_name" />
                                <p class="error" id="plan_name_error" style="display: none">
                                    This field is required
                                </p>
                            </div>

                        </div>                

                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closePopup()" style="margin: auto;"> Submit & Close</button>
                </div>
            </div>
        </div>
    </div>

</div>