<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3 cardbg">
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
            
            <h3 class="bs-title">Select Policy and Family Members Details</h3>      
            <hr class="mt-0" style="height: 0px">     
            <!-- <p style="font-size: 16px;"><b>Complete Review of you and your family Members</b></p> -->
                   
            <!-- Policy 6 fields -->
            <div class="row mt-5">
                <div class="col-sm-3">
                    <img src="../../../assets/img/employee/sidebg.png" alt="image" />
                </div>            
                <div class="col-sm-9">            

                    <!-- GMC -->
                    <div class="row" *ngIf="gmc_dropdown.length != 0">                                                 
                        <div class="col-sm-4" *ngIf="gmc_dropdown.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="gmc_dropdown"> {{gmc_policy_name}} <span class="redstar">*</span></label>
                                <select class="form-select" id="gmc_dropdown" [disabled]="gmc_dropdown.length == 0" style="background-color: #e9ecef; pointer-events: none;">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of gmc_dropdown" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                                <small class="error gmc_dropdown_error" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <div class="col-sm-4" *ngIf="gmc_dropdown_extra.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="gmc_dropdown_extra"> {{gmc_policy_name_extra}} <span class="redstar">*</span></label>
                                <select class="form-select" id="gmc_dropdown_extra" [disabled]="gmc_dropdown_extra.length == 0" style="background-color: #e9ecef; pointer-events: none;">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of gmc_dropdown_extra" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                                <small class="error gmc_dropdown_error" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <div class="col-sm-4" *ngIf="topupArray_GMC.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="topupArray_GMC"> {{gmc_topup_policy_name}} </label>
                                <select class="form-select" id="topupArray_GMC" [disabled]="topupArray_GMC.length == 0" (change)="relationinfobypolicy($event, 'gmc')">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of topupArray_GMC" [value]="relation.suminsured">
                                        {{relation.suminsured}}
                                    </option>
                                </select>
                                <small class="error topup_dropdown_error_gmc" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <div class="col-sm-4" *ngIf="topupArray_GMC_extra.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="topupArray_GMC_extra"> {{gmc_topup_policy_name}} </label>
                                <select class="form-select" id="topupArray_GMC_extra" [disabled]="topupArray_GMC_extra.length == 0" (change)="relationinfobypolicy($event, 'gmc_extra')">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of topupArray_GMC_extra" [value]="relation.suminsured">
                                        {{relation.suminsured}}
                                    </option>
                                </select>
                                <small class="error topup_dropdown_error_gmc_extra" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <!--  -->

                        <!-- <div class="col-sm-4" *ngIf="topup_dropdown.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="topup_dropdown"> Top-up Plan <span class="redstar">*</span></label>
                                <select class="form-select" id="topup_dropdown" [disabled]="topup_dropdown.length == 0">
                                    <option value="0">Select Top-up Plan</option>
                                    <option *ngFor="let relation of topup_dropdown" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                                <small class="error topup_dropdown_error" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>                         -->                        
                    </div>
                    
                    <!-- GPA -->
                    <div class="row" *ngIf="gpa_dropdown.length != 0">                                                
                        <div class="col-sm-4 mt-3" *ngIf="gpa_dropdown.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="gpa_dropdown"> {{gpa_policy_name}} <span class="redstar">*</span></label>
                                <select class="form-select" id="gpa_dropdown" [disabled]="gpa_dropdown.length == 0" style="background-color: #e9ecef; pointer-events: none;">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of gpa_dropdown" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                                <small class="error gpa_dropdown_error" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3" *ngIf="topupArray_GPA.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="topupArray_GPA">{{topupgpa_policy_name}}</label>
                                <select class="form-select" id="topupArray_GPA" [disabled]="topupArray_GPA.length == 0" (change)="relationinfobypolicy($event, 'gpa')">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of topupArray_GPA" [value]="relation.suminsured">
                                        {{relation.suminsured}}
                                    </option>
                                </select>
                                <small class="error topup_dropdown_error_gpa" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <!--  -->
                    </div>

                    <!-- GLT -->
                    <div class="row" *ngIf="gtl_dropdown.length != 0">                        
                        <div class="col-sm-4 mt-3" *ngIf="gtl_dropdown.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="gtl_dropdown"> {{gtl_policy_name}} <span class="redstar">*</span></label>
                                <select class="form-select" id="gtl_dropdown" [disabled]="gtl_dropdown.length == 0" style="background-color: #e9ecef; pointer-events: none;">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of gtl_dropdown" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                                <small class="error gtl_dropdown_error" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3" *ngIf="topupArray_GTL.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="topupArray_GTL"> {{gtl_topup_policy_name}}</label>
                                <select class="form-select" id="topupArray_GTL" [disabled]="topupArray_GTL.length == 0" (change)="relationinfobypolicy($event,'gtl')">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of topupArray_GTL" [value]="relation.suminsured">
                                        {{relation.suminsured}}
                                    </option>
                                </select>
                                <small class="error topup_dropdown_error_gtl" style="display: none; font-weight: bold;">This field is Required</small>
                            </div>
                        </div>
                        <!--  -->
                    </div>

                    <!-- Parental -->
                    <div class="row" *ngIf="parental_dropdown.length != 0">
                        <div class="col-sm-4 mt-3" *ngIf="parental_dropdown.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="parental_dropdown"> Parental Plan <span class="redstar">*</span></label>
                                <select class="form-select" id="parental_dropdown" [disabled]="parental_dropdown.length == 0">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of parental_dropdown" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- CC -->
                    <div class="row" *ngIf="cc_dropdown.length != 0">
                        <div class="col-sm-4 mt-3" *ngIf="cc_dropdown.length != 0">
                            <div class="form-group">
                                <label class="bs-form-label" for="cc_dropdown"> CC Plan <span class="redstar">*</span></label>
                                <select class="form-select" id="cc_dropdown" [disabled]="cc_dropdown.length == 0">
                                    <option value="0">Select</option>
                                    <option *ngFor="let relation of cc_dropdown" [value]="relation.policy_id">
                                        {{relation.policy_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>  

                </div>
            </div>                               
            
            <!-- Relation Rows -->
            <div class="mt-3" *ngFor="let data of Relation_Array; let i = index;">
                
                <div class="row" id="{{data['relation']}}_row">                
                    <div class="col-sm-3">
                        <button class="btn btn-primary" title="Add {{data['relation']}}" *ngIf="data['relation'] == 'Son' || data['relation'] == 'Daughter' || data['relation'] == 'Brother' || data['relation'] == 'Sister'" (click)="addOthersField(data['relation'], data['relation_id'])" style="background-color: #00D63C; border-radius: 6px;margin-top: 30px; font-size: 24px; padding: 10px;height: 30px; width: 30px;">
                            <span style="font-size: 30px;position: relative; top: -10px;left: -6px;">+</span>
                        </button>

                        <div class="form-check" style="background-color: var(--bs-sidebar); padding: 10px;color: #fff;margin-top: 26px;border-radius: 50px 0px 0px 50px;" [ngClass]="{'addmorewidth': data['relation'] == 'Son' || data['relation'] == 'Daughter' || data['relation'] == 'Brother' || data['relation'] == 'Sister'}">
                            <input type="checkbox" class="form-check-input" (change)="checkCrossSection($event)" id="hr_info_{{i}}" style="margin-left: 5px; margin-top: 2px; cursor: pointer; border: solid 1px #fff;" [disabled]="data['relation'] == 'Self'" [checked]="data['first_name'] != '' " />
                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">{{data['relation']}}</label>
                            <input type="hidden" id="relation_checkbox_{{i}}" value="{{data['relation']}}" />
                            <img src="{{data['image_url']}}" style="width: 30px; position: absolute; right: 10px; bottom: 6px;" alt="image" />
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="rel_dob_{{i}}"> Date Of Birth <span class="redstar">*</span></label>
                            <input type="date" id="rel_dob_{{i}}" class="form-control" [max]="dob_future" placeholder="dd/mm/yyyy" pattern="\d{2}/\d{2}/\d{4}" [disabled]="data['relation'] == 'Self'" [value]="data['dob'] != '' ? data['dob'] : ''" />
                            <small class="error age_greater_error_{{i}}" style="display: none; font-weight: bold;">Invalid Age ! Age must between {{data['min_age']}} and {{data['max_age']}}</small>
                            <small class="error age_equal_to_error_{{i}}" style="display: none; font-weight: bold;">Invalid Age Difference</small> 
                            <small class="error rel_dob_error_{{i}}" style="display: none; font-weight: bold;">This field is Required</small>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="rel_first_name_{{i}}"> First Name <span class="redstar">*</span></label>
                            <input type="text" id="rel_first_name_{{i}}" class="form-control" placeholder="First Name" [disabled]="data['relation'] == 'Self'" [value]="data['first_name'] != '' ? data['first_name'] : ''" />
                            <small class="error rel_first_name_error_{{i}}" style="display: none; font-weight: bold;">This field is Required</small>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="rel_last_name_{{i}}"> Last Name</label>
                            <input type="text" id="rel_last_name_{{i}}" class="form-control" placeholder="Last Name" [disabled]="data['relation'] == 'Self'" [value]="data['last_name'] != '' ? data['last_name'] : ''" />
                            <small class="error rel_last_name_error_{{i}}" style="display: none; font-weight: bold;">This field is Required</small>
                        </div>
                    </div>
                </div>  

            </div>

            <!-- Add More Div -->
            <div class="col-sm-12">
                <div class="newothersField_son">

                </div>
                <div class="newothersField_daughter">

                </div>
            </div>

            <!-- Enrollment Row -->
            <div class="row my-3">                
                <div class="col-sm-6">
                    <div style="display: flex;">
                        <img src="../../../assets/img/employee/handbook.png" style="width: 15px; height: 20px;" alt="img" />
                        <a href="{{enrollment_handbook}}" target="_blank" style="font-size: 14px; font-weight: bold; margin-left: 10px;">Enrollment Handbook</a>
                    </div>
                </div>
                <div class="col-sm-6">
                    <button class="btn btn-primary" id="submitButton" style="float: right; margin-left: 10px;" (click)="onSubmit()">Submit</button>    
                    <button class="btn btn-primary" style="background-color: #fff; float: right; color:#000;" (click)="gotoDashboard()">Cancel</button>
                </div>
            </div>

                        
            <!-- Max Limit Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':MaxLimittoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Reached Max Limit for {{max_relation}} ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':addErrorPopup}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Member not added, Some error occured ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Global Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Errortoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> {{ErrortoggleMsg}} ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Desc Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Descriptiontoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Description
                                <img src="../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">                            
                            <p> {{DescriptionMsg}} </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lockin Popup -->
             <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Lockinpopup}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Policy Is Lock-In Mode, You Cannot Add Member information! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>