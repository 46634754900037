import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }


  getsamplefile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/samplexcelfile/'+data.master_policies_id,
      data
    , httpOptions);
  }

  AddInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'netwokhospital/tpaapitrigger',
    data
    , httpOptions);
  }

  InfoUpdate(data: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'benefitwellness/update/' + data.id,
    data
    , httpOptions);
  }

  getPolicyuploaddoclist(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/policyuploaddoclist',
      data
    , httpOptions);
  }  

  downloadfailedDcoument(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/errorexcelfile/' + data.uploadid,
      data
    , httpOptions);
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'benefitwellness/show/' + data.id, 
      data
    , httpOptions);
  }

  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'netwokhospital/tpaapitriggerloglist', 
    data,
      httpOptions);
  }

  InfoActiveDeactivate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'benefitwellness/activedeactive/' + data.id,
      data
    , httpOptions);
  }

  InfoDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'benefitwellness/destroy/' + data.id,
      data
    , httpOptions);
  }

  getInfoClient(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise', 
      data,
      httpOptions);
  }

  getpolicytype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }

  getpolicynumbers(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
    , httpOptions);
  }
  getAPI(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'netwokhospital/apitypeonline', 
      data,
      httpOptions);
  }
}
