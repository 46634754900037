import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';
import { numberFormat } from 'highcharts';

@Component({
  selector: 'app-health-insurance',
  templateUrl: './health-insurance.component.html',
  styleUrls: ['./health-insurance.component.css']
})
export class HealthInsuranceComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');

  ngOnInit(): void {
    this.getInfo();
    // $(".bs-title").slideToggle();
  }

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  getInfo(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getemployeepolicies(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {                                 

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  goback(){
    this.route.navigate(['/flex-configurator-dashboard']);
  }

  goto(){
    window.open('https://www.bajajallianz.com/health-insurance-plans.html');
  }

}
