<div id="makertData" class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <form [formGroup]="makertData">
        <h3 class="bs-title">Market Data</h3>
        <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
            <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
            <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <hr style="margin-top: -5px;">
        <div class="row">
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for="file">Choose File <span class="redstar">*</span></label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event)" id="file" enctype="multipart/form-data"
                    [ngClass]="{'control-red': marketDataSubmitted && getmarketDataFormControls.file.errors}"
                    required />
                    <ng-container
                        *ngIf="marketDataSubmitted && getmarketDataFormControls.file.errors">
                        <p class="error"
                        *ngIf="marketDataSubmitted && getmarketDataFormControls.file.errors.required">
                        Please upload file
                        </p>
                    </ng-container>
                </div>
            </div>                       
            <div class="col-lg-3">
                <div class="form-group">
                    <label class="bs-form-label" for=""></label>
                    <input type="submit" class="form-control" (click)="ondownload()" id="downloadbtn" value="Download Sample File" />
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <label class="bs-form-label" for="savebtn"></label>
                    <input type="submit" class="form-control" (click)="onSubmit()" id="savebtn" value="Save" />
                </div>
            </div>            
        </div>
    </form>

    <div class="table-responsive mt-4">
        <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Company code</th>
                <th>Company name</th>
                <th>Industry type</th>
                <th>Employee strength</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of marketArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['company_code']}}</td>
                   <td>{{group['company_name']}}</td>                   
                   <td>{{group['industry_type']}}</td>
                   <td>{{group['employee_strength']}}</td>
                   <td style="width: 15%" align="center">
                    <img src="../../../assets/img/common icons/eye.png" id="viewuser" (click)="openPopup_view(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="View" />
                    <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopup(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:18px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}" style="overflow:hidden">
        <div class="modal-dialog modal-dialog_view" role="document">
            <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
            <div class="modal-header">
                <h4 class="modal-title">Edit Market Data
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;
                    right: 10px;"> &#10006;</button>
                </h4>
            </div>
            <div class="modal-body modal-body_edit">
                
                <!-- <form [formGroup]="marketEdit">                    
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="company_code">Company Code <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="company_code" id="company_code" />                    
                            </div>
                        </div>            
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="company_name">Company name</label>
                                <input type="text" class="form-control" formControlName="company_name" id="company_name" />                    
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="industry_type">Industry type <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="industry_type" id="industry_type" />                    
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="employee_strength">Employee Strength <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="employee_strength" id="employee_strength" />                    
                            </div>
                        </div>
                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />                                                        
                    </div>
                </form> -->

                <div class="row" id="editDiv">
                    
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_view}" style="overflow:hidden">
        <div class="modal-dialog modal-dialog_view" role="document">
            <div class="modal-content model-content-view">
            <div class="modal-header">
                <h4 class="modal-title">
                    View Market Data
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;
                    right: 10px;"> &#10006;</button>
                </h4>
            </div>
            <div class="modal-body modal-body_view">
                
                <!-- <form [formGroup]="marketEdit_view">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="company_code_view">Company Code <span class="redstar">*</span></label>
                                <input type="text" class="form-control pnone" formControlName="company_code_view" id="company_code_view" />                    
                            </div>
                        </div>            
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="company_name_view">Company name</label>
                                <input type="text" class="form-control pnone" formControlName="company_name_view" id="company_name_view" />                    
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="industry_type_view">Industry type <span class="redstar">*</span></label>
                                <input type="text" class="form-control pnone" formControlName="industry_type_view" id="industry_type_view" />                    
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="employee_strength_view">Employee Strength <span class="redstar">*</span></label>
                                <input type="text" class="form-control pnone" formControlName="employee_strength_view" id="employee_strength_view" />                    
                            </div>
                        </div>
                    </div>
                </form> -->

                <div class="row" id="viewDiv" style="pointer-events: none;">
                    
                </div>

            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div> -->
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete</h4>
                </div>
                <form [formGroup]="marketDelete">
                    <div class="modal-body">                                
                        Are you sure you want to delete?
                    </div>
                    <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                        <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                    </div>
                </form>            
            </div>
        </div>
    </div>

</div>
