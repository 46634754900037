import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-dashboard-ancillary',
  templateUrl: './dashboard-ancillary.component.html',
  styleUrls: ['./dashboard-ancillary.component.css']
})
export class DashboardAncillaryComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');

  partner_id: any;
  imgpath: any;
  addFlag: any;

  ngOnInit(): void {
    this.getInfo();
    // $(".bs-title").slideToggle();
  }

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  getInfo(){

    this.addFlag = 0;
    localStorage.setItem('addFlag', this.addFlag);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getemployeepolicies(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {   
        
        if (result.status == true) {

          this.imgpath = result.data;
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  goback(){
    this.route.navigate(['/employeruser-dashboard']);
  }

  gotopage(id: any){

    this.route.navigate(['/insurance']);
    this.partner_id = id;

    localStorage.setItem('partner_id', this.partner_id);
  }

}
