import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../verifymobile/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-verifymobile',
  templateUrl: './verifymobile.component.html',
  styleUrls: ['./verifymobile.component.css']
})
export class VerifymobileComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public registerForm:FormGroup; 
  validmsg: any;
  registerbtn: any;
  generated_OTP: any;

  time: number = 31;
  display : any;
  interval: any;

  resstatus = localStorage.getItem('resstatus');

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private route: Router
    ) {
      this.route.events.subscribe((e) => {
        if (this.resstatus == null) {
            this.route.navigate(['/register']);
        }    
      });
  }

  ngOnInit(): void {

    this.initialize();    

  }

  initialize(){

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    $('#generate_OTP').prop('disabled', true);

    if (navigator.userAgent.indexOf("Firefox") > 0) {
      $(".div-center").attr('style', 'top: -250px');
    }

    // Form element defined below
    this.registerForm = this.fb.group({
      mobile: new FormControl('', Validators.required),
      otp: new FormControl('')
    });    

  } 

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    var val = evt.target.value;
    var id = evt.target.id;
    var len = val.length;
    if(len == 10 && id == "mobile"){
      $('#generate_OTP').prop('disabled', false);
      return true;      
    }else{
      $('#' + id).val('');
      $('#generate_OTP').prop('disabled', true);
      return false;
    }          
    
    // return true;
  }

  generate_OTP() {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      $('#validmsg').css('display', 'none');

      let postdataObj = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "mobile_no": this.registerForm.controls.mobile.value
      };

      this.authService.generate_OTP(postdataObj)
      .subscribe((result: any) => {
        if (result.status == true) {                        

          this.show = false;
          
          this.generated_OTP = result.data;
          this.validmsg = result.message;
          $('#validmsg').css('display', 'block');
          $('#verifyOTP').css('display', 'block');

          // Resend OTP
          this.startTimer();

        } else {
          // console.log(result);            
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'block');          
      });

  }  

  verify_OTP() {
    
    $('#validmsg').css('display', 'none');
    
    let postdataObj = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "mobile_no": this.registerForm.controls.mobile.value,
      "mobile_otp": this.registerForm.controls.otp.value
    };

    this.authService.verify_OTP(postdataObj)
    .subscribe((result: any) => {
      console.log(result);
      if (result.status == true) {                        

        localStorage.setItem('mobileverification', 'yes');
        // this.route.navigate(['/verify-email']);
        localStorage.setItem('pagehide', 'no');
        localStorage.setItem('mobile_no', this.registerForm.controls.mobile.value);
        this.route.navigate(['/clientCreation']);

      } else {
        // console.log(result);  
          this.validmsg = result.message;
          $('#validmsg').css('display', 'block');          
      }
    }, (error) => {
      this.validmsg = error.error.message;
      $('#validmsg').css('display', 'block');          
    });
    
    // if(this.generated_OTP == this.registerForm.controls.otp.value){

    //   this.route.navigate(['/verify-email']);

    // }else{
    //     this.validmsg = "OTP does not matched";
    //     $('#validmsg').css('display', 'block');
    // }    
    
  }

  goback(){
    localStorage.clear();
    this.route.navigate(['/register']);
  }
  
  /********** Resend OTP Timer *****/
  startTimer() {
    $('#resendbtn').css('display','block');
    this.interval = setInterval(() => {
      if (this.time === 31) {
        this.time--;
      } else {
        this.time--;
      }
      this.display=this.transform( this.time);
      if(this.time == 0){
        this.pauseTimer();
      }
    }, 1000);
  }
  transform(value: number): string {
       const minutes: number = Math.floor(value / 60);
       return minutes + ':' + (value - minutes * 60);
  }
  pauseTimer() {
    clearInterval(this.interval);
    $('#timer').css('display','none');
    $('#resendlink').prop('disabled', false);
    $('#resendlink').css('color', '#4E0099');
    $('#resendlink').css('cursor', 'pointer');    
  }
  resendotp(){
    this.generate_OTP();
    this.validmsg = 'Resending OTP..';
    $('#validmsg').css('display', 'block');
  }
  /********************************/

}
