import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  constructor(private route: Router) {
  
    this.route.events.subscribe((e) => {
      // localStorage.setItem('resstatus', 'false');     
    });

   }

  ngOnInit(): void {    
    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 1000); 
  }  

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}
