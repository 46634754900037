import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {sidebarservice} from './sidebarservice';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  username: any;
  restatus: any;
  user_role: any;
  user_company: any;
  plan_name: any;
  url: any;

  user_type_id:any = localStorage.getItem('user_type_id');

  roleSuperadmin:any;

  
  claimsexecutiveuser_dashboard:any = 0;
  claim_module: any = 0;
  wellness_module: any = 0;
  network_hospital_module: any = 0;
  tpa_trigger_module: any = 0;
  reports_module: any = 0;
  e_card_module: any = 0;
  ancillary_module: any = 0;

  /**** Claims */
  executive_user_claim_intimation:any = 0;
  claim_intimation:any = 0;
  claim_portal:any = 0;
  overall_claims:any = 0;
  empClaim_intimation:any = 0;
  empClaim_portal:any = 0;
  
  // Super Admin
  superadminDashboard:any = 0
  management:any = 0;
  manage_theme:any = 0;
  manage_news:any = 0;
  manage_annoucements:any = 0;
  manage_country:any = 0;
  manage_state:any = 0;
  manage_city:any = 0;
  manage_industry:any = 0;
  manage_user_types:any = 0;
  manage_plan:any = 0;
  tpa_details:any = 0;
  insurance_company_insurer:any = 0;
  masters:any = 0;
  admin_mastermodules:any = 0;
  master_broker:any = 0;
  document_type:any = 0;

  // Broker Admin
  broker_dashboard:any = 0;
  broker_manage_master:any = 0;
  broker_user_management:any = 0;
  broker_product_feature_master:any = 0;
  policies_module:any = 0;
  create_policy:any = 0;
  listpolicies:any = 0;
  member_upload:any = 0;
  endorsement_request:any = 0; 
  flex_configurator:any = 0;
  reports:any = 0; 
  claim_status:any = 0; 
  broker_insurance_company:any = 0;
  broker_tpa:any = 0;
  policy_lock_in:any = 0;
  nominee_upload:any = 0;

  // Broker User
  brokeruser_dashboard:any = 0;


  // Employer Admin
  employer_dashboard:any = 0;
  employee_user_management:any = 0;
  employer_reports:any = 0;


  // Employer User
  employeruser_dashboard:any = 0; 
  enrollment:any = 0; 
  claims_employee_user:any = 0; 
  contact_us:any = 0;
  download_ecard:any = 0; 
  network_hospital:any = 0; 
  help:any = 0;
  form_center:any = 0;
  flex_configurator_dashboard: any = 0;
  employeruser_flex_plan: any = 0;


  //HR Info
  hr_info_dashboard:any = 0;

  //Admin
  Client_dashboard:any = 0;

  isPoliciesOpen: boolean = false;
  isClaimsOpen: boolean = false;
  isWellnessOpen: boolean = false;
  isAncillaryOpen: boolean = false;
  isTPAOpen: boolean = false;
  isNetworksOpen: boolean = false;
  isECardOpen: boolean = false;
  isReportsOpen: boolean = false;
  isMgmtOpen: boolean = false;
  isMastersOpen: boolean = false;

  networks:any = 0;
  broker_networks:any = 0;

  broker_tpa_trigger:any = 0;
  broker_tpa_trigger_logs:any = 0;

  broker_report:any = 0;
  flex_report:any = 0;

  broker_e_card:any = 0;
  broker_e_card_upload:any = 0;
  e_card_file_upload:any = 0;

  broker_ancillary:any = 0;
  broker_ancillary_details:any = 0;

  wellness:any = 0;
  wellness_benefit:any = 0;
  emp_wellness_benefit:any = 0;

  employee_ecard:any = 0;
  employer_ecard:any = 0;
  broker_ecard:any = 0;

  json: any = [
    {
      "status": true,
      "message": "Module Information!",
      "data": {
          "tokenable_id": "134",
          "module_info": [
              {
                  "id": 1,
                  "module_name": "Master Module",
                  "module_url": "master-module",
                  "module_content": "Master Module",
                  "sort_order": 0,
                  "module_icon": 'mdi-home',
                  "child": [
                      {
                          "id": 2,
                          "module_name": "Manage Country",
                          "module_url": "admin-country",
                          "module_content": "country",
                          "sort_order": 0,
                          "module_icon": 'mdi-home',
                          "module_read": 1,
                          "module_write": 1,
                          "module_delete": 1
                      },
                      {
                          "id": 3,
                          "module_name": "Manage State",
                          "module_url": "manage-state",
                          "module_content": "State",
                          "sort_order": 0,
                          "module_icon": 'mdi-home',
                          "module_read": 1,
                          "module_write": 1,
                          "module_delete": 1
                      }
                  ]
              },
              {
                  "id": 5,
                  "module_name": "Master Policy Mgmt",
                  "module_url": "master-policy-management",
                  "module_content": "Master Policy",
                  "sort_order": 0,
                  "module_icon": 'mdi-settings',
                  "child": [
                      {
                          "id": 6,
                          "module_name": "Manage Policy",
                          "module_url": "url",
                          "module_content": 'mdi-settings',
                          "sort_order": 0,
                          "module_icon": 'mdi-settings',
                          "module_read": 1,
                          "module_write": 1,
                          "module_delete": 1
                      }
                  ]
              }
          ]
      }
    }
  ];

  // json: any = [
  //   {
  //     superadmin: [
  //       {
  //         dashboard: [
  //           {
  //             title: 'Dashboard',
  //             id: '',
  //             url: '/SuperAdmin-dashboard',
  //             icon: 'mdi-home',
  //             imageURL: '',
  //             submenu: 'no'
  //           }
  //         ],
  //         management:[
  //           {
  //             title: 'Management',
  //             id: 'managelist',
  //             url: '',
  //             icon: 'mdi-settings',
  //             imageURL: '',
  //             submenu: 'yes',
  //             data: [
  //               {
  //                 title: 'Manage Theme',
  //                 url: '/admin-manage-theme',
  //               }
  //             ]
  //           },
  //         ]
  //       }

  //     ],


  //   }
  // ]
  modulesdata : any = [];

  constructor(private route: Router, private fb: FormBuilder,private sidebarservice: sidebarservice) {

    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.username = localStorage.getItem('username');
        this.restatus = localStorage.getItem('resstatus');
        this.user_role = localStorage.getItem('type_name');
        this.user_company = localStorage.getItem('company_name');
        this.plan_name = localStorage.getItem('plan_name');
        this.url = e.url;
      }

   });
   this.fetchuserroles();
  }

  ngOnInit(): void {
    // console.log(this.json[0]['data']['module_info'])
    
    if (this.route.url.includes('/policies') ||
    this.route.url.includes('/listpolicies') ||
    this.route.url.includes('/member-upload') ||
    this.route.url.includes('/nominee-upload') ||
    this.route.url.includes('/endoresement-request') ||
    this.route.url.includes('/flex-configurator') ||
    this.route.url.includes('/upload-policy-coverage') ) {
      this.isPoliciesOpen = true;
    }

    if (this.route.url.includes('/claim-intimation') || 
    // this.route.url.includes('/admin-manage-theme') ||
    this.route.url.includes('/claim-status') ||
    this.route.url.includes('/broker-insurance-company') ||
    this.route.url.includes('/broker-intimate-claims') ||
    this.route.url.includes('/portal-claims') ||
    this.route.url.includes('/employee-claim-portal') ||
    this.route.url.includes('/employee-intimate-claims') ||
    this.route.url.includes('/overall-claims')) {
      this.isClaimsOpen = true;
    }

    
    if (this.route.url.includes('/wellness')||this.route.url.includes('/wellness-benefit') ||
    this.route.url.includes('/employer-wellness-benefit')) {
      this.isWellnessOpen = true;
    }

    if(this.route.url.includes('/broker-ancillary')||this.route.url.includes('/broker-ancillary-details')){
      this.isAncillaryOpen = true;
    }

    if(this.route.url.includes('/broker-tpa-trigger')||this.route.url.includes('/broker-tpa-trigger-logs')){
      this.isTPAOpen = true;
    }

    if(this.route.url.includes('/broker-report')||this.route.url.includes('/flex-report')){
      this.isReportsOpen = true;
    }

    if(this.route.url.includes('/network-hospital')||this.route.url.includes('/broker-networks')){
      this.isNetworksOpen = true;
    }

    if(this.route.url.includes('/broker-ecard')||this.route.url.includes('/broker-ecard-upload')||this.route.url.includes('/ecard-file-upload')){
      this.isECardOpen = true;
    }

    if (this.route.url.includes('/admin-manage-theme') ||
    this.route.url.includes('/admin-manage-news') ||
    this.route.url.includes('/admin-manage-annoucements') ||
    this.route.url.includes('/admin-manage-country') ||
    this.route.url.includes('/admin-manage-state') ||
    this.route.url.includes('/admin-manage-city') ||
    this.route.url.includes('/admin-manage-industry') ||
    this.route.url.includes('/admin-manage-user-types') ||
    this.route.url.includes('/admin-manage-plan') ||
    this.route.url.includes('/admin-tpa-details') ||
    this.route.url.includes('/product-feature-master') ||
    this.route.url.includes('/admin-document-type') ||
    this.route.url.includes('/admin-insurance-company-insurer')) {
      this.isMgmtOpen = true;
    }

    if (this.route.url.includes('/admin-mastermodules') ||
    this.route.url.includes('/admin-manage-master-broker')) {
      this.isMastersOpen = true;
    }
    
  }

  checkclaim(){
    // localStorage.setItem('addflag_client', '0');    
    if(this.user_type_id != '10'){
      this.route.navigate(['claim-intimation']);
    }else{
      // this.route.navigate(['executive-user/claim-intimation']);
      this.route.navigate(['client-user-listing']);
      localStorage.setItem('claim_intimation_policyID_flag', '0');
    }    
  }

  fetchuserroles(){
    var ab = localStorage.getItem('module_info');
    if(ab==null){
      let postdata = {
        'tokenable_id':localStorage.getItem('tokenable_id')
      }
      this.sidebarservice.getuserrights(postdata)
        .subscribe((result: any) => {
          // console.log(result.data.module_info);
          this.modulesdata = result.data.module_info;
          localStorage.setItem("module_info",JSON.stringify(result.data.module_info));
          this.callsidebar(result.data.module_info);
        }, (error) => {
            console.log(error);
      });
    }else{
      this.modulesdata = localStorage.getItem('module_info');
      this.modulesdata = JSON.parse(this.modulesdata);

      this.callsidebar(this.modulesdata);
    }
  }

  callsidebar(modulesdata:any){
    for(let i = 0; i < modulesdata.length; i++){
        
      /********** SuperAdmin ***************/

      // SuperAdmin-dashboard
      if(this.modulesdata[i]['module_url'] == "SuperAdmin-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.superadminDashboard = 1;
        }else{
          this.superadminDashboard = 0;
        }
      }        

      // management
      if(this.modulesdata[i]['module_url'] == "management"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.management = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){              

            // manage_theme
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-theme"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_theme = 1;
              }else{
                this.manage_theme = 0;
              }
            }

            // manage_news
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-news"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_news = 1;
              }else{
                this.manage_news = 0;
              }
            }

            // manage_annoucements
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-annoucements"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_annoucements = 1;
              }else{
                this.manage_annoucements = 0;
              }
            }

            // manage_country
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-country"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_country = 1;
              }else{
                this.manage_country = 0;
              }
            }

            // manage_state
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-state"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_state = 1;
              }else{
                this.manage_state = 0;
              }
            }

            // manage_city
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-city"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_city = 1;
              }else{
                this.manage_city = 0;
              }
            }

            // manage_industry
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-industry"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_industry = 1;
              }else{
                this.manage_industry = 0;
              }
            }

            // manage_user_types
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-user-types"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_user_types = 1;
              }else{
                this.manage_user_types = 0;
              }
            }

            // manage_plan
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-plan"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.manage_plan = 1;
              }else{
                this.manage_plan = 0;
              }
            }

            // tpa_details
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-tpa-details"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.tpa_details = 1;
              }else{
                this.tpa_details = 0;
              }
            }

            // insurance_company_insurer
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-insurance-company-insurer"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.insurance_company_insurer = 1;
              }else{
                this.insurance_company_insurer = 0;
              }
            }

            // document_type
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-document-type"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.document_type = 1;
              }else{
                this.document_type = 0;
              }
            }

          }

        }else{
          this.management = 0;
        }
      }

      // masters
      if(this.modulesdata[i]['module_url'] == "masters"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.masters = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){              

            // admin_mastermodules
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-mastermodules"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.admin_mastermodules = 1;
              }else{
                this.admin_mastermodules = 0;
              }
            }

            // master_broker
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-manage-master-broker"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.master_broker = 1;
              }else{
                this.master_broker = 0;
              }
            }            

          }

        }else{
          this.masters = 0;
        }
      }

      // claimsexecutiveuser-dashboard
      if(this.modulesdata[i]['module_url'] == "claimsexecutiveuser-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.claimsexecutiveuser_dashboard = 1;
        }else{
          this.claimsexecutiveuser_dashboard = 0;
        }
      }

      /**************************  Claims ******************************/

      if(this.modulesdata[i]['module_url'] == "claim-module"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.claim_module = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

            if(this.modulesdata[i]['child'][j]['module_url'] == "executive-user/claim-intimation"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.executive_user_claim_intimation = 1;
              }else{
                this.executive_user_claim_intimation = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "broker-intimate-claims"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.claim_intimation = 1;
              }else{
                this.claim_intimation = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "portal-claims"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.claim_portal = 1;
              }else{
                this.claim_portal = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "overall-claims"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.overall_claims = 1;
              }else{
                this.overall_claims = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "employee-intimate-claims"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.empClaim_intimation = 1;
              }else{
                this.empClaim_intimation = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "employee-claim-portal"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.empClaim_portal = 1;
              }else{
                this.empClaim_portal = 0;
              }
            }

          }

        }else{
          this.claim_module = 0;
        }
      }


      /********** Broker Admin ***************/

      /************************* WELLNESS **************************************/
      
      if(this.modulesdata[i]['module_url'] == "wellness"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.wellness_module = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

            if(this.modulesdata[i]['child'][j]['module_url'] == "wellness"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.wellness = 1;
              }else{
                this.wellness = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "wellness-benefit"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.wellness_benefit = 1;
              }else{
                this.wellness_benefit = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "employer-wellness-benefit"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.emp_wellness_benefit = 1;
              }else{
                this.emp_wellness_benefit = 0;
              }
            }            

          }

        }else{
          this.wellness_module = 0;
        }
      }

      /************************ NETWORK HOSPITAL***********************************/

      if(this.modulesdata[i]['module_url'] == "broker-networks"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.network_hospital_module = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

            if(this.modulesdata[i]['child'][j]['module_url'] == "broker-networks"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.broker_networks = 1;
              }else{
                this.broker_networks = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "network-hospital"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.network_hospital = 1;
              }else{
                this.network_hospital = 0;
              }
            }
          }

        }else{
          this.network_hospital_module = 0;
        }
      }


      /********************** ANCILLARY*****************************/

      if(this.modulesdata[i]['module_url'] == "broker-ancillary"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.ancillary_module = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

            if(this.modulesdata[i]['child'][j]['module_url'] == "broker-ancillary"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.broker_ancillary = 1;
              }else{
                this.broker_ancillary = 0;
              }
            }

            if(this.modulesdata[i]['child'][j]['module_url'] == "broker-ancillary-details"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.broker_ancillary_details = 1;
              }else{
                this.broker_ancillary_details = 0;
              }
            }
          }

        }else{
          this.ancillary_module = 0;
        }
      }

    /**************************************************************************/


    /****************************** TPA TRIGGER ******************************/

    if(this.modulesdata[i]['module_url'] == "broker-tpa-trigger-module"){
      if(this.modulesdata[i]['is_show'] == 1){          
        this.tpa_trigger_module = 1;

        for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

          if(this.modulesdata[i]['child'][j]['module_url'] == "broker-tpa-trigger"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.broker_tpa_trigger = 1;
            }else{
              this.broker_tpa_trigger = 0;
            }
          }

          if(this.modulesdata[i]['child'][j]['module_url'] == "broker-tpa-trigger-logs"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.broker_tpa_trigger_logs = 1;
            }else{
              this.broker_tpa_trigger_logs = 0;
            }
          }
        }

      }else{
        this.tpa_trigger_module = 0;
      }
    }

    /***************************************************************************************/

    /*********************** BROKER E-CARD *************************************************/

    
    if(this.modulesdata[i]['module_url'] == "e-card-module"){
      if(this.modulesdata[i]['is_show'] == 1){          
        this.e_card_module = 1;

        for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

          if(this.modulesdata[i]['child'][j]['module_url'] == "broker-ecard"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.broker_e_card = 1;
            }else{
              this.broker_e_card = 0;
            }
          }

          if(this.modulesdata[i]['child'][j]['module_url'] == "broker-ecard-upload"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.broker_e_card_upload = 1;
            }else{
              this.broker_e_card_upload = 0;
            }
          }

          if(this.modulesdata[i]['child'][j]['module_url'] == "ecard-file-upload"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.e_card_file_upload = 1;
            }else{
              this.e_card_file_upload = 0;
            }
          }

        }

      }else{
        this.e_card_module = 0;
      }
    }

    /*********************************************************************************/

    /****************************** Broker Reports ******************************/

    if(this.modulesdata[i]['module_url'] == "reports-module"){
      if(this.modulesdata[i]['is_show'] == 1){          
        this.reports_module = 1;

        for(let j = 0; j < this.modulesdata[i]['child'].length; j++){

          if(this.modulesdata[i]['child'][j]['module_url'] == "broker-report"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.broker_report = 1;
            }else{
              this.broker_report = 0;
            }
          }

          if(this.modulesdata[i]['child'][j]['module_url'] == "flex-report"){
            if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
              this.flex_report = 1;
            }else{
              this.flex_report = 0;
            }
          }
        }

      }else{
        this.reports_module = 0;
      }
    }

    /***************************************************************************************/


    /*************************** broker-dashboard **********************************/

      if(this.modulesdata[i]['module_url'] == "broker-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_dashboard = 1;
        }else{
          this.broker_dashboard = 0;
        }
      }

      // broker-manage-master
      if(this.modulesdata[i]['module_url'] == "broker-manage-master"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_manage_master = 1;
        }else{
          this.broker_manage_master = 0;
        }
      }
      
      // broker_user_management
      if(this.modulesdata[i]['module_url'] == "broker-manage-master-employer/user-management"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_user_management = 1;
        }else{
          this.broker_user_management = 0;
        }
      }

      // broker_product_feature_master
      if(this.modulesdata[i]['module_url'] == "product-feature-master"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_product_feature_master = 1;
        }else{
          this.broker_product_feature_master = 0;
        }
      }

      // policies_module
      if(this.modulesdata[i]['module_url'] == "policies-module"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.policies_module = 1;

          for(let j = 0; j < this.modulesdata[i]['child'].length; j++){              

            // create_policy
            if(this.modulesdata[i]['child'][j]['module_url'] == "policies"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.create_policy = 1;
              }else{
                this.create_policy = 0;
              }
            }

            // listpolicies
            if(this.modulesdata[i]['child'][j]['module_url'] == "listpolicies"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.listpolicies = 1;
              }else{
                this.listpolicies = 0;
              }
            }
            
            // member-upload
            if(this.modulesdata[i]['child'][j]['module_url'] == "member-upload"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.member_upload = 1;
              }else{
                this.member_upload = 0;
              }
            }

            // nominee-upload
            if(this.modulesdata[i]['child'][j]['module_url'] == "nominee-upload"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.nominee_upload = 1;
              }else{
                this.nominee_upload = 0;
              }
            }

            // endoresement-request
            if(this.modulesdata[i]['child'][j]['module_url'] == "endoresement-request"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.endorsement_request = 1;
              }else{
                this.endorsement_request = 0;
              }
            }

            // flex-configurator
            if(this.modulesdata[i]['child'][j]['module_url'] == "flex-configurator"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.flex_configurator = 1;
              }else{
                this.flex_configurator = 0;
              }
            }

            // policy_lock_in
            if(this.modulesdata[i]['child'][j]['module_url'] == "upload-policy-coverage"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.policy_lock_in = 1;
              }else{
                this.policy_lock_in = 0;
              }
            }

            // insurance_company_insurer
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-insurance-company-insurer"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.insurance_company_insurer = 1;
              }else{
                this.insurance_company_insurer = 0;
              }
            }

            // tpa_details
            if(this.modulesdata[i]['child'][j]['module_url'] == "admin-tpa-details"){
              if(this.modulesdata[i]['child'][j]['is_show'] == 1){          
                this.tpa_details = 1;
              }else{
                this.tpa_details = 0;
              }
            }
          }

        }else{
          this.policies_module = 0;
        }
      }

      // reports
      if(this.modulesdata[i]['module_url'] == "reports"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.reports = 1;
        }else{
          this.reports = 0;
        }
      }

      // claim-status
      if(this.modulesdata[i]['module_url'] == "claim-status"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.claim_status = 1;
        }else{
          this.claim_status = 0;
        }
      }

      // broker_insurance_company
      if(this.modulesdata[i]['module_url'] == "broker-insurance-company"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_insurance_company = 1;
        }else{
          this.broker_insurance_company = 0;
        }
      }

      // broker_tpa
      if(this.modulesdata[i]['module_url'] == "broker-insurance-company"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_tpa = 1;
        }else{
          this.broker_tpa = 0;
        }
      }

      // broker-ecard      
      if(this.modulesdata[i]['module_url'] == "broker-ecard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.broker_ecard = 1;
        }else{
          this.broker_ecard = 0;
        }
      }

      
      
      /********** Broker User ***************/  

      // broker-dashboard
      if(this.modulesdata[i]['module_url'] == "brokeruser-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.brokeruser_dashboard = 1;
        }else{
          this.brokeruser_dashboard = 0;
        }
      }


      /********** Employer Admin ***************/

      // employer-dashboard
      if(this.modulesdata[i]['module_url'] == "employer-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employer_dashboard = 1;
        }else{
          this.employer_dashboard = 0;
        }
      }

      // employee-user-management
      if(this.modulesdata[i]['module_url'] == "employee-user-management"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employee_user_management = 1;
        }else{
          this.employee_user_management = 0;
        }
      }

      // employer-reports
      if(this.modulesdata[i]['module_url'] == "employer-reports"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employer_reports = 1;
        }else{
          this.employer_reports = 0;
        }
      }

      // employer-ecard      
      if(this.modulesdata[i]['module_url'] == "employer-ecard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employer_ecard = 1;
        }else{
          this.employer_ecard = 0;
        }
      }
      

      /********** Employer User ***************/
      
      // networks
      if(this.modulesdata[i]['module_url'] == "networks"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.networks = 1;
        }else{
          this.networks = 0;
        }
      }

      // employeruser-dashboard
      if(this.modulesdata[i]['module_url'] == "employeruser-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employeruser_dashboard = 1;
        }else{
          this.employeruser_dashboard = 0;
        }
      }

      // employeruser_flex_plan
      if(this.modulesdata[i]['module_url'] == "flex-plan"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employeruser_flex_plan = 1;
        }else{
          this.employeruser_flex_plan = 0;
        }
      }    
      
      // flex-configurator-dashboard
      if(this.modulesdata[i]['module_url'] == "flex-configurator-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.flex_configurator_dashboard = 1;
        }else{
          this.flex_configurator_dashboard = 0;
        }
      }      

      // employeruser-dashboard/family-enrollment
      if(this.modulesdata[i]['module_url'] == "employeruser-dashboard/family-enrollment"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.enrollment = 1;
        }else{
          this.enrollment = 0;
        }
      }

      // claims-employee-user
      if(this.modulesdata[i]['module_url'] == "claims-employee-user"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.claims_employee_user = 1;
        }else{
          this.claims_employee_user = 0;
        }
      }

      // contact-us
      if(this.modulesdata[i]['module_url'] == "contact-us"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.contact_us = 1;
        }else{
          this.contact_us = 0;
        }
      }

      // employee-ecard      
      if(this.modulesdata[i]['module_url'] == "employee-ecard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.employee_ecard = 1;
        }else{
          this.employee_ecard = 0;
        }
      }

      // help
      if(this.modulesdata[i]['module_url'] == "help"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.help = 1;
        }else{
          this.help = 0;
        }
      }

      // help
      if(this.modulesdata[i]['module_url'] == "form-center"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.form_center = 1;
        }else{
          this.form_center = 0;
        }
      }        
      
      
      /********** Hr Info ***************/  

      // hr-info-dashboard
      if(this.modulesdata[i]['module_url'] == "hr-info-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.hr_info_dashboard = 1;
        }else{
          this.hr_info_dashboard = 0;
        }
      }


      /********** Admin ***************/  

      // Client-dashboard
      if(this.modulesdata[i]['module_url'] == "Client-dashboard"){
        if(this.modulesdata[i]['is_show'] == 1){          
          this.Client_dashboard = 1;
        }else{
          this.Client_dashboard = 0;
        }
      }

    }
  }

  togglePoliciesCollapse(): void {
    this.isPoliciesOpen = !this.isPoliciesOpen;
  }

  toggleClaimsCollapse(): void {
    this.isClaimsOpen = !this.isClaimsOpen;
  }

  toggleMgmtCollapse(): void {
    this.isMgmtOpen = !this.isMgmtOpen;
  }

  toggleMastersCollapse(): void {
    this.isMastersOpen = !this.isMgmtOpen;
  }

  toggleWellnessCollapse(): void {
    this.isWellnessOpen = !this.isWellnessOpen;
  }

  toggleAncillaryCollapse(): void {
    this.isAncillaryOpen = !this.isAncillaryOpen;
  }

  toggleTPACollapse(): void {
    this.isTPAOpen = !this.isTPAOpen;
  }

  toggleNetworksCollapse(): void {
    this.isNetworksOpen = !this.isNetworksOpen;
  }

  toggleECardCollapse(): void {
    this.isECardOpen = !this.isECardOpen;
  }

  toggleReportsCollapse(): void {
    this.isReportsOpen = !this.isReportsOpen;
  }

}
