<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

           <div class="row">
                <div class="col-sm-8">

                    <div *ngIf="Employepolicyes.length == 0">
                        No Policy Available
                    </div>

                    <div *ngIf="Employepolicyes.length > 0">
                    
                        <div class="row" style="margin-top: -15px;">

                            <div class="col-sm-6 p-0" *ngFor="let group of Employepolicyes; let i = index">
                                <div class="p-3">
                                    <div class="card">
                                        <div class="p-2 section1">
                                            <img src="../../../../../assets/img/employee/group1.png" alt="img" style="width: 30px;" *ngIf="group['vp_flag'] == 0" />
                                            <img src="../../../../../assets/img/employee/group2.png" alt="img" style="width: 30px;" *ngIf="group['vp_flag'] == 1" />
                                            <label class="bs-label" style="margin-left: 10px; font-size: 13px; font-weight: bold;">{{group['name']}}</label>
                                        </div>
                                        <div class="p-2 section2" [ngClass]="{'sec2':group['vp_flag'] == '0', 'sec22':group['vp_flag'] == '1'}">
                                            <!-- <p style="margin-top: 30px;">
                                                {{group['policy_name']}}
                                            </p>
                                            <p>
                                                {{group['policy_number']}}
                                            </p> -->
                                            <p style="margin-top: 20px;"><b>{{group['policy_number']}}</b></p>
                                            <p>Enrollment Confirmation</p>
                                            <p>
                                                Status - <span style="color: #F8934C"><b>{{group['enrolement_status']}}</b></span>
                                            </p>                                        
                                            <div align="center">
                                                <button class="btn btn-primary allbtn" (click)="navigateto(group['policy_id'], group)" role="button" *ngIf="group['enrolement_btn'] == 'OPEN'">Enrollment Window Open</button>
                                                <!-- <button class="btn btn-primary allbtn" *ngIf="group['enrolement_btn'] == 'CLOSE'">Enrollment Window Closed</button> -->
                                            </div>
                                            <div align="center" class="mt-3">
                                                <button class="btn btn-primary allbtn" *ngIf="group['enrolement_btn'] == 'CLOSE'" (click)="showPolicyFeatures(group['policy_features'])">Policy Features</button>
                                            </div>
                                            <div class="row" style="margin-top: 10px; margin-bottom: 0px;" *ngIf="group['enrolement_btn'] == 'CLOSE'">
                                                <div class="col-sm-6" style="padding: 15px;">
                                                    <p style="margin: 0px; text-decoration: underline;">Family Construct</p>                                                                                                
                                                    <div class="closeselfbtn" (mouseenter)="onHover(true,i)" (mouseleave)="onHover(false,i)">                                                    
                                                        <p (click)="getemployeepoliciesmembers(group['policy_id'])" style="text-decoration: underline; margin-bottom: 0px; font-weight: 700;">{{group['family_count']}}</p>                                                    
                                                    </div>
                                                    <div id="hoverDiv_{{i}}" class="hoverdiv">
                                                        {{group['family_construct']}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6" style="padding: 15px;">
                                                    <p style="margin: 0px; text-decoration: underline;">IPD Sum Insured</p>
                                                    <div class="closeselfbtn" (click)="getemployeepoliciesmembers(group['policy_id'])">                                                    
                                                        <p style="margin-bottom: 0px; font-weight: 700;">INR {{group['suminsured']}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                    <div class="mt-1">
                        <iframe width="100%" height="300"
                            src="https://www.youtube.com/embed/tgbNymZ7vqY">
                        </iframe>
                    </div>
                    
                </div>
                <div class="col-sm-4">
                    <div style="background-color: #fff; padding: 10px; box-shadow: 0px 0px 10px 1px #EEEEEE;">
                        <h5 style="color: #40189D; font-size: 14px; font-weight: bold;">Space to show some related content</h5>
                        <hr>
                        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                        <h5 style="color: #40189D; font-size: 14px; font-weight: bold; margin-top: 20px;">Space to show some related content</h5>
                        <hr>
                        <ul>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                        </ul>


                        <h5 style="color: #40189D; font-size: 14px; font-weight: bold; margin-top: 20px;">Space to show some related content</h5>
                        <hr>

                        <div class="row">
                            <div class="col-sm-2">
                                <img src="../../../../../assets/img/employee/rectangle.png" alt="img" />
                            </div>
                            <div class="col-sm-10">
                                <label class="bs-label" style="font-size: 13px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit.</label>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-sm-2">
                                <img src="../../../../../assets/img/employee/rectangle.png" alt="img" />
                            </div>
                            <div class="col-sm-10">
                                <label class="bs-label" style="font-size: 13px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit.</label>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-sm-2">
                                <img src="../../../../../assets/img/employee/rectangle.png" alt="img" />
                            </div>
                            <div class="col-sm-10">
                                <label class="bs-label" style="font-size: 13px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit.</label>
                            </div>
                        </div>
                        
                    </div>
                </div>
           </div>                      

            <!-- Policy Fetaures Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStylePolicyFeatures}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Policy Features
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
            
                        <div class="modal-body">
            
                            <!-- Tabs Headings -->
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item liwidth" role="presentation">
                                    <button class="nav-link active w100" id="pf_excluded-tab" data-bs-toggle="tab"
                                        data-bs-target="#pf_excluded" type="button" role="tab" aria-controls="pf_excluded"
                                        aria-selected="true">Excluded</button>
                                </li>
                                <li class="nav-item liwidth" role="presentation">
                                    <button class="nav-link w100" id="pf_included-tab" data-bs-toggle="tab"
                                        data-bs-target="#pf_included" type="button" role="tab" aria-controls="pf_included"
                                        aria-selected="false">Included</button>
                                </li>
                                <li class="nav-item liwidth" role="presentation">
                                    <button class="nav-link w100" id="pf_optional-tab" data-bs-toggle="tab"
                                        data-bs-target="#pf_optional" type="button" role="tab" aria-controls="pf_optional"
                                        aria-selected="false">Optional</button>
                                </li>
                            </ul>
            
                            <!-- Tabs Content -->
                            <div class="tab-content" id="myTabContent">
            
                                <!-- Tab pf_excluded -->
                                <div class="tab-pane fade show active" id="pf_excluded" role="tabpanel"
                                    aria-labelledby="pf_excluded-tab">
            
                                    <table class="table table-sm row-border table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Content</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let group1 of PFxcluded">
                                                <td>
                                                    {{group1['title']}}
                                                </td>
                                                <td>
                                                    <span *ngIf="group1['content'] != ''">
                                                        {{group1['content']}}
                                                    </span>
                                                    <span *ngIf="group1['content'] == ''">-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
            
                                </div>
            
                                <!-- Tab pf_included -->
                                <div class="tab-pane fade" id="pf_included" role="tabpanel" aria-labelledby="pf_included-tab">
            
                                    <table class="table table-sm row-border table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Content</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let group1 of PFincluded">
                                                <td>
                                                    {{group1['title']}}
                                                </td>
                                                <td>
                                                    <span *ngIf="group1['content'] != ''">
                                                        {{group1['content']}}
                                                    </span>
                                                    <span *ngIf="group1['content'] == ''">-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
            
                                </div>
            
                                <!-- Tab pf_optional -->
                                <div class="tab-pane fade" id="pf_optional" role="tabpanel" aria-labelledby="pf_optional-tab">
            
                                    <table class="table table-sm row-border table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Content</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let group1 of PFoptional">
                                                <td>
                                                    {{group1['title']}}
                                                </td>
                                                <td>
                                                    <span *ngIf="group1['content'] != ''">
                                                        {{group1['content']}}
                                                    </span>
                                                    <span *ngIf="group1['content'] == ''">-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
            
                                </div>
            
                            </div>
            
                        </div>
                        <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
            
                    </div>
                </div>
            </div>

            <!-- Close Policy Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleClosePolicy}">
                <div class="modal-dialog modellg" role="document" style="max-width: 800px;">
                    <div class="modal-content">
					
                        <div class="modal-header">
                            <h4 class="modal-title">Member Details
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
            
                        <div class="modal-body" style="max-height: 400px; overflow-y: auto;">
                            <div class="row" *ngFor="let closeres of ClosePolicyArray; let cl = index" style="background-color: #e7e7e7; margin-bottom: 15px; border-radius: 8px;">
                                <div class="col-sm-3">
                                    <div class="p-3">
                                        <img src="{{closeres['image_url']}}" alt="img" style="margin: auto; display: block; width: 100px; margin-bottom: 5px;" />
                                        <button class="btn btn-primary" style="width: 100%; font-size: 12px;">Download E-Card</button>
                                    </div>                                    
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="p-3">
                                                <p class="pb-0 colorlabel">Member Id</p>
                                                <p class="pb-0 bold">{{closeres['member_id']}}</p>                                              
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="p-3">
                                                <p class="pb-0 colorlabel">Relation</p>
                                                <p class="pb-0 bold">{{closeres['relation']}}</p>                                                                            
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="p-3">
                                                <p class="pb-0 colorlabel">Status</p>
                                                <p class="pb-0 bold">{{closeres['status']}}</p>                                                                               
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="p-3">                                        
                                                <p class="pb-0 colorlabel">Date Of Birth</p>
                                                <p class="pb-0 bold">{{closeres['dob']}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="p-3">
                                                <p class="pb-0 colorlabel">Sum Insured</p>
                                                <p class="pb-0 bold">&#8377; {{closeres['suminsured']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="p-3">
                                                <p class="pb-0 colorlabel">Cover Date</p>
                                                <p class="pb-0 bold">{{closeres['cover_date']}}</p>                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
						
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
            
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
