import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  API_URL = environment.apiURL;

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(this.API_URL + 'all', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(this.API_URL + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(this.API_URL + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(this.API_URL + 'admin', { responseType: 'text' });
  }

  // Create
  createTask(data: any): Observable<any> {
    return this.http.post(this.API_URL + 'create', data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  // Read
  showTasks() {
    return this.http.get(`${this.API_URL}`);
  }

  // Update
  updateTask(id: any, data: any): Observable<any> {
    return this.http
      .put(this.API_URL + 'update', data)
      .pipe(map((res:any)=>{
        return res;
      }))
  }

  // Delete
  deleteTask(id: any): Observable<any> {
    return this.http.delete(this.API_URL + 'delete-task')
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  moneyformatter(number:any){
    number = parseFloat(number);
    return number.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
  }
}