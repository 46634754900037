<div id="makertData" class="bs-stepper bg-white my-4 mb-5 rounded p-3">

    <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div> -->

    <!----- Bnner Section ------>
    <div class="row">
        <div class="col-sm-6">
            <img src="../../assets/img/selectproduct/banner.png" class="img-fluid banner" alt="Img" />
        </div>
        <div class="col-sm-6 textm">
            <h3 class="bs-title alignc">{{gotopolicy}} Policies</h3>
            <p class="bs-p-normal alignc"> 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                Nam non metus id augue tempor volutpat sit amet sit amet nunc.
            </p>
        </div>
    </div>
    <hr class="my-4">
    <!--------- END ------------>
    
    <div class="table-responsive plantable m-2 mt-3">
        <table class="table table-sm hover mt-2 w1000">
            <thead style="border: none">
                <tr style="border-bottom: solid 1px #eee; height:40px">
                    <td class="bs-form-label text-center text-black">Plan ID</td>
                    <td class="bs-form-label text-center text-black">Plan Name</td>
                    <td class="bs-form-label text-center text-black">Status</td>
                    <!-- <td class="bs-form-label text-center text-black">Action</td> -->
                </tr>
            </thead>
            <tbody>  
                <tr *ngFor="let group of usersArray; let i = index;" style="border-bottom: solid 1px #eee; height:40px">
                    <td class="bs-form-label text-center text-black">{{group['proposal_number']}}</td>
                    <td class="bs-form-label text-center text-black">
                        <span *ngIf="group['proposal_name'] != null">
                            {{group['proposal_name']}}
                        </span>
                        <span *ngIf="group['proposal_name'] == null">
                            -
                        </span>
                    </td>
                    <td class="text-center text-black pt-2">
                        <div class="{{group['policy_status']}}">
                            {{group['policy_status']}}
                        </div>
                    </td>
                    <!-- <td class="text-center text-black">
                        <div class="text-black icons" style="margin-left: -20px;">
                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                        </div>
                    </td> -->
                </tr>                                
            </tbody>
        </table>
    </div>

</div>