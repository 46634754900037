import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ClientCreationService } from './client-creation';
import {Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-claim-intimation',
  templateUrl: './claim-intimation.component.html',
  styleUrls: ['./claim-intimation.component.css']
})
export class ClaimIntimationComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // isDtInitialized:boolean = false;

  private stepper: Stepper;
  private stepper_edit: Stepper;

  // addflag: any = localStorage.getItem('addflag_client');
  addflag: any = 0;
  InfoArray: any = [];
  InfoRelation: any = [];
  InfoPolicyNo: any = [];
  InfoSumAssured: any = [];
  InfoTypeClaim: any = [];
  InfoStatus: any = [];

  show = false;
  fullScreen = true;
  template = ``;

  public basicInfo: FormGroup;
  public contactInfo: FormGroup;

  basicInfoSubmitted: boolean = false;
  contactInfoSubmitted: boolean = false;

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');
  phonenumber = localStorage.getItem('mobile_no');
  claim_intimation_policyID_flag:any = localStorage.getItem('claim_intimation_policyID_flag');
  claim_intimation_policyID:any = localStorage.getItem('claim_intimation_policyID');
  user_type_id: any = localStorage.getItem('user_type_id');

  validmsg: any;

  statusClaimId: any;

  public EditInfo1: FormGroup;
  EditInfoSubmitted1: boolean = false;

  public EditInfo2: FormGroup;
  EditInfoSubmitted2: boolean = false;

  public DeleteInfo: FormGroup;

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDelete = "none";
  displayStyleStatus = "none";
  displayStyleError = "none";

  relationship: any;

  policy_start_date: any;
  policy_end_date: any;

  Client: any = [];
  Insurer: any = [];
  ProductType: any = [];
  MasterPolicyNo: any = [];
  additionalBenefitsArray: any = [];
  additionalBenefitsArray_edit: any = [];
  statusArray: any = [];

  relationship_edit: any
  policy_start_date_edit: any;
  policy_end_date_edit: any;

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'claim_intimation';
  total_sum_assured:any = 0;
  total_sum_assured_edit_val:any = 0;
  policy_id:any;  
  claim_id_edit:any;

  constructor(private fb: FormBuilder, private clientCreationService: ClientCreationService, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });

  }

  ngOnInit(): void {

    this.initGroup();
    
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
  }

  initGroup(){    

    $('.defaultclass').css('display','none');

    this.InfoArray = [];
    this.getpolicyno();
    this.getInfoRelation();
    this.gettypeclaim();
    this.getInfoClient();

    localStorage.setItem('claim_id', '0');                
    
    this.validmsg = '';
    $('#validmsg').css('display', 'none');     

    // Define Stepper
    const stepperElement = document.querySelector('#stepper1');
    if(stepperElement !== null){
    this.stepper = new Stepper(stepperElement , {
          linear: false,
          animation: true
        })
    }

    // Define Stepper
    const stepperElement_edit = document.querySelector('#stepper1_edit');
    if(stepperElement_edit !== null){
    this.stepper_edit = new Stepper(stepperElement_edit , {
          linear: false,
          animation: true
        })
    }

    // Form element defined below
    this.basicInfo = this.fb.group({
      member_name: new FormControl('', Validators.required),
      account_number: new FormControl(''), 
      relationship_code: new FormControl(''),
      relationship: new FormControl(''),
      policy_no: new FormControl(''),
      policy_start_date: new FormControl(''),
      policy_end_date: new FormControl(''),
      customer_id: new FormControl(''),
      customer_account_no: new FormControl(''),
      sum_assured: new FormControl(''),
      sol_id: new FormControl(''),
      country: new FormControl(''),
      state: new FormControl(''),
      city: new FormControl(''),
      zipcode: new FormControl(''),
      date_of_reporting: new FormControl('', Validators.required),
      addtional_benefits: new FormControl(''),      
      date_of_loss: new FormControl('', Validators.required),
      request_no: new FormControl(''),           
    });

    this.contactInfo = this.fb.group({            
      claimed_amount: new FormControl(''),
      cancelled_cheque_of_Nominee: new FormControl(''),
      nominee_cheque_details: new FormControl('', Validators.required),
      IFSC_code: new FormControl('', Validators.required),
      bank: new FormControl('', Validators.required),
      branch: new FormControl('', Validators.required),
      type_of_claim: new FormControl('', Validators.required),
      relationship_step2: new FormControl(''),
      comments: new FormControl(''),
      claim_intimation_number: new FormControl(''),
      date_GPA_claim: new FormControl(''),      
      settlement_date_top_up_plan: new FormControl(''),
      document_received_date: new FormControl(''),
      status_main_policy: new FormControl(''),
      claim_amount_to_settle: new FormControl(''),
      claim_settle_amount: new FormControl(''),

      nominee_name: new FormControl('', Validators.required),
      settlement_date: new FormControl('', Validators.required),

    });        

    // Edit Step 1
    this.EditInfo1 = this.fb.group({

      // customer details
      member_name_edit: new FormControl('', Validators.required),
      account_number_edit: new FormControl(''),
      relationship_code_edit: new FormControl(''),
      relationship_edit: new FormControl(''),
      customer_id_edit: new FormControl(''),
      customer_account_no_edit: new FormControl(''),
      sol_id_edit: new FormControl(''),
      country_edit: new FormControl(''),
      state_edit: new FormControl(''),
      city_edit: new FormControl(''),
      zipcode_edit: new FormControl(''),
      date_of_reporting_edit: new FormControl('', Validators.required),
      date_of_loss_edit: new FormControl('', Validators.required),
      request_no_edit: new FormControl(''),
      edit_id: new FormControl(''),

      // claim details
      policy_no_edit: new FormControl(''),
      policy_name_edit: new FormControl(''),
      policy_start_date_edit: new FormControl(''),
      policy_end_date_edit: new FormControl(''),
      sum_assured_edit: new FormControl(''),
      addtional_benefits_edit: new FormControl(''),
      total_sum_assured_edit: new FormControl(''),
      type_of_claim_edit: new FormControl('', Validators.required),
      cause_of_death_edit: new FormControl(''),
      remarks_edit: new FormControl(''),
      claim_intimation_number_edit: new FormControl(''),
    });

    // Edit Step 2
    this.EditInfo2 = this.fb.group({
      claimed_amount_edit: new FormControl(''),
      cancelled_cheque_of_Nominee_edit: new FormControl(''),
      nominee_cheque_details_edit: new FormControl('', Validators.required),
      IFSC_code_edit: new FormControl('', Validators.required),
      bank_edit: new FormControl('', Validators.required),
      branch_edit: new FormControl('', Validators.required),
      relationship_step2_edit: new FormControl(''),
      comments_edit: new FormControl(''),
      date_GPA_claim_edit: new FormControl(''),
      settlement_date_top_up_plan_edit: new FormControl(''),
      document_received_date_edit: new FormControl(''),
      status_main_policy_edit: new FormControl(''),
      claim_amount_to_settle_edit: new FormControl(''),
      claim_settle_amount_edit: new FormControl(''),

      nominee_name_edit: new FormControl('', Validators.required),
      settlement_date_edit: new FormControl('', Validators.required),

    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });    

  }  

  openPopupAdd(){
    this.addflag = 1;    
    $('.stepperclass').css('display', 'flex');
    $('.steppercontent').css('display', 'block');
  }

  adduser(res: any){
    
    localStorage.setItem('brokeruser_claim_id', res.id);
    // this.route.navigate(['broker-manage-master-employer/employer-user']);
    this.addflag = 1;    
    $('.stepperclass').css('display', 'flex');
    $('.steppercontent').css('display', 'block');
    // $('.defaultclass').css('display', 'none');
        
  }   

  closePopupAdd(){
    this.addflag = 0;          
    window.location.reload(); 
    $('.stepperclass').css('display', 'none'); 
    $('.steppercontent').css('display', 'none');  
    $('.stepperclass_edit').css('display', 'none'); 
    $('.steppercontent_edit').css('display', 'none');  
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }     

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  get getContactInformationFormControls() {
    return this.contactInfo.controls;
  }    

  get getEditInformationFormControls() {
    return this.EditInfo1.controls;
  } 

  get getEditInformationFormControls2() {
    return this.EditInfo2.controls;
  } 

  //Delete
  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.clientCreationService.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.clientCreationService.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  closePopup() {
    this.displayStyleStatus = "none";    
  }  

  getInfo(){

    this.InfoArray = [];
    // console.log(this.InfoArray.length);        
    
    var client_id = $('#clientdropdown').val();
    var insurer_id = $('#insurerdropdown').val();
    var product_type_id = $('#productdropdown').val();
    var masterpolicyno = $('#masterpolicyno').val();    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),

      "client_id":client_id,
      "insurer_id": insurer_id,
      "product_type_id": product_type_id,
      "policy_id": masterpolicyno,
    };    

    this.clientCreationService.getInfo(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {                                  

        this.InfoArray = result.data;
        console.log(this.InfoArray);

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });

        
              
      }, (error) => {          
        
        this.InfoArray = [];

        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
        
    });
    
  }

  getInfoRelation(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    // var postdata = {
    //   "tokenable_id":localStorage.getItem('tokenable_id'),
    //   "broker_id": localStorage.getItem('broker_id')
    // };

    this.clientCreationService.getInfoRelation()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoRelation = result.data;           

        }, (error) => {          
          
          this.InfoRelation = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }
  
  gettypeclaim(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;     

    this.clientCreationService.gettypeclaim()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoTypeClaim = result.data;           

        }, (error) => {          
          
          this.InfoTypeClaim = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  // Stepper Next Event
  next(step: number) {
    if (step == 1) {
      this.basicInfoSubmitted = true;
      if (this.basicInfoSubmitted && this.basicInfo.valid) {
        // this.stepper.next();
        console.log(this.basicInfo.value);
        this.onSubmitFirst();
      }
    }
    if (step == 2) {
      this.contactInfoSubmitted = true;
      if (this.contactInfoSubmitted && this.contactInfo.valid) {
        this.stepper.next();
        console.log(this.contactInfo.value);
        this.onSubmitSecond();
      }
    }    
  }

  // Stepper Previous Event
  previous() {
    this.stepper.previous();
  }

  // Show / Hide Address block
  ifradioclicked(){

    if($('#yes_as_register').is(':checked')) {
      $("#yes_or_no_div").css("display","none");
    }else{
      $("#yes_or_no_div").css("display","block");
    }

  }

  // Submit First
  onSubmitFirst() {      

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),        
        "claim_id": localStorage.getItem('claim_id'),
        "policy_id": this.basicInfo.controls['policy_no'].value,

        "member_name": this.basicInfo.controls['member_name'].value,
        "account_number": this.basicInfo.controls['account_number'].value, 
        // "relationship_code": this.basicInfo.controls['relationship_code'].value,
        // "relationship": this.relationship,
        "policy_no": $("#policy_no option:selected").text(),
        "policy_start_date": this.policy_start_date,
        "policy_end_date": this.policy_end_date,
        "customer_id": this.basicInfo.controls['customer_id'].value,
        "customer_account_no": this.basicInfo.controls['customer_account_no'].value,
        "sum_assured": this.basicInfo.controls['sum_assured'].value,
        "sol_id": this.basicInfo.controls['sol_id'].value,
        "country": this.basicInfo.controls['country'].value,
        "state": this.basicInfo.controls['state'].value,
        "city": this.basicInfo.controls['city'].value,
        "zipcode": this.basicInfo.controls['zipcode'].value,
        "date_of_reporting": this.basicInfo.controls['date_of_reporting'].value,
        "addtional_benefits": this.basicInfo.controls['addtional_benefits'].value,      
        "date_of_loss": this.basicInfo.controls['date_of_loss'].value,
        // "request_no": this.basicInfo.controls['request_no'].value,       
      };

      console.log(postdata);

      this.clientCreationService.onSubmitFirst(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);

            localStorage.setItem('claim_id', result.data.claim_id);
            
            this.closePopupAdd();
            
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
            
          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });    

  }

  // Submit Second
  onSubmitSecond(){
   
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),      
      "claim_id": localStorage.getItem('claim_id'),
      "policy_id": this.basicInfo.controls['policy_no'].value,
	  
      "claimed_amount": this.contactInfo.controls['claimed_amount'].value,
      "cancelled_cheque_of_Nominee": this.contactInfo.controls['cancelled_cheque_of_Nominee'].value,
      "nominee_cheque_details": this.contactInfo.controls['nominee_cheque_details'].value,
      "IFSC_code": this.contactInfo.controls['IFSC_code'].value,
      "bank": this.contactInfo.controls['bank'].value,
      "branch": this.contactInfo.controls['branch'].value,
      "type_of_claim": this.contactInfo.controls['type_of_claim'].value,
      // "relationship_step2": this.relationship,
      "comments": this.contactInfo.controls['comments'].value,
      "claim_intimation_number": this.contactInfo.controls['claim_intimation_number'].value,
      "date_of_GPA_claim": $('#date_GPA_claim').val(),      
      // "settlement_date_top_up_plan": this.contactInfo.controls['settlement_date'].value,
      "document_received_date": this.contactInfo.controls['document_received_date'].value,
      // "status_main_policy": this.contactInfo.controls['status_main_policy'].value,
      "claim_amount_to_settle": this.contactInfo.controls['claim_amount_to_settle'].value,
      // "claim_settle_amount": this.contactInfo.controls['claim_settle_amount'].value,
      "nominee_name": this.contactInfo.controls['nominee_name'].value,
      "settlement_date": this.contactInfo.controls['settlement_date'].value,
    };

    console.log(postdata);

    this.clientCreationService.onSubmitSecond(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {
        console.log(result);
        if (result.status == true) {
          // console.log(result);          
          
          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');

          this.closePopupAdd();
          
        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');
    });

  }       

  checkIFSC(e:any){
    var inputvalues = e.target.value;
    // console.log(inputvalues.toUpperCase());
    var gstinformat = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;    
    if (gstinformat.test(inputvalues.toUpperCase())) {    
        $('#IFSCvalid').css('display', 'none');
        $('#step2btn').prop('disabled', false);      
    } else {    
        $('#IFSCvalid').css('display', 'block');
        $('#step2btn').prop('disabled', true);    
    }       
  }

  checkCode(e: any){
    var id:any = e.target.value;
    var myString:any = $("#relationship_code option:selected").text();
    var value:any = myString.substr(myString.indexOf("-") + 1);
    $("#relationship").val(value);
    $("#relationship_step2").val(value);
    this.relationship = value;

    // var postdata = {
    //   "tokenable_id":localStorage.getItem('tokenable_id'),
    //   "relation_id": id
    // }

    // this.clientCreationService.getpolicyno(postdata)
    //     .subscribe((result: any) => {          
          
    //       this.show = false;
    //       // console.log(result);
    //       this.InfoPolicyNo = result.data;
          
    //     }, (error) => {          
          
    //       this.InfoPolicyNo = [];

    //       if(error.error.message == 'Unauthenticated.'){
    //         localStorage.clear();
    //         localStorage.setItem('resstatus', 'false');
    //         this.route.navigate(['/home']);
    //       }
          
    //   });

  }

  checkPolicyNo(e: any){
    var id:any = e.target.value;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": id
    }

    // Period API
    this.clientCreationService.getPeriod(postdata)
      .subscribe((result: any) => {          
        
        this.show = false;
        // console.log(result);         
        
        $("#policy_start_date").val(result.data[0]['policy_start_date']);
        $("#policy_end_date").val(result.data[0]['policy_end_date']);

        this.policy_start_date = result.data[0]['policy_start_date'];
        this.policy_end_date = result.data[0]['policy_end_date'];
        
        this.policy_end_date = result.data[0]['policy_end_date'];

      }, (error) => {     
        
        $("#policy_start_date").val('');
        $("#policy_end_date").val('');
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
          
    });

    // Sum Assured API
    this.clientCreationService.getSumAssured(postdata)
      .subscribe((result: any) => {          
        
        this.show = false;
        // console.log(result);         
                        
        this.InfoSumAssured = result.data;

      }, (error) => {     
        
        this.InfoSumAssured = [];
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
          
    });

  }

  // Filters dropdown API

  // Dropdown 1
  getInfoClient(){   

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'), 
    }

    this.clientCreationService.getInfoClient(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {                             
          // console.log(result);
          this.Client = result.data;                     

        }, (error) => {          
          
          this.Client = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });

  }

  // Dropdown 2
  getInfoInsurer(){   

    this.getInfo();
    // this.InfoArray = [];
    // console.log(this.InfoArray);
    // this.dtTrigger.next();    

    $('#insurerdropdown').val('0');
    $('#productdropdown').val('0');
    $('#masterpolicyno').val('0');

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),       
      "client_id": $('#clientdropdown').val(),
    }

    this.clientCreationService.getInfoInsurer(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {                             
          // console.log(result);
          this.Insurer = result.data;    
          this.getInfoProductType();

        }, (error) => {          
          
          this.Insurer = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });

  }

  dropdown2(){
    
    this.InfoArray = [];
    // console.log(this.InfoArray);
    // this.dtTrigger.next();

    $('#productdropdown').val('0');
    $('#masterpolicyno').val('0');
   
  }

  // Dropdown 3
  getInfoProductType(){   

    this.InfoArray = [];
    // console.log(this.InfoArray);
    // this.dtTrigger.next();

    $('#masterpolicyno').val('0');

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),       
      "client_id": $('#clientdropdown').val(),      
    }

    this.clientCreationService.getInfoProductType(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {                             
          // console.log(result);
          this.ProductType = result.data;

        }, (error) => {          
          
          this.ProductType = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  // Dropdown 4
  getInfoMasterPolicyNo(){           

    this.InfoArray = [];
    // console.log(this.InfoArray);    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'), 
      
      "client_id": $('#clientdropdown').val(),
      "insurer_id": $('#insurerdropdown').val(),
      "product_type_id": $('#productdropdown').val(),
    }

    // console.log(postdata);

    this.clientCreationService.getInfoMasterPolicyNo(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {                             
          // console.log(result);
          this.MasterPolicyNo = result.data;                              

        }, (error) => {          
          
          this.MasterPolicyNo = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  document(document_id:any, claim_id:any){
    localStorage.setItem('document_id', document_id);
    localStorage.setItem('claim_id', claim_id);
    this.route.navigateByUrl('claim-document');
  }

  isNumber_tab2(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }

    // console.log(this.contactInfo.controls['zipcode'].value.length);

    if(this.basicInfo.controls['zipcode'].value.length == 6){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":this.basicInfo.controls['zipcode'].value
      }

      this.clientCreationService.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {

            // console.log(result.data);

            this.basicInfo.controls['country'].setValue(result.data[0]['country_name']);
            this.basicInfo.controls['state'].setValue(result.data[0]['state_name']);
            this.basicInfo.controls['city'].setValue(result.data[0]['districtName']);

          } else {
            // console.log(result);
          }
        }, (error) => {

      });

    }else{
      $('#country').val('');
      $('#state').val('');
      $('#city').val('');
    }

    return true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getInfoStatus(res: any){   
    
    this.displayStyleStatus = "block";  

    this.statusClaimId = res.claimid;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id":res.policyid, 
    }

    this.clientCreationService.getInfoStatus(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoStatus = result.data;           

        }, (error) => {          
          
          this.InfoStatus = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }
  
  onSubmitStatus(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "claim_id":this.statusClaimId,
      "status": $('#status').val(),
    };

    console.log(postdata);

    this.clientCreationService.onSubmitStatus(postdata, localStorage.getItem('Token'))
    .subscribe((result: any) => {
        // console.log(result);
        if (result.status == true) {
          // console.log(result);

          this.displayStyleStatus = "none";

          window.location.reload();
          
          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');
          
        } else {
          // console.log(result);
        }
      }, (error) => {
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');
        $('#errormsg').css('display', 'block');

      });


  }

  getpolicyno(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),      
    }

    this.clientCreationService.getpolicyno(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoPolicyNo = result.data;
          
        }, (error) => {          
          
          this.InfoPolicyNo = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  /******** EDIT ********/

  edituser(res: any) {

    // this.getInfoStatus(res.policy_id);

    this.addflag = 2;
    $('.stepperclass_edit').css('display', 'flex');
    $('.steppercontent_edit').css('display', 'block');

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "id": res.claimid
    };

    this.clientCreationService.InfoSingle(postdata)
      .subscribe((result: any) => {

        // step 1
        // Customer details
        this.EditInfo1.controls['member_name_edit'].setValue(result.data.claiminfo[0]['member_name']);
        // this.EditInfo1.controls['account_number_edit'].setValue(result.data.claiminfo[0]['account_number']);
        // this.EditInfo1.controls['relationship_code_edit'].setValue(result.data.claiminfo[0]['relation_idfk']);
        // this.EditInfo1.controls['relationship_edit'].setValue(result.data.claiminfo[0]['relationship']);
        this.EditInfo1.controls['customer_id_edit'].setValue(result.data.claiminfo[0]['customer_id']);
        this.EditInfo1.controls['customer_account_no_edit'].setValue(result.data.claiminfo[0]['customer_account_no']);
        // this.EditInfo1.controls['sol_id_edit'].setValue(result.data.claiminfo[0]['sol_id']);
        this.EditInfo1.controls['country_edit'].setValue(result.data.claiminfo[0]['country']);
        this.EditInfo1.controls['state_edit'].setValue(result.data.claiminfo[0]['state']);
        this.EditInfo1.controls['city_edit'].setValue(result.data.claiminfo[0]['city']);
        this.EditInfo1.controls['zipcode_edit'].setValue(result.data.claiminfo[0]['zipcode']);
        this.EditInfo1.controls['date_of_reporting_edit'].setValue(result.data.claiminfo[0]['date_of_reporting']);
        this.EditInfo1.controls['date_of_loss_edit'].setValue(result.data.claiminfo[0]['date_of_loss']);
        this.relationship_edit = result.data.claiminfo[0]['relationship'],
        this.policy_start_date_edit = result.data.claiminfo[0]['policy_start_date'],
        this.policy_end_date_edit = result.data.claiminfo[0]['policy_end_date'],

        // Claim details
        this.EditInfo1.controls['policy_no_edit'].setValue(result.data.claiminfo[0]['policy_id']);
        this.EditInfo1.controls['policy_name_edit'].setValue(result.data.claiminfo[0]['policy_name']);
        this.EditInfo1.controls['policy_start_date_edit'].setValue(result.data.claiminfo[0]['policy_start_date']);
        this.EditInfo1.controls['policy_end_date_edit'].setValue(result.data.claiminfo[0]['policy_end_date']);
        this.EditInfo1.controls['sum_assured_edit'].setValue(result.data.claiminfo[0]['sum_assured']);
        this.additionalBenefitsArray_edit = result.data.claim_additional_benefits;
        this.EditInfo1.controls['total_sum_assured_edit'].setValue(result.data.claiminfo[0]['total_sum_assured']);

        this.total_sum_assured_edit_val = result.data.claiminfo[0]['total_sum_assured'];

        this.EditInfo1.controls['type_of_claim_edit'].setValue(result.data.claiminfo[0]['claim_type_idfk']);

        if(result.data.claiminfo[0]['claim_type_idfk'] == '1'){
          $('.typediv_edit').css('display','block');
        }else{
          $('.typediv_edit').css('display','none');
        }

        this.EditInfo1.controls['cause_of_death_edit'].setValue(result.data.claiminfo[0]['cause_of_death']);

        this.EditInfo1.controls['remarks_edit'].setValue(result.data.claiminfo[0]['remarks']);
        this.EditInfo1.controls['claim_intimation_number_edit'].setValue(result.data.claiminfo[0]['claim_intimation_number']);

        this.EditInfo1.controls['edit_id'].setValue(result.data.claiminfo[0]['id']);


        // Step 2
        this.EditInfo2.controls['claimed_amount_edit'].setValue(result.data.claiminfo[0]['total_sum_assured']);
        this.EditInfo2.controls['cancelled_cheque_of_Nominee_edit'].setValue(result.data.claiminfo[0]['cancelled_cheque_of_nominee']);
        this.EditInfo2.controls['nominee_cheque_details_edit'].setValue(result.data.claiminfo[0]['nominee_cheque_details']);
        this.EditInfo2.controls['IFSC_code_edit'].setValue(result.data.claiminfo[0]['IFSC_code']);
        this.EditInfo2.controls['bank_edit'].setValue(result.data.claiminfo[0]['bank_name']);
        this.EditInfo2.controls['branch_edit'].setValue(result.data.claiminfo[0]['branch_name']);
        this.EditInfo2.controls['relationship_step2_edit'].setValue(result.data.claiminfo[0]['relationship_step2']);
        this.EditInfo2.controls['comments_edit'].setValue(result.data.claiminfo[0]['comments']);
        // this.EditInfo2.controls['date_GPA_claim_edit'].setValue(result.data.claiminfo[0]['date_of_reporting']);
        this.EditInfo2.controls['settlement_date_top_up_plan_edit'].setValue(result.data.claiminfo[0]['settlement_date_top_up_plan']);
        this.EditInfo2.controls['document_received_date_edit'].setValue(result.data.claiminfo[0]['document_received_date']);
        this.EditInfo2.controls['status_main_policy_edit'].setValue(result.data.claiminfo[0]['claim_status']);
        this.EditInfo2.controls['claim_amount_to_settle_edit'].setValue(result.data.claiminfo[0]['claim_amount_to_settled']);
        this.EditInfo2.controls['claim_settle_amount_edit'].setValue(result.data.claiminfo[0]['claim_settled_amount_main_policy']);
        this.EditInfo2.controls['nominee_name_edit'].setValue(result.data.claiminfo[0]['nominee_name']);
        this.EditInfo2.controls['settlement_date_edit'].setValue(result.data.claiminfo[0]['settled_date_main_policy']);

      }, (error) => {

      });

  }

  next_edit(step: number) {
    if (step == 1) {
      this.EditInfoSubmitted1 = true;
      if (this.EditInfoSubmitted1 && this.EditInfo1.valid) {

        let val:any = $('#total_sum_assured_edit').val();
        $('#claimed_amount_edit').val(val);

        this.stepper_edit.next();
        console.log(this.EditInfo1.value);
        this.onSubmitEditFirst();
      }
    }
    if (step == 2) {
      this.EditInfoSubmitted2 = true;
      if (this.EditInfoSubmitted2 && this.EditInfo2.valid) {
        this.stepper_edit.next();
        console.log(this.EditInfo2.value);
        this.onSubmitEditSecond();
      }
    }
  }

  // Stepper Previous Event
  previous_edit() {
    this.stepper_edit.previous();
  }

  onSubmitEditFirst() {

    let additionalarray_edit:any = [];
    for(var i = 0; i < this.additionalBenefitsArray_edit.length; i++){
      let valchecked:any = $('#additionalarrEdit_'+i).is(":checked");
      if(valchecked == false){
        // relationDetails_arr[i] = {$('#relation_'+i).val()};
      }else{
        let val:any = this.additionalBenefitsArray_edit[i];
        additionalarray_edit.push(val);
      }
    }

    this.EditInfoSubmitted1 = true;
    if (this.EditInfoSubmitted1 && this.EditInfo1.valid) {

      var postdata = {        
        
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "claim_id": this.EditInfo1.controls['edit_id'].value,
        "policy_id": this.EditInfo1.controls['policy_no_edit'].value,

        // customer details
        "member_name": this.EditInfo1.controls['member_name_edit'].value,
        // "account_number": this.EditInfo1.controls['account_number_edit'].value,
        // "relationship_code": this.EditInfo1.controls['relationship_code_edit'].value,
        // "relationship": this.relationship_edit,
        "customer_id": this.EditInfo1.controls['customer_id_edit'].value,
        "customer_account_no": this.EditInfo1.controls['customer_account_no_edit'].value,
        // "sol_id": this.EditInfo1.controls['sol_id_edit'].value,
        "country": this.EditInfo1.controls['country_edit'].value,
        "state": this.EditInfo1.controls['state_edit'].value,
        "city": this.EditInfo1.controls['city_edit'].value,
        "zipcode": this.EditInfo1.controls['zipcode_edit'].value,
        "date_of_reporting": this.EditInfo1.controls['date_of_reporting_edit'].value,
        "date_of_loss": this.EditInfo1.controls['date_of_loss_edit'].value,
        // "request_no": this.EditInfo1.controls['request_no_edit'].value,

        // claim details
        "policy_no": $("#policy_no_edit option:selected").text(),
        "policy_name": $("#policy_name_edit").val(),
        "policy_start_date": this.policy_start_date_edit,
        "policy_end_date": this.policy_end_date_edit,
        "sum_assured": $('#sum_assured_edit').val(),
        "addtional_benefits": JSON.stringify(additionalarray_edit),
        "total_sum_assured": $('#total_sum_assured_edit').val(),
        "type_of_claim": this.EditInfo1.controls['type_of_claim_edit'].value,
        "cause_of_death": this.EditInfo1.controls['cause_of_death_edit'].value,
        "remarks": this.EditInfo1.controls['remarks_edit'].value,
        "claim_intimation_number": this.EditInfo1.controls['claim_intimation_number_edit'].value,

      };

      console.log(postdata);

      this.clientCreationService.onSubmitEditFirst(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);

            this.claim_id_edit = result.data.claim_id;

            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
        });

    }

  }

  onSubmitEditSecond() {

    this.EditInfoSubmitted2 = true;
    if (this.EditInfoSubmitted2 && this.EditInfo2.valid) {

      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "claim_id": this.claim_id_edit,
        "policy_id": this.EditInfo1.controls['policy_no_edit'].value,

        "claimed_amount": this.EditInfo2.controls['claimed_amount_edit'].value,
        "cancelled_cheque_of_Nominee": this.EditInfo2.controls['cancelled_cheque_of_Nominee_edit'].value,
        "nominee_cheque_details": this.EditInfo2.controls['nominee_cheque_details_edit'].value,
        "IFSC_code": this.EditInfo2.controls['IFSC_code_edit'].value,
        "bank": this.EditInfo2.controls['bank_edit'].value,
        "branch": this.EditInfo2.controls['branch_edit'].value,
        "relationship_step2": this.relationship_edit,
        "comments": this.EditInfo2.controls['comments_edit'].value,
        // "date_of_GPA_claim": $('#date_GPA_claim_edit').val(),
        // "settlement_date_top_up_plan": this.EditInfo2.controls['settlement_date_edit'].value,
        "document_received_date": this.EditInfo2.controls['document_received_date_edit'].value,
        "claim_status": this.EditInfo2.controls['status_main_policy_edit'].value,
        "claim_amount_to_settle": this.EditInfo2.controls['claim_amount_to_settle_edit'].value,
        // "claim_settle_amount": this.EditInfo2.controls['claim_settle_amount_edit'].value,
        "nominee_name": this.EditInfo2.controls['nominee_name_edit'].value,
        "settlement_date": this.EditInfo2.controls['settlement_date_edit'].value,
      };

      console.log(postdata);

      this.clientCreationService.onSubmitEditSecond(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);

            window.location.reload();
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

  }

  totalSI(val:any, additionalarr_i:any){
    var sum_assured:any = $('#sum_assured').val();
    let val1:any = $('#' + additionalarr_i).is(':checked');

    if(val1 == true){
      this.total_sum_assured = parseInt(this.total_sum_assured) + parseInt(val);
    }else{
      this.total_sum_assured = parseInt(this.total_sum_assured) - parseInt(val);
    }

    if(this.total_sum_assured == 0){
      $('#total_sum_assured').val(sum_assured);
    }else{
      $('#total_sum_assured').val(this.total_sum_assured);
    }

  }

  totalSI_edit(val:any, additionalarr_i:any){
    var sum_assured:any = $('#sum_assured_edit').val();
    let val1:any = $('#' + additionalarr_i).is(':checked');

    if(val1 == true){
      this.total_sum_assured_edit_val = parseInt(this.total_sum_assured_edit_val) + parseInt(val);
    }else{
      this.total_sum_assured_edit_val = parseInt(this.total_sum_assured_edit_val) - parseInt(val);
    }

    if(this.total_sum_assured_edit_val == 0){
      $('#total_sum_assured_edit').val(sum_assured);
    }else{
      $('#total_sum_assured_edit').val(this.total_sum_assured_edit_val);
    }

  }

  getInfoclaimnumber(res:any){

    this.displayStyleStatus = "block";

    this.statusClaimId = res.id;   
    this.policy_id = res.policy_id ;

    $('#status').val(res.claim_intimation_number);    

  }

  submitclaimnumber(){
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "claim_id": this.statusClaimId,
      "claim_number": $('#status').val(),
      "policy_id": this.policy_id,
    }

    this.clientCreationService.onSubmitClaimNumber(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {

        this.show = false;
        // console.log(result);
        this.displayStyleStatus = "none";
        // this.getInfo();
        // this.dtTrigger.next();
        window.location.reload();

      }, (error) => {

        this.displayStyleStatus = "none";

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });
  }

  checktype_claim(e:any){
    let val:any = e.target.value;
    if(val == '1'){
      $('.typediv').css('display','block');
    }else{
      $('.typediv').css('display','none');
    }
  }

  checktype_claim_edit(e:any){
    let val:any = e.target.value;
    if(val == '1'){
      $('.typediv_edit').css('display','block');
    }else{
      $('.typediv_edit').css('display','none');
    }
  }

  downloadCSV() {    

    let finalArr:any = this.InfoArray;

    for(var i = 0; i < finalArr.length; i++){

      delete finalArr[i]['id'];
      delete finalArr[i]['policy_id'];
      delete finalArr[i]['is_active'];

    }

    // console.log(finalArr);    

    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.InfoArray);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */  
    XLSX.writeFile(wb, 'Claim_Data.xlsx');

  }

}
