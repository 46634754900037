<div id="makertData" class="bs-stepper bg-white my-4 mb-5 rounded p-3">    

    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title alignc">Benefit Benchmarking        
        <!-- <button type="button" class="btn btn-success" (click)="exportcsv()" role="button" style="float:right; margin-right: 10px;">Export Excel</button>
        <button type="button" class="btn btn-success" (click)="openPDF()" role="button" style="float:right; margin-right: 10px;">Export PDF</button> -->
        <img src="../../../assets/img/common icons/xlsx.png" (click)="exportcsv()" style="float:right; margin-right: 10px; width: 40px; cursor: pointer" />
        <img src="../../../assets/img/common icons/pdf.png" (click)="openPDF()" style="float:right; margin-right: 10px; width: 40px; cursor: pointer" />
    </h3>
    <hr>

    <!-- Filters -->
    <div class="col-sm-12">

        <div class="row mb-3">
            
            <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                <label class="bs-form-label">Industry Type</label>
                <select class="form-select" id="industry" (change)="getgmc_Section1()">
                    <option value="" selected>Select</option>
                    
                    <optgroup label="AGRICULTURE BASED">
                        <option *ngFor="let title of Array1" [value]="title.industry_name">
                        {{title.industry_name}}
                        </option>                        
                    </optgroup>
                
                    <optgroup label="AUTO & ANCILLIARY">
                        <option *ngFor="let title of Array2" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="CONSUMER DURABLES">
                        <option *ngFor="let title of Array3" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="FINANCIAL & BANKING">
                        <option *ngFor="let title of Array4" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="FMCG FAST MOVING CONSUMER GOODS">
                        <option *ngFor="let title of Array5" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="HEALTH CARE">
                        <option *ngFor="let title of Array6" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="INFORMATION TECHNOLOGY & COMMUNICATION">
                        <option *ngFor="let title of Array7" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>
                
                    <optgroup label="METAL & MINNING">
                        <option *ngFor="let title of Array8" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="OTHER INDUSTRIES">
                        <option *ngFor="let title of Array9" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="POWER">
                        <option *ngFor="let title of Array10" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="RETAILING">
                        <option *ngFor="let title of Array11" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="SERVICE SECTOR">
                        <option *ngFor="let title of Array12" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>

                    <optgroup label="TEXTILES">
                        <option *ngFor="let title of Array13" [value]="title.industry_name">
                            {{title.industry_name}}
                        </option>
                    </optgroup>
                    
                </select>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                <label class="bs-form-label">Headcount</label>
                <select class="form-select" id="headcount" (change)="getgmc_Section1()">
                    <option value="" selected>Select</option>
                    <option value="Upto 100 (usually lifes will be multiply by 4 or 5)">Upto 100 (usually lifes will be multiply by 4 or 5)</option>
                    <option value="101 to 250">101 to 250</option>
                    <option value="251 to 500">251 to 500</option>
                    <option value="501 to 1000">501 to 1000</option>
                    <option value="1001 to 2000">1001 to 2000</option>
                    <option value="2001 to 5000">2001 to 5000</option>
                    <option value="5001 to 10000">5001 to 10000</option>
                    <option value="10001 to 25000">10001 to 25000</option>
                    <option value="25001 and more">25001 and more</option>
                </select>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                <label class="bs-form-label">MNC/Non MNC</label>
                <select class="form-select" id="mnc_type" (change)="getgmc_Section1()">
                    <option value="" selected>Select</option>
                    <option value="mnc" selected>Mnc</option>
                    <option value="non_mnc" selected>Non- Mnc</option>
                </select>
            </div>

            <!-- <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                <label class="bs-form-label">Percentile</label>                                
                <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
            </div> -->
        </div>        

    </div> 
    <!-- End -->

    <!-- Tabs Headings -->
    <ul class="nav nav-tabs mt-4" id="myTab" role="tablist">
        <li class="nav-item liwidth" role="presentation">
            <button class="nav-link active w100" id="gmc-tab" data-bs-toggle="tab" data-bs-target="#gmc" type="button"
                role="tab" aria-controls="home" aria-selected="true">GMC</button>
        </li>
        <li class="nav-item liwidth" role="presentation">
            <button class="nav-link w100" id="gpa-tab" data-bs-toggle="tab" data-bs-target="#gpa" type="button"
                role="tab" aria-controls="gpa" aria-selected="false">GPA</button>
        </li>
        <li class="nav-item liwidth" role="presentation">
            <button class="nav-link w100" id="gtl-tab" data-bs-toggle="tab" data-bs-target="#gtl" type="button"
                role="tab" aria-controls="gtl" aria-selected="false">GTL</button>
        </li>
        <li class="nav-item liwidth" role="presentation">
            <button class="nav-link w100" id="opd-tab" data-bs-toggle="tab" data-bs-target="#opd" type="button"
                role="tab" aria-controls="opd" aria-selected="false">OPD</button>
        </li>
    </ul>

    <!-- Tabs Content -->
    <div class="tab-content" id="myTabContent">

        <!-- Tab GMC -->
        <div class="tab-pane fade show active" id="gmc" role="tabpanel" aria-labelledby="gmc-tab">

            <!---------------------- Section 1 ------------------------->
            <div class="divbox">
                <div class="seven">
                    <h1><i>Primary Benefits</i></h1>
                </div>

                <div class="table-responsive">            
                    <table class="table" id="section1">
                        <thead class="thead">
                            <tr style="border-bottom: solid 1px #eee; height:40px">
                                <th *ngFor="let groupheader of gmc_section1.tableData.header; let i = index;" class="bs-form-label text-white text-center">
                                    {{groupheader}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">                    
                            <tr *ngFor="let group of gmc_section1.tableData.me; let i = index;" style="border-bottom: solid 1px #eee; height:40px">                                                
        
                                <td class="bs-form-label text-black text-center">                                
                                    {{gmc_section1.tableData.me[i]}}
                                </td>
                                
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section1.tableData.classes[i] == 'suminsuredbelow25p' || gmc_section1.tableData.classes[i] == 'familydefinationbelow25p' || gmc_section1.tableData.classes[i] == 'ambulancechargesbelow25p' || gmc_section1.tableData.classes[i] == 'preandpostbelow25p'}">                            
                                    {{gmc_section1.tableData.below25[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section1.tableData.classes[i] == 'suminsuredbelow25p' || gmc_section1.tableData.classes[i] == 'familydefinationbelow25p' || gmc_section1.tableData.classes[i] == 'ambulancechargesbelow25p' || gmc_section1.tableData.classes[i] == 'preandpostbelow25p'" />

                                </td>

                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section1.tableData.classes[i] == 'suminsured25to50p' || gmc_section1.tableData.classes[i] == 'familydefination25to50p' || gmc_section1.tableData.classes[i] == 'ambulancecharges25to50p' || gmc_section1.tableData.classes[i] == 'preandpost25to50p'}" *ngIf="comp_0 == true">                            
                                    {{gmc_section1.tableData.p25to50[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section1.tableData.classes[i] == 'suminsured25to50p' || gmc_section1.tableData.classes[i] == 'familydefination25to50p' || gmc_section1.tableData.classes[i] == 'ambulancecharges25to50p' || gmc_section1.tableData.classes[i] == 'preandpost25to50p'" />

                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section1.tableData.classes[i] == 'suminsured50to75p' || gmc_section1.tableData.classes[i] == 'familydefination50to75p' || gmc_section1.tableData.classes[i] == 'ambulancecharges50to75p' || gmc_section1.tableData.classes[i] == 'preandpost50to75p'}" *ngIf="comp_1 == true">                            
                                    {{gmc_section1.tableData.p50to75[i]}}
                                    
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section1.tableData.classes[i] == 'suminsured50to75p' || gmc_section1.tableData.classes[i] == 'familydefination50to75p' || gmc_section1.tableData.classes[i] == 'ambulancecharges50to75p' || gmc_section1.tableData.classes[i] == 'preandpost50to75p'" />
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section1.tableData.classes[i] == 'suminsured75to100p' || gmc_section1.tableData.classes[i] == 'familydefination75to100p' || gmc_section1.tableData.classes[i] == 'ambulancecharges75to100p' || gmc_section1.tableData.classes[i] == 'preandpost75to100p'}" *ngIf="comp_2 == true">                            
                                    {{gmc_section1.tableData.p75to100[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section1.tableData.classes[i] == 'suminsured75to100p' || gmc_section1.tableData.classes[i] == 'familydefination75to100p' || gmc_section1.tableData.classes[i] == 'ambulancecharges75to100p' || gmc_section1.tableData.classes[i] == 'preandpost75to100p'" />

                                </td>
                                      
                            </tr>
                        </tbody>
                    </table>
                </div>
             
                <hr>
                <div class="row" id="chart">

                    <div class="col-sm-12 row mb-5">

                        <div class="col-sm-8"></div>

                        <div class="col-sm-1">
                            <label class="bs-form-label" style="padding-top: 22px;"><b>Percentile</b></label>                                
                        </div>                    
    
                        <div class="col-sm-3 form-group">                        
                            <ngx-slider [(value)]="value" [options]="options" (mouseup)="getval()"></ngx-slider>                        
                        </div>                           

                    </div>
                    
                    <div class="col-sm-4">
                        <div [chart]="line" class="chartdiv"></div>
                    </div>
                
                    <div class="col-sm-4">
                        <div [chart]="bar" class="chartdiv"></div>
                    </div>

                    <div class="col-sm-4">
                        <div [chart]="column" class="chartdiv"></div>
                    </div>

                </div>
            </div>
            <hr>

            <!---------------------- Section 2 ------------------------->            

            <div class="divbox mt-5">
                <div class="seven">
                    <h1><i>Maternity Benefits</i></h1>
                </div>

                <div class="table-responsive">            
                    <table class="table" id="section1">
                        <thead class="thead">
                            <tr style="border-bottom: solid 1px #eee; height:40px">
                                <th *ngFor="let groupheader2 of gmc_section2_Arr.tableData.header; let i = index;" class="bs-form-label text-white text-center">
                                    {{groupheader2}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">                    
                            <tr *ngFor="let group of gmc_section2_Arr.tableData.me; let i = index;" style="border-bottom: solid 1px #eee; height:40px">                                                
        
                                <td class="bs-form-label text-black text-center">                                
                                    {{gmc_section2_Arr.tableData.me[i]}}
                                </td>
                                
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section2_Arr.tableData.classes[i] == 'ndeliverybelow25p' || gmc_section2_Arr.tableData.classes[i] == 'cdeliverybelow25p' || gmc_section2_Arr.tableData.classes[i] == 'infertilitybelow25p'}" *ngIf="below25 == true">                            
                                    {{gmc_section2_Arr.tableData.below25[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section2_Arr.tableData.classes[i] == 'ndeliverybelow25p' || gmc_section2_Arr.tableData.classes[i] == 'cdeliverybelow25p' || gmc_section2_Arr.tableData.classes[i] == 'infertilitybelow25p'" />

                                </td>

                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section2_Arr.tableData.classes[i] == 'ndelivery25to50p' || gmc_section2_Arr.tableData.classes[i] == 'cdelivery25to50p' || gmc_section2_Arr.tableData.classes[i] == 'infertility25to50p'}" *ngIf="comp_0 == true">                            
                                    {{gmc_section2_Arr.tableData.p25to50[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section2_Arr.tableData.classes[i] == 'ndelivery25to50p' || gmc_section2_Arr.tableData.classes[i] == 'cdelivery25to50p' || gmc_section2_Arr.tableData.classes[i] == 'infertility25to50p'" />

                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section2_Arr.tableData.classes[i] == 'ndelivery50to75p' || gmc_section2_Arr.tableData.classes[i] == 'cdelivery50to75p' || gmc_section2_Arr.tableData.classes[i] == 'infertility50to75p'}" *ngIf="comp_1 == true">                            
                                    {{gmc_section2_Arr.tableData.p50to75[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section2_Arr.tableData.classes[i] == 'ndelivery50to75p' || gmc_section2_Arr.tableData.classes[i] == 'cdelivery50to75p' || gmc_section2_Arr.tableData.classes[i] == 'infertility50to75p'" />

                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section2_Arr.tableData.classes[i] == 'ndelivery75to100p' || gmc_section2_Arr.tableData.classes[i] == 'cdelivery75to100p' || gmc_section2_Arr.tableData.classes[i] == 'infertility75to100p'}" *ngIf="comp_2 == true">                            
                                    {{gmc_section2_Arr.tableData.p75to100[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section2_Arr.tableData.classes[i] == 'ndelivery75to100p' || gmc_section2_Arr.tableData.classes[i] == 'cdelivery75to100p' || gmc_section2_Arr.tableData.classes[i] == 'infertility75to100p'" />

                                </td>
                                      
                            </tr>
                        </tbody>
                    </table>
                </div>
             
                <hr>
                <div class="row" id="chart">

                    <div class="col-sm-12 row mb-5">

                        <div class="col-sm-8"></div>

                        <div class="col-sm-1">
                            <label class="bs-form-label" style="padding-top: 22px;"><b>Percentile</b></label>                                
                        </div>                    
    
                        <div class="col-sm-3 form-group">                        
                            <ngx-slider [(value)]="value2" [options]="options2" (mouseup)="getval2()"></ngx-slider>                        
                        </div>                          

                    </div>

                    <div class="col-sm-4">
                        <div [chart]="gmc_graph1" class="chartdiv"></div>
                    </div>
                
                    <div class="col-sm-4">
                        <div [chart]="gmc_graph2" class="chartdiv"></div>
                    </div>                  

                </div>
            </div>
            <hr>

            <!---------------------- Section 3 ------------------------->
            <div class="divbox mt-5">

                <div class="seven">
                    <h1><i>Cost control Benefits</i></h1>
                </div>

                <div class="table-responsive">            
                    <table class="table" id="section1">
                        <thead class="thead">
                            <tr style="border-bottom: solid 1px #eee; height:40px">
                                <th *ngFor="let groupheader3 of gmc_section3_Arr.tableData.header; let i = index;" class="bs-form-label text-white text-center">
                                    {{groupheader3}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">                    
                            <tr *ngFor="let group of gmc_section3_Arr.tableData.me; let i = index;" style="border-bottom: solid 1px #eee; height:40px">                                                
        
                                <td class="bs-form-label text-black text-center">                                
                                    {{gmc_section3_Arr.tableData.me[i]}}
                                </td>
                                
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == true">                            
                                    {{gmc_section3_Arr.tableData.below25[i]}}
                                </td>

                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == true">                            
                                    {{gmc_section3_Arr.tableData.p25to50[i]}}
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == true">                            
                                    {{gmc_section3_Arr.tableData.p50to75[i]}}
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == true">                            
                                    {{gmc_section3_Arr.tableData.p75to100[i]}}
                                </td>
                                      
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr>
                <div class="row">

                    <div class="col-sm-12 row mb-5">

                        <div class="col-sm-8"></div>

                        <div class="col-sm-1">
                            <label class="bs-form-label" style="padding-top: 22px;"><b>Percentile</b></label>                                
                        </div>                    
    
                        <div class="col-sm-3 form-group">                        
                            <ngx-slider [(value)]="value3" [options]="options3" (mouseup)="getval3()"></ngx-slider>                        
                        </div>    

                    </div>

                    <div class="col-sm-4">
                        <div [chart]="gmc_graph3" class="chartdiv"></div>
                    </div>
                
                    <div class="col-sm-4">
                        <div [chart]="gmc_graph4" class="chartdiv"></div>
                    </div>

                    <div class="col-sm-4">
                        <!-- Graph here -->
                    </div>

                </div>
            </div>  
            <hr>

            <!---------------------- Section 4 ------------------------->           

            <div class="divbox mt-5">
                <div class="seven">
                    <h1><i>Other Benefits</i></h1>
                </div>

                <div class="table-responsive">            
                    <table class="table" id="section1">
                        <thead class="thead">
                            <tr style="border-bottom: solid 1px #eee; height:40px">
                                <th *ngFor="let groupheader4 of gmc_section4_Arr.tableData.header; let i = index;" class="bs-form-label text-white text-center">
                                    {{groupheader4}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">                    
                            <tr *ngFor="let group of gmc_section4_Arr.tableData.me; let i = index;" style="border-bottom: solid 1px #eee; height:40px">                                                
        
                                <td class="bs-form-label text-black text-center">                                
                                    {{gmc_section4_Arr.tableData.me[i]}}
                                </td>
                                
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section4_Arr.tableData.classes[i] == 'Externalcongenitalbelow25p' || gmc_section4_Arr.tableData.classes[i] == 'Lasikbelow25p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanationbelow25p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliarybelow25p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonorbelow25p'}" *ngIf="below25 == true">                            
                                    {{gmc_section4_Arr.tableData.below25[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section4_Arr.tableData.classes[i] == 'Externalcongenitalbelow25p' || gmc_section4_Arr.tableData.classes[i] == 'Lasikbelow25p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanationbelow25p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliarybelow25p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonorbelow25p'" />

                                </td>

                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section4_Arr.tableData.classes[i] == 'Externalcongenital25to50p' || gmc_section4_Arr.tableData.classes[i] == 'Lasik25to50p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanation25to50p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliary25to50p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonor25to50p'}" *ngIf="comp_0 == true">                            
                                    {{gmc_section4_Arr.tableData.p25to50[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section4_Arr.tableData.classes[i] == 'Externalcongenital25to50p' || gmc_section4_Arr.tableData.classes[i] == 'Lasik25to50p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanation25to50p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliary25to50p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonor25to50p'" />

                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section4_Arr.tableData.classes[i] == 'Externalcongenital50to75p' || gmc_section4_Arr.tableData.classes[i] == 'Lasik50to75p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanation50to75p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliary50to75p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonor50to75p'}" *ngIf="comp_1 == true">                            
                                    {{gmc_section4_Arr.tableData.p50to75[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section4_Arr.tableData.classes[i] == 'Externalcongenital50to75p' || gmc_section4_Arr.tableData.classes[i] == 'Lasik50to75p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanation50to75p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliary50to75p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonor50to75p'" />

                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" [ngClass]="{'borderme': gmc_section4_Arr.tableData.classes[i] == 'Externalcongenital75to100p' || gmc_section4_Arr.tableData.classes[i] == 'Lasik75to100p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanation75to100p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliary75to100p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonor75to100p'}" *ngIf="comp_2 == true">                            
                                    {{gmc_section4_Arr.tableData.p75to100[i]}}
                                    <img src="../../../assets/img/policy/tickicon.png" style="float: right; position: sticky; top: 100%;" *ngIf="gmc_section4_Arr.tableData.classes[i] == 'Externalcongenital75to100p' || gmc_section4_Arr.tableData.classes[i] == 'Lasik75to100p' || gmc_section4_Arr.tableData.classes[i] == 'Vaccanation75to100p' || gmc_section4_Arr.tableData.classes[i] == 'Domiciliary75to100p' || gmc_section4_Arr.tableData.classes[i] == 'orgondonor75to100p'" />

                                </td>
                                      
                            </tr>
                        </tbody>
                    </table>
                </div>
               
            </div>            

            <div class="divbox mt-5">
                <div class="seven">
                    <h1><i>Medical Peer</i></h1>
                </div>

                <div [chart]="other_graph" class="chartdiv"></div>

            </div>

        </div>

        <!-- Tab GPA -->
        <div class="tab-pane fade" id="gpa" role="tabpanel" aria-labelledby="gpa-tab">
            
            <div class="divbox">
                <div class="seven">
                    <h1><i>Sum Assured</i></h1>
                </div>

                <div class="table-responsive">            
                    <table class="table" id="section1">
                        <thead class="thead">
                            <tr style="border-bottom: solid 1px #eee; height:40px">
                                <th *ngFor="let header of gmc_section1.tableData.header; let i = index;" class="bs-form-label text-white text-center">
                                    {{header}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">                    
                            <tr *ngFor="let group of gmc_section1.tableData.me; let i = index;" style="border-bottom: solid 1px #eee; height:40px">                                                
        
                                <td class="bs-form-label text-black text-center">                                
                                    {{gmc_section1.tableData.me[i]}}
                                </td>
                                
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == true">                            
                                    {{gmc_section1.tableData.below25[i]}}
                                </td>

                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == true">                            
                                    {{gmc_section1.tableData.p25to50[i]}}
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == true">                            
                                    {{gmc_section1.tableData.p50to75[i]}}
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == true">                            
                                    {{gmc_section1.tableData.p75to100[i]}}
                                </td>
                                      
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                        
                    <div class="col-sm-6">

                        <div class="pbardiv mt-3">
                            <h6 class="text-center h55">Industry Best Practice</h6>
                            <!-- <hr> -->
                            <div class="progress mt-3" style="height: 2rem;">
                                <div class="progress-bar" role="progressbar" style="width: 46%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">46%</div>
                                <div class="progress-bar bg-success" role="progressbar" style="width: 42%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">41%</div>
                                <div class="progress-bar bg-info" role="progressbar" style="width: 13%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">13%</div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-4" style="display: flex;">
                                    <div class="spanMOS"></div>
                                    <label class="bs-form-label labelT">Multiples of Salary</label>
                                </div>
                                <div class="col-sm-4" style="display: flex;">
                                    <div class="spanGB"></div>
                                    <label class="bs-form-label labelT">Graded Benefits</label>
                                </div>
                                <div class="col-sm-4" style="display: flex;">
                                    <div class="spanFSI"></div>
                                    <label class="bs-form-label labelT">Fix Sum Insured</label>
                                </div>
                            </div>
                        </div>

                    </div>                        

                    <div class="col-sm-6">

                        <h6 class="text-center mt-3 h55">Riders</h6>
                        <!-- <hr> -->
                        Graph here
                        
                    </div>

                </div>

            </div>            

        </div>

        <!-- Tab GTL -->
        <div class="tab-pane fade" id="gtl" role="tabpanel" aria-labelledby="gtl-tab">
            
            <div class="divbox">
                <div class="seven">
                    <h1><i>Sum Assured</i></h1>
                </div>

                <div class="table-responsive">            
                    <table class="table" id="section1">
                        <thead class="thead">
                            <tr style="border-bottom: solid 1px #eee; height:40px">
                                <th *ngFor="let header of gtl_array.tableData.header; let i = index;" class="bs-form-label text-white text-center">
                                    {{header}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody">                    
                            <tr *ngFor="let group of gtl_array.tableData.me; let i = index;" style="border-bottom: solid 1px #eee; height:40px">                                                
        
                                <td class="bs-form-label text-black text-center">                                
                                    {{gtl_array.tableData.me[i]}}
                                </td>
                                
                                <!-- <td class="bs-form-label text-black text-center" *ngIf="below25 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="below25 == true">                            
                                    {{gtl_array.tableData.below25[i]}}
                                </td> -->

                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_0 == true">                            
                                    {{gtl_array.tableData.p25[i]}} 
                                    <span *ngIf="i == 0">
                                        Times of Annual
                                    </span>
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_1 == true">                            
                                    {{gtl_array.tableData.p50[i]}}
                                    <span *ngIf="i == 0">
                                        Times of Annual
                                    </span>
                                </td>
        
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == false">
                                    -
                                </td>
                                <td class="bs-form-label text-black text-center" *ngIf="comp_2 == true">                            
                                    {{gtl_array.tableData.p75[i]}}
                                    <span *ngIf="i == 0">
                                        Times of Annual
                                    </span>
                                </td>
                                      
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                        
                    <div class="col-sm-6">

                        <div class="pbardiv mt-3">
                            <h6 class="text-center h55">Industry Best Practice</h6>
                            <!-- <hr> -->
                            <div class="progress mt-3" style="height: 2rem;">
                                <div class="progress-bar gtl_IBP_MOS" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">{{gtl_IBP_MOS}}%</div>
                                <div class="progress-bar bg-success gtl_IBP_GB" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">{{gtl_IBP_GB}}%</div>
                                <div class="progress-bar bg-info gtl_IBP_FSA" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">{{gtl_IBP_FSA}}%</div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-4" style="display: flex;">
                                    <div class="spanMOS"></div>
                                    <label class="bs-form-label labelT">Multiples of Salary</label>
                                </div>
                                <div class="col-sm-4" style="display: flex;">
                                    <div class="spanGB"></div>
                                    <label class="bs-form-label labelT">Graded Benefits</label>
                                </div>
                                <div class="col-sm-4" style="display: flex;">
                                    <div class="spanFSI"></div>
                                    <label class="bs-form-label labelT">Fix Sum Insured</label>
                                </div>
                            </div>
                        </div>

                    </div>                        

                    <div class="col-sm-6">

                        <h6 class="text-center mt-3 h55">Riders</h6>
                        <!-- <hr> -->
                        <div [chart]="gtl_bar" class="chartdiv"></div>
                        
                    </div>

                </div>
                
            </div>

        </div>

        <!-- Tab OPD -->
        <div class="tab-pane fade" id="opd" role="tabpanel" aria-labelledby="opd-tab">
            comming soon...
        </div>

    </div>    

</div>