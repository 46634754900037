import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Service } from '../benefit-benchmarking/service';
import { Chart } from 'angular-highcharts';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-benefit-benchmarking',
  templateUrl: './benefit-benchmarking.component.html',
  styleUrls: ['./benefit-benchmarking.component.css']
})
export class BenefitBenchmarkingComponent implements OnInit {

  @ViewChild('chart') htmlData!: ElementRef;

  line: Chart;  
  bar: Chart;
  column: Chart;
  spline: Chart;
  area: Chart;
  pie: Chart;
  columnLabel: Chart;
  barLabel: Chart;
  lineLabel: Chart;

  gmc_graph1: Chart;
  gmc_graph2: Chart;
  gmc_graph3: Chart;
  gmc_graph4: Chart;

  gtl_bar: Chart;

  other_graph: Chart;

  resstatus = localStorage.getItem('resstatus');

  gmc_section1: any = {};
  gmc_section2_Arr: any = {};
  gmc_section3_Arr: any = {};
  gmc_section4_Arr: any = {};

  gtl_array: any = {};

  below25: any;
  comp_0: any;
  comp_1: any;
  comp_2: any;
  comp_3: any;
  comp_4: any;

  below25_sec2: any;
  comp_0_sec2: any;
  comp_1_sec2: any;
  comp_2_sec2: any;
  comp_3_sec2: any;
  comp_4_sec2: any;

  below25_sec3: any;
  comp_0_sec3: any;
  comp_1_sec3: any;
  comp_2_sec3: any;
  comp_3_sec3: any;
  comp_4_sec3: any;

  below25_sec4: any;
  comp_0_sec4: any;
  comp_1_sec4: any;
  comp_2_sec4: any;
  comp_3_sec4: any;
  comp_4_sec4: any;

  below25_gtl: any;
  comp_0_gtl: any;
  comp_1_gtl: any;
  comp_2_gtl: any;
  comp_3_gtl: any;
  comp_4_gtl: any;

  show = false;
  fullScreen = true;
  template = ``;

  percentialambulance25:any = [];
  percentialpreposthospt25:any = [];
  percentialsuminsured25:any = [];

  percentialambulance25_sec2:any = [];
  percentialpreposthospt25_sec2:any = [];
  percentialsuminsured25_sec2:any = [];

  percentialambulance25_sec3:any = [];
  percentialpreposthospt25_sec3:any = [];
  percentialsuminsured25_sec3:any = [];

  percentialambulance25_sec4:any = [];
  percentialpreposthospt25_sec4:any = [];
  percentialsuminsured25_sec4:any = [];

  glt_graph_data: any = [];

  setgmc_Section4_chart: any = [];
  setgmc_Section4_title: any = [];
  setgmc_Section4_Covered: any = [];
  setgmc_Section4_NotCovered: any = [];

  value: number = 25;
  highValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100
  };

  value2: number = 25;
  highValue2: number = 100;
  options2: Options = {
    floor: 0,
    ceil: 100
  };

  value3: number = 25;
  highValue3: number = 100;
  options3: Options = {
    floor: 0,
    ceil: 100
  };

  value4: number = 25;
  highValue4: number = 100;
  options4: Options = {
    floor: 0,
    ceil: 100
  };

  Array1: any = [];
  Array2: any = [];
  Array3: any = [];
  Array4: any = [];
  Array5: any = [];
  Array6: any = [];
  Array7: any = [];
  Array8: any = [];
  Array9: any = [];
  Array10: any = [];
  Array11: any = [];
  Array12: any = [];
  Array13: any = [];

  // Industry Best Practice
  gtl_IBP_MOS: any;
  gtl_IBP_GB: any;
  gtl_IBP_FSA: any;
  glt_graph_key: any = [];
  glt_graph_value: any = [];

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == null) {
          this.route.navigate(['/home']);
      }      
      
    });

  }

  ngOnInit(): void {
    this.initGroup();
  }

  initGroup(){      
    
    //GMC
    this.getgmc_Section1(); 
    this.getgmc_Section2(); 
    this.getgmc_Section3();
    this.getgmc_Section4(); 
    this.getIndustryType();

    //GTL
    this.getgtl();

  }

  getIndustryType(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
    };
    this.dataservice.getIndustryType(postdata)
      .subscribe((result) => {

        this.Array1 = result.data[0]['AGRICULTURE_-_BASED'];
        this.Array2 = result.data[1]['AUTO_&_ANCILLIARY'];
        this.Array3 = result.data[2]['CONSUMER_DURABLES'];
        this.Array4 = result.data[3]['FINANCIAL_&_BANKING'];
        this.Array5 = result.data[4]['FMCG_-_FAST_MOVING_CONSUMER_GOODS'];
        this.Array6 = result.data[5]['HEALTH_CARE'];
        this.Array7 = result.data[6]['INFORMATION_TECHNOLOGY_&_COMMUNICATION'];
        this.Array8 = result.data[7]['METAL_&_MINNING'];
        this.Array9 = result.data[8]['OTHER_INDUSTRIES'];
        this.Array10 = result.data[9]['POWER'];
        this.Array11 = result.data[10]['RETAILING'];
        this.Array12 = result.data[11]['SERVICE_SECTOR'];
        this.Array13 = result.data[12]['TEXTILES'];
               
      }, (error) => {
          // console.log(result);
    });
  }

  /*********** GMC Section 1 API **********/
  getgmc_Section1(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
    };

    this.dataservice.getgmc_Section1(postdata)
      .subscribe((result) => {

        this.gmc_section1 = result.data;
        
        this.below25 = 'below25' in result.data.tableData; 
        this.comp_0 = 'p25to50' in result.data.tableData;
        this.comp_1 = 'p50to75' in result.data.tableData;
        this.comp_2 = 'p75to100' in result.data.tableData;        
        
        $('#industry').val(result.data.tableData.filters.industry);
        $('#headcount').val(result.data.tableData.filters.headcount);
        $('#mnc_type').val(result.data.tableData.filters.mnc_type);

        this.getgmc_Section1_chart();

        // this.percentialambulance25 = result.data.graphData.percentialambulance25;
        // this.percentialpreposthospt25 = result.data.graphData.p60_days_90_days;
        // this.percentialsuminsured25 = result.data.graphData.percentialsuminsured25;

        // this.barchart(this.percentialambulance25);
        // this.linechart(this.percentialpreposthospt25); 
        // this.columnchart(this.percentialsuminsured25);       

      }, (error) => {
          // console.log(result);
    });

  }      

  getgmc_Section1_chart(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
      "percentile": this.value,
    };

    this.dataservice.getgmc_Section1_chart(postdata)
      .subscribe((result) => {

        this.show = false;
       
        this.percentialambulance25 = result.data.graphData.percentialambulance;
        this.percentialpreposthospt25 = result.data.graphData.pre_post_graph;
        this.percentialsuminsured25 = result.data.graphData.percentialsuminsured;

        this.barchart(this.percentialambulance25);
        this.linechart(this.percentialpreposthospt25, result.data.graphData.pre_post_type); 
        this.columnchart(this.percentialsuminsured25);       

      }, (error) => {
          // console.log(result);
    });

  }
  /**************** End ***************/

  /*********** GMC Section 1 Grpahs **********/
  barchart(percentialambulance25: any) {
    let barchart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential Ambulance ' + this.value,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialambulance25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {             
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {          
        },
      },
      
      series: [
        {
          name: 'Percential Ambulance ' + this.value,
          type: 'column',
          data: percentialambulance25,
          
        }
      ],
    });
    this.bar = barchart;    
    barchart.ref;
  }

  linechart(percentialpreposthospt25: any, pre_post_type:any) {
    let linechart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential Prepost Hospt ' + this.value,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialpreposthospt25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {        
        title: {
          // text: 'percentialpreposthospt25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {
        },
      },
     
      series: [
        {
          name: pre_post_type,
          type: 'column',
          data: percentialpreposthospt25,
          
        },
      ],
    });
    this.line = linechart;    
    linechart.ref;
  }

  columnchart(percentialsuminsured25: any){
    let linechart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential Sum Insured ' + this.value,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialsuminsured25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {        
        title: {
          // text: 'percentialsuminsured25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {
        },
      },
     
      series: [
        {
          name: 'Percential Sum Insured ' + this.value,
          type: 'column',
          data: percentialsuminsured25,
          
        },
      ],
    });
    this.column = linechart;    
    linechart.ref;
  }
  /**************** End ****************/


  /*********** GMC Section 2 API **********/
  getgmc_Section2(){    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
    };

    this.dataservice.getgmc_Section2(postdata)
      .subscribe((result) => {

        this.gmc_section2_Arr = result.data;
        
        this.below25_sec2 = 'below25' in result.data.tableData; 
        this.comp_0_sec2 = 'p25to50' in result.data.tableData;
        this.comp_1_sec2 = 'p50to75' in result.data.tableData;
        this.comp_2_sec2 = 'p75to100' in result.data.tableData;        
           
        this.getgmc_Section2_chart();

        // this.percentialambulance25_sec2 = result.data.graphData.percentialcdelivery25;
        // this.percentialpreposthospt25_sec2 = result.data.graphData.percentialndelivery25;

        // // console.log(result.data.graphData)
        
        // this.gmc_sec2_chart(this.percentialambulance25_sec2);
        // this.gmc_sec2_chart2(this.percentialpreposthospt25_sec2);

      }, (error) => {
          // console.log(result);
    });

  }      

  getgmc_Section2_chart(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
      "percentile": this.value2,
    };

    this.dataservice.getgmc_Section2_chart(postdata)
      .subscribe((result) => {

        this.show = false;
       
        this.percentialambulance25_sec2 = result.data.graphData.percentialcdelivery;
        this.percentialpreposthospt25_sec2 = result.data.graphData.percentialndelivery;

        this.gmc_sec2_chart(this.percentialambulance25_sec2);
        this.gmc_sec2_chart2(this.percentialpreposthospt25_sec2);       

      }, (error) => {
          // console.log(result);
    });

  }

  /**************** End ***************/

  /*********** GMC Section 2 Grpahs **********/
  gmc_sec2_chart(percentialambulance25_sec2: any) {
    let gmc_sec2_chart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential C Delivery ' + this.value2,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialambulance25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {             
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {          
        },
      },
      
      series: [
        {
          name: 'Percential C Delivery '  + this.value2,
          type: 'column',
          data: percentialambulance25_sec2,
          
        }
      ],
    });
    this.gmc_graph1 = gmc_sec2_chart;    
    gmc_sec2_chart.ref;
  }

  gmc_sec2_chart2(percentialpreposthospt25_sec2: any) {
    let gmc_sec2_chart2 = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential N Delivery '  + this.value2,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialambulance25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {             
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {          
        },
      },
      
      series: [
        {
          name: 'Percential N Delivery '  + this.value2,
          type: 'column',
          data: percentialpreposthospt25_sec2,
          
        }
      ],
    });
    this.gmc_graph2 = gmc_sec2_chart2;    
    gmc_sec2_chart2.ref;
  }

  /*********** GMC Section 3 API **********/
  getgmc_Section3(){    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
    };

    this.dataservice.getgmc_Section3(postdata)
      .subscribe((result) => {

        this.gmc_section3_Arr = result.data;
        
        this.below25_sec3 = 'below25' in result.data.tableData; 
        this.comp_0_sec3 = 'p25to50' in result.data.tableData;
        this.comp_1_sec3 = 'p50to75' in result.data.tableData;
        this.comp_2_sec3 = 'p75to100' in result.data.tableData;        
               
        // this.percentialambulance25_sec3 = result.data.graphData.percentialicuroom25;
        // this.percentialpreposthospt25_sec3 = result.data.graphData.percentialnormalroom25;
        
        // // console.log(result.data.graphData)

        // this.gmc_sec3_chart(this.percentialambulance25_sec3);
        // this.gmc_sec3_chart2(this.percentialpreposthospt25_sec3);

        this.getgmc_Section3_chart();

      }, (error) => {
          // console.log(result);
    });

  }      

  getgmc_Section3_chart(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
      "percentile": this.value3,
    };

    this.dataservice.getgmc_Section3_chart(postdata)
      .subscribe((result) => {

        this.show = false;
       
        this.percentialambulance25_sec3 = result.data.graphData.percentialicuroom;
        this.percentialpreposthospt25_sec3 = result.data.graphData.percentialnormalroom;

        this.gmc_sec3_chart(this.percentialambulance25_sec3);
        this.gmc_sec3_chart2(this.percentialpreposthospt25_sec3);       

      }, (error) => {
          // console.log(result);
    });

  }

  /**************** End ***************/

  /*********** GMC Section 2 Grpahs **********/
  gmc_sec3_chart(percentialambulance25_sec3: any) {
    let gmc_sec3_chart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential ICU Room ' + this.value3,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialambulance25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {             
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {          
        },
      },
      
      series: [
        {
          name: 'Percential ICU Room ' + this.value3,
          type: 'column',
          data: percentialambulance25_sec3,
          
        }
      ],
    });
    this.gmc_graph3 = gmc_sec3_chart;    
    gmc_sec3_chart.ref;
  }

  gmc_sec3_chart2(percentialpreposthospt25_sec3: any) {
    let gmc_sec3_chart2 = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Percential Normal Room ' + this.value3,
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // categories: ['percentialambulance25'],
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {             
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {          
        },
      },
      
      series: [
        {
          name: 'Percential Normal Room ' + this.value3,
          type: 'column',
          data: percentialpreposthospt25_sec3,
          
        }
      ],
    });
    this.gmc_graph4 = gmc_sec3_chart2;    
    gmc_sec3_chart2.ref;
  }

  /*********** GMC Section 3 API **********/
  getgmc_Section4(){    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
    };

    this.dataservice.getgmc_Section4(postdata)
      .subscribe((result) => {

        this.gmc_section4_Arr = result.data;
        
        this.below25_sec4 = 'below25' in result.data.tableData; 
        this.comp_0_sec4 = 'p25to50' in result.data.tableData;
        this.comp_1_sec4 = 'p50to75' in result.data.tableData;
        this.comp_2_sec4 = 'p75to100' in result.data.tableData;        
               
        this.percentialambulance25_sec4 = result.data.graphData.percentialicuroom25;
        this.percentialpreposthospt25_sec4 = result.data.graphData.percentialnormalroom25;

        this.getgmc_Section4_chart();
                
      }, (error) => {
          // console.log(result);
    });

  }     
  
  getgmc_Section4_chart(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
      "percentile": this.value3,
    };

    this.dataservice.getgmc_Section4_chart(postdata)
      .subscribe((result) => {

        this.show = false;
       
        this.setgmc_Section4_chart = result.data.graphData;

        for(var i=0; i < this.setgmc_Section4_chart.length; i++){
          this.setgmc_Section4_title.push(this.setgmc_Section4_chart[i]['title']);
          this.setgmc_Section4_Covered.push(this.setgmc_Section4_chart[i]['Covered']);
          this.setgmc_Section4_NotCovered.push(this.setgmc_Section4_chart[i]['Not_Covered']);
        }

        this.gmc_sec4_chart(this.setgmc_Section4_title, this.setgmc_Section4_Covered, this.setgmc_Section4_NotCovered);        

      }, (error) => {
          // console.log(result);
    });

  }

  gmc_sec4_chart(setgmc_Section4_title: any, setgmc_Section4_Covered: any, setgmc_Section4_NotCovered: any) {
    
    console.log(setgmc_Section4_title);

    let gmc_sec4_chart = new Chart({
      chart: {
        type: 'bar',
        height: 600,
        events: {
          load: function() {
              let categories:any = this.xAxis[0].tickPositions,
                  plotHeight = categories.length * 50,
                  chartHeight = plotHeight + (this.chartHeight - this.plotHeight);
  
              if (chartHeight > this.chartHeight) {
                  this.setSize(null, chartHeight, false)
              }
          }
      },
      },
      title: {
        text: '',
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.setgmc_Section4_title,
        tickInterval: 1,
        labels: {
            enabled: true,
        },        
      },
      yAxis: {    
        max: 100,      
        maxPadding: 0,        
        // title: {
        //   text: 'Medical - Peer',
        //   style: {
        //     fontFamily: 'Poppins'
        //   }
        // },
        gridLineWidth: 0,
        labels: {          
        },
      },      
      plotOptions: {        
        series: {
            stacking: 'normal',            
            dataLabels: {
                enabled: true
            },
        }
      },
      series: [                
        {
          name: 'Not Coverred',
          type: 'column',
          data: this.setgmc_Section4_NotCovered,    
          color: '#9E9E9E',      
          pointWidth: 25,
        },
        {
          name: 'Coverred',
          type: 'column',
          data: this.setgmc_Section4_Covered, 
          color: '#4CAF50',               
          pointWidth: 25
        },
      ],
    });
    this.other_graph = gmc_sec4_chart;    
    gmc_sec4_chart.ref;
  }

  /**************** End ***************/

  exportcsv(): void {
    let csv='';
    let table:any = document.getElementById("reportstable");
    let tr=table.children[0].children[0];
    for(let i=0;i<tr.children.length;i++)
    {
        csv+=tr.children[i].innerText+",";
    }
    csv=csv.substring(0,csv.length-1)+"\n";
    let tbody=table.children[1];
    for(let i=0;i<tbody.children.length;i++)
    {
        for(let j=0;j<tbody.children[i].children.length;j++)
        {
            csv+=tbody.children[i].children[j].innerText+",";
        }
        csv=csv.substring(0,csv.length-1)+"\n";
    }
    csv=csv.substring(0,csv.length-1)+"\n";
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Benchmarking Reports.csv';
        hiddenElement.click();
  }

  openPDF(): void {
    let DATA: any = document.getElementById('chart');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Reports.pdf');
    });
  }      

  getval(){    
    this.getgmc_Section1_chart();
  }

  getval2(){    
    this.getgmc_Section2_chart();
  }

  getval3(){    
    this.getgmc_Section3_chart();
  }

  /**************** GTL ****************/
  getgtl(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val(),
    };

    this.dataservice.getgtl(postdata)
      .subscribe((result) => {

        this.gtl_array = result.data;
        
        // this.below25_gtl = 'below25' in result.data.tableData; 
        this.comp_0_gtl = 'p25' in result.data.tableData;
        this.comp_1_gtl = 'p50' in result.data.tableData;
        this.comp_2_gtl = 'p75' in result.data.tableData;    
        
        this.gtl_IBP_MOS = result.data.graphData.multiple_of_salary[0];
        $('.gtl_IBP_MOS').css('width', this.gtl_IBP_MOS + '%');
        
        this.gtl_IBP_GB = result.data.graphData.graded_benefits[0];
        $('.gtl_IBP_GB').css('width', this.gtl_IBP_GB + '%');

        this.gtl_IBP_FSA = result.data.graphData.fix_sum_insured[0];
        $('.gtl_IBP_FSA').css('width', this.gtl_IBP_FSA + '%');

        this.gtl_array_chart();             

      }, (error) => {
          // console.log(result);
    });

  }

  gtl_array_chart(){          
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),        
      "client_id":localStorage.getItem('client_id'),
      "industry":$('#industry').val(),
      "headcount":$('#headcount').val(),
      "mnc_type":$('#mnc_type').val()
    };    
    
    this.dataservice.gtl_array_chart(postdata)
      .subscribe((result) => {     
      
        console.log(result);

        this.glt_graph_key = result.data.graphData.kay;
        this.glt_graph_value = result.data.graphData.val;
       
        this.gtl_barchart(this.glt_graph_key, this.glt_graph_value);
       
      }, (error) => {
          // console.log(result);
    });        

  }

  gtl_barchart(glt_graph_key: any, glt_graph_value: any) {    

    let gtl_barchart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Riders',
        style: {
          fontFamily: 'Poppins',
          fontSize: '14px',
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: glt_graph_key,
        tickInterval: 1,
        labels: {
            enabled: true,
        }
      },
      yAxis: {             
        title: {
          // text: 'percentialambulance25',
          style: {
            fontFamily: 'Poppins'
          }
        },
        gridLineWidth: 0,
        labels: {          
        },
      },
      
      series: [
        {
          name: 'Riders',
          type: 'column',
          data: glt_graph_value,
          
        }
      ],
    });
    this.gtl_bar = gtl_barchart;    
    gtl_barchart.ref;
  }
  /**************************************/

}
