<div class="back">
  <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

  <div>
    <a routerLink="/home"><img src="../../../assets/img/login/logo.png" class="cmplogo" alt="geometric_img"
        width="4%" style="float: left; margin: 20px; margin-left: 50px;" /></a>
  </div>

  <div class="row div-center">

    <div class="col-sm-12 col-lg-7 col-md-7 hidemd">
      <div>
        <img src="../../../assets/img/login/geometric_img.png" class="loginh4 loginh4img" alt="geometric_img"
          width="60%" style="display: block; margin: auto;" />
      </div>
    </div>

    <div class="col-sm-12 col-lg-5 col-md-5 logindiv">

      <h4 class="loginheading loginh4">Sign Up</h4>

      <form [formGroup]="registerForm" class="mt-3">
        <div class="mb-2">
          <label for="username" class="form-label">Username</label>
          <input type="email" class="form-control" id="username" formControlName="username"
            placeholder="Email / Username">
        </div>
        <div class="mb-2">
          <label for="pwd" class="form-label">Password</label>
          <input type="password" class="form-control" id="pwd" formControlName="pwd" placeholder="Password">
        </div>
        <div class="mb-2">
          <label for="pwd_confirm" class="form-label">Confirm Password</label>
          <input type="password" class="form-control" id="pwd_confirm" (keyup)="checkpwd()"
            placeholder="Confirm Password">
        </div>
        <div class="mt-3">
          <a style="text-decoration: none;">
            <button type="submit" id="registerbtn" (click)="register()" class="btn btn-primary w-100 loginbutton">
              Create Account
            </button>
          </a>
        </div>
        <div class="mt-2 mb-3" style="width: 100%; height: 14px; border-bottom: 1px solid #ced4da; text-align: center">
          <span style="background-color: #ffffff73; padding: 0 10px; border-radius: 20px;">
            Or
          </span>
        </div>
        <!-- <div class="my-1 w-100">
          <a class="btn btn-outline-dark w-100" href="/users/googleauth" role="button"
            style="font-size:14px; text-transform:none; border: solid 1px #ced4da; padding: 6px 20px 6px 20px; border: 1px solid #AEAAAE; border-radius: 4px;">
            <img width="20px" style="margin-bottom:3px; margin-right:5px;" alt="Google sign-in"
              src="../../../assets/img/common icons/gogglelogo.png" />
            Sign In with Google
          </a>
        </div> -->
        <div class="my-3 w-100 form-label" style="text-align: center;"> Already have an Account? <a
            routerLink="/login">Login</a>
        </div>
      </form>

    </div>

  </div>

  <!---- Thank you Popup ---->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Thank You !</h4>
        </div>
        <div class="modal-body">
          Your are registered. We have assigned you plan 'BASIC' for now you can upgrade that afterwards.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
        </div>
      </div>
    </div>
  </div>

</div>