<div class="back">
  <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

  <div>    
    <!-- <a routerLink="/home"><img src="../../../assets/img/login/logo.png" class="cmplogo" alt="geometric_img" width="4%" style="float: left; margin: 20px; margin-left: 50px;" /></a> -->
    <span style="font-weight: 600;
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 20px;
    color: #9F2CC9;">Welcome to {{projectTitle}}…</span>
  </div>

  <div class="row div-center">
    
    <div class="col-sm-12 col-lg-7 col-md-7 hidemd">
      <div>
        <img src="../../../assets/img/login/geometric_img.png" class="loginh4 loginh4img" alt="geometric_img" width="60%" style="display: block; margin: auto;" />
        <img src="../../../assets/img/login/forgotpasswordimg.png" class="forgoth4 forgoth4img" alt="forgotpasswordimg" width="60%" style="display: none; margin: auto;" />
        <img src="../../../assets/img/login/passimg.png" class="reseth4" alt="otpimg" width="60%" style="display: none; margin: auto;" />
        <!-- <img src="../../../assets/img/login/passimg.png" class="forgoth4 passimg4" alt="passimg" width="60%" style="display: none; margin: auto;" /> -->
      </div>
    </div>

    <div class="col-sm-12 col-lg-5 col-md-5 logindiv">
        
        <h4 class="loginheading loginh4">Login</h4>
        <h4 class="loginheading forgoth4" style="display: none;">Forgot Password ?</h4>
        <h4 class="loginheading reseth4" style="display: none;">Reset Password</h4>

        <form [formGroup]="loginForm" class="mt-3">
          
          <div id="logindiv">
            <div class="mb-3">
              <label for="username" class="form-label">Email ID/Employee Code/Mobile No.</label>
              <input type="email" class="form-control" id="username" formControlName="username" placeholder="Enter email ID" >
            </div>
            <div class="mb-2">
              <label for="pwd" class="form-label">Password</label>
              <input type="{{ hidePassword ? 'password' : 'text' }}" class="form-control" id="pwd" formControlName="pwd" placeholder="Your Password">
              <img src="../../../assets/img/common icons/eye.png" alt="Toggle Password Visibility" class="eye-icon" (click)="togglePassword()" style="height: 20px; position: relative; float: right; left: -15px; margin-top: -27px; z-index: 2;">
          </div>                 
            <div class="mb-2 row" style="display: flex;">
                <div class="col-sm-6">
                    <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" name="exampleCheck1">
                    <label class="form-check-label form-label" for="exampleCheck1"> &nbsp; Remember me </label> -->
                </div>
                <div class="col-sm-6 form-label" style="text-align: right;"><span style="color:#4E0099; cursor: pointer;" (click)="openforgotpwd()"><u>Forgot Password? </u></span></div>
            </div>
            <div class="mt-4">
              <a style="text-decoration: none;">
                <button type="submit" (click)="login()" class="btn btn-primary w-100 loginbutton">
                  Login
                </button>
              </a>
            </div>
            <!-- <div class="mt-2 mb-3 orline">
                <span style="background-color: #ffffff73; padding: 0 10px; border-radius: 20px;">
                    Or
                </span>
            </div> -->
            <!-- <div class="my-1 w-100">
                <a class="btn btn-outline-dark w-100" href="/users/googleauth" role="button"
                    style="font-size:14px; text-transform:none; border: solid 1px #ced4da; padding: 6px 20px 6px 20px; border: 1px solid #AEAAAE; border-radius: 4px;">
                    <img width="20px" style="margin-bottom:3px; margin-right:5px;" alt="Google sign-in"
                        src="../../../assets/img/common icons/gogglelogo.png" />
                    Sign In with Google
                </a>
            </div> -->
            <!-- <div class="my-3 w-100 form-label" style="text-align: center;"> Don't you have an Account? <a routerLink="/register">Sign Up</a>
            </div> -->
          </div> 
          
          <!------ Forgot pwd ----->
          <div id="forgotpwd" style="display: none;">
            <p id="paramsg">
              Don’t worry it happens. Please enter the address associated with your account.
            </p>

            <div class="mb-2" id="forgotpwdDiv" style="display: none;">
              <label for="forgotpwdemail" class="form-label">Email</label>
              <input type="email" class="form-control" id="forgotpwdemail" formControlName="forgotpwdemail" placeholder="Enter email ID">
              <small style="font-size: 12px;">Temporary password will be sent to above email, login with username and temp password.</small>
            </div>

            <div class="mb-2" id="temppwdDiv" style="display: none;">
              <label for="forgotpwd" class="form-label">Temporary Password</label>
              <input type="password" class="form-control" id="temppwdDiv" formControlName="temppwdDiv" placeholder="Enter Temporary password">
            </div>

            <div class="mb-2" id="newpwdDiv" style="display: none;">
              <label for="forgotpwd" class="form-label">New Password</label>
              <input type="password" class="form-control" id="newpwdDiv" formControlName="newpwdDiv" placeholder="Enter new password" (keyup)="checkPassword($event)">
            </div>

            <div class="mb-3" id="confirmpwd" style="display: none;">
              <label for="forgotpwd" class="form-label">Confirm Password</label>
              <input type="password" class="form-control" id="confirmpwd" (keyup)="checkpwd(); checkPassword($event)" formControlName="confirmpwd" placeholder="Confirm password">
            </div>

            <a style="text-decoration: none;" id="submitemail">
              <button type="submit" (click)="submitemail()" class="btn btn-primary w-100 loginbutton">
                Login
              </button>
            </a>

            <a style="text-decoration: none;" id="submitpwd" style="display:none">
              <button type="submit" id="submitfinal" (click)="submitpwd()" class="btn btn-primary w-100 loginbutton">
                Submit
              </button>
            </a>

            <div class="mt-3">
              <button type="button" (click)="backtologin()" class="btn btn-primary w-100 loginbutton">
                Back to Sign Up
              </button>
            </div>              

          </div>

        </form>  

        <div id="validmsg" class="red">{{ validmsg }}</div>
        <div id="password_msg" class="mt-2" style="display: none; color: red; position: fixed; font-size: 13px;">
          Password must be at least 8 digit, <br>
          Must have uppercase, <br>
          Must have lowercase, <br>
          Must have special character, <br>
          Must have number
        </div>

    </div>

  </div>

  <!---- Incorrect Password ---->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_DoyouwantRegister}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Oops!!</h4>
            </div>                
            <div class="modal-body">                                
                {{ validmsg }}
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-success" (click)="closePopup('yes')"> Yes</button> -->
                <button type="button" class="btn btn-danger" (click)="closePopup('no')"> Close</button>
            </div>
        </div>
    </div>
  </div>

  <!---- Thank you Popup ---->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Success !</h4>
            </div>                
            <div class="modal-body">                                
                Password updated.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="closePopup_forgotpwd()"> Close</button>
            </div>
        </div>
    </div>
  </div>

</div>