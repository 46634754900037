import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-faq',
  templateUrl: './employee-faq.component.html',
  styleUrls: ['./employee-faq.component.css']
})
export class EmployeeFaqComponent implements OnInit {

  faqs = [
    {
      question: 'What is your return policy?',
      answer: 'Our return policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately, we can’t offer you a refund or exchange.',
      active: false
    },
    {
      question: 'How do I track my order?',
      answer: 'You can track your order by using the tracking number provided in your confirmation email.',
      active: false
    },
    {
      question: 'What should I do if my item is damaged?',
      answer: 'If your item arrives damaged, please contact our customer support team within 48 hours of receiving the package. We will arrange for a replacement or refund. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget risus augue. In hac habitasse platea dictumst. Duis eget tortor lectus. Donec ullamcorper ipsum sit amet molestie vehicula. Ut eu arcu non mi accumsan dictum. Fusce auctor velit vitae imperdiet dictum. Nullam commodo mi non metus luctus, quis congue odio vulputate. Duis gravida lacus eget aliquet ultricies. Sed elementum dictum volutpat. In facilisis bibendum magna, vel accumsan sapien mattis id. If your item arrives damaged, please contact our customer support team within 48 hours of receiving the package. We will arrange for a replacement or refund. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget risus augue. In hac habitasse platea dictumst. Duis eget tortor lectus. Donec ullamcorper ipsum sit amet molestie vehicula. Ut eu arcu non mi accumsan dictum. Fusce auctor velit vitae imperdiet dictum. Nullam commodo mi non metus luctus, quis congue odio vulputate. Duis gravida lacus eget aliquet ultricies. Sed elementum dictum volutpat. In facilisis bibendum magna, vel accumsan sapien mattis id',
      active: false
    },
    {
      question: 'Do you ship internationally?',
      answer: 'Yes, we ship worldwide. Shipping costs will apply, and will be added at checkout.',
      active: false
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept Visa, Mastercard, American Express, and PayPal.',
      active: false
    },
    {
      question: 'How long does shipping take?',
      answer: 'Shipping times vary depending on your location. Typically, domestic orders arrive within 3-5 business days, while international orders may take 7-14 business days.',
      active: false
    },
    {
      question: 'Can I cancel or change my order?',
      answer: 'Once an order has been placed, it cannot be canceled or changed. However, you may be able to return the item(s) for a refund once you receive them.',
      active: false
    },
    {
      question: 'Do you offer wholesale pricing?',
      answer: 'Yes, we offer wholesale pricing for bulk orders. Please contact our sales team for more information.',
      active: false
    }    
  ];

  constructor() { }

  ngOnInit(): void {
  }

  toggleAnswer(faq: any) {
    faq.active = !faq.active;
  }

}
