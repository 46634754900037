import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SurveyAuthService {

  AUTH_API = environment.apiURL;
  
  constructor(private http: HttpClient) { }

  login(username: string, pwd: string): Observable<any> {
    return this.http.post(this.AUTH_API + 'auth/login', {
      username,
      pwd
    }, httpOptions);
  }
  
  getuserdetails(postdataObj: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'get-user-details', 
    postdataObj
    , httpOptions);
  }
  
  emailSendTempPassword(postdataObj: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'auth/email-send-temp-password', 
    postdataObj
    , httpOptions);
  }

  sendnewPwd(postdataObj: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'auth/forgot-update-password', 
    postdataObj
    , httpOptions);
  }

}