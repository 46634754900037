import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-claim-view',
  templateUrl: './claim-view.component.html',
  styleUrls: ['./claim-view.component.css']
})
export class ClaimViewComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  /***** Policy Details */
  claimId: any;
  claimStatus: any;
  claimSubStatus: any;
  policyType: any;
  policyNumber: any;
  insuranceCompany: any;
  policyStartDate: any;
  policyEndDate: any;
  employeeName: any;
  employeeEmail: any;
  BalanceSumInsured: any;

  /******** Claim Tracker Deatails */
  Deficiency_Reason:any;
  Reject_date:any;
  Reject_Reason_short:any;

  /********** Claim Details */
  Relation:any;
  TPA_name:any;
  ClaimType:any;
  ClaimRegisterDate:any;
  ClaimedAmount:any;
  HospitalName:any;
  HospitalAddress:any;
  hospital_city:any;
  hospital_state:any;
  HospPincode:any;
  DateOfAdmission:any;
  DateOfDischarge:any;

  /******** Expense Details */
  ClaimApprovedAmount:any;
  Claim_settled_amount:any;
  SurgeryChargesClaimed:any;
  claim_Incurred_amount:any;

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.ClaimView();

  }

  ClaimView(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id":localStorage.getItem('policy_id'),
      "cid":localStorage.getItem('claim_id'),
    };

    this.service.InfoSingle(postdata)
    .subscribe((result: any) => {

      /****************** POLICY Deatils *******************************************/
      
      this.claimId = result.data[0].claim_data[0]['insurance_claim_id'];
      this.claimStatus = result.data[0].claim_data[0]['ClaimStatus'];
      this.claimSubStatus = result.data[0].claim_data[0]['Claim_sub_Status'];
      this.policyType = result.data[0].claim_data[0]['Policy_type'];
      this.policyNumber = result.data[0].claim_data[0]['PolicyNumber'];
      this.insuranceCompany = result.data[0].claim_data[0]['InsuranceCompName'];
      this.policyStartDate = result.data[0].claim_data[0]['PolicyStartDate'];
      this.policyEndDate = result.data[0].claim_data[0]['PolicyEndDate'];
      this.employeeName = result.data[0].claim_data[0]['EmployeeName'];
      this.employeeEmail = result.data[0].claim_data[0]['Member_Email'];
      this.BalanceSumInsured = result.data[0].claim_data[0]['BalanceSumInsured'];

      /********************** Claim Tracker Deatils *********************************/
      this.Deficiency_Reason = result.data[0].claim_data[0]['Deficiency_Reason'];
      this.Reject_date = result.data[0].claim_data[0]['Reject_date'];
      this.Reject_Reason_short = result.data[0].claim_data[0]['Reject_Reason_short'];

      /*********************** Claim Deatails *************************************/
      this.Relation = result.data[0].claim_data[0]['Relation'];
      this.TPA_name = result.data[0].claim_data[0]['TPA_name'];
      this.ClaimType = result.data[0].claim_data[0]['ClaimType'];
      this.ClaimRegisterDate = result.data[0].claim_data[0]['ClaimRegisterDate'];
      this.ClaimedAmount = result.data[0].claim_data[0]['ClaimedAmount'];
      this.HospitalName = result.data[0].claim_data[0]['HospitalName'];
      this.HospitalAddress = result.data[0].claim_data[0]['HospitalAddress'];
      this.hospital_city = result.data[0].claim_data[0]['hospital_city'];
      this.hospital_state = result.data[0].claim_data[0]['hospital_state'];
      this.HospPincode = result.data[0].claim_data[0]['HospPincode'];
      this.DateOfAdmission = result.data[0].claim_data[0]['DateOfAdmission'];
      this.DateOfDischarge = result.data[0].claim_data[0]['DateOfDischarge'];

    }, (error) => {          
  });
  }

  backToClaims(){
    this.route.navigate(['overall-claims']);
  }
}
