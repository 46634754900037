<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Download E-Card</h3>

            <div class="row mt-4">

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_id">Client <span class="redstar">*</span></label>
                        <ng-select id="client_id"  (change)="getPolicy($event)"
                                [items]="InfoArrayClient"
                                bindLabel="name"
                                bindValue="id"
                                [placeholder]="'Select Client'">
                            </ng-select>         
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="policy_id">Policy <span class="redstar">*</span></label>
                        <select class="form-select" id="policy_id" (change)="getEmployee()">
                            <option value="">Select Policy</option>
                            <option *ngFor="let policy of policyArray" [value]="policy.id">{{ policy.policy_name }}</option>
                        </select>          
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="employee_id">Employee <span class="redstar">*</span></label>
                        <select class="form-select" id="employee_id" (change)="getMembers()">
                            <option value="">Select Employee</option>
                            <option *ngFor="let employee of EmployeeArray" [value]="employee.empid">{{employee.empcode}} - {{employee.first_name}} {{employee.last_name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3 memberDiv" style="display: none;  background: white; padding: 10px; border-radius: 10px; margin-top: 20px;">
                   <p> <b>No E-cards Available</b> </p>
                </div>

                <div class="col-sm-3"  *ngIf="MemberArray.length > 0">
                    <div class="form-group">
                        <label class="bs-form-label" for="member_id">Member</label>
                        <select class="form-select" id="member_id"(change)="getMemberData($event)">
                            <option value="">Select Member</option>
                            <option *ngFor="let member of MemberArray" [value]="member.mid">{{ member.first_name }} {{ member.last_name }}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="memberDiv" style="display: none;  background: white; padding: 10px; border-radius: 10px; margin-top: 20px;">
                <label style="font-size: 13px"> <b>Enrollment is in progress or Service currently not available, kindly contact your service executive</b></label>
            </div>

            <div class="row">
                    <div class="col-sm-4 mt-3" *ngFor="let member of MemberArray; let i = index">
                        <div class="contact-card" *ngIf="selectedMemberId === undefined || selectedMemberId === '' || selectedMemberId === null">
                            <div class="card-header">
                                <div style="width: 50%; display: flex;">
                                    <img class="userimg" src="{{member['image_url']}}" alt="User">
                                    <div class="username">{{member['first_name']}} {{member['last_name']}}</div>
                                </div>  
                                <div style="width: 50%;">
                                    <a href="{{member['ecard_url']}}" target="_blank" role="button" class="btn ebtn">Download E-Card</a>
                                </div>                                                     
                            </div>
                            <div class="card-content">
                                <ul>
                                    <li><strong>Relation:</strong> {{member['relation']}}</li>
                                    <li><strong>Member Id:</strong> {{member['tpa_member_id']}}</li>
                                    <li><strong>Policy Number:</strong> {{member['policy_number']}}</li>
                                    <li><strong>Cover Date:</strong> {{member['policy_start_date']}} to {{member['policy_end_date']}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                

                <span *ngIf="selectedMemberId !== ''">
                    <div class="col-sm-4 mt-3" *ngFor="let member of InfoArray; let i = index">
                        <div class="contact-card">
                            <div class="card-header">
                                <div style="width: 50%; display: flex;">
                                    <img class="userimg" src="{{member['image_url']}}" alt="User">
                                    <div class="username">{{member['first_name']}} {{member['last_name']}}</div>
                                </div>  
                                <div style="width: 50%;">
                                    <a href="{{member['ecard_url']}}" target="_blank" role="button" class="btn ebtn">Download E-Card</a>
                                </div>                                                     
                            </div>
                            <div class="card-content">
                                <ul>
                                    <li><strong>Relation:</strong> {{member['relation']}}</li>
                                    <li><strong>Member Id:</strong> {{member['tpa_member_id']}}</li>
                                    <li><strong>Policy Number:</strong> {{member['policy_number']}}</li>
                                    <li><strong>Cover Date:</strong> {{member['policy_start_date']}} to {{member['policy_end_date']}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </span>
            </div> 

        </div>
        <app-footer></app-footer>
    </div>
</div>
