import {AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

interface PolicyType {
  name: string;
  sub_type_id: number;
}

@Component({
  selector: 'app-ecard-file-upload',
  templateUrl: './ecard-file-upload.component.html',
  styleUrls: ['./ecard-file-upload.component.css']
})
export class EcardFileUploadComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  i: number;

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList =   event.dataTransfer.files;
      console.log(files);
      for (let i = 0; i < files.length; i++) {
        this.document_file.push(files[i]);
        }
    }
  }
  error: string;
  dragAreaClass: string;

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  
  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  public DeleteInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayClient: any = [];   
  policy_type:  PolicyType[] = [];   
  policynumbers: any = [];   

  document_file: any = [];
  downloadbuttontooltip:any;
  samplefile: any;
  Policyuploaddoclist:any = [];
  addflag: any = 0;
  imgpath: any;

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Broker E-Card File Upload';

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  displayStyleDelete = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click()

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup(){

    this.dragAreaClass = "dragarea";
    this.getcompanyDetails();
    this.assignrights();

    this.basicInfo = this.fb.group({
      client: new FormControl(null, Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_name: new FormControl('', Validators.required),
      document_file: new FormControl(''),
    });

    this.EditInfo = this.fb.group({      
      wellness_partner_edit: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      document_file: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getsamplefile(){


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.basicInfo.controls['policy_name'].value,
      "policy_type_id":this.basicInfo.controls['policy_type'].value,
      "employer_id":this.basicInfo.controls['client'].value,
    }
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.samplefile=result.data['ecard_sample'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.InfoArrayClient = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

  getpolicytype(){

    setTimeout(()=>{
      $('#policy_name').val('');
    },1000);

    // this.getPolicyuploaddoclist();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['client'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data.filter((PolicyType: PolicyType) => PolicyType.sub_type_id === 1);

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['client'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
          // console.log(result);
    });
  }
  

  ondownload(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    // if(val == "policyfeatures"){
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', 'Sample_Policy__Data.xls');
    // }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // uploadfile(event: any){
    
  //   if(event.target.files.length > 0){
  //     console.log(event.target.files[0]);
  //     this.document_file.push(event.target.files[0]);
  //     $("#features-file-upload_document_file").empty();
  //     $('#features-file-upload_document_file').html(event.target.files[0]['name']);
  //     $('#features-file-upload_document_file').css('text-align', 'left');

  //     var iSize = Math.round(event.target.files[0]['size'] / 1024);

  //     iSize = (Math.round(iSize * 100) / 100);

  //     $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


  //   }
  // }

  uploadfile(event: any) {
    if (event.target.files.length > 0) {

      $("#features-file-upload_document_file").empty();
  
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        console.log(file);
        this.document_file.push(file);
  
        // Calculate the file size in KB
        let iSize = Math.round(file.size / 1024);
        iSize = (Math.round(iSize * 100) / 100);
  
      }

       // Append file information to the HTML container
       $('#features-file-upload_document_file').append(
        `<div style="margin-bottom: 5px;">
        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp;
        <span id="selectFile_Logo"
            style="color:#4E0099; font-weight: 600; text-align:center">Add
            File</span>          
        </div>`
      );
  
      $('#features-file-upload_document_file').css('text-align', 'center');
    }
  }

  deleteFile(index: number) {
    if (index >= 0 && index < this.document_file.length) {
        this.document_file.splice(index, 1);
    }
}

  onSubmitAdd(){

    this.basicInfoSubmitted = true;
    if (this.basicInfoSubmitted && this.basicInfo.valid) {

      // this.show = true;

      console.log(this.document_file);

      if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
        $('#document_file_error').css('display', 'block');
        $('#features-file-upload_document_file').css('border', '1px solid red');

        return;
      }else{

        $('#document_file_error').css('display', 'none');
        $('#features-file-upload_document_file').css('border', '1px solid #dee2e6');
       }
  
     
       var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "employer_id":this.basicInfo.controls['client'].value,
        "sub_type_id":this.basicInfo.controls['policy_type'].value,
        "policy_id":this.basicInfo.controls['policy_name'].value,
        "ecard_file" : this.document_file
      };
      console.log(postdata);
      console.log(this.document_file);

      this.show = true;
      this.service.uploadsamplefile(postdata)
        .subscribe((result) => {
          if(result.status){
            this.show = false;            

            this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            // setTimeout(() => {
            //   window.location.reload();
            // }, 3000);
            
          }
        }, (error) => {
            // console.log(error);
            this.toastService.showFailed('Failed', 'There was some error');

      });
      
    }    

  }

  closePopup() {    
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
    this.displayStyleDelete = "none";  
  }

  opengetEcardDetails(){
   this.route.navigate(['/ecard-details']);

  }

  getEcardDetails(){    

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":$('#client_id').val(),

    }

    this.service.getPolicyuploaddoclist(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.Policyuploaddoclist = result.data; 
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });
          
      }, (error) => {
          // console.log(result);
          this.Policyuploaddoclist = [];
    });
  }



  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "employer_id":$('#client').val(),

    }

    this.service.getPolicyuploaddoclist(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 

          setTimeout(() => {
    
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.clear();
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });

            
          }, 1000);

         
        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.error_filename,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });    

  }

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  } 

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          


    this.addflag = 1;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['wellness_partner_edit'].setValue(result.data[0]['wellness_name']);
        this.EditInfo.controls['url'].setValue(result.data[0]['wellness_url']);
        this.imgpath = result.data[0]['wellness_logo'];
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    // console.log(id);
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "broker_id":localStorage.getItem('broker_id'),
        
        "wellness_name":this.EditInfo.controls['wellness_partner_edit'].value,
        "wellness_url":this.EditInfo.controls['url'].value,
        "image_data" : this.document_file
      };

      console.log(postdata);

      this.show = false;

      this.service.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            this.toastService.showSuccess('Success', 'Details Updated Successfully!');

          
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'none');  
          // $('#errormsg').css('display', 'block'); 
          this.toastService.showFailed('Failed', 'There was some error');
       
      });

    }

  }


  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');   
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');   
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupDelete(res: any) {

    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": this.DeleteInfo.controls['delete_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
    };

    this.service.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deleted Successfully!');

          
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');
      
    });

  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }
}
