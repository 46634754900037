import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SurveyGTLPolicyDetailsService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  policyDetailsCreate_first(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();
    filedata.append("benefit_id",data.benefit_id);

    if(data.client_product_id == 0 && data.client_product_policy_id == 0){

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
          
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);
      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
         
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);      
        filedata.append("filename",data.upload_policydocument);      
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policyGTL/store';
      }else{
        val = 'client-policyGTL/policytemp-import';
      }

    }else{

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
        
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);
      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
         
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);      
        filedata.append("filename",data.upload_policydocument);      
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policyGTL/updateGTLstep1/' + data.client_product_policy_id;
      }else{
        val = 'client-policyGTL/policytemp-import';
      }

    }                        
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + val,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_second(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("benefit_id",data.benefit_id); 

    if(data.existing_policy_check == "Yes"){
      filedata.append("policy_date",data.policy_date);
      filedata.append("policy_expirydate",data.policy_expirydate);
      filedata.append("data_report_date",data.data_report_date);      
      filedata.append("policy_number",data.policy_number);
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("lives_covered",data.lives_covered);
      filedata.append("emp_atreport",data.emp_atreport);
      filedata.append("lives_atreport",data.lives_atreport);
    }else{
      filedata.append("how_soon_buy",data.how_soon_buy);      
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("choose_month",data.choose_month);      
    }
    

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGTL/updateGTLstep2/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_third(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);
    filedata.append("benefit_id",data.benefit_id);        
    
    //Sum Incured
    filedata.append("SI_type",data.SI_type),
    filedata.append("multiple_of_salary",data.multiple_of_salary),
    filedata.append("Define_flat_SI",data.Define_flat_SI),
    filedata.append("SI_Junior_management",data.SI_Junior_management),
    filedata.append("SI_Middle_management",data.SI_Middle_management),
    filedata.append("SI_Senior_management",data.SI_Senior_management),
    filedata.append("Graded_extra_fields",JSON.stringify(data.Graded_extra_fields));

    filedata.append("actively_at_work",data.actively_at_work),
    filedata.append("Waived_For_all_Employees",data.Waived_For_all_Employees),
    filedata.append("Only_For_Existing_Employees",data.Only_For_Existing_Employees),

    //Waiting
    filedata.append("employees_life_top_up_plan",data.employees_life_top_up_plan);
    // filedata.append("employees_life_top_up_plan_limit",data.employees_life_top_up_plan_limit);
    filedata.append("SI_type_topup",data.SI_type_topup),
    filedata.append("multiple_of_salary_topup",data.multiple_of_salary_topup),
    filedata.append("Define_flat_SI_topup",data.Define_flat_SI_topup),
    filedata.append("SI_Junior_management_topup",data.SI_Junior_management_topup),
    filedata.append("SI_Middle_management_topup",data.SI_Middle_management_topup),
    filedata.append("SI_Senior_management_topup",data.SI_Senior_management_topup),
    filedata.append("Graded_extra_fields_topup",JSON.stringify(data.Graded_extra_fields_topup));

    filedata.append("is_spouse_life_cover",data.is_spouse_life_cover);
    filedata.append("is_spouse_life_cover_limit",data.is_spouse_life_cover_limit);
    filedata.append("is_spouse_life_cover_limit_INR",data.is_spouse_life_cover_limit_INR);

    //OPD
    // filedata.append("Accidental_Death",data.Accidental_Death);
    // filedata.append("Accidental_Death_limit",data.out_patient_benefits_limit);
    // filedata.append("Permanent_Total_Disability",data.Permanent_Total_Disability);
    // filedata.append("Permanent_Total_Disability_limit",data.opd_dental_limit);
    // filedata.append("Permanent_Partial_Disability",data.Permanent_Partial_Disability);
    // filedata.append("Permanent_Partial_Disability_limit",data.opd_vision_limit);
    // filedata.append("Temporary_Total_disability_loss_of_wages",data.Temporary_Total_disability_loss_of_wages);
    // filedata.append("Temporary_Total_disability_loss_of_wages_limit",data.preventive_health_checkup_limit);    

    //Others   
    filedata.append("Terminal_illness",data.Terminal_illness);
    filedata.append("Terminal_illness_limit",data.Terminal_illness_limit);

    filedata.append("Accidental_death_benefit",data.Accidental_death_benefit);
    filedata.append("Accidental_death_benefit_limit",data.Accidental_death_benefit_limit);

    filedata.append("Accidental_permananent_Total_Disability",data.Accidental_permananent_Total_Disability);
    filedata.append("Accidental_permananent_Total_Disability_limit",data.Accidental_permananent_Total_Disability_limit);

    filedata.append("Accidental_permananent_Partial_Disability",data.Accidental_permananent_Partial_Disability);
    filedata.append("Accidental_permananent_Partial_Disability_limit",data.Accidental_permananent_Partial_Disability_limit);

    filedata.append("Accelerated_critical_illness",data.Accelerated_critical_illness);
    filedata.append("Accelerated_critical_illness_limit",data.Accelerated_critical_illness_limit);

    filedata.append("Additional_critical_illness",data.Additional_critical_illness);
    filedata.append("Additional_critical_illness_limit",data.Additional_critical_illness_limit);

    filedata.append("Sickness_disability_rider",data.Sickness_disability_rider);
    filedata.append("Sickness_disability_rider_limit",data.Sickness_disability_rider_limit);

    filedata.append("Total_permanent_Disability",data.Total_permanent_Disability);
    filedata.append("Total_permanent_Disability_limit",data.Total_permanent_Disability_limit);

    filedata.append("Total_partial_Disability",data.Total_partial_Disability);
    filedata.append("Total_partial_Disability_limit",data.Total_partial_Disability_limit);    

    filedata.append("Loss_of_Pay",data.Loss_of_Pay);
    filedata.append("Loss_of_Pay_limit",data.Loss_of_Pay_limit);    

    filedata.append("additional_benefits_extra_fields",JSON.stringify(data.others_extra_fields));

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGTL/updateGTLbenefitdata/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_fourth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  

    if(data.existing_policy_check == "Yes"){
      filedata.append("policy_document",data.policy_document);
      filedata.append("member_data",data.member_data);
      filedata.append("claims_in_last_five_years",data.claims_in_last_five_years);
    }else{
      filedata.append("member_data",data.member_data);
      filedata.append("claims_in_last_five_years",data.claims_in_last_five_years);
    }
    
    // let sizeObj: any = Object.keys(data.extra_upload_files);    
    // for (var i = 1; i <= sizeObj.length; i++) { 
    //   filedata.append("extra_upload_files_"+i, data.extra_upload_files[i][i]);
    // }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGTL/updateGTLdoc/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  getIsuranceCompanyDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'insurance-comp-detailsfront',
    httpOptions);
  }

  getBrokerDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'broker-detailsfront',
    httpOptions);
  }

  getSubCategory(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'productsub-info/product/'+ data.id, 
    data,   
    httpOptions);
  }

  policyDetails_planName(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("plan_name",data.plan_name);
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policyGTL/updateplanGTLdetails/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }
  
}
