import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TopUpPolicyDetailsService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  policyDetailsCreate(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);

    filedata.append("policy_sub_category",data.policy_sub_category);
    filedata.append("existing_policy_check",data.existing_policy_check);
    filedata.append("upload_check",data.upload_check);
    filedata.append("insurance_company",data.insurance_company);
    filedata.append("upload_policydocument",data.upload_policydocument);

    filedata.append("policy_date",data.policy_date);
    filedata.append("policy_expirydate",data.policy_expirydate);
    filedata.append("policy_number",data.policy_number);
    filedata.append("previous_insurer",data.previous_insurer);
    filedata.append("broker_name",data.broker_name);
    filedata.append("tpa",data.tpa);
    filedata.append("emp_atstart",data.emp_atstart);
    filedata.append("lives_covered",data.lives_covered);
    filedata.append("emp_atreport",data.emp_atreport);
    filedata.append("lives_atreport",data.lives_atreport);

    filedata.append("family_def",data.family_def);
    filedata.append("sum_insured_type",data.sum_insured_type);
    filedata.append("sum_insured_approach",data.sum_insured_approach);
    filedata.append("age_range",data.age_range);
    filedata.append("mygradedarray",JSON.stringify(data.mygradedarray));

    filedata.append("thirty_days_waiting_period",data.thirty_days_waiting_period);
    filedata.append("one_and_half_days_waiting_period",data.one_and_half_days_waiting_period);
    filedata.append("pre_existing_condition",data.pre_existing_condition);

    filedata.append("hospitalization_tableArr",JSON.stringify(data.hospitalization_tableArr));
    filedata.append("pre_post_hospitalisation",data.pre_post_hospitalisation);
    filedata.append("domiciliary_hospitalisation",data.domiciliary_hospitalisation);
    filedata.append("domiciliary_hospitalisation_limit",data.domiciliary_hospitalisation_limit);
    filedata.append("day_care_procedures",data.day_care_procedures);
    filedata.append("day_care_procedures_limit",data.day_care_procedures_limit);

    filedata.append("maternity_tableArr",JSON.stringify(data.maternity_tableArr));
    filedata.append("nine_month_waiting_period",data.nine_month_waiting_period);
    filedata.append("room_rent_natal_cover",data.room_rent_natal_cover);
    filedata.append("bay_day_one",data.bay_day_one);
    filedata.append("bay_day_one_isCover",data.bay_day_one_isCover);
    filedata.append("baby_specific_covered_limit",data.baby_specific_covered_limit);
    filedata.append("well_baby_cover",data.baby_specific_covered_limit);
    filedata.append("well_baby_cover_isCover",data.well_baby_cover_isCover);
    filedata.append("well_baby_covered_limit",data.well_baby_covered_limit);
    filedata.append("infertility_cover",data.infertility_cover);
    filedata.append("infertility_cover_isCover",data.infertility_cover_isCover);
    filedata.append("infertility_covered_limit",data.infertility_covered_limit);
    filedata.append("surrogacy_cover",data.surrogacy_cover);
    filedata.append("surrogacy_cover_covered_limit",data.surrogacy_cover_covered_limit);
    filedata.append("maternity_new_born_vaccination",data.maternity_new_born_vaccination);
    filedata.append("maternity_new_born_vaccination_isCover",data.maternity_new_born_vaccination_isCover);
    filedata.append("maternity_new_born_vaccination_limit",data.maternity_new_born_vaccination_limit);
    filedata.append("mother_cover",data.mother_cover);
    filedata.append("mother_cover_isCover",data.mother_cover_isCover);
    filedata.append("mother_cover_covered_limit",data.mother_cover_covered_limit);
    
    filedata.append("claim_copay_condition",data.claim_copay_condition);
    filedata.append("claim_copay_condition_applicable",data.claim_copay_condition_applicable);
    filedata.append("claim_diseasewisecapping_condition",data.claim_diseasewisecapping_condition);
    filedata.append("claim_diseasewisecapping_condition_applicable",data.claim_diseasewisecapping_condition_applicable);
    filedata.append("deductible",data.deductible);
    filedata.append("deductible_condition_applicable",data.deductible_condition_applicable);
    filedata.append("SI_for_parents",data.SI_for_parents);
    filedata.append("SI_for_parents_condition_applicable",data.SI_for_parents_condition_applicable);

    filedata.append("out_patient_benefits",data.out_patient_benefits);
    filedata.append("out_patient_benefits_limit",data.out_patient_benefits_limit);
    filedata.append("opd_dental",data.opd_dental);
    filedata.append("opd_dental_limit",data.opd_dental_limit);
    filedata.append("opd_vision",data.opd_vision);
    filedata.append("opd_vision_limit",data.opd_vision_limit);
    filedata.append("preventive_health_checkup",data.preventive_health_checkup);
    filedata.append("preventive_health_checkup_limit",data.preventive_health_checkup_limit);
    filedata.append("doctor_consultation",data.doctor_consultation);
    filedata.append("doctor_consultation_limit",data.doctor_consultation_limit);
    filedata.append("diagnostics",data.diagnostics);
    filedata.append("diagnostics_limit",data.diagnostics_limit);
    filedata.append("pharmacy",data.pharmacy);
    filedata.append("pharmacy_limit",data.pharmacy_limit);
    filedata.append("new_born_vaccination",data.new_born_vaccination);
    filedata.append("new_born_vaccination_limit",data.new_born_vaccination_limit);
    filedata.append("benefit_extra_fields",JSON.stringify(data.benefit_extra_fields));

    filedata.append("hospital_daily_cash",data.hospital_daily_cash);
    filedata.append("hospital_daily_cash_limit",data.hospital_daily_cash_limit);
    filedata.append("organ_donor_cover",data.organ_donor_cover);
    filedata.append("organ_donor_cover_limit",data.organ_donor_cover_limit);
    filedata.append("hiv_cover",data.hiv_cover);
    filedata.append("hiv_cover_limit",data.hiv_cover_limit);
    filedata.append("genetic_disorder",data.genetic_disorder);
    filedata.append("genetic_disorder_limit",data.genetic_disorder_limit);
    filedata.append("lucentis_Avastin_injections",data.lucentis_Avastin_injections);
    filedata.append("lucentis_Avastin_injections_limit",data.lucentis_Avastin_injections_limit);
    filedata.append("oral_chemo_therapy",data.oral_chemo_therapy);
    filedata.append("oral_chemo_therapy_limit",data.oral_chemo_therapy_limit);
    filedata.append("cochlear_implant",data.cochlear_implant);
    filedata.append("cochlear_implant_limit",data.cochlear_implant_limit);
    filedata.append("ayush_treatment",data.ayush_treatment);
    filedata.append("ayush_treatment_limit",data.ayush_treatment_limit);
    filedata.append("lasik_treatment",data.lasik_treatment);
    filedata.append("lasik_treatment_limit",data.lasik_treatment_limit);
    filedata.append("psychiatry_mental_illness_treatment",data.psychiatry_mental_illness_treatment);
    filedata.append("psychiatry_mental_illness_treatment_limit",data.psychiatry_mental_illness_treatment_limit);
    filedata.append("endoscopic_sinus_surgery",data.endoscopic_sinus_surgery);
    filedata.append("endoscopic_sinus_surgery_limit",data.endoscopic_sinus_surgery_limit);
    filedata.append("internal_congenital",data.internal_congenital);
    filedata.append("internal_congenital_limit",data.internal_congenital_limit);
    filedata.append("external_congenital",data.external_congenital);
    filedata.append("external_congenital_limit",data.external_congenital_limit);
    filedata.append("portability_cover",data.portability_cover);
    filedata.append("corporate_buffer",data.corporate_buffer);
    filedata.append("corporate_buffer_limit",data.corporate_buffer_limit);
    filedata.append("pandemic_and_epidemic",data.pandemic_and_epidemic);

    filedata.append("road_ambulance",data.road_ambulance);
    filedata.append("road_ambulance_limit",data.road_ambulance_limit);
    filedata.append("cardiac_ambulance",data.cardiac_ambulance);
    filedata.append("cardiac_ambulance_limit",data.cardiac_ambulance_limit);
    filedata.append("air_ambulace",data.air_ambulace);
    filedata.append("air_ambulace_limit",data.air_ambulace_limit);
    
    filedata.append("settlement_type",data.settlement_type);

    filedata.append("policy_document",data.policy_document);
    filedata.append("extra_upload_files",JSON.stringify(data.extra_upload_files));

    // var uploadFiles = JSON.stringify(data.extra_upload_files);
    // for(var i = 0; i < uploadFiles.length; i++){
    //     filedata.append("extra_upload_files_"+i, uploadFiles[i][1]);
    // }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/update/' + data.client_product_policy_id,
      filedata
    , httpOptions);
  }

  policyDetailsCreate_first(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();
    filedata.append("benefit_id",data.benefit_id);
    
    if(data.client_product_id == 0 && data.client_product_policy_id == 0){

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
  
        filedata.append("policy_sub_category",'2');
        filedata.append("base_plan",data.base_plan);
        // filedata.append("proposal_number",data.proposal_number);
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("tpa",data.tpa);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);
      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
  
        filedata.append("policy_sub_category",'2');
        filedata.append("base_plan",data.base_plan);
        // filedata.append("proposal_number",data.proposal_number);
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);      
        filedata.append("filename",data.upload_policydocument);      
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policy/store';
      }else{
        val = 'client-policy/policytemp-import';
      }

    }else{

      if(data.existing_policy_check == "Yes"){
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
  
        filedata.append("policy_sub_category",'2');
        filedata.append("base_plan",data.base_plan);
        // filedata.append("proposal_number",data.proposal_number);
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);
        filedata.append("insurance_company",data.insurance_company);
        filedata.append("tpa",data.tpa);
        filedata.append("broker_name",data.broker_name);    
        filedata.append("filename",data.upload_policydocument);
      }else{
        filedata.append("tokenable_id",data.tokenable_id);
        filedata.append("client_id",data.client_id);
        filedata.append("client_product_id",data.client_product_id);
        filedata.append("client_product_policy_id",data.client_product_policy_id);  
  
        filedata.append("policy_sub_category",'2');
        filedata.append("base_plan",data.base_plan);
        // filedata.append("proposal_number",data.proposal_number);
        filedata.append("select_product",data.select_product);
  
        filedata.append("existing_policy_check",data.existing_policy_check);
        filedata.append("upload_check",data.upload_check);      
        filedata.append("filename",data.upload_policydocument);      
      }

      var val;
      if(data.upload_check != 'policy_template'){
        val = 'client-policy/updatebasestep1/' + data.client_product_policy_id;
      }else{
        val = 'client-policy/policytemp-import';
      }

    }                      
        
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + val,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_second(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("benefit_id",data.benefit_id); 

    if(data.existing_policy_check == "Yes"){
      filedata.append("policy_date",data.policy_date);
      filedata.append("policy_expirydate",data.policy_expirydate);
      filedata.append("data_report_date",data.data_report_date);      
      filedata.append("policy_number",data.policy_number);
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("lives_covered",data.lives_covered);
      filedata.append("emp_atreport",data.emp_atreport);
      filedata.append("lives_atreport",data.lives_atreport);
    }else{
      filedata.append("how_soon_buy",data.how_soon_buy);      
      filedata.append("emp_atstart",data.emp_atstart);
      filedata.append("choose_month",data.choose_month);      
    }
    

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/updatebasestep2/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_third(data: any): Observable<any> {

    // console.log(data);

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_id",data.client_product_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("benefit_id",data.benefit_id);    

    // filedata.append("family_def",JSON.stringify(data.family_def));
    
    //Family
    filedata.append("Employees",data.Employees);
    filedata.append("On_Roll_Employees",data.On_Roll_Employees);
    filedata.append("Contractual_Employees",data.Contractual_Employees);
    filedata.append("Retired_Employees",data.Retired_Employees);
    filedata.append("Consultants",data.Consultants);

    filedata.append("Spouse",data.Spouse);
    filedata.append("Legally_Wedded_Spouse",data.Legally_Wedded_Spouse);
    filedata.append("Domestic_Partner",data.Domestic_Partner);
    filedata.append("LGBTQ_Cover",data.LGBTQ_Cover);

    filedata.append("Childrens",data.Childrens);
    filedata.append("children_type",data.children_type);
    filedata.append("Age_Limit_of_the_Child",data.Age_Limit_of_the_Child);
    // filedata.append("Age_Limit_of_the_Child_text",data.Age_Limit_of_the_Child_text);

    filedata.append("Parents",data.Parents);
    filedata.append("Parent_only",data.Parent_only);
    // filedata.append("Parent_or_Parent_Inlaws",data.Parent_or_Parent_Inlaws);
    // filedata.append("Parent_and_Parent_Inlaws",data.Parent_and_Parent_Inlaws);
    filedata.append("Cross_Combination_status",data.Cross_Combination_status);  

    filedata.append("Siblings_or_disabled_siblings",data.Siblings_or_disabled_siblings);
    filedata.append("Siblings_or_disabled_siblings_status",data.Siblings_or_disabled_siblings_status);
    filedata.append("Age_Limit_of_the_Siblings",data.Age_Limit_of_the_Siblings);
    filedata.append("Age_Limit_of_the_Siblings_text",data.Age_Limit_of_the_Siblings_text);
    
    filedata.append("overall_age_range",data.overall_age_range);
    filedata.append("age_range_min",data.age_range_min);
    filedata.append("age_range_max",data.age_range_max);
    
    //Sum Incured
    filedata.append("basis_of_topup_SI",data.basis_of_topup_SI);
    filedata.append("min_topup_SI",data.min_topup_SI);
    filedata.append("max_topup_SI",data.max_topup_SI);
    filedata.append("interval_topup_SI",data.interval_topup_SI);
    filedata.append("Multiplier_of_base_Arr",JSON.stringify(data.Multiplier_of_base_Arr));
    filedata.append("Graded_topup_Arr",JSON.stringify(data.Graded_topup_Arr));                  

    //Waiting
    filedata.append("policy_hygien",data.policy_hygien);
    filedata.append("entry_exit_clause",data.entry_exit_clause);
    filedata.append("entry_exit_clause_limit",data.entry_exit_clause_limit);
    filedata.append("waver_of_base_SI_sub_limit",data.waver_of_base_SI_sub_limit);
    filedata.append("waver_of_base_SI_sub_limit_limit",data.waver_of_base_SI_sub_limit_limit);
    filedata.append("add_more_condition",data.add_more_condition);
    filedata.append("mygradedarray",JSON.stringify(data.mygradedarray));

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/updatetopbenefitdata/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  policyDetailsCreate_fourth(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("policy_document",data.policy_document);

    // console.log(data.extra_upload_files);
    let sizeObj: any = Object.keys(data.extra_upload_files);
    // console.log(sizeObj.length);
    
    for (var i = 1; i <= sizeObj.length; i++) { 
      filedata.append("extra_upload_files_"+i, data.extra_upload_files[i][i]);
    }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/updatedoc/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  }

  getIsuranceCompanyDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'insurance-comp-detailsfront',
    httpOptions);
  }

  getTPADetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'tpa-detailsfront',
    httpOptions);
  }

  getBrokerDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'broker-detailsfront',
    httpOptions);
  }

  getSubCategory(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'productsub-info/product/'+ data.id, 
    data,   
    httpOptions);
  }

  policyDetails_planName(data: any): Observable<any> {        

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("client_product_policy_id",data.client_product_policy_id);  
    filedata.append("plan_name",data.plan_name);
    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/updateplandetails/' + data.client_product_policy_id,
      filedata
    , httpOptions);
    
  } 

  getBasePlan(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/getclientbasepolicy/' + data.client_id,
    data,
    httpOptions);
  }

  getBenefitData(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'client-policy/getgmcbasepolicybenifitdata/' + data.client_product_policy_id,
    data,
    httpOptions);
  }

}
