import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { MarketDataService } from '../market-data/market-data';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-market-data',
  templateUrl: './market-data.component.html',
  styleUrls: ['./market-data.component.css']
})
export class MarketDataComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public makertData:FormGroup;

  user_role: any;
  validmsg: any;
  fileData: any;
  fileName: any = '';

  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  marketDataSubmitted: boolean = false;

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  displayStyle = "none";
  displayStyle_view = "none";
  displayStyleDelete = "none";

  public marketEdit:FormGroup;
  public marketEdit_view:FormGroup;
  public marketDelete:FormGroup;

  marketArray: any = [];
  viewArray: any = [];
  edit_lisitng_Array: any = [];
  postarray_edit: any = [];

  constructor(private fb: FormBuilder, private marketDataService: MarketDataService, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.marketArray = [];
    this.getMarketInfo();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.makertData = this.fb.group({
      file: new FormControl(''),
      fileSource: new FormControl('')
    });

    // Form element defined below
    this.marketEdit = this.fb.group({
      company_code: new FormControl(''),
      company_name: new FormControl(''),
      industry_type: new FormControl(''),
      employee_strength: new FormControl(''),
      edit_id: new FormControl('')
    });

    // Form element defined below
    this.marketEdit_view = this.fb.group({
      company_code_view: new FormControl(''),
      company_name_view: new FormControl(''),
      industry_type_view: new FormControl(''),
      employee_strength_view: new FormControl('')
    });

    // Form element defined below
    this.marketDelete = this.fb.group({
      delete_id: new FormControl('')      
    });

  }

  getMarketInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    this.marketDataService.getMarketInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.marketArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.marketArray = [];
          
      });
  }

  openPopup(res: any) {
    this.displayStyle = "block";
    this.onEditLoad(res);
  }

  openPopup_view(res: any) {
    this.displayStyle_view = "block";
    this.onEditLoad_view(res);
  }

  onEditLoad_view(res: any){          

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.mcm_id
    };

    this.marketDataService.marketEdit(postdata)
      .subscribe((result: any) => {
        
        // Display no of text box
        this.viewArray = result.data[0];
                  
        $("#viewDiv").empty();

        var i = 0;
        for (var k in this.viewArray){

          //  console.log("Key - " + k + ", Value - " + this.viewArray[k]);            

          const search = '_'; 
          const replaceWith = ' ';

          var val:any = k.split(search).join(replaceWith);
          var title = val.charAt(0).toUpperCase() + val.slice(1)
          // console.log(title);  
          
          var classval;
          if(i > 2){
            classval = 'mt-2';
          }          

          var html = `
          <div class="col-md-4 col-sm-6 col-xs-12 `+ classval +`">
              <div class="form-group">
                  <label class="bs-form-label" for="`+ this.viewArray[k] +`">`+ title +`</label>
                  <input type="text" class="form-control pnone" value="`+ this.viewArray[k] + `" />                    
              </div>
          </div>
          `;

          $('#viewDiv').append(html);
          i++;

        }

        // this.marketEdit_view.controls['company_code_view'].setValue(result.data[0]['company_code']);
        // this.marketEdit_view.controls['company_name_view'].setValue(result.data[0]['company_name']);
        // this.marketEdit_view.controls['industry_type_view'].setValue(result.data[0]['industry_type']);
        // this.marketEdit_view.controls['employee_strength_view'].setValue(result.data[0]['employee_strength']);

      }, (error) => {          
               
    });

  }

  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.mcm_id
    };

    this.marketDataService.marketEdit(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.marketDelete.controls['delete_id'].setValue(result[0]['mcm_id']);
        
      }, (error) => {          
               
    });

  }

  onEditLoad(res: any){          

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.mcm_id
    };

    this.marketDataService.marketEdit(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);
        this.edit_lisitng_Array = result.data[0];
        this.postarray_edit = result.data[0];

        $("#editDiv").empty();

        var i = 0;
        for (var k in this.edit_lisitng_Array){

          //  console.log("Key - " + k + ", Value - " + this.edit_lisitng_Array[k]);            

          const search = '_'; 
          const replaceWith = ' ';

          var val:any = k.split(search).join(replaceWith);
          var title = val.charAt(0).toUpperCase() + val.slice(1)
          // console.log(title);  
          
          var classval;
          if(i > 2){
            classval = 'mt-2';
          }

          var html = `
          <div class="col-md-4 col-sm-6 col-xs-12 `+ classval +`">
              <div class="form-group">
                  <label class="bs-form-label" for="`+ this.edit_lisitng_Array[k] +`">`+ title +`</label>
                  <input type="text" class="form-control pnone" id="`+ k + `" value="`+ this.edit_lisitng_Array[k] + `" />                    
              </div>
          </div>
          `;

          $('#editDiv').append(html);
          i++;

        }

        // this.marketEdit.controls['company_code'].setValue(result.data[0]['company_code']);
        // this.marketEdit.controls['company_name'].setValue(result.data[0]['company_name']);
        // this.marketEdit.controls['industry_type'].setValue(result.data[0]['industry_type']);
        // this.marketEdit.controls['employee_strength'].setValue(result.data[0]['employee_strength']);
        // this.marketEdit.controls['edit_id'].setValue(result.data[0]['mcm_id']);

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    var keyarray: any = [];
    var valuearray: any = [];

    var i = 0;
    for (var k in this.postarray_edit){

      //  console.log("Key - " + k + ", Value - " + this.edit_lisitng_Array[k]);            

      var key:any = k;      
      var value:any = $('#' + key).val();      

      keyarray[i] = k;
      valuearray[i] = value;

      i++;

    }

    // console.log(keyarray);
    // console.log(valuearray); 

    var postdata:any = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "key": valuearray
    };
   
    // var postdata = {
    //   "tokenable_id":localStorage.getItem('tokenable_id'),
    //   "id": id,
    //   "company_code":this.marketEdit.controls['company_code'].value,
    //   "company_name":this.marketEdit.controls['company_name'].value,
    //   "industry_type":this.marketEdit.controls['industry_type'].value,
    //   "employee_strength":this.marketEdit.controls['employee_strength'].value
    // };

    console.log(postdata);

    return false;

    this.marketDataService.marketUpdate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none'); 
          this.closePopup();         
          this.validmsg = result.message;
          this.getMarketInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {          
        this.closePopup();         
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.marketDataService.marketDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getMarketInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  closePopup() {
    this.displayStyle = "none";
    this.displayStyle_view = "none";
    this.displayStyleDelete = "none";  
  }

  get getmarketDataFormControls() {
    return this.makertData.controls;
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  ondownload(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/sample-market-data.xlsx');
    link.setAttribute('download', `Sample Market File.xls`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onFileChange(event: any) {

    if (event.target.files.length > 0) {
      this.fileName = event.target.files[0]['name'];
      this.fileData = event.target.files[0];
    }   

  }

  onSubmit() {
    this.marketDataSubmitted = true;
    if (this.marketDataSubmitted && this.makertData.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "filename": this.fileData   
      };

      console.log("postdata",postdata);

      this.marketDataService.employeeImport(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {
            
            this.show = false;
            // this.makertData.reset();
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            this.validmsg = result.message;

            let flag = this;
            setTimeout(function() {
              if(flag.type_name == "client"){
                flag.route.navigate(['/Client-dashboard']);
              }
              if(flag.type_name == "superadmin"){              
                flag.route.navigate(['/SuperAdmin-dashboard']);
              }                         
              if(flag.type_name == "admin"){
                flag.route.navigate(['/Admin-dashboard']);
              }
              flag.makertData.reset();
            }, 2000);          

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    } else {
      // validate all form fields
    }
  }

  onSkip(){

    this.route.navigate(['/Client-dashboard']);

  }

}
