import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { ToastService } from '../../toast.service';
import { event } from 'jquery';


@Component({
  selector: 'app-broker-ecard',
  templateUrl: './broker-ecard.component.html',
  styleUrls: ['./broker-ecard.component.css']
})
export class BrokerEcardComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;
  
  InfoArray: any = [];   
  InfoArrayClient: any = [];   
  policyArray: any = [];   
  EmployeeArray: any = [];   
  MemberArray: any = [];   
  selectedMember: any = [];
  selectedMemberId: any;
  selectedClient: any;

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Broker-ecard';

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
  }


  initGroup(){
    this.getcompanyDetails();
    this.assignrights();
  }

  goto(url: string){
    window.open(url,'_blank');   
  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.InfoArrayClient = result.data;

      }, (error) => {
          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

   getPolicy(event: any) {

    this.selectedClient = event.id;

    setTimeout(()=>{
      this.InfoArray = [];
      this.MemberArray = [];
      $('#employee_id').val('');
      this.selectedMemberId = undefined
      $('.memberDiv').css('display', 'none');
    },1000);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id":this.selectedClient,
    };

    this.service.getPolicy(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.policyArray = result.data;
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getEmployee(){

    setTimeout(()=>{
      this.InfoArray = [];
      this.MemberArray = [];
      $('#member_id').val('');
      this.selectedMemberId = undefined
    },1000);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.selectedClient,
      "policy_id": $('#policy_id').val(),
    };

    this.service.getEmployee(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.EmployeeArray = result.data;
          if(this.EmployeeArray.length === 0){
            $('.memberDiv').css('display', 'flex');
          }else{
            $('.memberDiv').css('display', 'none');
          }
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  getMembers(){

    setTimeout(()=>{
      this.InfoArray = [];
      this.selectedMemberId = undefined
    },1000);
    
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id": this.selectedClient,
      "policy_id": $('#policy_id').val(),
      "empid": $('#employee_id').val(),
    };

    console.log(this.selectedMemberId);

    this.service.getMembers(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.MemberArray = result.data;
          if(this.MemberArray.length === 0){
            $('.memberDiv').css('display', 'flex');
          }else{
            $('.memberDiv').css('display', 'none');
          }
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  
  getMemberData(event: any){

   this.selectedMemberId = event.target.value;
   
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "employer_id":this.selectedClient,
      "policy_id": $('#policy_id').val(),
      "empid": $('#employee_id').val(),
      "mid":this.selectedMemberId,
    };

    this.service.getMemberId(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.InfoArray = result.data;
        }
      }, (error) => {

        this.InfoArray = []
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

}
