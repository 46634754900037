<div class="back">
    <div class="div-center">
      <div class="content row m-0">
  
        <div class="col-sm-7 p-0">
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../assets/img/login/bg-image-1.png" class="d-block img-fluid">
                  <div class="carousel-caption d-md-block">
                    <h5>Heading 1</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src="../../../assets/img/login/bg-image-2.png" class="d-block img-fluid">
                  <div class="carousel-caption d-md-block">
                    <h5>Heading 2</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
                <!-- <div class="carousel-item">
                  <img src="../../../assets/img/login/loginslideimage1_400x300.jpeg" class="d-block img-fluid">
                  <div class="carousel-caption d-none d-md-block">
                    <h5>Heading 1</h5>
                    <p>Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div> -->
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
      </div>
      <div class="col-sm-5 p-4 pl-0">
          <div align="center">
            <a routerLink="/home"><img class="" src="../../../assets/logo.png" style="width: 40px; cursor: pointer;" class="img-fluid" alt="logo" /></a> 
          </div>
          <div class="mt-3">
              <h4 class="text-center" id="loginh4">Login</h4>
              <h4 class="text-center" id="forgoth4" style="display: none;">Forgot Password</h4>
              <!-- <label class=""> Lorem ipsum dolor sit amet consectetur</label> -->
              <form [formGroup]="loginForm" class="mt-3">
                <div id="logindiv">
                  <div class="mb-2">
                    <label for="username" class="form-label">Username</label>
                    <input type="email" class="form-control" id="username" formControlName="username" placeholder="Email / Username" >
                  </div>
                  <div class="mb-2">
                      <label for="pwd" class="form-label">Password</label>
                      <input type="password" class="form-control" id="pwd" formControlName="pwd" placeholder="Password">
                  </div>                
                  <div class="mb-2 row" style="display: flex;">
                      <div class="col-sm-6">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="exampleCheck1">
                          <label class="form-check-label form-label" for="exampleCheck1"> &nbsp; Remember me </label>
                      </div>
                      <div class="col-sm-6 form-label" style="text-align: right;"><span style="color:#4E0099; cursor: pointer;" (click)="openforgotpwd()"><u>Forgot Password? </u></span></div>
                  </div>
                  <div>
                    <a style="text-decoration: none;">
                      <button type="submit" (click)="login()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                        Sign In
                      </button>
                    </a>
                  </div>
                  <div class="mt-2 mb-3"
                      style="width: 100%; height: 14px; border-bottom: 1px solid #ced4da; text-align: center">
                      <span style="background-color: #fff; padding: 0 10px;">
                          Or
                      </span>
                  </div>
                  <div class="my-1 w-100">
                      <a class="btn btn-outline-dark w-100" href="/users/googleauth" role="button"
                          style="font-size:14px; text-transform:none; border: solid 1px #ced4da; padding: 6px 20px 6px 20px; border: 1px solid #AEAAAE; border-radius: 4px;">
                          <img width="20px" style="margin-bottom:3px; margin-right:5px;" alt="Google sign-in"
                              src="../../../assets/img/common icons/gogglelogo.png" />
                          Sign In with Google
                      </a>
                  </div>
                  <div class="my-3 w-100 form-label" style="text-align: center;"> Don't you have an Account? <a routerLink="/survey-register">Sign Up</a>
                  </div>
                </div> 
                
                <!------ Forgot pwd ----->
                <div id="forgotpwd" style="display: none;">
                  <div class="mb-2" id="forgotpwdDiv" style="display: none;">
                    <label for="forgotpwdemail" class="form-label">Email</label>
                    <input type="email" class="form-control" id="forgotpwdemail" formControlName="forgotpwdemail" placeholder="Enter email">
                    <small style="font-size: 12px;">Temporary password will be sent to above email</small>
                  </div>
  
                  <div class="mb-2" id="temppwdDiv" style="display: none;">
                    <label for="forgotpwd" class="form-label">Temporary Password</label>
                    <input type="password" class="form-control" id="temppwdDiv" formControlName="temppwdDiv" placeholder="Enter Temporary password">
                  </div>
  
                  <div class="mb-2" id="newpwdDiv" style="display: none;">
                    <label for="forgotpwd" class="form-label">New Password</label>
                    <input type="password" class="form-control" id="newpwdDiv" formControlName="newpwdDiv" placeholder="Enter new password">
                  </div>
  
                  <div class="mb-2" id="confirmpwd" style="display: none;">
                    <label for="forgotpwd" class="form-label">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmpwd" (keyup)="checkpwd()" formControlName="confirmpwd" placeholder="Confirm password">
                  </div>
  
                  <a style="text-decoration: none;" id="submitemail">
                    <button type="submit" (click)="submitemail()" class="btn btn-primary w-100 primary">
                      Submit
                    </button>
                  </a>
  
                  <a style="text-decoration: none;" id="submitpwd" style="display:none">
                    <button type="submit" id="submitfinal" (click)="submitpwd()" class="btn btn-primary w-100 primary">
                      Submit
                    </button>
                  </a>
  
                  <div class="mt-3">
                    <button type="button" (click)="backtologin()" class="btn btn-primary w-100" style="padding: 6px 20px 6px 20px; text-align: center; background:#4E0099; box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%); border-radius: 4px; display: block;">
                      Back to Sign Up
                    </button>
                  </div>              
  
                </div>
  
              </form>                                        
              <div id="validmsg" class="red">{{ validmsg }}</div>
  
          </div>
      </div>
      
      </div>    
    </div>
  
    <!---- Do you want to reegister Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_DoyouwantRegister}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Don't you have an Account?</h4>
              </div>                
              <div class="modal-body">                                
                  Sorry you are not a existing user. Do you want to register?
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-success" (click)="closePopup('yes')"> Yes</button>
                  <button type="button" class="btn btn-danger" (click)="closePopup('no')"> No</button>
              </div>
          </div>
      </div>
  </div>
  
  <!---- Thank you Popup ---->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Success !</h4>
            </div>                
            <div class="modal-body">                                
                Password updated.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="closePopup_forgotpwd()"> Close</button>
            </div>
        </div>
    </div>
  </div>
  
  </div>